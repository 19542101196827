import React from "react";
import "../../../assets/style.css";

class MyInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
    };
  }

  handleFocus = () => {
    this.setState({ isFocused: true });
  };

  handleBlur = () => {
    if (!this.props.value) {
      this.setState({ isFocused: false });
    }
  }

  render() {
    const { isFocused } = this.state;
    const { id, value, nameLabel, bootstrap, type, disabled, maxLength, required, onChange } = this.props;

    return (
      <div className={`grupo p-0 ${bootstrap}`}>
        <input
          className={`input-animated col-12 mb-2 ${isFocused || value ? "focused" : ""}`}
          type={type}
          id={id}
          disabled={disabled}
          value={value}
          maxLength={maxLength}
          required={required === "false" ? false : true}
          onChange={onChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />
        <span className="barra"></span>
        <br />
        <label className={`label-animated ${isFocused || value ? "focused" : ""}`} htmlFor={id}>
          {nameLabel}
        </label>
      </div>
    );
  }

}

export default MyInput;
