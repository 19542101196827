import {
  faEye,
  faFileDownload,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "@sweetalert/with-react";
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import MyInput from "../../../utils/custom/MyInput";
import CustomSelect from "../../../utils/custom/CustomSelect";
import MyTextArea from "../../../utils/custom/MyTextArea";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";
import ModalModificarCat from "./ModalModificarCat";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalCatalogo extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      corto: "",
      clave: "",
      movimiento_select_text: "Seleccione una poliza",
      movimiento_select_id: "",
      catalogoMovimientos: [],
      tipos_poliza: [
        { nombre: "Ingresos", clave: "1" },
        { nombre: "Egresos", clave: "2" },
        { nombre: "Diario", clave: "3" },
      ],
      claveCatalogo: "",
      registros: [],
      catalogoCreado: [],
      toogle_catalogo_sub: false,
      modificarId: "",
      modificarNombre: "",
      modificarCorto: "",
      modificarClave: "",
    };
    this.handleModificacion = this.handleModificacion.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderEgresos = () => {
    const clave = "2";

    axios
      .post(utility.url_subtipos, {
        clave: clave,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              catalogoMovimientos: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  renderIngresos = () => {
    const clave = "1";

    axios
      .post(utility.url_subtipos, {
        clave: clave,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              catalogoMovimientos: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  renderDiario = () => {
    const clave = "3";

    axios
      .post(utility.url_subtipos, {
        clave: clave,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              catalogoMovimientos: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  catalogoCreaRegistros = () => {
    let claveCatalogo = null;
    if (this.state.movimiento_select_text === "Ingresos") claveCatalogo = "1";
    else if (this.state.movimiento_select_text === "Egresos")
      claveCatalogo = "2";
    else if (this.state.movimiento_select_text === "Diario")
      claveCatalogo = "3";

    this.setState({ registros: this.state.catalogoCreado });

    axios
      .post(utility.url_crea_modifica, {
        claveCatalogo,
        registros: this.state.registros,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            swal({
              icon: "success",
              title: response.data.title,
              text: response.data.text,
              button: true,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handleAlta = () => {
    message
      .message_confirmacion_password(
        "¿Desea guardar los cambios?",
        "Ingrese su contraseña y observaciones"
      )
      .then((res) => {
        if (res === true) {
          let mov = {
            user: document.getElementById("usuario_modificador").value,
            pass: document.getElementById("contraseña_modificador").value,
            motivo: document.getElementById("observaciones_textarea").value,
          };
          if (mov.motivo.length === 0)
            swal("Completar el campo:", "Motivo de Notificación", "warning");
          else
            axios
              .post(utility.url_verify, {
                usuario: mov.user,
                password: mov.pass,
              })
              .then((response) => {
                if (response.status >= 200 && response.status < 400) {
                  if (this._isMounted){
                    this.catalogoCreaRegistros();
                    this.setState({catalogoCreado: []})
                  }
                }
              })
              .catch((error) => {
                if (error.response)
                  message.message_modal(
                    error.response.data.title,
                    error.response.data.text,
                    error.response.status
                  );
              });
        }
      });
  };

  handleMovimientoSol = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let movimiento_sol_anticipo_text = e[label];
    let movimiento_sol_anticipo_id = e[label_id];
    this.setState({ movimiento_sol_anticipo_text, movimiento_sol_anticipo_id });
  };

  handleMovimiento = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let movimiento_select_text = e[label];
    let movimiento_select_id = e[label_id];
    this.setState({ movimiento_select_text, movimiento_select_id });
  };
  handleConcepto = (e) => {
    let nombre = e.target.value;
    this.setState({ nombre });
  };
  handlecorto = (e) => {
    let corto = e.target.value;
    this.setState({ corto });
  };
  handleClave = (e) => {
    let clave = e.target.value;
    this.setState({ clave });
  };
  handleUnidad = (e) => {
    let unidad = e.target.value;
    this.setState({ unidad });
  };
  handleAuxiliar = (e) => {
    let auxiliar = e.target.value;
    this.setState({ auxiliar });
  };

  handleCerrar = () => {
    this.props.toogleCatalogo();
    this.handleLimpiar();
  };

  handleLimpiar = () => {
    this.setState({
      corto: "",
      clave: "",
      nombre: "",
      movimiento_select_text: "Seleccione una poliza",
      catalogoMovimientos: [],
    });
  };

  handleAgregar = () => {
    if (
      this.state.movimiento_select_text === "Seleccione una poliza" ||
      this.state.nombre === "" ||
      this.state.corto === "" ||
      this.state.clave === ""
    )
      message.warningMessage("Por favor, ingrese los campos faltantes");
    else {

      let movCreados = this.state.catalogoCreado;

      let info = {
        nombre: this.state.nombre,
        corto: this.state.corto,
        clave: this.state.clave,
      };
      movCreados.push(info);
      this.setState({catalogoCreado: movCreados});
      /* this.setState({
        nombre: this.state.nombre,
        corto: this.state.corto,
        clave: this.state.clave,
      }); */
    }
  };

  handleEliminar = (clave, id) => {
    const toFind = clave;

    if (id === undefined) {
      const movimientos = this.state.catalogoCreado.filter(
        (movimiento) => movimiento.clave !== toFind
      );
      this.setState({
        catalogoCreado: movimientos,
      });
    } else {
      message
        .message_confirmacion_password(
          "¿Desea eliminar el movimiento?",
          "Ingrese su contraseña y observaciones"
        )
        .then((res) => {
          if (res === true) {
            let mov = {
              user: document.getElementById("usuario_modificador").value,
              pass: document.getElementById("contraseña_modificador").value,
              motivo: document.getElementById("observaciones_textarea").value,
            };
            if (mov.motivo.length === 0)
              swal("Completar el campo:", "Motivo de Notificación", "warning");
            else if (this.state.movimiento_select_text === "Ingresos")
              this.setState({ claveCatalogo: "1" });
            else if (this.state.movimiento_select_text === "Egresos")
              this.setState({ claveCatalogo: "2" });
            else if (this.state.movimiento_select_text === "Diario")
              this.setState({ claveCatalogo: "3" });

            const iD = id.toString();
            const claveCatalogo = this.state.claveCatalogo;
            axios
              .post(utility.url_elimina, {
                id: iD,
                claveCatalogo: claveCatalogo,
              })
              .then((response) => {
                if (response.status >= 200 && response.status < 400) {
                  if (this._isMounted) {
                    swal({
                      icon: "success",
                      title: response.data.title,
                      text: response.data.text,
                      button: true,
                    });
                    this.handleConsulta();
                  }
                }
              })
              .catch((error) => {
                if (error.response)
                  message.message_modal(
                    error.response.data.title,
                    error.response.data.text,
                    error.response.status
                  );
              });
          }
        });
    }
  };

  handleConsulta = () => {
    if (this.state.movimiento_select_text === "Seleccione una poliza")
      message.warningMessage("Por favor, ingrese el tipo de poliza");
    else {
      if (this.state.movimiento_select_text === "Egresos") this.renderEgresos();
      if (this.state.movimiento_select_text === "Ingresos")
        this.renderIngresos();
      if (this.state.movimiento_select_text === "Diario") this.renderDiario();
    }
  };

  handleModificacion = (id, clave, nombre, corto) => {
    this.setState({
      modificarId: id,
      modificarClave: clave,
      modificarNombre: nombre,
      modificarCorto: corto,
    });

    this.setState((prevState) => ({
      toogle_catalogo_sub: !prevState.toogle_catalogo_sub,
    }));
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_catalogo}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Catálogo De Subtipos
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="mb-0 row">
              <CustomSelect
                bootstrap="col-lg-10 col-12"
                name_text="nombre"
                name_index="clave"
                id="tipo_movimiento_sol_antiicpo"
                nameLabel="Tipo de poliza"
                value={this.state.movimiento_select_text}
                onChange={this.handleMovimiento}
                options={this.state.tipos_poliza}
              />
              <Button
                color="primary"
                className="col-lg-2 col-12 mb-2"
                onClick={this.handleConsulta}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-10 col-12"
                id="Concepto"
                nameLabel="Concepto"
                onChange={this.handleConcepto}
                value={this.state.nombre}
                disabled={
                  this.state.catalogoMovimientos.length === 0 ? true : false
                }
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="corto"
                nameLabel="Concepto Abreviado"
                onChange={this.handlecorto}
                value={this.state.corto}
                disabled={
                  this.state.catalogoMovimientos.length === 0 ? true : false
                }
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="claveCatalogo"
                nameLabel="Clave"
                onChange={this.handleClave}
                value={this.state.clave}
                disabled={
                  this.state.catalogoMovimientos.length === 0 ? true : false
                }
              />
              <Button
                color="primary"
                className="col-lg-2 col-12 mb-2"
                onClick={this.handleAgregar}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </FormGroup>
          </Form>
          <Table size="sm" bordered hover responsive>
            <thead className="text-center header-table">
              <tr>
                <td> Concepto </td>
                <td>Concepto Abreviado</td>
                <td>Clave</td>
                <td></td>
                <td></td>
              </tr>
            </thead>
            <tbody className="body-table">
              {this.state.catalogoMovimientos.map((mov) => (
                <tr key={mov.clave}>
                  <td>
                    <p className="mb-0 text-center">{mov.nombre}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{mov.corto}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{mov.clave}</p>
                  </td>
                  <td className="text-center">
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => this.handleEliminar(mov.clave, mov.id)}
                    >
                      Eliminar
                    </Button>
                  </td>
                  <td className="text-center">
                    <Button
                      id="boton"
                      color="info"
                      size="sm"
                      onClick={() =>
                        this.handleModificacion(
                          mov.id,
                          mov.clave,
                          mov.nombre,
                          mov.corto
                        )
                      }
                    >
                      Modificar
                    </Button>
                  </td>
                </tr>
              ))}
              {this.state.catalogoCreado.map((mov) => (
                <tr key={mov.clave}>
                  <td>
                    <p className="mb-0 text-center">{mov.nombre}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{mov.corto}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{mov.clave}</p>
                  </td>
                  <td className="text-center">
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => this.handleEliminar(mov.nombre, mov.id)}
                    >
                      Eliminar
                    </Button>
                  </td>
                  <td className="text-center">
                    <Button
                      id="boton"
                      color="info"
                      size="sm"
                      onClick={() =>
                        this.handleModificacion(
                          mov.id,
                          mov.clave,
                          mov.nombre,
                          mov.corto
                        )
                      }
                    >
                      Modificar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="success"
            className="mr-2"
            onClick={this.handleAlta}
            disabled={
              this.state.catalogoCreado.length === 0 ? true : false
            }
          >
            Guardar
          </Button>
          <Button type="button" color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
        <ModalModificarCat
          toogle_catalogo={this.state.toogle_catalogo_sub}
          toogleCatalogo={this.handleModificacion}
          subId={this.state.modificarId}
          subClave={this.state.modificarClave}
          subNombre={this.state.modificarNombre}
          subCorto={this.state.modificarCorto}
          tipoPoliza={this.state.movimiento_select_text}
        />
      </Modal>
    );
  }
}
export default ModalCatalogo;
