export const url_mis_chats = "/DjangoWeb/ChatsApp/consulta/mis_chat";
export const url_usuarios = "/DjangoWeb/ChatsApp/consulta/usuarios";
export const url_departamentos = '/DjangoWeb/EmpleadosApp/consulta/departamentos/lista';
export const url_clientes = '/DjangoWeb/BaseApp/consulta/clientes/lista';

/**
 * Función que permite retornar los nombres de un usuario.
 * @param {Array} array Arreglo de usuarios.
 * @returns {Array} Nombres de usuario.
 */
export const nombres_usuarios = (array) => {
    let nombres = [];
    for (let i = 0; i < array.length; i++)
        nombres.push(array[i].username);
    return nombres;
}
export const obtener_first_name = (array, nombre) => {
    for (let i = 0; i < array.length; i++)
        if (array[i].username === nombre)
            return array[i].first_name;
}
/**
 * Función que obtiene los nombres de todos los clientes
 * @param {Array} arreglo
 * @returns {Array} Arreglo de nombres.
 */
export const nombres_departamentos = (arreglo) => {
    var aux = [];
    for (var i = 0; i < arreglo.length; i++) {
        aux.push(arreglo[i].nombre);
    }
    return (aux);
}

export const obtener_numero_nombre_cliente = (array) => {
    var aux = [];
    for (let i = 0; i < array.length; i++)
        aux.push(`${array[i].numero} - ${array[i].nombre}`);
    return (aux);
}