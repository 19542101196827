/* 
Autor: Alexis Yael Hernández Grimaldo
Descripción: El componenete genera un bloque correspondiente a la busqueda dinamica en la tabla para acceder a la información.
*/
import React, { Component } from 'react';
import { Button, Collapse, Form, FormGroup } from 'reactstrap';
import '../../assets/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import MyInput from '../../utils/custom/MyInput';

class BusquedaEspecifica extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toogle_collapse: false,
        }
    }
    toogleCollapse = (e) => {
        this.setState(prevState => ({ toogle_collapse: !prevState.toogle_collapse }))
    }
    handleValida = (data) => {
        if (data.numero.length === 0)
            delete data.numero;
        if (data.nombre_corto.length === 0)
            delete data.nombre_corto;
        if (data.nombre.length === 0)
            delete data.nombre;
        if (data.id_fiscal.length === 0)
            delete data.id_fiscal;
        if (data.ciudad.length === 0)
            delete data.ciudad;
        if (data.pais.length === 0)
            delete data.pais;
        if (data.estado.length === 0)
            delete data.estado;
        if (data.telefono.length === 0)
            delete data.telefono;
        if (data.correo.length === 0)
            delete data.correo;
        if (data.patente.length === 0)
            delete data.patente;
        if (data.aduana.length === 0)
            delete data.aduana;
        if (data.cliente.length === 0)
            delete data.cliente;
    }
    handleFiltrada = () => {
        let data = {
            numero: document.getElementById('specific_numero').value,
            nombre_corto: document.getElementById('specific_nombre').value,
            nombre: document.getElementById('specific_nombre_corto').value,
            id_fiscal: document.getElementById('specific_id_fiscal').value,
            ciudad: document.getElementById('specific_ciudad').value,
            pais: document.getElementById('specific_pais').value,
            estado: document.getElementById('specific_estado').value,
            telefono: document.getElementById('specific_telefono').value,
            correo: document.getElementById('specific_correo').value,
            patente: document.getElementById('specific_patente').value,
            aduana: document.getElementById('specific_aduana').value,
            cliente: document.getElementById('specific_cliente').value,
        }
        this.handleValida(data);
        this.props.onChangeFiltrada(data);
    }
    render() {
        return (
            <React.Fragment>
                <div className="row mb-2 mx-1">
                    <h3 className="col-md-11 col-9 text-md-left px-0 mb-0">
                        Consulta Específica
                    </h3>
                    <Button
                        color="info"
                        onClick={this.toogleCollapse}
                        className="col-md-1 col-3">
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </div>
                <Collapse isOpen={this.state.toogle_collapse}>
                    <Form className="container-fluid">
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-3" id="specific_numero" nameLabel="Número"
                                 />
                            <MyInput type="text" bootstrap="col-lg-3" id="specific_nombre_corto" nameLabel="Nombre Corto"
                                 />
                            <MyInput type="text" bootstrap="col-lg-3" id="specific_nombre" nameLabel="Nombre"
                                 />
                            <MyInput type="text" bootstrap="col-lg-3" id="specific_id_fiscal" nameLabel="ID Fiscal"
                                 />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-3" id="specific_ciudad" nameLabel="Ciudad"
                                 />
                            <MyInput type="text" bootstrap="col-lg-3" id="specific_pais" nameLabel="País"
                                 />
                            <MyInput type="text" bootstrap="col-lg-3" id="specific_estado" nameLabel="Estado"
                                 />
                            <MyInput type="text" bootstrap="col-lg-3" id="specific_telefono" nameLabel="Telefono"
                                 />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-3" id="specific_correo" nameLabel="Ciudad"
                                 />
                            <MyInput type="text" bootstrap="col-lg-3" id="specific_patente" nameLabel="Patente"
                                 />
                            <MyInput type="text" bootstrap="col-lg-3" id="specific_aduana" nameLabel="Aduana"
                                 />
                            <MyInput type="text" bootstrap="col-lg-3" id="specific_cliente" nameLabel="Cliente"
                                 />
                        </FormGroup>
                        <FormGroup className="row d-flex justify-content-center mb-0">
                            <Button color="danger" className="mb-1" onClick={this.props.onBackTable}>Regresar</Button>
                            <Button color="success ml-2 mb-1" onClick={this.handleFiltrada}>Consultar</Button>
                        </FormGroup>
                    </Form>
                </Collapse>
            </React.Fragment>

        );
    }
}
export default BusquedaEspecifica;