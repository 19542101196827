import React from "react";

class SelectComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }
        this.handleDropdownClick = this.handleDropdownClick.bind(this);
    }
    handleDropdownClick() {
        if (this.props.disabled)
            this.setState({ isOpen: false });
        else
            this.setState({
                isOpen: this.state.isOpen == true ? false : true
            })
    }
    render() {
        var cls = 'select';
        if (this.state.isOpen) cls += ' _active select_backgroud';
        return (
            <div className={`grupo p-0 ${this.props.bootstrap}`}>
                <span className={this.state.isOpen ? "label-select-custom label-select-custom-focus" : "label-select-custom"}>{this.props.nameLabel}</span>
                <div className={cls} style={{ background: this.props.disabled ? '#e9ecef' : "#fff" }} onClick={this.handleDropdownClick} >
                    <div className="select-selected">
                        {this.props.value}
                    </div>
                    {
                        this.state.isOpen && <div className="select-dropdown" style={{
                            zIndex: "10", position: "relative", maxHeight: "300px",
                            overflowY: "scroll", borderBottom: "solid 1px #ced4da",
                            borderLeft: "solid 1px #ced4da", borderRight: "solid 1px #ced4da",
                        }}>
                            {this.props.options.map(option => {
                                return <SelectOption key={option[this.props.name_index]} text={option[this.props.name_text]}
                                    onClick={e => {
                                        this.props.onChange({ name_text: this.props.name_text, name_index: this.props.name_index, ...option });
                                        this.setState({ isOpen: false })
                                        e.stopPropagation();
                                    }}
                                />
                            })}

                        </div>}
                </div>
            </div>
        );
    }
}
export default SelectComponent;

class SelectOption extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="select-option" onClick={this.props.onClick}>
                {this.props.text}
            </div>
        );
    }
}