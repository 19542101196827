/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un anticipo. 
*/
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as message from "../messages/messages";
import MySelect from "../utils/MySelect";
import * as utility from "../utils/utility";
import WebSocketInstance from "../../../utils/websocket";
import * as permisos from "../../../utils/permisos/permisos";

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      nombre_select: "",
      codigo: "",
      tipo: "",
      mayor: "",
      subcuenta: "",
      detalle: "",
      descripcion: "",
      naturaleza: "",
      tpo_cuenta: "",
      nivel: 0,
      cuentas_mayor: [],
      cuentas_menor: [],
    };
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.identificativo;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "cuenta_auxiliar",
      motivo: motivo,
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleAlta = () => {
    message
      .message_confimacion("¿Desea continuar con la operación alta?", "")
      .then((res) => {
        if (res) {
          let data = {
            codigo: this.state.codigo,
            tipo: this.state.tipo,
            auxiliar: utility.valida_cuenta(
              this.state.mayor,
              this.state.subcuenta,
              this.state.nivel
            ),
            descripcion: this.state.descripcion,
            naturaleza: this.state.naturaleza,
            tpo_cuenta: this.state.tpo_cuenta,
            nivel: this.state.nivel,
          };

          const camposFaltantes = utility.valida_data(data);
          if (camposFaltantes.length > 0) {
            const mensaje = message
            .completeMessage(
              `${camposFaltantes}`
            );
            mensaje.then(() => {
              this.setState({ camposFaltantes: camposFaltantes });
            });
          } else {
            this.methodPOST_API(
              utility.url_crea_modifica_cuenta,
              "alta",
              data
            );
          }
        }
      });
  };

  handleCerrar = () => {
    this.props.toogleNuevo();
    this.setState({
      nombre_select: "",
      codigo: "",
      tipo: "",
      mayor: "",
      subcuenta: "",
      detalle: "",
      descripcion: "",
      naturaleza: "",
      tpo_cuenta: "",
      nivel: 0,
      cuentas_mayor: [],
      cuentas_menor: [],
      identificativo: ""
    });
  };
  handleNivel = (e) => {
    let nivel = parseInt(e.target.value);
    if (nivel >= 2)
      this.methodPOST_API(utility.url_pagination_cuentas, "cuentas_mayor", {
        nivel: 1,
      });
    this.setState({ nivel, mayor: "", subcuenta: "", detalle: "" });
  };
  handleMayor = (e) => {
    let mayor = e.target.value;
    if (this.state.nivel === 3)
      this.methodPOST_API(utility.url_pagination_cuentas, "cuentas_menor", {
        mayor: mayor,
        nivel: 2,
      });
    this.setState({ mayor });
  };
  handleSubcuenta = (e) => {
    let subcuenta = e.target.value;
    this.setState({ subcuenta });
  };
  handleDetalle = (e) => {
    let detalle = e.target.value;
    this.setState({ detalle });
  };
  handleTipo_cuenta = (e) => {
    let tpo_cuenta = e.target.value;
    this.setState({ tpo_cuenta });
  };
  handleNaturaleza = (e) => {
    let naturaleza = e.target.value;
    this.setState({ naturaleza });
  };
  handleTipo = (e) => {
    let tipo = e.target.value;
    this.setState({ tipo });
  };
  handleDescripcion = (e) => {
    let descripcion = e.target.value;
    this.setState({ descripcion });
  };
  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let codigo = e.target.value;
    this.setState({ codigo });
    for (let i = 0; i < this.props.codigos_sat.length; i++) {
      if (codigo.length === 0) this.suggestionSelected("");
      if (codigo === this.props.codigos_sat[i].codigo)
        this.suggestionSelected(this.props.codigos_sat[i].descripcion);
    }
  };
  suggestionSelected = (value) => {
    this.setState({
      nombre_select: value,
    });
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  /**
   * Función que permite hacer una petición de forma POST.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} data Objeto a mandar atraves del metodo POST.
   */
  methodPOST_API = (url, state, data) => {
    /*  SI SE REALIZA UNA OPERACION QUE DEBE CERRAR EL MODAL, MOSTAR UN MENSAJE Y ACTUALIZAR LA 
        TABLA PRINCIPAL ESTA ES LA ESTRCUTURA.
    
        this.props.toogleXXXXX(); -> FUNCIÓN QUE CIRRA EL MODAL.
        message.message_modal(response.data.title, response.data.text, response.status); -> MENSAJE A MOSTAR.
        this.props.update(1); -> ACTUALIZACIÓN DE LA TABLA PRINCIPAL.   */

    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "alta":
              this.setState(
                { identificativo: response.data.results.auxiliar },
                () => {
                  this.sendNotificacionHandler("crear");
                }
              );
              this.props.toogleNuevo();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            case "cuentas_mayor":
              this.setState({
                cuentas_mayor: response.data.results,
              });
              break;
            case "cuentas_menor":
              this.setState({
                cuentas_menor: response.data.results,
              });
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_nuevo}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Alta Cuenta Auxiliar
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <div className="grupo col-lg-12 p-0">
                <select
                  className="input-animated col-12 mb-2"
                  id="nivel_cuenta"
                  onChange={this.handleNivel}
                  style={{ background: "#fff", padding: "7px 10px" }}
                  required
                >
                  <option value="0">Seleccione un Nivel</option>
                  <option value="1">Mayor</option>
                  <option value="2">Subcuenta</option>
                  <option value="3">Detalle</option>
                </select>
                <span className="barra"></span>
                <br />
                <label className="label-animated" htmlFor="nivel_cuenta">
                  Nivel De Cuenta
                </label>
              </div>
            </FormGroup>
            <FormGroup className="row mb-0">
              {this.state.nivel === 1 ? (
                <div className="grupo col-lg-4 p-0">
                  <input
                    type="text"
                    className="input-animated col-12 mb-2"
                    id="cuenta_mayor"
                    maxLength="4"
                    required
                    disabled={this.state.nivel < 1 ? true : false}
                    value={this.state.mayor}
                    onChange={this.handleMayor}
                  />
                  <span className="barra"></span>
                  <br />
                  <label className="label-animated" htmlFor="cuenta_mayor">
                    Cuenta Mayor
                  </label>
                </div>
              ) : (
                <MySelect
                  bootstrap="col-lg-4 col-12"
                  id="cuenta_mayor"
                  nameLabel="Cuenta Mayor"
                  required="false"
                  disabled={this.state.nivel < 1 ? true : false}
                  onChange={this.handleMayor}
                  first_option="Seleccione una cuenta"
                >
                  {this.state.cuentas_mayor.map((cuenta) => (
                    <option
                      key={cuenta.auxiliar.slice(0, 4)}
                      value={cuenta.auxiliar.slice(0, 4)}
                    >
                      {cuenta.auxiliar.slice(0, 4)}
                    </option>
                  ))}
                </MySelect>
              )}
              <MySelect
                bootstrap="col-lg-4 col-12"
                id="subcuenta"
                nameLabel="Subcuenta"
                required="false"
                disabled={this.state.nivel <= 2 ? true : false}
                onChange={this.handleSubcuenta}
                first_option="Seleccione una subcuenta"
              >
                {this.state.cuentas_menor.map((cuenta) => (
                  <option
                    key={cuenta.auxiliar.slice(5, 8)}
                    value={cuenta.auxiliar.slice(5, 8)}
                  >
                    {cuenta.auxiliar.slice(5, 8)}
                  </option>
                ))}
              </MySelect>
              <div className="grupo col-lg-4 p-0">
                <input
                  type="text"
                  className="input-animated col-12 mb-2"
                  id="cuenta_mayor"
                  maxLength="4"
                  required
                  disabled={this.state.nivel <= 3 ? true : false}
                  value={this.state.detalle}
                  onChange={this.handleDetalle}
                />
                <span className="barra"></span>
                <br />
                <label className="label-animated" htmlFor="cuenta_mayor">
                  Detalle
                </label>
              </div>
            </FormGroup>
            <FormGroup className="row mb-0">
              <div className="grupo col-lg-6 p-0">
                <input
                  type="text"
                  className="input-animated col-12 mb-2"
                  id="codigo_cuenta"
                  onChange={this.nameGenerator}
                  value={this.state.codigo}
                  required
                />
                <span className="barra"></span>
                <br />
                <label className="label-animated" htmlFor="codigo_cuenta">
                  Código
                </label>
              </div>
              <div className="grupo col-lg-6 p-0">
                <input
                  type="text"
                  className="input-animated input-animatedd col-12 mb-2"
                  id="codigo_cuenta_descripcion"
                  onChange={this.onNameChange}
                  value={this.state.nombre_select}
                  disabled
                />
                <span className="barra"></span>
                <br />
                <label
                  className="label-animated"
                  htmlFor="codigo_cuenta_descripcion"
                >
                  Descripción De Código
                </label>
              </div>
            </FormGroup>
            <FormGroup className="row mb-0">
              <div className="grupo col-lg-4 p-0">
                <select
                  className="input-animated col-12 mb-2"
                  id="tipo_cuenta"
                  onChange={this.handleTipo_cuenta}
                  style={{ background: "#fff", padding: "7px 10px" }}
                  required
                >
                  <option value="null">Seleccione tipo de cuenta</option>
                  <option value="D">Detalle</option>
                  <option value="A">Acumulativa</option>
                </select>
                <span className="barra"></span>
                <br />
                <label className="label-animated" htmlFor="tipo_cuenta">
                  Tipo De Cuenta
                </label>
              </div>
              <div className="grupo col-lg-4 p-0">
                <select
                  className="input-animated col-12 mb-2"
                  id="naturaleza_cuenta"
                  onChange={this.handleNaturaleza}
                  style={{ background: "#fff", padding: "7px 10px" }}
                  required
                >
                  <option value="null">Seleccione una naturaleza</option>
                  <option value="A">Acreedor</option>
                  <option value="D">Deudor</option>
                </select>
                <span className="barra"></span>
                <br />
                <label className="label-animated" htmlFor="naturaleza_cuenta">
                  Naturaleza
                </label>
              </div>
              <div className="grupo col-lg-4 p-0">
                <select
                  className="input-animated col-12 mb-2"
                  id="tipo"
                  onChange={this.handleTipo}
                  style={{ background: "#fff", padding: "7px 10px" }}
                  required
                >
                  <option value="null">Seleccione un tipo</option>
                  <option value="01">Activo</option>
                  <option value="02">Pasivo</option>
                  <option value="03">Capital</option>
                  <option value="04">Ingreso</option>
                  <option value="05">Costo</option>
                  <option value="06">Gasto</option>
                  <option value="07">Orden</option>
                </select>
                <span className="barra"></span>
                <br />
                <label className="label-animated" htmlFor="tipo">
                  Tipo
                </label>
              </div>
            </FormGroup>
            <FormGroup className="row mb-0">
              <div className="grupo col-lg-12 p-0" style={{ height: "90px" }}>
                <textarea
                  className="input-animated col-12"
                  id="descripcion_cuenta"
                  onChange={this.handleDescripcion}
                  value={this.state.descripcion}
                  style={{ resize: "none" }}
                  required
                ></textarea>
                <span className="barra"></span>
                <br />
                <label className="label-animated" htmlFor="">
                  Descripción
                </label>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={this.handleAlta} disabled={
              this.state.descripcion === ""
                ? true
                : false && permisos.obtener_permiso_especifico(
                  "crear",
                  this.props.permisos
                )
            }>
            Aceptar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalAlta;
