/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la consulta de un anticipo. 
*/
import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Form, FormGroup } from 'reactstrap';
import * as utility from '../utils/utility';
import * as message from '../messages/messages';
import axios from 'axios';
import WebSocketInstance from "../../../utils/websocket";
import MyInput from '../../../utils/custom/MyInput';
import MyTextArea from '../../../utils/custom/MyTextArea';
import MySelect from '../../../utils/custom/MySelect';
import CustomSelect from '../../../utils/custom/CustomSelect';

class ModalConsulta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numero: "",
            nombre: "",
            rfc: "",
            contacto: "",
            telefono: "",
            horario_guiasI: "",
            horario_guiasF: "",
            horario_pagosI: "",
            horario_pagosF: "",
            correo: "",
            pagina: "",
            comentario: "",

            domicilio: "",
            colonia_id: "",
            colonia_text: "Seleccione una colonia",
            codigo_postal: "",
            estado: "",
            ciudad: "",

            colonias: [],
            input_disabled: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],

            data_modificacion: {},
        }
    }
    /*--------------------------------------------------------------------- */
    /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
    sendNotificacionHandler = (motivo) => {
        let folio = this.state.numero;
        const notificacionObject = {
            tipo: 0,
            aplicacion: 'consolidadora',
            motivo: motivo,
            folio: [folio]
        }
        WebSocketInstance.newNotificacion(notificacionObject);
    }
    /*--------------------------------------------------------------------- */
    handleNumero = (e) => { this.setState({ numero: e.target.value }); }
    handleNombre = (e) => {
        let nombre = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['nombre'] = nombre;
        this.setState({ nombre, data_modificacion });
    }
    handleRFC = (e) => {
        let rfc = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['rfc'] = rfc;
        this.setState({ rfc, data_modificacion });
    }
    handleContacto = (e) => {
        let contacto = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['contacto'] = contacto;
        this.setState({ contacto, data_modificacion });
    }
    handleTelefono = (e) => {
        let telefono = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['telefono'] = telefono;
        this.setState({ telefono, data_modificacion });
    }
    handleGuia_I = (e) => {
        let horario_guiasI = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['horario_guiasI'] = horario_guiasI;
        this.setState({ horario_guiasI, data_modificacion });
    }
    handleGuia_F = (e) => {
        let horario_guiasF = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['horario_guiasF'] = horario_guiasF;
        this.setState({ horario_guiasF, data_modificacion });
    }
    handlePago_I = (e) => {
        let horario_pagosI = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['horario_pagosI'] = horario_pagosI;
        this.setState({ horario_pagosI, data_modificacion });
    }
    handlePago_F = (e) => {
        let horario_pagosF = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['horario_pagosF'] = horario_pagosF;
        this.setState({ horario_pagosF, data_modificacion });
    }
    handleCorreo = (e) => {
        let correo = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['correo'] = correo;
        this.setState({ correo, data_modificacion });
    }
    handlePagina = (e) => {
        let pagina = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['pagina'] = pagina;
        this.setState({ pagina, data_modificacion });
    }
    handleComentario = (e) => {
        let comentario = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['comentario'] = comentario;
        this.setState({ comentario, data_modificacion });
    }
    handlePagina = (e) => {
        let pagina = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['pagina'] = pagina;
        this.setState({ pagina, data_modificacion });
    }
    handleConsulta = () => {
        if (this.state.numero.length === 0)
            message.message_modal("Advertencia", "Debe tener un Número de Consolidadora", 400);
        else {
            this.methodPOST_API(utility.url_consulta_consolidadoras, 'consulta_consolidadora', { numero: this.state.numero });
        }
    }
    handleDomicilio = (e) => {
        let domicilio = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['domicilio'] = domicilio;
        this.setState({ domicilio, data_modificacion });
    }
    handleColonia = (e) => {
        let estado, ciudad;
        let label_id = e.name_index;
        let label = e.name_text;
        let colonia_text = e[label];
        let colonia_id = e[label_id];
        let data_modificacion = this.state.data_modificacion;
        this.state.colonias.forEach(col => {
            if (colonia_id === col.asentamiento) {
                estado = col.estado;
                ciudad = col.ciudad;
            }
        });
        data_modificacion['colonia'] = colonia_id;
        this.setState({ colonia_text, colonia_id, estado, ciudad, data_modificacion });
    }
    handleCodigo_postal = (e) => {
        let codigo_postal = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        if (codigo_postal.length === 5)
            this.methodPOST_API(utility.url_codigo_postal, 'codigo_postal', { codigo: codigo_postal });
        data_modificacion['codigo_postal'] = codigo_postal;
        this.setState({ codigo_postal });
    }
    handleModificar = () => {
        if (this.state.numero.length === 0)
            message.message_modal("Advertencia", "Debe tener un número", 400);
        else {
            message.message_confimacion_textarea('¿Desea continuar con la operación modificar?', 'Ingrese sus observaciones ')
                .then(res => {
                    if (res) {
                        let observaciones = document.getElementById('observaciones_textarea').value;
                        let data_modificacion = this.state.data_modificacion;
                        if (Object.values(data_modificacion).length > 0) {
                            data_modificacion['numero'] = this.state.numero;
                            data_modificacion['observaciones'] = observaciones;
                            this.methodPUT_API(utility.url_crear_consolidadora, 'modificar', data_modificacion);
                            this.sendNotificacionHandler('modificar');
                        }
                        else
                            message.message_modal("Advertencia", "No hay modificaciones", 400);
                    }
                });
        }
    }
    handleCerrar = () => {
        this.setState({
            numero: "",
            nombre: "",
            rfc: "",
            contacto: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: "",
            codigo_postal: "",
            telefono: "",
            horario_guiasI: "",
            horario_guiasF: "",
            horario_pagosI: "",
            horario_pagosF: "",
            correo: "",
            pagina: "",
            comentario: "",
            direccion: "",

            input_disabled: [true, true, true, true, true, true, true, true, true, true, true, true],
            data_modificacion: {},
        });
        this.props.toogleConsulta();
    }
    methodPOST_API = (url, state, data) => {
        let url_intern = url;
        axios.post(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400) {
                    switch (state) {
                        case 'codigo_postal':
                            this.setState({
                                colonias: response.data.results
                            });
                            break;
                        case 'consulta_consolidadora':
                            console.log(response.data.results.horario_guias.slice(0, 5), response.data.results.horario_guias.slice(8, response.data.results.horario_guias.length));
                            this.setState({
                                numero: response.data.results.numero,
                                nombre: response.data.results.nombre,
                                rfc: response.data.results.rfc,
                                contacto: response.data.results.contacto,
                                domicilio: response.data.results.domicilio,
                                colonia_text: response.data.results.colonia,
                                codigo_postal: response.data.results.codigo_postal,
                                telefono: response.data.results.telefono,
                                horario_guiasI: response.data.results.horario_guias.slice(0, 5),
                                horario_guiasF: response.data.results.horario_guias.slice(8, response.data.results.horario_guias.length),
                                horario_pagosI: response.data.results.horario_pagos.slice(0, 5),
                                horario_pagosF: response.data.results.horario_pagos.slice(8, response.data.results.horario_pagos.length),
                                correo: response.data.results.correo,
                                pagina: response.data.results.pagina,
                                comentario: response.data.results.comentario,
                                input_disabled: [false, false, false, false, false, false, false, true, true, false, false, false, false, false, false, false, false]
                            }, () => {
                                this.methodPOST_API(utility.url_codigo_postal, 'codigo_postal', { codigo: response.data.results.codigo_postal });
                            });
                            setTimeout(() => {
                                for (let i = 0; i < this.state.colonias.length; i++)
                                    if (this.state.colonias[i].id_codigopostal === parseInt(this.state.codigo_postal))
                                        this.setState({ estado: this.state.colonias[i].estado, ciudad: this.state.colonias[i].ciudad })
                            }, 50);
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    methodPUT_API = (url, state, data) => {
        let url_intern = url;
        axios.put(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400) {
                    switch (state) {
                        case 'modificar':
                            this.handleCerrar();
                            message.message_modal(response.data.title, response.data.text, response.status);
                            this.props.update(1);
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    render() {
        return (
            <Modal isOpen={this.props.toogle_consulta} toggle={this.props.toogleConsulta} backdrop="static" size="xl">
                <ModalHeader toggle={this.props.toogleConsulta}>Consulta De Consolidadora</ModalHeader>
                <ModalBody>
                    <Form className="container-fluid">
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-10 col-8" nameLabel="Número de Consolidadora" id="numero_consolidadora_consulta"
                                value={this.state.numero} onChange={this.handleNumero} />
                            <Button color="primary" className="col-lg-2 col-4 mb-2" onClick={this.handleConsulta} >Consulta</Button>
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-6 col-12" nameLabel="Nombre de Consolidadora" id="nombre_consolidadora_consulta"
                                value={this.state.nombre} onChange={this.handleNombre} disabled={this.state.input_disabled[0]} />
                            <MyInput type="text" bootstrap="col-lg-6 col-12" nameLabel="RFC" id="rfc_consolidadora_consulta"
                                value={this.state.rfc} onChange={this.handleRFC} disabled={this.state.input_disabled[1]} />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-6 col-12" nameLabel="Página" id="pagina_consolidadora_consulta"
                                value={this.state.pagina} onChange={this.handlePagina} disabled={this.state.input_disabled[2]} />
                            <MyInput type="text" bootstrap="col-lg-6 col-12" nameLabel="Contacto" id="contacto_consolidadora_consulta"
                                value={this.state.contacto} onChange={this.handleContacto} disabled={this.state.input_disabled[3]} />
                        </FormGroup>

                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="domicilio_consolidadora_consulta" nameLabel="Domicilio"
                                value={this.state.domicilio} onChange={this.handleDomicilio} disabled={this.state.input_disabled[4]} />
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="cp_consolidadora_consulta" nameLabel="Código Postal"
                                value={this.state.codigo_postal} onChange={this.handleCodigo_postal} disabled={this.state.input_disabled[5]} />
                            <CustomSelect bootstrap="col-lg-4 col-12" name_text='asentamiento' name_index='asentamiento' id="colonia_consolidadora_consulta"
                                nameLabel="Colonia" value={this.state.colonia_text} onChange={this.handleColonia} options={this.state.colonias}
                                disabled={this.state.input_disabled[6]} />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-6 col-12" id="estado_consolidadora_consulta" nameLabel="Estado/Provincia/Región"
                                value={this.state.estado} disabled={this.state.input_disabled[7]} />
                            <MyInput type="text" bootstrap="col-lg-6 col-12" id="ciudad_consolidadora_consulta" nameLabel="Ciudad"
                                value={this.state.ciudad} disabled={this.state.input_disabled[8]} />
                        </FormGroup>

                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-6 col-12" nameLabel="Telefono" id="telefono_consolidadora_consulta"
                                value={this.state.telefono} onChange={this.handleTelefono} disabled={this.state.input_disabled[9]} />
                            <MyInput type="text" bootstrap="col-lg-6 col-12" nameLabel="Correo" id="correo_consolidadora_consulta"
                                value={this.state.correo} onChange={this.handleCorreo} disabled={this.state.input_disabled[10]} />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="time" bootstrap="col-lg-3 col-12" nameLabel="Horario de Guias (Desde)" id="hguiaI_consolidadora_consulta"
                                value={this.state.horario_guiasI} onChange={this.handleGuia_I} disabled={this.state.input_disabled[11]} />
                            <MyInput type="time" bootstrap="col-lg-3 col-12" nameLabel="Horario de Guias (Hasta)" id="hguiaF_consolidadora_consulta"
                                value={this.state.horario_guiasF} onChange={this.handleGuia_F} disabled={this.state.input_disabled[12]} />
                            <MyInput type="time" bootstrap="col-lg-3 col-12" nameLabel="Horario de Pago (Desde)" id="hpagoI_consolidadora_consulta"
                                value={this.state.horario_pagosI} onChange={this.handlePago_I} disabled={this.state.input_disabled[13]} />
                            <MyInput type="time" bootstrap="col-lg-3 col-12" nameLabel="Horario de Pago (Hasta)" id="hpagoF_consolidadora_consulta"
                                value={this.state.horario_pagosF} onChange={this.handlePago_F} disabled={this.state.input_disabled[14]} />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyTextArea bootstrap="col-12" nameLabel="Comentario" id="observaciones_consolidadora_consulta"
                                value={this.state.comentario} onChange={this.handleComentario} disabled={this.state.input_disabled[15]} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={this.handleModificar}>Modificar</Button>
                    <Button color="primary" onClick={this.handleCerrar}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default ModalConsulta;