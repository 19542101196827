export const url_historico_alerta = '/DjangoWeb/AlertasApp/consulta/paginacion/historico';


export const Burbuja_Alerta = (arreglo) => {
    var lista = arreglo;
    var n, i, k, aux;
    n = lista.length;
    for (k = 1; k < n; k++) {
        for (i = 0; i < (n - k); i++) {
            if (lista[i].folio > lista[i + 1].folio) {
                aux = lista[i];
                lista[i] = lista[i + 1];
                lista[i + 1] = aux;
            }
        }
    }
    return (lista);
}

/**
 * 
 * @param {number} cliente Número de cliente.
 * @param {array} arreglo Arreglo de anticipos.
 */
 export const repeticion_alerta = (alerta, arreglo) => {
    let contador = 0;
    for (let i = 0; i < arreglo.length; i++)
        if (alerta === arreglo[i].folio)
            contador++;
    return contador;
}