/* 
    Autor: Alexis Yael Hernández Grimaldo
    Descipción: Acciones que se utilizaran para pdder interpretar con redux la estancia de una sesión en la aplicación.
*/

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_END = 'AUTH_END';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const SET_MESSAGES = 'SET_MESSAGES';

export const GET_CHATS_SUCCESS = 'GET_CHATS_SUCCESS';