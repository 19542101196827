/* 
Componente que genera un input de tipo textarea orientado al diseño del sistema
*/
import React from "react";
import "../../../assets/style.css";

const MyTextArea = (props) => {
  return (
    <div className={`grupo p-0 ${props.bootstrap}`}>
      <textarea
        className="input-animated col-12 mb-2"
        id={props.id}
        disabled={props.disabled}
        style={{ resize: "none" }}
        required={props.required === "false" ? false : true}
      ></textarea>
      <span className="barra"></span>
      <br />
      <label className="label-animated" htmlFor={props.id}>
        {props.nameLabel}
      </label>
    </div>
  );
};
export default MyTextArea;
