/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente Principal para el Modal de las distintas opciones de operación para solicitud de anticipos. 
*/
import React, { Component } from "react";
import { ButtonGroup, Button } from "reactstrap";
import ModalAlta from "./ModalAlta";
import ModalConsulta from "./ModalConsulta";
import * as permisos from "../../../utils/permisos/permisos";

class ModalAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toogle_nuevo: false,
      toogle_consulta: false,
    };
    this.toogleNuevo = this.toogleNuevo.bind(this);
    this.toogleConsulta = this.toogleConsulta.bind(this);
  }
  toogleNuevo = () => {
    this.setState((prevState) => ({ toogle_nuevo: !prevState.toogle_nuevo }));
  };
  toogleConsulta = () => {
    this.setState((prevState) => ({
      toogle_consulta: !prevState.toogle_consulta,
    }));
  };
  render() {
    return (
      <div className="col-lg-3 col-sm-12 d-flex justify-content-center flex-column mb-2 my-md-2">
        <ButtonGroup className="mb-1">
          {/* <Button
            color="primary"
            onClick={this.toogleNuevo}
            disabled={permisos.obtener_permiso_especifico(
              "crear",
              this.props.permisos
            )}
          >
            Nuevo
          </Button> */}
          <Button color="success" onClick={this.toogleConsulta}>
            Consulta
          </Button>
        </ButtonGroup>
        <ModalAlta
          toogle_nuevo={this.state.toogle_nuevo}
          toogleNuevo={this.toogleNuevo}
          update={this.props.update}
          codigos_sat={this.props.codigos_sat}
        />
        <ModalConsulta
          toogle_consulta={this.state.toogle_consulta}
          toogleConsulta={this.toogleConsulta}
          update={this.props.update}
          permisos={this.props.permisos}
          codigos_sat={this.props.codigos_sat}
        />
      </div>
    );
  }
}
export default ModalAction;
