/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un anticipo. 
*/
import { faEye, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  CustomInput,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";
import MyInput from "../../../utils/custom/MyInput";
import MySelect from "../../../utils/custom/MySelect";
import MyTextArea from "../../../utils/custom/MyTextArea";
import WebSocketInstance from "../../../utils/websocket";
import CustomSelect from "../../../utils/custom/CustomSelect";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalConsulta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      folio: "",
      fecha_alta: "",
      tipo_alta: "",
      aduana: "",
      patente_id: "",
      patente_text: "Seleccione una patente",
      numero_select: "",
      guia_principal: "",
      tipo_flete: "",
      importe_flete: "",
      desconsolidacion: "",
      numero_vuelo: "",
      numero_select_con: "",
      fecha_llegada: "",
      fecha_recoleccion: "",
      hora_recoleccion: "",
      quien_notifica: "",
      fecha_entrega: "",
      hora_entrega: "",
      empleado_entrega: "",
      consignada: "",
      empleado_seguimiento: "",
      notificaciones: "",
      observaciones: "",
      nombre_select: "",
      nombre_select_con: "",
      status: "",
      input_disabled: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],

      enable_pdf: false,
      data_modificacion: {},
      patentes: [],
      aduana_select: [],
      nombre_suggestions: [],
      nombre_suggestions_con: [],
    };
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.folio;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "notificacion_embarque",
      motivo: motivo,
      folio: [folio],
    };
    if (this.props.on_cliente === false)
      notificacionObject["cliente"] = [parseInt(this.state.numero_select)];
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_patentes, "patentes");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleCerrar = () => {
    this.setState({
      folio: "",
      fecha_alta: "",
      tipo_alta: "",
      patente: "",
      aduana: "",
      numero_select: "",
      guia_principal: "",
      tipo_flete: "",
      importe_flete: "",
      desconsolidacion: "",
      numero_vuelo: "",
      numero_select_con: "",
      fecha_llegada: "",
      fecha_recoleccion: "",
      hora_recoleccion: "",
      quien_notifica: "",
      fecha_entrega: "",
      hora_entrega: "",
      empleado_entrega: "",
      consignada: "",
      empleado_seguimiento: "",
      notificaciones: "",
      observaciones: "",
      nombre_select: "",
      nombre_select_con: "",
      status: "",
      input_disabled: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],

      enable_pdf: false,
      data_modificacion: {},
      aduana_select: [],
      nombre_suggestions: [],
      nombre_suggestions_con: [],
    });
    this.props.toogleConsulta();
  };
  handleConsulta = () => {
    if (this.state.folio.length === 0)
      message.message_modal("Advertencia", "Debe tener un folio", 400);
    else {
      let data = { folio: this.state.folio };
      if (this.props.on_cliente !== false)
        data["cliente"] = this.props.on_cliente;
      this.methodPOST_API(utility.url_consulta_alerta, "consulta", data);
      this.setState({ enable_pdf: true }, () => {
        this.methodPDF_API(
          utility.url_pdf_alerta,
          "consulta_alerta",
          this.state.folio
        );
      });
    }
  };
  handleModificar = () => {
    if (this.state.folio.length === 0)
      message.message_modal("Advertencia", "Debe tener un folio", 400);
    else {
      message
        .message_confimacion_textarea(
          "¿Desea continuar con la operación modificar?",
          "Ingrese sus observaciones"
        )
        .then((res) => {
          if (res) {
            let observaciones = document.getElementById(
              "observaciones_textarea"
            ).value;
            let data_modificacion = this.state.data_modificacion;
            if (Object.values(data_modificacion).length > 0) {
              data_modificacion["folio"] = this.state.folio;
              data_modificacion["observaciones"] = observaciones;
              this.methodPUT_API(
                utility.url_alta_alerta,
                "modificar",
                data_modificacion
              );
              this.sendNotificacionHandler("modificar");
            } else
              message.message_modal(
                "Advertencia",
                "No hay modificaciones",
                400
              );
          }
        });
    }
  };
  handleNotificar = () => {
    if (this.state.folio.length === 0)
      message.message_modal("Advertencia", "Debe tener un folio", 400);
    else {
      message
        .message_confimacion_textarea(
          "¿Desea continuar con la operación notificar?",
          "Ingrese sus observaciones"
        )
        .then((res) => {
          if (res) {
            let observaciones = document.getElementById(
              "observaciones_textarea"
            ).value;
            if (observaciones.length === 0)
              message.message_modal(
                "Advertencia",
                "Campo observaciones vacio",
                400
              );
            else {
              this.methodPOST_API(utility.url_notifica_alerta, "notificar", {
                alertas: [this.state.folio],
                observaciones: observaciones,
              });
              this.sendNotificacionHandler("notificar");
            }
          }
        });
    }
  };
  handleCancelar = () => {
    if (this.state.folio.length === 0)
      message.message_modal("Advertencia", "Debe tener un folio", 400);
    else {
      message
        .message_confirmacion_password(
          "¿Desea continuar con la operación cancelar?",
          "Ingrese su contraseña y observaciones"
        )
        .then((res) => {
          if (res === true) {
            let mov = {
              user: document.getElementById("usuario_modificador").value,
              pass: document.getElementById("contraseña_modificador").value,
              motivo: document.getElementById("observaciones_textarea").value,
            };
            if (mov.motivo.length === 0)
              message.message_modal(
                "Completar el campo:",
                "Motivo de Modificación",
                400
              );
            else
              axios
                .post(utility.url_verify, {
                  usuario: mov.user,
                  password: mov.pass,
                })
                .then((response) => {
                  if (response.status >= 200 && response.status < 400) {
                    this.methodPOST_API(utility.url_cancela_alerta, "cancela", {
                      alerta: this.state.folio,
                      observaciones: mov.motivo,
                    });
                    this.sendNotificacionHandler("cancelar");
                  }
                })
                .catch((error) => {
                  if (error.response)
                    message.message_modal(
                      error.response.data.title,
                      error.response.data.text,
                      error.response.status
                    );
                });
          }
        });
    }
  };
  getFolio = (e) => {
    this.setState({
      folio: e.target.value,
    });
  };
  handleAduana = (e) => {
    let aduana = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    let aduana_select = [];
    for (let i = 0; i < this.state.patentes.length; i++) {
      if (this.state.patentes[i].aduana === aduana)
        aduana_select.push(this.state.patentes[i]);
    }
    data_modificacion["aduana"] = aduana;
    this.setState({ aduana, data_modificacion, aduana_select });
  };
  handlePatente = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let patente_text = e[label];
    let patente_id = e[label_id];
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["patente"] = patente_id;
    this.setState({ patente_id, patente_text, data_modificacion });
  };
  handleGuia = (e) => {
    let guia_principal = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["guia_principal"] = guia_principal;
    this.setState({ guia_principal, data_modificacion });
  };
  handleTipo_flete = (e) => {
    let tipo_flete = e.target.checked;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["tipo_flete"] = tipo_flete ? 1 : 0;
    this.setState({ tipo_flete: tipo_flete ? 1 : 0, data_modificacion });
  };
  handleImporte = (e) => {
    let importe_flete = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["importe_flete"] = importe_flete;
    this.setState({ importe_flete, data_modificacion });
  };
  handleEmpleado_entrega = (e) => {
    let empleado_entrega = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["empleado_entrega"] = empleado_entrega;
    this.setState({ empleado_entrega, data_modificacion });
  };
  handleVuelo = (e) => {
    let numero_vuelo = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["numero_vuelo"] = numero_vuelo;
    this.setState({ numero_vuelo, data_modificacion });
  };
  handleObservaciones = (e) => {
    let observaciones = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["observaciones"] = observaciones;
    this.setState({ observaciones, data_modificacion });
  };
  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "patentes":
              if (this._isMounted)
                this.setState({
                  patentes: response.data.results,
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPDF_API = (url, state, num) => {
    let url_intern = url + `${num}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta_alerta":
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);
              document.getElementById("dowload_pdf_consulta_alerta").href =
                fileURL;
              document.getElementById("window_pdf_consulta_alerta").href =
                fileURL;
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta":
              this.setState(
                {
                  fecha_alta: response.data.results.fecha_alta,
                  tipo_alta: response.data.results.tipo_alta,
                  aduana: response.data.results.aduana,
                  patente_text: response.data.results.patente,
                  patenet_id: response.data.results.patente,
                  numero_select: response.data.results.cliente,
                  guia_principal: response.data.results.guia_principal,
                  tipo_flete: response.data.results.tipo_flete,
                  importe_flete: response.data.results.importe_flete,
                  desconsolidacion: response.data.results.desconsolidacion,
                  numero_vuelo: response.data.results.numero_vuelo,
                  numero_select_con: response.data.results.consolidadora,
                  fecha_llegada: response.data.results.fecha_llegada,
                  fecha_recoleccion: response.data.results.fecha_recoleccion,
                  hora_recoleccion: response.data.results.hora_recoleccion,
                  quien_notifica: response.data.results.quien_notifica,
                  fecha_entrega: response.data.results.fecha_entrega,
                  hora_entrega: response.data.results.hora_entrega,
                  empleado_entrega: response.data.results.empleado_entrega,
                  consignada: response.data.results.consignada,
                  empleado_seguimiento:
                    response.data.results.empleado_seguimiento,
                  notificaciones: response.data.results.notificaciones,
                  observaciones: response.data.results.observaciones,
                  status: response.data.results.status,
                  nombre_select: utility.get_name_cliente(
                    response.data.results.cliente,
                    this.props.clientes
                  ),
                  nombre_select_con: utility.get_name_consolidadoras(
                    response.data.results.consolidadora,
                    this.props.consolidadoras
                  ),
                },
                () => {
                  let aduana_select = [];
                  for (let i = 0; i < this.state.patentes.length; i++)
                    if (this.state.patentes[i].aduana === this.state.aduana)
                      aduana_select.push(this.state.patentes[i]);
                  this.setState({ aduana_select });
                }
              );
              if (this.state.status === "A") {
                if (this.props.on_cliente !== null)
                  this.setState({
                    input_disabled: [
                      true,
                      true,
                      false,
                      false,
                      false,
                      false,
                      false,
                      true,
                      false,
                      false,
                      true,
                      false,
                      true,
                      true,
                      true,
                    ],
                  });
                else
                  this.setState({
                    input_disabled: [
                      false,
                      false,
                      false,
                      false,
                      false,
                      false,
                      false,
                      true,
                      false,
                      false,
                      true,
                      false,
                      true,
                      true,
                      true,
                    ],
                  });
              } else
                this.setState({
                  input_disabled: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                  ],
                });
              let options = document.getElementById(
                "patente_alerta_consulta"
              ).options;
              for (let i = 0; i < options.length; i++)
                if (options[i].innerText === this.state.patente)
                  document.getElementById("patente_alerta_consulta").value =
                    options[i].value;
              break;
            case "notificar":
              this.handleCerrar();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            case "cancela":
              this.handleCerrar();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPUT_API = (url, state, data) => {
    let url_intern = url;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "modificar":
              this.handleCerrar();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") text_add = "";
    else if (numero_cliente.length <= 4) {
      switch (numero_cliente.length) {
        case 1:
          text_add = "000";
          text_add += numero_cliente;
          break;
        case 2:
          text_add = "00";
          text_add += numero_cliente;
          break;
        case 3:
          text_add = "0";
          text_add += numero_cliente;
          break;
        case 4:
          text_add = numero_cliente;
          break;
        default:
          return null;
      }
    } else text_add = "";
    for (let i = 0; i < this.props.clientes.length; i++) {
      if (text_add.length === 0) {
        document.getElementById("nombre_cliente_alerta_consulta").value = "";
        this.suggestionSelected("");
        break;
      }
      if (text_add === this.props.clientes[i].numero) {
        document.getElementById("nombre_cliente_alerta_consulta").value =
          this.props.clientes[i].nombre;
        this.suggestionSelected(this.props.clientes[i].nombre);
        break;
      }
    }
  };
  suggestionSelected = (value) => {
    let number = utility.get_number_cliente(value, this.props.clientes);
    let data_modificacion = this.state.data_modificacion;
    if (number === undefined) number = "";
    document.getElementById("numero_cliente_alerta_consulta").value = number;
    data_modificacion["cliente"] = number;
    this.setState({
      nombre_select: value,
      numero_select: number,
      nombre_suggestions: [],
      data_modificacion,
    });
  };
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_nombres_clientes(this.props.clientes)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      nombre_suggestions: suggestions,
      nombre_select: value,
    });
  };
  renderSuggestion = () => {
    if (this.state.nombre_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de consolidadora dependiendo su número ------ */
  nameGeneratorCon = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") text_add = "";
    else if (numero_cliente.length <= 4) {
      text_add += numero_cliente;
    } else text_add = "";
    for (let i = 0; i < this.props.consolidadoras.length; i++) {
      if (text_add.length === 0) {
        document.getElementById("nombre_consolidadora_alerta_consulta").value =
          "";
        this.suggestionSelectedCon("");
        break;
      }
      if (text_add === this.props.consolidadoras[i].numero) {
        document.getElementById("nombre_consolidadora_alerta_consulta").value =
          this.props.consolidadoras[i].nombre;
        this.suggestionSelectedCon(this.props.consolidadoras[i].nombre);
        break;
      }
    }
  };
  suggestionSelectedCon = (value) => {
    let number = utility.get_number_consolidaodras(
      value,
      this.props.consolidadoras
    );
    let data_modificacion = this.state.data_modificacion;
    if (number === undefined) number = "";
    document.getElementById("numero_consolidadora_alerta_consulta").value =
      number;
    data_modificacion["consolidadora"] = number;
    this.setState({
      nombre_select_con: value,
      numero_select_con: number,
      nombre_suggestions_con: [],
      nombre_suggestions: [],
      data_modificacion,
    });
  };
  onNameChangeCon = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_nombres_consolidadoras(this.props.consolidadoras)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      nombre_suggestions_con: suggestions,
      nombre_select_con: value,
    });
  };
  renderSuggestionCon = () => {
    if (this.state.nombre_suggestions_con.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions_con.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelectedCon(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de consolidadora dependiendo su número ------ */
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Consulta Notificación
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-10 col-8"
                nameLabel="Folio"
                id="numero_folio_consulta"
                value={this.state.folio}
                onChange={this.getFolio}
              />
              <Button
                color="primary"
                className="col-lg-2 col-4 mb-2"
                onClick={this.handleConsulta}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="numero_cliente_alerta_consulta"
                nameLabel="Número de Cliente"
                onChange={this.nameGenerator}
                value={this.state.numero_select}
                disabled={this.state.input_disabled[0]}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="nombre_cliente_alerta_consulta"
                nameLabel="Nombre de Cliente"
                onChange={this.onNameChange}
                value={this.state.nombre_select}
                disabled={this.state.input_disabled[1]}
              />
              {this.renderSuggestion()}
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="numero_consolidadora_alerta_consulta"
                nameLabel="Número de Consolidadora"
                onChange={this.nameGeneratorCon}
                value={this.state.numero_select_con}
                disabled={this.state.input_disabled[2]}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="nombre_consolidadora_alerta_consulta"
                nameLabel="Nombre de Consolidadora"
                onChange={this.onNameChangeCon}
                value={this.state.nombre_select_con}
                disabled={this.state.input_disabled[3]}
              />
              {this.renderSuggestionCon()}
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                id="aduana_alerta_consulta"
                nameLabel="Aduana"
                onChange={this.handleAduana}
                value={this.state.aduana}
                disabled={this.state.input_disabled[4]}
              />
              <CustomSelect
                bootstrap="col-lg-3 col-12"
                name_text="patente"
                name_index="patente"
                id="patente_alerta_consulta"
                nameLabel="Patente"
                disabled={this.state.input_disabled[5]}
                value={this.state.patente_text}
                onChange={this.handlePatente}
                options={this.state.aduana_select}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-6"
                id="importe_alerta_consulta"
                nameLabel="Importe Flete"
                onChange={this.handleImporte}
                value={this.state.importe_flete}
                disabled={this.state.input_disabled[6]}
              />
              <div className="col-6 col-lg-2 p-0 pb-1 d-flex justify-content-center align-items-center">
                <CustomInput
                  type="checkbox"
                  id="tipo_flete_alerta_consulta"
                  label="Tipo Flete"
                  onChange={this.handleTipo_flete}
                  checked={this.state.tipo_flete === 1 ? true : false}
                  disabled={this.state.input_disabled[7]}
                />
              </div>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                id="tipo_alta_alerta_consulta"
                nameLabel="Tipo alta"
                value={this.state.tipo_alta}
                disabled={this.state.input_disabled[7]}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                id="empelado_segumiento_alerta_consulta"
                nameLabel="Empleado entrega"
                onChange={this.handleEmpleado_entrega}
                value={this.state.empleado_entrega}
                disabled={this.state.input_disabled[8]}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="guia_alerta_consulta"
                nameLabel="Guía principal"
                onChange={this.handleGuia}
                value={this.state.guia_principal}
                disabled={this.state.input_disabled[9]}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                id="desconsolidacion_alerta_consulta"
                nameLabel="Desconsolidación"
                value={this.state.desconsolidacion}
                disabled={this.state.input_disabled[10]}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                id="numero_vuelo_alerta_consulta"
                nameLabel="Número de Vuelo"
                onChange={this.handleVuelo}
                value={this.state.numero_vuelo}
                disabled={this.state.input_disabled[11]}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-6"
                id="notifica_alerta_consulta"
                nameLabel="Notifica"
                value={this.state.quien_notifica}
                disabled={this.state.input_disabled[12]}
              />
              <div className="col-6 col-lg-3 p-0 pb-1 d-flex justify-content-center align-items-center">
                <CustomInput
                  type="checkbox"
                  id="consiganada_alerta_consulta"
                  label="Consignada"
                  checked={this.state.consignada === 1 ? true : false}
                  disabled={this.state.input_disabled[13]}
                />
              </div>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyTextArea
                bootstrap="col-12"
                nameLabel="Observaciones"
                id="observaciones_alerta_consulta"
                onChange={this.handleObservaciones}
                value={this.state.observaciones}
                required
                disabled={this.state.input_disabled[14]}
              />
            </FormGroup>
            <div className="d-flex justify-content-center">
              <LinkD
                href="#"
                id="dowload_pdf_consulta_alerta"
                download={`Notificación de embarque ${this.state.folio}`}
              />
              <LinkV
                href="#"
                id="window_pdf_consulta_alerta"
                target={this.state.enable_pdf ? "_blank" : "_self"}
                className="ml-5"
              />
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="success"
            className="mr-md-auto"
            onClick={this.handleNotificar}
            disabled={
              this.state.status === "A" &&
              !utility.obtener_permiso_especifico(
                "notificar",
                this.props.permisos
              )
                ? false
                : true
            }
          >
            Notificar
          </Button>
          <Button
            color="danger"
            onClick={this.handleCancelar}
            disabled={
              this.state.status === "A" &&
              !utility.obtener_permiso_especifico(
                "cancelar",
                this.props.permisos
              )
                ? false
                : true
            }
          >
            Cancelar
          </Button>
          <Button
            color="info"
            onClick={this.handleModificar}
            disabled={
              this.state.status === "A" &&
              !utility.obtener_permiso_especifico(
                "modificar",
                this.props.permisos
              )
                ? false
                : true
            }
          >
            Modificar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalConsulta;
