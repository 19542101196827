/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la consulta de una patente. 
*/
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Badge,
} from "reactstrap";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";
import MyDropzone from "../utils/MyDropzone";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      archivo_select: [],
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  /* --------------- Funciones que actualizan las variables de los inputs --------------- */
  handleCerrar = () => {
    this.props.toogleAlta();
    this.clearState();
  };
  handleArchivo = (file) => {
    let archvios_arr = this.state.archivo_select;
    archvios_arr[0] = file;
    this.setState({ archivo_select: archvios_arr });
  };
  handleEliminaArchivo = (e) => {
    let archivos_arr = this.state.archivo_select;
    archivos_arr.splice(parseInt(e.target.id), 1);
    this.setState({ archivo_select: archivos_arr });
  };
  /* --------------- Fin de funciones que actualizan las variables de los inputs --------------- */

  /* --------------- Funciones de eventos que generan una petición --------------- */

  handleAlta = () => {
    let data = {
      archivo: this.state.archivo_select[0],
    };
    message
      .message_confimacion("¿Desea continuar con la operación alta?", "")
      .then((res) => {
        if (res)
          if (
            data.archivo === "" ||
            data.archivo === null ||
            data.archivo === undefined
          )
            message.message_modal(
              "Falta completar los siguientes campos:",
              "Archivo",
              "warning"
            );
          else
            axios
              .post(utility.url_alta_pedimento, this.jsonToFormData(data), {
                headers: { "Content-type": "multipart/form-data" },
              })
              .then((response) => {
                this.handleCerrar();
                message.message_modal(
                  response.data.title,
                  response.data.text,
                  response.status
                );
                this.props.update(1);
              })
              .catch((error) => {
                if (error.response)
                  message.message_modal(
                    error.response.data.title,
                    error.response.data.text,
                    error.response.status
                  );
              });
      });
  };
  /* --------------- Fin de funciones de eventos que generan una petición --------------- */
  buildFormData(formData, data, parentKey) {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;
      formData.append(parentKey, value);
    }
  }
  jsonToFormData(data) {
    const formData = new FormData();
    this.buildFormData(formData, data);
    return formData;
  }
  clearState = () => {
    this.setState({
      numero: "",
      aduana: "",
      patente: "",
      nombre: "",
      rfc: "",
      curp: "",
      tipo_patente: "",
      domicilio: "",
      colonia: "",
      codigo_postal: "",
      estado: "",
      ciudad: "",

      contacto: "",
      telefono: "",
      correo: "",

      contactos: [],
    });
  };
  /**
   * Función que permite hacer una petición de forma POST.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} data Objeto a mandar atraves del metodo POST.
   */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "codigo_postal":
              if (this._isMounted)
                this.setState({
                  colonias: response.data.results,
                });
              break;
            case "alta":
              this.props.toogleAlta();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_alta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>Alta Pedimento</ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0 mt-2 mt-lg-0">
              <MyDropzone handleArchivo={this.handleArchivo} />
              <div className="col-12 my-2 d-flex justify-content-center flex-wrap">
                {this.state.archivo_select.map((archivo, index) => (
                  <Badge color="light" className="border">
                    {archivo.name}&nbsp;
                    <spam
                      style={{ cursor: "pointer" }}
                      id={index}
                      onClick={this.handleEliminaArchivo}
                    >
                      &times;
                    </spam>
                  </Badge>
                ))}
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" className="mr-2" onClick={this.handleAlta}>
            Aceptar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalAlta;
