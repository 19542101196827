import React from 'react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NotFound400 = (props) => {
    return (
        <div className="d-flex justify-content-center align-items-center abs-center">
            <h1><FontAwesomeIcon icon={faExclamationTriangle} /> </h1>
            <h2>Página no encontrada.</h2>
        </div>
    );
}
export default NotFound400;