/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente general de la aplicación anticipos. 
*/
import React, { Component } from "react";
import SearchBar from "./SearchBar";
import TableIngresos from "./Table";
import axios from "axios";
import * as message from "../../utils/messages/message";
import * as utility from "./utils/utility";
import * as permisos from "../../utils/permisos/permisos";

class Ingresos extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      actualizar: 0,
      lista_phxcdc: [],
      clientes: [],
      ingresos: [],
      formas_pagos: [],
      tipos_usos: [],
      phxcdc: [],
      permisos: permisos.obtener_permisos_by_app(
        "cuentasgastos",
        localStorage.getItem("perm")
      ),
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_consulta_clientes_lista, "clientes");
    this.methodGET_API(utility.url_consulta_ingresos_lsita, "ingresos");
    this.methodGET_API(utility.url_consulta_formas_pago, "formas_pagos");
    this.methodGET_API(utility.url_consulta_tipos_usos, "tipos_usos");
    this.methodGET_API(utility.url_consulta_phxcdc_catalogo, "phxcdc");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "lista_phxcdc":
              if (this._isMounted === true)
                this.setState({ lista_phxcdc: response.data.results });
              break;
            case "clientes":
              if (this._isMounted === true)
                this.setState({ clientes: response.data.results });
              break;
            case "ingresos":
              if (this._isMounted === true)
                this.setState({ ingresos: response.data.results });
              break;
            case "formas_pagos":
              if (this._isMounted === true)
                this.setState({ formas_pagos: response.data.results });
            case "tipos_usos":
              if (this._isMounted === true)
                this.setState({ tipos_usos: response.data.results });
            case "phxcdc":
              if (this._isMounted === true)
                this.setState({ phxcdc: response.data.results });
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  update = (cambio) => {
    this.setState({ actualizar: cambio });
  };
  render() {
    return (
      <div className="container-fluid">
        <SearchBar
          update={this.update}
          clientes={this.state.clientes}
          ingresos={this.state.ingresos}
          formas_pagos={this.state.formas_pagos}
          tipos_usos={this.state.tipos_usos}
          phxcdc={this.state.phxcdc}
          permisos={this.state.permisos}
        />
        <TableIngresos
          update={this.update}
          actualizar={this.state.actualizar}
        />
      </div>
    );
  }
}
export default Ingresos;
