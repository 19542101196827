import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormText } from 'reactstrap';

function MyDropzone(props) {
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0)
      props.handleArchivo(acceptedFiles[0]);
  }, [props]);

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    accept: '.png, .jpg',
    disabled: props.disabled ? props.disabled : false
  });
  return (
    <div {...getRootProps()} className="col-12 dropzone-container">
      <input {...getInputProps()} id="archivos" />
      <p className="mb-0 text-center">{
        isDragActive ?
          'Suelte sus archivos aquí' :
          'Seleccione o arraste sus archivos'
      }<br />
        <FormText color="muted" className="d-block">
          Sólo archivos con extensión .png, .jpg y jpeg son aceptados
        </FormText>
      </p>
    </div>

  )
}
export default MyDropzone;