import React from 'react';
import '../../../assets/style.css';

const MyInput = (props) => {
    return (
        <div className={`grupo p-0 ${props.bootstrap}`}>
            <input className={`input-animated ${props.classInput} col-12 mb-2`} type={props.type} id={props.id}
                disabled={props.disabled} value={props.value} defaultValue={props.defaultValue}
                maxLength={props.maxLength} required={props.required === "false" ? false : true} onChange={props.onChange} />
            <span className="barra"></span>
            <br /><label className="label-animated" htmlFor={props.id}>{props.nameLabel}</label>
        </div>
    );
}
export default MyInput;