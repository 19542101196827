import React, { Component } from 'react';
import "../../../assets/style.css";

class AutocompleteInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: props.value || '',
      filteredOptions: [],
      showOptions: false,
      isOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ inputValue: this.props.value });
    }
  }

  handleChange = (e) => {
    const inputValue = e.target.value;
    const filteredOptions = this.props.options.filter(option =>
      option[this.props.name_text].toLowerCase().includes(inputValue.toLowerCase())
    );

    this.setState({
      inputValue,
      filteredOptions,
      showOptions: true,
      isOpen: true
    });
  };

  handleOptionClick = (option) => {
    const inputValue = option[this.props.name_text];
    this.setState({
      inputValue,
      showOptions: false,
      isOpen: false
    });
    this.props.onSelect(option);
  };

  handleDropdownClick = () => {
    if (this.props.disabled) {
      this.setState({ isOpen: false });
    } else {
      this.setState((prevState) => ({
        isOpen: !prevState.isOpen
      }));
    }
  };

  render() {
    const { inputValue, filteredOptions, showOptions, isOpen } = this.state;

    return (
      <div className={`grupo p-0 ${this.props.bootstrap}`}>
        <input
          className={`input-animated col-12 mb-2`}
          type="text"
          id={this.props.id}
          value={inputValue}
          onChange={this.handleChange}
          placeholder=""
          required={this.props.required}
        />
        <span className="barra"></span>
        <br />
        <label className="label-animated" htmlFor={this.props.id}>
          {this.props.nameLabel}
        </label>
        {isOpen && showOptions && filteredOptions.length > 0 && (
            <div className="select-dropdown" style={{
              zIndex: "10", position: "absolute", maxHeight: "300px",
              overflowY: "scroll", borderBottom: "solid 1px #ced4da",
              borderLeft: "solid 1px #ced4da", borderRight: "solid 1px #ced4da",
              width: "100%"
            }}>
              {filteredOptions.map((option, index) => (
                <SelectOption key={index} text={option[this.props.name_text]} onClick={() => this.handleOptionClick(option)} />
              ))}
            </div>
          )}
      </div>
    );
  }
}

const SelectOption = ({ text, onClick }) => {
    return (
      <div className="select-option" onClick={onClick}>
        {text}
      </div>
    );
  };

export default AutocompleteInput;
