/*
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la operación de recolección de guias.
*/
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Collapse,
  Card,
  CardBody,
  Table,
  FormGroup,
  Alert,
  Badge,
  Spinner,
  Form,
  CustomInput,
  ModalFooter,
} from "reactstrap";
import { faEye, faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import swal from "@sweetalert/with-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyDropzone from "../utils/Second/MyDropzone";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";
import MyInput from "../../../utils/custom/MyInput";
import MyTextArea from "../../../utils/custom/MyTextArea";
import WebSocketInstance from "../../../utils/websocket";

class ModalOperacion extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      toogle_recoleccion: false,
      toogle_llegada: false,
      toogle_entrega: false,
      toogle_previo: false,

      archivo_png: null,
      archivo_jpg: null,

      guiaPrevio: "",
      archivo_select: [],
      archivos: [],
      subir_imagen: false,

      nuevoProducto: "",
      numParte: "",
      observaciones: "",
      lote: "",
      auxiliar: "",
      fraccion: "",
      referenciaPrevio: "",
      documento: [],
    };
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folios = [];
    let clientes = [];
    if (motivo === "llegar") {
      this.props.checked_llegada.forEach((data) => folios.push(data.folio));
      this.props.checked_llegada.forEach((data) =>
        clientes.push(parseInt(data.cliente))
      );
    } else if (motivo === "recolectar") {
      this.props.checked_recolectar.forEach((data) => folios.push(data.folio));
      this.props.checked_recolectar.forEach((data) =>
        clientes.push(parseInt(data.cliente))
      );
    } else {
      this.props.checked_entrega.forEach((data) => folios.push(data.folio));
      this.props.checked_entrega.forEach((data) =>
        clientes.push(parseInt(data.cliente))
      );
    }
    const notificacionObject = {
      tipo: 0,
      aplicacion: "notificacion_embarque",
      motivo: motivo,
      folio: folios,
    };
    if (this.props.on_cliente === false)
      notificacionObject["cliente"] = clientes;
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  toogleRecoleccion = () => {
    this.setState((prevState) => ({
      toogle_recoleccion: !prevState.toogle_recoleccion,
      toogle_llegada: false,
      toogle_entrega: false,
      toogle_previo: false,
    }));
  };
  toogleLlegada = () => {
    this.setState((prevState) => ({
      toogle_llegada: !prevState.toogle_llegada,
      toogle_recoleccion: false,
      toogle_entrega: false,
      toogle_previo: false,
    }));
  };
  toogleEntrega = () => {
    this.setState((prevState) => ({
      toogle_entrega: !prevState.toogle_entrega,
      toogle_recoleccion: false,
      toogle_llegada: false,
      toogle_previo: false,
    }));
  };
  tooglePrevio = () => {
    this.setState((prevState) => ({
      toogle_previo: !prevState.toogle_previo,
      toogle_recoleccion: false,
      toogle_llegada: false,
      toogle_entrega: false,
    }));
  };
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCerrar = () => {
    this.setState({
      toogle_recoleccion: false,
      toogle_llegada: false,
      toogle_entrega: false,
      toogle_previo: false,

      archivo_png: null,
      archivo_jpg: null,

      guiaPrevio: "",
      archivo_select: [],
      archivos: [],
      subir_imagen: false,

      nuevoProducto: "",
      numParte: "",
      observaciones: "",
      lote: "",
      auxiliar: "",
      fraccion: "",
      referenciaPrevio: "",
      documento: [],
    });
    this.props.toogleReporte();
  };

  handleLLegada = () => {
    let folios = [];
    this.props.checked_llegada.forEach((data) => folios.push(data.folio));
    let data = {
      alertas: folios,
      observaciones: document.getElementById("observaciones_llegada").value,
    };
    if (data.observaciones.length === 0)
      message.message_modal("Verificar datos", "Observaciones", 400);
    else {
      this.methodPOST_API(utility.url_llegada_alerta, "llegada", data);
      this.sendNotificacionHandler("llegar");
      this.props.checkedLlegada([]);
      this.props.update(1);
    }
  };
  handleEntrega = () => {
    let folios = [];
    this.props.checked_entrega.forEach((data) => {
      folios.push(String(data.folio));
    });
    let data = {
      alertas: folios,
      observaciones: document.getElementById("observaciones_entrega").value,
    };
    console.log(data);
    if (data.observaciones.length === 0)
      message.message_modal("Verificar datos", "Observaciones", 400);
    else {
      this.methodPOST_API(utility.url_entrega_alerta, "llegada", data);
      this.sendNotificacionHandler("entregar");
      this.props.checkedEntrega([]);
      this.props.update(1);
    }
  };

  handleRecoleccion = () => {
    let folios = [];
    this.props.checked_recolectar.forEach((data) => folios.push(data.folio));
    let data = {
      alertas: folios,
    };
    this.methodPOST_API(
      utility.url_reporte_recoleccion_fecha,
      "recoleccion",
      data
    );
    this.sendNotificacionHandler("recolectar");
    this.props.checkedRecoleccion([]);
    this.props.update(1);
  };

  handleGuia = (e) => {
    let guiaPrevio = e.target.value;
    this.setState({
      guiaPrevio,
    });
  };

  handleArchivo = (file) => {
    let archivo = file;
    if (archivo.type === "text/xml") {
      let arch = new FileReader();
      arch.onload = (event) => {
        this.setState({ archivo_xml: archivo });
        this.handleXMLS(event.target.result);
      };
      arch.readAsText(archivo);
    }
    let archvios_arr = this.state.archivo_select;
    archvios_arr.push(file);
    this.setState({ archivo_select: archvios_arr });
  };

  buildFormData(formData, data, parentKey) {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;
      formData.append(parentKey, value);
    }
  }

  jsonToFormData(data) {
    const formData = new FormData();
    this.buildFormData(formData, data);
    return formData;
  }

  handleAltaNumParte = () => {
    if (this.state.numParte === "") {
      swal("Advertencia", "Por favor ingrese un número de parte", "warning");
      return;
    }

    const { numParte, fraccion, lote, auxiliar, observaciones } = this.state;

    if (numParte === "" || fraccion === "" || lote === "") {
      let camposFaltantes = [];
      if (numParte === "") camposFaltantes.push("Número de Parte");
      if (fraccion === "") camposFaltantes.push("Fracción");
      if (lote === "") camposFaltantes.push("Número de Lote");

      let mensaje = `Por favor llena los siguientes campos: ${camposFaltantes.join(
        ", "
      )}.`;
      message.warningMessage(mensaje);
    } else {
      let data = {
        id_parte: numParte,
        id_fraccion: fraccion,
        num_lote: lote,
      };

      if (auxiliar !== "") {
        data.num_auxilar = auxiliar;
      }

      if (observaciones !== "") {
        data.observaciones = observaciones;
      }

      axios
        .post(utility.url_crea_numParte, data)
        .then((response) => {
          if (response.status >= 200 && response.status < 400) {
            if (this._isMounted) {
              /* message.message_success(
                "Se creó exitosamente",
                "Número Parte creado"
              ); */
            }
            this.handleCerrar();
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response)
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
        });
    }
  };

  handleSubirArchivos = () => {
    // Verificar si agrega nuevo producto
    if (this.state.nuevoProducto === 1) {
      this.handleAltaNumParte();
    }

    let archivos = this.state.archivos;
    let tipo_archivo = "previo";
    let documentos = this.state.archivo_select;
    let data_archivos = { tipo_archivo, documentos };
    if (this.state.guiaPrevio.length === 0)
      message.message_modal("Advertencia", "Debe tener una guía", 400);
    else {
      archivos.push(data_archivos);
      let data = {
        referencia: this.state.referenciaPrevio,
        archivos,
      };
      swal({
        title: "Subiendo archivo...",
        buttons: false,
        closeOnClickOutside: false,
        content: (
          <div>
            <Spinner
              color="success"
              style={{
                width: "5rem",
                height: "5rem",
                marginBottom: "26px",
                animation: "1.25s linear infinite spinner-border",
              }}
            >
              Loading...
            </Spinner>
          </div>
        ),
      });
      axios
        .post(
          "/DjangoWeb/ExpedienteApp/cargar/archivo_pdf",
          this.jsonToFormData(data),
          { headers: { "Content-type": "multipart/form-data" } }
        )
        .then((response) => {
          message.message_modal(
            response.data.title,
            response.data.text,
            response.status
          );
          this.setState(
            {
              archivos: [],
              archivo_select: [],
              tipo_archivo_operacion: null,
              subir_imagen: false,
            },
            () => {
              swal.close();
            }
          );
        })
        .catch((error) => {
          if (error.response)
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
        });
    }
  };

  handleEliminaArchivoPNG = () => {
    this.setState({ archivo_png: null });
  };
  handleEliminaArchivoJPG = () => {
    this.setState({ archivo_jpg: null });
  };

  handleCheck = (event) => {
    const { checked } = event.target;
    this.setState({ nuevoProducto: checked ? 1 : 0 });
  };

  /**
   * Función que permite hacer una petición de forma POST
   * @param {String} url URL a consultar
   * @param {String} state Onpción del case
   * @param {Object} data Objeto a mandar atraves del metodo POST
   */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "llegada":
              this.props.toogleReporte();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              break;
            case "recoleccion":
              this.props.toogleReporte();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              break;
            case "entrega":
              this.props.toogleReporte();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handleEliminaArchivo = (e) => {
    let archivos_arr = this.state.archivo_select;
    archivos_arr.splice(parseInt(e.target.id), 1);
    this.setState({ archivo_select: archivos_arr });
  };

  handleNumParte = (event) => {
    const { value } = event.target;
    this.setState({ numParte: value });
  };

  handleObservaciones = (event) => {
    const { value } = event.target;
    this.setState({ observaciones: value });
  };

  handleLote = (event) => {
    const { value } = event.target;
    this.setState({ lote: value });
  };
  handleAuxiliar = (event) => {
    const { value } = event.target;
    this.setState({ auxiliar: value });
  };

  handleFraccion = (event) => {
    const { value } = event.target;
    this.setState({ fraccion: value });
  };

  handleConsulta = () => {
    axios
      .post(utility.url_checar_guia, { guia: this.state.guiaPrevio })
      .then((response) => {
        if (response.data && response.data.results) {
          this.setState({ referenciaPrevio: response.data.results }, () => {
            axios
              .get(
                `${utility.url_referenciapdf}/${this.state.referenciaPrevio}/guia`,
                { responseType: "arraybuffer" }
              )
              .then((response) => {
                const contentType = response.headers["content-type"];
                const validTypes = [
                  "application/pdf",
                  "image/jpeg",
                  "image/jpg",
                  "image/png",
                ];

                if (validTypes.includes(contentType)) {
                  const file = new Blob([response.data], { type: contentType });
                  const fileURL = URL.createObjectURL(file);
                  this.setState({ documento: fileURL });
                } else {
                  console.error("El tipo de archivo recibido no es válido");
                }
              })
              .catch((error) => {
                if (error.response) {
                  message.message_modal(
                    error.response.data.title,
                    error.response.data.text,
                    error.response.status
                  );
                }
              });
          });
        } else {
          console.error(
            "La respuesta de la consulta de la guía no tiene el formato esperado"
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
        }
      });
  };

  visualizarDocumento = () => {
    if (this.state.documento) {
      // Abrir el documento en una nueva ventana o pestaña
      window.open(this.state.documento, "_blank");
    } else {
      // Mostrar un mensaje de advertencia o realizar alguna otra acción
      console.warn("No hay documento para visualizar");
    }
  };

  descargarDocumento = () => {
    if (this.state.documento) {
      const link = document.createElement("a");
      link.href = this.state.documento;
      link.download = `guia-${this.state.referenciaPrevio}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  eliminarDocumento = () => {
    axios
      .post(utility.url_eliminapdf_guia, {
        referencia: this.state.referenciaPrevio,
        archivo: "guia",
      })
      .then((response) => {
        if (response.status === 200) {
          this.setState({ documento: [] });
          console.log("Documento eliminado con éxito");
        } else {
          console.error("Error al eliminar el documento:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error al eliminar el documento:", error);
      });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_reporte}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>Operación</ModalHeader>
        <ModalBody>
          <div className="row mb-2 d-flex justify-content-center">
            <div className="col-lg-10 col-12 d-flex justify-content-around">
              <Button
                color="info"
                onClick={this.toogleLlegada}
                disabled={utility.obtener_permiso_especifico(
                  "modificar",
                  this.props.permisos
                )}
              >
                Llegada
              </Button>
              <Button
                color="info"
                onClick={this.toogleRecoleccion}
                disabled={this.props.on_cliente !== false ? true : false}
              >
                Recolección
              </Button>
              <Button
                color="info"
                onClick={this.toogleEntrega}
                disabled={utility.obtener_permiso_especifico(
                  "modificar",
                  this.props.permisos
                )}
              >
                Entrega
              </Button>
              <Button
                color="info"
                onClick={this.tooglePrevio}
                disabled={utility.obtener_permiso_especifico(
                  "modificar",
                  this.props.permisos
                )}
              >
                Previo
              </Button>
            </div>
          </div>
          <Collapse isOpen={this.state.toogle_recoleccion}>
            <Card outline color="info">
              <CardBody>
                {this.props.checked_recolectar.length === 0 ? (
                  <Alert color="warning" className="text-center mt-3">
                    <h2>Debe selecionar alguna Alerta.</h2>
                  </Alert>
                ) : (
                  <React.Fragment>
                    <div className="container-fluid">
                      <div className="row">
                        <MyInput
                          type="text"
                          bootstrap="col-12"
                          nameLabel="Fecha Actual"
                          value={utility.get_fecha_actual().fecha_string}
                          disabled
                        />
                      </div>
                    </div>
                    <Table
                      size="sm"
                      id="tabla_solicitud_anticipos"
                      bordered
                      hover
                      responsive
                    >
                      <thead className="text-center header-table">
                        <tr>
                          <th>Cliente</th>
                          <th>Folio</th>
                          <th>Guía</th>
                          <th>Número Vuelo</th>
                          <th>Consolidadora</th>
                          <th>Llegada</th>
                        </tr>
                      </thead>
                      <tbody className="body-table">
                        {this.props.checked_recolectar.map((alerta) => (
                          <tr key={alerta.folio}>
                            <td className="text-center">{alerta.cliente}</td>
                            <td className="text-center">{alerta.folio}</td>
                            <td className="text-center">
                              {alerta.guia_principal}
                            </td>
                            <td className="text-center">
                              {alerta.numero_vuelo}
                            </td>
                            <td className="text-center">
                              {alerta.consolidadora}
                            </td>
                            <td className="text-center">
                              {alerta.fecha_llegada}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </React.Fragment>
                )}
                <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                  <Button
                    type="button"
                    color="success"
                    className="mr-2"
                    onClick={this.handleRecoleccion}
                  >
                    Recolectar
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="mr-3"
                    onClick={this.props.toogleReporte}
                  >
                    Cerrar
                  </Button>
                </FormGroup>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_llegada}>
            <Card outline color="info">
              <CardBody>
                {this.props.checked_llegada.length === 0 ? (
                  <Alert color="warning" className="text-center mt-3">
                    <h2>Debe selecionar alguna Alerta.</h2>
                  </Alert>
                ) : (
                  <React.Fragment>
                    <div className="container fluid">
                      <div className="row">
                        <MyInput
                          type="text"
                          bootstrap="col-12"
                          nameLabel="Fecha Actual"
                          value={utility.get_fecha_actual().fecha_string}
                          disabled
                        />
                      </div>
                    </div>
                    <Table
                      size="sm"
                      id="tabla_solicitud_anticipos"
                      bordered
                      hover
                      responsive
                    >
                      <thead className="text-center header-table">
                        <tr>
                          <th>Cliente</th>
                          <th>Folio</th>
                          <th>Guía</th>
                          <th>Número Vuelo</th>
                          <th>Consolidadora</th>
                        </tr>
                      </thead>
                      <tbody className="body-table">
                        {this.props.checked_llegada.map((alerta) => (
                          <tr key={alerta.folio}>
                            <td className="text-center">{alerta.cliente}</td>
                            <td className="text-center">{alerta.folio}</td>
                            <td className="text-center">
                              {alerta.guia_principal}
                            </td>
                            <td className="text-center">
                              {alerta.numero_vuelo}
                            </td>
                            <td className="text-center">
                              {alerta.consolidadora}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <MyTextArea
                      bootstrap="col-lg-12 col-12"
                      id="observaciones_llegada"
                      nameLabel="Observaciones"
                    />
                  </React.Fragment>
                )}
                <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                  <Button
                    type="button"
                    color="success"
                    className="mr-2"
                    onClick={this.handleLLegada}
                  >
                    Asignar Fecha
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="mr-3"
                    onClick={this.props.toogleReporte}
                  >
                    Cerrar
                  </Button>
                </FormGroup>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_entrega}>
            <Card outline color="info">
              <CardBody>
                {this.props.checked_entrega.length === 0 ? (
                  <Alert color="warning" className="text-center mt-3">
                    <h2>Debe selecionar alguna Alerta.</h2>
                  </Alert>
                ) : (
                  <React.Fragment>
                    <div className="container-fluid">
                      <div className="row">
                        <MyInput
                          type="text"
                          bootstrap="col-12"
                          nameLabel="Fecha Actual"
                          value={utility.get_fecha_actual().fecha_string}
                          disabled
                        />
                      </div>
                    </div>
                    <Table
                      size="sm"
                      id="tabla_solicitud_anticipos"
                      bordered
                      hover
                      responsive
                    >
                      <thead className="text-center header-table">
                        <tr>
                          <th>Cliente</th>
                          <th>Folio</th>
                          <th>Guía</th>
                          <th>Número Vuelo</th>
                          <th>Consolidadora</th>
                          <th>Llegada</th>
                        </tr>
                      </thead>
                      <tbody className="body-table">
                        {this.props.checked_entrega.map((alerta) => (
                          <tr key={alerta.folio}>
                            <td className="text-center">{alerta.cliente}</td>
                            <td className="text-center">{alerta.folio}</td>
                            <td className="text-center">
                              {alerta.guia_principal}
                            </td>
                            <td className="text-center">
                              {alerta.numero_vuelo}
                            </td>
                            <td className="text-center">
                              {alerta.consolidadora}
                            </td>
                            <td className="text-center">
                              {alerta.fecha_llegada}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <MyTextArea
                      bootstrap="col-lg-12 col-12"
                      id="observaciones_entrega"
                      nameLabel="Observaciones"
                    />
                  </React.Fragment>
                )}
                <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                  <Button
                    type="button"
                    color="success"
                    className="mr-2"
                    onClick={this.handleEntrega}
                  >
                    Entregar
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="mr-3"
                    onClick={this.props.toogleReporte}
                  >
                    Cerrar
                  </Button>
                </FormGroup>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_previo}>
            <Card outline color="info">
              <CardBody>
                <FormGroup className="row mb-0 align-items-center">
                  <div className="col-lg-9 col-8">
                    <MyInput
                      type="text"
                      nameLabel="Guía"
                      id="guia_previo"
                      onChange={this.handleGuia}
                      value={this.state.guiaPrevio}
                    />
                  </div>
                  <div className="col-lg-3 col-4 d-flex align-items-center">
                    <Button
                      color="primary"
                      className="w-100"
                      onClick={this.handleConsulta}
                    >
                      Consulta
                    </Button>
                  </div>
                </FormGroup>
                {this.state.documento.length !== 0 && (
                  <div className="row align-items-center">
                    <div className="col d-flex justify-content-center mt-3">
                      <Button
                        color="primary"
                        className="mx-1"
                        onClick={this.visualizarDocumento}
                      >
                        <FontAwesomeIcon icon={faEye} className="mr-1" />{" "}
                        Visualizar
                      </Button>
                      <Button
                        color="success"
                        className="mx-1"
                        onClick={this.descargarDocumento}
                      >
                        <FontAwesomeIcon icon={faDownload} className="mr-1" />{" "}
                        Descargar
                      </Button>
                      <Button
                        color="danger"
                        className="mx-1"
                        onClick={this.eliminarDocumento}
                      >
                        <FontAwesomeIcon icon={faTrash} className="mr-1" />{" "}
                        Eliminar
                      </Button>
                    </div>
                  </div>
                )}

                {/* <div className="row mt-3">
                  <div className="col-lg-3">
                    <MyInput
                      type="text"
                      nameLabel="Número Parte"
                      id="numParte_previo"
                      onChange={this.handleNumParte}
                      value={this.state.numParte}
                    />
                  </div>
                  <MyInput
                    type="text"
                    bootstrap="col-lg-3 col-12"
                    nameLabel="Lote"
                    id="Lote"
                    onChange={this.handleLote}
                    value={this.state.lote}
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-3 col-12"
                    nameLabel="Fracción"
                    id="fraccion"
                    onChange={this.handleFraccion}
                    value={this.state.fraccion}
                  />
                  <div className="col-lg-3 d-flex align-items-center">
                    <CustomInput
                      type="checkbox"
                      id="nuevoProducto_alerta_consulta"
                      label="Agregar Nuevo Producto"
                      onChange={this.handleCheck}
                      checked={this.state.nuevoProducto === 1 ? true : false}
                    />
                  </div>

                  <div className="col-lg-12">
                    <MyInput
                      type="text"
                      nameLabel="Auxiliar (Opcional)"
                      id="auxiliar"
                      onChange={this.handleAuxiliar}
                      value={this.state.auxiliar}
                    />
                  </div>
                </div>

                <MyTextArea
                  bootstrap="col-12"
                  nameLabel="Observaciones (Opcional)"
                  id="observaciones_previo"
                  onChange={this.handleObservaciones}
                  value={this.state.observaciones}
                /> */}
                <Form className="container-fluid">
                  <FormGroup className="row mb-0 mt-2 mt-lg-0">
                    <MyDropzone
                      handleArchivo={this.handleArchivo}
                      accept="image/*, .pdf, .jpg, .jpeg, .png"
                    />
                    <div className="col-12 my-2 d-flex justify-content-center flex-wrap">
                      {this.state.archivo_select.map((archivo, index) => (
                        <Badge color="light" className="border">
                          {archivo.name}&nbsp;
                          <spam
                            style={{ cursor: "pointer" }}
                            id={index}
                            onClick={this.handleEliminaArchivo}
                          >
                            &times;
                          </spam>
                        </Badge>
                      ))}
                    </div>
                    <Button
                      color="primary"
                      className="col-12"
                      onClick={this.handleSubirArchivos}
                    >
                      Subir Archivo
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalOperacion;
