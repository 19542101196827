/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un operacion. 
*/
import axios from "axios";
import React, { Component } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Collapse,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  Spinner,
} from "reactstrap";
import MyInput from "../../../utils/custom/MyInput";
import AutocompleteInput from "../utils/AutocompleteInput";
import MyTextArea from "../../../utils/custom/MyTextArea";
import * as message from "../../../utils/messages/message";
import MyDropzone from "../utils/MyDropzone";
import * as utility from "../utils/utility";
import CustomSelect from "../../../utils/custom/CustomSelect";
import XMLParser from "react-xml-parser";
import swal from "@sweetalert/with-react";
import { faFileDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      folio: "",
      numero_select: "",
      nombre_select: "",
      empleado: "",
      proveedor_text: "",
      proveedor_id: "",
      tipo_text: "Seleccione un tipo de operación",
      tipo_id: "",
      registro_entrada: "",
      guia_principal: "",
      guia_secundaria: "",
      consolidadora_text: "",
      consolidadora_id: "",
      aduana: "",
      patente_text: "Seleccione una patente",
      patente_id: "",
      procedencia_text: "",
      procedencia_id: "",
      numero_pedido: "",
      cantidad: "",
      fecha_alta: "",
      fecha_llegada: "",
      fecha_despacho: "",
      descripcion: "",
      referencia_archivo: "",
      tipo_archivo_text: "Seleccione el tipo de archivo",
      tipo_archivo_id: "",
      num_operacion: "",
      observaciones: "",
      referencia: "",
      tipo_alta: "",

      archivo_select: [],
      archivos: [],
      nombre_suggestions: [],
      patentes_select: [],
      tipo_operacion: [
        { clave: 0, nombre: "Importación" },
        { clave: 1, nombre: "Exportación" },
      ],
      tipo_archivo_options: [
        { clave: "guia", nombre: "Guia" },
        { clave: "anexo_guia", nombre: "Anexo de Guia" },
        { clave: "hoja_calculo", nombre: "Hoja de Calculo" },
        { clave: "pedimento", nombre: "Pedimento" },
        { clave: "anexo_pedimento", nombre: "Anexo de Pedimento" },
        { clave: "manifestacion_valor", nombre: "Manifestación de Valor" },
        { clave: "cove", nombre: "COVE" },
        { clave: "e_documento", nombre: "e-Documentos" },
        { clave: "doda", nombre: "DODA" },
      ],

      toogle_alta: false,
      toogle_archivos: false,
      enable_toogle_archvos: true, //Habilitar bot'on archivos

      archivo_xml: null,
      archivo_pdf: null,
      archivo_valido: false,
      enable_add: true,
      comprobante_campos: {},
      emisor_campos: {},
      receptor_campos: {},
      timbre_campos: {},

      tipo_transaccion_array: [
        { id: "CC", text: "Comprobable del cliente" },
        { id: "CE", text: "Comprobable de la empresa" },
        { id: "PC", text: "Por comprobar del cliente" },
        { id: "PE", text: "Por comprobar de la empresa" },
      ],
      tipo_movimiento_array: [
        { id: 0, nombre: "Transacción" },
        { id: 1, nombre: "Cheque" },
      ],

      tap_factura: "1",
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.tipo_archivo_text !== prevState.tipo_archivo_text) {
      // Actualizar archivo_select al estado deseado cuando tipo_archivo_text cambia
      this.setState({ archivo_select: [] });
    }
  }
  toogleArchivos = () => {
    this.setState((prevState) => ({
      toogle_archivos: !prevState.toogle_archivos,
      toogle_alta: false,
    }));
  };
  toogleAlta = () => {
    this.setState((prevState) => ({
      toogle_alta: !prevState.toogle_alta,
      toogle_archivos: false,
    }));
  };
  /* ------ Bloque de funciones que nos permitiran obtener el texto en los inputs ------ */
  handleFolio = (e) => {
    let folio = e.target.value;
    this.setState({ folio });
  };
  handleReferencia_archivo = (e) => {
    let referencia_archivo = e.target.value;
    this.setState({ referencia_archivo });
  };
  handleEmpleado = (e) => {
    let empleado = e.target.value;
    this.setState({ empleado });
  };
  handleTipo = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_text = e[label];
    let tipo_id = e[label_id];
    this.setState({ tipo_text, tipo_id });
  };
  handleRegistro_entrada = (e) => {
    let registro_entrada = e.target.value;
    this.setState({ registro_entrada });
  };
  handleGuia_principal = (e) => {
    let guia_principal = e.target.value;
    this.setState({ guia_principal });
  };
  handleGuia_secundaria = (e) => {
    let guia_secundaria = e.target.value;
    this.setState({ guia_secundaria });
  };
  handleConsolidadora = (option) => {
    const consolidadora_text = option.nombre;
    const consolidadora_id = option.numero;
    this.setState({
      consolidadora_text,
      consolidadora_id,
    });
  };
  handleProcedencia = (option) => {
    const procedencia_text = option.nombre;
    const procedencia_id = option.clave;
    this.setState({ procedencia_text, procedencia_id });
  };
  handleProveedor = (option) => {
    const proveedor_text = option.nombre;
    const proveedor_id = option.numero;
    this.setState({ proveedor_text, proveedor_id });
  };
  handleAduana = (e) => {
    let aduana = e.target.value;
    let patentes_select = this.state.patentes_select;
    for (let i = 0; i < this.props.patentes.length; i++)
      if (this.props.patentes[i].aduana === aduana)
        patentes_select.push(this.props.patentes[i]);
    this.setState({ aduana, patentes_select });
  };
  handlePatente = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let patente_text = e[label];
    let patente_id = e[label_id];
    this.setState({ patente_text, patente_id });
  };
  handleNumero_pedido = (e) => {
    let numero_pedido = e.target.value;
    this.setState({ numero_pedido });
  };
  handleClase = (e) => {
    let clase = e.target.value;
    this.setState({ clase });
  };
  handleCodigo_fase = (e) => {
    let codigo_fase = e.target.value;
    this.setState({ codigo_fase });
  };
  handlePeso_bruto = (e) => {
    let peso_bruto = e.target.value;
    this.setState({ peso_bruto });
  };
  handleCantidad = (e) => {
    let cantidad = e.target.value;
    this.setState({ cantidad });
  };
  handleNum_operacion = (e) => {
    let num_operacion = e.target.value;
    this.setState({ num_operacion });
  };
  handleObservacion = (e) => {
    let observaciones = e.target.value;
    this.setState({ observaciones });
  };
  handleFecha_alta = (e) => {
    let fecha_alta = e.target.value;
    this.setState({ fecha_alta });
  };
  handleFecha_llegada = (e) => {
    let fecha_llegada = e.target.value;
    this.setState({ fecha_llegada });
  };
  handleFecha_despacho = (e) => {
    let fecha_despacho = e.target.value;
    this.setState({ fecha_despacho });
  };
  handleDescripcion = (e) => {
    let descripcion = e.target.value;
    this.setState({ descripcion });
  };
  handleTipo_archivo = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_archivo_text = e[label];
    let tipo_archivo_id = e[label_id];
    this.setState({ tipo_archivo_text, tipo_archivo_id });
  };
  handleArchivo = (file) => {
    let archivo = file;
    if (archivo.type === "text/xml") {
      let arch = new FileReader();
      arch.onload = (event) => {
        this.setState({ archivo_xml: archivo });
        this.handleXMLS(event.target.result);
      };
      arch.readAsText(archivo);
    }
    let archvios_arr = this.state.archivo_select;
    archvios_arr.push(file);
    this.setState({ archivo_select: archvios_arr });
  };
  handleEliminaArchivo = (e) => {
    let archivos_arr = this.state.archivo_select;
    archivos_arr.splice(parseInt(e.target.id), 1);
    this.setState({ archivo_select: archivos_arr });
  };
  handleAgregaArchivo = () => {
    let archivos = this.state.archivos;
    let tipo_archivo_id = this.state.tipo_archivo_id;
    let documentos = this.state.archivo_select;
    let data = { tipo_archivo_id, documentos };
    archivos.push(data);
    this.setState({ archivos, archivo_select: [] });
  };
  /* ------ Fin del bloque de funciones que nos permitiran obtener el texto en los inputs ------ */
  handleAlta = () => {
    message
      .message_confimacion("¿Desea continuar con la operación alta?", "")
      .then((res) => {
        if (res) {
          if (this.state.tipo_text === "Exportación") {
            this.setState({ tipo_id: "1" });
          } else {
            this.setState({ tipo_id: "0" });
          }

          let data = {
            folio: this.state.folio,
            cliente: this.state.numero_select,
            empleado: localStorage.getItem("id_usr"),
            tipo: this.state.tipo_id,
            registro_entrada: this.state.registro_entrada,
            fecha_llegada: this.state.fecha_llegada,
            guia_principal: this.state.guia_principal,
            consolidadora: parseInt(this.state.consolidadora_id, 10),
            proveedor: this.state.proveedor_id,
            procedencia: this.state.procedencia_id,
            descripcion: this.state.descripcion,
            no_pedido: this.state.numero_pedido,
            patente: this.state.patente_text,
            num_operacion: this.state.num_operacion,
            observaciones: this.state.observaciones,
            aduana: this.state.aduana,
            guia_secundaria: this.state.guia_secundaria,
            fecha_despacho: this.state.fecha_despacho,
          };
          utility.eliminaCamposPOST(data);
          if (utility.validadCamposPOST(data).length > 0)
            message.message_modal(
              "Falta completar los siguientes campos:",
              utility.validadCamposPOST(data),
              400
            );
          else this.methodPOST_API(utility.url_alta_operacion, "alta", data);
        }
      });
  };
  handleCompletar = () => {
    if (this.state.folio != "") {
      this.methodPOST_API(utility.url_consulta_alerta, "consulta_alerta", {
        folio: this.state.folio,
      });
    } else {
      message.message_modal("Advertencia", "Debe tener un folio", 400);
    }
  };
  buildFormData(formData, data, parentKey) {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;
      formData.append(parentKey, value);
    }
  }
  jsonToFormData(data) {
    const formData = new FormData();
    this.buildFormData(formData, data);
    return formData;
  }
  handleUpload_archivo = () => {
    let data = {
      referencia: this.state.referencia_archivo,
      archivos: this.state.archivos,
    };
    axios
      .post(
        "/DjangoWeb/ExpedienteApp/cargar/archivo_pdf",
        this.jsonToFormData(data),
        { headers: { "Content-type": "multipart/form-data" } }
      )
      .then((response) => {
        this.handleCerrar();
        message.message_modal(
          response.data.title,
          response.data.text,
          response.status
        );
        this.props.update(1);
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  handleLimpiar = () => {
    this.setState({
      folio: "",
      numero_select: "",
      nombre_select: "",
      empleado: "",
      proveedor_text: "",
      proveedor_id: "",
      tipo_text: "Seleccione un tipo de operación",
      tipo_id: "",
      registro_entrada: "",
      guia_principal: "",
      guia_secundaria: "",
      consolidadora_text: "",
      consolidadora_id: "",
      aduana: "",
      patente_text: "Seleccione una patente",
      patente_id: "",
      procedencia_text: "",
      procedencia_id: "",
      numero_pedido: "",
      cantidad: "",
      fecha_alta: "",
      fecha_llegada: "",
      fecha_despacho: "",
      descripcion: "",
      referencia_archivo: "",
      tipo_archivo_text: "Seleccione el tipo de archivo",
      tipo_archivo_id: "",
      num_operacion: "",
      observaciones: "",
      referencia: "",
      tipo_alta: "",

      archivo_select: [],
      archivos: [],
      nombre_suggestions: [],
      patentes_select: [],
      tipo_operacion: [
        { clave: 0, nombre: "Importación" },
        { clave: 1, nombre: "Exportación" },
      ],
      tipo_archivo_options: [
        { clave: "guia", nombre: "Guia" },
        { clave: "anexo_guia", nombre: "Anexo de Guia" },
        { clave: "hoja_calculo", nombre: "Hoja de Calculo" },
        { clave: "pedimento", nombre: "Pedimento" },
        { clave: "anexo_pedimento", nombre: "Anexo de Pedimento" },
        { clave: "manifestacion_valor", nombre: "Manifestación de Valor" },
        { clave: "factura", nombre: "Factura" },
        { clave: "cove", nombre: "COVE" },
        { clave: "e_documento", nombre: "e-Documentos" },
        { clave: "doda", nombre: "DODA" },
      ],

      toogle_alta: false,
      toogle_archivos: false,
      enable_toogle_archvos: true,
    });
  };
  handleCerrar = () => {
    this.handleLimpiar();
    this.props.toogleNuevo();
  };
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPDF_API = (url, state, num) => {
    let url_intern = url + `${num}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta_operacion":
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);
              document.getElementById("dowload_pdf_alta_operacion").href =
                fileURL;
              document.getElementById("window_pdf_alta_operacion").href =
                fileURL;
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  getConsolidadora = (numero) => {
    const numeroString = numero.toString();
    return this.props.consolidadoras.find((item) => item.numero === numeroString);
  };

  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "alta":
              this.setState({
                enable_toogle_archvos: false,
                referencia_archivo: response.data.results.referencia,
              });

              this.methodPDF_API(
                utility.url_consulta_pdf,
                "alta_operacion",
                response.data.results.referencia,
              );

              if (response.status === 200 || response.status === 201) {
                swal({
                  icon: "success",
                  title: response.data.title,
                  text: response.data.text,
                  button: true,
                  content: (
                    <div>
                      <LinkD
                        href=""
                        id="dowload_pdf_alta_operacion"
                        download={`Operación ${response.data.results.referencia}`}
                      />
                      <br />
                      <LinkV
                        href=""
                        id="window_pdf_alta_operacion"
                        target="_blank"
                      />
                    </div>
                  ),
                });
              } else {
                // Respuesta no exitosa, mostrar advertencia
                swal({
                  icon: "warning",
                  title: response.data.title,
                  text: response.data.text,
                  button: true,
                });
              }
          
              this.props.update(1);
              this.handleCerrar();
              break;
            case "consulta_alerta":
              const consolidadora = this.getConsolidadora(
                response.data.results.consolidadora
              );
              this.setState({
                consolidadora_text: consolidadora
                  ? consolidadora.nombre
                  : "Desconocido",
                consolidadora_id: consolidadora
                  ? consolidadora.numero
                  : "Desconocido",
              });
              this.setState({
                patente_text: response.data.results.patente,
                aduana: response.data.results.aduana,
                numero_select: response.data.results.cliente,
                nombre_select: utility.get_name_cliente(
                  response.data.results.cliente,
                  this.props.clientes
                ),
                guia_principal: response.data.results.guia_principal,
                fecha_llegada: response.data.results.fecha_llegada,
                observaciones: response.data.results.observaciones,
                tipo_alta: response.data.results.tipo_alta,
              });
              if (this.state.tipo_alta === 0) {
                this.setState({ tipo_text: "Importación" });
              }
              if (this.state.tipo_alta === 1) {
                this.setState({ tipo_text: "Exportación" });
              }
              let options =
                document.getElementById("patente_operacion").options;
              let options_consolidadoras = document.getElementById(
                "consolidadora_operacion"
              ).options;
              for (let i = 0; i < options.length; i++)
                if (options[i].value === this.state.patente_text)
                  document.getElementById("patente_operacion").value =
                    options[i].value;
              for (let i = 0; i < options_consolidadoras.length; i++)
                if (
                  parseInt(options_consolidadoras[i].value) ===
                  this.state.consolidadora_text
                )
                  document.getElementById("consolidadora_operacion").value =
                    options_consolidadoras[i].value;

              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") text_add = "";
    else if (numero_cliente.length <= 4) {
      switch (numero_cliente.length) {
        case 1:
          text_add = "000";
          text_add += numero_cliente;
          break;
        case 2:
          text_add = "00";
          text_add += numero_cliente;
          break;
        case 3:
          text_add = "0";
          text_add += numero_cliente;
          break;
        case 4:
          text_add = numero_cliente;
          break;
        default:
          return null;
      }
    } else text_add = "";
    for (let i = 0; i < this.props.clientes.length; i++) {
      if (text_add.length === 0) {
        document.getElementById("nombre_cliente_operacion").value = "";
        this.suggestionSelected("");
        break;
      }
      if (text_add === this.props.clientes[i].numero) {
        document.getElementById("nombre_cliente_operacion").value =
          this.props.clientes[i].nombre;
        this.suggestionSelected(this.props.clientes[i].nombre);
        break;
      }
    }
  };
  suggestionSelected = (value) => {
    let number = utility.get_number_cliente(value, this.props.clientes);
    if (number === undefined) number = "";
    document.getElementById("numero_cliente_operacion").value = number;
    this.setState({
      nombre_select: value,
      numero_select: number,
      nombre_suggestions: [],
    });
  };
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_nombres_clientes(this.props.clientes)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      nombre_suggestions: suggestions,
      nombre_select: value,
    });
  };
  renderSuggestion = () => {
    if (this.state.nombre_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */

  handleXMLS = (XMLData) => {
    let comprobante = new XMLParser().parseFromString(XMLData);
    let emisor = comprobante.getElementsByTagName("cfdi:Emisor")[0];
    let receptor = comprobante.getElementsByTagName("cfdi:Receptor")[0];
    let timbre = comprobante.getElementsByTagName("tfd:TimbreFiscalDigital")[0];
    if (
      emisor === undefined ||
      receptor === undefined ||
      timbre === undefined
    ) {
      message.message_modal(
        "Error",
        "La factura no cumple con el formato del SAT",
        500
      );
      this.setState({
        comprobante_campos: {},
        emisor_campos: {},
        receptor_campos: {},
        timbre_campos: {},
        enable_add: true,
        archivo: {},
      });
    } else
      this.setState(
        {
          comprobante_campos: {
            folio: comprobante.attributes.Folio,
            fecha: comprobante.attributes.Fecha,
            forma_de_pago: comprobante.attributes.FormaPago,
            no_certificado: comprobante.attributes.NoCertificado,
            moneda: comprobante.attributes.Moneda,
            total: comprobante.attributes.Total,
            metodo_pago: comprobante.attributes.MetodoPago,
            tipo_comprobante: comprobante.attributes.TipoDeComprobante,
            numero_pago: comprobante.attributes.NumCtaPago,
            certificado: comprobante.attributes.Certificado,
            version: comprobante.attributes.Version,
          },
          emisor_campos: {
            rfc: emisor.attributes.Rfc,
            nombre: emisor.attributes.Nombre,
          },
          receptor_campos: {
            rfc: receptor.attributes.Rfc,
          },
          timbre_campos: {
            version: timbre.attributes.Version,
            uuid: timbre.attributes.UUID,
            fecha_timbrado: timbre.attributes.FechaTimbrado,
            sello_cfd: timbre.attributes.SelloCFD,
            sello_sat: timbre.attributes.SelloSAT,
          },
        },
        () => {
          this.methodPOST_SOAP();
        }
      );
  };

  methodPOST_SOAP = () => {
    let sr =
      '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">' +
      "<soapenv:Header/>" +
      "<soapenv:Body>" +
      "<tem:Consulta>" +
      "<!--Optional:-->" +
      `<tem:expresionImpresa><![CDATA[?re=${this.state.emisor_campos.rfc}&rr=${this.state.receptor_campos.rfc}&tt=${this.state.comprobante_campos.total}
                &id=${this.state.timbre_campos.uuid}]]></tem:expresionImpresa>` +
      "</tem:Consulta>" +
      "</soapenv:Body>" +
      "</soapenv:Envelope>";
    swal({
      title: "Validando factura...",
      buttons: false,
      closeOnClickOutside: false,
      content: (
        <div>
          <Spinner
            color="success"
            style={{
              width: "5rem",
              height: "5rem",
              marginBottom: "26px",
              animation: "1.25s linear infinite spinner-border",
            }}
          >
            Loading...
          </Spinner>
        </div>
      ),
    });
    axios
      .post("/ConsultaCFDIService.svc", sr, {
        headers: {
          "Content-type": 'text/xml;charset="utf-8"',
          Accept: "text/xml",
          SOAPAction: "http://tempuri.org/IConsultaCFDIService/Consulta",
          "cache-control": "no-cache",
          Host: "consultaqr.facturaelectronica.sat.gob.mx",
        },
      })
      .then((res) => {
        let comprobante = new XMLParser().parseFromString(res.data);
        let codigo_estatus =
          comprobante.getElementsByTagName("a:CodigoEstatus")[0];
        let estado = comprobante.getElementsByTagName("a:Estado")[0];
        swal.close();
        if (
          codigo_estatus.value ===
          "S - Comprobante obtenido satisfactoriamente."
        )
          if (estado.value === "Vigente") {
            message.message_modal(
              "Factura valida",
              "Puede agregar el movimiento",
              200
            );
            this.setState({ enable_add: false });
          } else {
            message.message_modal(
              "Factura cancelada",
              "No puede agregar el movimiento",
              400
            );
            this.setState({ enable_add: true });
          }
        else {
          message.message_modal(
            "No existe la factura",
            "Seleccione otro archivo",
            500
          );
          this.setState({ enable_add: true });
        }
      })
      .catch((err) => {
        if (err)
          message.message_modal("Ocurrio un error", "Intentelo de nuevo", 500);
      });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_nuevo}
        toggle={this.props.toogleNuevo}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.props.toogleNuevo}>
          Alta Operación
        </ModalHeader>
        <ModalBody>
          <div className="row mb-2 d-flex justify-content-center">
            <div className="col-lg-10 col-12 d-flex justify-content-around">
              <Button color="primary" onClick={this.toogleAlta}>
                Alta De Operación
              </Button>
              <Button
                color="info"
                onClick={this.toogleArchivos}
                disabled={this.state.enable_toogle_archvos}
              >
                Carga De Archivos
              </Button>
            </div>
          </div>
          <Collapse isOpen={this.state.toogle_alta}>
            <Card outline color="primary">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-10 col-8"
                      id="folio_operacion"
                      onChange={this.handleFolio}
                      value={this.state.folio}
                      nameLabel="Folio"
                    />
                    <Button
                      color="primary"
                      className="col-lg-2 col-4 mb-2"
                      onClick={this.handleCompletar}
                    >
                      Completar
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="numero_cliente_operacion"
                      onChange={this.nameGenerator}
                      value={this.state.numero_select}
                      nameLabel="Número de Cliente"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="nombre_cliente_operacion"
                      onChange={this.onNameChange}
                      value={this.state.nombre_select}
                      nameLabel="Nombre de Cliente"
                    />
                    {this.renderSuggestion()}
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4"
                      id="empleado_operacion"
                      disabled={true}
                      onChange={this.handleEmpleado}
                      value={localStorage.getItem("emp_name")}
                      nameLabel="Empleado"
                    />
                    <AutocompleteInput
                      bootstrap="col-lg-4 col-12"
                      name_text="nombre"
                      name_index="nombre_corto"
                      id="proveedor_operacion"
                      nameLabel="Proveedor"
                      value={this.state.proveedor_text}
                      onSelect={this.handleProveedor}
                      options={this.props.proveedores}
                    />
                    <CustomSelect
                      bootstrap="col-lg-4 col-12"
                      name_text="nombre"
                      name_index="clave"
                      id="tipo_operacion"
                      nameLabel="Tipo"
                      value={this.state.tipo_text}
                      onChange={this.handleTipo}
                      options={this.state.tipo_operacion}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4"
                      id="registro_entrada_operacion"
                      onChange={this.handleRegistro_entrada}
                      value={this.state.registro_entrada}
                      nameLabel="Registro entrada"
                    />
                    <AutocompleteInput
                      bootstrap="col-lg-4 col-12"
                      name_text="nombre"
                      name_index="clave"
                      id="procedencia_operacion"
                      nameLabel="Procedencia"
                      value={this.state.procedencia_text}
                      onSelect={this.handleProcedencia}
                      options={this.props.paises}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4"
                      id="numero_pedido_operacion"
                      onChange={this.handleNumero_pedido}
                      value={this.state.numero_pedido}
                      nameLabel="Número de pedido"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <AutocompleteInput
                      bootstrap="col-lg-4 col-12"
                      name_text="nombre"
                      name_index="numero"
                      id="consolidadora_operacion"
                      nameLabel="Consolidadora"
                      value={this.state.consolidadora_text}
                      onSelect={this.handleConsolidadora}
                      options={this.props.consolidadoras}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4"
                      id="aduana_operacion"
                      onChange={this.handleAduana}
                      value={this.state.aduana}
                      nameLabel="Aduana"
                    />
                    <CustomSelect
                      bootstrap="col-lg-4 col-12"
                      name_text="patente"
                      name_index="patente"
                      id="patente_operacion"
                      nameLabel="Patente"
                      value={this.state.patente_text}
                      onChange={this.handlePatente}
                      options={this.state.patentes_select}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4"
                      id="num_operacion_principal_operacion"
                      onChange={this.handleNum_operacion}
                      value={this.state.num_operacion}
                      nameLabel="Número de Operación"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4"
                      id="guia_principal_operacion"
                      onChange={this.handleGuia_principal}
                      value={this.state.guia_principal}
                      nameLabel="Guia principal"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4"
                      id="guia_secundaria_operacion"
                      onChange={this.handleGuia_secundaria}
                      value={this.state.guia_secundaria}
                      nameLabel="Guia secundaria"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="date"
                      bootstrap="col-lg-6"
                      id="fecha_alta_operacion"
                      onChange={this.handleFecha_llegada}
                      value={this.state.fecha_llegada}
                      nameLabel="Fecha llegada"
                    />
                    <MyInput
                      type="date"
                      bootstrap="col-lg-6"
                      id="fecha_despacho_operacion"
                      onChange={this.handleFecha_despacho}
                      value={this.state.fecha_despacho}
                      nameLabel="Fecha despacho"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      bootstrap="col-12"
                      id="descripcion_operacion"
                      onChange={this.handleDescripcion}
                      value={this.state.descripcion}
                      nameLabel="Descripción"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyTextArea
                      bootstrap="col-12"
                      id="descripcion_operacion"
                      onChange={this.handleObservacion}
                      value={this.state.observaciones}
                      nameLabel="Observaciones"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      color="success"
                      className="mr-2"
                      onClick={this.handleAlta}
                    >
                      Aceptar
                    </Button>
                    <Button color="primary" onClick={this.handleCerrar}>
                      Cerrar
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_archivos}>
            <Card outline color="info">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="referencia_archivo_operacion"
                      value={this.state.referencia_archivo}
                      nameLabel="Referencia"
                      onChange={this.handleReferencia_archivo}
                      disabled={true}
                    />
                    <CustomSelect
                      bootstrap="col-lg-6 col-12"
                      name_text="nombre"
                      name_index="clave"
                      id="tipo_archivo_operacion"
                      nameLabel="Tipo de Archivo"
                      value={this.state.tipo_archivo_text}
                      onChange={this.handleTipo_archivo}
                      options={this.state.tipo_archivo_options}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0 mt-2 mt-lg-0">
                    {this.state.tipo_archivo_text === "Factura" ? (
                      <MyDropzone
                        handleArchivo={this.handleArchivo}
                        accept=".xml, image/*, .pdf"
                      />
                    ) : (
                      <MyDropzone
                        handleArchivo={this.handleArchivo}
                        accept="image/*, .pdf, .jpg, .jpeg, .png"
                      />
                    )}
                    <div className="col-12 my-2 d-flex justify-content-center flex-wrap">
                      {this.state.archivo_select.map((archivo, index) => (
                        <Badge color="light" className="border">
                          {archivo.name}&nbsp;
                          <spam
                            style={{ cursor: "pointer" }}
                            id={index}
                            onClick={this.handleEliminaArchivo}
                          >
                            &times;
                          </spam>
                        </Badge>
                      ))}
                    </div>
                    <Button
                      color="primary"
                      className="col-12"
                      onClick={this.handleAgregaArchivo}
                    >
                      Agregar al paquete
                    </Button>
                  </FormGroup>
                </Form>
                <Table size="sm" className="mt-2" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>Tipo de Archivo</td>
                      <td>Número de Archivos</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.archivos.map((archivo) => (
                      <tr>
                        <td>{archivo.tipo_archivo_id}</td>
                        <td>{archivo.documentos.length}</td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                  <Button
                    color="success"
                    className="mr-2"
                    onClick={this.handleUpload_archivo}
                  >
                    Aceptar
                  </Button>
                  <Button color="primary" onClick={this.handleCerrar}>
                    Cerrar
                  </Button>
                </FormGroup>
              </CardBody>
            </Card>
          </Collapse>
        </ModalBody>
      </Modal>
    );
  }
}
export default ModalAlta;
