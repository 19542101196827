/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente Principal para el Modal de las distintas opciones de operación para solicitud de anticipos. 
*/
import React, { Component } from "react";
import { ButtonGroup, Button } from "reactstrap";
import ModalAlta from "./ModalAlta";
import ModalConsulta from "./ModalConsulta";
import ModalPago from "./ModalPago";
import ModalExpediente from "./ModalExpediente";
import * as permisos from "../../../utils/permisos/permisos";

class ModalAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toogle_nuevo: false,
      toogle_consulta: false,
      toogle_pago: false,
      toogle_expediente: false,
    };
    this.toogleNuevo = this.toogleNuevo.bind(this);
    this.toogleConsulta = this.toogleConsulta.bind(this);
    this.tooglePago = this.tooglePago.bind(this);
    this.toogleExpediente = this.toogleExpediente.bind(this);
  }
  toogleNuevo = () => {
    this.setState((prevState) => ({ toogle_nuevo: !prevState.toogle_nuevo }));
  };
  toogleConsulta = () => {
    this.setState((prevState) => ({
      toogle_consulta: !prevState.toogle_consulta,
    }));
  };
  tooglePago = () => {
    this.setState((prevState) => ({ toogle_pago: !prevState.toogle_pago }));
  };
  toogleExpediente = () => {
    this.setState((prevState) => ({ toogle_expediente: !prevState.toogle_expediente }));
  };

  render() {
    return (
      <div className="col-lg-3 col-sm-12 d-flex justify-content-center flex-column mb-2 my-md-2">
        <ButtonGroup className="mb-1">
          <Button
            color="primary"
            onClick={this.toogleNuevo}
            disabled={permisos.obtener_permiso_especifico(
              "crear",
              this.props.permisos
            )}
          >
            Nuevo
          </Button>
          <Button color="primary" onClick={this.toogleConsulta}>
            Consulta
          </Button>
        </ButtonGroup>
        <ButtonGroup className="mb-1">
          <Button color="success" onClick={this.tooglePago}>
            Pagos
          </Button>
          <Button color="success" onClick={this.toogleExpediente}>
            Expediente
          </Button>
        </ButtonGroup>
        <ModalAlta
          toogle_nuevo={this.state.toogle_nuevo}
          toogleNuevo={this.toogleNuevo}
          update={this.props.update}
          clientes={this.props.clientes}
          patentes={this.props.patentes}
          paises={this.props.paises}
          consolidadoras={this.props.consolidadoras}
          proveedores={this.props.proveedores}
          permisos={this.props.permisos}
        />
        <ModalConsulta
          toogle_consulta={this.state.toogle_consulta}
          toogleConsulta={this.toogleConsulta}
          update={this.props.update}
          clientes={this.props.clientes}
          patentes={this.props.patentes}
          paises={this.props.paises}
          consolidadoras={this.props.consolidadoras}
          proveedores={this.props.proveedores}
          permisos={this.props.permisos}
          pedimento={this.props.pedimento}
          phxcc={this.props.phxcc}
        />
        <ModalPago
          toogle_pago={this.state.toogle_pago}
          tooglePago={this.tooglePago}
          phxcc={this.props.phxcc}
          beneficiarios={this.props.beneficiarios}
          patentes={this.props.patentes}
        />
        <ModalExpediente
          toogle_expediente={this.state.toogle_expediente}
          toogleExpediente={this.toogleExpediente}
          clientes={this.props.clientes}
        />
      </div>
    );
  }
}
export default ModalAction;
