/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, generar pagos de operacion. 
*/
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Input,
  FormText,
  Card,
  CardBody,
  Badge,
} from "reactstrap";
import MyInput from "../../../utils/custom/MyInput";
import MySelect from "../../../utils/custom/MySelect";
import MyTextArea from "../../../utils/custom/MyTextArea";
import * as message from "../../../utils/messages/message";
import * as utility from "../utils/utility";
import MyDropzone from "../utils/MyDropzone";
import { faPlus, faEye } from "@fortawesome/free-solid-svg-icons";
import swal from "@sweetalert/with-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WebSocketInstance from "../../../utils/websocket";

class ModalPago extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      archivosPendiente: "0",
      referencia: "",
      folio: "",
      patente: "",
      cliente: "",
      cuentaBanco: "",
      banco: "",

      tipo_pago: "",
      beneficiario: "",
      importe: "",
      fecha: "",
      factura: "",
      observaciones: "",

      estatusPago: "",

      archivo_select: [],
      lista_pagos: [],
      pagos_mod: [],
      nuevoTipoPago: "",
      idPagoPendiente: "",
      modal: false,

      bancoBeneficiario: "",
      idBancoBeneficiario: "",
      nombreBeneficiario: "",
      idModBeneficiario: "",
    };
    this.toggle = this.toggle.bind(this);
    this.handlePendiente = this.handlePendiente.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    document.addEventListener("keyup", this.handleEnterKeyPress);
  }
  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("keyup", this.handleEnterKeyPress);
  }

  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.referencia;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "operacion",
      motivo: motivo,
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */

  /* ************************** Funciones que interactuan con el DOM************************ */
  handleReferencia = (e) => {
    let referencia = e.target.value;
    this.setState({ referencia });
  };
  handlePago = (e) => {
    let tipo_pago = e.target.value;
    this.setState({ tipo_pago });
  };
  handleBeneficiario = (e) => {
    let beneficiario = e.target.value;
    this.setState({ beneficiario });

    this.methodPOST_API(utility.url_consulta_beneficiarios, "beneficiario", {
      id: beneficiario,
    });
  };
  handleCuentaBanco = (event) => {
    this.setState({ cuentaBanco: event.target.value });
  };
  handleBanco = (event) => {
    this.setState({ banco: event.target.value });
  };
  handleImporte = (e) => {
    let importe = e.target.value;
    this.setState({ importe });
  };
  handleFecha = (e) => {
    let fecha = e.target.value;
    this.setState({ fecha });
  };
  handleFactura = (e) => {
    let factura = e.target.value;
    this.setState({ factura });
  };
  handleObservaciones = (e) => {
    let observaciones = e.target.value;
    this.setState({ observaciones });
  };
  handleNuevoPago = (event) => {
    const nuevoTipoPago = event.target.value;
    this.setState({ nuevoTipoPago });
  };

  handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      // Si la tecla presionada fue 'Enter', llamamos a la función para manejar el evento
      this.handleImporteBlur();
    }
  };

  handleImporteBlur = () => {
    let importe = this.state.importe;

    // Si el campo está vacío, establecer el valor como '0.00'
    if (!importe) {
      importe = "0.00";
    } else {
      // Convertir el valor a un número
      let num = parseFloat(importe);

      // Verificar si el valor es un número válido
      if (!isNaN(num)) {
        // Formatear el número con dos decimales
        importe = num.toFixed(2);
      } else {
        // Si no es un número válido, establecer el valor como '0.00'
        importe = "0.00";
      }
    }

    this.setState({ importe: importe });
  };

  /* ************************** Funciones que interactuan con el back ************************ */
  handleConsulta = () => {
    if (this.state.referencia.length === 0)
      message.message_modal("Advertencia", "Debe tener una Referencia", 400);
    else {
      this.methodPOST_API(utility.url_consulta_pago, "consulta", {
        referencia: this.state.referencia,
      });
    }
  };

  handleAgrega_pago = () => {
    if (this.state.nuevoTipoPago !== "E" && this.state.nuevoTipoPago !== "") {
      let beneficiarioBancoNuevo = {
        id: this.state.beneficiario,
        cta_banco: this.state.cuentaBanco,
        banco: this.state.banco,
      };

      this.methodPUT_API(
        utility.url_modifica_beneficiarios,
        "modificaBeneficiario",
        beneficiarioBancoNuevo
      );
    } else {
      this.setState({
        cuentaBanco: "",
        banco: "",
      });
    }

    let data = {
      fecha: this.state.fecha,
      tpo_pago: this.state.tipo_pago,
      cantidad: this.state.importe,
      observaciones: this.state.observaciones,
      factura: this.state.factura,
      beneficiario: this.state.beneficiario,
      metodo: this.state.nuevoTipoPago,
    };

    // Verificar qué valores faltan en el objeto data
    let valoresFaltantes = [];
    if (!data.fecha) valoresFaltantes.push("Fecha");
    if (!data.tpo_pago) valoresFaltantes.push("Tipo de pago");
    if (!data.cantidad) valoresFaltantes.push("Importe");
    if (!data.observaciones) valoresFaltantes.push("Observaciones");
    if (!data.beneficiario) valoresFaltantes.push("Beneficiario");
    if (!data.metodo) valoresFaltantes.push("Método de Pago");

    // Mostrar mensaje de advertencia si faltan valores
    if (valoresFaltantes.length > 0) {
      let mensaje = `Faltan los siguientes valores: ${valoresFaltantes.join(
        ", "
      )}`;
      swal("Advertencia", mensaje, "warning");
    }

    if (this.state.referencia.length === 0)
      message.message_modal("Advertencia", "Debe tener una Referencia", 400);
    else {
      if (utility.valida_data_pago(data).length > 0)
        message.message_modal(
          "Advertencia",
          `Completar campos: ${utility.valida_data_pago(data)}`,
          400
        );
      else {
        for (let i = 0; i < this.state.lista_pagos.length; i++) {
          if (this.state.lista_pagos[i].id === parseInt(data.tpo_pago)) {
            message.message_modal(
              "Existe el Pago",
              utility.get_name_pagos(parseInt(data.tpo_pago), this.props.phxcc),
              400
            );
            return;
          }
        }

        if (this.state.nuevoTipoPago === "E") {
          if (this.state.archivo_select.length === 0) {
            swal("Advertencia", "Debe cargar un archivo", "warning");
            return;
          }
          let formData = new FormData();
          formData.append("referencia", this.state.referencia);
          formData.append("pagos", JSON.stringify([data]));
          formData.append("pdf", this.state.archivo_select[0]);

          axios
            .post(utility.url_alta_pago, formData, {
              headers: {
                "Content-type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (response.status >= 200 && response.status < 400) {
                message.message_modal(
                  response.data.title,
                  response.data.text,
                  response.status
                );
                this.setState(
                  {
                    fecha: "",
                    tpo_pago: "",
                    cantidad: "",
                    observaciones: "",
                    factura: "",
                  },
                  () => {
                    this.methodPOST_API(utility.url_consulta_pago, "consulta", {
                      referencia: this.state.referencia,
                    });
                  }
                );
              }
            })
            .catch((error) => {
              if (error.response)
                message.message_modal(
                  error.response.data.title,
                  error.response.data.text,
                  error.response.status
                );
            });
        } else {
          this.methodPOST_API(utility.url_alta_pago, "alta", {
            referencia: this.state.referencia,
            pagos: [data],
          });
        }
      }
    }
  };
  handleElimina_pago = (e) => {
    const idPago = e.target.getAttribute("data-id"); // Obtiene el id del atributo data-id

    message
      .message_confimacion(
        "Advertencia",
        "¿Desea continuar con la operación cancelar?"
      )
      .then((res) => {
        if (res) {
          this.methodPOST_API(utility.url_cancela_pagos, "elimina", {
            id: idPago,
          });
        }
      });
  };

  FiltroPatente = (numpatente) => {
    const numeroBuscado = parseInt(numpatente, 10);

    const objetoEncontrado = this.props.patentes.find(
      (item) => item.numero === numeroBuscado
    );

    if (objetoEncontrado) {
      const { patente } = objetoEncontrado;
      this.setState({ patente });
    } else {
      console.log("No existe patente");
    }
  };

  handleSolPago = () => {
    if (this.state.referencia.length === 0)
      message.message_modal("Advertencia", "Debe tener una Referencia", 400);
    else
      message
        .message_confimacion(
          "Advertencia",
          "¿Desea continuar con la solicitud de pagos?"
        )
        .then((res) => {
          if (res) {
            this.methodPOST_API(utility.url_solicitud_pagos, "solicitudpagos", {
              referencia: this.state.referencia,
            });
            this.sendNotificacionHandler("solicitar_pago");
          }
        });
  };
  handleModifica_pago = () => {
    if (this.state.referencia.length === 0) {
      message.message_modal("Advertencia", "Debe tener una Referencia", 400);
    } else {
      if (
        this.state.idBancoBeneficiario !== "" &&
        this.state.bancoBeneficiario !== ""
      ) {
        let beneficiarioBancoNuevo = {
          id: this.state.idBancoBeneficiario,
          banco: this.state.bancoBeneficiario,
        };

        this.methodPUT_API(
          utility.url_modifica_beneficiarios,
          "modificaBeneficiarios",
          beneficiarioBancoNuevo
        );
      }

      if (
        this.state.idModBeneficiario !== "" &&
        this.state.nombreBeneficiario !== ""
      ) {
        let beneficiarioNuevo = {
          id: this.state.idModBeneficiario,
          nombre: this.state.nombreBeneficiario,
        };

        this.methodPUT_API(
          utility.url_modifica_beneficiarios,
          "modificaBeneficiarios",
          beneficiarioNuevo
        );
      }

      if (this.state.pagos_mod.length === 0) {
        swal({
          icon: "warning",
          title: "Advertencia",
          text: "No hay pagos para modificar.",
          button: "OK",
        });
      } else {
        message
          .message_confimacion(
            "Advertencia",
            "¿Desea continuar con la operación modificar operaciones?"
          )
          .then((res) => {
            if (res) {
              if (this.state.pagos_mod.length > 0) {
                this.methodPUT_API(utility.url_alta_pago, "modifica", {
                  referencia: this.state.referencia,
                  pagos: this.state.pagos_mod,
                });
              }
            }
          });
      }
    }
  };

  handleModifica_beneficiario = (e, id) => {
    let nombreBeneficiario = e.target.value;
    let idModBeneficiario = id;
    this.setState({ nombreBeneficiario, idModBeneficiario });
  };

  handleModifica_cantidad = (e, pago) => {
    this.updatePagoModificado(pago.id, "cantidad", e.target.value);
  };

  handleModifica_banco = (e, id) => {
    let bancoBeneficiario = e.target.value;
    let idBancoBeneficiario = id;
    this.setState({ bancoBeneficiario, idBancoBeneficiario });
  };

  handleModifica_fecha = (e, pago) => {
    this.updatePagoModificado(pago.id, "fecha", e.target.value);
  };

  handleModifica_factura = (e, pago) => {
    this.updatePagoModificado(pago.id, "factura", e.target.value);
  };

  updatePagoModificado(id, campo, valor) {
    this.setState((prevState) => {
      let pagos_mod = [...prevState.pagos_mod];
      let pagoExistente = pagos_mod.find((pago) => pago.id === id);

      if (pagoExistente) {
        pagoExistente[campo] = valor;
      } else {
        let nuevoPago = this.state.lista_pagos.find((pago) => pago.id === id);
        if (nuevoPago) {
          pagos_mod.push({
            id: nuevoPago.id,
            cantidad: campo === "cantidad" ? valor : nuevoPago.cantidad,
            fecha: campo === "fecha" ? valor : nuevoPago.fecha,
            factura: campo === "factura" ? valor : nuevoPago.factura,
          });
        }
      }

      return { pagos_mod };
    });
  }

  handleCerrar = () => {
    this.setState({
      archivosPendiente: "0",
      referencia: "",
      folio: "",
      patente: "",
      cliente: "",
      cuentaBanco: "",
      banco: "",

      tipo_pago: "",
      beneficiario: "",
      importe: "",
      fecha: "",
      factura: "",
      observaciones: "",

      estatusPago: "",

      archivo_select: [],
      lista_pagos: [],
      pagos_mod: [],
      nuevoTipoPago: "",
      idPagoPendiente: "",
      modal: false,

      bancoBeneficiario: "",
      idBancoBeneficiario: "",
      nombreBeneficiario: "",
      idModBeneficiario: "",
    });
    this.props.tooglePago();
  };

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  handlePendiente = (e) => {
    const idPago = e.target.getAttribute("data-id");
    this.setState({
      idPagoPendiente: idPago,
    });

    this.toggle();
  };

  handlePagoModal = (e) => {
    const formData = new FormData();
    formData.append("id", this.state.idPagoPendiente);

    // Verificar si hay archivos seleccionados
    if (
      this.state.archivosPendiente !== "1" &&
      this.state.archivo_select.length === 0
    ) {
      swal("Favor de agregar un archivo", "", "warning");
      return;
    }

    this.state.archivo_select.forEach((archivo) => {
      formData.append("pdf", archivo);
    });

    this.methodPOST_API(utility.url_liquida_pagos, "pago", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    this.toggle();
  };

  methodPDF_API = (url, state, ref) => {
    let url_intern = url + `${ref}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "impresion":
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL, "_blank");
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handleImpresion = () => {
    this.methodPDF_API(
      utility.url_consulta_pdf_pagos,
      "impresion",
      this.state.referencia
    );
  };

  /* ************************** Funciones que interactuan con la lógica del programa ************************ */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "consulta":
              this.setState({
                folio: response.data.results.folio,
                patente: this.FiltroPatente(response.data.results.patente),
                cliente: response.data.results.cliente,
                lista_pagos: response.data.results.lista_pagos,
                estatusPago:
                  response.data.results.lista_pagos.length > 0
                    ? response.data.results.lista_pagos[0].status
                    : null,
              });
              break;
            case "elimina":
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.methodPOST_API(utility.url_consulta_pago, "consulta", {
                referencia: this.state.referencia,
              });
              break;
            case "beneficiario":
              let sinBanco = response.data.results.banco;
              if (sinBanco === "sin banco") {
                swal("No tiene banco asignado", "", "warning");
                this.setState({
                  cuentaBanco: "",
                  banco: "",
                });
                return;
              }
              this.setState({
                cuentaBanco: response.data.results.cta_banco,
                banco: response.data.results.banco,
              });
              break;
            case "solicitudpagos":
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.setState({
                estatusPago: "P",
              });
              break;
            case "impresion":
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              break;
            case "pago":
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.setState({
                estatusPago: "L",
              });
              break;
            case "alta":
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.setState(
                {
                  fecha: "",
                  tpo_pago: "",
                  cantidad: "",
                  observaciones: "",
                  factura: "",
                },
                () => {
                  this.methodPOST_API(utility.url_consulta_pago, "consulta", {
                    referencia: this.state.referencia,
                  });
                }
              );
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPUT_API = (url, state, data) => {
    let url_intern = url;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "elimina":
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.methodPOST_API(utility.url_consulta_pago, "consulta", {
                referencia: this.state.referencia,
              });
              break;
            case "modifica":
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.methodPOST_API(utility.url_consulta_pago, "consulta", {
                referencia: this.state.referencia,
              });
              break;
            case "modificaBeneficiario":
              /* message.message_modal(
              response.data.title,
              response.data.text,
              response.status
            ); */
              break;
            case "modificaBeneficiarios":
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handleArchivos = (e) => {
    let archivosPendiente = e.target.checked ? "1" : "0";
    this.setState({ archivosPendiente });
  };

  handleEliminaArchivo = (e) => {
    let archivos_arr = this.state.archivo_select;
    archivos_arr.splice(parseInt(e.target.id), 1);
    this.setState({ archivo_select: archivos_arr });
  };

  handleArchivo = (file) => {
    let archivo = file;
    if (archivo.type === "text/xml") {
      let arch = new FileReader();
      arch.onload = (event) => {
        this.setState({ archivo_select: archivo });
        this.handleXMLS(event.target.result);
      };
      arch.readAsText(archivo);
    }
    let archvios_arr = this.state.archivo_select;
    archvios_arr.push(file);
    this.setState({ archivo_select: archvios_arr });
  };

  handleEyeClick = (id) => {
    axios
      .get(
        `/DjangoWeb/OperacionesApp/consulta/operacion/pagos/comprobante/${id}`,
        { responseType: "blob" }
      )
      .then((response) => {
        const contentType = response.headers["content-type"];
        const validTypes = [
          "application/pdf",
          "image/jpeg",
          "image/jpg",
          "image/png",
        ];

        if (validTypes.includes(contentType)) {
          const file = new Blob([response.data], { type: contentType });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, "_blank");
        } else {
          console.error("El tipo de archivo recibido no es válido");
        }
      })
      .catch((error) => {
        if (error.response) {
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
        }
      });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_pago}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Pagos Hechos Por Cuenta De Cliente
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <Card className="mt-2" outline>
              <CardBody>
                <FormGroup className="row mb-1 d-flex justify-content-center general_sub">
                  <FormText color="muted">Consulta</FormText>
                </FormGroup>
                <FormGroup className="row mb-0">
                  <MyInput
                    type="text"
                    bootstrap="col-lg-10 col-8"
                    id="referencia_operacion"
                    onChange={this.handleReferencia}
                    value={this.state.referencia}
                    nameLabel="Referencia"
                  />
                  <Button
                    color="primary"
                    className="col-lg-2 col-4 mb-2"
                    onClick={this.handleConsulta}
                  >
                    Consulta
                  </Button>
                </FormGroup>
                <FormGroup className="row mb-0">
                  <MyInput
                    type="text"
                    bootstrap="col-lg-4 col-12"
                    id="folio_operacion"
                    value={this.state.folio}
                    disabled={true}
                    nameLabel="Folio"
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-4 col-12"
                    id="patente_operacion"
                    value={this.state.patente}
                    disabled={true}
                    nameLabel="Patente"
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-4 col-12"
                    id="cliente_operacion"
                    value={this.state.cliente}
                    disabled={true}
                    nameLabel="Cliente"
                  />
                </FormGroup>
              </CardBody>
            </Card>
          </Form>
          <Form className="container-fluid">
            <Card className="mt-2" outline>
              <CardBody>
                <FormGroup className="row mb-1 d-flex justify-content-center general_sub">
                  <FormText color="muted">Nuevo Movimiento</FormText>
                </FormGroup>
                <FormGroup className="row mb-0">
                  <MySelect
                    bootstrap="col-lg-4"
                    id="tipo_pago_operacion"
                    first_option="Seleccione tipo de pago"
                    onChange={this.handlePago}
                    nameLabel="PHXCC"
                  >
                    {this.props.phxcc.map((pago) => (
                      <option value={pago.clave} key={pago.clave}>
                        {pago.nombre}
                      </option>
                    ))}
                  </MySelect>
                  <MyInput
                    type="text"
                    bootstrap="col-lg-4 col-12"
                    id="importe_operacion"
                    value={this.state.importe}
                    onBlur={this.handleImporteBlur}
                    onChange={this.handleImporte}
                    nameLabel="Importe"
                  />
                  <MyInput
                    type="date"
                    bootstrap="col-lg-4 col-12"
                    id="fecha_operacion"
                    value={this.state.fecha}
                    onChange={this.handleFecha}
                    nameLabel="Fecha"
                  />
                </FormGroup>
                <FormGroup className="row mb-0">
                  <MySelect
                    bootstrap="col-lg-4 col-12"
                    id="nuevo_tipo_pago"
                    first_option="Seleccione tipo de pago"
                    onChange={this.handleNuevoPago}
                    nameLabel="Tipo De Pago"
                  >
                    <option value="E">Efectivo</option>
                    <option value="T">Transferencia</option>
                  </MySelect>
                  <MySelect
                    bootstrap="col-lg-8 col-12"
                    id="beneficiario"
                    first_option="Seleccione un beneficiario"
                    onChange={this.handleBeneficiario}
                    nameLabel="Beneficiarios"
                  >
                    {this.props.beneficiarios.map((ben) => (
                      <option value={ben.id} key={ben.id}>
                        {ben.nombre}
                      </option>
                    ))}
                  </MySelect>
                  <MyInput
                    type="text"
                    bootstrap="col-lg-4 col-12"
                    id="cuenta_banco"
                    value={this.state.cuentaBanco}
                    onChange={this.handleCuentaBanco}
                    nameLabel="Cuenta Banco"
                    disabled={this.state.nuevoTipoPago === "E"}
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-4 col-12"
                    id="factura_operacion"
                    value={this.state.banco}
                    onChange={this.handleBanco}
                    nameLabel="Banco"
                    disabled={this.state.nuevoTipoPago === "E"}
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-4 col-12"
                    id="factura_operacion"
                    value={this.state.factura}
                    onChange={this.handleFactura}
                    nameLabel="Factura"
                  />
                </FormGroup>
                <FormGroup className="row mb-0">
                  <MyTextArea
                    bootstrap="col-12"
                    id="observaciones_operacion"
                    onChange={this.handleObservaciones}
                    value={this.state.observaciones}
                    nameLabel="Observaciones"
                  />
                </FormGroup>
                {this.state.nuevoTipoPago === "E" && (
                  <FormGroup className="row mb-0 mt-2 mt-lg-0">
                    <MyDropzone
                      handleArchivo={this.handleArchivo}
                      accept="image/*, .pdf, .jpg, .jpeg, .png"
                    />
                    <div className="col-12 my-2 d-flex justify-content-center flex-wrap">
                      {this.state.archivo_select.map((archivo, index) => (
                        <Badge color="light" className="border">
                          {archivo.name}&nbsp;
                          <span
                            style={{ cursor: "pointer" }}
                            id={index}
                            onClick={this.handleEliminaArchivo}
                          >
                            &times;
                          </span>
                        </Badge>
                      ))}
                    </div>
                    <br></br>
                  </FormGroup>
                )}
                <FormGroup className="row mb-0">
                  <Button
                    color="primary"
                    className="col-12 mb-2"
                    onClick={this.handleAgrega_pago}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </FormGroup>

                <Table size="sm" className="mt-2" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>Tipo De Pago</td>
                      <td>Beneficiario</td>
                      <td>Banco</td>
                      <td>Importe</td>
                      <td>Fecha</td>
                      <td>Factura</td>
                      <td>Estado</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.lista_pagos.map((pago) => (
                      <tr key={pago.id}>
                        <td className="text-center">
                          {utility.get_name_pagos(
                            pago.tpo_pago,
                            this.props.phxcc
                          )}
                        </td>
                        <td className="text-center">
                          <Input
                            type="text"
                            defaultValue={pago.beneficiario.nombre}
                            id={`${pago.id}_beneficiario`}
                            onChange={(e) =>
                              this.handleModifica_beneficiario(
                                e,
                                pago.beneficiario.id
                              )
                            }
                          />
                        </td>
                        <td className="text-center">
                          {pago.metodo_pago === "E" ? (
                            "Efectivo"
                          ) : (
                            <Input
                              type="text"
                              defaultValue={pago.beneficiario.banco}
                              id={`${pago.id}_banco`}
                              onChange={(e) =>
                                this.handleModifica_banco(
                                  e,
                                  pago.beneficiario.id
                                )
                              }
                            />
                          )}
                        </td>
                        <td className="text-center">
                          <Input
                            type="text"
                            defaultValue={pago.cantidad}
                            id={`${pago.id}_cantidad`}
                            onChange={(e) =>
                              this.handleModifica_cantidad(e, pago)
                            }
                          />
                        </td>
                        <td className="text-center">
                          <Input
                            type="date"
                            defaultValue={pago.fecha}
                            id={`${pago.id}_fecha`}
                            onChange={(e) => this.handleModifica_fecha(e, pago)}
                          />
                        </td>
                        <td className="text-center">
                          <Input
                            type="text"
                            defaultValue={pago.factura}
                            id={`${pago.id}_factura`}
                            onChange={(e) =>
                              this.handleModifica_factura(e, pago)
                            }
                          />
                        </td>
                        <td className="text-center">
                          {pago.status === "A" ? (
                            "Alta"
                          ) : pago.status === "P" ? (
                            <Button
                              color="primary"
                              size="sm"
                              data-id={pago.id}
                              onClick={this.handlePendiente}
                            >
                              Pendiente
                            </Button>
                          ) : pago.status === "L" ? (
                            <>
                              <div>Liquidado</div>
                              <FontAwesomeIcon
                                icon={faEye}
                                style={{ cursor: "pointer" }}
                                onClick={() => this.handleEyeClick(pago.id)}
                              />
                            </>
                          ) : pago.status === "C" ? (
                            "Comprobado"
                          ) : pago.status === "K" ? (
                            "Cancelado"
                          ) : (
                            "Sin Estatus"
                          )}
                        </td>
                        <td className="text-center">
                          <Button
                            color="danger"
                            size="sm"
                            data-id={pago.id}
                            onClick={this.handleElimina_pago}
                          >
                            Cancelar
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Form>

          <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
            <ModalHeader toggle={this.toggle}></ModalHeader>
            <ModalBody>
              <Form className="container-fluid">
                <FormGroup className="row mb-0 mt-2 mt-lg-0">
                  <MyDropzone
                    handleArchivo={this.handleArchivo}
                    accept="image/*, .pdf, .jpg, .jpeg, .png"
                  />
                  <div className="col-12 my-2 d-flex justify-content-center flex-wrap">
                    {this.state.archivo_select.map((archivo, index) => (
                      <Badge color="light" className="border">
                        {archivo.name}&nbsp;
                        <spam
                          style={{ cursor: "pointer" }}
                          id={index}
                          onClick={this.handleEliminaArchivo}
                        >
                          &times;
                        </spam>
                      </Badge>
                    ))}
                  </div>
                  <br></br>
                  <div className="col-lg-3 col-12 d-flex justify-content-center align-items-center">
                    <p className="mb-1">Sin Archivo</p>&nbsp;&nbsp;
                    <label className="switch" htmlFor="archivos_pendiente">
                      <input
                        type="checkbox"
                        id="archivos_pendiente"
                        onChange={this.handleArchivos}
                      />
                      <div className="slider round"></div>
                    </label>
                  </div>
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="info" onClick={this.handlePagoModal}>
                Pago
              </Button>
              <Button color="primary" onClick={this.toggle}>
                Cerrar
              </Button>
            </ModalFooter>
          </Modal>
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={this.handleImpresion}>
            Impresión
          </Button>
          <Button color="primary" onClick={this.handleSolPago}>
            Solicitud Pagos
          </Button>
          <Button color="info" onClick={this.handleModifica_pago}>
            Modificar Pagos
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalPago;
