import axios from "axios";
import React, { Component } from "react";
import {
  Spinner,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import "../../assets/style.css";
import * as message from "./messages/messages";
import Paginacion from "../Pagination";
import ConsultaEspecifica from "./SearchSpecific";
import * as utility from "./utils/utility";
import classnames from "classnames";

class TableSol extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      pagination: { count: 1, results: [] },
      paginationDos: { count: 1, results: [] },
      pagination_orden: { count: 1, results: [] },
      page_actual: 1,
      tabla_orden: false,
      arr_ingresos: [],
      arr_pagos: [],
      arr_terceros: [],
      sol_checked: [],

      toogleTap_referencia: "1",
    };
    this.toogleTap_Referencia = this.toogleTap_Referencia.bind(this);
    this.toogleTap_ReferenciaDos = this.toogleTap_ReferenciaDos.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_ingresos, "ingresos");
    this.methodGET_API(utility.url_contribuciones, "pagos");
    this.methodGET_API(utility.url_phxcc, "tercero");
    
    if (this.props.on_cliente === false){
        this.methodGET_API(utility.url_paginacion, "pagination");
    }
    else
      this.methodPOST_API(utility.url_paginacion, "pagination", {
        cliente: this.props.on_cliente,
      });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps) {
    if (this.props.actualizar !== prevProps.actualizar) {
      this.handlePage(this.state.page_actual);
      this.props.update(0);
    }
    if (this.props.actualizar_pago !== prevProps.actualizar_pago) {
      this.handlePage(this.state.page_actual);
      this.setState({ sol_checked: [] }, () => {
        this.props.checked(this.state.sol_checked);
      });
      this.props.update_pago(0);
    }
  }
  toogleTap_Referencia = (value) => {
    this.setState({ toogleTap_referencia: value });
  };

  toogleTap_ReferenciaDos = (value) => {
    this.methodGET_API(utility.url_consulta_sin_referencia, "paginationDos");
    this.setState({ toogleTap_referencia: value });
  };

  handlePage = (page_actual) => {
    if (this.props.on_cliente === false)
      this.setState({ page_actual }, () => {
        this.methodGET_API(utility.url_paginacion, "pagination");
      });
    else
      this.setState({ page_actual }, () => {
        this.methodPUT_API(utility.url_paginacion, "pagination", {
          cliente: this.props.on_cliente,
        });
      });
  };
  handlePageOrden = () => {
    this.methodGET_API(utility.url_consulta_orden, "pagination_orden");
  };
  methodGET_API = (url, state) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "pagination":
              if (this._isMounted === true){
                this.setState({
                    pagination: {
                      count: response.data.count,
                      results: response.data.results,
                    },
                  });
              }
              break;
              case "paginationDos":
              if (this._isMounted === true){
                  this.setState({
                    paginationDos: {
                      count: response.data.count,
                      results: response.data.results,
                    },
                  });
              }
              break;
            case "pagination_orden":
              if (this._isMounted === true)
                this.setState({
                  pagination_orden: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                });
              break;
            case "ingresos":
              if (this._isMounted === true)
                this.setState({
                  arr_ingresos: response.data.results,
                });
              break;
            case "pagos":
              if (this._isMounted === true)
                this.setState({
                  arr_pagos: response.data.results,
                });
              break;
            case "tercero":
              if (this._isMounted === true)
                this.setState({
                  arr_terceros: response.data.results,
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    if (state === "pagination" || state === "pagination_orden")
      url_intern += `?page=${this.state.page_actual}`;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "pagination":
              if (this._isMounted === true)
                this.setState({
                  pagination: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                });
              break;
              case "paginationDos":
                if (this._isMounted === true)
                  this.setState({
                    paginationDos: {
                      count: response.data.count,
                      results: response.data.results,
                    },
                  });
                break;
            case "pagination_orden":
              if (this._isMounted === true)
                this.setState({
                  pagination_orden: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPUT_API = (url, state, data) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "pagination":
              if (this._isMounted === true)
                this.setState({
                  pagination: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                });
              break;
            case "pagination_orden":
              if (this._isMounted === true)
                this.setState({
                  pagination_orden: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  onChecked = (e) => {
    let checked = [...this.state.sol_checked];
    let sol = e.target.id;
    let sol_complete = this.state.pagination.results.find(
      (data) => data.solicitud_ant[0].id_solicitud === parseInt(sol.slice(4, 6))
    );
    if (e.target.checked) {
      checked.push(sol_complete);
      this.setState(
        {
          sol_checked: checked,
        },
        () => {
          this.props.checked(this.state.sol_checked);
        }
      );
    } else {
      checked = checked.filter(
        (index) =>
          index.solicitud_ant[0].id_solicitud !==
          sol_complete.solicitud_ant[0].id_solicitud
      );
      this.setState(
        {
          sol_checked: checked,
        },
        () => {
          this.props.checked(this.state.sol_checked);
        }
      );
    }
  };
  chageSpecificTable = (data_specific) => {
    if (this.state.tabla_orden === false)
      this.setState({ page_actual: 1 }, () => {
        this.methodPOST_API(
          utility.url_paginacion,
          "pagination",
          data_specific
        );
      });
    else
      this.methodPOST_API(
        utility.url_consulta_orden,
        "pagination_orden",
        data_specific
      );

      if(this.state.toogleTap_referencia === "2")
      this.setState({ page_actual: 1 }, () => {
        this.methodPOST_API(
          utility.url_paginacion,
          "paginationDos",
          data_specific
        );
      });
  };
  handleTablaOrdenes = (status) => {
    if (status === false) this.setState({ tabla_orden: status });
    else
      this.setState({ tabla_orden: status }, () => {
        this.handlePageOrden(this.state.page_actual);
      });
  };
  render() {
    return (
      <React.Fragment>
        <ConsultaEspecifica
         toogleTap_referencia={this.state.toogleTap_referencia}
          onChangeSpecific={this.chageSpecificTable}
          onBack={
            this.state.tabla_orden ? this.handlePageOrden : this.handlePage
          }
          page_actual={this.state.page_actual}
          changeTable={this.handleTablaOrdenes}
          on_cliente={this.props.on_cliente}
          on_nivel={this.props.on_nivel}
        />
        <Nav className="mb-1" tabs>
          <NavItem className="pointer-cursor">
            <NavLink
              onClick={() => this.toogleTap_Referencia("1")}
              className={classnames({
                active: this.state.toogleTap_referencia === "1",
              })}
            >
              Con Referencia
            </NavLink>
          </NavItem>
          <NavItem className="pointer-cursor">
            <NavLink
              onClick={() => this.toogleTap_ReferenciaDos("2")}
              className={classnames({
                active: this.state.toogleTap_referencia === "2",
              })}
            >
              Sin Referencia
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.toogleTap_referencia}>
          <TabPane tabId="1">
            {this.state.tabla_orden === false ? (
              <div className="overflow-auto tm-table">
                <Table size="sm" id="tabla_solicitud_anticipos" bordered hover>
                  <thead className="text-center header-table ">
                    <tr>
                      <th>No. Solicitud</th>
                      <th>No. Notificación</th>
                      <th>Cliente</th>
                      <th>Referencia</th>
                      <th>Fecha</th>
                      <th>Observaciones</th>
                      <th>Tipo de Movimiento</th>
                      <th>Concepto</th>
                      <th>Cargo</th>
                    </tr>
                  </thead>
                  <tbody className="body-table hover-gray">
                    {this.state.pagination.results.length === 0 ? (
                      <tr>
                        <td colSpan="12" align="center">
                          <Spinner
                            style={{ width: "10rem", height: "10rem" }}
                            color="danger"
                            type="grow"
                            className="align-self-center"
                          />
                        </td>
                      </tr>
                    ) : (
                      <React.Fragment>
                        {this.state.pagination.results.map((ref) => {
                          let sum_cargo = 0;
                          return (
                            <tr key={ref.solicitud_ant[0].id_solicitud}>
                              <td className="text-center">
                                <p className="mb-0">
                                  {ref.solicitud_ant[0].id_solicitud}
                                </p>
                              </td>
                              <td>
                                {ref.solicitud_ant[0].status === "C" ? (
                                  <p className="text-center status-table">
                                    CANCELADA
                                  </p>
                                ) : ref.solicitud_ant[0].id_deposito ===
                                  null ? (
                                  <div className="d-flex justify-content-center my-auto">
                                    <input
                                      type="checkbox"
                                      id={`sol_${ref.solicitud_ant[0].id_solicitud}`}
                                      onChange={this.onChecked}
                                    />
                                    <label
                                      htmlFor={`sol_${ref.solicitud_ant[0].id_solicitud}`}
                                      className="check-circle"
                                      style={{ width: "30px" }}
                                    ></label>
                                  </div>
                                ) : (
                                  <p className="text-center">
                                    {ref.solicitud_ant[0].id_deposito}
                                  </p>
                                )}
                              </td>
                              <td className="text-center">
                                <p className="mb-0">{ref.cliente}</p>
                              </td>
                              <td className="text-center">
                                <p className="mb-0">{ref.referencia}</p>
                              </td>
                              <td className="text-center">
                                <p
                                  className="mb-0 mx-auto"
                                  style={{ width: "90px" }}
                                >
                                  {ref.solicitud_ant[0].fecha}
                                </p>
                              </td>
                              <td className="text-center">
                                <p
                                  className="mb-0 mx-auto"
                                  style={{ width: "350px" }}
                                >
                                  {ref.solicitud_ant[0].observaciones}
                                </p>
                              </td>
                              <td className="text-center">
                                <p className="mb-0 text-white">-</p>
                                {ref.solicitud_ant[0].movimientos.map(
                                  (mov, index, array) => {
                                    return (
                                      <p
                                        className="mb-0 text-center mx-auto"
                                        style={{ width: "150px" }}
                                        key={mov.tipo_solicitud + "mov" + index}
                                      >
                                        {utility.tipo_solicitud(
                                          mov.tipo_solicitud
                                        )}
                                      </p>
                                    );
                                  }
                                )}
                              </td>
                              <td className="text-center">
                                <p className="mb-0 text-white">-</p>
                                {ref.solicitud_ant[0].movimientos.map(
                                  (mov, index, array) => {
                                    return (
                                      <p
                                        className="mb-0 text-center"
                                        key={mov.clave_concepto + "con" + index}
                                      >
                                        {utility.get_type_concepto_tabla(
                                          mov.clave_concepto,
                                          mov.tipo_solicitud,
                                          this.state.arr_ingresos,
                                          this.state.arr_pagos,
                                          this.state.arr_terceros
                                        )}
                                      </p>
                                    );
                                  }
                                )}
                              </td>
                              <td className="text-center">
                                <p className="mb-0 text-white">-</p>
                                {ref.solicitud_ant[0].movimientos.map(
                                  (mov, index, array) => {
                                    sum_cargo += parseFloat(mov.monto);
                                    return (
                                      <p
                                        className="mb-0 text-center mx-auto"
                                        style={{ width: "90px" }}
                                        key={mov.monto + "car" + index}
                                      >
                                        $ {mov.monto}
                                      </p>
                                    );
                                  }
                                )}
                                <hr className="my-0" />
                                <p className="mb-0 text-center">
                                  <b>$ {sum_cargo.toFixed(2)}</b>
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                      </React.Fragment>
                    )}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className="overflow-auto tm-table">
                <Table size="sm" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>No. Orden</td>
                      <td>Fecha Alta</td>
                      <td>Fecha Notificación</td>
                      <td>No. Solicitud</td>
                      <td>Referencia</td>
                      <td>Tipo de Movimiento</td>
                      <td>Concepto</td>
                      <td>Importe</td>
                    </tr>
                  </thead>
                  <tbody className="body-table hover-gray">
                    {this.state.pagination_orden.results.map((orden) => (
                      <tr key={orden.id_deposito}>
                        <td>
                          <p className="text-center mb-0">
                            {orden.id_deposito}
                          </p>
                        </td>
                        <td>
                          <p className="text-center mb-0">{orden.fecha_alta}</p>
                        </td>
                        <td>
                          <p className="text-center mb-0">
                            {orden.fecha_notificacion}
                          </p>
                        </td>
                        <td>
                          <p className="mb-0 text-center text-white">-</p>
                          {orden.solicitudes.map((sol) => {
                            const solicitud = (
                              <p
                                className="mb-0 text-center mx-auto"
                                style={{ width: "90px" }}
                              >
                                {sol.id_solicitud}
                              </p>
                            );
                            return (
                              <React.Fragment key={sol.id_solicitud}>
                                {solicitud}
                                {sol.movimientos.map((mov) => (
                                  <p
                                    className="mb-0 text-center text-white"
                                    key={sol.id_solicitud}
                                  >
                                    -
                                  </p>
                                ))}
                              </React.Fragment>
                            );
                          })}
                        </td>
                        <td>
                          <p className="mb-0 text-center text-white">-</p>
                          {orden.solicitudes.map((sol) => {
                            const referencia = (
                              <p
                                className="mb-0 text-center mx-auto"
                                style={{ width: "90px" }}
                              >
                                {sol.referencia}
                              </p>
                            );
                            return (
                              <React.Fragment key={sol.id_solicitud}>
                                {referencia}
                                {sol.movimientos.map((mov) => (
                                  <p
                                    className="mb-0 text-center text-white"
                                    key={sol.id_solicitud}
                                  >
                                    -
                                  </p>
                                ))}
                              </React.Fragment>
                            );
                          })}
                        </td>
                        <td>
                          <p className="mb-0 text-center text-white">-</p>
                          {orden.solicitudes.map((sol) => {
                            const espacio = (
                              <p className="mb-0 text-center text-white">-</p>
                            );
                            return (
                              <React.Fragment key={sol.id_solicitud}>
                                {espacio}
                                {sol.movimientos.map((mov) => (
                                  <p
                                    className="mb-0 text-center"
                                    key={sol.id_solicitud}
                                  >
                                    {utility.tipo_solicitud(mov.tipo_solicitud)}
                                  </p>
                                ))}
                              </React.Fragment>
                            );
                          })}
                        </td>
                        <td>
                          <p className="mb-0 text-center text-white">-</p>
                          {orden.solicitudes.map((sol) => {
                            const espacio = (
                              <p className="mb-0 text-center text-white">-</p>
                            );
                            return (
                              <React.Fragment key={sol.id_solicitud}>
                                {espacio}
                                {sol.movimientos.map((mov) => (
                                  <p
                                    className="mb-0 text-center"
                                    key={sol.id_solicitud}
                                  >
                                    {utility.get_type_concepto(
                                      mov.clave_concepto,
                                      mov.tipo_solicitud,
                                      this.state.arr_ingresos,
                                      this.state.arr_pagos,
                                      this.state.arr_terceros
                                    )}
                                  </p>
                                ))}
                              </React.Fragment>
                            );
                          })}
                        </td>
                        <td>
                          <p className="mb-0 text-center text-white">-</p>
                          {orden.solicitudes.map((sol) => {
                            const espacio = (
                              <p className="mb-0 text-center text-white">-</p>
                            );
                            return (
                              <React.Fragment key={sol.id_solicitud}>
                                {espacio}
                                {sol.movimientos.map((mov) => (
                                  <p
                                    className="mb-0 text-center"
                                    key={sol.id_solicitud}
                                  >
                                    $ {mov.monto}
                                  </p>
                                ))}
                              </React.Fragment>
                            );
                          })}
                          <hr className="my-0" />
                          <p className="mb-0 text-center">
                            <b>$ {orden.importe}</b>
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </TabPane>

          <TabPane tabId="2">
          {this.state.tabla_orden === false ? (
              <div className="overflow-auto tm-table">
                <Table size="sm" id="tabla_solicitud_anticiposDos" bordered hover>
                  <thead className="text-center header-table ">
                    <tr>
                      <th>No. Solicitud</th>
                      <th>Fecha</th>
                      <th>Observaciones</th>
                      <th>Tipo de Movimiento</th>
                      <th>Concepto</th>
                      <th>Cargo</th>
                    </tr>
                  </thead>
                  <tbody className="body-table hover-gray">
                    {this.state.paginationDos.results.length === 0 ? (
                      <tr>
                        <td colSpan="12" align="center">
                          <Spinner
                            style={{ width: "10rem", height: "10rem" }}
                            color="danger"
                            type="grow"
                            className="align-self-center"
                          />
                        </td>
                      </tr>
                    ) : (
                      <React.Fragment>
                        {this.state.paginationDos.results.map((ref) => {
                          let sum_cargo = 0;
                          return (
                            <tr key={ref.id_solicitud}>
                              <td className="text-center">
                                <p className="mb-0">
                                  {ref.id_solicitud}
                                </p>
                              </td>
                              <td className="text-center">
                                <p
                                  className="mb-0 mx-auto"
                                  style={{ width: "90px" }}
                                >
                                  {ref.fecha}
                                </p>
                              </td>
                              <td className="text-center">
                                <p
                                  className="mb-0 mx-auto"
                                  style={{ width: "350px" }}
                                >
                                  {ref.observaciones}
                                </p>
                              </td>
                              <td className="text-center">
                                <p className="mb-0 text-white">-</p>
                                {ref.movimientos.map(
                                  (mov, index, array) => {
                                    return (
                                      <p
                                        className="mb-0 text-center mx-auto"
                                        style={{ width: "150px" }}
                                        key={mov.tipo_solicitud + "mov" + index}
                                      >
                                        {utility.tipo_solicitud(
                                          mov.tipo_solicitud
                                        )}
                                      </p>
                                    );
                                  }
                                )}
                              </td>
                              <td className="text-center">
                                <p className="mb-0 text-white">-</p>
                                {ref.movimientos.map(
                                  (mov, index, array) => {
                                    return (
                                      <p
                                        className="mb-0 text-center"
                                        key={mov.clave_concepto + "con" + index}
                                      >
                                        {utility.get_type_concepto_tabla(
                                          mov.clave_concepto,
                                          mov.tipo_solicitud,
                                          this.state.arr_ingresos,
                                          this.state.arr_pagos,
                                          this.state.arr_terceros
                                        )}
                                      </p>
                                    );
                                  }
                                )}
                              </td>
                              <td className="text-center">
                                <p className="mb-0 text-white">-</p>
                                {ref.movimientos.map(
                                  (mov, index, array) => {
                                    sum_cargo += parseFloat(mov.monto);
                                    return (
                                      <p
                                        className="mb-0 text-center mx-auto"
                                        style={{ width: "90px" }}
                                        key={mov.monto + "car" + index}
                                      >
                                        $ {mov.monto}
                                      </p>
                                    );
                                  }
                                )}
                                <hr className="my-0" />
                                <p className="mb-0 text-center">
                                  <b>$ {sum_cargo.toFixed(2)}</b>
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                      </React.Fragment>
                    )}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className="overflow-auto tm-table">
                <Table size="sm" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>No. Orden</td>
                      <td>Fecha Alta</td>
                      <td>Fecha Notificación</td>
                      <td>No. Solicitud</td>
                      <td>Referencia</td>
                      <td>Tipo de Movimiento</td>
                      <td>Concepto</td>
                      <td>Importe</td>
                    </tr>
                  </thead>
                  <tbody className="body-table hover-gray">
                    {this.state.pagination_orden.results.map((orden) => (
                      <tr key={orden.id_deposito}>
                        <td>
                          <p className="text-center mb-0">
                            {orden.id_deposito}
                          </p>
                        </td>
                        <td>
                          <p className="text-center mb-0">{orden.fecha_alta}</p>
                        </td>
                        <td>
                          <p className="text-center mb-0">
                            {orden.fecha_notificacion}
                          </p>
                        </td>
                        <td>
                          <p className="mb-0 text-center text-white">-</p>
                          {orden.solicitudes.map((sol) => {
                            const solicitud = (
                              <p
                                className="mb-0 text-center mx-auto"
                                style={{ width: "90px" }}
                              >
                                {sol.id_solicitud}
                              </p>
                            );
                            return (
                              <React.Fragment key={sol.id_solicitud}>
                                {solicitud}
                                {sol.movimientos.map((mov) => (
                                  <p
                                    className="mb-0 text-center text-white"
                                    key={sol.id_solicitud}
                                  >
                                    -
                                  </p>
                                ))}
                              </React.Fragment>
                            );
                          })}
                        </td>
                        <td>
                          <p className="mb-0 text-center text-white">-</p>
                          {orden.solicitudes.map((sol) => {
                            const referencia = (
                              <p
                                className="mb-0 text-center mx-auto"
                                style={{ width: "90px" }}
                              >
                                {sol.referencia}
                              </p>
                            );
                            return (
                              <React.Fragment key={sol.id_solicitud}>
                                {referencia}
                                {sol.movimientos.map((mov) => (
                                  <p
                                    className="mb-0 text-center text-white"
                                    key={sol.id_solicitud}
                                  >
                                    -
                                  </p>
                                ))}
                              </React.Fragment>
                            );
                          })}
                        </td>
                        <td>
                          <p className="mb-0 text-center text-white">-</p>
                          {orden.solicitudes.map((sol) => {
                            const espacio = (
                              <p className="mb-0 text-center text-white">-</p>
                            );
                            return (
                              <React.Fragment key={sol.id_solicitud}>
                                {espacio}
                                {sol.movimientos.map((mov) => (
                                  <p
                                    className="mb-0 text-center"
                                    key={sol.id_solicitud}
                                  >
                                    {utility.tipo_solicitud(mov.tipo_solicitud)}
                                  </p>
                                ))}
                              </React.Fragment>
                            );
                          })}
                        </td>
                        <td>
                          <p className="mb-0 text-center text-white">-</p>
                          {orden.solicitudes.map((sol) => {
                            const espacio = (
                              <p className="mb-0 text-center text-white">-</p>
                            );
                            return (
                              <React.Fragment key={sol.id_solicitud}>
                                {espacio}
                                {sol.movimientos.map((mov) => (
                                  <p
                                    className="mb-0 text-center"
                                    key={sol.id_solicitud}
                                  >
                                    {utility.get_type_concepto(
                                      mov.clave_concepto,
                                      mov.tipo_solicitud,
                                      this.state.arr_ingresos,
                                      this.state.arr_pagos,
                                      this.state.arr_terceros
                                    )}
                                  </p>
                                ))}
                              </React.Fragment>
                            );
                          })}
                        </td>
                        <td>
                          <p className="mb-0 text-center text-white">-</p>
                          {orden.solicitudes.map((sol) => {
                            const espacio = (
                              <p className="mb-0 text-center text-white">-</p>
                            );
                            return (
                              <React.Fragment key={sol.id_solicitud}>
                                {espacio}
                                {sol.movimientos.map((mov) => (
                                  <p
                                    className="mb-0 text-center"
                                    key={sol.id_solicitud}
                                  >
                                    $ {mov.monto}
                                  </p>
                                ))}
                              </React.Fragment>
                            );
                          })}
                          <hr className="my-0" />
                          <p className="mb-0 text-center">
                            <b>$ {orden.importe}</b>
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </TabPane>
          
        </TabContent>

        <Paginacion
          actual={this.state.page_actual}
          count={
            this.state.tabla_ordenes === 0
              ? this.state.pagination.count
              : this.state.pagination_orden.count
          }
          paginate={
            this.state.tabla_ordenes === 0
              ? this.handlePage
              : this.handlePageOrden
          }
        />
      </React.Fragment>
    );
  }
}
export default TableSol;
