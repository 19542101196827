/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un operacion. 
*/
import axios from "axios";
import React, { Component } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Collapse,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  Spinner,
  ModalFooter,
} from "reactstrap";
import MyInput from "../../../utils/custom/MyInput";
import MyTextArea from "../../../utils/custom/MyTextArea";
import * as message from "../../../utils/messages/message";
import MyDropzone from "../utils/MyDropzone";
import * as utility from "../utils/utility";
import CustomSelect from "../../../utils/custom/CustomSelect";
import XMLParser from "react-xml-parser";
import swal from "@sweetalert/with-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";

class ModalExpediente extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      folio: "",
      numero_select: "",
      nombre_select: "",
      empleado: "",
      proveedor_text: "Seleccione un proveedor",
      proveedor_id: "",
      tipo_text: "Seleccione un tipo de operación",
      tipo_id: "",
      registro_entrada: "",
      guia_principal: "",
      guia_secundaria: "",
      consolidadora_text: "Seleccione una consolidadora",
      consolidadora_id: "",
      aduana: "",
      patente_text: "Seleccione una patente",
      patente_id: "",
      procedencia_text: "Seleccione procedencia",
      procedencia_id: "",
      numero_pedido: "",
      cantidad: "",
      fecha_alta: "",
      fecha_llegada: "",
      fecha_despacho: "",
      descripcion: "",
      referencia_archivo: "",
      tipo_archivo_text: "Seleccione el tipo de archivo",
      tipo_archivo_id: "",
      num_operacion: "",
      observaciones: "",
      referencia: "",
      tipo_alta: "",

      archivo_select: [],
      archivos: [],
      nombre_suggestions: [],
      patentes_select: [],
      tipo_operacion: [
        { clave: 0, nombre: "Importación" },
        { clave: 1, nombre: "Exportación" },
      ],
      tipo_archivo_options: [
        { clave: "guia", nombre: "Guia" },
        { clave: "anexo_guia", nombre: "Anexo de Guia" },
        { clave: "hoja_calculo", nombre: "Hoja de Calculo" },
        { clave: "pedimento", nombre: "Pedimento" },
        { clave: "anexo_pedimento", nombre: "Anexo de Pedimento" },
        { clave: "manifestacion_valor", nombre: "Manifestación de Valor" },
        { clave: "cove", nombre: "COVE" },
        { clave: "e_documento", nombre: "e-Documentos" },
        { clave: "doda", nombre: "DODA" },
      ],

      toogle_alta: false,
      toogle_archivos: false,
      toogle_envia: false,
      enable_toogle_archvos: true, //Habilitar bot'on archivos

      archivo_xml: null,
      archivo_pdf: null,
      archivo_valido: false,
      enable_add: true,
      comprobante_campos: {},
      emisor_campos: {},
      receptor_campos: {},
      timbre_campos: {},

      tipo_transaccion_array: [
        { id: "CC", text: "Comprobable del cliente" },
        { id: "CE", text: "Comprobable de la empresa" },
        { id: "PC", text: "Por comprobar del cliente" },
        { id: "PE", text: "Por comprobar de la empresa" },
      ],
      tipo_movimiento_array: [
        { id: 0, nombre: "Transacción" },
        { id: 1, nombre: "Cheque" },
      ],
      parametros: [],
      tap_factura: "1",
      aduanadesc: "",
      patenteDesc: "",
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.tipo_archivo_text !== prevState.tipo_archivo_text) {
      // Actualizar archivo_select al estado deseado cuando tipo_archivo_text cambia
      this.setState({ archivo_select: [] });
    }
  }
  toogleArchivos = () => {
    this.setState((prevState) => ({
      toogle_archivos: !prevState.toogle_archivos,
      toogle_alta: false,
      toogle_envia: false,
    }));
  };
  toogleAlta = () => {
    this.setState((prevState) => ({
      toogle_alta: !prevState.toogle_alta,
      toogle_archivos: false,
      toogle_envia: false,
    }));
  };
  toogleEnvia = () => {
    this.setState((prevState) => ({
      toogle_envia: !prevState.toogle_envia,
      toogle_archivos: false,
      toogle_alta: false,
    }));
  };
  /* ------ Bloque de funciones que nos permitiran obtener el texto en los inputs ------ */
  handleFolio = (e) => {
    let folio = e.target.value;
    this.setState({ folio });
  };
  handleReferencia_archivo = (e) => {
    let referencia_archivo = e.target.value;
    this.setState({ referencia_archivo });
  };
  handleEmpleado = (e) => {
    let empleado = e.target.value;
    this.setState({ empleado });
  };
  handleProveedor = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let proveedor_text = e[label];
    let proveedor_id = e[label_id];
    this.setState({ proveedor_text, proveedor_id });
  };
  handleTipo = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_text = e[label];
    let tipo_id = e[label_id];
    this.setState({ tipo_text, tipo_id });
  };
  handleRegistro_entrada = (e) => {
    let registro_entrada = e.target.value;
    this.setState({ registro_entrada });
  };
  handleGuia_principal = (e) => {
    let guia_principal = e.target.value;
    this.setState({ guia_principal });
  };
  handleGuia_secundaria = (e) => {
    let guia_secundaria = e.target.value;
    this.setState({ guia_secundaria });
  };
  handleConsolidadora = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let consolidadora_text = e[label];
    let consolidadora_id = e[label_id];
    this.setState({ consolidadora_text, consolidadora_id });
  };
  handleAduana = (e) => {
    let aduana = e.target.value;
    let patentes_select = this.state.patentes_select;
    for (let i = 0; i < this.props.patentes.length; i++)
      if (this.props.patentes[i].aduana === aduana)
        patentes_select.push(this.props.patentes[i]);
    this.setState({ aduana, patentes_select });
  };
  handlePatente = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let patente_text = e[label];
    let patente_id = e[label_id];
    this.setState({ patente_text, patente_id });
  };
  handleProcedencia = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let procedencia_text = e[label];
    let procedencia_id = e[label_id];
    this.setState({ procedencia_text, procedencia_id });
  };
  handleNumero_pedido = (e) => {
    let numero_pedido = e.target.value;
    this.setState({ numero_pedido });
  };
  handleClase = (e) => {
    let clase = e.target.value;
    this.setState({ clase });
  };
  handleCodigo_fase = (e) => {
    let codigo_fase = e.target.value;
    this.setState({ codigo_fase });
  };
  handlePeso_bruto = (e) => {
    let peso_bruto = e.target.value;
    this.setState({ peso_bruto });
  };
  handleCantidad = (e) => {
    let cantidad = e.target.value;
    this.setState({ cantidad });
  };
  handleNum_operacion = (e) => {
    let num_operacion = e.target.value;
    this.setState({ num_operacion });
  };
  handleObservacion = (e) => {
    let observaciones = e.target.value;
    this.setState({ observaciones });
  };
  handleFecha_alta = (e) => {
    let fecha_alta = e.target.value;
    this.setState({ fecha_alta });
  };
  handleFecha_llegada = (e) => {
    let fecha_llegada = e.target.value;
    this.setState({ fecha_llegada });
  };
  handleFecha_despacho = (e) => {
    let fecha_despacho = e.target.value;
    this.setState({ fecha_despacho });
  };
  handleDescripcion = (e) => {
    let descripcion = e.target.value;
    this.setState({ descripcion });
  };
  handleTipo_archivo = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_archivo_text = e[label];
    let tipo_archivo_id = e[label_id];
    this.setState({ tipo_archivo_text, tipo_archivo_id });
  };
  handleArchivo = (file) => {
    let archivo = file;
    if (archivo.type === "text/xml") {
      let arch = new FileReader();
      arch.onload = (event) => {
        this.setState({ archivo_xml: archivo });
        this.handleXMLS(event.target.result);
      };
      arch.readAsText(archivo);
    }
    let archvios_arr = this.state.archivo_select;
    archvios_arr.push(file);
    this.setState({ archivo_select: archvios_arr });
  };
  handleEliminaArchivo = (e) => {
    let archivos_arr = this.state.archivo_select;
    archivos_arr.splice(parseInt(e.target.id), 1);
    this.setState({ archivo_select: archivos_arr });
  };
  handleAgregaArchivo = () => {
    let archivos = this.state.archivos;
    let tipo_archivo_id = this.state.tipo_archivo_id;
    let documentos = this.state.archivo_select;
    let data = { tipo_archivo_id, documentos };
    archivos.push(data);
    this.setState({ archivos, archivo_select: [] });
  };
  /* ------ Fin del bloque de funciones que nos permitiran obtener el texto en los inputs ------ */
  handleAlta = () => {
    message
      .message_confimacion("¿Desea continuar con la operación alta?", "")
      .then((res) => {
        if (res) {
          if (this.state.tipo_text === "Exportación") {
            this.setState({ tipo_id: "1" });
          } else {
            this.setState({ tipo_id: "0" });
          }

          let data = {
            folio: this.state.folio,
            cliente: this.state.numero_select,
            empleado: localStorage.getItem("id_usr"),
            tipo: this.state.tipo_id,
            registro_entrada: this.state.registro_entrada,
            fecha_llegada: this.state.fecha_llegada,
            guia_principal: this.state.guia_principal,
            consolidadora: this.state.consolidadora_id,
            proveedor: this.state.proveedor_id,
            procedencia: this.state.procedencia_id,
            descripcion: this.state.descripcion,
            no_pedido: this.state.numero_pedido,
            patente: this.state.patente_text,
            num_operacion: this.state.num_operacion,
            observaciones: this.state.observaciones,
            aduana: this.state.aduana,
            guia_secundaria: this.state.guia_secundaria,
            fecha_despacho: this.state.fecha_despacho,
          };
          utility.eliminaCamposPOST(data);
          if (utility.validadCamposPOST(data).length > 0)
            message.message_modal(
              "Falta completar los siguientes campos:",
              utility.validadCamposPOST(data),
              400
            );
          else this.methodPOST_API(utility.url_alta_operacion, "alta", data);
        }
      });
  };
  handleCompletar = () => {
    if (this.state.folio != "") {
      this.methodPOST_API(utility.url_consulta_alerta, "consulta_alerta", {
        folio: this.state.folio,
      });
    } else {
      message.message_modal("Advertencia", "Debe tener un folio", 400);
    }
  };
  buildFormData(formData, data, parentKey) {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;
      formData.append(parentKey, value);
    }
  }
  jsonToFormData(data) {
    const formData = new FormData();
    this.buildFormData(formData, data);
    return formData;
  }
  handleUpload_archivo = () => {
    let data = {
      referencia: this.state.referencia_archivo,
      archivos: this.state.archivos,
    };
    axios
      .post(
        "/DjangoWeb/ExpedienteApp/cargar/archivo_pdf",
        this.jsonToFormData(data),
        { headers: { "Content-type": "multipart/form-data" } }
      )
      .then((response) => {
        this.handleCerrar();
        message.message_modal(
          response.data.title,
          response.data.text,
          response.status
        );
        this.props.update(1);
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  handleLimpiar = () => {
    this.setState({
      folio: "",
      numero_select: "",
      nombre_select: "",
      empleado: "",
      proveedor_text: "Seleccione un proveedor",
      proveedor_id: "",
      tipo_text: "Seleccione un tipo de operación",
      tipo_id: "",
      registro_entrada: "",
      guia_principal: "",
      guia_secundaria: "",
      consolidadora_text: "Seleccione una consolidadora",
      consolidadora_id: "",
      aduana: "",
      patente_text: "Seleccione una patente",
      patente_id: "",
      procedencia_text: "Seleccione procedencia",
      procedencia_id: "",
      numero_pedido: "",
      cantidad: "",
      fecha_alta: "",
      fecha_llegada: "",
      fecha_despacho: "",
      descripcion: "",
      referencia_archivo: "",
      tipo_archivo_text: "Seleccione el tipo de archivo",
      tipo_archivo_id: "",
      num_operacion: "",
      observaciones: "",
      referencia: "",
      tipo_alta: "",

      archivo_select: [],
      archivos: [],
      nombre_suggestions: [],
      patentes_select: [],
      tipo_operacion: [
        { clave: 0, nombre: "Importación" },
        { clave: 1, nombre: "Exportación" },
      ],
      tipo_archivo_options: [
        { clave: "guia", nombre: "Guia" },
        { clave: "anexo_guia", nombre: "Anexo de Guia" },
        { clave: "hoja_calculo", nombre: "Hoja de Calculo" },
        { clave: "pedimento", nombre: "Pedimento" },
        { clave: "anexo_pedimento", nombre: "Anexo de Pedimento" },
        { clave: "manifestacion_valor", nombre: "Manifestación de Valor" },
        { clave: "cove", nombre: "COVE" },
        { clave: "e_documento", nombre: "e-Documentos" },
        { clave: "doda", nombre: "DODA" },
      ],

      toogle_alta: false,
      toogle_archivos: false,
      toogle_envia: false,
      enable_toogle_archvos: true, //Habilitar bot'on archivos

      archivo_xml: null,
      archivo_pdf: null,
      archivo_valido: false,
      enable_add: true,
      comprobante_campos: {},
      emisor_campos: {},
      receptor_campos: {},
      timbre_campos: {},

      tipo_transaccion_array: [
        { id: "CC", text: "Comprobable del cliente" },
        { id: "CE", text: "Comprobable de la empresa" },
        { id: "PC", text: "Por comprobar del cliente" },
        { id: "PE", text: "Por comprobar de la empresa" },
      ],
      tipo_movimiento_array: [
        { id: 0, nombre: "Transacción" },
        { id: 1, nombre: "Cheque" },
      ],
      parametros: [],
      tap_factura: "1",
      aduanadesc: "",
      patenteDesc: "",
    });
  };
  handleCerrar = () => {
    this.handleLimpiar();
    this.props.toogleExpediente();
  };
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "alta":
              this.setState({
                enable_toogle_archvos: false,
                referencia_archivo: response.data.results.referencia,
              });
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            case "consulta_alerta":
              this.setState({
                patente_text: response.data.results.patente,
                aduana: response.data.results.aduana,
                numero_select: response.data.results.cliente,
                nombre_select: utility.get_name_cliente(
                  response.data.results.cliente,
                  this.props.clientes
                ),
                guia_principal: response.data.results.guia_principal,
                consolidadora_text: response.data.results.consolidadora,
                fecha_llegada: response.data.results.fecha_llegada,
                observaciones: response.data.results.observaciones,
                tipo_alta: response.data.results.tipo_alta,
              });
              if (this.state.tipo_alta === 0) {
                this.setState({ tipo_text: "Importación" });
              }
              if (this.state.tipo_alta === 1) {
                this.setState({ tipo_text: "Exportación" });
              }
              let options =
                document.getElementById("patente_operacion").options;
              let options_consolidadoras = document.getElementById(
                "consolidadora_operacion"
              ).options;
              for (let i = 0; i < options.length; i++)
                if (options[i].value === this.state.patente_text)
                  document.getElementById("patente_operacion").value =
                    options[i].value;
              for (let i = 0; i < options_consolidadoras.length; i++)
                if (
                  parseInt(options_consolidadoras[i].value) ===
                  this.state.consolidadora_text
                )
                  document.getElementById("consolidadora_operacion").value =
                    options_consolidadoras[i].value;

              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") text_add = "";
    else if (numero_cliente.length <= 4) {
      switch (numero_cliente.length) {
        case 1:
          text_add = "000";
          text_add += numero_cliente;
          break;
        case 2:
          text_add = "00";
          text_add += numero_cliente;
          break;
        case 3:
          text_add = "0";
          text_add += numero_cliente;
          break;
        case 4:
          text_add = numero_cliente;
          break;
        default:
          return null;
      }
    } else text_add = "";
    for (let i = 0; i < this.props.clientes.length; i++) {
      if (text_add.length === 0) {
        document.getElementById("nombre_cliente_expediente").value = "";
        this.suggestionSelected("");
        break;
      }
      if (text_add === this.props.clientes[i].numero) {
        document.getElementById("nombre_cliente_expediente").value =
          this.props.clientes[i].nombre;
        this.suggestionSelected(this.props.clientes[i].nombre);
        break;
      }
    }
  };
  suggestionSelected = (value) => {
    let number = utility.get_number_cliente(value, this.props.clientes);
    if (number === undefined) number = "";
    document.getElementById("numero_cliente_expediente").value = number;
    this.setState({
      nombre_select: value,
      numero_select: number,
      nombre_suggestions: [],
    });
  };
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_nombres_clientes(this.props.clientes)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      nombre_suggestions: suggestions,
      nombre_select: value,
    });
  };
  renderSuggestion = () => {
    if (this.state.nombre_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */

  handleXMLS = (XMLData) => {
    let comprobante = new XMLParser().parseFromString(XMLData);
    let emisor = comprobante.getElementsByTagName("cfdi:Emisor")[0];
    let receptor = comprobante.getElementsByTagName("cfdi:Receptor")[0];
    let timbre = comprobante.getElementsByTagName("tfd:TimbreFiscalDigital")[0];
    if (
      emisor === undefined ||
      receptor === undefined ||
      timbre === undefined
    ) {
      message.message_modal(
        "Error",
        "La factura no cumple con el formato del SAT",
        500
      );
      this.setState({
        comprobante_campos: {},
        emisor_campos: {},
        receptor_campos: {},
        timbre_campos: {},
        enable_add: true,
        archivo: {},
      });
    } else
      this.setState(
        {
          comprobante_campos: {
            folio: comprobante.attributes.Folio,
            fecha: comprobante.attributes.Fecha,
            forma_de_pago: comprobante.attributes.FormaPago,
            no_certificado: comprobante.attributes.NoCertificado,
            moneda: comprobante.attributes.Moneda,
            total: comprobante.attributes.Total,
            metodo_pago: comprobante.attributes.MetodoPago,
            tipo_comprobante: comprobante.attributes.TipoDeComprobante,
            numero_pago: comprobante.attributes.NumCtaPago,
            certificado: comprobante.attributes.Certificado,
            version: comprobante.attributes.Version,
          },
          emisor_campos: {
            rfc: emisor.attributes.Rfc,
            nombre: emisor.attributes.Nombre,
          },
          receptor_campos: {
            rfc: receptor.attributes.Rfc,
          },
          timbre_campos: {
            version: timbre.attributes.Version,
            uuid: timbre.attributes.UUID,
            fecha_timbrado: timbre.attributes.FechaTimbrado,
            sello_cfd: timbre.attributes.SelloCFD,
            sello_sat: timbre.attributes.SelloSAT,
          },
        },
        () => {
          this.methodPOST_SOAP();
        }
      );
  };

  methodPOST_SOAP = () => {
    let sr =
      '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">' +
      "<soapenv:Header/>" +
      "<soapenv:Body>" +
      "<tem:Consulta>" +
      "<!--Optional:-->" +
      `<tem:expresionImpresa><![CDATA[?re=${this.state.emisor_campos.rfc}&rr=${this.state.receptor_campos.rfc}&tt=${this.state.comprobante_campos.total}
                &id=${this.state.timbre_campos.uuid}]]></tem:expresionImpresa>` +
      "</tem:Consulta>" +
      "</soapenv:Body>" +
      "</soapenv:Envelope>";
    swal({
      title: "Validando factura...",
      buttons: false,
      closeOnClickOutside: false,
      content: (
        <div>
          <Spinner
            color="success"
            style={{
              width: "5rem",
              height: "5rem",
              marginBottom: "26px",
              animation: "1.25s linear infinite spinner-border",
            }}
          >
            Loading...
          </Spinner>
        </div>
      ),
    });
    axios
      .post("/ConsultaCFDIService.svc", sr, {
        headers: {
          "Content-type": 'text/xml;charset="utf-8"',
          Accept: "text/xml",
          SOAPAction: "http://tempuri.org/IConsultaCFDIService/Consulta",
          "cache-control": "no-cache",
          Host: "consultaqr.facturaelectronica.sat.gob.mx",
        },
      })
      .then((res) => {
        let comprobante = new XMLParser().parseFromString(res.data);
        let codigo_estatus =
          comprobante.getElementsByTagName("a:CodigoEstatus")[0];
        let estado = comprobante.getElementsByTagName("a:Estado")[0];
        swal.close();
        if (
          codigo_estatus.value ===
          "S - Comprobante obtenido satisfactoriamente."
        )
          if (estado.value === "Vigente") {
            message.message_modal(
              "Factura valida",
              "Puede agregar el movimiento",
              200
            );
            this.setState({ enable_add: false });
          } else {
            message.message_modal(
              "Factura cancelada",
              "No puede agregar el movimiento",
              400
            );
            this.setState({ enable_add: true });
          }
        else {
          message.message_modal(
            "No existe la factura",
            "Seleccione otro archivo",
            500
          );
          this.setState({ enable_add: true });
        }
      })
      .catch((err) => {
        if (err)
          message.message_modal("Ocurrio un error", "Intentelo de nuevo", 500);
      });
  };

  handleAgregar = (id, inputName) => {
    const inputValue = document.getElementById(id).value;
  
    // Validar si el campo está vacío
    if (inputValue.trim() === "") {
      // Mostrar el mensaje de error con SweetAlert
      swal({
        title: "Campo Vacío",
        text: `El campo ${inputName} está vacío. Por favor ingresa un valor.`,
        icon: "warning",
        button: "Ok",
      });
      return; // Detener la ejecución de la función
    }
  
    // Obtener la lista de valores previos para este tipo de dato
    const valoresPrevios = this.state.parametros
      .filter((archivo) => archivo.tipo === inputName)
      .map((archivo) => archivo.valor);
  
    let nuevosValores;
    // Si ya hay un registro para este tipo de dato, actualizamos sus valores
    if (valoresPrevios.length > 0) {
      nuevosValores = [...valoresPrevios[0].split(", "), inputValue];
    } else {
      // Si no hay registros previos para este tipo de dato, creamos uno nuevo
      nuevosValores = [inputValue];
    }
  
    // Actualizar el estado con el nuevo valor
    const nuevoArchivo = {
      tipo: inputName,
      valor: nuevosValores.join(", "),
    };
  
    // Si ya hay un registro para este tipo de dato, actualizamos su valor
    if (valoresPrevios.length > 0) {
      const parametrosActualizados = this.state.parametros.map((archivo) => {
        if (archivo.tipo === inputName) {
          return nuevoArchivo;
        }
        return archivo;
      });
  
      this.setState({ parametros: parametrosActualizados });
    } else {
      this.setState((prevState) => ({
        parametros: [...prevState.parametros, nuevoArchivo],
      }));
    }
  
    // Limpiar el input
    document.getElementById(id).value = "";
  };
  

  handleFechaInicial = (e) => {
    this.setState({ fechaInicial: e.target.value });
  };

  handleFechaFinal = (e) => {
    this.setState({ fechaFinal: e.target.value });
  };

  handleAduanaP = (e) => {
    this.setState({ aduanadesc: e.target.value });
  };

  handlePatenteP = (e) => {
    this.setState({ patenteDesc: e.target.value });
  };

  handleEliminarValor = (tipo, valor) => {
    // Filtrar los valores para mantener solo aquellos que no coincidan con el valor a eliminar
    const nuevosParametros = this.state.parametros
      .map((archivo) => {
        if (archivo.tipo === tipo) {
          const nuevosValores = archivo.valor
            .split(", ")
            .filter((v) => v !== valor);
          // Si no hay más valores después de la eliminación, eliminar la entrada completa de la tabla
          if (nuevosValores.length === 0) return null;
          return { tipo, valor: nuevosValores.join(", ") };
        }
        return archivo;
      })
      .filter(Boolean); // Eliminar entradas nulas

    // Actualizar el estado con los nuevos parámetros
    this.setState({ parametros: nuevosParametros });
  };

  handleConsultaParametros = () => {
    // Clasificar los parámetros según su tipo
    const parametrosClasificados = {
      fecha_inicial: "",
      fecha_final: "",
      patente: "",
      cliente: [],
      aduana: [],
    };

    this.state.parametros.forEach((archivo) => {
      switch (archivo.tipo) {
        case "Fecha Inicial":
          parametrosClasificados.fecha_inicial = archivo.valor;
          break;
        case "Fecha Final":
          parametrosClasificados.fecha_final = archivo.valor;
          break;
        case "Patente":
          parametrosClasificados.patente = archivo.valor;
          break;
        case "Clientes":
          parametrosClasificados.cliente.push(archivo.valor);
          break;
        case "Aduanas":
          parametrosClasificados.aduana.push(archivo.valor);
          break;
        default:
          break;
      }
    });

    // Eliminar las propiedades con valores vacíos
    Object.keys(parametrosClasificados).forEach((key) => {
      if (
        parametrosClasificados[key] === "" ||
        parametrosClasificados[key].length === 0
      ) {
        delete parametrosClasificados[key];
      }
    });

    // Verificar si hay parámetros para enviar
    if (Object.keys(parametrosClasificados).length === 0) {
      console.log("No hay parámetros para enviar.");
      return;
    }

    axios
      .post(utility.url_paginacion, parametrosClasificados)
      .then((response) => {
        // Manejar la respuesta
        console.log(response.data);
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handleLimpiarTabla = () => {
    this.setState({
      fechaInicial: "",
      fechaFinal: "",
      aduanadesc: "",
      patenteDesc: "",
      numero_select: "",
      nombre_select: "",
      parametros: [],
      archivos: []
    });
  };
  

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_expediente}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>Expediente</ModalHeader>
        <ModalBody>
          <div className="row mb-2 d-flex justify-content-center">
            <div className="col-lg-10 col-12 d-flex justify-content-around">
              <Button color="info" onClick={this.toogleAlta}>
                Descarga
              </Button>
              <Button color="info" onClick={this.toogleArchivos}>
                Valida
              </Button>
              <Button color="info" onClick={this.toogleEnvia}>
                Envía
              </Button>
            </div>
          </div>
          <Collapse isOpen={this.state.toogle_alta}>
            <Card outline color="info">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="date"
                      bootstrap="col-lg-5"
                      id="fecha_inicial"
                      value={this.state.fechaInicial}
                      nameLabel="Fecha Inicial"
                      onClick={() => this.handleFechaInicial("fecha_inicial")}
                      disabled={this.state.parametros.some(
                        (archivo) => archivo.tipo === "Fecha Inicial"
                      )}
                    />
                    <Button
                      color="primary"
                      className="col-lg-1 col-4 mb-2"
                      onClick={() =>
                        this.handleAgregar("fecha_inicial", "Fecha Inicial")
                      }
                      disabled={this.state.parametros.some(
                        (archivo) => archivo.tipo === "Fecha Inicial"
                      )}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <MyInput
                      type="date"
                      bootstrap="col-lg-5"
                      id="fecha_final"
                      value={this.state.fechaFinal}
                      nameLabel="Fecha Final"
                      onChange={this.handleFechaFinal}
                      disabled={this.state.parametros.some(
                        (archivo) => archivo.tipo === "Fecha Final"
                      )}
                    />
                    <Button
                      color="primary"
                      className="col-lg-1 col-4 mb-2"
                      onClick={() =>
                        this.handleAgregar("fecha_final", "Fecha Final")
                      }
                      disabled={this.state.parametros.some(
                        (archivo) => archivo.tipo === "Fecha Final"
                      )}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-5"
                      id="Aduana_desc"
                      value={this.state.aduanadesc}
                      nameLabel="Aduana"
                      onChange={this.handleAduanaP}
                    />
                    <Button
                      color="primary"
                      className="col-lg-1 col-4 mb-2"
                      onClick={() =>
                        this.handleAgregar("Aduana_desc", "Aduanas")
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <MyInput
                      type="text"
                      bootstrap="col-lg-5"
                      id="Patente_desc"
                      value={this.state.patenteDesc}
                      nameLabel="Patente"
                      onChange={this.handlePatenteP}
                      disabled={this.state.parametros.some(
                        (archivo) => archivo.tipo === "Patente"
                      )}
                    />
                    <Button
                      color="primary"
                      className="col-lg-1 col-4 mb-2"
                      onClick={() =>
                        this.handleAgregar("Patente_desc", "Patente")
                      }
                      disabled={this.state.parametros.some(
                        (archivo) => archivo.tipo === "Patente"
                      )}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-5"
                      id="numero_cliente_expediente"
                      onChange={this.nameGenerator}
                      value={this.state.numero_select}
                      nameLabel="Número de Cliente"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="nombre_cliente_expediente"
                      onChange={this.onNameChange}
                      value={this.state.nombre_select}
                      nameLabel="Nombre de Cliente"
                    />
                    {this.renderSuggestion()}
                    <Button
                      color="primary"
                      className="col-lg-1 col-4 mb-2"
                      onClick={() =>
                        this.handleAgregar(
                          "numero_cliente_expediente",
                          "Clientes"
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                </Form>
                {this.state.parametros.length > 0 && (
                  <div>
                    <Table size="sm" className="mt-2" bordered hover responsive>
                      <thead className="text-center header-table">
                        <tr></tr>
                      </thead>
                      <tbody className="body-table">
                        {this.state.parametros.map((archivo, index) => (
                          <tr key={index}>
                            <td>{archivo.tipo}</td>
                            <td>
                              {archivo.valor.split(", ").map((valor, index) => (
                                <div
                                  key={index}
                                  className="d-inline-flex align-items-center border rounded p-1 mr-2"
                                >
                                  <span className="mr-1">{valor}</span>
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    className="text-danger"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.handleEliminarValor(
                                        archivo.tipo,
                                        valor
                                      )
                                    }
                                  />
                                </div>
                              ))}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <FormGroup className="row mb-0 mt-2 mt-lg-0">
                      <Button
                        color="primary"
                        className="col-12"
                        onClick={this.handleConsultaParametros}
                      >
                        Consulta
                      </Button>
                    </FormGroup>
                  </div>
                )}

                <Table size="sm" className="mt-2" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>Referencia</td>
                      <td>Pedimento</td>
                      <td>Fecha</td>
                      <td>Aduana</td>
                      <td>Núm Cliente</td>
                      <td>Nombre Cliente</td>
                      <td>Patente</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.archivos.map((archivo) => (
                      <tr>
                        <td>{archivo.tipo_archivo_id}</td>
                        <td>{archivo.documentos.length}</td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                  <Button
                    color="primary"
                    className="mr-2"
                    onClick={this.handleLimpiarTabla}
                  >
                    Limpiar
                  </Button>
                  <Button
                    color="primary"
                    className="mr-2"
                    onClick={this.handleDescargar}
                  >
                    Descargar
                  </Button>
                  <Button color="success" onClick={this.handleSelecciona}>
                    Seleccionar
                  </Button>
                </FormGroup>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_archivos}>
            <Card outline color="info">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="date"
                      bootstrap="col-lg-5"
                      id="fecha_inicial"
                      value={this.state.fechaInicial}
                      nameLabel="Fecha Inicial"
                      onClick={() => this.handleFechaInicial("fecha_inicial")}
                      disabled={this.state.parametros.some(
                        (archivo) => archivo.tipo === "Fecha Inicial"
                      )}
                    />
                    <Button
                      color="primary"
                      className="col-lg-1 col-4 mb-2"
                      onClick={() =>
                        this.handleAgregar("fecha_inicial", "Fecha Inicial")
                      }
                      disabled={this.state.parametros.some(
                        (archivo) => archivo.tipo === "Fecha Inicial"
                      )}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <MyInput
                      type="date"
                      bootstrap="col-lg-5"
                      id="fecha_final"
                      value={this.state.fechaFinal}
                      nameLabel="Fecha Final"
                      onChange={this.handleFechaFinal}
                      disabled={this.state.parametros.some(
                        (archivo) => archivo.tipo === "Fecha Final"
                      )}
                    />
                    <Button
                      color="primary"
                      className="col-lg-1 col-4 mb-2"
                      onClick={() =>
                        this.handleAgregar("fecha_final", "Fecha Final")
                      }
                      disabled={this.state.parametros.some(
                        (archivo) => archivo.tipo === "Fecha Final"
                      )}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-5"
                      id="Aduana_desc"
                      value={this.state.aduanadesc}
                      nameLabel="Aduana"
                      onChange={this.handleAduanaP}
                    />
                    <Button
                      color="primary"
                      className="col-lg-1 col-4 mb-2"
                      onClick={() =>
                        this.handleAgregar("Aduana_desc", "Aduanas")
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <MyInput
                      type="text"
                      bootstrap="col-lg-5"
                      id="Patente_desc"
                      value={this.state.patenteDesc}
                      nameLabel="Patente"
                      onChange={this.handlePatenteP}
                      disabled={this.state.parametros.some(
                        (archivo) => archivo.tipo === "Patente"
                      )}
                    />
                    <Button
                      color="primary"
                      className="col-lg-1 col-4 mb-2"
                      onClick={() =>
                        this.handleAgregar("Patente_desc", "Patente")
                      }
                      disabled={this.state.parametros.some(
                        (archivo) => archivo.tipo === "Patente"
                      )}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-5"
                      id="numero_cliente_expediente"
                      onChange={this.nameGenerator}
                      value={this.state.numero_select}
                      nameLabel="Número de Cliente"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="nombre_cliente_expediente"
                      onChange={this.onNameChange}
                      value={this.state.nombre_select}
                      nameLabel="Nombre de Cliente"
                    />
                    {this.renderSuggestion()}
                    <Button
                      color="primary"
                      className="col-lg-1 col-4 mb-2"
                      onClick={() =>
                        this.handleAgregar(
                          "numero_cliente_expediente",
                          "Clientes"
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                </Form>
                {this.state.parametros.length > 0 && (
                  <div>
                    <Table size="sm" className="mt-2" bordered hover responsive>
                      <thead className="text-center header-table">
                        <tr></tr>
                      </thead>
                      <tbody className="body-table">
                        {this.state.parametros.map((archivo, index) => (
                          <tr key={index}>
                            <td>{archivo.tipo}</td>
                            <td>
                              {archivo.valor.split(", ").map((valor, index) => (
                                <div
                                  key={index}
                                  className="d-inline-flex align-items-center border rounded p-1 mr-2"
                                >
                                  <span className="mr-1">{valor}</span>
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    className="text-danger"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.handleEliminarValor(
                                        archivo.tipo,
                                        valor
                                      )
                                    }
                                  />
                                </div>
                              ))}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <FormGroup className="row mb-0 mt-2 mt-lg-0">
                      <Button
                        color="primary"
                        className="col-12"
                        onClick={this.handleConsultaParametros}
                      >
                        Consulta
                      </Button>
                    </FormGroup>
                  </div>
                )}

                <Table size="sm" className="mt-2" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>Referencia</td>
                      <td>Pedimento</td>
                      <td>M</td>
                      <td>Doda</td>
                      <td>Manifestacion Valor</td>
                      <td>Cove</td>
                      <td>Patente</td>
                      <td>Declaracion de Valor</td>
                      <td>Carta Porte</td>
                      <td>Carta Encomienda</td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.archivos.map((archivo) => (
                      <tr>
                        <td>{archivo.tipo_archivo_id}</td>
                        <td>{archivo.documentos.length}</td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                  <Button
                    color="primary"
                    className="mr-2"
                    onClick={this.handleLimpiarTabla}
                  >
                    Limpiar
                  </Button>
                </FormGroup>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_envia}>
            <Card outline color="info">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="date"
                      bootstrap="col-lg-5"
                      id="fecha_inicial"
                      value={this.state.fechaInicial}
                      nameLabel="Fecha Inicial"
                      onClick={() => this.handleFechaInicial("fecha_inicial")}
                      disabled={this.state.parametros.some(
                        (archivo) => archivo.tipo === "Fecha Inicial"
                      )}
                    />
                    <Button
                      color="primary"
                      className="col-lg-1 col-4 mb-2"
                      onClick={() =>
                        this.handleAgregar("fecha_inicial", "Fecha Inicial")
                      }
                      disabled={this.state.parametros.some(
                        (archivo) => archivo.tipo === "Fecha Inicial"
                      )}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <MyInput
                      type="date"
                      bootstrap="col-lg-5"
                      id="fecha_final"
                      value={this.state.fechaFinal}
                      nameLabel="Fecha Final"
                      onChange={this.handleFechaFinal}
                      disabled={this.state.parametros.some(
                        (archivo) => archivo.tipo === "Fecha Final"
                      )}
                    />
                    <Button
                      color="primary"
                      className="col-lg-1 col-4 mb-2"
                      onClick={() =>
                        this.handleAgregar("fecha_final", "Fecha Final")
                      }
                      disabled={this.state.parametros.some(
                        (archivo) => archivo.tipo === "Fecha Final"
                      )}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-5"
                      id="Aduana_desc"
                      value={this.state.aduanadesc}
                      nameLabel="Aduana"
                      onChange={this.handleAduanaP}
                    />
                    <Button
                      color="primary"
                      className="col-lg-1 col-4 mb-2"
                      onClick={() =>
                        this.handleAgregar("Aduana_desc", "Aduanas")
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <MyInput
                      type="text"
                      bootstrap="col-lg-5"
                      id="Patente_desc"
                      value={this.state.patenteDesc}
                      nameLabel="Patente"
                      onChange={this.handlePatenteP}
                      disabled={this.state.parametros.some(
                        (archivo) => archivo.tipo === "Patente"
                      )}
                    />
                    <Button
                      color="primary"
                      className="col-lg-1 col-4 mb-2"
                      onClick={() =>
                        this.handleAgregar("Patente_desc", "Patente")
                      }
                      disabled={this.state.parametros.some(
                        (archivo) => archivo.tipo === "Patente"
                      )}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-5"
                      id="numero_cliente_expediente"
                      onChange={this.nameGenerator}
                      value={this.state.numero_select}
                      nameLabel="Número de Cliente"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="nombre_cliente_expediente"
                      onChange={this.onNameChange}
                      value={this.state.nombre_select}
                      nameLabel="Nombre de Cliente"
                    />
                    {this.renderSuggestion()}
                    <Button
                      color="primary"
                      className="col-lg-1 col-4 mb-2"
                      onClick={() =>
                        this.handleAgregar(
                          "numero_cliente_expediente",
                          "Clientes"
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                </Form>
                {this.state.parametros.length > 0 && (
                  <div>
                    <Table size="sm" className="mt-2" bordered hover responsive>
                      <thead className="text-center header-table">
                        <tr></tr>
                      </thead>
                      <tbody className="body-table">
                        {this.state.parametros.map((archivo, index) => (
                          <tr key={index}>
                            <td>{archivo.tipo}</td>
                            <td>
                              {archivo.valor.split(", ").map((valor, index) => (
                                <div
                                  key={index}
                                  className="d-inline-flex align-items-center border rounded p-1 mr-2"
                                >
                                  <span className="mr-1">{valor}</span>
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    className="text-danger"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.handleEliminarValor(
                                        archivo.tipo,
                                        valor
                                      )
                                    }
                                  />
                                </div>
                              ))}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <FormGroup className="row mb-0 mt-2 mt-lg-0">
                      <Button
                        color="primary"
                        className="col-12"
                        onClick={this.handleConsultaParametros}
                      >
                        Consulta
                      </Button>
                    </FormGroup>
                  </div>
                )}
                <Table size="sm" className="mt-2" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>Referencia</td>
                      <td>Pedimento</td>
                      <td>Fecha</td>
                      <td>Aduana</td>
                      <td>Núm Cliente</td>
                      <td>Nombre Cliente</td>
                      <td>Patente</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.archivos.map((archivo) => (
                      <tr>
                        <td>{archivo.tipo_archivo_id}</td>
                        <td>{archivo.documentos.length}</td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                  <Button
                    color="primary"
                    className="mr-2"
                    onClick={this.handleLimpiarTabla}
                  >
                    Limpiar
                  </Button>
                  <Button
                    color="primary"
                    className="mr-2"
                    onClick={this.handleEnviar}
                  >
                    Enviar
                  </Button>
                  <Button color="success" onClick={this.handleSelecciona}>
                    Seleccionar
                  </Button>
                </FormGroup>
              </CardBody>
            </Card>
          </Collapse>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalExpediente;
