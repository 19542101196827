/* 
Componente que muestra la información principal en una tabla de Alertas.
*/
import swal from "@sweetalert/with-react";
import axios from "axios";
import React, { Component } from "react";
import { Spinner, Table } from "reactstrap";
import Button from "reactstrap/lib/Button";
import "../../assets/style.css";
import Paginacion from "../Pagination";
import * as message from "./messages/messages";
import ConsultaEspecifica from "./SearchSpecific";
import MyTextArea from "./utils/MyTextArea";
import * as utility from "./utils/utility";
import WebSocketInstance from "../../utils/websocket";

class TableAlerta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      pagination: { count: 1, results: [] },
      checked_recolectar: [],
      checked_llegada: [],
      checked_entrega: [],
      page_actual: 1,
    };
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo, id_notificacion, cliente) => {
    const notificacionObject = {
      tipo: 0,
      aplicacion: "notificacion_embarque",
      motivo: motivo,
      folio: [id_notificacion],
    };
    if (this.props.on_cliente === false)
      notificacionObject["cliente"] = [cliente];
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
    if (this.props.on_cliente === false)
      this.methodGET_API(utility.url_paginacion, "pagination");
    else
      this.methodPUT_API(utility.url_paginacion, "pagination", {
        cliente: this.props.on_cliente,
      });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps) {
    if (this.props.actualizar !== prevProps.actualizar) {
      this.handlePage(this.state.page_actual);
      this.props.update(0);
    }
    if (this.props.checked_llegada !== prevProps.checked_llegada) {
      this.setState({ checked_llegada: this.props.checked_llegada });
      this.props.update(0);
    }
    if (this.props.checked_recolectar !== prevProps.checked_recolectar) {
      this.setState({ checked_recolectar: this.props.checked_recolectar });
      this.props.update(0);
    }
    if (this.props.checked_entrega !== prevProps.checked_entrega) {
      this.setState({ checked_entrega: this.props.checked_entrega });
      this.props.update(0);
    }
  }
  handlePage = (page_actual) => {
    if (this.props.on_cliente === false)
      this.setState({ page_actual }, () => {
        this.methodGET_API(utility.url_paginacion, "pagination");
      });
    else
      this.setState({ page_actual }, () => {
        this.methodPOST_API(utility.url_paginacion, "pagination", {
          cliente: this.props.on_cliente,
        });
      });
  };
  handleRecoleccion = (e) => {
    swal({
      icon: "warning",
      closeOnClickOutside: false,
      title: "¿Recolecto de manera exitosa?",
      text: "Si es asi debe llenar el dato de observaciones.",
      buttons: ["No", "Si"],
      dangerMode: false,
      content: (
        <div>
          <button value={e.target.id} id="data" className="d-none"></button>
          <MyTextArea
            bootstrap="col-12"
            id="observaciones_recolecta"
            nameLabel="Observaciones"
          />
        </div>
      ),
    }).then((res) => {
      if (res) {
        if (
          document.getElementById("observaciones_recolecta").value.length === 0
        )
          message.message_modal(
            "Advertencia",
            "Debe llenar el campo observaciones",
            400
          );
        else {
          this.methodPOST_API(
            utility.url_reporte_recoleccion_hora,
            "recoleccion",
            {
              alertas: [document.getElementById("data").value.slice(0, 4)],
              observaciones: document.getElementById("observaciones_recolecta")
                .value,
            }
          );
          this.sendNotificacionHandler(
            "recolectar",
            document.getElementById("data").value.slice(0, 4),
            parseInt(document.getElementById("data").value.slice(5, 9))
          );
          this.props.update(1);
        }
      } else {
        this.methodPOST_API(
          utility.url_reporte_recoleccion_hora,
          "recoleccion",
          {
            alertas_no: [document.getElementById("data").value.slice(0, 4)],
            observaciones: document.getElementById("observaciones_recolecta")
              .value,
          }
        );
        this.sendNotificacionHandler(
          "recolectar",
          document.getElementById("data").value.slice(0, 4),
          parseInt(document.getElementById("data").value.slice(5, 9))
        );
        this.props.update(1);
      }
    });
  };
  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "pagination":
              if (this._isMounted)
                this.setState({
                  pagination: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma POST
   * @param {String} url URL a consultar
   * @param {String} state Onpción del case
   * @param {Object} data Objeto a mandar atraves del metodo POST
   */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "pagination":
              if (this._isMounted)
                this.setState({
                  pagination: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma POST
   * @param {String} url URL a consultar
   * @param {String} state Onpción del case
   * @param {Object} data Objeto a mandar atraves del metodo POST
   */
  methodPUT_API = (url, state, data) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "pagination":
              if (this._isMounted)
                this.setState({
                  pagination: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite agregar o sacar alertas de un arreglo el cual sirve para hacer el proceso de recolección
   * @param {Event} e Evento generdo por un click en el checkbox
   */
  onChecked = (e) => {
    let checked = [...this.state.checked_recolectar];
    let folio = e.target.id.slice(0, 4);
    let alerta_complete = this.state.pagination.results.find(
      (data) => data.folio === parseInt(folio)
    );
    if (e.target.checked) {
      checked.push(alerta_complete);
      this.setState(
        {
          checked_recolectar: checked,
        },
        () => {
          this.props.checkedRecoleccion(this.state.checked_recolectar);
        }
      );
    } else {
      checked = checked.filter(
        (index) => index.folio !== alerta_complete.folio
      );
      this.setState(
        {
          checked_recolectar: checked,
        },
        () => {
          this.props.checkedRecoleccion(this.state.checked_recolectar);
        }
      );
    }
  };
  /**
   * Función que permite agregar o sacar alertas de un arreglo el cual sirve para hacer el proceso de llegada
   * @param {Event} e Evento generdo por un click en el checkbox
   */
  onCheckedLlegada = (e) => {
    let checked = [...this.state.checked_llegada];
    let folio = e.target.id.slice(0, 4);
    let alerta_complete = this.state.pagination.results.find(
      (data) => data.folio === parseInt(folio)
    );
    if (e.target.checked) {
      checked.push(alerta_complete);
      this.setState(
        {
          checked_llegada: checked,
        },
        () => {
          this.props.checkedLlegada(this.state.checked_llegada);
        }
      );
    } else {
      checked = checked.filter(
        (index) => index.folio !== alerta_complete.folio
      );
      this.setState(
        {
          checked_llegada: checked,
        },
        () => {
          this.props.checkedLlegada(this.state.checked_llegada);
        }
      );
    }
  };
  /**
   * Función que permite agregar o sacar alertas de un arreglo el cual sirve para hacer el proceso de entrega
   * @param {Event} e Evento generdo por un click en el checkbox
   */
  onCheckedEntrega = (e) => {
    let checked = [...this.state.checked_entrega];
    let folio = e.target.id.slice(0, 4);
    let alerta_complete = this.state.pagination.results.find(
      (data) => data.folio === parseInt(folio)
    );
    if (e.target.checked) {
      checked.push(alerta_complete);
      this.setState(
        {
          checked_entrega: checked,
        },
        () => {
          this.props.checkedEntrega(this.state.checked_entrega);
        }
      );
    } else {
      checked = checked.filter(
        (index) => index.folio !== alerta_complete.folio
      );
      this.setState(
        {
          checked_entrega: checked,
        },
        () => {
          this.props.checkedEntrega(this.state.checked_entrega);
        }
      );
    }
  };
  onBackTable = () => {
    if (this.props.on_cliente === false)
      this.methodGET_API(utility.url_paginacion, "pagination");
    else
      this.methodPUT_API(utility.url_paginacion, "pagination", {
        cliente: this.props.on_cliente,
      });
  };
  onChangeFiltrada = (data) => {
    if (this.props.on_cliente === false)
      this.setState({ page_actual: 1 }, () => {
        this.methodPOST_API(utility.url_paginacion, "pagination", data);
      });
    else
      this.setState({ page_actual: 1 }, () => {
        this.methodPOST_API(utility.url_paginacion, "pagination", {
          ...data,
          cliente: this.props.on_cliente,
        });
      });
  };

  // Funcion para obtener el nombre de la consolidadora
  getConsolidadoraName = (numero) => {
    const numeroString = numero.toString();
    const consolidadora = this.props.consolidadoras.find(
      (item) => item.numero === numeroString
    );
    return consolidadora ? consolidadora.nombre : "Desconocido";
  };
  render() {
    return (
      <React.Fragment>
        <ConsultaEspecifica
          onBackTable={this.onBackTable}
          onChangeFiltrada={this.onChangeFiltrada}
          on_cliente={this.props.on_cliente}
        />
        <div className="overflow-auto tm-table">
          <Table size="sm" id="tabla_alerta" bordered hover responsive>
            <thead className="text-center header-table">
              <tr>
                <th className="d-none d-md-table-cell">Cliente</th>
                <th>Número</th>
                <th>Folio</th>
                <th className="d-none d-md-table-cell">Tipo Alta</th>
                <th>Guía</th>
                <th className="d-none d-md-table-cell">Número Vuelo</th>
                <th className="d-none d-md-table-cell">Consolidadora</th>
                <th className="d-none d-md-table-cell">Alta</th>
                <th>Llegada</th>
                <th>Recolección</th>
                <th>Entrega</th>
              </tr>
            </thead>
            <tbody className="body-table">
              {this.state.pagination.results.length === 0 ? (
                <tr>
                  <td colSpan="12" align="center">
                    <Spinner
                      style={{ width: "10rem", height: "10rem" }}
                      color="danger"
                      type="grow"
                      className="align-self-center"
                    />
                  </td>
                </tr>
              ) : (
                <React.Fragment>
                  {this.state.pagination.results.map((alerta) => {
                    if (utility.generar_Dias_Naturales(alerta.fecha_alta) < 10)
                      return (
                        <tr key={alerta.folio} className="border-green">
                          <td className="d-none d-md-table-cell">
                            <p className="mb-0 text-center">
                              {utility.get_name_cliente(
                                alerta.cliente,
                                this.props.clientes
                              )}
                            </p>
                          </td>
                          <td>
                            <p className="mb-0 text-center">{alerta.cliente}</p>
                          </td>
                          <td>
                            <p className="mb-0 text-center">{alerta.folio}</p>
                          </td>
                          <td className="d-none d-md-table-cell">
                            <p className="mb-0 text-center">
                              {alerta.tipo_alta === 0
                                ? "Tráfico"
                                : alerta.tipo_alta === 1
                                ? "Cotizador"
                                : alerta.tipo_alta === 2
                                ? "Clientes"
                                : ""}
                            </p>
                          </td>

                          <td>
                            <p className="mb-0 text-center">
                              {alerta.guia_principal}
                            </p>
                          </td>
                          <td className="d-none d-md-table-cell">
                            <p className="mb-0 text-center">
                              {alerta.numero_vuelo}
                            </p>
                          </td>
                          <td className="d-none d-md-table-cell">
                            <p className="mb-0 text-center">
                              {this.getConsolidadoraName(alerta.consolidadora)}
                            </p>
                          </td>
                          <td className="d-none d-md-table-cell">
                            <p className="mb-0 text-center">
                              {alerta.fecha_alta.slice(0, 10)}
                            </p>
                          </td>
                          <td>
                            {alerta.fecha_llegada === null ? (
                              <div className="d-flex justify-content-center my-auto">
                                <input
                                  type="checkbox"
                                  id={`${alerta.folio}_FLL`}
                                  onChange={this.onCheckedLlegada}
                                />
                                <label
                                  htmlFor={`${alerta.folio}_FLL`}
                                  className="check-circle"
                                  style={{ width: "30px" }}
                                ></label>
                              </div>
                            ) : (
                              <p className="mb-0 text-center">
                                {alerta.fecha_llegada}
                              </p>
                            )}
                          </td>
                          <td>
                            {alerta.fecha_llegada ? (
                              alerta.fecha_recoleccion === null ? (
                                <div className="d-flex justify-content-center my-auto">
                                  <input
                                    type="checkbox"
                                    id={`${alerta.folio}_FR`}
                                    onChange={this.onChecked}
                                  />
                                  <label
                                    htmlFor={`${alerta.folio}_FR`}
                                    className="check-circle"
                                    style={{ width: "30px" }}
                                  ></label>
                                </div>
                              ) : alerta.hora_recoleccion === null ? (
                                <p className="mb-0 text-center">
                                  <Button
                                    color="info"
                                    size="sm"
                                    id={`${alerta.folio}_${alerta.cliente}_HR`}
                                    onClick={this.handleRecoleccion}
                                  >
                                    Recolectar
                                  </Button>
                                </p>
                              ) : (
                                <p className="mb-0 text-center">
                                  {alerta.fecha_recoleccion}
                                </p>
                              )
                            ) : (
                              <p
                                className="mb-0 text-center"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bolder",
                                }}
                              >
                                . . .
                              </p>
                            )}
                          </td>
                          <td>
                            {alerta.fecha_recoleccion &&
                            alerta.hora_recoleccion ? (
                              alerta.fecha_entrega === null ? (
                                <div className="d-flex justify-content-center my-auto">
                                  <input
                                    type="checkbox"
                                    id={`${alerta.folio}_FE`}
                                    onChange={this.onCheckedEntrega}
                                  />
                                  <label
                                    htmlFor={`${alerta.folio}_FE`}
                                    className="check-circle"
                                    style={{ width: "30px" }}
                                  ></label>
                                </div>
                              ) : (
                                <p className="mb-0 text-center">
                                  {alerta.fecha_entrega}
                                </p>
                              )
                            ) : (
                              <p
                                className="mb-0 text-center"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bolder",
                                }}
                              >
                                . . .
                              </p>
                            )}
                          </td>
                        </tr>
                      );
                    else if (
                      utility.generar_Dias_Naturales(alerta.fecha_alta) >= 10 &&
                      utility.generar_Dias_Naturales(alerta.fecha_alta) < 21
                    )
                      return (
                        <tr key={alerta.folio} className="border-yellow">
                          <td className="d-none d-md-table-cell">
                            <p className="mb-0 text-center">
                              {utility.get_name_cliente(
                                alerta.cliente,
                                this.props.clientes
                              )}
                            </p>
                          </td>
                          <td>
                            <p className="mb-0 text-center">{alerta.cliente}</p>
                          </td>
                          <td>
                            <p className="mb-0 text-center">{alerta.folio}</p>
                          </td>
                          <td className="d-none d-md-table-cell">
                            <p className="mb-0 text-center">
                              {alerta.tipo_alta === 0
                                ? "Tráfico"
                                : alerta.tipo_alta === 1
                                ? "Cotizador"
                                : alerta.tipo_alta === 2
                                ? "Clientes"
                                : ""}
                            </p>
                          </td>

                          <td>
                            <p className="mb-0 text-center">
                              {alerta.guia_principal}
                            </p>
                          </td>
                          <td className="d-none d-md-table-cell">
                            <p className="mb-0 text-center">
                              {alerta.numero_vuelo}
                            </p>
                          </td>
                          <td className="d-none d-md-table-cell">
                            <p className="mb-0 text-center">
                              {this.getConsolidadoraName(alerta.consolidadora)}
                            </p>
                          </td>
                          <td className="d-none d-md-table-cell">
                            <p className="mb-0 text-center">
                              {alerta.fecha_alta.slice(0, 10)}
                            </p>
                          </td>
                          <td>
                            {alerta.fecha_llegada === null ? (
                              <div className="d-flex justify-content-center my-auto">
                                <input
                                  type="checkbox"
                                  id={`${alerta.folio}_FLL`}
                                  onChange={this.onCheckedLlegada}
                                />
                                <label
                                  htmlFor={`${alerta.folio}_FLL`}
                                  className="check-circle"
                                  style={{ width: "30px" }}
                                ></label>
                              </div>
                            ) : (
                              <p className="mb-0 text-center">
                                {alerta.fecha_llegada}
                              </p>
                            )}
                          </td>
                          <td>
                            {alerta.fecha_llegada ? (
                              alerta.fecha_recoleccion === null ? (
                                <div className="d-flex justify-content-center my-auto">
                                  <input
                                    type="checkbox"
                                    id={`${alerta.folio}_FR`}
                                    onChange={this.onChecked}
                                  />
                                  <label
                                    htmlFor={`${alerta.folio}_FR`}
                                    className="check-circle"
                                    style={{ width: "30px" }}
                                  ></label>
                                </div>
                              ) : alerta.hora_recoleccion === null ? (
                                <p className="mb-0 text-center">
                                  <Button
                                    color="info"
                                    size="sm"
                                    id={`${alerta.folio}_HR`}
                                    onClick={this.handleRecoleccion}
                                  >
                                    Recolectar
                                  </Button>
                                </p>
                              ) : (
                                <p className="mb-0 text-center">
                                  {alerta.fecha_recoleccion}
                                </p>
                              )
                            ) : (
                              <p
                                className="mb-0 text-center"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bolder",
                                }}
                              >
                                . . .
                              </p>
                            )}
                          </td>
                          <td>
                            {alerta.fecha_recoleccion &&
                            alerta.hora_recoleccion ? (
                              alerta.fecha_entrega === null ? (
                                <div className="d-flex justify-content-center my-auto">
                                  <input
                                    type="checkbox"
                                    id={`${alerta.folio}_FE`}
                                    onChange={this.onCheckedEntrega}
                                  />
                                  <label
                                    htmlFor={`${alerta.folio}_FE`}
                                    className="check-circle"
                                    style={{ width: "30px" }}
                                  ></label>
                                </div>
                              ) : (
                                <p className="mb-0 text-center">
                                  {alerta.fecha_entrega}
                                </p>
                              )
                            ) : (
                              <p
                                className="mb-0 text-center"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bolder",
                                }}
                              >
                                . . .
                              </p>
                            )}
                          </td>
                        </tr>
                      );
                    else
                      return (
                        <tr key={alerta.folio} className="border-red">
                          <td className="d-none d-md-table-cell">
                            <p className="mb-0 text-center">
                              {utility.get_name_cliente(
                                alerta.cliente,
                                this.props.clientes
                              )}
                            </p>
                          </td>
                          <td>
                            <p className="mb-0 text-center">{alerta.cliente}</p>
                          </td>
                          <td>
                            <p className="mb-0 text-center">{alerta.folio}</p>
                          </td>
                          <td className="d-none d-md-table-cell">
                            <p className="mb-0 text-center">
                              {alerta.tipo_alta === 0
                                ? "Tráfico"
                                : alerta.tipo_alta === 1
                                ? "Cotizador"
                                : alerta.tipo_alta === 2
                                ? "Clientes"
                                : ""}
                            </p>
                          </td>

                          <td>
                            <p className="mb-0 text-center">
                              {alerta.guia_principal}
                            </p>
                          </td>
                          <td className="d-none d-md-table-cell">
                            <p className="mb-0 text-center">
                              {alerta.numero_vuelo}
                            </p>
                          </td>
                          <td className="d-none d-md-table-cell">
                            <p className="mb-0 text-center">
                              {this.getConsolidadoraName(alerta.consolidadora)}
                            </p>
                          </td>
                          <td className="d-none d-md-table-cell">
                            <p className="mb-0 text-center">
                              {alerta.fecha_alta.slice(0, 10)}
                            </p>
                          </td>
                          <td>
                            {alerta.fecha_llegada === null ? (
                              <div className="d-flex justify-content-center my-auto">
                                <input
                                  type="checkbox"
                                  id={`${alerta.folio}_FLL`}
                                  onChange={this.onCheckedLlegada}
                                />
                                <label
                                  htmlFor={`${alerta.folio}_FLL`}
                                  className="check-circle"
                                  style={{ width: "30px" }}
                                ></label>
                              </div>
                            ) : (
                              <p className="mb-0 text-center">
                                {alerta.fecha_llegada}
                              </p>
                            )}
                          </td>
                          <td>
                            {alerta.fecha_llegada ? (
                              alerta.fecha_recoleccion === null ? (
                                <div className="d-flex justify-content-center my-auto">
                                  <input
                                    type="checkbox"
                                    id={`${alerta.folio}_FR`}
                                    onChange={this.onChecked}
                                  />
                                  <label
                                    htmlFor={`${alerta.folio}_FR`}
                                    className="check-circle"
                                    style={{ width: "30px" }}
                                  ></label>
                                </div>
                              ) : alerta.hora_recoleccion === null ? (
                                <p className="mb-0 text-center">
                                  <Button
                                    color="info"
                                    size="sm"
                                    id={`${alerta.folio}_HR`}
                                    onClick={this.handleRecoleccion}
                                  >
                                    Recolectar
                                  </Button>
                                </p>
                              ) : (
                                <p className="mb-0 text-center">
                                  {alerta.fecha_recoleccion}
                                </p>
                              )
                            ) : (
                              <p
                                className="mb-0 text-center"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bolder",
                                }}
                              >
                                . . .
                              </p>
                            )}
                          </td>
                          <td>
                            {alerta.fecha_recoleccion &&
                            alerta.hora_recoleccion ? (
                              alerta.fecha_entrega === null ? (
                                <div className="d-flex justify-content-center my-auto">
                                  <input
                                    type="checkbox"
                                    id={`${alerta.folio}_FE`}
                                    onChange={this.onCheckedEntrega}
                                  />
                                  <label
                                    htmlFor={`${alerta.folio}_FE`}
                                    className="check-circle"
                                    style={{ width: "30px" }}
                                  ></label>
                                </div>
                              ) : (
                                <p className="mb-0 text-center">
                                  {alerta.fecha_entrega}
                                </p>
                              )
                            ) : (
                              <p
                                className="mb-0 text-center"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bolder",
                                }}
                              >
                                . . .
                              </p>
                            )}
                          </td>
                        </tr>
                      );
                  })}
                </React.Fragment>
              )}
            </tbody>
          </Table>
        </div>
        <Paginacion
          actual={this.state.page_actual}
          count={this.state.pagination.count}
          paginate={this.handlePage}
        />
      </React.Fragment>
    );
  }
}
export default TableAlerta;
