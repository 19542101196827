import axios from 'axios';
import React, { Component } from 'react';
import { Spinner, Table } from 'reactstrap';
import * as utility from './utils/utility';
import Paginacion from '../Pagination';
import ConsultaEspecifica from './SearchSpecific';
import * as message from '../../utils/messages/message';

class TableConciliacion extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            pagination: { count: 1, results: [] },
            totales: {
                p_sub: 0,
                cg_sub: 0,
                sat_sub: 0,
                p_iva: 0,
                cg_iva: 0,
                sat_iva: 0
            },
            page_actual: 1,
        }
    }
    componentDidMount() {
        this._isMounted = true;
        this.methodGET_API(utility.url_paginacion, 'pagination');
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (this.props.actualizar !== prevProps.actualizar) {
            this.handlePage(this.state.page_actual);
            this.props.update(0);
        }
    }
    handlePage = (page_actual) => {
        this.setState({ page_actual }, () => { this.methodGET_API(utility.url_paginacion, 'pagination') });
    }
    methodGET_API = (url, state) => {
        let url_intern = url;
        if (state === 'pagination')
            url_intern += `?page=${this.state.page_actual}`;
        axios.get(url_intern)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        case 'pagination':
                            if (this._isMounted)
                                this.setState({
                                    pagination: { count: response.data.count, results: response.data.results },
                                    totales: response.data.totales
                                });
                            break;
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal('', '', 500);
            });
    }
    /**
     * Función que permite hacer una petición de forma POST 
     * @param {String} url URL a consultar 
     * @param {String} state Onpción del case  
     * @param {Object} data Objeto a mandar atraves del metodo POST 
     */
    methodPOST_API = (url, state, data) => {
        let url_intern = url;
        if (state === 'pagination')
            url_intern += `?page=${this.state.page_actual}`;
        axios.post(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        case 'pagination':
                            if (this._isMounted)
                                if (response.data.results)
                                    this.setState({
                                        pagination: { count: response.data.count, results: response.data.results },
                                        totales: response.data.totales
                                    });
                                else
                                    message.message_modal(response.data.title, response.data.text, response.status);
                            break;
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    onBackTable = () => {
        this.methodGET_API(utility.url_paginacion, 'pagination')
    }
    onChangeFiltrada = (data) => {
        this.methodPOST_API(utility.url_paginacion, 'pagination', data);
    }
    render() {
        return (
            <React.Fragment>
                <div className="overflow-auto tm-table">
                    <Table size="sm" id="tabla_XXX" bordered hover responsive>
                        <thead className="text-center header-table">
                            <tr>
                                <td>FOLIO</td>
                                <td>UUID SAT</td>
                                <td>UUID SISTEMA</td>
                                <td>IGUALES (UUID)</td>
                                <td>POLIZA SUBTOTAL</td>
                                <td>SISTEMA SUBTOTAL</td>
                                <td>SAT SUBTOTAL</td>
                                <td>IGUALES (SUBTOTAL)</td>
                                <td>POLIZA TOTAL</td>
                                <td>SISTEMA TOTAL</td>
                                <td>SAT TOTAL</td>
                                <td>IGUALES (TOTAL)</td>
                            </tr>
                        </thead>
                        <tbody className="body-table">
                            {
                                this.state.pagination.results.length === 0 ? (
                                    <tr>
                                        <td colSpan="12" align="center">
                                            <Spinner
                                                style={{ width: '10rem', height: '10rem' }}
                                                color="danger"
                                                type="grow"
                                                className="align-self-center"
                                            />
                                        </td>
                                    </tr>
                                ) : (this.state.pagination.results.map(data => (
                                    <tr>
                                        <td><p className="mb-0 text-center">{data.folio}</p></td>
                                        <td><p className="mb-0 text-center">{data.sat_uuid}</p></td>
                                        <td><p className="mb-0 text-center">{data.cg_uuid}</p></td>
                                        <td>
                                            {data.empata_uuid ?
                                                <p style={{ color: "#5ad239", fontWeight: "bold" }} className="mb-0 text-center">SI</p> :
                                                <p style={{ color: "#ff2019", fontWeight: "bold" }} className="mb-0 text-center">NO</p>
                                            }
                                        </td>
                                        <td><p className="mb-0 text-center">{data.p_subtotal}</p></td>
                                        <td><p className="mb-0 text-center">{data.cg_subtotal}</p></td>
                                        <td><p className="mb-0 text-center">{data.sat_subtotal}</p></td>
                                        <td>
                                            {data.empata_sub ?
                                                <p style={{ color: "#5ad239", fontWeight: "bold" }} className="mb-0 text-center">SI</p> :
                                                <p style={{ color: "#ff2019", fontWeight: "bold" }} className="mb-0 text-center">NO</p>
                                            }
                                        </td>
                                        <td><p className="mb-0 text-center">{data.p_total}</p></td>
                                        <td><p className="mb-0 text-center">{data.cg_total}</p></td>
                                        <td><p className="mb-0 text-center">{data.sat_total}</p></td>
                                        <td>
                                            {data.empata_total ?
                                                <p style={{ color: "#5ad239", fontWeight: "bold" }} className="mb-0 text-center">SI</p> :
                                                <p style={{ color: "#ff2019", fontWeight: "bold" }} className="mb-0 text-center">NO</p>
                                            }
                                        </td>
                                    </tr>
                                )))
                            }
                            <tr className="text-center">
                                <td>SUBTOTAL POLIZA:</td>
                                <td style={{fontWeight:"bold"}}>{this.state.totales.p_sub.toFixed(2)}</td>
                                <td>SUBTOTAL SISTEMA:</td>
                                <td style={{fontWeight:"bold"}}>{this.state.totales.cg_sub.toFixed(2)}</td>
                                <td>SUBTOTAL SAT:</td>
                                <td style={{fontWeight:"bold"}}>{this.state.totales.sat_sub.toFixed(2)}</td>
                                <td>IVA POLIZA:</td>
                                <td style={{fontWeight:"bold"}}>{this.state.totales.p_iva.toFixed(2)}</td>
                                <td>IVA SISTEMA:</td>
                                <td style={{fontWeight:"bold"}}>{this.state.totales.cg_iva.toFixed(2)}</td>
                                <td>IVA SAT:</td>
                                <td style={{fontWeight:"bold"}}>{this.state.totales.sat_iva.toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </React.Fragment>
        );
    }
}
export default TableConciliacion;