/*
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera un vista sobre elrecorido de recolección de guias.
*/
import { faEye, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  Collapse,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap";
import * as message from "../messages/messages";
import MyInput from "../utils/MyInput";
import * as utility from "../utils/utility";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalOperacion extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      toogle_recoleccion: false,
      recolectadas: [],
      proceso: [],
      no_recolectadas: [],
      fecha: "",
      enable_pdf: false,
    };
  }
  toogleRecoleccion = () => {
    this.setState((prevState) => ({
      toogle_recoleccion: !prevState.toogle_recoleccion,
    }));
  };
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleFecha = (e) => {
    let fecha = e.target.value;
    this.setState({ fecha });
  };
  handleReporte = () => {
     this.methodPOST_API(utility.url_consulta_reporte, "reporte", {
      fecha: this.state.fecha,
    }); 
    this.setState({ enable_pdf: true }, () => {
      this.methodPDF_API(
        utility.url_pdf_recoleccion,
        "consulta_reporte",
        this.state.fecha
      );
    });
  };
  handleClose = () => {
    this.setState({
      toogle_recoleccion: false,
      recolectadas: [],
      proceso: [],
      no_recolectadas: [],
      fecha: "",
      enable_pdf: false,
    });
    this.props.toogleOperacion();
  };
  methodPDF_API = (url, state, num) => {
    let url_intern = url + `${num}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta_reporte":
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);
              document.getElementById("dowload_pdf_reporte_recoleccion").href =
                fileURL;
              document.getElementById("window_pdf_reporte_recoleccion").href =
                fileURL;
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma POST
   * @param {String} url URL a consultar
   * @param {String} state Onpción del case
   * @param {Object} data Objeto a mandar atraves del metodo POST
   */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "reporte":
              if (this._isMounted)
                this.setState({
                  recolectadas: response.data.results.recolectadas,
                  proceso: response.data.results.proceso,
                  no_recolectadas: response.data.results.no_recolectadas,
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_operacion}
        toggle={this.handleClose}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleClose}>Reportes</ModalHeader>
        <ModalBody>
          <div className="row mb-2 d-flex justify-content-center">
            <div className="col-lg-10 col-12 d-flex justify-content-around">
              <Button color="info" onClick={this.toogleRecoleccion}>
                Recolección
              </Button>
            </div>
          </div>
          <Collapse isOpen={this.state.toogle_recoleccion}>
            <Card outline color="info">
              <CardBody>
                <div className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="date"
                      bootstrap="col-lg-10 col-8"
                      id="fecha_recoleccion"
                      nameLabel="Fecha"
                      required="false"
                      onChange={this.handleFecha}
                      value={this.state.fecha}
                    />
                    <Button
                      color="primary"
                      className="col-lg-2 col-4 mb-2"
                      onClick={this.handleReporte}
                    >
                      Consultar
                    </Button>
                  </FormGroup>
                </div>
                <h4>Guias en proceso</h4>
                <Table
                  size="sm"
                  id="tabla_recoleccion_proceso"
                  bordered
                  hover
                  responsive
                >
                  <thead className="text-center header-table">
                    <tr>
                      <th>Folio</th>
                      <th>Cliente</th>
                      <th>consolidadora</th>
                      <th>Guia Principal</th>
                      <th>Fecha Llegada</th>
                      <th>Fecha Recolección</th>
                      <th>Hora Recolección</th>
                      <th>Observaciones</th>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.proceso.map((guia) => (
                      <tr>
                        <td>{guia.folio}</td>
                        <td>{guia.cliente}</td>
                        <td>{guia.consolidadora}</td>
                        <td>{guia.guia_principal}</td>
                        <td>{guia.fecha_llegada}</td>
                        <td>{guia.fecha_recoleccion}</td>
                        <td>{guia.hora_recoleccion}</td>
                        <td>{guia.observaciones}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <h4>Guias recolectadas</h4>
                <Table
                  size="sm"
                  id="tabla_recoleccion_recolectadas"
                  bordered
                  hover
                  responsive
                >
                  <thead className="text-center header-table">
                    <tr>
                      <th>Folio</th>
                      <th>Cliente</th>
                      <th>Consolidadora</th>
                      <th>Guia Principal</th>
                      <th>Fecha Llegada</th>
                      <th>Fecha Recolección</th>
                      <th>Hora Recolección</th>
                      <th>Observaciones</th>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.recolectadas.map((guia) => (
                      <tr>
                        <td>{guia.folio}</td>
                        <td>{guia.cliente}</td>
                        <td>{guia.consolidadora}</td>
                        <td>{guia.guia_principal}</td>
                        <td>{guia.fecha_llegada}</td>
                        <td>{guia.fecha_recoleccion}</td>
                        <td>{guia.hora_recoleccion}</td>
                        <td>{guia.observaciones}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <h4>Guias no recolectadas</h4>
                <Table
                  size="sm"
                  id="tabla_recoleccion_no_recolectadas"
                  bordered
                  hover
                  responsive
                >
                  <thead className="text-center header-table">
                    <tr>
                      <th>Folio</th>
                      <th>Cliente</th>
                      <th>consolidadora</th>
                      <th>Guia Principal</th>
                      <th>Fecha Llegada</th>
                      <th>Fecha Recolección</th>
                      <th>Hora Recolección</th>
                      <th>Observaciones</th>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.no_recolectadas.map((guia) => (
                      <tr>
                        <td>{guia.folio}</td>
                        <td>{guia.cliente}</td>
                        <td>{guia.consolidadora}</td>
                        <td>{guia.guia_principal}</td>
                        <td>{guia.fecha_llegada}</td>
                        <td>{guia.fecha_recoleccion}</td>
                        <td>{guia.hora_recoleccion}</td>
                        <td>{guia.observaciones}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-center">
                  <LinkD
                    href="#"
                    id="dowload_pdf_reporte_recoleccion"
                    download={`Reporte de recolección ${this.state.fecha}`}
                    className="mr-4"
                  />
                  <LinkV
                    href="#"
                    id="window_pdf_reporte_recoleccion"
                    target={this.state.enable_pdf ? "_blank" : "_self"}
                  />
                </div>
                <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                  <Button
                    type="button"
                    color="primary"
                    className="mr-3"
                    onClick={this.handleClose}
                  >
                    Cerrar
                  </Button>
                </FormGroup>
              </CardBody>
            </Card>
          </Collapse>
        </ModalBody>
      </Modal>
    );
  }
}
export default ModalOperacion;
