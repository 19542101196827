import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Alert, Button, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import '../../assets/style.css';
import * as authActions from '../../utils/actions/auth';
//import * as utility from './utility';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: "",
            password: "",
            error: false
        }
        this.handleUser = this.handleUser.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
    }
    /**
     * Cambiara el estado.
     * @param e evento generado por el DOM
     */
    handleUser = (e) => { this.setState({ user: e.target.value }) }
    /**
     * Cambiara el estado.
     * @param e evento generado por el DOM
     */
    handlePassword = (e) => { this.setState({ password: e.target.value }); }
    /**
     * Cambiara el estado.
     * @param e evento generado por el DOM
     */
    handleSubmit = (e) => {
        e.preventDefault();
        let history = this.props.history;
        let location = this.props.location;
        let { from } = location.state || { from: { pathname: "/" } }
        this.props.onAuth(this.state.user, this.state.password);
        history.push(from);
    }

    //Render del componente.
    render() {
        let errorMessage = null;
        if (this.props.error && this.props.error !== undefined)
            errorMessage = (
                <div className="row d-flex justify-content-center">
                    <div className="col-md-7 col-12">
                        <Alert color="danger" className="d-flex justify-content-center text-center" >
                            {this.props.error.response.data.text}
                        </Alert>
                    </div>
                </div>);
        return (
            <div className="container-fluid my-3">
                {
                    this.props.loading ?
                        (<div className="abs-center">
                            <Spinner style={{ width: '10rem', height: '10rem' }} color="dark" className="align-self-center" />
                        </div>) :
                        localStorage.getItem('csrfToken') ?
                            (<Redirect to="/" />) :
                            (<div className="abs-center" >
                                {errorMessage}
                                <div className="row d-flex justify-content-center">
                                    <div className="rounded-top text-center col-lg-6 col-sm-7 mx-2 header-login">
                                        <h1 className="py-2 mb-0">Inicia Sesión</h1>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center">
                                    <div className="rounded-bottom col-lg-6 col-sm-7 mx-2 body-login">
                                        <Form onSubmit={this.handleSubmit} className="px-3 py-3" >
                                            <FormGroup>
                                                <Label for="usuario" className="text-white">Usuario:</Label>
                                                <Input type="text" name="usuario" placeholder="Usuario" id="user_login" onChange={this.handleUser} required/>
                                                <Label for="contraseña" className="text-white mt-2">Contraseña:</Label>
                                                <Input type="password" name="contraseña" placeholder="Contraseña" id="user_password" onChange={this.handlePassword}  required/>
                                            </FormGroup>
                                            <FormGroup className="d-flex justify-content-end mb-0">
                                                <Button type="submit" className="button-login col-sm-12 col-md-2" color="none" >Acceder</Button>
                                            </FormGroup>
                                        </Form>
                                    </div>
                                </div>
                            </div>)
                }
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: (username, password) => dispatch(authActions.authLogin(username, password))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);