export const url_pagination_cuentas = '/DjangoWeb/CuentasAuxiliaresApp/consulta/cuentas_auxiliares/paginacion/principal';
export const url_codigos_sat = '/DjangoWeb/BaseApp/consulta/codigos_sat/lista';
export const url_verify = '/DjangoWeb/UsuariosApp/verica/usuario';

export const url_auxiliares = '/DjangoWeb/CuentasAuxiliaresApp/consulta/cuentas_auxiliares/paginacion/principal';
export const url_consulta_cuenta = "/DjangoWeb/CuentasAuxiliaresApp/consulta/cuenta_auxiliar";
export const url_crea_modifica_cuenta = "/DjangoWeb/CuentasAuxiliaresApp/crea_modifica/cuenta_auxiliares";
export const url_elimina_cuenta = "/DjangoWeb/CuentasAuxiliaresApp/elimina/cuenta_auxiliares";


/**
 * Función que nos permite filtar la tabla por el input general que serra de tipo text.
 */
 export const general_search = (e) => {
    e.preventDefault();
    let tr = document.getElementById('tabla_cuentas_auxiliares').getElementsByTagName('tr');
    let data_input = document.getElementById('busqueda_general_cuentas_auxiliares').value.toUpperCase();
    for (let i = 0; i < tr.length; i++) {
        let mostrar = false;
        let data = tr[i].getElementsByTagName('p');
        for (let j = 0; j < data.length; j++) {
            if (data_input === "") {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
            else if (data[j].textContent.toUpperCase().includes(data_input)) {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "rgba(0,123,255,1)";
                data[j].style.color = "#fff";
            }
            else {
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}

/**
 * Función que obtiene la descripción de los codigos del sat
 * @param {array} arreglo 
 * @returns {array} Arreglo de nombres.
 */
export const get_descripcion_codigo_sat = (arreglo) => {
    var aux = [];
    for (var i = 0; i < arreglo.length; i++)
        aux.push(arreglo[i].descripcion);
    return (aux);
}

/**
 * Finción que permite el retorno del número de codigo del sat.
 * @param {Sring} nombre Nombre del cliente. 
 * @param {Array} array Arreglo de clientes.
 * @returns {String} Número del cliente.
 */
export const get_number_cliente = (nombre, array) => {
    for (let i = 0; i < array.length; i++)
        if (nombre === array[i].descripcion)
            return (array[i].codigo)
}

export const valida_data = (data) => {
    let message = "";
    if (data.codigo.length === 0)
        message += "Código ";
    if (data.tipo.length === 0)
        message += (message ? ", " : "") + "Tipo ";
    if (data.auxiliar.length === 0)
        message += (message ? ", " : "") + "Cuenta ";
    if (data.descripcion.length === 0)
        message += (message ? ", " : "") + "Descripción ";
    if (data.naturaleza.length === 0)
        message += (message ? ", " : "") + "Naturaleza ";
    if (data.tpo_cuenta.length === 0)
        message += (message ? ", " : "") + "Tipo de Cuenta ";
    if (data.nivel === 0)
        message += (message ? ", " : "") + "Nivel ";
    return message;
}


export const valida_cuenta = (mayor, subcuenta, nivel) => {
    let cuenta = "";
    if (nivel === 1)
        cuenta = mayor+"-000-0000";
    if (nivel === 2)
        cuenta = `${mayor}-`;
    if (nivel === 3)
        cuenta = `${mayor}-${subcuenta}`;
    return cuenta;
}

export const nombre_by_codigo = (codigo, arreglo) => {
    for (let i = 0; i < arreglo.length; i++)
        if (arreglo[i].codigo === codigo)
            return arreglo[i].descripcion;
}