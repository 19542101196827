export const url_historico_solicitud = '/DjangoWeb/SolicitudAnticipoApp/consulta/solicitud_anticipo/paginacion/historico';
export const url_historico_anticipo = '/DjangoWeb/AnticiposApp/consulta/anticipo/paginacion/historico';
export const url_historico_transaccion = '/DjangoWeb/TransaccionesApp/consulta/transacciones_historico/paginacion';

export const separa_mov_anticipo = (arreglo) => {
    let aux = [];
    let arreglo_final = [];
    let anterior = arreglo[0].id_solicitud;
    for (let i = 0; i < arreglo.length; i++) {
        if (anterior === arreglo[i].id_solicitud) {
            aux.push(arreglo[i]);
            anterior = arreglo[i].id_solicitud;
        }
        else {
            arreglo_final.push(aux);
            aux = [];
            aux.push(arreglo[i]);
            anterior = arreglo[i].id_solicitud;
        }
    }
    return arreglo_final;
}

export const Burbuja = (arreglo) => {
    var lista = arreglo;
    var n, i, k, aux;
    n = lista.length;
    for (k = 1; k < n; k++) {
        for (i = 0; i < (n - k); i++) {
            if (lista[i].id_solicitud > lista[i + 1].id_solicitud) {
                aux = lista[i];
                lista[i] = lista[i + 1];
                lista[i + 1] = aux;
            }
        }
    }
    return (lista);
}
export const Burbuja_Anticipos = (arreglo) => {
    var lista = arreglo;
    var n, i, k, aux;
    n = lista.length;
    for (k = 1; k < n; k++) {
        for (i = 0; i < (n - k); i++) {
            if (lista[i].numero > lista[i + 1].numero) {
                aux = lista[i];
                lista[i] = lista[i + 1];
                lista[i + 1] = aux;
            }
        }
    }
    return (lista);
}
export const repeticion_solicitud = (solicitud, arreglo) => {
    let contador = 0;
    for (let i = 0; i < arreglo.length; i++)
        if (solicitud === arreglo[i].id_solicitud)
            contador++;
    return contador;
}

/**
 * 
 * @param {number} cliente Número de cliente.
 * @param {array} arreglo Arreglo de anticipos.
 */
export const repeticion_anticipo = (cliente, arreglo) => {
    let contador = 0;
    for (let i = 0; i < arreglo.length; i++)
        if (cliente === arreglo[i].numero)
            contador++;
    return contador;
}