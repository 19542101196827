import React, { Component } from "react";
import { Button, Collapse, Form, FormGroup } from "reactstrap";
import "../../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import MyInput from "../../utils/custom/MyInput";
import MySelect from "./utils/MySelect";

class BusquedaEspecifica extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      toogle_collapse: false,
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  toogleCollapse = (e) => {
    this.setState((prevState) => ({
      toogle_collapse: !prevState.toogle_collapse,
    }));
  };
  handleValida = (data) => {
    if (data.nombre.length === 0) delete data.nombre;
    if (data.banco.length === 0 || data.banco === "" || data.banco === "null") delete data.banco;
    if (data.cta_banco.length === 0) delete data.cta_banco;
  };
  
  handlebancos = (e) => {
    this.setState({ bancos: e.target.value });
  };
  handleFiltrada = () => {
    let data = {
      nombre: document.getElementById("specific_nombre").value,
      banco: document.getElementById("specific_banco").value,
      cta_banco: document.getElementById("specific_cta_banco").value,
    };
    this.handleValida(data);
    this.props.onChangeFiltrada(data);
  };

  render() {
    return (
      <React.Fragment>
        <div className="row mb-2 mx-1">
          <h3 className="col-md-11 col-9 text-md-left px-0 mb-0">
            Consulta Específica
          </h3>
          <Button
            color="info"
            onClick={this.toogleCollapse}
            className="col-md-1 col-3"
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
        <Collapse isOpen={this.state.toogle_collapse}>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                nameLabel="Nombre"
                id="specific_nombre"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                nameLabel="Banco"
                id="specific_banco"
              />
             {/*  <MySelect
                bootstrap="col-lg-4 col-12"
                nameLabel="Bancos"
                first_option="Seleccione un banco"
                required
                onChange={this.handlebancos}
                value={null}
                id="specific_banco"
              >
                {this.props.bancos.map((ban) => (
                  <option key={ban.numero}>
                    {ban.numero} - {ban.nombre}
                  </option>
                ))}
              </MySelect> */}
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                nameLabel="Cuenta Banco"
                id="specific_cta_banco"
              />
            </FormGroup>
            <FormGroup className="row d-flex justify-content-center mb-0">
              <Button
                color="danger"
                className="mb-1"
                onClick={this.props.onBackTable}
              >
                Regresar
              </Button>
              <Button color="success ml-2 mb-1" onClick={this.handleFiltrada}>
                Consultar
              </Button>
            </FormGroup>
          </Form>
        </Collapse>
      </React.Fragment>
    );
  }
}
export default BusquedaEspecifica;
