/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la consulta de un anticipo. 
*/
//import swal from '@sweetalert/with-react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Component } from 'react';
import { Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import CustomSelect from '../../../utils/custom/CustomSelect';
import * as permisos from '../../../utils/permisos/permisos';
import WebSocketInstance from "../../../utils/websocket";
import * as message from '../messages/messages';
import MyInput from '../../../utils/custom/MyInput';
import MyTextArea from '../../../utils/custom/MyTextArea';
import * as utility from '../utils/utility';

class ModalConsulta extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            id_departamento: "",
            nombre: "",
            areas_select: [],
            descripcion: "",
            data_modificacion: {},
            area_text: "Seleccione un area",
            area_id: "",
            areas: [
                { numero: 1, nombre: "Contabilidad" },
                { numero: 2, nombre: "Facturación y Cobranza" },
                { numero: 3, nombre: "Bancos" },
                { numero: 4, nombre: "Operación" },
                { numero: 5, nombre: "Catalogos" },
            ],
            desactive_input: [true, true, true]
        };
    }
    /*--------------------------------------------------------------------- */
    /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
    sendNotificacionHandler = (motivo) => {
        let folio = this.state.id_departamento;
        const notificacionObject = {
            tipo: 0,
            aplicacion: 'departamento',
            motivo: motivo,
            folio: [folio]
        }
        WebSocketInstance.newNotificacion(notificacionObject);
    }
    /*--------------------------------------------------------------------- */

    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    handleID = (e) => {
        let id_departamento = e.target.value;
        this.setState({ id_departamento })
    }
    handleNombre = (e) => {
        let nombre = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['nombre'] = nombre;
        this.setState({ nombre, data_modificacion });
    }
    handleDescripcion = (e) => {
        let descripcion = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['descripcion'] = descripcion;
        this.setState({ descripcion, data_modificacion })
    }
    handleMovimiento = (e) => {
        let label = e.name_text;
        let label_id = e.name_index;
        let area_text = e[label];
        let area_id = e[label_id];
        this.setState({ area_text, area_id });
    }
    handleModifica = () => {
        if (this.state.id_departamento.length === 0)
            message.message_modal("Advertencia", "Debe tener un número de anticipo", 400);
        else {
            message.message_confimacion_textarea('¿Desea continuar con la operación modificar?', 'Ingrese sus observaciones ')
                .then(res => {
                    if (res) {
                        let observaciones = document.getElementById('observaciones_textarea').value;
                        let data_modificacion = this.state.data_modificacion;
                        if (observaciones.length === 0)
                            message.message_modal('Completar el campo:', 'Motivo de Modificación', 400);
                        else
                            if (Object.values(data_modificacion).length > 0) {
                                data_modificacion['id_departamento'] = this.state.id_departamento;
                                data_modificacion['observaciones'] = observaciones;
                                this.methodPUT_API(utility.url_crea_departamento, 'modificar', data_modificacion);
                            }
                            else
                                message.message_modal("Advertencia", "No hay modificaciones", 400);
                    }
                });
        }
    }
    GET_Departamento = () => {
        if (document.getElementById("id_consulta_empleado").value.length === 0)
            message.message_modal("Advertencia", "Debe tener un ID de Departamento", 400);
        else {
            this.methodPOST_API(utility.url_consulta_departamento, 'consulta', { id_departamento: document.getElementById("id_consulta_empleado").value });
        }
    }
    closeWindow = () => {
        this.clearState();
        this.props.toogleConsulta();
    }
    clearState = () => {
        this.setState({
            nombre: "",
            areas_select: [],
            descripcion: "",
            data_modificacion: {},
            area_text: "Seleccione un area",
            area_id: "",
            areas: [
                { numero: 1, nombre: "Contabilidad" },
                { numero: 2, nombre: "Facturación y Cobranza" },
                { numero: 3, nombre: "Bancos" },
                { numero: 4, nombre: "Operación" },
                { numero: 5, nombre: "Catalogos" },
            ],
            desactive_input: [true, true, true]
        });
    }
    agregaArea = () => {
        let area_num = this.state.area_id;
        let areas_select = this.state.areas_select;
        let areas = this.state.areas;
        let data_modificacion = this.state.data_modificacion;
        for (let i = 0; i < areas.length; i++)
            if (area_num === areas[i].numero) {
                areas_select.push(areas[i]);
                areas.splice(i, 1);
            }
        data_modificacion['area'] = [];
        areas_select.forEach(area => data_modificacion.area.push(area.numero));
        data_modificacion.area = data_modificacion.area.toString();
        this.setState({
            areas, areas_select, data_modificacion,
            area_id: "", area_text: "Seleccione un area"
        })
    }
    eliminaArea = (e) => {
        let area_num = parseInt(e.target.value);
        let areas_select = this.state.areas_select;
        let areas = this.state.areas;
        let data_modificacion = this.state.data_modificacion;
        for (let i = 0; i < areas_select.length; i++)
            if (area_num === areas_select[i].numero) {
                areas.push(areas_select[i]);
                areas_select.splice(i, 1);
            }
        data_modificacion['area'] = [];
        areas_select.forEach(area => data_modificacion.area.push(area.numero));
        data_modificacion.area = data_modificacion.area.toString();
        this.setState({
            areas, areas_select, data_modificacion
        })
    }
    methodGET_API = (url, state) => {
        let url_intern = url;
        axios.get(url_intern)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    methodPOST_API = (url, state, data) => {
        let url_intern = url;
        axios.post(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        case 'consulta':
                            let areas_select = [];
                            let areas = this.state.areas;
                            let areas_response = response.data.results.area.split(',');
                            for (let i = 0; i < areas_response.length; i++) {
                                if (areas_response[i] === "1")
                                    areas_select.push({ numero: 1, nombre: "Contabilidad" });
                                if (areas_response[i] === "2")
                                    areas_select.push({ numero: 2, nombre: "Facturación y Cobranza" });
                                if (areas_response[i] === "3")
                                    areas_select.push({ numero: 3, nombre: "Bancos" });
                                if (areas_response[i] === "4")
                                    areas_select.push({ numero: 4, nombre: "Operación" });
                                if (areas_response[i] === "5")
                                    areas_select.push({ numero: 5, nombre: "Catalogos" });
                            }
                            for (let i = 0; i < areas_select.length; i++)
                                for (let j = 0; j < areas.length; j++)
                                    if (areas_select[i].numero === areas[j].numero)
                                        areas.splice(j, 1);
                            this.setState({
                                nombre: response.data.results.nombre,
                                descripcion: response.data.results.descripcion,
                                desactive_input: [false, false, false],
                                areas_select,
                                areas
                            });
                            utility.add_class_disabled('nombre_consulta_departamento');
                            utility.add_class_disabled('area_consulta_departamento');
                            utility.add_class_disabled('descripcion_consulta_departamento');
                            break;
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    methodPUT_API = (url, state, data) => {
        let url_intern = url;
        axios.put(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        case 'modificar':
                            this.setState({}, () => { this.sendNotificacionHandler('modificar'); })
                            this.closeWindow();
                            message.message_modal(response.data.title, response.data.text, response.status);
                            this.props.update(1);
                            break;
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    render() {
        return (
            <Modal isOpen={this.props.toogle_consulta} toggle={this.closeWindow} backdrop="static" size="xl"  >
                <ModalHeader toggle={this.closeWindow}>Consulta Departamento</ModalHeader>
                <ModalBody>
                    <Form className="container-fluid">
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-10 col-8" id="id_consulta_empleado" nameLabel="ID Departamento"
                                value={this.state.id_departamento} onChange={this.handleID} />
                            <Button color="primary" className="col-lg-2 col-4 mb-2" onClick={this.GET_Departamento}>Consulta</Button>
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-6 col-12" id="nombre_consulta_departamento" nameLabel="Nombre"
                                disabled={this.state.desactive_input[0]} value={this.state.nombre} onChange={this.handleNombre} />
                            <CustomSelect bootstrap="col-lg-4 col-12" name_text='nombre' name_index='numero' id="area_consulta_departamento"
                                nameLabel="Areas" value={this.state.area_text} onChange={this.handleMovimiento} options={this.state.areas}
                                disabled={this.state.desactive_input[1]} />
                            <Button color="primary" className="col-lg-2 col-4 mb-2" disabled={this.state.desactive_input[1]} onClick={this.agregaArea}>
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                            <MyTextArea bootstrap="col-lg-12 col-12" id="descripcion_consulta_departamento" nameLabel="Descripción"
                                disabled={this.state.desactive_input[2]} value={this.state.descripcion} onChange={this.handleDescripcion} />
                        </FormGroup>
                    </Form>
                    <Table size="sm" bordered hover responsive>
                        <thead className="text-center header-table">
                            <tr>
                                <td>Número departamento</td>
                                <td>Nombre departamento</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody className="body-table">
                            {this.state.areas_select.map(area => (
                                <tr key={area.numero}>
                                    <td className="text-center">{area.numero}</td>
                                    <td className="text-center">{area.nombre}</td>
                                    <td className="text-center">
                                        <Button color="danger" size="sm" onClick={this.eliminaArea} value={area.numero}>
                                            Eliminar
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={this.handleModifica} disabled={permisos.obtener_permiso_especifico('modificar', this.props.permisos)}>Modificar</Button>
                    <Button color="primary" onClick={this.closeWindow}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default ModalConsulta;