class WebSocketService {

    static instance = null;
    callbacks = {};

    static getInstance() {
        if (!WebSocketService.instance)
            WebSocketService.instance = new WebSocketService();
        return WebSocketService.instance;
    }

    constructor() {
        this.socketRef = null;
    }

    connect(userID) {
        let path = "";
        if (window.location.origin === "http://172.20.0.6") {
                path = `wss://172.20.0.5:8000/DjangoWeb/ws/socket/${userID}/`;
        } else if (window.location.origin === "https://arancel-sa.com" || window.location.origin === "https://www.arancel-sa.com") {
                path = `wss://arancel-sa.com/DjangoWeb/ws/socket/${userID}/`;
        } else {
                path = `wss://${window.location.host}/DjangoWeb/ws/socket/${userID}/`;
        }

        this.socketRef = new WebSocket(path);

        this.socketRef.onopen = () => {
            console.log('WebSocket esta conectado');
        }
        this.socketRef.onmessage = (e) => {
            this.socketNewNotificacion(e.data);
        }
        this.socketRef.onerror = (error) => {
            console.log(error);
        }
        this.socketRef.onclose = () => {
            console.log('WebSocket cerrado');
        }
    }

    disconnect() {
        this.socketRef.close(1000);
    }

    socketNewNotificacion(data) {
        const parsedData = JSON.parse(data);
        if (Object.keys(this.callbacks).length === 0)
            return;
        if (parsedData.tipo === 0)
            this.callbacks["notificacion"](parsedData);  //RECIBO NOTIFICACIONES
        if (parsedData.tipo === 1)
            this.callbacks["messages"](parsedData);      //RECIBO MENSAJES DE UN CHAT 
        if (parsedData.tipo === 3)
            this.callbacks["new_message"](parsedData);   //RECIBIR UN NUEVO MENSAJE 
        if (parsedData.tipo === 4)
            this.callbacks["chats"](parsedData);         //RECIBO CHATS DEL USUARIO
    }

    newNotificacion(paquete) {
        if (paquete.tipo === 0)
            this.sendNotificacion({
                tipo: paquete.tipo,
                aplicacion: paquete.aplicacion,
                motivo: paquete.motivo,
                cliente: paquete.cliente,
                folio: paquete.folio,
                destino: paquete.destino,
                departamento: paquete.departamento,
                chat: paquete.chat,
            });
        else if (paquete.tipo === 1)
            this.sendNotificacion({
                tipo: paquete.tipo,
                id_chat: paquete.id_chat,
                origen: paquete.origen,
            });
        else if (paquete.tipo === 2)
            this.sendNotificacion({
                tipo: paquete.tipo,
                destino: paquete.destino,
                mensaje: paquete.mensaje,
                departamento: paquete.departamento
            });
        else if (paquete.tipo === 3)
            this.sendNotificacion({
                tipo: paquete.tipo,
                destino: paquete.destino,
                mensaje: paquete.mensaje,
                id_chat: paquete.id_chat,
            });
        else
            this.sendNotificacion({
                tipo: paquete.tipo,
                usuarios: paquete.usuarios,
                departamento: paquete.departamento,
            });
    }

    addCallbacks(notifyCallback, messagesCallback, chatsCallback, newCallback) {
        this.callbacks["notificacion"] = notifyCallback;
        this.callbacks["messages"] = messagesCallback;
        this.callbacks["chats"] = chatsCallback;
        this.callbacks["new_message"] = newCallback;
    }

    sendNotificacion(data) {
        try {
            this.socketRef.send(JSON.stringify({ ...data }))
        }
        catch (error) {
            console.log(error)
        }
    }

    state() {
        if (this.socketRef === null)
            return null;
        else
            return this.socketRef.readyState;
    }

    waitForSocketConnection(callback) {
        const socket = this.socketRef;
        const recursion = this.waitForSocketConnection;
        setTimeout(() => {
            if (socket === 1) {
                console.log('conexión segura');
                if (callback !== null)
                    callback();
                return;
            }
            else {
                console.log('Esperando conexión...');
                recursion(callback);
            }
        }, 1);
    }
}

const WebSocketInstance = WebSocketService.getInstance();

export default WebSocketInstance;