/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un Proveedor. 
*/
import swal from "@sweetalert/with-react";
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ListGroup,
  ListGroupItem,
  FormText,
  Collapse,
  Card,
  CardBody,
} from "reactstrap";
import MyInput from "../../../utils/custom/MyInput";
import MySelect from "../../../utils/custom/MySelect";
import WebSocketInstance from "../../../utils/websocket";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";
import MyTextArea from "../utils/MyTextArea";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalAuxiliar from "../../Poliza/Modal/ModalAuxiliar";
import ModalCrearEnCatalogos from "../../CuentasAux/Modal/ModalCrearEnCatalogos";

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      nombre_corto: "",

      text_cfdi_unidad: "",
      cfdi_unidad_suggestions: [],
      cat_cfdi_unidad: [],

      text_cfdi_productos: "",
      cfdi_productos_suggestions: [],
      cat_cfdi_productos: [],

      auxiliar: "",
      claveAlta: "",
      codigo: "",
      nivel: 0,
      naturaleza: "",
      tipoCuenta: "",
      descripcion: "",
      toogle_collapse: false,
      nombre_select: "",
      tipo: "",
      mayor: "",
      subcuenta: "",
      detalle: "",
      cuentas_mayor: [],
      cuentas_menor: [],

      toogle_ingreso: false,
      toogle_cuentaux: false,

      clave: "",
      toogle_auxiliar: false,
      auxiliares: [],
      enableCuentaAuxiliar: true,
    };
    this.toogleAltaIngreso = this.toogleAltaIngreso.bind(this);
    this.toogleCuentaAux = this.toogleCuentaAux.bind(this);
    this.toogleAuxiliar = this.toogleAuxiliar.bind(this);
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  /*  sendNotificacionHandler = (motivo) => {
         let folio = this.state.identificativo;
         const notificacionObject = {
             tipo: 0,
             aplicacion: 'proveedor',
             motivo: motivo,
             folio: [folio]
         }
         WebSocketInstance.newNotificacion(notificacionObject);
     } */
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_cfdi_unidad, "cfdi_unidad");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleNombre = (e) => {
    let nombre = e.target.value;
    this.setState({ nombre });
  };
  handleNombre_corto = (e) => {
    let nombre_corto = e.target.value;
    this.setState({ nombre_corto });
  };
  handleClave = (e) => {
    let claveAlta = e.target.value;
    this.setState({ claveAlta });
  };
  toogleCollapse = (e) => {
    this.setState((prevState) => ({
      toogle_collapse: !prevState.toogle_collapse,
    }));
  };
  toogleAltaIngreso = () => {
    this.setState((prevState) => ({
      toogle_ingreso: !prevState.toogle_ingreso,
      toogle_cuentaux: false,
    }));
  };
  toogleCuentaAux = () => {
    this.setState((prevState) => ({
      toogle_cuentaux: !prevState.toogle_cuentaux,
      toogle_ingreso: false,
    }));
  };

  handleCallback = (childData) => {
    this.setState({ auxiliar: childData });
  };
  toogleAuxiliar = () => {
    this.setState((prevState) => ({
      toogle_auxiliar: !prevState.toogle_auxiliar,
    }));
    if (this.state.auxiliar === "") {
      this.getAuxiliaresMayores();
    } else {
      this.postAuxiliarSegundoNivel();
    }
  };
  getAuxiliaresMayores() {
    const url = utility.url_auxiliares;
    axios
      .get(url)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              auxiliares: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  postAuxiliarSegundoNivel() {
    axios
      .post(utility.url_auxiliares, {
        auxiliar: this.state.auxiliar,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              auxiliares: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  handleAltaDiario = () => {
    message
      .message_confimacion("¿Desea continuar con la operación alta?", "")
      .then((res) => {
        if (res) {
          let data = {
            nombre: this.state.nombre,
            corto: this.state.nombre_corto,
            clave: this.state.claveAlta,
          };
          if (utility.validadCamposPOST(data).length > 0)
            message.message_modal(
              "Falta completar los siguientes campos:",
              utility.validadCamposPOST(data),
              400
            );
          else {
            this.methodPOST_API(utility.url_crea_diario, "alta", data);
            this.setState({ enableCuentaAuxiliar: false });
            this.toogleAltaIngreso();
            this.toogleCuentaAux();
          }
        }
      });
  };
  handleConsulta_producto = () => {
    if (this.state.text_cfdi_productos === "") {
      message.warningMessage("Por favor, ingrese la clave de producto");
    } else {
      this.methodPOST_API(utility.url_cfdi_producto, "cfdi_productos", {
        descripcion: this.state.text_cfdi_productos,
      });
    }
  };

  handleAltaCA = () => {
    if (
      this.state.codigo === "" ||
      this.state.nivel === "" ||
      this.state.naturaleza === "" ||
      this.state.tipoCuenta === "" ||
      this.state.descripcion === ""
    ) {
      message.warningMessage("Por favor ingrese todos los campos");
    } else {
      axios
        .post(utility.url_crea_cuentas_aux, {
          auxiliar: utility.valida_cuenta(
            this.state.mayor,
            this.state.subcuenta,
            this.state.nivel
          ),
          codigo: this.state.codigo,
          clave: this.state.tipo,
          descripcion: this.state.descripcion,
          nivel: this.state.nivel,
          naturaleza: this.state.naturaleza,
          tpo_cuenta: this.state.tipoCuenta,
        })
        .then((response) => {
          if (response.status >= 200 && response.status < 400) {
            if (this._isMounted) {
              message.message_success("Se creó", "Cuenta auxiliar creada");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response)
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
        });
    }
  };
  handleAltaDiarioCA = () => {
    if (this.state.auxiliar === "") {
      message.warningMessage("Por favor ingrese una cuenta auxiliar");
    } else {
      axios
        .post(utility.url_crea_auxiliares, {
          catalogo: "Dr",
          clave: this.state.clave,
          auxiliar: this.state.auxiliar,
        })
        .then((response) => {
          if (response.status >= 200 && response.status < 400) {
            if (this._isMounted) {
              message.message_success(
                "Se creó exitosamente",
                "Registros de control de auxiliares creados"
              );
            }
            this.handleCerrar();
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response)
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
        });
    }
  };

  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta":
              if (this._isMounted) {
                this.setState({
                  clave: response.data.results.clave,
                });
                message.message_modal(
                  response.data.title,
                  response.data.text,
                  response.status
                );
                this.props.update(1);
              }
              break;
            case "cfdi_productos":
              this.setState({
                cat_cfdi_productos: response.data.results,
                cfdi_productos_suggestions: utility.organiza_cfdi_productos(
                  response.data.results
                ),
              });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "cfdi_unidad":
              this.setState({
                cat_cfdi_unidad: response.data.results,
              });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  handleCerrar = () => {
    this.setState({
      nombre: "",
      nombre_corto: "",

      text_cfdi_unidad: "",
      cfdi_unidad_suggestions: [],
      cat_cfdi_unidad: [],

      text_cfdi_productos: "",
      cfdi_productos_suggestions: [],
      cat_cfdi_productos: [],

      auxiliar: "",
      codigo: "",
      nivel: 0,
      naturaleza: "",
      tipoCuenta: "",
      descripcion: "",
      toogle_collapse: false,
      nombre_select: "",
      tipo: "",
      mayor: "",
      subcuenta: "",
      detalle: "",
      cuentas_mayor: [],
      cuentas_menor: [],

      toogle_ingreso: false,
      toogle_cuentaux: false,

      clave: "",
      toogle_auxiliar: false,
      auxiliares: [],
      enableCuentaAuxiliar: true,
    });
    this.props.toogleNuevo();
  };
  handleAuxiliar = (e) => {
    let auxiliar = e.target.value;
    this.setState({ auxiliar });
  };
  handleNivel = (e) => {
    let nivel = parseInt(e.target.value);
    if (nivel >= 2)
      this.methodPOST_API(utility.url_pagination_cuentas, "cuentas_mayor", {
        nivel: 1,
      });
    this.setState({ nivel, mayor: "", subcuenta: "", detalle: "" });
  };
  handleMayor = (e) => {
    let mayor = e.target.value;
    if (this.state.nivel === 3)
      this.methodPOST_API(utility.url_pagination_cuentas, "cuentas_menor", {
        mayor: mayor,
        nivel: 2,
      });
    this.setState({ mayor });
  };
  handleSubcuenta = (e) => {
    let subcuenta = e.target.value;
    this.setState({ subcuenta });
  };
  handleDetalle = (e) => {
    let detalle = e.target.value;
    this.setState({ detalle });
  };
  handleNivel = (e) => {
    let nivel = parseInt(e.target.value);
    if (nivel >= 2)
      this.methodPOST_API(utility.url_pagination_cuentas, "cuentas_mayor", {
        nivel: 1,
      });
    this.setState({ nivel, mayor: "", subcuenta: "", detalle: "" });
  };
  handleNaturaleza = (e) => {
    let naturaleza = e.target.value;
    this.setState({ naturaleza });
  };
  handleTipoCuenta = (e) => {
    let tipoCuenta = e.target.value;
    this.setState({ tipoCuenta });
  };
  handleTipo = (e) => {
    let tipo = e.target.value;
    this.setState({ tipo });
  };
  handleDescripcion = (e) => {
    let descripcion = e.target.value;
    this.setState({ descripcion });
  };

  /*--------------- FUNCIONES QUE ACTUALIZAN CAMPOS DE CFDI UNIDAD ---------------*/
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`${value}`, "i");
      suggestions = utility
        .organiza_cfdi_unidad(this.state.cat_cfdi_unidad)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      cfdi_unidad_suggestions: suggestions,
      text_cfdi_unidad: value,
    });
  };
  renderSuggestion = () => {
    if (this.state.cfdi_unidad_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.cfdi_unidad_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  suggestionSelected = (value) => {
    this.setState({
      text_cfdi_unidad: value,
      cfdi_unidad_suggestions: [],
    });
  };
  /*--------------- FIN FUNCIONES QUE ACTUALIZAN CAMPOS DE CFDI UNIDAD ---------------*/

  /*--------------- FUNCIONES QUE ACTUALIZAN CAMPOS DE CFDI PRODUCTOS ---------------*/
  onNameChange_P = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`${value}`, "i");
      suggestions = utility
        .organiza_cfdi_productos(this.state.cat_cfdi_productos)
        .sort()
        .filter((v) => regex.exec(v));
    }
    this.setState({
      cfdi_productos_suggestions: suggestions,
      text_cfdi_productos: value,
    });
  };
  renderSuggestion_P = () => {
    if (this.state.cfdi_productos_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.cfdi_productos_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected_P(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  suggestionSelected_P = (value) => {
    this.setState({
      text_cfdi_productos: value,
      cfdi_productos_suggestions: [],
    });
  };
  /*--------------- FIN FUNCIONES QUE ACTUALIZAN CAMPOS DE CFDI PRODUCTOS ---------------*/

  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let codigo = e.target.value;
    this.setState({ codigo });
    for (let i = 0; i < this.props.codigos_sat.length; i++) {
      if (codigo.length === 0) this.suggestionSelectedd("");
      if (codigo === this.props.codigos_sat[i].codigo)
        this.suggestionSelectedd(this.props.codigos_sat[i].descripcion);
    }
  };
  suggestionSelectedd = (value) => {
    this.setState({
      nombre_select: value,
    });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_nuevo}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>Alta Diario</ModalHeader>
        <ModalBody>
          {/* <div className="row mb-2 d-flex justify-content-center">
            <div className="col-lg-10 col-12 d-flex justify-content-around">
              <Button color="primary" onClick={this.toogleAltaIngreso}>
                Diario
              </Button>
              <Button color="info" onClick={this.toogleCuentaAux} disabled={this.state.enableCuentaAuxiliar}>
                Cuenta Auxiliar
              </Button>
            </div>
          </div>
          <Collapse isOpen={this.state.toogle_ingreso}>
            <Card outline color="primary">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="nombre_ingreso_alta"
                      nameLabel="Nombre de Diario"
                      value={this.state.nombre}
                      onChange={this.handleNombre}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="nombre_corto_ingreso_alta"
                      nameLabel="Nombre Corto"
                      value={this.state.nombre_corto}
                      onChange={this.handleNombre_corto}
                      maxLength="7"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="clave_alta"
                      nameLabel="Clave"
                      value={this.state.claveAlta}
                      onChange={this.handleClave}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      color="success"
                      className="mr-2"
                      onClick={this.handleAltaDiario}
                    >
                      Aceptar
                    </Button>
                    <Button color="primary" onClick={this.handleCerrar}>
                      Cerrar
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_cuentaux}>
            <Card outline color="info">
              <CardBody>
              <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-8 col-12"
                      id="auxiliar_poliza"
                      nameLabel="Auxiliar"
                      value={this.state.auxiliar}
                      onChange={this.handleAuxiliar}
                    />
                    <Button
                      color="primary"
                      className="col-lg-4 col-12 mb-2"
                      onClick={this.toogleAuxiliar}
                    >
                      Consulta
                    </Button>
                  </FormGroup>
                </Form>
                {this.state.toogle_cuentaux && (
                  <ModalCrearEnCatalogos
                    toogle_catalogo={this.state.toogle_cuentaux}
                    toogleCatalogo={this.toogleAuxiliar}
                    codigos_sat={this.props.codigos_sat}
                  />
                )}
                <br></br>
                <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                  <Button
                    color="success"
                    className="mr-2"
                    onClick={this.handleAltaDiarioCA}
                  >
                    Aceptar
                  </Button>
                  <Button color="primary" onClick={this.handleCerrar}>
                    Cerrar
                  </Button>
                </FormGroup>
              </CardBody>
            </Card>
          </Collapse> */}

          <CardBody>
            <Form className="container-fluid">
              <FormGroup className="row mb-0">
                <MyInput
                  type="text"
                  bootstrap="col-lg-6"
                  id="nombre_ingreso_alta"
                  nameLabel="Nombre de Diario"
                  value={this.state.nombre}
                  onChange={this.handleNombre}
                />
                <MyInput
                  type="text"
                  bootstrap="col-lg-6"
                  id="nombre_corto_ingreso_alta"
                  nameLabel="Nombre Corto"
                  value={this.state.nombre_corto}
                  onChange={this.handleNombre_corto}
                  maxLength="7"
                />
                <MyInput
                  type="text"
                  bootstrap="col-lg-6"
                  id="clave_alta"
                  nameLabel="Clave"
                  value={this.state.claveAlta}
                  onChange={this.handleClave}
                />
              </FormGroup>
            </Form>
          </CardBody>
        </ModalBody>
        <ModalFooter>
          <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
            <Button
              color="success"
              className="mr-2"
              onClick={this.handleAltaDiarioCA}
            >
              Aceptar
            </Button>
            <Button color="primary" onClick={this.handleCerrar}>
              Cerrar
            </Button>
          </FormGroup>
        </ModalFooter>
        <ModalAuxiliar
          toogle_catalogo={this.state.toogle_auxiliar}
          toogleCatalogo={this.toogleAuxiliar}
          listaAuxiliares={this.state.auxiliares}
          parentCallback={this.handleCallback}
        />
      </Modal>
    );
  }
}
export default ModalAlta;
