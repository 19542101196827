export const url_pagination_patentes = '/DjangoWeb/BaseApp/consulta/agente_aduanal/paginacion/principal';
export const url_consulta_patente = '/DjangoWeb/BaseApp/consulta/agente_aduanal';
export const url_alta_patente = '/DjangoWeb/BaseApp/crea_modifica/agente_aduanal';
export const url_alta_contacto = '/DjangoWeb/BaseApp/crea_modifica/contacto_agente_aduanal';
export const url_patentes_vector = '/DjangoWeb/BaseApp/consulta/agente_aduanal/lista';
export const url_codigo_postal = '/DjangoWeb/BaseApp/consulta/codigo_postal';

/**
 * Función que nos permite filtar la tabla por el input general que serra de tipo text.
 */
export const general_search = (e) => {
    e.preventDefault();
    let tr = document.getElementById('tabla_patentes').getElementsByTagName('tr');
    let data_input = document.getElementById('busqueda_general_patentes').value.toUpperCase();
    for (let i = 0; i < tr.length; i++) {
        let mostrar = false;
        let data = tr[i].getElementsByTagName('p');
        for (let j = 0; j < data.length; j++) {
            if (data_input === "") {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
            else if (data[j].textContent.toUpperCase().includes(data_input)) {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "rgba(0,123,255,1)";
                data[j].style.color = "#fff";
            }
            else {
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que nos permite filtrar latabla solamnete por la fecha.
 */
export const date_search = (e) => {
    e.preventDefault();
    var tabla_anticipo, input_general, tr, dato, input_dato, mostrar;
    tabla_anticipo = document.getElementById("tabla_patentes");
    input_general = document.getElementById("busqueda_date_patentes");
    input_dato = input_general.value;
    tr = tabla_anticipo.getElementsByTagName("tr");
    for (var i = 0; i < tr.length; i++) {
        mostrar = false;
        dato = tr[i].getElementsByTagName("p");
        for (var j = 0; j < dato.length; j++) {
            if (dato[j].textContent === input_dato) {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "rgba(0,123,255,1)";
                dato[j].style.color = "#fff";
            }
            else if (input_dato === "") {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
            else {
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}

/**
 * Función que validad que los campos de la data para POST esten completos.
 * @param {Object} data Objeto que se manda como data en el POST.
 */
export const validadCamposPOST = (data) => {
    let message = "";
    if (data.nombre.length === 0)
        message += "Nombre del Agente Aduanal, ";
    if (data.rfc.length === 0)
        message += "RFC, ";
    if (data.curp.length === 0)
        message += "CURP, ";
    if (data.aduana.length === 0)
        message += "Aduana, ";
    if (data.patente.length === 0)
        message += "Patente, ";
    if (data.tpo_patente.length === null)
        message += "Tipo de Patente, ";
    if (data.domicilio.length === 0)
        message += "Domicilio, ";
    if (data.colonia.length === 0)
        message += "Colonia, ";
    if (data.codigo_postal.length === 0)
        message += "Código Postal, ";
    message = message.slice(0, message.length - 2);
    return message;
}