/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la consulta de un empleado. 
*/
import swal from '@sweetalert/with-react';
import axios from 'axios';
import React, { Component } from 'react';
import { Button, Form, FormGroup, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CustomSelect from '../../../utils/custom/CustomSelect';
import MyInput from '../../../utils/custom/MyInput';
import MyTextArea from '../../../utils/custom/MyTextArea';
import * as permisos from '../../../utils/permisos/permisos';
import WebSocketInstance from "../../../utils/websocket";
import * as message from '../messages/messages';
import * as utility from '../utils/utility';

class ModalConsulta extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            nombre: "",
            apellidoP: "",
            apellidoM: "",
            rfc: "",
            curp: "",
            fecha: "",
            nss: "",
            domicilio: "",
            colonia_text: "Seleccione una colonia",
            colonia_id: "",
            estado: "",
            ciudad: "",
            cp: "",
            telefono: "",
            correo: "",
            estudios: "",
            departamento_id: "",
            departamento_text: "Seleccione un departamento",
            puesto: "",
            desactive_input: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
            departamentos: [],
            puestos: [],
            data_modificacion: {},
            puestos_suggestions: [],
            new_puesto_id: "",

            estados: [],
            colonias: [],
            identificativo: "",
        };
    }
    /*--------------------------------------------------------------------- */
    /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
    sendNotificacionHandler = (motivo, app) => {
        let folio = app === 'puesto' ? this.state.identificativo : this.state.nombre;
        const notificacionObject = {
            tipo: 0,
            aplicacion: app,
            motivo: motivo,
            folio: [folio]
        }
        WebSocketInstance.newNotificacion(notificacionObject);
    }
    /*--------------------------------------------------------------------- */
    componentDidMount() {
        this._isMounted = true;
        this.methodGET_API(utility.url_departamentos, 'departamentos');
        this.methodGET_API(utility.url_puestos, 'puestos');
        this.methodGET_API(utility.url_estados, 'estados');
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    handleID = (e) => {
        let id = e.target.value;
        this.setState({ id })
    }
    /* ----------------------------------- Funciones para el tratamiento del DOM ----------------------------------- */
    handleNombre = (e) => {
        let nombre = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['nombre'] = nombre;
        this.setState({ nombre, data_modificacion })
    }
    handleApellidoP = (e) => {
        let apellidoP = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['apellido_paterno'] = apellidoP;
        this.setState({ apellidoP, data_modificacion })
    }
    handleApellidoM = (e) => {
        let apellidoM = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['apellido_materno'] = apellidoM;
        this.setState({ apellidoM, data_modificacion })
    }
    handleRFC = (e) => {
        let rfc = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['rfc'] = rfc;
        this.setState({ rfc, data_modificacion });
    }
    handleCURP = (e) => {
        let curp = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['curp'] = curp;
        this.setState({ curp, data_modificacion })
    }
    handleFecha = (e) => {
        let fecha = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['fecha_ingreso'] = fecha;
        this.setState({ fecha })
    }
    handleNSS = (e) => {
        let nss = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['imss'] = nss;
        this.setState({ nss, data_modificacion })
    }
    handleDomicilio = (e) => {
        let domicilio = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['domicilio'] = domicilio;
        this.setState({ domicilio, data_modificacion })
    }
    handleColonia = (e) => {
        let estado, ciudad;
        let label_id = e.name_index;
        let label = e.name_text;
        let data_modificacion = this.state.data_modificacion;
        let colonia_text = e[label];
        let colonia_id = e[label_id];
        data_modificacion['colonia'] = colonia_id;
        this.state.colonias.forEach(col => {
            if (colonia_id === col.asentamiento) {
                estado = col.estado;
                ciudad = col.ciudad;
            }
        })
        this.setState({ colonia_text, colonia_id, estado, ciudad, data_modificacion });
    }
    handleEstado = (e) => {
        let estado = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['estado'] = estado;
        this.setState({ estado, data_modificacion })
    }
    handleCP = (e) => {
        let cp = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        if (cp.length === 5)
            this.methodPOST_API(utility.url_codigo_postal, 'codigo_postal', { codigo: cp });
        data_modificacion['codigo_postal'] = cp;
        this.setState({ cp, data_modificacion })
    }
    handleTelefono = (e) => {
        let telefono = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['telefono'] = telefono;
        this.setState({ telefono, data_modificacion })
    }
    handleCorreo = (e) => {
        let correo = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['correo'] = correo;
        this.setState({ correo, data_modificacion })
    }
    handleEstudios = (e) => {
        let estudios = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['nivel_estudios'] = estudios;
        this.setState({ estudios, data_modificacion })
    }
    handleDepartamento = (e) => {
        let label_id = e.name_index;
        let label = e.name_text;
        let departamento_text = e[label];
        let departamento_id = e[label_id];
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['departamento'] = departamento_id;
        this.setState({ departamento_text, departamento_id, data_modificacion })
    }
    /* ----------------------------------- Fin de funciones para el tratamiento del DOM ----------------------------------- */
    handlePuesto = (data) => {
        swal({
            icon: 'warning',
            closeOnClickOutside: false,
            title: 'Puesto inexistente',
            text: 'Se dara de alta el puesto y continuara el proceso',
            buttons: ['Cancelar', true],
            dangerMode: true,
            content: (
                <div>
                    <button value={JSON.stringify(data)} id="data" className="d-none"></button>
                    <MyTextArea bootstrap="col-12" id="descripción_alta_puesto" nameLabel="Descripción" />
                </div>
            )
        }).then(res => {
            if (res) {
                let data = JSON.parse(document.getElementById("data").value);
                data["descripcion"] = document.getElementById("descripción_alta_puesto").value;
                this.methodPOST_API(utility.url_crea_puesto, 'alta_puesto', data);
            }
            else
                swal("Error", "Seleccione un puesto existente", "warning");
        });
    }
    GET_Empleado = () => {
        if (this.state.id.length === 0)
            message.message_modal("Advertencia", "Debe tener un ID de Empleado", 400);
        else {
            this.methodPOST_API(utility.url_consulta_empleado, 'consulta', { id_empleado: this.state.id });
        }
    }
    MODIFY_Empleado = () => {
        if (this.state.id.length === 0)
            message.message_modal("Advertencia", "Debe tener un ID de Empleado", 400);
        else {
            message.message_confimacion_textarea('¿Desea continuar con la operación modificar?', 'Ingrese sus observaciones ')
                .then(res => {
                    if (res) {
                        let observaciones = document.getElementById('observaciones_textarea').value;
                        let data = this.state.data_modificacion;
                        if (observaciones.length === 0)
                            message.message_modal('Completar el campo:', 'Motivo de Modificación', 400);
                        else
                            if (Object.values(this.state.data_modificacion).length > 0) {
                                data['id_empleado'] = this.state.id;
                                data['observaciones'] = observaciones;
                                if (utility.valida_puesto(this.state.puesto, this.state.puestos)) {
                                    this.methodPUT_API(utility.url_crea_empleado, 'modifica', data);
                                }
                                else
                                    this.handlePuesto({
                                        puesto: document.getElementById("puesto_consulta_empleado").value,
                                        id_departamento: utility.get_id_departamento(this.state.departamento, this.state.departamentos)
                                    });
                            }
                            else
                                message.message_modal('Advertencia', 'No ha modificado algún campo', 400);
                    }
                });
        }
    }
    /**
     * Función que permite hacer una petición de forma GET
     * @param {String} url URL a consulta 
     * @param {String} state Opción del case 
     */
    methodGET_API = (url, state) => {
        let url_intern = url;
        axios.get(url_intern)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        case 'departamentos':
                            if (this._isMounted)
                                this.setState({ departamentos: response.data.results });
                            break;
                        case 'puestos':
                            if (this._isMounted)
                                this.setState({ puestos: response.data.results });
                            break;
                        case 'estados':
                            if (this._isMounted)
                                this.setState({ estados: response.data.results });
                            break;
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    /**
     * Función que permite hacer una petición de forma POST 
     * @param {String} url URL a consultar 
     * @param {String} state Onpción del case  
     * @param {Object} data Objeto a mandar atraves del metodo POST 
     */
    methodPOST_API = (url, state, data) => {
        let url_intern = url;
        axios.post(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        case 'consulta':
                            this.setState({
                                nombre: response.data.results[0].nombre,
                                apellidoP: response.data.results[0].apellido_paterno,
                                apellidoM: response.data.results[0].apellido_materno,
                                rfc: response.data.results[0].rfc,
                                curp: response.data.results[0].curp,
                                fecha: response.data.results[0].fecha_ingreso,
                                nss: response.data.results[0].imss,
                                domicilio: response.data.results[0].domicilio,
                                colonia_text: response.data.results[0].colonia,
                                estado: response.data.results[0].estado,
                                cp: response.data.results[0].codigo_postal,
                                telefono: response.data.results[0].telefono,
                                correo: response.data.results[0].correo,
                                estudios: response.data.results[0].nivel_estudios,
                                departamento_text: response.data.results[0].departamento,
                                puesto: response.data.results[0].puesto,
                                desactive_input: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,]
                            }, () => { this.methodPOST_API(utility.url_codigo_postal, 'codigo_postal', { codigo: response.data.results[0].codigo_postal }); });
                            break;
                        case 'alta_puesto':
                            let new_data = "";
                            message.message_modal(response.data.title, response.data.text, response.status);
                            this.setState({ new_puesto_id: response.data.results.id_puesto, identificativo: response.data.results.puesto }, () => {
                                this.sendNotificacionHandler('crear', 'puesto');
                                new_data = this.state.data_modificacion;
                                new_data['puesto'] = this.state.new_puesto_id;
                                this.methodPUT_API(utility.url_crea_empleado, 'modifica', new_data);
                            });
                            break;
                        case 'codigo_postal':
                            if (this._isMounted)
                                this.setState({
                                    colonias: response.data.results
                                });
                            break;
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    /**
     * Función que permite hacer una petición de forma PUT 
     * @param {String} url URL a consultar 
     * @param {String} state Onpción del case  
     * @param {Object} data Objeto a mandar atraves del metodo PUT 
     */
    methodPUT_API = (url, state, data) => {
        let url_intern = url;
        axios.put(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        case 'modifica':
                            this.setState({}, () => { this.sendNotificacionHandler('modificar', 'empleado'); })
                            this.closeWindow();
                            message.message_modal(response.data.title, response.data.text, response.status);
                            this.props.update(1);
                            break;
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    clearState = () => {
        this.setState({
            id: "",
            nombre: "",
            apellidoP: "",
            apellidoM: "",
            rfc: "",
            curp: "",
            fecha: "",
            nss: "",
            domicilio: "",
            colonia: "",
            estado: "",
            cp: "",
            telefono: "",
            correo: "",
            estudios: "",
            departamento: "",
            puesto: "",
            desactive_input: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
            data_modificacion: {},
            puestos_suggestions: [],
            new_puesto_id: "",
            identificativo: "",
        });
    }
    closeWindow = () => {
        this.clearState();
        this.props.toogleConsulta();
    }
    /* ------ Bloque de funciones que nos permitiran el autocompletado de un puesto ------ */
    onNameChange = (e) => {
        const value = e.target.value;
        let suggestions = [];
        let data_modificacion = this.state.data_modificacion;
        if (value.length > 0) {
            const regex = new RegExp(`^${value}`, 'i');
            suggestions = utility.get_name_puesto(this.state.puestos).sort().filter(v => regex.test(v));
        }
        data_modificacion['puesto'] = value;
        this.setState({
            puestos_suggestions: suggestions,
            puesto: value,
            data_modificacion
        });
    }
    renderSuggestion = () => {
        if (this.state.puestos_suggestions.length === 0)
            return null;
        return (
            <ListGroup className="col-12" flush>
                {this.state.puestos_suggestions.map(data => (<ListGroupItem key={data} onClick={() => this.suggestionSelected(data)} action>{data}</ListGroupItem>))}
            </ListGroup>
        );
    }
    suggestionSelected = (value) => {
        document.getElementById('puesto_consulta_empleado').value = value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['puesto'] = value;
        this.setState({
            puesto: value,
            puestos_suggestions: [],
            data_modificacion
        });
    }
    /* ------ Bloque de funciones que nos permitiran el autocompletado de un puesto ------ */
    render() {
        return (
            <Modal isOpen={this.props.toogle_consulta} toggle={this.props.toogleConsulta} backdrop="static" size="xl"  >
                <ModalHeader toggle={this.props.toogleConsulta}>Consulta Empleado</ModalHeader>
                <ModalBody>
                    <Form className="container-fluid">
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-10 col-8" id="id_consulta_empleado" nameLabel="ID Empleado"
                                value={this.state.id} onChange={this.handleID} />
                            <Button color="primary" className="col-lg-2 col-4 mb-2" onClick={this.GET_Empleado}>Consulta</Button>
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="nombre_consulta_empleado" nameLabel="Nombre(s)" disabled={this.state.desactive_input[0]}
                                value={this.state.nombre} onChange={this.handleNombre} />
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="apellidoP_consulta_empleado" nameLabel="Apellido Paterno" disabled={this.state.desactive_input[1]}
                                value={this.state.apellidoP} onChange={this.handleApellidoP} />
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="apellidoM_consulta_empleado" nameLabel="Apellido Materno" disabled={this.state.desactive_input[2]}
                                value={this.state.apellidoM} onChange={this.handleApellidoM} />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="rfc_consulta_empleado" nameLabel="RFC" disabled={this.state.desactive_input[3]}
                                value={this.state.rfc} onChange={this.handleRFC} />
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="curp_consulta_empleado" nameLabel="CURP" disabled={this.state.desactive_input[4]}
                                value={this.state.curp} onChange={this.handleCURP} />
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="imss_consulta_empleado" nameLabel="Número de Seguro Social" disabled={this.state.desactive_input[5]}
                                value={this.state.nss} onChange={this.handleNSS} />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="domicilio_consulta_empleado" nameLabel="Domicilio" disabled={this.state.desactive_input[6]}
                                value={this.state.domicilio} onChange={this.handleDomicilio} />
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="cp_consulta_empleado" nameLabel="Código Postal" disabled={this.state.desactive_input[7]}
                                value={this.state.cp} onChange={this.handleCP} />
                            <CustomSelect bootstrap="col-lg-4 col-12" name_text='asentamiento' name_index='asentamiento' id="colonia_alta_empleado"
                                nameLabel="Colonia" value={this.state.colonia_text} onChange={this.handleColonia} options={this.state.colonias}
                                disabled={this.state.desactive_input[8]} />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-6 col-12" id="estado_alta_empleado" nameLabel="Estado/Provincia/Región"
                                value={this.state.estado} disabled />
                            <MyInput type="text" bootstrap="col-lg-6 col-12" id="ciudad_alta_empleado" nameLabel="Ciudad"
                                value={this.state.ciudad} disabled />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="telefono_consulta_empleado" nameLabel="Telefono" disabled={this.state.desactive_input[9]}
                                value={this.state.telefono} onChange={this.handleTelefono} />
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="correo_consulta_empleado" nameLabel="Correo" disabled={this.state.desactive_input[10]}
                                value={this.state.correo} onChange={this.handleCorreo} />
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="nivel_estudios_consulta_empleado" nameLabel="Nivel Estudios" disabled={this.state.desactive_input[11]}
                                value={this.state.estudios} onChange={this.handleEstudios} />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <CustomSelect bootstrap="col-lg-4 col-12" name_text='nombre' name_index='id_departamento' id="departamento_alta_empleado"
                                nameLabel="Departamento" value={this.state.departamento_text} onChange={this.handleDepartamento}
                                options={this.state.departamentos} disabled={this.state.desactive_input[12]} />
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="fecha_ingreso_consulta_empleado" nameLabel="Fecha de ingreso" required="false"
                                disabled={this.state.desactive_input[13]} value={this.state.fecha} onChange={this.handleFecha} />
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="puesto_consulta_empleado" nameLabel="Puesto" disabled={this.state.desactive_input[14]}
                                value={this.state.puesto} onChange={this.onNameChange} />
                            {this.renderSuggestion()}
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={this.MODIFY_Empleado} disabled={permisos.obtener_permiso_especifico('modificar', this.props.permisos)}>Modificar</Button>
                    <Button color="primary" onClick={this.closeWindow}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default ModalConsulta;