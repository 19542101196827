/* 
Autor: Alexis Yael Hernández Grimaldo
Descripción: El componenete genera un bloque correspondiente a la busqueda dinamica en la tabla para acceder a la información.
*/
import React, { Component } from 'react';
import { Button, Collapse, Form, FormGroup } from 'reactstrap';
import '../../assets/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

class BusquedaEspecifica extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toogle_collapse: false,
        }
    }
    toogleCollapse = (e) => {
        this.setState(prevState => ({ toogle_collapse: !prevState.toogle_collapse }))
    }
    handleValida = (data) => {
        if (data.mayor.length === 0)
            delete data.mayor;
        if (data.subcuenta.length === 0)
            delete data.subcuenta;
        if (data.detalle.length === 0)
            delete data.detalle;
        if (data.descripcion.length === 0)
            delete data.descripcion;
        if (data.nivel.length === 0)
            delete data.nivel;
        if (data.codigo.length === 0)
            delete data.codigo;
        if (data.naturaleza.length === 0)
            delete data.naturaleza;
        if (data.tpo_cuenta.length === 0)
            delete data.tpo_cuenta;
    }
    handleFiltrada = () => {
        let data = {
            subcuenta: document.getElementById('cuenta_subcuenta_specific').value,
            detalle: document.getElementById('cuenta_detalle_specific').value,
            descripcion: document.getElementById('cuenta_descripcion_specific').value,
            nivel: document.getElementById('cuenta_nivel_specific').value,
            codigo: document.getElementById('cuenta_codigo_specific').value,
            naturaleza: document.getElementById('cuenta_naturaleza_specific').value,
            tpo_cuenta: document.getElementById('cuenta_tipo_cuenta_specific').value,
            mayor: document.getElementById('cuenta_mayor_specific').value
        }
        this.handleValida(data)
        this.props.onChangeFiltrada(data);
    }
    render() {
        return (
            <React.Fragment>
                <div className="row mb-2 mx-1">
                    <h3 className="col-md-11 col-9 text-md-left px-0 mb-0">
                        Consulta Específica
					</h3>
                    <Button
                        color="info"
                        onClick={this.toogleCollapse}
                        className="col-md-1 col-3">
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </div>
                <Collapse isOpen={this.state.toogle_collapse} className="container-fluid">
                    <Form className="constainer-fluid">
                        <FormGroup className="row mb-0">
                            <div className="grupo col-lg-3 p-0 d-flex">
                                <input type="text" className="input-animated col-lg-12 mb-2" id="cuenta_mayor_specific" required />
                                <span className="barra"></span>
                                <br /><label className="label-animated" htmlFor="cuenta_mayor_specific">Mayor</label>
                            </div>
                            <div className="grupo col-lg-3 p-0">
                                <input type="text" className="input-animated col-12 mb-2" id="cuenta_subcuenta_specific" required />
                                <span className="barra"></span>
                                <br /><label className="label-animated" htmlFor="cuenta_subcuenta_specific">Subcuenta</label>
                            </div>
                            <div className="grupo col-lg-3 p-0">
                                <input type="text" className="input-animated col-12 mb-2" id="cuenta_detalle_specific" required />
                                <span className="barra"></span>
                                <br /><label className="label-animated" htmlFor="cuenta_detalle_specific">Detalle</label>
                            </div>
                            <div className="grupo col-lg-3 p-0">
                                <input type="text" className="input-animated col-12 mb-2" id="cuenta_descripcion_specific" required />
                                <span className="barra"></span>
                                <br /><label className="label-animated" htmlFor="cuenta_descripcion_specific">Descripción</label>
                            </div>
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <div className="grupo col-lg-3 p-0">
                                <input type="text" className="input-animated col-12 mb-2" id="cuenta_nivel_specific" required />
                                <span className="barra"></span>
                                <br /><label className="label-animated" htmlFor="cuenta_nivel_specific">Nivel</label>
                            </div>
                            <div className="grupo col-lg-3 p-0">
                                <input type="text" className="input-animated col-12 mb-2" id="cuenta_codigo_specific" required />
                                <span className="barra"></span>
                                <br /><label className="label-animated" htmlFor="cuenta_codigo_specific">Código</label>
                            </div>
                            <div className="grupo col-lg-3 p-0">
                                <input type="text" className="input-animated col-12 mb-2" id="cuenta_naturaleza_specific" required />
                                <span className="barra"></span>
                                <br /><label className="label-animated" htmlFor="cuenta_naturaleza_specific">Naturaleza</label>
                            </div>
                            <div className="grupo col-lg-3 p-0">
                                <input type="text" className="input-animated col-12 mb-2" id="cuenta_tipo_cuenta_specific" required />
                                <span className="barra"></span>
                                <br /><label className="label-animated" htmlFor="cuenta_tipo_cuenta_specific">Tipo de Cuenta</label>
                            </div>
                        </FormGroup>
                        <FormGroup className="row d-flex justify-content-center mb-0">
                            <Button color="danger" className="mb-1" onClick={this.props.onBackTable}>Regresar</Button>
                            <Button color="success" className="ml-2 mb-1" onClick={this.handleFiltrada}>Consultar</Button>
                        </FormGroup>
                    </Form>
                </Collapse>
            </React.Fragment>

        );
    }
}
export default BusquedaEspecifica;