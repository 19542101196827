/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente general de la aplicación Transportista. 
*/
import axios from "axios";
import React, { Component } from "react";
import * as message from "./messages/messages";
import * as utility from "./utils/utility";
import TablaTransportista from "./Table";
import SearchBar from "./SearchBar";
import { FormText } from "reactstrap";
import WebSocketInstance from "../../utils/websocket";
import { useToasts } from "react-toast-notifications";

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
}

class Transportista extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      clientes: [],
      consolidadoras: [],
      checked_recolectar: [],
      checked_llegada: [],
      checked_entrega: [],
      acutalizar: 0,
      permisos: utility.obtener_permisos_by_app(
        "alerta",
        localStorage.getItem("perm")
      ),
      on_cliente:
        localStorage.getItem("puesto") === "Cliente"
          ? localStorage.getItem("id_usr")
          : false,
    };
    this.waitForSocketConnection(() => {
      WebSocketInstance.addCallbacks(this.addNotificacion.bind(this));
    });
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  waitForSocketConnection(callback) {
    const component = this;
    setTimeout(() => {
      if (WebSocketInstance.state() === 1) {
        callback();
        return;
      } else {
        component.waitForSocketConnection(callback);
      }
    }, 100);
  }
  addNotificacion(notificaciones) {
    this.props.addToast(
      <div>
        <p className="text-uppercase mb-1">
          <b>{notificaciones.aplicacion}</b>
        </p>
        <p className="mb-0">{notificaciones.mensaje}</p>
        <FormText className="mb-0" color="none">
          {notificaciones.hora}
        </FormText>
      </div>,
      {
        appearance: notificaciones.tipo_n === 0 ? "success" : "warning",
        autoDismiss: true,
      }
    );
  }
  sendNotificacionHandler = (e) => {
    e.preventDefault();
    const notificacionObject = {
      tipo: 0,
      tipo_n: 0,
      aplicacion: "alerta",
      motivo: "notificar",
      cliente: "13",
      folio: "20",
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_cliente, "clientes");
    this.methodGET_API(utility.url_consolidadoras, "consolidadoras");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "clientes":
              if (this._isMounted === true)
                this.setState({ clientes: response.data.results });
              break;
            case "consolidadoras":
              if (this._isMounted === true)
                this.setState({ consolidadoras: response.data.results });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  update = (cambio) => {
    this.setState({ acutalizar: cambio });
  };
  checkedRecoleccion = (data) => {
    this.setState({ checked_recolectar: data });
  };
  checkedLlegada = (data) => {
    this.setState({ checked_llegada: data });
  };
  checkedEntrega = (data) => {
    this.setState({ checked_entrega: data });
  };
  render() {
    return (
      <div className="container-fluid">
        <SearchBar
          clientes={this.state.clientes}
          consolidadoras={this.state.consolidadoras}
          update={this.update}
          checked_recolectar={this.state.checked_recolectar}
          checked_llegada={this.state.checked_llegada}
          checked_entrega={this.state.checked_entrega}
          checkedRecoleccion={this.checkedRecoleccion}
          checkedLlegada={this.checkedLlegada}
          checkedEntrega={this.checkedEntrega}
          permisos={this.state.permisos}
          on_cliente={this.state.on_cliente}
        />
        <TablaTransportista
          clientes={this.state.clientes}
          actualizar={this.state.acutalizar}
          update={this.update}
          checked_recolectar={this.state.checked_recolectar}
          checked_llegada={this.state.checked_llegada}
          checked_entrega={this.state.checked_entrega}
          checkedRecoleccion={this.checkedRecoleccion}
          checkedLlegada={this.checkedLlegada}
          checkedEntrega={this.checkedEntrega}
          on_cliente={this.state.on_cliente}
        />
      </div>
    );
  }
}
export default withToast(Transportista);
