/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la consulta de un XXX. 
*/
import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Form,
  FormGroup,
  Table,
  Collapse,
  Card,
  CardBody,
  Input,
} from "reactstrap";
import * as utility from "../utility/utility";
import * as message from "../messages/messages";
import axios from "axios";
import MyInput from "../../../utils/custom/MyInput";
import MyTextArea from "../utility/MyTextArea";
import CustomSelect from "../../../utils/custom/CustomSelect";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as permisos from "../../../utils/permisos/permisos";
import WebSocketInstance from "../../../utils/websocket";
import ModalAuxiliarContado from "./ModalAuxiliarContado";
import ModalAuxiliarAnticipo from "./ModalAuxiliarAnticipo";
import ModalAuxiliarPhxcdc from "./ModalAuxiliarPhxcdc";
import ModalAuxiliarCredito from "./ModalAuxiliarCredito";
import ModalAuxiliarSaldo from "./ModalAuxiliarSaldo";

class ModalConsulta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      numero: "",
      nombre: "", //
      rfc: "", //
      domicilio: "", //
      colonia: "", //
      codigo_postal: "", //
      pais_text: "Seleccione un país",
      pais_id: "",
      estado: "",
      ciudad: "",
      patente_text: "Seleccione una patente", //
      patente_id: "", //
      aduana: "", //
      apellido_paterno: "", //
      apellido_materno: "", //
      curp: "", //
      numero_interior: "", //
      encargo_conferido: "0", //
      rfc_sociedad: "", //
      cta_banco_pago_text: "Seleccione una cuenta", //
      cta_banco_pago_id: "",
      poder_notarial: "", //
      grupo_text: "Seleccione un grupo", //
      grupo_id: "",
      regimen_fiscal_id: "", //
      regimen_fiscal_text: "Seleccione un regimen fiscal", //
      colonias: [],
      colonias_domicilio: [],

      tarifa_entrega: "", //
      tarifa_importacion: "", //
      tarifa_exportacion: "", //
      descuento_importacion: "", //
      descuento_exportacion: "", //

      nombre_representante: "", //
      rfc_representante: "", //
      curp_representante: "", //

      nombre_contacto: "",
      telefono_contacto: "",
      correo_contacto: "",

      quien_recibe_domicilio: "",
      telefono_domicilio: "",
      pais_domicilio_text: "Seleccione un país",
      pais_domicilio_id: "",
      domicilio_domicilio: "",
      numero_interior_domicilio: "",
      codigo_postal_domicilio: "",
      estado_domicilio: "",
      ciudad_domicilio: "",
      colonia_domicilio: "Seleccione una colonia",

      representantes: [],
      contactos_empresa: [],
      domicilios_cliente: [],

      toogle_cliente: true,
      toogle_contactos: false,
      toogle_domicilios: false,
      toogle_cuentas_auxiliares: false,

      enable_contacto: true,
      enable_domicilio: true,
      enable_cuentas_auxiliares: true,

      auxiliarContado: "",
      auxiliarAnticipo: "",
      auxiliarPhxcdc: "",
      auxiliarCredito: "",
      auxiliarSaldo: "",

      toogle_auxiliar_contado: false,
      toogle_auxiliar_anticipo: false,
      toogle_auxiliar_phxcdc: false,
      toogle_auxiliar_credito: false,
      toogle_auxiliar_saldo: false,

      auxiliarContadoOriginal: "",
      auxiliarAnticipoOriginal: "",
      auxiliarPhxcdcOriginal: "",
      auxiliarCreditoOriginal: "",
      auxiliarSaldoOriginal: "",

      disabled: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],
      data_modificacion: {},
      data_modificacion_acuerdo: {},

      modificacion_representante: [],
      eliminacion_representante: [],
      nuevo_representante: [],

      modificacion_contacto: [],
      eliminacion_contacto: [],
      nuevo_contacto: [],

      eliminacion_domicilio: [],
      nuevo_domicilio: [],

      auxiliar: "",
      nuevoAuxiliar: "",
      codigoAgrupador: "",
      nivelCuenta: "",
      naturaleza: "",
      tipoCuenta: "",
      descripcion: "",
    };
    this.toogleAuxiliarContado = this.toogleAuxiliarContado.bind(this);
    this.toogleAuxiliarAnticipo = this.toogleAuxiliarAnticipo.bind(this);
    this.toogleAuxiliarPhxcdc = this.toogleAuxiliarPhxcdc.bind(this);
    this.toogleAuxiliarCredito = this.toogleAuxiliarCredito.bind(this);
    this.toogleAuxiliarSaldo = this.toogleAuxiliarSaldo.bind(this);
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.numero;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "cliente",
      motivo: motivo,
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  toogleCliente = () => {
    this.setState((prevState) => ({
      toogle_cliente: !prevState.toogle_cliente,
      toogle_contactos: false,
      toogle_domicilios: false,
      toogle_cuentas_auxiliares: false,
    }));
  };
  toogleContactos = () => {
    this.setState((prevState) => ({
      toogle_contactos: !prevState.toogle_contactos,
      toogle_cliente: false,
      toogle_domicilios: false,
      toogle_cuentas_auxiliares: false,
    }));
  };
  toogleDomicilios = () => {
    this.setState((prevState) => ({
      toogle_domicilios: !prevState.toogle_domicilios,
      toogle_contactos: false,
      toogle_cliente: false,
      toogle_cuentas_auxiliares: false,
    }));
  };

  toogleCuentasAuxiliares = () => {
    this.setState((prevState) => ({
      toogle_cuentas_auxiliares: !prevState.toogle_cuentas_auxiliares,
      toogle_cliente: false,
      toogle_contactos: false,
      toogle_domicilios: false,
    }));
  };

  toogleAuxiliarContado = () => {
    this.setState((prevState) => ({
      toogle_auxiliar_contado: !prevState.toogle_auxiliar_contado,
    }));
  };
  toogleAuxiliarAnticipo = () => {
    this.setState((prevState) => ({
      toogle_auxiliar_anticipo: !prevState.toogle_auxiliar_anticipo,
    }));
  };
  toogleAuxiliarPhxcdc = () => {
    this.setState((prevState) => ({
      toogle_auxiliar_phxcdc: !prevState.toogle_auxiliar_phxcdc,
    }));
  };
  toogleAuxiliarCredito = () => {
    this.setState((prevState) => ({
      toogle_auxiliar_credito: !prevState.toogle_auxiliar_credito,
    }));
  };
  toogleAuxiliarSaldo = () => {
    this.setState((prevState) => ({
      toogle_auxiliar_saldo: !prevState.toogle_auxiliar_saldo,
    }));
  };

  handleCallbackContado = (childData) => {
    this.setState({ auxiliarContado: childData });
  };
  handleCallbackAnticipo = (childData) => {
    this.setState({ auxiliarAnticipo: childData });
  };
  handleCallbackPhxcdc = (childData) => {
    this.setState({ auxiliarPhxcdc: childData });
  };
  handleCallbackCredito = (childData) => {
    this.setState({ auxiliarCredito: childData });
  };
  handleCallbackSaldo = (childData) => {
    this.setState({ auxiliarSaldo: childData });
  };
  /* ------------------------ FUNCIONES QUE INTERACTUAN CON EL BACKEND ------------------------ */
  handleConsulta = () => {
    if (this.state.numero.length === 0)
      message.message_modal(
        "Advertencia",
        "Debe tener un Número de Cliente",
        400
      );
    else {
      this.methodPOST_API(utility.url_consulta_cliente, "consulta", {
        numero: this.state.numero,
      });
    }
  };
  handleConsultaAuxiliar = () => {
    if (this.state.auxiliar === "") {
      message.warningMessage("Por favor, ingrese un auxiliar");
    } else {
      message.warningMessage("PASASTE VALIDACIÓN ");
    }
  };
  handleAuxiliar = (e) => {
    let auxiliar = e.target.value;
    this.setState({ auxiliar });
  };
  handleNuevoAuxiliar = (e) => {
    let nuevoAuxiliar = e.target.value;
    this.setState({ nuevoAuxiliar });
  };
  handleCodigoAgrupador = (e) => {
    let codigoAgrupador = e.target.value;
    this.setState({ codigoAgrupador });
  };
  handleNivelCuenta = (e) => {
    let nivelCuenta = e.target.value;
    this.setState({ nivelCuenta });
  };
  handleNaturaleza = (e) => {
    let naturaleza = e.target.value;
    this.setState({ naturaleza });
  };
  handleTipoCuenta = (e) => {
    let tipoCuenta = e.target.value;
    this.setState({ tipoCuenta });
  };
  handleDesc = (e) => {
    let descripcion = e.target.value;
    this.setState({ descripcion });
  };

  handleModificar_cliente = () => {
    if (this.state.numero.length === 0)
      message.message_modal(
        "Advertencia",
        "Debe tener un Número de Agente Aduanal",
        400
      );
    else {
      message
        .message_confimacion("¿Desea continuar con la operación modificar?", "")
        .then((res) => {
          if (res) {
            const modifica_representante = () => {
              let eliminacion_representante =
                this.state.eliminacion_representante;
              let modificacion_representante =
                this.state.modificacion_representante;
              for (let i = 0; i < eliminacion_representante.length; i++)
                for (let j = 0; j < modificacion_representante.length; j++)
                  if (
                    modificacion_representante[j].id ===
                    eliminacion_representante[i].id
                  )
                    modificacion_representante.splice(j, 1);
              this.setState({
                modificacion_representante,
                eliminacion_representante,
              });
              let data = {
                cliente: this.state.numero,
                creados: this.state.nuevo_representante,
                modificados: this.state.modificacion_representante,
                eliminados: this.state.eliminacion_representante,
              };
              utility.valida_modificacion_representantes(data);
              if (Object.values(data).length > 1)
                return axios.post(utility.url_alta_representantes, data);
              else return null;
            };
            const modifica_cliente = () => {
              let data = {
                numero: this.state.numero,
                ...this.state.data_modificacion,
              };
              if (Object.values(data).length > 1)
                return axios.put(utility.url_alta_cliente, data);
              else return null;
            };
            const modifica_acuerdo = () => {
              let data = {
                cliente: this.state.numero,
                ...this.state.data_modificacion_acuerdo,
              };
              if (Object.values(data).length > 1)
                return axios.post(utility.url_alta_acuerdo, data);
              else return null;
            };
            const modifica_contacto = () => {
              let eliminacion_contacto = this.state.eliminacion_contacto;
              let modificacion_contacto = this.state.modificacion_contacto;
              for (let i = 0; i < eliminacion_contacto.length; i++)
                for (let j = 0; j < modificacion_contacto.length; j++)
                  if (
                    modificacion_contacto[j].id === eliminacion_contacto[i].id
                  )
                    modificacion_contacto.splice(j, 1);
              this.setState({ modificacion_contacto, eliminacion_contacto });
              let data = {
                cliente: this.state.numero,
                creados: this.state.nuevo_contacto,
                modificados: this.state.modificacion_contacto,
                eliminados: this.state.eliminacion_contacto,
              };
              utility.valida_modificacion_contactos(data);
              if (Object.values(data).length > 1)
                return axios.post(utility.url_alta_contactos, data);
              else return null;
            };
            const modifica_domicilio = () => {
              let data = {
                cliente: this.state.numero,
                creados: this.state.nuevo_domicilio,
                eliminados: this.state.eliminacion_domicilio,
              };
              utility.valida_modificacion_domicilios(data);
              if (Object.values(data).length > 1)
                return axios.post(utility.url_alta_domicilios, data);
              else return null;
            };
            let responses = [
              modifica_representante(),
              modifica_cliente(),
              modifica_acuerdo(),
              modifica_contacto(),
              modifica_domicilio(),
            ];
            if (
              responses[0] === null &&
              responses[1] === null &&
              responses[2] === null &&
              responses[3] === null &&
              responses[4] === null
            )
              message.message_modal(
                "Advertencia",
                "No hay alguna modificación",
                400
              );
            else
              Promise.all(responses)
                .then((res) => {
                  let validacion = [false, false, false];
                  if (res[0]) {
                    res[0].status === 200
                      ? (validacion[0] = true)
                      : (validacion[0] = false);
                    this.setState({
                      nuevo_representante: [],
                      eliminacion_representante: [],
                      modificacion_representante: [],
                    });
                  }
                  if (res[1]) {
                    res[1].status === 200
                      ? (validacion[1] = true)
                      : (validacion[1] = false);
                    this.setState({ data_modificacion: {} });
                  }
                  if (res[2]) {
                    res[2].status === 200
                      ? (validacion[2] = true)
                      : (validacion[2] = false);
                    this.setState({ data_modificacion_acuerdo: {} });
                  }
                  if (validacion[0] || validacion[1] || validacion[2])
                    message.message_modal(
                      "Operación exitosa",
                      "Se han realizado los cambios",
                      200
                    );
                  if (res[3])
                    message.message_modal(
                      res[3].data.title,
                      res[3].data.text,
                      res[3].status
                    );
                  if (res[4])
                    message.message_modal(
                      res[4].data.title,
                      res[4].data.text,
                      res[4].status
                    );
                  this.sendNotificacionHandler("modificar");
                })
                .catch((error) => {
                  if (
                    error[0].response ||
                    error[1].response ||
                    error[2].response
                  )
                    message.message_modal(
                      "Ocurrio un error",
                      "Valida la información",
                      400
                    );
                  if (error[3])
                    message.message_modal(
                      error[3].response.data.title,
                      error[3].response.data.text,
                      error[3].response.status
                    );
                  if (error[4])
                    message.message_modal(
                      error[4].response.data.title,
                      error[4].response.data.text,
                      error[4].response.status
                    );
                });
          }
        });
    }
  };

  handleClear = () => {
    this.setState({
      numero: "",
      nombre: "", //
      rfc: "", //
      domicilio: "", //
      colonia: "", //
      codigo_postal: "", //
      pais: "", //
      estado: "",
      ciudad: "",
      patente: "", //
      aduana: "", //
      apellido_paterno: "", //
      apellido_materno: "", //
      curp: "", //
      numero_interior: "", //
      encargo_conferido: "0", //
      rfc_sociedad: "", //
      cta_banco_pago: "", //
      cta_banco_pago_text: "Seleccione una cuenta", //
      poder_notarial: "", //
      grupo: "", //
      regimen_fiscal: [], //
      colonias: [],
      colonias_domicilio: [],

      tarifa_entrega: "", //
      tarifa_importacion: "", //
      tarifa_exportacion: "", //
      descuento_importacion: "", //
      descuento_exportacion: "", //

      nombre_representante: "", //
      rfc_representante: "", //
      curp_representante: "", //

      nombre_contacto: "",
      telefono_contacto: "",
      correo_contacto: "",

      quien_recibe_domicilio: "",
      telefono_domicilio: "",
      pais_domicilio: "",
      domicilio_domicilio: "",
      numero_interior_domicilio: "",
      codigo_postal_domicilio: "",
      estado_domicilio: "",
      ciudad_domicilio: "",
      colonia_domicilio: "",
      patente_text: "Seleccione una patente",
      grupo_text: "Seleccione una un grupo",
      regimen_fiscal_text: "Seleccione un regimen fiscal",
      pais_text: "Seleccione un país",

      representantes: [],
      contactos_empresa: [],
      domicilios_cliente: [],

      toogle_cliente: true,
      toogle_contactos: false,
      toogle_domicilios: false,
      toogle_cuentas_auxiliares: false,

      enable_contacto: true,
      enable_domicilio: true,
      enable_cuentas_auxiliares: true,

      auxiliarContadoOriginal: "",
      auxiliarAnticipoOriginal: "",
      auxiliarPhxcdcOriginal: "",
      auxiliarCreditoOriginal: "",
      auxiliarSaldoOriginal: "",

      disabled: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],
      data_modificacion: {},
      data_modificacion_acuerdo: {},

      modificacion_representante: [],
      eliminacion_representante: [],
      nuevo_representante: [],

      modificacion_contacto: [],
      eliminacion_contacto: [],
      nuevo_contacto: [],

      eliminacion_domicilio: [],
      nuevo_domicilio: [],
    });
  };
  handleCerrar = () => {
    this.handleClear();
    this.props.toogleConsulta();
  };
  /* ------------------------ FUNCIONES QUE INTERACTUAN CON EL DOM ------------------------ */
  handleNumero = (e) => {
    let numero = e.target.value;
    this.setState({ numero });
  };
  handleNombre = (e) => {
    let nombre = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["nombre"] = nombre;
    this.setState({ nombre, data_modificacion });
  };
  handleRFC = (e) => {
    let rfc = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["rfc"] = rfc;
    this.setState({ rfc, data_modificacion });
  };
  handleDomicilio = (e) => {
    let domicilio = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["direccion"] = domicilio;
    this.setState({ domicilio, data_modificacion });
  };
  handleColonia = (e) => {
    let label = e.name_text;
    let colonia = e[label];
    let estado, ciudad;
    let data_modificacion = this.state.data_modificacion;
    this.state.colonias.forEach((col) => {
      if (colonia === col.asentamiento) {
        estado = col.estado;
        ciudad = col.ciudad;
      }
    });
    data_modificacion["colonia"] = colonia;
    this.setState({ colonia, estado, ciudad, data_modificacion });
  };
  handleCodigo_postal = (e) => {
    let codigo_postal = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    if (codigo_postal.length === 5)
      this.methodPOST_API(utility.url_codigo_postal, "codigo_postal", {
        codigo: codigo_postal,
      });
    data_modificacion["codigo_postal"] = codigo_postal;
    this.setState({ codigo_postal, data_modificacion });
  };
  handlePais = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let pais_text = e[label];
    let pais_id = e[label_id];
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["pais"] = pais_id;
    this.setState({ pais_text, pais_id, data_modificacion });
  };
  handlePatente = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let patente_text = e[label];
    let patente_id = e[label_id];
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["patente"] = patente_id;
    this.setState({ patente_text, patente_id, data_modificacion });
  };
  handleAduana = (e) => {
    let aduana = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["aduana"] = aduana;
    this.setState({ aduana, data_modificacion });
  };
  handleEncargo = (e) => {
    let encargo_conferido = e.target.checked ? "1" : "0";
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["encargo_conferido"] = encargo_conferido;
    this.setState({ encargo_conferido, data_modificacion });
  };
  handleApellidoP = (e) => {
    let apellido_paterno = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["apellido_paterno"] = apellido_paterno;
    this.setState({ apellido_paterno, data_modificacion });
  };
  handleApellidoM = (e) => {
    let apellido_materno = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["apellido_materno"] = apellido_materno;
    this.setState({ apellido_materno, data_modificacion });
  };
  handleCURP = (e) => {
    let curp = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["curp"] = curp;
    this.setState({ curp, data_modificacion });
  };
  handleNumero_int = (e) => {
    let numero_interior = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["numero_interior"] = numero_interior;
    this.setState({ numero_interior, data_modificacion });
  };
  handleRFC_social = (e) => {
    let rfc_sociedad = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["rfc_sociedad"] = rfc_sociedad;
    this.setState({ rfc_sociedad, data_modificacion });
  };
  handleMetodo = (e) => {
    let metodo_pago = e.target.value;
    this.setState({ metodo_pago });
  };
  handleCuenta = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let cta_banco_pago_text = e[label];
    let cta_banco_pago_id = e[label_id];
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["cta_banco_pago"] = cta_banco_pago_id;
    this.setState({
      cta_banco_pago_text,
      cta_banco_pago_id,
      data_modificacion,
    });
  };
  handlePoder = (e) => {
    let poder_notarial = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["poder_notarial"] = poder_notarial;
    this.setState({ poder_notarial, data_modificacion });
  };
  handleGrupo = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let grupo_text = e[label];
    let grupo_id = e[label_id];
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["grupo"] = grupo_id;
    this.setState({ grupo_text, grupo_id, data_modificacion });
  };
  handleRegimen = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let regimen_fiscal_text = e[label];
    let regimen_fiscal_id = e[label_id];
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["regimen_fiscal"] = regimen_fiscal_id;
    this.setState({
      regimen_fiscal_text,
      regimen_fiscal_id,
      data_modificacion,
    });
  };
  handleTarifa_entrega = (e) => {
    let tarifa_entrega = e.target.value;
    let data_modificacion_acuerdo = this.state.data_modificacion_acuerdo;
    data_modificacion_acuerdo["tarifa_entrega"] = tarifa_entrega;
    this.setState({ tarifa_entrega, data_modificacion_acuerdo });
  };
  handleTarifa_importacion = (e) => {
    let tarifa_importacion = e.target.value;
    let data_modificacion_acuerdo = this.state.data_modificacion_acuerdo;
    data_modificacion_acuerdo["tarifa_importacion"] = tarifa_importacion;
    this.setState({ tarifa_importacion, data_modificacion_acuerdo });
  };
  handleTarifa_exportacion = (e) => {
    let tarifa_exportacion = e.target.value;
    let data_modificacion_acuerdo = this.state.data_modificacion_acuerdo;
    data_modificacion_acuerdo["tarifa_exportacion"] = tarifa_exportacion;
    this.setState({ tarifa_exportacion, data_modificacion_acuerdo });
  };
  handleDescuento_importacion = (e) => {
    let descuento_importacion = e.target.value;
    let data_modificacion_acuerdo = this.state.data_modificacion_acuerdo;
    data_modificacion_acuerdo["descuento_importacion"] = descuento_importacion;
    this.setState({ descuento_importacion, data_modificacion_acuerdo });
  };
  handleDescuento_exportacion = (e) => {
    let descuento_exportacion = e.target.value;
    let data_modificacion_acuerdo = this.state.data_modificacion_acuerdo;
    data_modificacion_acuerdo["descuento_exportacion"] = descuento_exportacion;
    this.setState({ descuento_exportacion, data_modificacion_acuerdo });
  };
  handleNombre_representante = (e) => {
    let nombre_representante = e.target.value;
    this.setState({ nombre_representante });
  };
  handleRFC_representante = (e) => {
    let rfc_representante = e.target.value;
    this.setState({ rfc_representante });
  };
  handleCURP_representante = (e) => {
    let curp_representante = e.target.value;
    this.setState({ curp_representante });
  };
  hanldeNombre_contacto = (e) => {
    let nombre_contacto = e.target.value;
    this.setState({ nombre_contacto });
  };
  handleTelefono_contacto = (e) => {
    let telefono_contacto = e.target.value;
    this.setState({ telefono_contacto });
  };
  handleCorreo_contacto = (e) => {
    let correo_contacto = e.target.value;
    this.setState({ correo_contacto });
  };
  hanldeNombre_domicilio = (e) => {
    let quien_recibe_domicilio = e.target.value;
    this.setState({ quien_recibe_domicilio });
  };
  handleTelefono_domicilio = (e) => {
    let telefono_domicilio = e.target.value;
    this.setState({ telefono_domicilio });
  };
  handlePais_contacto = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let pais_domicilio_text = e[label];
    let pais_domicilio_id = e[label_id];
    this.setState({ pais_domicilio_text, pais_domicilio_id });
  };
  handleDomicilio_domicilio = (e) => {
    let domicilio_domicilio = e.target.value;
    this.setState({ domicilio_domicilio });
  };
  handleNumero_int_domicilio = (e) => {
    let numero_interior_domicilio = e.target.value;
    this.setState({ numero_interior_domicilio });
  };
  handleCodigo_postal_domicilio = (e) => {
    let codigo_postal_domicilio = e.target.value;
    if (codigo_postal_domicilio.length === 5)
      this.methodPOST_API(
        utility.url_codigo_postal,
        "codigo_postal_domicilio",
        { codigo: codigo_postal_domicilio }
      );
    this.setState({ codigo_postal_domicilio });
  };
  handleColonia_domicilio = (e) => {
    let label = e.name_text;
    let colonia_domicilio = e[label];
    let estado_domicilio, ciudad_domicilio;
    this.state.colonias_domicilio.forEach((col) => {
      if (colonia_domicilio === col.asentamiento) {
        estado_domicilio = col.estado;
        ciudad_domicilio = col.ciudad;
      }
    });
    this.setState({ colonia_domicilio, estado_domicilio, ciudad_domicilio });
  };
  /* ------------------------ FUNCIONES QUE MODIFICAN LOS REPRESENTANTES LEGALES ------------------------ */
  hanldeNuevo_representante = () => {
    let nuevo_representante = this.state.nuevo_representante;
    let data = {
      nombre: this.state.nombre_representante,
      rfc: this.state.rfc_representante,
      curp: this.state.curp_representante,
    };
    nuevo_representante.push(data);
    this.setState({
      nuevo_representante,
      nombre_representante: "",
      rfc_representante: "",
      curp_representante: "",
    });
  };
  handleElimina_representante = (e) => {
    let representantes = this.state.representantes;
    let eliminacion_representante = this.state.eliminacion_representante;
    eliminacion_representante.push(representantes.splice(e.target.value, 1)[0]);
    this.setState({ representantes, eliminacion_representante });
  };
  handleElimina_nuevo_representante = (e) => {
    let nuevo_representante = this.state.nuevo_representante;
    nuevo_representante.splice(e.target.value, 1);
    this.setState(nuevo_representante);
  };
  handleModifica_representante_nombre = (e) => {
    let id = parseInt(e.target.id.slice(2, e.target.id.length));
    let modificacion_representante = this.state.modificacion_representante;
    let representantes = this.state.representantes;
    let posicion;
    for (let i = 0; i < representantes.length; i++) {
      if (representantes[i].id === id) {
        representantes[i].nombre = e.target.value;
        posicion = i;
        break;
      }
    }
    if (modificacion_representante.length === 0)
      modificacion_representante.push(representantes[posicion]);
    else
      for (let i = 0; i < modificacion_representante.length; i++) {
        if (modificacion_representante[i].id === representantes[posicion].id) {
          modificacion_representante[i] = { ...representantes[posicion] };
          break;
        }
        if (i === modificacion_representante.length - 1) {
          modificacion_representante.push(representantes[posicion]);
          break;
        }
      }
    this.setState({ representantes, modificacion_representante });
  };
  handleModifica_representante_rfc = (e) => {
    let id = parseInt(e.target.id.slice(2, e.target.id.length));
    let modificacion_representante = this.state.modificacion_representante;
    let representantes = this.state.representantes;
    let posicion;
    for (let i = 0; i < representantes.length; i++) {
      if (representantes[i].id === id) {
        representantes[i].rfc = e.target.value;
        posicion = i;
        break;
      }
    }
    if (modificacion_representante.length === 0)
      modificacion_representante.push(representantes[posicion]);
    else
      for (let i = 0; i < modificacion_representante.length; i++) {
        if (modificacion_representante[i].id === representantes[posicion].id) {
          modificacion_representante[i] = { ...representantes[posicion] };
          break;
        }
        if (i === modificacion_representante.length - 1) {
          modificacion_representante.push(representantes[posicion]);
          break;
        }
      }
    this.setState({ representantes, modificacion_representante });
  };
  handleModifica_representante_curp = (e) => {
    let id = parseInt(e.target.id.slice(2, e.target.id.length));
    let modificacion_representante = this.state.modificacion_representante;
    let representantes = this.state.representantes;
    let posicion;
    for (let i = 0; i < representantes.length; i++) {
      if (representantes[i].id === id) {
        representantes[i].curp = e.target.value;
        posicion = i;
        break;
      }
    }
    if (modificacion_representante.length === 0)
      modificacion_representante.push(representantes[posicion]);
    else
      for (let i = 0; i < modificacion_representante.length; i++) {
        if (modificacion_representante[i].id === representantes[posicion].id) {
          modificacion_representante[i] = { ...representantes[posicion] };
          break;
        }
        if (i === modificacion_representante.length - 1) {
          modificacion_representante.push(representantes[posicion]);
          break;
        }
      }
    this.setState({ representantes, modificacion_representante });
  };
  /* ------------------------ FUNCIONES QUE MODIFICAN LOS CONTACTOS ------------------------ */
  hanldeNuevo_contacto = () => {
    let nuevo_contacto = this.state.nuevo_contacto;
    let data = {
      nombre: this.state.nombre_contacto,
      telefono: this.state.telefono_contacto,
      correo: this.state.correo_contacto,
    };
    nuevo_contacto.push(data);
    this.setState({
      nuevo_contacto,
      nombre_contacto: "",
      telefono_contacto: "",
      correo_contacto: "",
    });
  };
  handleElimina_contacto = (e) => {
    let contactos_empresa = this.state.contactos_empresa;
    let eliminacion_contacto = this.state.eliminacion_contacto;
    eliminacion_contacto.push(contactos_empresa.splice(e.target.value, 1)[0]);
    this.setState({ contactos_empresa, eliminacion_contacto });
  };
  handleElimina_nuevo_contacto = (e) => {
    let nuevo_contacto = this.state.nuevo_contacto;
    nuevo_contacto.splice(e.target.value, 1);
    this.setState({ nuevo_contacto });
  };
  handleModifica_contacto_nombre = (e) => {
    let id = parseInt(e.target.id.slice(2, e.target.id.length));
    let modificacion_contacto = this.state.modificacion_contacto;
    let contactos_empresa = this.state.contactos_empresa;
    let posicion;
    for (let i = 0; i < contactos_empresa.length; i++) {
      if (contactos_empresa[i].id === id) {
        contactos_empresa[i].nombre = e.target.value;
        posicion = i;
        break;
      }
    }
    if (modificacion_contacto.length === 0)
      modificacion_contacto.push(contactos_empresa[posicion]);
    else
      for (let i = 0; i < modificacion_contacto.length; i++) {
        if (modificacion_contacto[i].id === contactos_empresa[posicion].id) {
          modificacion_contacto[i] = { ...contactos_empresa[posicion] };
          break;
        }
        if (i === modificacion_contacto.length - 1) {
          modificacion_contacto.push(contactos_empresa[posicion]);
          break;
        }
      }
    this.setState({ contactos_empresa, modificacion_contacto });
  };
  handleModifica_contacto_telefono = (e) => {
    let id = parseInt(e.target.id.slice(2, e.target.id.length));
    let modificacion_contacto = this.state.modificacion_contacto;
    let contactos_empresa = this.state.contactos_empresa;
    let posicion;
    for (let i = 0; i < contactos_empresa.length; i++) {
      if (contactos_empresa[i].id === id) {
        contactos_empresa[i].telefono = e.target.value;
        posicion = i;
        break;
      }
    }
    if (modificacion_contacto.length === 0)
      modificacion_contacto.push(contactos_empresa[posicion]);
    else
      for (let i = 0; i < modificacion_contacto.length; i++) {
        if (modificacion_contacto[i].id === contactos_empresa[posicion].id) {
          modificacion_contacto[i] = { ...contactos_empresa[posicion] };
          break;
        }
        if (i === modificacion_contacto.length - 1) {
          modificacion_contacto.push(contactos_empresa[posicion]);
          break;
        }
      }
    this.setState({ contactos_empresa, modificacion_contacto });
  };
  handleModifica_contacto_correo = (e) => {
    let id = parseInt(e.target.id.slice(2, e.target.id.length));
    let modificacion_contacto = this.state.modificacion_contacto;
    let contactos_empresa = this.state.contactos_empresa;
    let posicion;
    for (let i = 0; i < contactos_empresa.length; i++) {
      if (contactos_empresa[i].id === id) {
        contactos_empresa[i].correo = e.target.value;
        posicion = i;
        break;
      }
    }
    if (modificacion_contacto.length === 0)
      modificacion_contacto.push(contactos_empresa[posicion]);
    else
      for (let i = 0; i < modificacion_contacto.length; i++) {
        if (modificacion_contacto[i].id === contactos_empresa[posicion].id) {
          modificacion_contacto[i] = { ...contactos_empresa[posicion] };
          break;
        }
        if (i === modificacion_contacto.length - 1) {
          modificacion_contacto.push(contactos_empresa[posicion]);
          break;
        }
      }
    this.setState({ contactos_empresa, modificacion_contacto });
  };
  /* ------------------------ FUNCIONES QUE MODIFICAN LOS DOMICILIOS ------------------------ */
  handleNuevo_domicilio = (e) => {
    let nuevo_domicilio = this.state.nuevo_domicilio;
    let data = {
      nombre: this.state.quien_recibe_domicilio,
      direccion: this.state.domicilio_domicilio,
      numero_interior: this.state.numero_interior_domicilio,
      colonia: this.state.codigo_postal_domicilio,
      codigo_postal: this.state.colonia_domicilio,
      pais: this.state.pais_domicilio,
      telefono: this.state.telefono_domicilio,
    };
    nuevo_domicilio.push(data);
    this.setState(
      {
        nuevo_domicilio,
        quien_recibe_domicilio: "",
        telefono_domicilio: "",
        pais_domicilio: "",
        domicilio_domicilio: "",
        numero_interior_domicilio: "",
        codigo_postal_domicilio: "",
        estado_domicilio: "",
        ciudad_domicilio: "",
        colonia_domicilio: "",
      },
      () => {
        document.getElementById("pais_consulta_domicilio").value = null;
      }
    );
  };
  handleElimina_domicilio = (e) => {
    let domicilios_cliente = this.state.domicilios_cliente;
    let eliminacion_domicilio = this.state.eliminacion_domicilio;
    eliminacion_domicilio.push(domicilios_cliente.splice(e.target.value, 1)[0]);
    this.setState({ domicilios_cliente, eliminacion_domicilio });
  };
  handleElimina_nuevo_domicilio = (e) => {
    let nuevo_domicilio = this.state.nuevo_domicilio;
    nuevo_domicilio.splice(e.target.value, 1);
    this.setState({ nuevo_domicilio });
  };
  /**
   * Función que permite hacer una petición de forma POST.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} data Objeto a mandar atraves del metodo POST.
   */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "codigo_postal":
              if (this._isMounted)
                this.setState({
                  colonias: response.data.results,
                });
              break;
            case "codigo_postal_domicilio":
              if (this._isMounted)
                this.setState({
                  colonias_domicilio: response.data.results,
                });
              break;
            case "modificacion":
              break;
            case "consulta":
              this.setState(
                {
                  nombre: response.data.results.nombre,
                  rfc: response.data.results.rfc,
                  domicilio: response.data.results.direccion,
                  colonia: response.data.results.colonia,
                  codigo_postal: response.data.results.codigo_postal,
                  pais_id: response.data.results.pais,
                  pais_text: utility.get_text_by_id(
                    response.data.results.pais,
                    "clave",
                    "nombre",
                    this.props.paises
                  ),
                  estado: response.data.results.estado,
                  ciudad: response.data.results.ciudad,
                  patente_text: utility.get_text_by_id(
                    response.data.results.patente,
                    "patente",
                    "patente",
                    utility.patente_por_audana(
                      response.data.results.aduana,
                      this.props.patentes
                    )
                  ),
                  aduana: response.data.results.aduana,
                  apellido_paterno: response.data.results.apellido_paterno,
                  apellido_materno: response.data.results.apellido_materno,
                  curp: response.data.results.curp,
                  numero_interior: response.data.results.numero_interior,
                  encargo_conferido: response.data.results.encargo_conferido,
                  rfc_sociedad: response.data.results.rfc_sociedad,
                  cta_banco_pago_id: response.data.results.cta_banco_pago,
                  cta_banco_pago_text: utility.get_text_by_id(
                    response.data.results.cta_banco_pago,
                    "numero",
                    "banco",
                    this.props.bancos
                  ),
                  poder_notarial: response.data.results.poder_notarial,
                  grupo_id: response.data.results.id_grupo,
                  grupo_text: utility.get_text_by_id(
                    response.data.results.id_grupo,
                    "id",
                    "nombre",
                    this.props.grupos
                  ),

                  /* tarifa_entrega:
                    response.data.results.acuerdo_comercial.tarifa_entrega,
                  tarifa_importacion:
                    response.data.results.acuerdo_comercial.tarifa_importacion,
                  tarifa_exportacion:
                    response.data.results.acuerdo_comercial.tarifa_exportacion,
                  descuento_importacion:
                    response.data.results.acuerdo_comercial
                      .descuento_importacion,
                  descuento_exportacion:
                    response.data.results.acuerdo_comercial
                      .descuento_exportacion, */
                  tarifa_entrega: response.data.results.acuerdo_comercial
                    ? response.data.results.acuerdo_comercial.tarifa_entrega
                    : 0,
                  tarifa_importacion: response.data.results.acuerdo_comercial
                    ? response.data.results.acuerdo_comercial.tarifa_importacion
                    : 0,
                  tarifa_exportacion: response.data.results.acuerdo_comercial
                    ? response.data.results.acuerdo_comercial.tarifa_exportacion
                    : 0,
                  descuento_importacion: response.data.results.acuerdo_comercial
                    ? response.data.results.acuerdo_comercial
                        .descuento_importacion
                    : 0,
                  descuento_exportacion: response.data.results.acuerdo_comercial
                    ? response.data.results.acuerdo_comercial
                        .descuento_exportacion
                    : 0,

                  representantes: response.data.results.rl_cliente,
                  contactos_empresa: response.data.results.ce_cliente,
                  domicilios_cliente: response.data.results.de_cliente,

                  auxiliarContado: response.data.results.auxiliar[0]
                    ? response.data.results.auxiliar[0].contado
                    : "",
                  auxiliarAnticipo: response.data.results.auxiliar[0]
                    ? response.data.results.auxiliar[0].anticipo
                    : "",
                  auxiliarPhxcdc: response.data.results.auxiliar[0]
                    ? response.data.results.auxiliar[0].phxcdc
                    : "",
                  auxiliarCredito: response.data.results.auxiliar[0]
                    ? response.data.results.auxiliar[0].credito
                    : "",
                  auxiliarSaldo: response.data.results.auxiliar[0]
                    ? response.data.results.auxiliar[0].saldo_favor
                    : "",

                  auxiliarContadoOriginal: response.data.results.auxiliar[0]
                    ? response.data.results.auxiliar[0].contado
                    : "",
                  auxiliarAnticipoOriginal: response.data.results.auxiliar[0]
                    ? response.data.results.auxiliar[0].anticipo
                    : "",
                  auxiliarPhxcdcOriginal: response.data.results.auxiliar[0]
                    ? response.data.results.auxiliar[0].phxcdc
                    : "",
                  auxiliarCreditoOriginal: response.data.results.auxiliar[0]
                    ? response.data.results.auxiliar[0].credito
                    : "",
                  auxiliarSaldoOriginal: response.data.results.auxiliar[0]
                    ? response.data.results.auxiliar[0].saldo_favor
                    : "",

                  disabled: [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                  ],

                  enable_contacto: false,
                  enable_domicilio: false,
                  enable_cuentas_auxiliares: false,
                },
                () => {
                  this.methodPOST_API(
                    utility.url_codigo_postal,
                    "codigo_postal",
                    { codigo: response.data.results.codigo_postal }
                  );
                }
              );
              let options_pais = document.getElementById(
                "pais_consulta_cliente"
              ).options;
              let options_colonia = document.getElementById(
                "colonia_consulta_cliente"
              ).options;
              let options_grupo = document.getElementById(
                "grupo_consulta_cliente"
              ).options;
              let options_patente = document.getElementById(
                "patente_consulta_cliente"
              ).options;
              let options_banco = document.getElementById(
                "cta_consulta_cliente"
              ).options;
              if (this.state.encargo_conferido === "1")
                document.getElementById(
                  "encargo_consulta_cliente"
                ).checked = true;
              setTimeout(() => {
                if (this.state.pais === "MEX")
                  for (let i = 0; i < options_colonia.length; i++)
                    if (options_colonia[i].value === this.state.colonia)
                      document.getElementById(
                        "colonia_consulta_cliente"
                      ).value = options_colonia[i].value;
              }, 100);
              for (let i = 0; i < options_pais.length; i++)
                if (options_pais[i].value === this.state.pais)
                  document.getElementById("pais_consulta_cliente").value =
                    options_pais[i].value;
              for (let i = 0; i < options_grupo.length; i++)
                if (parseInt(options_grupo[i].value) === this.state.grupo)
                  document.getElementById("grupo_consulta_cliente").value =
                    options_grupo[i].value;
              for (let i = 0; i < options_patente.length; i++)
                if (options_patente[i].value === this.state.patente)
                  document.getElementById("patente_consulta_cliente").value =
                    options_patente[i].value;
              for (let i = 0; i < options_banco.length; i++)
                if (options_banco[i].value === this.state.cta_banco_pago)
                  document.getElementById("cta_consulta_cliente").value =
                    options_banco[i].value;
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handleGuardar = () => {
    let datos = {};

    if (this.state.auxiliarContado !== this.state.auxiliarContadoOriginal)
      datos.contado = this.state.auxiliarContado;
    if (this.state.auxiliarAnticipo !== this.state.auxiliarAnticipoOriginal)
      datos.anticipo = this.state.auxiliarAnticipo;
    if (this.state.auxiliarPhxcdc !== this.state.auxiliarPhxcdcOriginal)
      datos.phxcdc = this.state.auxiliarPhxcdc;
    if (this.state.auxiliarCredito !== this.state.auxiliarCreditoOriginal)
      datos.credito = this.state.auxiliarCredito;
    if (this.state.auxiliarSaldo !== this.state.auxiliarSaldoOriginal)
      datos.saldo_favor = this.state.auxiliarSaldo;

    datos.catalogo = "cl";
    datos.id = this.state.numero;

    if (Object.keys(datos).length === 2) {
      message.warningMessage("No hubo modificación");
      return;
    }

    axios
      .post(utility.url_crea_auxiliares, datos)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted) {
            message.message_success(
              "Se modificó exitosamente",
              "Registros de control de auxiliares modificados"
            );
          }
          /* this.handleCerrar(); */
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>Consulta Cliente</ModalHeader>
        <ModalBody>
          <div className="row mb-2 d-flex justify-content-center">
            <div className="col-lg-10 col-12 d-flex justify-content-around">
              <Button color="info" onClick={this.toogleCliente}>
                Cliente
              </Button>
              <Button
                color="info"
                onClick={this.toogleContactos}
                disabled={this.state.enable_contacto}
              >
                Contactos
              </Button>
              <Button
                color="info"
                onClick={this.toogleDomicilios}
                disabled={this.state.enable_domicilio}
              >
                Domicilios
              </Button>
              <Button
                color="info"
                onClick={this.toogleCuentasAuxiliares}
                disabled={this.state.enable_cuentas_auxiliares}
              >
                Cuentas Auxiliares
              </Button>
            </div>
          </div>
          <Collapse isOpen={this.state.toogle_cliente}>
            <Card outline color="info">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-10 col-8"
                      id="numero_consulta"
                      onChange={this.handleNumero}
                      nameLabel="Número Cliente"
                    />
                    <Button
                      color="primary"
                      className="col-lg-2 col-4 mb-2"
                      onClick={this.handleConsulta}
                    >
                      Consulta
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="nombre_consulta_cliente"
                      nameLabel="Nombre"
                      value={this.state.nombre}
                      onChange={this.handleNombre}
                      disabled={this.state.disabled[0]}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="apellidoP_consulta_cliente"
                      nameLabel="Apellido Parterno"
                      value={this.state.apellido_paterno}
                      onChange={this.handleApellidoP}
                      disabled={this.state.disabled[1]}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="apellidoM_consulta_cliente"
                      nameLabel="Apellido Materno"
                      value={this.state.apellido_materno}
                      onChange={this.handleApellidoM}
                      disabled={this.state.disabled[2]}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="rfc_consulta_cliente"
                      nameLabel="RFC"
                      value={this.state.rfc}
                      onChange={this.handleRFC}
                      disabled={this.state.disabled[3]}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="curp_consulta_cliente"
                      nameLabel="CURP"
                      value={this.state.curp}
                      onChange={this.handleCURP}
                      disabled={this.state.disabled[4]}
                    />
                    <CustomSelect
                      bootstrap="col-lg-4 col-12"
                      name_text="nombre"
                      name_index="clave"
                      id="pais_consulta_cliente"
                      nameLabel="País"
                      value={this.state.pais_text}
                      onChange={this.handlePais}
                      options={this.props.paises}
                      disabled={this.state.disabled[5]}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="direccion_consulta_cliente"
                      nameLabel="Domicilio"
                      value={this.state.domicilio}
                      onChange={this.handleDomicilio}
                      disabled={this.state.disabled[6]}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="numero_interior_consulta_cliente"
                      nameLabel="Num. Interior"
                      value={this.state.numero_interior}
                      onChange={this.handleNumero_int}
                      disabled={this.state.disabled[7]}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="cp_consulta_cliente"
                      nameLabel="Código Postal"
                      value={this.state.codigo_postal}
                      onChange={this.handleCodigo_postal}
                      disabled={this.state.disabled[8]}
                    />
                    {this.state.pais_id !== "MEX" ? (
                      <MyInput
                        type="text"
                        bootstrap="col-lg-3 col-12"
                        id="colonia_consulta_cliente"
                        nameLabel="Colonia"
                        value={this.state.colonia}
                        onChange={this.handleColonia}
                        disabled={this.state.disabled[9]}
                      />
                    ) : (
                      <CustomSelect
                        bootstrap="col-lg-3 col-12"
                        name_text="asentamiento"
                        name_index="asentamiento"
                        id="colonia_consulta_cliente"
                        nameLabel="Colonia"
                        onChange={this.handleColonia}
                        options={this.state.colonias}
                        value={this.state.colonia}
                        disabled={this.state.disabled[9]}
                      />
                    )}
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="estado_consulta_cliente"
                      nameLabel="Estado/Provincia/Región"
                      value={this.state.estado}
                      disabled={this.state.disabled[10]}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="ciudad_consulta_cliente"
                      nameLabel="Ciudad"
                      value={this.state.ciudad}
                      disabled={this.state.disabled[11]}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="aduana_consulta_cliente"
                      nameLabel="Aduana"
                      value={this.state.aduana}
                      onChange={this.handleAduana}
                      disabled={this.state.disabled[12]}
                    />
                    <CustomSelect
                      bootstrap="col-lg-3 col-12"
                      name_text="patente"
                      name_index="patente"
                      id="patente_consulta_cliente"
                      nameLabel="Patente"
                      onChange={this.handlePatente}
                      options={utility.patente_por_audana(
                        this.state.aduana,
                        this.props.patentes
                      )}
                      value={this.state.patente_text}
                      disabled={this.state.disabled[13]}
                    />
                    <CustomSelect
                      bootstrap="col-lg-3 col-12"
                      name_text="nombre"
                      name_index="id"
                      id="grupo_consulta_cliente"
                      nameLabel="Grupo"
                      onChange={this.handleGrupo}
                      options={this.props.grupos}
                      value={this.state.grupo_text}
                      disabled={this.state.disabled[14]}
                    />
                    <CustomSelect
                      bootstrap="col-lg-3 col-12"
                      name_text="nombre"
                      name_index="clave"
                      id="regimen_fiscal_consulta_cliente"
                      nameLabel="Regimen Fiscal"
                      onChange={this.handleRegimen}
                      options={this.props.regimen_fiscal}
                      value={this.state.regimen_fiscal_text}
                      disabled={this.state.disabled[15]}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="rfc_sociedad_consulta_cliente"
                      nameLabel="RFC Sociedad"
                      value={this.state.rfc_sociedad}
                      onChange={this.handleRFC_social}
                      disabled={this.state.disabled[16]}
                    />
                    <MyInput
                      bootstrap="col-lg-3 col-12"
                      name_text="banco"
                      name_index="numero"
                      id="cta_consulta_cliente"
                      nameLabel="Cta. Banco"
                      value={this.state.cta_banco_pago_id}
                      onChange={this.handleCuenta}
                      options={this.props.bancos}
                      disabled={this.state.disabled[17]}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="notarial_consulta_cliente"
                      nameLabel="Poder notarial"
                      value={this.state.poder_notarial}
                      onChange={this.handlePoder}
                      disabled={this.state.disabled[18]}
                    />
                    <div className="col-lg-3 col-12 d-flex justify-content-center align-items-center">
                      <p className="mb-1">Encargo conferido</p>&nbsp;&nbsp;
                      <label
                        className="switch"
                        htmlFor="encargo_consulta_cliente"
                      >
                        <input
                          type="checkbox"
                          id="encargo_consulta_cliente"
                          onChange={this.handleEncargo}
                          disabled={this.state.disabled[19]}
                        />
                        <div className="slider round"></div>
                      </label>
                    </div>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="tarifa_entrega_consulta_cliente"
                      nameLabel="Tarifa entrega"
                      value={this.state.tarifa_entrega}
                      onChange={this.handleTarifa_entrega}
                      disabled={this.state.disabled[20]}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="tarifa_importacion_consulta_cliente"
                      nameLabel="Tarifa importación"
                      value={this.state.tarifa_importacion}
                      onChange={this.handleTarifa_importacion}
                      disabled={this.state.disabled[21]}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="tarifa_exportación_consulta_cliente"
                      nameLabel="Tarifa exportación"
                      value={this.state.tarifa_exportacion}
                      onChange={this.handleTarifa_exportacion}
                      disabled={this.state.disabled[22]}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="descuento_importacion_consulta_cliente"
                      nameLabel="Descuento importación"
                      value={this.state.descuento_importacion}
                      onChange={this.handleDescuento_importacion}
                      disabled={this.state.disabled[23]}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="descuento_exportacion_consulta_cliente"
                      nameLabel="Descuento exportación"
                      value={this.state.descuento_exportacion}
                      onChange={this.handleDescuento_exportacion}
                      disabled={this.state.disabled[24]}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="nombre_representante_alta_cliente"
                      nameLabel="Nombre representante"
                      value={this.state.nombre_representante}
                      onChange={this.handleNombre_representante}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="rfc_representante_alta_cliente"
                      nameLabel="RFC Representante"
                      value={this.state.rfc_representante}
                      onChange={this.handleRFC_representante}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="curp_representante_alta_cliente"
                      nameLabel="CURP Representante"
                      value={this.state.curp_representante}
                      onChange={this.handleCURP_representante}
                    />
                    <Button
                      color="primary"
                      className="col-lg-2 col-12 mb-2"
                      onClick={this.hanldeNuevo_representante}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                </Form>
                <Table size="sm" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>Nombre</td>
                      <td>RFC</td>
                      <td>CURP</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.representantes.map((rep, index) => (
                      <tr key={rep.id}>
                        <td className="text-center">
                          <Input
                            type="text"
                            value={rep.nombre}
                            id={`n_${rep.id}`}
                            onChange={this.handleModifica_representante_nombre}
                          />
                        </td>
                        <td className="text-center">
                          <Input
                            type="text"
                            value={rep.rfc}
                            id={`r_${rep.id}`}
                            onChange={this.handleModifica_representante_rfc}
                          />
                        </td>
                        <td className="text-center">
                          <Input
                            type="text"
                            value={rep.curp}
                            id={`c_${rep.id}`}
                            onChange={this.handleModifica_representante_curp}
                          />
                        </td>
                        <td className="text-center">
                          <Button
                            color="danger"
                            value={index}
                            onClick={this.handleElimina_representante}
                          >
                            Eliminar
                          </Button>
                        </td>
                      </tr>
                    ))}
                    {this.state.nuevo_representante.map((rep, index) => (
                      <tr key={`${index}_n`}>
                        <td className="text-center">{rep.nombre}</td>
                        <td className="text-center">{rep.rfc}</td>
                        <td className="text-center">{rep.curp}</td>
                        <td className="text-center">
                          <Button
                            color="danger"
                            value={index}
                            onClick={this.handleElimina_nuevo_representante}
                          >
                            Eliminar
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="container-fluid">
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      color="info"
                      className="mr-2"
                      onClick={this.handleModificar_cliente}
                      disabled={permisos.obtener_permiso_especifico(
                        "modificar",
                        this.props.permisos
                      )}
                    >
                      Modificar
                    </Button>
                    <Button color="primary" onClick={this.handleCerrar}>
                      Cerrar
                    </Button>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_contactos}>
            <Card outline color="primary">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-12"
                      id="numero_consulta_contacto"
                      nameLabel="Numero del Cliente"
                      value={this.state.numero}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="nombre_consulta_contacto"
                      nameLabel="Nombre"
                      value={this.state.nombre_contacto}
                      onChange={this.hanldeNombre_contacto}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="telefono_consulta_contacto"
                      nameLabel="Telefono"
                      value={this.state.telefono_contacto}
                      onChange={this.handleTelefono_contacto}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="correo_consulta_contacto"
                      nameLabel="Correo"
                      value={this.state.correo_contacto}
                      onChange={this.handleCorreo_contacto}
                    />
                    <Button
                      color="primary"
                      className="col-lg-2 col-12 mb-2"
                      onClick={this.hanldeNuevo_contacto}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                </Form>
                <Table size="sm" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>Nombre</td>
                      <td>Telefono</td>
                      <td>Correo</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.contactos_empresa.map((rep, index) => (
                      <tr key={index}>
                        <td className="text-center">
                          <Input
                            type="text"
                            value={rep.nombre}
                            id={`a_${rep.id}`}
                            onChange={this.handleModifica_contacto_nombre}
                          />
                        </td>
                        <td className="text-center">
                          <Input
                            type="text"
                            value={rep.telefono}
                            id={`t_${rep.id}`}
                            onChange={this.handleModifica_contacto_telefono}
                          />
                        </td>
                        <td className="text-center">
                          <Input
                            type="text"
                            value={rep.correo}
                            id={`e_${rep.id}`}
                            onChange={this.handleModifica_contacto_correo}
                          />
                        </td>
                        <td className="text-center">
                          <Button
                            color="danger"
                            size="sm"
                            value={index}
                            onClick={this.handleElimina_contacto}
                          >
                            Eliminar
                          </Button>
                        </td>
                      </tr>
                    ))}
                    {this.state.nuevo_contacto.map((rep, index) => (
                      <tr key={`n_${index}`}>
                        <td className="text-center">{rep.nombre}</td>
                        <td className="text-center">{rep.telefono}</td>
                        <td className="text-center">{rep.correo}</td>
                        <td className="text-center">
                          <Button
                            color="danger"
                            size="sm"
                            value={index}
                            onClick={this.handleElimina_nuevo_contacto}
                          >
                            Eliminar
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="container-fluid">
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      color="info"
                      className="mr-2"
                      onClick={this.handleModificar_cliente}
                      disabled={permisos.obtener_permiso_especifico(
                        "modificar",
                        this.props.permisos
                      )}
                    >
                      Modificar
                    </Button>
                    <Button color="primary" onClick={this.handleCerrar}>
                      Cerrar
                    </Button>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_domicilios}>
            <Card outline color="secondary">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-12"
                      id="numero_consulta_domicilio"
                      nameLabel="Numero del Cliente"
                      value={this.state.numero}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="quien_recibe_consulta_domicilio"
                      nameLabel="Quien recibe"
                      value={this.state.quien_recibe_domicilio}
                      onChange={this.hanldeNombre_domicilio}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="telefono_consulta_domicilio"
                      nameLabel="Telefono"
                      value={this.state.telefono_domicilio}
                      onChange={this.handleTelefono_domicilio}
                    />
                    <CustomSelect
                      bootstrap="col-lg-4 col-12"
                      name_text="nombre"
                      name_index="clave"
                      id="pais_consulta_domicilio"
                      nameLabel="País"
                      value={this.state.pais_domicilio_text}
                      onChange={this.handlePais_contacto}
                      options={this.props.paises}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="direccion_consulta_domicilio"
                      nameLabel="Domicilio"
                      value={this.state.domicilio_domicilio}
                      onChange={this.handleDomicilio_domicilio}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="numero_interior_consulta_domicilio"
                      nameLabel="Num. Interior"
                      value={this.state.numero_interior_domicilio}
                      onChange={this.handleNumero_int_domicilio}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="cp_consulta_domicilio"
                      nameLabel="Código Postal"
                      value={this.state.codigo_postal_domicilio}
                      onChange={this.handleCodigo_postal_domicilio}
                    />
                    {this.state.pais_domicilio_id !== "MEX" ? (
                      <MyInput
                        type="text"
                        bootstrap="col-lg-3 col-12"
                        id="colonia_consulta_domicilio"
                        nameLabel="Colonia"
                        onChange={this.handleColonia_domicilio}
                      />
                    ) : (
                      <CustomSelect
                        bootstrap="col-lg-3 col-12"
                        name_text="asentamiento"
                        name_index="asentamiento"
                        id="colonia_consulta_domicilio"
                        nameLabel="Colonia"
                        onChange={this.handleColonia_domicilio}
                        options={this.state.colonias_domicilio}
                        value={this.state.colonia_domicilio}
                      />
                    )}
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="estado_consulta_domicilio"
                      nameLabel="Estado/Provincia/Región"
                      value={this.state.estado_domicilio}
                      disabled
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="ciudad_consulta_domicilio"
                      nameLabel="Ciudad"
                      value={this.state.ciudad_domicilio}
                      disabled
                    />
                    <Button
                      color="primary"
                      className="col-12 mb-2"
                      onClick={this.handleNuevo_domicilio}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                </Form>
                <Table size="sm" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>Quien recibe</td>
                      <td>País</td>
                      <td>Domicilio</td>
                      <td>Código Postal</td>
                      <td>Colonia</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.domicilios_cliente.map((rep, index) => (
                      <tr key={index}>
                        <td className="text-center">{rep.nombre}</td>
                        <td className="text-center">{rep.pais}</td>
                        <td className="text-center">{rep.direccion}</td>
                        <td className="text-center">{rep.codigo_postal}</td>
                        <td className="text-center">{rep.colonia}</td>
                        <td className="text-center">
                          <Button
                            color="danger"
                            size="sm"
                            value={index}
                            onClick={this.handleElimina_domicilio}
                          >
                            Eliminar
                          </Button>
                        </td>
                      </tr>
                    ))}
                    {this.state.nuevo_domicilio.map((rep, index) => (
                      <tr key={`n_${index}`}>
                        <td className="text-center">{rep.nombre}</td>
                        <td className="text-center">{rep.pais}</td>
                        <td className="text-center">{rep.direccion}</td>
                        <td className="text-center">{rep.codigo_postal}</td>
                        <td className="text-center">{rep.colonia}</td>
                        <td className="text-center">
                          <Button
                            color="danger"
                            size="sm"
                            value={index}
                            onClick={this.handleElimina_nuevo_domicilio}
                          >
                            Eliminar
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="container-fluid">
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      color="info"
                      className="mr-2"
                      onClick={this.handleModificar_cliente}
                      disabled={permisos.obtener_permiso_especifico(
                        "modificar",
                        this.props.permisos
                      )}
                    >
                      Modificar
                    </Button>
                    <Button color="primary" onClick={this.handleCerrar}>
                      Cerrar
                    </Button>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_cuentas_auxiliares}>
            <Card outline color="info">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-8 col-12"
                      id="cuenta_auxiliar"
                      nameLabel="Cuenta Auxiliar Contado"
                      value={this.state.auxiliarContado}
                      onChange={this.handleAuxiliarC}
                    />
                    <Button
                      color="primary"
                      className="col-lg-4 col-12 mb-2"
                      onClick={this.toogleAuxiliarContado}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-8 col-12"
                      id="cuenta_auxiliar"
                      nameLabel="Cuenta Auxiliar Anticipo"
                      value={this.state.auxiliarAnticipo}
                      onChange={this.handleAuxiliarA}
                    />
                    <Button
                      color="primary"
                      className="col-lg-4 col-12 mb-2"
                      onClick={this.toogleAuxiliarAnticipo}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-8 col-12"
                      id="cuenta_auxiliar"
                      nameLabel="Cuenta Auxiliar Pagos A Terceros"
                      value={this.state.auxiliarPhxcdc}
                      onChange={this.handleAuxiliarP}
                    />
                    <Button
                      color="primary"
                      className="col-lg-4 col-12 mb-2"
                      onClick={this.toogleAuxiliarPhxcdc}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-8 col-12"
                      id="cuenta_auxiliar"
                      nameLabel="Cuenta Auxiliar Crédito"
                      value={this.state.auxiliarCredito}
                      onChange={this.handleCredito}
                    />
                    <Button
                      color="primary"
                      className="col-lg-4 col-12 mb-2"
                      onClick={this.toogleAuxiliarCredito}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-8 col-12"
                      id="cuenta_auxiliar"
                      nameLabel="Cuenta Auxiliar Saldo A Favor"
                      value={this.state.auxiliarSaldo}
                      onChange={this.handleAuxiliarS}
                    />
                    <Button
                      color="primary"
                      className="col-lg-4 col-12 mb-2"
                      onClick={this.toogleAuxiliarSaldo}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                </Form>
                <div className="container-fluid">
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      color="info"
                      id="btn-guardar"
                      className="mr-2"
                      onClick={this.handleGuardar}
                    >
                      Modificar
                    </Button>
                    <Button color="primary" onClick={this.handleCerrar}>
                      Cerrar
                    </Button>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Collapse>
        </ModalBody>
        <ModalAuxiliarContado
          toogle_catalogo={this.state.toogle_auxiliar_contado}
          toogleCatalogo={this.toogleAuxiliarContado}
          parentCallback={this.handleCallbackContado}
          codigos_sat={this.props.codigos_sat}
        />
        <ModalAuxiliarAnticipo
          toogle_catalogo={this.state.toogle_auxiliar_anticipo}
          toogleCatalogo={this.toogleAuxiliarAnticipo}
          parentCallback={this.handleCallbackAnticipo}
          codigos_sat={this.props.codigos_sat}
        />
        <ModalAuxiliarPhxcdc
          toogle_catalogo={this.state.toogle_auxiliar_phxcdc}
          toogleCatalogo={this.toogleAuxiliarPhxcdc}
          parentCallback={this.handleCallbackPhxcdc}
          codigos_sat={this.props.codigos_sat}
        />
        <ModalAuxiliarCredito
          toogle_catalogo={this.state.toogle_auxiliar_credito}
          toogleCatalogo={this.toogleAuxiliarCredito}
          parentCallback={this.handleCallbackCredito}
          codigos_sat={this.props.codigos_sat}
        />
        <ModalAuxiliarSaldo
          toogle_catalogo={this.state.toogle_auxiliar_saldo}
          toogleCatalogo={this.toogleAuxiliarSaldo}
          parentCallback={this.handleCallbackSaldo}
          codigos_sat={this.props.codigos_sat}
        />
      </Modal>
    );
  }
}
export default ModalConsulta;
