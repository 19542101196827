/* 
Componente que genera un input de tipo select orientado al diseño del sistema
*/
import React from 'react';
import '../../../assets/style.css';

const MySelect = (props) => {
    return (
        <div className={`grupo p-0 ${props.bootstrap}`}>
            <select className="input-animated col-12 mb-2" id={props.id} disabled={props.disabled} onChange={props.onChange}
                style={{ background: "#fff", padding: "7px 10px" }} required={props.required === "false" ? false : true}>
                <option value="null">{props.first_option}</option>
                {props.children}
            </select>
            <span className="barra"></span>
            <br /><label className="label-animated" htmlFor={props.id}>{props.nameLabel}</label>
        </div>
    );
}
export default MySelect;