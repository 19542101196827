export const url_pagination_transacciones = '/DjangoWeb/TransaccionesApp/consulta/transacciones/paginacion';
export const url_alta_transaccion = '/DjangoWeb/TransaccionesApp/crea_modifica/transacciones';
export const url_consulta_transaccion = '/DjangoWeb/TransaccionesApp/consulta/transacciones';

export const url_beneficiarios = '/DjangoWeb/BaseApp/consulta/beneficiarios';
export const url_bancos = '/DjangoWeb/BaseApp/consulta/bancos/lista';
export const url_egresos = '/DjangoWeb/BaseApp/consulta/cat_egresos/lista';

export const url_desglose_operacion = '/DjangoWeb/OperacionesApp/consulta/operacion/pagos';
export const url_verify = '/DjangoWeb/UsuariosApp/verica/usuario';
export const url_phxcdc = '/DjangoWeb/BaseApp/consulta/cat_phxcdc/lista';

export const url_auxiliares = '/DjangoWeb/CuentasAuxiliaresApp/consulta/cuentas_auxiliares/paginacion/principal'; 
export const url_cat_phxcdc = '/DjangoWeb/BaseApp/consulta/cat_phxcdc/lista'; 

export const url_info_bancos = '/DjangoWeb/BaseApp/consulta/bancos'; 
export const url_clientes = '/DjangoWeb/BaseApp/consulta/clientes/lista';

export const lista_beneficiarios = '/DjangoWeb/BaseApp/consulta/beneficiarios/lista'; 

/**
 * Función que nos permite filtar la tabla por el input general que serra de tipo text.
 */
export const general_search = (e) => {
    e.preventDefault();
    let tr = document.getElementById('tabla_patentes').getElementsByTagName('tr');
    let data_input = document.getElementById('busqueda_general_patentes').value.toUpperCase();
    for (let i = 0; i < tr.length; i++) {
        let mostrar = false;
        let data = tr[i].getElementsByTagName('p');
        for (let j = 0; j < data.length; j++) {
            if (data_input === "") {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
            else if (data[j].textContent.toUpperCase().includes(data_input)) {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "rgba(0,123,255,1)";
                data[j].style.color = "#fff";
            }
            else {
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que nos permite filtrar latabla solamnete por la fecha.
 */
export const date_search = (e) => {
    e.preventDefault();
    var tabla_anticipo, input_general, tr, dato, input_dato, mostrar;
    tabla_anticipo = document.getElementById("tabla_patentes");
    input_general = document.getElementById("busqueda_date_patentes");
    input_dato = input_general.value;
    tr = tabla_anticipo.getElementsByTagName("tr");
    for (var i = 0; i < tr.length; i++) {
        mostrar = false;
        dato = tr[i].getElementsByTagName("p");
        for (var j = 0; j < dato.length; j++) {
            if (dato[j].textContent === input_dato) {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "rgba(0,123,255,1)";
                dato[j].style.color = "#fff";
            }
            else if (input_dato === "") {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
            else {
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}

export const obtener_nombre_pagos = (id, array) => {
    for (let i = 0; i < array.length; i++)
        if (id === array[i].clave)
            return array[i].nombre;
}

export const validaCamposPOST = (data) => {
    let message = "";
    if (data.numero)
        if (data.numero.length === 0)
            delete data.numero;
    if (data.banco.length === 0)
        message += "Banco, ";
    if (data.beneficiario.length === 0)
        message += "Beneficiario, ";
    if (data.concepto.length === 0)
        message += "Concepto, ";
    if (data.fecha.length === 0)
        message += "Fecha, ";
    if (data.observaciones.length === 0)
        message += "Observaciones, ";
    if (data.auxiliar.length === 0)
        message += "Auxiliar, ";
    if (data.tipo.length === 0)
        message += "Tipo, ";

    message = message.slice(0, message.length - 2);
    return message;
}

/**
 * Función que obtiene los nombres de todos los clientes
 * @param {array} arreglo 
 * @returns {array} Arreglo de nombres.
 */
 export const get_nombres_clientes = (arreglo) => {
    var aux = [];
    for (var i = 0; i < arreglo.length; i++) {
        aux.push(arreglo[i].nombre);
    }
    return (aux);
}

/**
 * Finción que permite el retorno del número de cliente por su nombre.
 * @param {Sring} nombre Nombre del cliente. 
 * @param {Array} array Arreglo de clientes.
 * @returns {String} Número del cliente.
 */
 export const get_number_cliente = (nombre, array) => {
    let only_number = "";
    let bandera = 0;
    for (let i = 0; i < array.length; i++)
        if (nombre === array[i].nombre) {
            for (let j = 0; j < 4; j++) {
                if (array[i].numero[j] !== '0')
                    bandera = 1;
                if (bandera === 1)
                    only_number += array[i].numero[j];
            }
            return (only_number);
        }
}
