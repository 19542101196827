/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de una notificación de embarque. 
*/
import { faEye, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "@sweetalert/with-react";
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  CustomInput,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import WebSocketInstance from "../../../utils/websocket";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";
import MyInput from "../../../utils/custom/MyInput";
import MyTextArea from "../../../utils/custom/MyTextArea";
import CustomSelect from "../../../utils/custom/CustomSelect";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      importeAlta: "0.00",
      numero_select:
        this.props.on_cliente === false ? "" : this.props.on_cliente,
      nombre_select:
        this.props.on_cliente === false ? "" : localStorage.getItem("emp_name"),
      cliente_enable: this.props.on_cliente === false ? false : true,
      patentes: [],
      nombre_suggestions: [],
      aduana_select: [],
      patente_text: "Seleccione una patente",
      patente_id: "",
      tipo_alta_text: "Seleccione un tipo de alta",
      tipo_alta_id: "",
      nombre_select_con: "",
      numero_select_con: "",
      nombre_suggestions_con: [],
      tipo_alta: this.props.on_cliente === false ? "null" : "2",

      identificativo: "",
      observaciones: "SIN OBSERVACIONES",

      tipo_alta: [
        { clave: "0", nombre: "Trafico" },
        { clave: "1", nombre: "Cotizador" },
        { clave: "2", nombre: "Cliente" },
      ],
    };
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.identificativo;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "notificacion_embarque",
      motivo: motivo,
      folio: [folio],
    };
    if (this.props.on_cliente === false)
      notificacionObject["cliente"] = [parseInt(this.state.numero_select)];
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_patentes, "patentes");
    document.addEventListener("keyup", this.handleEnterKeyPress);
  }
  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("keyup", this.handleEnterKeyPress);
    this.methodGET_API(utility.url_patentes, "patentes");
  }
  handleChange = (event) => {
    this.setState({ observaciones: event.target.value });
  };
  handleCerrar = () => {
    if (this.props.on_cliente === false)
      this.setState({
        numero_select: "",
        nombre_select: "",
        cliente_enable: false,
        nombre_suggestions: [],
        aduana_select: [],
        nombre_select_con: "",
        numero_select_con: "",
        nombre_suggestions_con: [],
      });
    else
      this.setState({
        nombre_suggestions: [],
        aduana_select: [],
        nombre_select_con: "",
        numero_select_con: "",
        nombre_suggestions_con: [],
      });
    this.props.toogleNuevo();
    this.setState({
      importeAlta: "0.00",
      numero_select:
        this.props.on_cliente === false ? "" : this.props.on_cliente,
      nombre_select:
        this.props.on_cliente === false ? "" : localStorage.getItem("emp_name"),
      cliente_enable: this.props.on_cliente === false ? false : true,
      nombre_suggestions: [],
      aduana_select: [],
      patente_text: "Seleccione una patente",
      patente_id: "",
      tipo_alta_text: "Seleccione un tipo de alta",
      tipo_alta_id: "",
      nombre_select_con: "",
      numero_select_con: "",
      nombre_suggestions_con: [],
      tipo_alta: this.props.on_cliente === false ? "null" : "2",

      identificativo: "",

      tipo_alta: [
        { clave: "0", nombre: "Trafico" },
        { clave: "1", nombre: "Cotizador" },
        { clave: "2", nombre: "Cliente" },
      ],
    });
  };
  handleValida = (data) => {
    let message = "";
    if (data.tipo_alta.length < 1) message += "Tipo alta, ";
    if (data.patente === null) message += "Patente, ";
    if (data.cliente.length < 1) message += "Cliente, ";
    if (data.guia_principal.length < 1) message += "Guia Principal, ";
    if (isNaN(data.desconsolidacion)) message += "Desconsolidación invalida,";
    if (data.numero_vuelo.length < 1) message += "Número de vuelo, ";
    if (data.observaciones.length < 1) message += "Observaciones,  ";
    if (isNaN(data.importe_flete)) message += "Importe invalido,";
    if (data.tipo_flete === 0) delete data.importe_flete;
    else if (data.importe_flete.length < 1) data.importe_flete = "0.00";
    if (message.length > 0) {
      swal("LLenar los campos: ", message, "warning");
      return 0;
    }
    return 1;
  };
  handleAlta = () => {
    message
      .message_confimacion("¿Desea continuar con la operación alta?", "")
      .then((res) => {
        if (res) {
          // Obtener y formatear los valores
          let importeFlete = parseFloat(document.getElementById("importe_alerta_alta").value);
          let desconsolidacion = parseFloat(document.getElementById("desconsolidacion_alerta_alta").value);
    
          // Asegurarse de que los valores tengan al menos dos decimales
          importeFlete = importeFlete.toFixed(2);
          desconsolidacion = desconsolidacion.toFixed(2);
    
          // Agregar ".00" si no hay decimales
          if (!importeFlete.includes('.')) importeFlete += ".00";
          if (!desconsolidacion.includes('.')) desconsolidacion += ".00";
    
          let data = {
            tipo_alta: this.state.tipo_alta_id,
            patente: this.state.patente_id,
            aduana: document.getElementById("aduana_alerta_alta").value,
            cliente: document.getElementById("numero_cliente_alerta_alta").value,
            guia_principal: document.getElementById("guia_alerta_alta").value,
            tipo_flete: document.getElementById("tipo_flete_alerta_alta").checked ? "1" : "0",
            importe_flete: importeFlete,
            desconsolidacion: desconsolidacion,
            numero_vuelo: document.getElementById("numero_vuelo_alerta_alta").value,
            consolidadora: document.getElementById("numero_consolidadora_alerta_alta").value,
            consignada: document.getElementById("consiganada_alerta_alta").checked ? "1" : "0",
            observaciones: document.getElementById("observaciones_alerta_alta").value,
            quien_notifica: document.getElementById("notifica_alerta_alta").value,
          };
    
          if (this.handleValida(data) === 0) return 0;
          this.methodPOST_API(utility.url_alta_alerta, "alta", data);
        }
      });
  };
  
  
  
  handlePatente = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let patente_text = e[label];
    let patente_id = e[label_id];
    this.setState({ patente_text, patente_id });
  };
  handleTipo_alta = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_alta_text = e[label];
    let tipo_alta_id = e[label_id];
    this.setState({ tipo_alta_text, tipo_alta_id });
  };

  handleImporteAlta = (value) => {
    let importeAlta = String(value); 

    // Remover caracteres que no son números, puntos o comas
    importeAlta = importeAlta.replace(/[^\d.,]/g, "");

    // Reemplazar comas por puntos
    importeAlta = importeAlta.replace(/,/g, ".");

    // Si hay más de un punto, eliminar los puntos extras
    const dotCount = importeAlta.split(".").length - 1;
    if (dotCount > 1) {
      importeAlta = importeAlta.replace(/\./g, (match, offset) =>
        offset ? "" : match
      );
    }

    return importeAlta; // Return processed value
  };

  handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      // Si la tecla presionada fue 'Enter', llamamos a la función para manejar el evento
      if (e.target.id === "importe_alerta_alta") {
        let importeAlta = this.handleImporteAlta(e.target.value);
        importeAlta = this.autoCompleteZeros(importeAlta);
        this.setState({ importeAlta });
      }
    }
  };

  autoCompleteZeros = (value) => {
    // Agregar ceros si no hay decimales
    const parts = value.split(".");
    if (parts.length === 1) {
      return `${parts[0]}.00`;
    } else if (parts[1].length === 1) {
      // Agregar un cero si solo hay un decimal
      return `${parts[0]}.${parts[1]}0`;
    } else {
      return value;
    }
  };

  handleImporteAltaBlur = () => {
    let importeAlta = this.state.importeAlta;

    // Si el campo está vacío, establecer el valor como '0.00'
    if (!importeAlta) {
      importeAlta = "0.00";
    } else {
      // Convertir el valor a un número
      let num = parseFloat(importeAlta);

      // Verificar si el valor es un número válido
      if (!isNaN(num)) {
        // Formatear el número con dos decimales
        importeAlta = num.toFixed(2);
      } else {
        // Si no es un número válido, establecer el valor como '0.00'
        importeAlta = "0.00";
      }
    }

    this.setState({ importeAlta });
  };

  /**
   * Función que permite hacer una petición de forma GET para colcultar un PDF
   * @param {String} url URL a consultar
   * @param {String} state Opción del case
   * @param {Number} num Consecutivo del registro para el pdf
   */
  methodPDF_API = (url, state, num) => {
    let url_intern = url + `${num}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta_alerta":
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);
              document.getElementById("dowload_pdf_alta_notificacion").href =
                fileURL;
              document.getElementById("window_pdf_alta_notificacion").href =
                fileURL;
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma POST
   * @param {String} url URL a consultar
   * @param {String} state Onpción del case
   * @param {Object} data Objeto a mandar atraves del metodo POST
   */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta":
              this.setState(
                { identificativo: response.data.results.folio },
                () => {
                  this.methodPDF_API(
                    utility.url_pdf_alerta,
                    "alta_alerta",
                    response.data.results.folio
                  );
                  this.sendNotificacionHandler("crear");
                }
              );
              swal({
                icon: "success",
                title: response.data.title,
                text: response.data.text,
                button: true,
                content: (
                  <div>
                    <LinkD
                      href=""
                      id="dowload_pdf_alta_notificacion"
                      download={`Notificación de Embarque ${response.data.results.folio}`}
                    />
                    <br />
                    <LinkV
                      href=""
                      id="window_pdf_alta_notificacion"
                      target="_blank"
                    />
                  </div>
                ),
              });
              this.handleCerrar();
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "patentes":
              if (this._isMounted)
                this.setState({
                  patentes: response.data.results,
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  onChangeAduana = (e) => {
    let aduana = e.target.value;
    let aduana_select = [];
    for (let i = 0; i < this.state.patentes.length; i++)
      if (this.state.patentes[i].aduana === aduana)
        aduana_select.push(this.state.patentes[i]);
    this.setState({ aduana_select: aduana_select });
  };

  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") text_add = "";
    else if (numero_cliente.length <= 4) {
      switch (numero_cliente.length) {
        case 1:
          text_add = "000";
          text_add += numero_cliente;
          break;
        case 2:
          text_add = "00";
          text_add += numero_cliente;
          break;
        case 3:
          text_add = "0";
          text_add += numero_cliente;
          break;
        case 4:
          text_add = numero_cliente;
          break;
        default:
          return null;
      }
    } else text_add = "";
    for (let i = 0; i < this.props.clientes.length; i++) {
      if (text_add.length === 0) {
        document.getElementById("nombre_cliente_alerta_alta").value = "";
        this.suggestionSelected("");
        break;
      }
      if (text_add === this.props.clientes[i].numero) {
        document.getElementById("nombre_cliente_alerta_alta").value =
          this.props.clientes[i].nombre;
        this.suggestionSelected(this.props.clientes[i].nombre);
        break;
      }
    }
  };
  suggestionSelected = (value) => {
    let number = utility.get_number_cliente(value, this.props.clientes);
    if (number === undefined) number = "";
    document.getElementById("numero_cliente_alerta_alta").value = number;
    this.setState({
      nombre_select: value,
      numero_select: number,
      nombre_suggestions: [],
    });
  };
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_nombres_clientes(this.props.clientes)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      nombre_suggestions: suggestions,
      nombre_select: value,
    });
  };
  renderSuggestion = () => {
    if (this.state.nombre_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de consolidadora dependiendo su número ------ */
  nameGeneratorCon = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") text_add = "";
    else text_add += numero_cliente;
    for (let i = 0; i < this.props.consolidadoras.length; i++) {
      if (text_add.length === 0) {
        document.getElementById("nombre_consolidadora_alerta_alta").value = "";
        this.suggestionSelectedCon("");
        break;
      }
      if (text_add === this.props.consolidadoras[i].numero) {
        document.getElementById("nombre_consolidadora_alerta_alta").value =
          this.props.consolidadoras[i].nombre;
        this.suggestionSelectedCon(this.props.consolidadoras[i].nombre);
        break;
      }
    }
  };
  suggestionSelectedCon = (value) => {
    let number = utility.get_number_consolidaodras(
      value,
      this.props.consolidadoras
    );
    if (number === undefined) number = "";
    document.getElementById("numero_consolidadora_alerta_alta").value = number;
    this.setState({
      nombre_select_con: value,
      numero_select_con: number,
      nombre_suggestions_con: [],
    });
  };
  onNameChangeCon = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_nombres_consolidadoras(this.props.consolidadoras)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      nombre_suggestions_con: suggestions,
      nombre_select_con: value,
    });
  };
  renderSuggestionCon = () => {
    if (this.state.nombre_suggestions_con.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions_con.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelectedCon(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de consolidadora dependiendo su número ------ */
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_nuevo}
        toggle={this.props.toogleNuevo}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.props.toogleNuevo}>
          Alta Notificación
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                nameLabel="Número de Cliente"
                id="numero_cliente_alerta_alta"
                onChange={this.nameGenerator}
                value={this.state.numero_select}
                disabled={this.state.cliente_enable}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                nameLabel="Nombre de Cliente"
                id="nombre_cliente_alerta_alta"
                onChange={this.onNameChange}
                value={this.state.nombre_select}
                disabled={this.state.cliente_enable}
              />
              {this.renderSuggestion()}
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                nameLabel="Número de Consolidadora"
                id="numero_consolidadora_alerta_alta"
                onChange={this.nameGeneratorCon}
                value={this.state.numero_select_con}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                nameLabel="Nombre de Consolidadora"
                id="nombre_consolidadora_alerta_alta"
                onChange={this.onNameChangeCon}
                value={this.state.nombre_select_con}
              />
              {this.renderSuggestionCon()}
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                nameLabel="Aduana"
                id="aduana_alerta_alta"
                onChange={this.onChangeAduana}
              />
              <CustomSelect
                bootstrap="col-lg-3 col-12"
                name_text="patente"
                name_index="patente"
                id="patente_alerta_alta"
                nameLabel="Patente"
                value={this.state.patente_text}
                onChange={this.handlePatente}
                options={this.state.aduana_select}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                nameLabel="Importe Flete"
                id="importe_alerta_alta"
                value={this.state.importeAlta}
                onChange={(e) =>
                  this.setState({
                    importeAlta: this.handleImporteAlta(e.target.value),
                  })
                }
                onBlur={this.handleImporteAltaBlur}
              />

              <div className="col-6 col-lg-2 p-0 pb-1 d-flex justify-content-center align-items-center">
                <CustomInput
                  type="checkbox"
                  id="tipo_flete_alerta_alta"
                  label="Tipo Flete"
                />
              </div>
            </FormGroup>
            <FormGroup className="row mb-0">
              <CustomSelect
                bootstrap="col-lg-3 col-12"
                name_text="nombre"
                name_index="clave"
                id="tipo_alta_alerta_alta"
                disabled={this.state.cliente_enable}
                nameLabel="Tipo alta"
                value={this.state.tipo_alta_text}
                onChange={this.handleTipo_alta}
                options={this.state.tipo_alta}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                nameLabel="Quién notifica"
                id="notifica_alerta_alta"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                nameLabel="Guía principal"
                id="guia_alerta_alta"
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                nameLabel="Desconsolidación"
                id="desconsolidacion_alerta_alta"
                defaultValue="0.00"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                nameLabel="Número de Vuelo"
                id="numero_vuelo_alerta_alta"
                defaultValue="S/N"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                nameLabel="Empleado seguimiento"
                id="notifica_alerta_alta"
                value={localStorage.getItem("user_name")}
                disabled
              />
              <div className="col-6 col-lg-2 p-0 pb-1 d-flex justify-content-center align-items-center">
                <CustomInput
                  type="checkbox"
                  id="consiganada_alerta_alta"
                  label="Consiganda"
                />
              </div>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyTextArea
                bootstrap="col-12"
                id="observaciones_alerta_alta"
                nameLabel="Observaciones"
                value={this.state.observaciones}
                onChange={this.handleChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={this.handleAlta}>
            Aceptar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalAlta;
