/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente general de la aplicación anticipos. 
*/
import axios from "axios";
import React, { Component } from "react";
import SearchBar from "./SearchBar";
import TableSol from "./Table";
import * as permisos from "../../utils/permisos/permisos";
import * as message from "../../utils/messages/message";
import * as utility from "./utils/utility";

class Transacciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
        clientes: [],
      actualizar: 0,
      beneficiarios: [],
      phxcdc: [],
      bancos: [],
      egresos: [],
      permisos: permisos.obtener_permisos_by_app(
        "transacciones",
        localStorage.getItem("perm")
      ),
      on_cliente:
        localStorage.getItem("puesto") === "Cliente"
          ? localStorage.getItem("id_usr")
          : false,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.lista_beneficiarios, "beneficiario");
    this.methodGET_API(utility.url_phxcdc, "phxcdc");
    this.methodGET_API(utility.url_bancos, "bancos");
    this.methodGET_API(utility.url_egresos, "egresos");
    this.methodGET_API(utility.url_clientes, "clientes");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  update = (cambio) => {
    this.setState({ actualizar: cambio });
  };
  /**
   * Función que permite hacer una petición de forma GET.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   */
  methodGET_API = (url, state, data) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "beneficiario":
              if (this._isMounted)
                this.setState({ beneficiarios: response.data.results });
              break;
            case "phxcdc":
              if (this._isMounted)
                this.setState({ phxcdc: response.data.results });
              break;
            case "bancos":
              if (this._isMounted)
                this.setState({ bancos: response.data.results });
              break;
            case "egresos":
              if (this._isMounted)
                this.setState({ egresos: response.data.results });
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  render() {
    return (
      <div className="container-fluid">
        <SearchBar
          permisos={this.state.permisos}
          update={this.update}
          beneficiarios={this.state.beneficiarios}
          phxcdc={this.state.phxcdc}
          bancos={this.state.bancos}
          egresos={this.state.egresos}
          clientes={this.state.clientes}
          on_cliente={this.state.on_cliente}
        />
        <TableSol
          actualizar={this.state.actualizar}
          update={this.update}
          egresos={this.state.egresos}
          phxcdc={this.state.phxcdc}
          beneficiarios={this.state.beneficiarios}
          bancos={this.state.bancos}
          clientes={this.state.clientes}
          on_cliente={this.state.on_cliente}
        />
      </div>
    );
  }
}
export default Transacciones;
