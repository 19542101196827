/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente general de la aplicación empleados. 
*/
import React, { Component } from 'react';
import SearchBar from './SearchBar';
import TablePuestos from './Table';

class Conciliacion extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            actualizar: 0,
        };
    }
    componentDidMount() {
        this._isMounted = true;

    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    update = (cambio) => {
        this.setState({ actualizar: cambio });
    }
    render() {
        return (
            <div className="container-fluid">
                <SearchBar update={this.update}  />
                <TablePuestos update={this.update} actualizar={this.state.actualizar} />
            </div>
        );
    }
}
export default Conciliacion;