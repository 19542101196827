/* 
Autor: Alexis Yael Hernández Grimaldo
Descripción: El componenete genera un bloque correspondiente a la busqueda dinamica en la tabla para acceder a la información.
*/
import React, { Component } from 'react';
import { Form, FormGroup, Button, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import MyInput from '../../utils/custom/MyInput';
import * as utility from './utils/utility';

class BusquedaEspecifica extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            toogle_collapse: false,
        }
    }
    toogleCollapse = (e) => {
        this.setState(prevState => ({ toogle_collapse: !prevState.toogle_collapse }))
    }
    handleFiltrada = () => {
        let data = {
            id_deposito: document.getElementById('id_deposito_specific_deposito').value,
            cliente: document.getElementById('cliente_specific_deposito').value,
            banco: document.getElementById('banco_specific_deposito').value,
            documento: document.getElementById('documento_specific_deposito').value,
            importe: document.getElementById('importe_specific_deposito').value,
            tipo_deposito: document.getElementById('tipo_deposito_specific_deposito').value,
            observaciones: document.getElementById('observaciones_specific_deposito').value,
            fecha_alta_inicio: document.getElementById('fecha_alta_inicio_specific_deposito').value,
            fecha_alta_final: document.getElementById('fecha_alta_final_specific_deposito').value,
            fecha_notificacion_inicio: document.getElementById('fecha_notificacion_inicio_specific_deposito').value,
            fecha_notificacion_final: document.getElementById('fecha_notificacion_final_specific_deposito').value
        }
        utility.validacion_data_filtrada(data);
        this.props.onChangeFiltrada(data);
    }
    render() {
        return (
            <React.Fragment>
                <div className="row mb-2 mx-1">
                    <h3 className="col-md-11 col-9 text-md-left px-0 mb-0">Consulta Específica</h3>
                    <Button color="info" onClick={this.toogleCollapse} className="col-md-1 col-3">
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </div>
                <Collapse isOpen={this.state.toogle_collapse}>
                    <Form className="container-fluid">
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-3 col-12" id="id_deposito_specific_deposito" nameLabel="ID Deposito" />
                            <MyInput type="text" bootstrap="col-lg-3 col-12" id="cliente_specific_deposito" nameLabel="Cliente"
                                defaultValue={this.props.on_cliente !== false ? this.props.on_cliente : ""}
                                disabled={this.props.on_cliente !== false ? true : false} />
                            <MyInput type="text" bootstrap="col-lg-3 col-12" id="banco_specific_deposito" nameLabel="Banco" />
                            <MyInput type="text" bootstrap="col-lg-3 col-12" id="documento_specific_deposito" nameLabel="Documento" />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-3 col-12" id="importe_specific_deposito" nameLabel="Importe" />
                            <MyInput type="text" bootstrap="col-lg-3 col-12" id="tipo_deposito_specific_deposito" nameLabel="Tipo deposito" />
                            <MyInput type="text" bootstrap="col-lg-6 col-12" id="observaciones_specific_deposito" nameLabel="Observaciones" />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="date" bootstrap="col-lg-3 col-12" id="fecha_alta_inicio_specific_deposito" nameLabel="Fecha alta (Desde)"
                                required="false" />
                            <MyInput type="date" bootstrap="col-lg-3 col-12" id="fecha_alta_final_specific_deposito" nameLabel="Fecha alta (Hasta)"
                                required="false" />
                            <MyInput type="date" bootstrap="col-lg-3 col-12" id="fecha_notificacion_inicio_specific_deposito" nameLabel="Fecha notificación (Desde)"
                                required="false" />
                            <MyInput type="date" bootstrap="col-lg-3 col-12" id="fecha_notificacion_final_specific_deposito" nameLabel="Fecha notificación (Hasta)"
                                required="false" />
                        </FormGroup>
                    </Form>
                    <FormGroup className="row d-flex justify-content-center mb-0">
                        <Button color="danger" className="mb-1" onClick={this.props.onBackTable}>Regresar</Button>
                        <Button color="success ml-2 mb-1" onClick={this.handleFiltrada}>Consultar</Button>
                    </FormGroup>
                </Collapse>
            </React.Fragment>
        );
    }
}
export default BusquedaEspecifica;