/* 
Autor: Alexis Yael Hernández Grimaldo
Descripción: El componenete genera un bloque correspondiente a la busqueda dinamica en la tabla para acceder a la información.
*/
import React, { Component } from 'react';
import { Button, Collapse, Form, FormGroup } from 'reactstrap';
import '../../assets/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import MyInput from '../../utils/custom/MyInput';
import CustomSelect from '../../utils/custom/CustomSelect';

class BusquedaEspecifica extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toogle_collapse: false,
            tipo_patente_text: "Seleccione un tipo de patente",
            tipo_patente_id: "",

            tipos_patente: [{ clave: 0, nombre: "Propia" }, { clave: 1, nombre: "Socio" }, { clave: 2, nombre: "Corresponsal" }]
        }
    }
    toogleCollapse = (e) => {
        this.setState(prevState => ({ toogle_collapse: !prevState.toogle_collapse }))
    }
    handleValida = (data) => {
        if (data.patente.length === 0)
            delete data.patente;
        if (data.nombre.length === 0)
            delete data.nombre;
        if (data.rfc.length === 0)
            delete data.rfc;
        if (data.curp.length === 0)
            delete data.curp;
        if (data.aduana.length === 0)
            delete data.aduana;
        if (data.tpo_patente.length === 0)
            delete data.tpo_patente;
    }
    handleTipo_patente = (e) => {
        let label = e.name_text;
        let label_id = e.name_index;
        let tipo_patente_text = e[label];
        let tipo_patente_id = e[label_id];
        this.setState({ tipo_patente_text, tipo_patente_id });
    }
    handleFiltrada = () => {
        let data = {
            patente: document.getElementById('specific_patente').value,
            nombre: document.getElementById('specific_nombre').value,
            rfc: document.getElementById('specific_rfc').value,
            curp: document.getElementById('specific_curp').value,
            aduana: document.getElementById('specific_aduana').value,
            tpo_patente: this.state.tipo_patente_id,
        }
        this.handleValida(data);
        this.props.onChangeFiltrada(data);
    }
    render() {
        return (
            <React.Fragment>
                <div className="row mb-2 mx-1">
                    <h3 className="col-md-11 col-9 text-md-left px-0 mb-0">
                        Consulta Específica
                    </h3>
                    <Button
                        color="info"
                        onClick={this.toogleCollapse}
                        className="col-md-1 col-3">
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </div>
                <Collapse isOpen={this.state.toogle_collapse}>
                    <Form className="container-fluid">
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="specific_nombre" nameLabel="Nombre" />
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="specific_rfc" nameLabel="RFC" />
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="specific_curp" nameLabel="CURP" />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="specific_aduana" nameLabel="Aduana" />
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="specific_patente" nameLabel="Patente" />
                            <CustomSelect bootstrap="col-lg-4 col-12" name_text='nombre' name_index='clave' id="patente_alta_tipo_patente"
                                nameLabel="Tipo de Patente" value={this.state.tipo_patente_text} onChange={this.handleTipo_patente}
                                options={this.state.tipos_patente} />
                        </FormGroup>
                        <FormGroup className="row d-flex justify-content-center mb-0">
                            <Button color="danger" className="mb-1" onClick={this.props.onBackTable}>Regresar</Button>
                            <Button color="success ml-2 mb-1" onClick={this.handleFiltrada}>Consultar</Button>
                        </FormGroup>
                    </Form>
                </Collapse>
            </React.Fragment>

        );
    }
}
export default BusquedaEspecifica;