import { faEye, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import swal from "@sweetalert/with-react";
import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Badge,Table,
} from "reactstrap";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";
import MyDropzone from "../utils/MyDropzone";
import MyInput from "../utils/MyInput";
import SelectComponent from "../utils/CustomSelect";
import MySelect from "../utils/MySelect";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalModificaMov extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    console.warn(this.props);
    console.warn(props);
    this.state = {

      modificarRef: this.props.propRef,
      modificarAux: this.props.propAux,
      modificarDoc: this.props.propDoc,
      modificarAbono: this.props.propAbono,
      modificarArchivo: this.props.propArchivo,

      listaPhxcdc:[],

      archivoActual:[],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getListaPhxcdc();
    let archivos = this.state.archivoActual;

      let info = {
        archivo: this.state.modificarArchivo
      };
      archivos.push(info);
      this.setState({archivoActual: archivos});
    
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getListaPhxcdc(){
    const url = utility.url_cat_phxcdc;
    axios
      .get(url)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              listaPhxcdc: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }


  handleCerrar = () => {
    this.props.toogleModificacion();
  };

  handleCallback = (childData) => {
    this.setState({ modificarAux: childData });
  };

  handleMovimiento = (e) => {
    let auxiliar = e.target.value;
    this.setState({ auxiliar });
  };
  handleReferencia = (e) => {
    let modificarRef = e.target.value;
    this.setState({ modificarRef });
  };
  handleDocumento = (e) => {
    let modificarDoc = e.target.value;
    this.setState({ modificarDoc });
  };
  handleAbono = (e) => {
    let modificarAbono = e.target.value;
    this.setState({ modificarAbono });
  };
  handleArchivo = (e) => {
    let modificarArchivo = e.target.value;
    this.setState({ modificarArchivo });
  };

  handleArchivo = (file) => {
    let archivo = file;
    if (archivo.type === "text/xml") {
      let arch = new FileReader();
      arch.onload = (event) => {
        this.setState({ modificarArchivo: archivo });
        this.handleXMLS(event.target.result);
      };
      arch.readAsText(archivo);
    } else if (archivo.type === "application/pdf")
      this.setState({ modificarArchivo: archivo });
    else message.message_modal("Error", "El archivo no es compatible", 500);
  };

  handleGuardar = () => {
   
    if (
      this.state.modificarAux === this.props.propAux &&
      this.state.modificarRef === this.props.propRef &&
      this.state.modificarDoc === this.props.propDoc &&
      this.state.modificarAbono === this.props.propAbono &&
      this.state.modificarArchivo === this.props.propArchivo 
    ) {
      message.warningMessage("No existió alguna modificación");
    } else {
      message
        .message_confirmacion_password(
          "¿Desea guardar las modificaciones?",
          "Ingrese su contraseña y observaciones"
        )
        .then((res) => {
          if (res === true) {
            let mov = {
              user: document.getElementById("usuario_modificador").value,
              pass: document.getElementById("contraseña_modificador").value,
              motivo: document.getElementById("observaciones_textarea").value,
            };
            if (mov.motivo.length === 0)
              swal("Completar el campo:", "Motivo de Notificación", "warning");
            else
              axios
                .post(utility.url_verify, {
                  usuario: mov.user,
                  password: mov.pass,
                })
                .then((response) => {
                  if (response.status >= 200 && response.status < 400) {
                    if (this._isMounted) {
                      
                      let modificados = {};

                      if (this.state.modificarAux != this.props.propAux)
                        modificados.movimiento = this.state.modificarAux;
                      
                      if (this.state.modificarRef != this.props.propRef)
                        modificados.referencia = this.state.modificarRef;
                     
                      if (this.state.modificarDoc != this.props.propDoc)
                        modificados.documento = this.state.modificarDoc;

                      if (this.state.modificarAbono != this.props.propAbono)
                        modificados.abono = this.state.modificarAbono;

                      if (this.state.modificarArchivo != this.props.propArchivo)
                        modificados.archivo = this.state.modificarArchivo;

                      axios
                        .post(utility, {
                          movimientos: modificados,
                          observaciones: mov.motivo,
                        })
                        .then((response) => {
                          if (response.status >= 200 && response.status < 400) {
                            if (this._isMounted) {
                              swal({
                                icon: "success",
                                title: response.data.title,
                                text: response.data.text,
                                button: true,
                              });
                            }
                            this.props.update(1);
                          }
                        })
                        .catch((error) => {
                          if (error.response)
                            message.message_modal(
                              error.response.data.title,
                              error.response.data.text,
                              error.response.status
                            );
                        });
                    }
                  }
                })
                .catch((error) => {
                  if (error.response)
                    message.message_modal(
                      error.response.data.title,
                      error.response.data.text,
                      error.response.status
                    );
                });
          }
        });
    }
  };

  handleEliminaArchivo = (archivo) => {
    const toFind = archivo;
    const archivos = this.state.archivoActual.filter(
      (archivos) => archivos.archivo !== toFind
    );
    this.setState({
      archivoActual: archivos,
    });
  };

  render() {
    const referencia = this.state.modificarRef;
    const auxiliar = this.state.modificarAux;
    const documento = this.state.modificarDoc;
    const abono = this.state.modificarAbono;
    const archivo = this.state.modificarArchivo;

    return (
      <Modal
        isOpen={this.props.toogle_modificacion}
        toggle={this.handleCerrar}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Modificación Movimientos
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
            <MySelect
                bootstrap="col-lg-6 col-12"
                nameLabel="Movimiento"
                first_option={auxiliar}
                id="movimientos_phxcdc"
                required
                value={auxiliar}
                onChange={this.handleMovimiento}
              >
                {this.state.listaPhxcdc.map((mov) => (
                  <option key={mov.clave}>
                    {mov.nombre}
                  </option>
                ))}
              </MySelect>
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="referencia"
                nameLabel="Referencia"
                value={referencia}
                onChange={this.handleReferencia}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="documento"
                nameLabel="Documento"
                value={documento}
                onChange={this.handleDocumento}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="abono"
                nameLabel="Abono"
                value={abono}
                onChange={this.handleAbono}
              />
            </FormGroup>
            <Table size="sm" bordered hover responsive>
            <thead className="text-center header-table">
              <tr>
                <td>Archivo</td>
                <td></td>
              </tr>
            </thead>
            <tbody className="body-table">
            {this.state.archivoActual.map((data) => (
                <tr>
                   <td className="text-center">{data.archivo ? data.archivo.split("/")[6] : null}</td>
                   <td className="text-center">
                    <Button
                      size="sm"
                      color="danger"
                      value={data.archivo}
                      onClick={() => this.handleEliminaArchivo(data.archivo)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="success"
            className="mr-2"
            onClick={this.handleGuardar}
          >
            Guardar
          </Button>
          <Button type="button" color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalModificaMov;
