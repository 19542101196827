/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente Principal para el Modal de las distintas opciones de operación para solicitud de anticipos. 
*/
import React, { Component } from "react";
import { ButtonGroup, Button } from "reactstrap";
import ModalConsulta from "./ModalConsulta";
import ModalAlta from "./ModalAlta";
import ModalReporte from "./ModalReporte";
import ModalCatalogo from "./ModalCatalogo";
import * as permisos from "../../../utils/permisos/permisos";

class ModalAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toogle_consulta: false,
      toogle_nuevo: false,
      toogle_reporte: false,
      toogle_catalogo: false,
    };
    this.toogleConsulta = this.toogleConsulta.bind(this);
    this.toogleNuevo = this.toogleNuevo.bind(this);
    this.toogleReporte = this.toogleReporte.bind(this);
    this.toogleCatalogo = this.toogleCatalogo.bind(this);
  }
  toogleConsulta = () => {
    this.setState((prevState) => ({
      toogle_consulta: !prevState.toogle_consulta,
    }));
  };
  toogleNuevo = () => {
    this.setState((prevState) => ({ toogle_nuevo: !prevState.toogle_nuevo }));
  };
  toogleReporte = () => {
    this.setState((prevState) => ({
      toogle_reporte: !prevState.toogle_reporte,
    }));
  };
  toogleCatalogo = () => {
    this.setState((prevState) => ({
      toogle_catalogo: !prevState.toogle_catalogo,
    }));
  };

  render() {
    return (
      <div className="col-lg-3 col-sm-12 d-flex justify-content-center flex-column">
        <ButtonGroup className="mb-1">
          <Button
            color="primary"
            onClick={this.toogleNuevo}
            disabled={permisos.obtener_permiso_especifico(
              "crear",
              this.props.permisos
            )}
          >
            Nuevo
          </Button>
          <Button color="primary" onClick={this.toogleConsulta}>
            Consulta
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button color="success" onClick={this.toogleReporte}>
            Reportes
          </Button>
          <Button
            color="success"
            onClick={this.toogleCatalogo}
            disabled={permisos.obtener_permiso_especifico(
                "modificar",
                this.props.permisos
              ) && permisos.obtener_permiso_especifico(
                "crear",
                this.props.permisos
              )&& permisos.obtener_permiso_especifico(
                "cancelar",
                this.props.permisos
              )}
          >
            Catálogo
          </Button>
        </ButtonGroup>

        <ModalAlta
          toogle_nuevo={this.state.toogle_nuevo}
          toogleNuevo={this.toogleNuevo}
          clientes={this.props.clientes}
          bancos={this.props.bancos}
          update={this.props.update}
          on_cliente={this.props.on_cliente}
          on_nivel={this.props.on_nivel}
          permisos={this.props.permisos}
        />
        <ModalConsulta
          toogle_consulta={this.state.toogle_consulta}
          toogleConsulta={this.toogleConsulta}
          clientes={this.props.clientes}
          bancos={this.props.bancos}
          update={this.props.update}
          on_cliente={this.props.on_cliente}
          permisos={this.props.permisos}
        />
        <ModalReporte
          toogle_reporte={this.state.toogle_reporte}
          toogleReporte={this.toogleReporte}
          clientes={this.props.clientes}
          bancos={this.props.bancos}
          update={this.props.update}
          on_cliente={this.props.on_cliente}
          permisos={this.props.permisos}
        />
        <ModalCatalogo
          toogle_catalogo={this.state.toogle_catalogo}
          toogleCatalogo={this.toogleCatalogo}
          update={this.props.update}
        />
      </div>
    );
  }
}
export default ModalAction;
