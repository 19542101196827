/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente general de la aplicación anticipos. 
*/
import React, { Component } from 'react';
import SearchBar from './SearchBar';
import TableSol from './Table';
import * as permisos from '../../utils/permisos/permisos';

class Patentes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actualizar: 0,
            permisos: permisos.obtener_permisos_by_app('catagentesaduanales', localStorage.getItem('perm')),
        };
    }
    update = (cambio) => {
        this.setState({ actualizar: cambio });
    }
    render() {
        return (
            <div className="container-fluid">
                <SearchBar permisos={this.state.permisos} update={this.update} />
                <TableSol actualizar={this.state.actualizar} update={this.update} />
            </div>
        );
    }
}
export default Patentes;