export const url_pagination_cta_gastos = '/DjangoWeb/CuentasGastoApp/consulta/cuentas_gastos/paginacion';
export const url_crea_cta_gastos = '/DjangoWeb/CuentasGastoApp/crea_modifica/cuentas_gastos';
export const url_crea_cta_pagos = '/DjangoWeb/CuentasGastoApp/crea_modifica/cuentas_gastos_pagos';
export const url_crea_factura = '/DjangoWeb/CuentasGastoApp/crea_modifica/factura';
export const url_consulta_factura = '/DjangoWeb/CuentasGastoApp/consulta/factura';
export const url_consulta_cta_gastos = '/DjangoWeb/CuentasGastoApp/consulta/cuentas_gastos';
export const url_consulta_phxcdc_poliza = '/DjangoWeb/CuentasGastoApp/consulta/phxcdc_referencia';
export const url_consulta_pagos_lista = '/DjangoWeb/OperacionesApp/consulta/operacion/pagos/lista';
export const url_valor_aduanal = '/DjangoWeb/CuentasGastoApp/consulta/pedimentos_referencia';
export const url_consulta_movimientos_ingresos = '/DjangoWeb/CuentasGastoApp/consulta/cliente_documentos';

export const url_consulta_phxcdc_lista = '/DjangoWeb/BaseApp/consulta/cat_phxcdc/lista';

export const url_consulta_clientes_lista = '/DjangoWeb/BaseApp/consulta/clientes/lista';
export const url_consulta_ingresos_lsita = '/DjangoWeb/BaseApp/consulta/cat_ingresos/lista';
export const url_consulta_documentos_grupo = '/DjangoWeb/BaseApp/consulta/clientes/documentos';
export const url_consulta_formas_pago = '/DjangoWeb/BaseApp/consulta/formas_pagos';
export const url_consulta_tipos_usos = '/DjangoWeb/BaseApp/consulta/tipos_usos';
export const url_consulta_phxcdc_catalogo = '/DjangoWeb/BaseApp/consulta/cat_phxcdc/lista';

export const url_consulta_anticipo = '/DjangoWeb/AnticiposApp/consulta/anticipo';

export const url_consulta_pdf_gta_gastos = '/DjangoWeb/ExpedienteApp/descargar/archivo_pdf/cuenta_gastos/';
export const url_consulta_pdf_factura = '/DjangoWeb/ExpedienteApp/descargar/archivo_pdf/facturacion/';

export const url_phxcc = '/DjangoWeb/BaseApp/consulta/cat_phxcdc/lista';

/**
 * Función que nos permite filtar la tabla por el input general que serra de tipo text.
 */
export const general_search = (e) => {
    e.preventDefault();
    let tr = document.getElementById('tabla_cta_gastos').getElementsByTagName('tr');
    let data_input = document.getElementById('busqueda_general_cta_gastos').value.toUpperCase();
    for (let i = 0; i < tr.length; i++) {
        let mostrar = false;
        let data = tr[i].getElementsByTagName('p');
        for (let j = 0; j < data.length; j++) {
            if (data_input === "") {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
            else if (data[j].textContent.toUpperCase().includes(data_input)) {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "rgba(0,123,255,1)";
                data[j].style.color = "#fff";
            }
            else {
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que nos permite filtrar latabla solamnete por la fecha.
 */
export const date_search = (e) => {
    e.preventDefault();
    var tabla_anticipo, input_general, tr, dato, input_dato, mostrar;
    tabla_anticipo = document.getElementById("tabla_cta_gastos");
    input_general = document.getElementById("busqueda_date_cta_gastos");
    input_dato = input_general.value;
    tr = tabla_anticipo.getElementsByTagName("tr");
    for (var i = 0; i < tr.length; i++) {
        mostrar = false;
        dato = tr[i].getElementsByTagName("p");
        for (var j = 0; j < dato.length; j++) {
            if (dato[j].textContent === input_dato) {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "rgba(0,123,255,1)";
                dato[j].style.color = "#fff";
            }
            else if (input_dato === "") {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
            else {
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que valida los datos vacios para la petición POST
 * @param {Object} data Objeto que contiene los datos para POST 
 * @return {String} Mensaje de los campos vacios.
 */
export const validadCamposPOST = (data) => {
    let message = "";
    if (data.nombre.length === 0)
        message += "Nombre del Ingreso, ";
    if (data.corto.length === 0)
        message += "Nombre corto, ";

    message = message.slice(0, message.length - 2);
    return message;
}

/**
 * Función que permite hacer la suma de todos los pagos a terceros de una arreglo
 * @param {Array} arreglo Arreglo de pagos a terceros 
 * @returns {Number} La suma de los pagos a terceros.
 */
export const suma_pagos = (arreglo) => {
    let total = 0;
    for (let i = 0; i < arreglo.length; i++)
        total = parseFloat(arreglo[i].importe) + total;
    return total;
}

export const suma_pagos_documento = (arreglo) => {
    let total = 0;
    for (let i = 0; i < arreglo.length; i++)
        if (arreglo[i].status === 'P')
            total = parseFloat(arreglo[i].importe) + total;
    return total;
}
/**
 * Finción que permite el retorno del número de cliente por su nombre.
 * @param {Sring} nombre Nombre del cliente. 
 * @param {Array} array Arreglo de clientes.
 * @returns {String} Número del cliente.
 */
export const get_number_cliente = (nombre, array) => {
    let only_number = "";
    let bandera = 0;
    for (let i = 0; i < array.length; i++)
        if (nombre === array[i].nombre) {
            for (let j = 0; j < 4; j++) {
                if (array[i].numero[j] !== '0')
                    bandera = 1;
                if (bandera === 1)
                    only_number += array[i].numero[j];
            }
            return (only_number);
        }
}

/**
 * Función que permite retornar el nombre de un cliente ne especifico.
 * @param {String} numero Número de cliente.
 * @param {Array} array Arreglo de clientes.
 * @returns {String} Nombre del cliente.
 */
export const get_name_cliente = (numero, array) => {
    let complete_number = "";
    switch (numero.length) {
        case 1: complete_number = '000' + numero; break;
        case 2: complete_number = '00' + numero; break;
        case 3: complete_number = '0' + numero; break;
        case 4: complete_number = numero; break;
        default: break;
    }
    for (let i = 0; i < array.length; i++)
        if (complete_number === array[i].numero)
            return (array[i].nombre);
}
/**
 * Función que obtiene los nombres de todos los clientes
 * @param {array} arreglo 
 * @returns {array} Arreglo de nombres.
 */
export const get_nombres_clientes = (arreglo) => {
    var aux = [];
    for (var i = 0; i < arreglo.length; i++) {
        aux.push(arreglo[i].nombre);
    }
    return (aux);
}
/**
 * 
 * @param {number} ref Número de referencia.
 * @param {array} arreglo Arreglo de referencias.
 */
export const repeticion_referencia = (ref, arreglo) => {
    let contador = 0;
    for (let i = 0; i < arreglo.length; i++)
        if (ref === arreglo[i].referencia)
            contador++;
    return contador;
}

export const valida_referencia_repeticion = (referencia, array) => {
    for (let i = 0; i < array.length; i++)
        if (referencia === array[i].referencia)
            return false;
    return true;
}

export const valida_referencia_repeticion_select = (referencia, array) => {
    for (let i = 0; i < array.length; i++)
        if (referencia === array[i].id)
            return false;
    return true;
}
/**
 * 
 * @param {number} ref Número de referencia.
 * @param {array} arreglo Arreglo de referencias.
 */
export const repeticion_poliza = (poliza, arreglo) => {
    let contador = 0;
    for (let i = 0; i < arreglo.length; i++) {
        if (poliza === arreglo[i].poliza)
            contador++;
    }
    return contador;
}
/**
 * Función que permite sacar un arreglo de los phxcdc por Referencia
 * @param {String} referencia Referencia a filtar
 * @param {Array} arreglo Arreglo con todos los phxcdc 
 * @returns {Array} Arreglo filtrado 
 */
export const contruye_alta_phxcdc = (referencia, arreglo) => {
    let arreglo_pagos_referencia = [];
    for (let i = 0; i < arreglo.length; i++) {
        if (referencia === arreglo[i].referencia) {
                let data = {
                    importe: arreglo[i].cantidad,
                    tipo_phxcdc: parseInt(arreglo[i].tpo_pago),
                    observaciones: ""
                };
                arreglo_pagos_referencia.push(data);
        }
    }
    return arreglo_pagos_referencia;
}

/**
 * Función que permite sacar un arreglo de los ingresos por Referencia
 * @param {String} referencia Referencia a filtar
 * @param {Array} arreglo Arreglo con todos los ingresos 
 * @returns {Array} Arreglo filtrado 
 */
export const construye_alta_ingresos = (referencia, arreglo) => {
    let arreglo_ingresos_referencia = [];
    for (let i = 0; i < arreglo.length; i++)
        if (referencia === arreglo[i].referencia) {
            let data = {
                importe: arreglo[i].importe,
                tipo_ingreso: arreglo[i].tipo_ingreso,
                observaciones: arreglo[i].observaciones
            };
            arreglo_ingresos_referencia.push(data);
        }
    return arreglo_ingresos_referencia;
}

export const construye_ingresos_eliminar = (ingresos, referencias) => {
    for (let i = 0; i < referencias.length; i++) {
        for (let j = 0; j < ingresos.length; j++)
            if (referencias[i].referencia === ingresos[j].referencia)
                referencias[i].ingresos['eliminados'] = [...referencias[i].ingresos['eliminados'], ingresos[j].id];
    }
}

/**
 * Función que permite eliminar los phxcdc e ingresos si no existen.
 * @param {Array} referencias Arreglo con las referencias que se agregaran a la cuenta de gastos.
 */
export const valida_pagos_ingresos_referencia = (referencias) => {
    for (let i = 0; i < referencias.length; i++) {
        if (referencias[i].ingresos.agregados.length === 0)
            delete referencias[i].ingresos;
        if (referencias[i].phxcdc.agregados.length === 0)
            delete referencias[i].phxcdc;
    }
}

export const valida_data_modifica_pagos = (data) => {
    if (data.agregados.length === 0)
        delete data.agregados;
    if (data.eliminados.ingresos.length === 0)
        delete data.eliminados.ingresos;
    if (data.eliminados.phxcdc.length === 0)
        delete data.eliminados.phxcdc;
    if (Object.values(data.eliminados).length === 0)
        delete data.eliminados;
}
/**
 * Función que suma los importes de todos los movimientos de phxcdc
 * @param {Array} arreglo Arreglo de phxcdc 
 * @returns {Float} Suma total de pagos
 */
export const suma_importes_phxcdc = (arreglo) => {
    let suma_total = 0;
    for (let i = 0; i < arreglo.length; i++)
        arreglo[i].movimientos.forEach(mov => suma_total = suma_total + mov.cantidad);
    return suma_total;
}
/**
 * Función que suma los importes de todos los movimientos de ingresos
 * @param {Array} arreglo Arreglo de ingresos 
 * @returns {Float} Suma total de ingresos
 */
export const suma_importes_ingresos = (arreglo) => {
    let suma_total = 0;
    for (let i = 0; i < arreglo.length; i++)
        suma_total = suma_total + parseFloat(arreglo[i].importe);
    return suma_total;
}
/**
 * Función que suma los importes de todos los movimientos de ingresos, suma el IVA al 16%
 * y resta las retenciones de aquellos movimientos que tengan.
 * @param {Array} arreglo Arreglo de ingresos 
 * @returns {Float} Suma total de ingresos
 */
export const suma_importes_ingresos_total = (arreglo) => {
    let suma_total = 0;
    let retenciones = 0;
    for (let i = 0; i < arreglo.length; i++) {
        if (arreglo[i].retencion)
            retenciones = retenciones + (parseFloat(arreglo[i].importe) * (parseFloat(arreglo[i].retencion) / 100))
        suma_total = suma_total + parseFloat(arreglo[i].importe);
    }
    suma_total = suma_total - retenciones;
    suma_total = suma_total + (suma_total * (16 / 100));
    return suma_total;
}
/**
 * Función que permite obtener el nombre de un ingresos dado su id.
 * @param {Array} arreglo Arreglo de ingresos
 * @param {Array} clave Id del ingreso
 */
export const get_nombre_ingreso = (arreglo, clave) => {
    for (let i = 0; i < arreglo.length; i++)
        if (clave === arreglo[i].clave)
            return arreglo[i].nombre;
}

/**
 * Función que permite obtener el nombre de un phxcdc dado su id.
 * @param {Array} arreglo Arreglo de phxcdc
 * @param {Array} clave Id del phxcdc
 */
export const get_nombre_phxcdc = (arreglo, clave) => {
    for (let i = 0; i < arreglo.length; i++)
        if (clave === arreglo[i].clave)
            return arreglo[i].nombre;
}
/**
 * Función que permite organizar un arreglo para miostar los documentos por cliente y id en arreglo.
 * @param {Array} array Arreglo con documentos. 
 * @returns {Array} Arreglo con docuentos para select.
 */
export const organiza_documentos_cliente = (array) => {
    let arreglo = [];
    for (let i = 0; i < array.length; i++)
        arreglo.push({
            id: array[i].documento,
            text: `Doc: ${array[i].documento} - Cliente: ${array[i].cliente}`,
            importe: array[i].importe,
            saldo: array[i].saldo
        });
    return arreglo;
}
/**
 *  
 * @param {*} array 
 * @param {*} id 
 * @param {*} tipo_pago 
 * @returns 
 */
export const valida_movimiento_pago = (array, id, tipo_pago) => {
    for (let i = 0; i < array.length; i++)
        if (array[i].id_movimiento === id && array[i].tipo_movimiento === tipo_pago)
            return { valido: true, position: i };
    return { valido: false, position: "" };
}

export const cambia_importe = (array, id, importe) => {
    for (let i = 0; i < array.length; i++)
        if (array[i].id === id)
            array[i].importe = importe;
}

export const cambia_importe_suma = (array, id, importe) => {
    for (let i = 0; i < array.length; i++)
        if (array[i].id === id)
            array[i].importe += importe;
}

export const posicion_referencia = (array, id) => {
    for (let i = 0; i < array.length; i++)
        if (array[i].id === id)
            return i;
}

export const elimina_documento = (array, id) => {
    for (let i = 0; i < array.length; i++)
        if (array[i].id === id)
            array.splice(i, 1);
}

export const valida_documento_array = (array, id) => {
    for (let i = 0; i < array.length; i++)
        if (array[i].id === id)
            return { valido: true, info: array[i], pos: i };
    return { valido: false, info: null }
}

export const valida_data_alta_factura = (data) => {
    let message = "";
    if (data.cuenta.length === 0)
        message += "Cuenta, ";
    if (data.cliente_factura.length === 0)
        message += "Cliente Factura, ";
    if (data.tipo_comprobante.length === 0)
        message += "Tipo de Comprobante, ";
    if (data.condicion_pago.length === 0)
        delete data.condicion_pago;
    if (data.metodo_pago.length === 0)
        message += "Metodo de Pago, ";
    if (data.forma_pago.length === 0)
        message += "Forma de Pago, ";
    if (data.tipo_uso.length === 0)
        message += "Tipo de Uso, ";
    message = message.slice(0, message.length - 2);
    return message;
}

export const valid_data_modificacion_cta_gastos = (data) => {

}

/**
 * Funcion que permite obtener el texto con base aun identificador para ub objeto.
 * @param {Any} id El identifiacador del objeto
 * @param {String} identificador El nombre de la etiqueta para el identificador
 * @param {String} contendio El nombre de la etiqueta para el texto 
 * @param {Array} array Arreglo de objetos
 * @returns {Any} El texto
 */
export const get_text_by_id = (id, identificador, contendio, array) => {
    for (let i = 0; i < array.length; i++)
        if (array[i][identificador] === id)
            return array[i][contendio];
}