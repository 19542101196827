/* 
Autor: Alexis Yael Hernández Grimaldo
Descripción: El componenete genera un bloque correspondiente a la busqueda dinamica en la tabla para acceder a la información.
*/
import React, { Component } from "react";
import { Form, FormGroup, Input } from "reactstrap";
import "../../assets/style.css";
import * as utility from "./utils/utility";
import ModalAction from "./Modal/ModalAction";

class BusquedaTabla extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-lg-9 col-sm-12 my-2">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <FormGroup className="d-flex justify-content-center flex-column mb-0">
              <Input
                type="text"
                placeholder="Buscar"
                id="busqueda_general_consolidadoras"
                onKeyUp={utility.general_search}
              />
            </FormGroup>
          </Form>
        </div>
        <ModalAction
          bancos={this.props.bancos}
          update={this.props.update}
          permisos={this.props.permisos}
        />
      </div>
    );
  }
}
export default BusquedaTabla;
