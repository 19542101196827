/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la consulta de un anticipo. 
*/
import { faEye, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import * as message from "../messages/messages";
import MySelect from "../../../utils/custom/MySelect";
import * as utility from "../utils/utility";
import * as permisos from "../../../utils/permisos/permisos";
import MyInput from "../../../utils/custom/MyInput";
const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props} download>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalConsulta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      enable_pdf: false,
      numero_anticipo: "",
      fecha_alta: "",
      bancos: "Seleccione un banco",
      documento: "",
      importe: "",
      status: "",
      fecha_vencimiento: "",
      movimientos: [],
      modifica_ant: [true, true, true, true, true, true, true],
      modifica_change: [false, false, false, false, false, false, false, false],
      nombre_select: "",
      numero_select: "",
      nombre_suggestions: [],
      nameBanks: [],
      data_modificacion: {},
      file: "",
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleConsulta = () => {
    if (this.state.numero_anticipo.length === 0)
      message.message_modal("Advertencia", "Debe tener un folio", 400);
    else {
      let data = { numero: this.state.numero_anticipo };
      if (this.props.on_cliente !== false)
        data["cliente"] = this.props.on_cliente;
      this.methodPOST_API(utility.url_consulta_anticipo, "consulta", data);
    }
  };
  handleModifica = () => {
    if (this.state.numero_anticipo.length === 0)
      message.message_modal(
        "Advertencia",
        "Debe tener un número de anticipo",
        400
      );
    else {
      if (this.state.status === "A")
        message
          .message_confimacion_textarea(
            "¿Desea continuar con la operación modificar?",
            "Ingrese sus observaciones "
          )
          .then((res) => {
            if (res) {
              let observaciones = document.getElementById(
                "observaciones_textarea"
              ).value;
              let data_modificacion = this.state.data_modificacion;
              if (observaciones.length === 0)
                message.message_modal(
                  "Completar el campo:",
                  "Motivo de Modificación",
                  400
                );
              else if (Object.values(this.state.data_modificacion).length > 0) {
                data_modificacion["observaciones"] = observaciones;
                data_modificacion["anticipo"] = this.state.numero_anticipo;
                this.modificaPUT_API(
                  utility.url_alta_anticipo,
                  "modifica",
                  data_modificacion
                );
                /* this.sendNotificacionHandler("modificar"); */
              } else
                message.message_modal(
                  "Advertencia",
                  "No hay alguna modificación",
                  400
                );
            }
          });
      else if (this.state.status === "C")
        message
          .message_confirmacion_password(
            "¿Desea continuar con la operación modificar?",
            "Ingrese su contraseña y observaciones"
          )
          .then((res) => {
            if (res === true) {
              let mov = {
                user: document.getElementById("usuario_modificador").value,
                pass: document.getElementById("contraseña_modificador").value,
                motivo: document.getElementById("observaciones_textarea").value,
              };
              if (mov.motivo.length === 0)
                message.message_modal(
                  "Completar el campo:",
                  "Motivo de Modificación",
                  400
                );
              else
                axios
                  .post(utility.url_verify, {
                    usuario: mov.user,
                    password: mov.pass,
                  })
                  .then((response) => {
                    if (response.status >= 200 && response.status < 400) {
                      let data = this.state.data_modificacion;
                      if (
                        Object.values(this.state.data_modificacion).length > 0
                      ) {
                        data["anticipo"] = this.state.numero_anticipo;
                        data["observaciones"] = mov.motivo;
                        this.modificaPUT_API(
                          utility.url_modifica_anticipo,
                          "modifica",
                          data
                        );
                        /* this.sendNotificacionHandler("modificar"); */
                      } else
                        message.message_modal(
                          "Advertencia",
                          "No hay alguna modificación",
                          400
                        );
                    }
                  })
                  .catch((error) => {
                    if (error.response)
                      message.message_modal(
                        error.response.data.title,
                        error.response.data.text,
                        error.response.status
                      );
                  });
            }
          });
      else
        message.message_modal("Advertencia", "No hay alguna modificación", 400);
    }
  };
  handleNumeroAnticipo = (e) => {
    let numero_anticipo = e.target.value;
    this.setState({
      numero_anticipo,
    });
  };

  handleBancos = (e) => {
    const selectedValue = e.target.value;
    const [numero] = selectedValue.split(" - ");
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["banco"] = numero;
    this.setState({
      bancos: numero,
      data_modificacion,
    });
  };
  handleImporte = (e) => {
    let importe = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["importe"] = importe;
    this.setState({
      importe,
      data_modificacion,
    });
  };
  handleDocumento = (e) => {
    let documento = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["documento"] = documento;
    this.setState({
      documento,
      data_modificacion,
    });
  };
  handleFecha_alta = (e) => {
    let fecha = e.target.value;
    let vencimiento = new Date(fecha);
    let data_modificacion = this.state.data_modificacion;
    let dias = parseInt(
      document.getElementById("vigencia_anticipo_consulta").value
    );
    vencimiento.setDate(vencimiento.getDate() + dias);
    data_modificacion["fecha"] = fecha;
    data_modificacion["fecha_vencimiento"] = vencimiento
      .toISOString()
      .slice(0, 10);
    this.setState({
      fecha_vencimiento: vencimiento.toISOString().slice(0, 10),
      fecha_alta: fecha,
      data_modificacion,
    });
  };
  onFechaVencimiento = (e) => {
    let dias = parseInt(e.target.value);
    let data_modificacion = this.state.data_modificacion;
    let vencimiento = new Date(
      document.getElementById("fecha_alta_anticipo_consulta").value
    );
    vencimiento.setDate(vencimiento.getDate() + dias);
    data_modificacion["fecha_vencimiento"] = vencimiento
      .toISOString()
      .slice(0, 10);
    this.setState({
      fecha_vencimiento: vencimiento.toISOString().slice(0, 10),
      data_modificacion,
    });
  };
  handleErase = () => {
    this.setState({
      enable_pdf: false,
      numero_anticipo: "",
      fecha_alta: "",
      bancos: "Seleccione un banco",
      documento: "",
      importe: "",
      status: "",
      fecha_vencimiento: "",
      movimientos: [],
      modifica_ant: [true, true, true, true, true, true, true],
      modifica_change: [false, false, false, false, false, false, false, false],
      nombre_select: "",
      numero_select: "",
      nombre_suggestions: [],
      nameBanks: [],
      data_modificacion: {},
    });
    this.props.toogleConsulta();
  };
  /**
   * Función que permite hacer una petición de forma Get para colsultar un PDF.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} num Número del documento.
   */
  methodPDF_API = (url, state, num) => {
    let url_intern = url + `${num}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta_anticipo":
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);

              /* document.getElementById("dowload_pdf_consulta_anticipo").href =
                fileURL; */
              document.getElementById("window_pdf_consulta_anticipo").href =
                fileURL;

              const linkD = document.getElementById(
                "dowload_pdf_consulta_anticipo"
              );
              linkD.href = fileURL;
              linkD.download = `Anticipo${this.state.numero_anticipo}`;

              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta":
              if (
                response.data.results.status === "A" ||
                response.data.results.status === "C"
              ) {
                this.setState({ enable_pdf: true }, () => {
                  this.methodPDF_API(
                    utility.url_consulta_pdf,
                    "consulta_anticipo",
                    response.data.results.numero + "/0"
                  );
                });
              }
              if (response.data.results.status === "D") {
                this.setState({ enable_pdf: true }, () => {
                  this.methodPDF_API(
                    utility.url_consulta_pdf,
                    "consulta_anticipo",
                    response.data.results.numero + "/1"
                  );
                });
              }
              this.setState({
                fecha_alta: response.data.results.fecha,
                numero_select: response.data.results.cliente,
                nombre_select: utility.get_name_cliente(
                  response.data.results.cliente,
                  this.props.clientes
                ),
                bancos: this.bancoCompleto(response.data.results.banco),
                documento: response.data.results.documento,
                importe: response.data.results.importe,
                movimientos: response.data.results.movimientos,
                status: response.data.results.status,
              });
              let options = document.getElementById(
                "bancos_anticipo_consulta"
              ).options;
              let options_2 = document.getElementById(
                "vigencia_anticipo_consulta"
              ).options;
              for (let i = 0; i < options.length; i++)
                if (options[i].value === this.state.bancos)
                  document.getElementById("bancos_anticipo_consulta").value =
                    options[i].value;
              for (let i = 0; i < options_2.length; i++)
                if (
                  parseInt(options_2[i].value) ===
                  Math.ceil(
                    (new Date(this.state.fecha_vencimiento) -
                      new Date(this.state.fecha_alta)) /
                      (60 * 60 * 24 * 1000)
                  )
                )
                  document.getElementById("vigencia_anticipo_consulta").value =
                    options_2[i].value;
              if (
                (response.data.results.status === "A" ||
                  response.data.results.status === "C") &&
                response.data.results.movimientos.length === 0
              ) {
                this.setState({
                  modifica_ant: [
                    false,
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    true,
                  ],
                });
                utility.add_class_disabled("documento_anticipo_consulta");
                /* utility.add_class_disabled(
                  "fecha_vencimiento_anticipo_consulta"
                ); */
                /*  utility.add_class_disabled("fecha_alta_anticipo_consulta");
                utility.add_class_disabled("vigencia_anticipo_consulta"); */
              } else {
                this.setState({
                  modifica_ant: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                  ],
                });
                utility.add_class_disabled("numero_cliente_anticipo_consulta");
                utility.add_class_disabled("nombre_cliente_anticipo_consulta");
                utility.add_class_disabled("bancos_anticipo_consulta");
                utility.add_class_disabled("documento_anticipo_consulta");
                utility.add_class_disabled("importe_anticipo_consulta");
                utility.add_class_disabled("vigencia_anticipo_consulta");
                utility.add_class_disabled("fecha_alta_anticipo_consulta");
                utility.add_class_disabled(
                  "fecha_vencimiento_anticipo_consulta"
                );
              }
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  modificaPUT_API = (url, state, data) => {
    let url_intern = url;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "modifica":
              this.handleErase();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") text_add = "";
    else if (numero_cliente.length <= 4) {
      switch (numero_cliente.length) {
        case 1:
          text_add = "000";
          text_add += numero_cliente;
          break;
        case 2:
          text_add = "00";
          text_add += numero_cliente;
          break;
        case 3:
          text_add = "0";
          text_add += numero_cliente;
          break;
        case 4:
          text_add = numero_cliente;
          break;
        default:
          return null;
      }
    } else text_add = "";
    for (let i = 0; i < this.props.clientes.length; i++) {
      console.log(text_add);
      if (text_add.length === 0) {
        document.getElementById("nombre_cliente_anticipo_consulta").value = "";
        this.suggestionSelected("");
        break;
      }
      if (text_add === this.props.clientes[i].numero) {
        document.getElementById("nombre_cliente_anticipo_consulta").value =
          this.props.clientes[i].nombre;
        this.suggestionSelected(this.props.clientes[i].nombre);
        break;
      }
    }
  };
  suggestionSelected = (value) => {
    let number = utility.get_number_cliente(value, this.props.clientes);
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["cliente"] = number;
    if (number === undefined) number = "";
    document.getElementById("numero_cliente_anticipo_consulta").value = number;
    this.setState({
      nombre_select: value,
      numero_select: number,
      nombre_suggestions: [],
      data_modificacion,
    });
  };
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_nombres_clientes(this.props.clientes)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      nombre_suggestions: suggestions,
      nombre_select: value,
    });
  };
  renderSuggestion = () => {
    if (this.state.nombre_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  getBankInformation() {
    const url = utility.url_bancos;
    axios
      .get(url)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              nameBanks: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  bancoCompleto = (banco) => {
    const selectedValue = banco;
    const [numero] = selectedValue.split(" - ");

    axios
      .post(utility.url_info_bancos, {
        numero,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted) {
            const nombre = response.data.results.nombre;
            let nombreCompleto = banco + " - " + nombre;
            this.setState({ bancos: nombreCompleto });
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  renderBancos = () => {
    if (this.state.nameBanks.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nameBanks.map((data) => (
          <ListGroupItem key={data} action>
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.handleErase}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleErase}>Consulta Anticipo</ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-10 col-8"
                id="numero_anticipo_consulta"
                required
                value={this.state.numero_anticipo}
                onChange={this.handleNumeroAnticipo}
                nameLabel="Número de Anticipo"
              />
              <Button
                color="primary"
                className="col-lg-2 col-4 mb-2"
                onClick={this.handleConsulta}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="numero_cliente_anticipo_consulta"
                nameLabel="Número de Cliente"
                onChange={this.nameGenerator}
                value={this.state.numero_select}
                disabled={
                  this.state.status === "A"
                    ? false
                    : true && this.state.status === "C"
                    ? false
                    : true
                }
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="nombre_cliente_anticipo_consulta"
                nameLabel="Nombre de Cliente"
                onChange={this.onNameChange}
                value={this.state.nombre_select}
                disabled={
                  this.state.status === "A"
                    ? false
                    : true && this.state.status === "C"
                    ? false
                    : true
                }
              />
              {this.renderSuggestion()}
            </FormGroup>
            <FormGroup className="row mb-0">
              <MySelect
                type="text"
                bootstrap="col-lg-6"
                nameLabel="Banco"
                id="bancos_anticipo_consulta"
                first_option={this.state.bancos}
                value={this.state.bancos}
                onChange={this.handleBancos}
                disabled={
                  this.state.status === "A"
                    ? false
                    : true && this.state.status === "C"
                    ? false
                    : true
                }
              >
                {this.props.bancos.map((ban) => (
                  <option key={ban.numero}>
                    {ban.numero} - {ban.nombre}
                  </option>
                ))}
              </MySelect>
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                nameLabel="Documento"
                id="documento_anticipo_consulta"
                onChange={this.handleDocumento}
                value={this.state.documento}
                disabled={
                  this.state.status === "A"
                    ? true
                    : true && this.state.movimientos.length === 0
                    ? true
                    : true
                }
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                nameLabel="Importe"
                id="importe_anticipo_consulta"
                onChange={this.handleImporte}
                value={this.state.importe}
                disabled={
                  this.state.status === "A"
                    ? false
                    : true && this.state.status === "C"
                    ? false
                    : true
                }
              />
              <MyInput
                type="date"
                bootstrap="col-lg-6"
                nameLabel="Fecha de Alta"
                id="fecha_alta_anticipo_consulta"
                max={this.state.fecha_alta}
                value={this.state.fecha_alta}
                disabled={
                  this.state.status === "A"
                    ? false
                    : true && this.state.movimientos.length === 0
                    ? true
                    : true
                }
                onChange={this.handleFecha_alta}
              />
            </FormGroup>
            {/* <FormGroup className="row mb-0">
                        
                        <MyInput type="date" bootstrap="col-lg-6" nameLabel="Fecha de Vencimiento" id="fecha_vencimiento_anticipo_consulta"
                                value={this.state.fecha_vencimiento} disabled={this.state.modifica_ant[7]}
                             />
                    </FormGroup> */}
          </Form>
          <Table size="sm" bordered hover responsive>
            <thead className="text-center header-table">
              <tr>
                <td>Referencia</td>
                <td>Tipo de Movimiento</td>
                <td>Documento</td>
                <td>Abono</td>
              </tr>
            </thead>
            <tbody className="body-table">
              {this.state.movimientos.map((mov) => (
                <tr>
                  <td className="text-center">{mov.referencia}</td>
                  <td className="text-center">{mov.tipo}</td>
                  <td className="text-center">{mov.documento}</td>
                  <td className="text-center">-$ {mov.abono}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="d-flex justify-content-center">
            <LinkD href="" id="dowload_pdf_consulta_anticipo">
              <FontAwesomeIcon icon={faFileDownload} /> Descargar
            </LinkD>

            <LinkV
              href="#"
              id="window_pdf_consulta_anticipo"
              target={this.state.enable_pdf ? "_blank" : "_self"}
              className="ml-5"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={this.handleModifica}
            disabled={
              !(!permisos.obtener_permiso_especifico(
                "modificar",
                this.props.permisos
              ) &&
              /* this.state.status !== "D" && */
              this.state.movimientos.length === 0
                ? true
                : false)
            }
          >
            Modificar
          </Button>
          <Button color="primary" onClick={this.handleErase}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalConsulta;
