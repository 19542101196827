/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente general de la aplicación anticipos. 
*/
import axios from "axios";
import React, { Component } from "react";
import * as message from "../../utils/messages/message";
import * as permisos from "../../utils/permisos/permisos";
import SearchBar from "./SearchBar";
import TableSol from "./Table";
import * as utility from "./utility/utility";

class Clientes extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      actualizar: 0,
      grupos: [],
      paises: [],
      patentes: [],
      bancos: [],
      regimen_fiscal: [],
      permisos: permisos.obtener_permisos_by_app(
        "clientes",
        localStorage.getItem("perm")
      ),
      codigos_sat: [],
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_grupos_clientes, "grupos_clientes");
    this.methodGET_API(utility.url_paises_lista, "paises");
    this.methodGET_API(utility.url_patentes, "patentes");
    this.methodGET_API(utility.url_bancos, "bancos");
    this.methodGET_API(utility.url_consulta_regimen_fiscal, "regimen_fiscal");
    this.methodGET_API(utility.url_codigos_sat, "codigo_sat");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  update = (cambio) => {
    this.setState({ actualizar: cambio });
  };

  /**
   * Función que permite hacer una petición de forma GET.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   */
  methodGET_API = (url, state, data) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "grupos_clientes":
              if (this._isMounted)
                this.setState({ grupos: response.data.results });
              break;
            case "paises":
              if (this._isMounted)
                this.setState({ paises: response.data.results });
              break;
            case "patentes":
              if (this._isMounted)
                this.setState({ patentes: response.data.results });
              break;
            case "bancos":
              if (this._isMounted)
                this.setState({ bancos: response.data.results });
              break;
            case "regimen_fiscal":
              if (this._isMounted)
                this.setState({ regimen_fiscal: response.data.results });
              break;
            case "codigo_sat":
              this.setState({ codigos_sat: response.data.results });
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  render() {
    return (
      <div className="container-fluid">
        <SearchBar
          update={this.update}
          grupos={this.state.grupos}
          paises={this.state.paises}
          patentes={this.state.patentes}
          bancos={this.state.bancos}
          regimen_fiscal={this.state.regimen_fiscal}
          permisos={this.state.permisos}
          codigos_sat={this.state.codigos_sat}
        />
        <TableSol update={this.update} actualizar={this.state.actualizar} />
      </div>
    );
  }
}
export default Clientes;
