/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente general de la aplicación usuarios. 
*/
import React, { Component } from 'react';
import SearchBar from './SearchBar';
import TableUsu from './Table';
import * as permisos from '../../utils/permisos/permisos';

class AltaUser extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            actualizar: 0,
            permisos: permisos.obtener_permisos_by_app('user', localStorage.getItem('perm')),
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    update = (cambio) => {
        this.setState({ actualizar: cambio });
    }
    render() {
        return (
            <div className="container-fluid">
                <SearchBar update={this.update} permisos={this.state.permisos} /> 
                <TableUsu update={this.update} actualizar={this.state.actualizar}  />
            </div>
        );
    }
}
export default AltaUser;