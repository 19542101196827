/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la consulta de un proveedor. 
*/
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import MyInput from "../../../utils/custom/MyInput";
import WebSocketInstance from "../../../utils/websocket";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";

class ModalConsulta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numero_proveedor: "",
      nombre_corto: "",
      nombre_proveedor: "",
      id_fiscal: "",
      rfc: "",
      curp: "",
      calle: "",
      no_exterior: "",
      no_interior: "",
      codigo_postal: "",
      colonia: "",
      ciudad: "",
      estado: "",
      pais: "",
      rep_legal: "",
      telefono: "",
      patente: "",
      aduana: "",
      cliente: "",
      correo: "",
      direccion: "",

      data_modificacion: {},
      input_disabled: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],
    };
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.numero_proveedor;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "proveedor",
      motivo: motivo,
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this.initialState = { ...this.state };
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevState.numero_proveedor !== this.state.numero_proveedor && this.state.numero_proveedor.length > 0) {
      this.initialState = { ...this.state };
    }
  }
  
  handleNumero = (e) => {
    this.setState({ numero_proveedor: e.target.value });
  };
  handleConsulta = () => {
    if (this.state.numero_proveedor.length === 0)
      message.message_modal("Advertencia", "Debe tener un folio", 400);
    else {
      this.methodPOST_API(
        utility.url_consulta_proveedor,
        "consulta_proveedor",
        { numero: this.state.numero_proveedor }
      );
      utility.add_class_disabled("nombre_proveedor_consulta");
      utility.add_class_disabled("nombre_corto_proveedor_consulta");
      utility.add_class_disabled("id_fiscal_proveedor_consulta");
      utility.add_class_disabled("rfc_proveedor_consulta");
      utility.add_class_disabled("curp_proveedor_consulta");
      utility.add_class_disabled("direccion_proveedor_consulta");
      utility.add_class_disabled("rep_legal_proveedor_consulta");
      utility.add_class_disabled("telefono_proveedor_consulta");
      utility.add_class_disabled("aduana_proveedor_consulta");
      utility.add_class_disabled("patente_proveedor_consulta");
      utility.add_class_disabled("cliente_proveedor_consulta");
      utility.add_class_disabled("email_proveedor_consulta");
    }
  };
  hasStateChanged = (key) => {
    return this.state[key] !== this.initialState[key];
  }

  
  handleModificar = () => {
    if (this.state.numero_proveedor.length === 0) {
      message.message_modal("Advertencia", "Debe tener un folio", 400);
    } else {
      message
        .message_confimacion_textarea(
          "¿Desea continuar con la operación modificar?",
          "Ingrese sus observaciones "
        )
        .then((res) => {
          if (res) {
            let observaciones = document.getElementById(
              "observaciones_textarea"
            ).value;
            let data_modificacion = {};
  
            // Agregar número de proveedor y observaciones
            data_modificacion["numero"] = this.state.numero_proveedor;
            data_modificacion["observaciones"] = observaciones;
  
            // Comprobar qué campos se han modificado y agregarlos a data_modificacion
            const fieldsToCheck = [
              "nombre_proveedor",
              "nombre_corto",
              "id_fiscal",
              "rfc",
              "curp",
              "calle",
              "no_exterior",
              "no_interior",
              "codigo_postal",
              "colonia",
              "ciudad",
              "estado",
              "pais",
              "rep_legal",
              "telefono",
              "patente",
              "aduana",
              "cliente",
              "correo",
            ];
  
            fieldsToCheck.forEach((field) => {
              if (this.hasStateChanged(field)) {
                data_modificacion[field] = this.state[field];
              }
            });
  
            // Verificar si hay cambios
            if (Object.keys(data_modificacion).length > 2) { // número y observaciones siempre están, por eso verificamos más de 2
              this.methodPUT_API(
                utility.url_crea_proveedor,
                "modificar",
                data_modificacion
              );
              this.sendNotificacionHandler("modificar");
            } else {
              message.message_modal(
                "Advertencia",
                "No se ha modificado ningún campo",
                400
              );
            }
          }
        });
    }
  };
  
  
  
  handleCerrar = () => {
    this.setState({
      numero_proveedor: "",
      nombre_corto: "",
      nombre_proveedor: "",
      id_fiscal: "",
      rfc: "",
      curp: "",
      calle: "",
      no_exterior: "",
      no_interior: "",
      codigo_postal: "",
      colonia: "",
      ciudad: "",
      estado: "",
      pais: "",
      rep_legal: "",
      telefono: "",
      patente: "",
      aduana: "",
      cliente: "",
      correo: "",
      direccion: "",

      data_modificacion: {},
      input_disabled: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],
    });
    this.props.toogleConsulta();
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios.post(url_intern, data)
        .then(response => {
            if (response.status >= 200 && response.status < 400) {
                switch (state) {
                    case 'consulta_proveedor':
                        this.setState({
                            nombre_corto: response.data.results.nombre_corto,
                            nombre_proveedor: response.data.results.nombre,
                            id_fiscal: response.data.results.id_fiscal,
                            rfc: response.data.results.rfc,
                            curp: response.data.results.curp,
                            calle: response.data.results.calle,
                            no_exterior: response.data.results.no_exterior,
                            no_interior: response.data.results.no_interior,
                            codigo_postal: response.data.results.codigo_postal,
                            colonia: response.data.results.colonia,
                            ciudad: response.data.results.ciudad,
                            estado: response.data.results.estado,
                            pais: response.data.results.pais,
                            rep_legal: response.data.results.rep_legal,
                            telefono: response.data.results.telefono,
                            patente: response.data.results.patente,
                            aduana: response.data.results.aduana,
                            cliente: response.data.results.cliente,
                            correo: response.data.results.correo,
                            input_disabled: [false, false, false, false, false, true, false, false, false, false, false, false],
                        }, () => {
                            let direccion = "";
                            if (this.state.calle.length > 0)
                                direccion += this.state.calle;
                            if (this.state.no_exterior.length > 0)
                                direccion += " No. " + this.state.no_exterior + ", ";
                            if (this.state.no_interior.length > 0)
                                direccion += this.state.no_interior + ", ";
                            if (this.state.colonia.length > 0)
                                direccion += this.state.colonia + ", ";
                            if (this.state.codigo_postal.length > 0)
                                direccion += "CP. " + this.state.codigo_postal + ", ";
                            if (this.state.ciudad && this.state.ciudad.length > 0)
                                direccion += this.state.ciudad + ", ";
                            if (this.state.estado && this.state.estado.length > 0)
                                direccion += this.state.estado + ", ";
                            if (this.state.pais.length > 0)
                                direccion += this.state.pais + ".";
                                this.setState({ direccion: direccion }, () => {
                                  this.initialState = { ...this.state }; 
                                });
                        })
                        break;
                    default:
                        break;
                }
            }
        })
        .catch(error => {
            if (error.response)
                message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
        });
}

  methodPUT_API = (url, state, data) => {
    let url_intern = url;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "modificar":
              this.handleCerrar();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  handleNombre = (e) => {
    let nombre_proveedor = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["nombre_proveedor"] = nombre_proveedor;
    this.setState({ nombre_proveedor, data_modificacion });
  };
  handleNombre_corto = (e) => {
    let nombre_corto = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["nombre_corto"] = nombre_corto;
    this.setState({ nombre_corto, data_modificacion });
  };
  handleFiscal = (e) => {
    let id_fiscal = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["id_fiscal"] = id_fiscal;
    this.setState({ id_fiscal, data_modificacion });
  };
  handleRFC = (e) => {
    let rfc = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["rfc"] = rfc;
    this.setState({ rfc, data_modificacion });
  };
  handleCURP = (e) => {
    let curp = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["curp"] = curp;
    this.setState({ curp, data_modificacion });
  };
  handleLegal = (e) => {
    let rep_legal = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["rep_legal"] = rep_legal;
    this.setState({ rep_legal, data_modificacion });
  };
  handleTelefono = (e) => {
    let telefono = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["telefono"] = telefono;
    this.setState({ telefono, data_modificacion });
  };
  handleAduana = (e) => {
    let aduana = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["aduana"] = aduana;
    this.setState({ aduana, data_modificacion });
  };
  handlePatente = (e) => {
    let patente = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["patente"] = patente;
    this.setState({ patente, data_modificacion });
  };
  handleCliente = (e) => {
    let cliente = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["cliente"] = cliente;
    this.setState({ cliente, data_modificacion });
  };
  handleCorreo = (e) => {
    let correo = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["correo"] = correo;
    this.setState({ correo, data_modificacion });
  };
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Consulta Proveedor De Cliente
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-10 col-8"
                id="numero_proveedor_consulta"
                value={this.state.numero_proveedor}
                onChange={this.handleNumero}
                nameLabel="Número de Proveedor"
              />
              <Button
                color="primary"
                className="col-lg-2 col-4 mb-2"
                onClick={this.handleConsulta}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="nombre_proveedor_consulta"
                nameLabel="Nombre de Proveedor"
                disabled={this.state.input_disabled[0]}
                value={this.state.nombre_proveedor}
                onChange={this.handleNombre}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="nombre_corto_proveedor_consulta"
                disabled={this.state.input_disabled[1]}
                value={this.state.nombre_corto}
                onChange={this.handleNombre_corto}
                nameLabel="Nombre corto"
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4"
                id="id_fiscal_proveedor_consulta"
                disabled={this.state.input_disabled[2]}
                value={this.state.id_fiscal}
                onChange={this.handleFiscal}
                nameLabel="ID Fiscal"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4"
                id="rfc_proveedor_consulta"
                disabled={this.state.input_disabled[3]}
                value={this.state.rfc}
                onChange={this.handleRFC}
                nameLabel="RFC"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4"
                id="curp_proveedor_consulta"
                disabled={this.state.input_disabled[4]}
                value={this.state.curp}
                onChange={this.handleCURP}
                nameLabel="CURP"
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-12"
                id="direccion_proveedor_consulta"
                disabled={this.state.input_disabled[5]}
                value={this.state.direccion}
                nameLabel="Dirección"
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="rep_legal_proveedor_consulta"
                disabled={this.state.input_disabled[6]}
                value={this.state.rep_legal}
                onChange={this.handleLegal}
                nameLabel="Representante Legal"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="telefono_proveedor_consulta"
                disabled={this.state.input_disabled[7]}
                value={this.state.telefono}
                onChange={this.handleTelefono}
                nameLabel="Telefono"
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="aduana_proveedor_consulta"
                disabled={this.state.input_disabled[8]}
                value={this.state.aduana}
                onChange={this.handleAduana}
                nameLabel="Aduana"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="patente_proveedor_consulta"
                disabled={this.state.input_disabled[9]}
                value={this.state.patente}
                onChange={this.handlePatente}
                nameLabel="Patente"
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="cliente_proveedor_consulta"
                disabled={this.state.input_disabled[10]}
                value={this.state.cliente}
                onChange={this.handleCliente}
                nameLabel="Cliente"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="email_proveedor_consulta"
                disabled={this.state.input_disabled[11]}
                value={this.state.correo}
                onChange={this.handleCorreo}
                nameLabel="Correo"
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={this.handleModificar}>
            Modificar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalConsulta;
