/* 
Autor: Alexis Yael Hernández Grimaldo
Descripción: El componenete genera un bloque correspondiente a la busqueda dinamica en la tabla para acceder a la información.
*/
import React, { Component } from "react";
import { Button, Collapse, Form, FormGroup } from "reactstrap";
import "../../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import MyInput from "../../utils/custom/MyInput";
import SelectComponent from "./utils/CustomSelect";
import MySelect from "../../utils/custom/MySelect";

class BusquedaEspecifica extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toogle_collapse: false,

      banco: "Seleccione un banco",
      id_banco: "",
      tipo: "Seleccione un tipo",
      id_tipo: "",
      tipo_transaccion: "Seleccione un tipo",
      id_transaccion: "",
      beneficiario: "Seleccione un beneficiario",
      id_beneficiario: "",
      comprobado: "Seleccione un estado",
      id_comprobado: "",
      concepto: "",
      numero: "",
      importe_inicial: "",
      importe_final: "",
      fecha_inicial: "",
      fecha_final: "",

      tipo_movimiento_array: [
        { id: 0, nombre: "Transacción" },
        { id: 1, nombre: "Cheque" },
      ],
      comprobado_array: [
        { id: 0, nombre: "No" },
        { id: 1, nombre: "Si" },
      ],
      tipo_transaccion_array: [
        { id: "CC", text: "Comprobable del cliente" },
        { id: "CE", text: "Comprobable de la empresa" },
        { id: "PC", text: "Por comprobar del cliente" },
        { id: "PE", text: "Por comprobar de la empresa" },
      ],
      tipo_movimiento_array: [
        { id: 0, nombre: "Transacción" },
        { id: 1, nombre: "Cheque" },
      ],
    };
  }
  toogleCollapse = (e) => {
    this.setState((prevState) => ({
      toogle_collapse: !prevState.toogle_collapse,
    }));
  };
  handleValida = (data) => {
    if (data.numero.length === 0) delete data.numero;
    if (data.cheque.length === 0) delete data.cheque;
    if (data.banco.length === 0) delete data.banco;
    if (data.tipo.length === 0) delete data.tipo;
    if (data.beneficiario.length === 0) delete data.beneficiario;
    if (data.comprobado.length === 0) delete data.comprobado;
    if (data.fecha_inicial.length > 0 && data.fecha_final.length > 0)
      data["fechas"] = data.fecha_inicial + "," + data.fecha_final;
    else if (data.fecha_inicial.length > 0 && data.fecha_final.length === 0)
      data["fechas"] = data.fecha_inicial + "," + data.fecha_inicial;
    else if (data.fecha_inicial.length === 0 && data.fecha_final.length > 0)
      data["fechas"] = data.fecha_final + "," + data.fecha_final;
    else {
      delete data.fecha_inicial;
      delete data.fecha_final;
    }
    if (data.importe_inicial.length > 0 && data.importe_final.length > 0)
      data["importes"] = data.importe_inicial + "," + data.importe_final;
    else if (data.importe_inicial.length > 0 && data.importe_final.length === 0)
      data["importes"] = data.importe_inicial + "," + data.importe_inicial;
    else if (data.importe_inicial.length === 0 && data.importe_final.length > 0)
      data["importes"] = data.importe_final + "," + data.importe_final;
    else {
      delete data.importe_inicial;
      delete data.importe_final;
    }
  };
  handleFiltrada = () => {
    let data = {
      numero: this.state.numero,
      cheque: this.state.id_tipo,
      banco: this.state.id_banco,
      tipo: this.state.id_transaccion,
      beneficiario: this.state.id_beneficiario,
      importe_inicial: this.state.importe_inicial,
      importe_final: this.state.importe_final,
      comprobado: this.state.id_comprobado,
      fecha_inicial: this.state.fecha_inicial,
      fecha_final: this.state.fecha_final,
    };
    this.handleValida(data);
    this.props.onChangeFiltrada(data);
  };

  handleBanco = (e) => {
    const selectedValue = e.target.value;
    const [numero, tpo_bancoFull] = selectedValue.split(".");
    const [tpo_banco] = tpo_bancoFull.split(" - ");

    this.setState({
      banco: numero,
      tpo_banco: tpo_banco,
    });
  };
  handleTipo_movimento = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo = e[label];
    let id_tipo = e[label_id];
    this.setState({ tipo, id_tipo });
  };
  handleTipo_transaccion = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_transaccion = e[label];
    let id_transaccion = e[label_id];
    this.setState({
      tipo_transaccion,
      id_transaccion,
      tipo_movimiento: "Seleccione un tipo",
      id_tipo_movimiento: "",
    });
  };
  handleBeneficiario = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let beneficiario = e[label];
    let id_beneficiario = e[label_id];
    this.setState({ beneficiario, id_beneficiario });
  };
  handleConcepto = (e) => {
    let concepto = e.target.value;
    this.setState({ concepto });
  };
  handleNumero = (e) => {
    let numero = e.target.value;
    this.setState({ numero });
  };
  handleImporte_I = (e) => {
    let importe_inicial = e.target.value;
    this.setState({ importe_inicial });
  };
  handleImporte_F = (e) => {
    let importe_final = e.target.value;
    this.setState({ importe_final });
  };
  handleFecha_I = (e) => {
    let fecha_inicial = e.target.value;
    this.setState({ fecha_inicial });
  };
  handleFecha_F = (e) => {
    let fecha_final = e.target.value;
    this.setState({ fecha_final });
  };
  render() {
    return (
      <React.Fragment>
        <div className="row mb-2 mx-1">
          <h3 className="col-md-11 col-9 text-md-left px-0 mb-0">
            Consulta Específica
          </h3>
          <Button
            color="info"
            onClick={this.toogleCollapse}
            className="col-md-1 col-3"
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
        <Collapse isOpen={this.state.toogle_collapse}>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                id="numero_transaccion"
                nameLabel="Número"
                value={this.state.numero}
                onChange={this.handleNumero}
              />
              <MySelect
                bootstrap="col-lg-3 col-12"
                nameLabel="Bancos"
                first_option="Seleccione un banco"
                required
                value={this.state.banco}
                onChange={this.handleBanco}
                id="bancos_alta_anticipo"
              >
                {this.props.bancos.map((ban) => (
                  <option key={ban.numero}>
                    {ban.numero} - {ban.nombre}
                  </option>
                ))}
              </MySelect>
              {/* <SelectComponent
                bootstrap="col-lg-3 col-12"
                name_text="banco"
                name_index="numero"
                id="banco_transaccion"
                nameLabel="Banco"
                options={this.props.bancos}
                value={this.state.banco}
                onChange={this.handleBanco}
              /> */}
              <SelectComponent
                bootstrap="col-lg-3 col-12"
                name_text="nombre"
                name_index="id"
                id="tipo_movimiento_transaccion"
                nameLabel="Tipo de Movimiento"
                options={this.state.tipo_movimiento_array}
                value={this.state.tipo}
                onChange={this.handleTipo_movimento}
              />
              <SelectComponent
                bootstrap="col-lg-3 col-12"
                name_text="text"
                name_index="id"
                id="tipo_transaccion_transaccion"
                nameLabel="Tipo de Transacción"
                options={this.state.tipo_transaccion_array}
                value={this.state.tipo_transaccion}
                onChange={this.handleTipo_transaccion}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <SelectComponent
                bootstrap="col-lg-3 col-12"
                name_text="nombre"
                name_index="id"
                id="id"
                nameLabel="Beneficiario"
                options={this.props.beneficiarios}
                value={this.state.beneficiario}
                onChange={this.handleBeneficiario}
              />
              <SelectComponent
                bootstrap="col-lg-3 col-12"
                name_text="nombre"
                name_index="id"
                id="id"
                nameLabel="Comprobado"
                options={this.state.comprobado_array}
                value={this.state.comprobado}
                onChange={this.handleComprobado}
              />
              <MyInput
                type="number"
                bootstrap="col-lg-3 col-12"
                id="importe_I_transaccion"
                nameLabel="Importe inicial"
                value={this.state.importe_inicial}
                onChange={this.handleImporte_I}
              />
              <MyInput
                type="number"
                bootstrap="col-lg-3 col-12"
                id="importe_F_transaccion"
                nameLabel="Importe final"
                value={this.state.importe_final}
                onChange={this.handleImporte_F}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="date"
                bootstrap="col-lg-4 col-12"
                id="fecha_I_transaccion"
                nameLabel="Fecha inicial"
                value={this.state.fecha_inicial}
                onChange={this.handleFecha_I}
              />
              <MyInput
                type="date"
                bootstrap="col-lg-4 col-12"
                id="fecha_F_transaccion"
                nameLabel="Fecha final"
                value={this.state.fecha_final}
                onChange={this.handleFecha_F}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="concepto_transaccion"
                nameLabel="Concepto"
                value={this.state.concepto}
                onChange={this.handleConcepto}
              />
            </FormGroup>
            <FormGroup className="row d-flex justify-content-center mb-0">
              <Button
                color="danger"
                className="mb-1"
                onClick={this.props.onBackTable}
              >
                Regresar
              </Button>
              <Button color="success ml-2 mb-1" onClick={this.handleFiltrada}>
                Consultar
              </Button>
            </FormGroup>
          </Form>
        </Collapse>
      </React.Fragment>
    );
  }
}
export default BusquedaEspecifica;
