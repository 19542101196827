/* 
Autor: Alexis Yael Hernández Grimaldo
Descripción: El componenete genera un bloque correspondiente a la busqueda dinamica en la tabla para acceder a la información.
*/
import React, { Component } from 'react';
import { Form, FormGroup, Button, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import MyInput from '../../utils/custom/MyInput';
import * as utility from './utils/utility';

class BusquedaEspecifica extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            toogle_collapse: false,
        }
    }
    toogleCollapse = (e) => {
        this.setState(prevState => ({ toogle_collapse: !prevState.toogle_collapse }))
    }
    /**
     * Función que oprmite llamar a una función del componente padre para hacer una bisqueda filtrada en la 
     * tabla principal.
     */
    handleFiltrada = () => {
        let data = {
            id_departamento: document.getElementById('id_departamento_specific_departamento').value,
            nombre: document.getElementById('nombre_specific_departamento').value,
            area: document.getElementById('area_specific_departamento').value,
            descripcion: document.getElementById('descripcion_specific_departamento').value,
        }
        utility.valida_specific_data(data);
        this.props.onChangeFiltrada(data);
    }
    render() {
        return (
            <React.Fragment>
                <div className="row mb-2 mx-1">
                    <h3 className="col-md-11 col-9 text-md-left px-0 mb-0">Consulta Específica</h3>
                    <Button color="info" onClick={this.toogleCollapse} className="col-md-1 col-3">
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </div>
                <Collapse isOpen={this.state.toogle_collapse}>
                    <Form className="container-fluid">
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-3 col-12" id="id_departamento_specific_departamento" nameLabel="ID Departamento" />
                            <MyInput type="text" bootstrap="col-lg-3 col-12" id="nombre_specific_departamento" nameLabel="Nombre" />
                            <MyInput type="text" bootstrap="col-lg-3 col-12" id="area_specific_departamento" nameLabel="Area" />
                            <MyInput type="text" bootstrap="col-lg-3 col-12" id="descripcion_specific_departamento" nameLabel="Decripción" />
                        </FormGroup>
                    </Form>
                    <FormGroup className="row d-flex justify-content-center mb-0">
                        <Button color="danger" className="mb-1" onClick={this.props.onBackTable}>Regresar</Button>
                        <Button color="success ml-2 mb-1" onClick={this.handleFiltrada} >Consultar</Button>
                    </FormGroup>
                </Collapse>
            </React.Fragment>
        );
    }
}
export default BusquedaEspecifica;