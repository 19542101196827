/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un XXX. 
*/
import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Form, FormGroup } from 'reactstrap';
import * as utility from '../utils/utility';
import * as message from '../messages/messages';
import axios from 'axios';
import MyInput from '../utils/MyInput';

class ModalAlta extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            periodo: "",
            tipo: "",
            fecha: "",
            archivo: "",
            folio: "",
            status: "",

            data_modificacion: {}
        }
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    handleConsulta = () => {
        if (this.state.periodo.length === 0 || this.state.tipo.length === 0)
            message.message_modal("Advertencia", "Debe tener un Periodo y Tipo", 400);
        else {
            this.methodPOST_API(utility.url_consulta_contabilidad, 'consulta', {
                periodo: this.state.periodo,
                tipo: this.state.tipo,
            });
        }
    }
    handlePeriodo = (e) => {
        let periodo = e.target.value;
        this.setState({ periodo });
    }
    handleTipo = (e) => {
        let tipo = e.target.value;
        this.setState({ tipo });
    }
    handleFecha = (e) => {
        let fecha = e.target.value;
        this.setState({ fecha });
    }
    handleArchivo = (e) => {
        let archivo = e.target.value;
        this.setState({ archivo });
    }
    handleFolio = (e) => {
        let folio = e.target.value;
        this.setState({ folio });
    }
    handleStatus = (e) => {
        let status = e.target.value;
        this.setState({ status });
    }
    handleCerrar = () => {
        this.setState({
            periodo: "",
            tipo: "",
            fecha: "",
            archivo: "",
            folio: "",
            status: "",

            data_modificacion: {}
        });
        this.props.toogleConsulta();
    }
    /**
     * Función que permite hacer una petición de forma POST.
     * @param {String} url URL a consultar. 
     * @param {String} state Opción del case.  
     * @param {Object} data Objeto a mandar atraves del metodo POST. 
     */
    methodPOST_API = (url, state, data) => {

        /*  SI SE REALIZA UNA OPERACION QUE DEBE CERRAR EL MODAL, MOSTAR UN MENSAJE Y ACTUALIZAR LA 
        TABLA PRINCIPAL ESTA ES LA ESTRCUTURA.

        this.props.toogleXXXXX(); -> FUNCIÓN QUE CIRRA EL MODAL.
        message.message_modal(response.data.title, response.data.text, response.status); -> MENSAJE A MOSTAR.
        this.props.update(1); -> ACTUALIZACIÓN DE LA TABLA PRINCIPAL.   */

        let url_intern = url;
        axios.post(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        case 'alta':
                            break;
                        case 'modificacion':
                            break;
                        case 'consulta':
                            this.setState({
                                fecha: response.data.results.fecha,
                                archivo: response.data.results.archivo,
                                folio: response.data.results.folio,
                                status: response.data.results.status,
                            });
                            utility.add_class_disabled("fecha_consulta_contabilidad");
                            utility.add_class_disabled("archivo_consulta_contabilidad");
                            utility.add_class_disabled("folio_consulta_contabilidad");
                            utility.add_class_disabled("status_consulta_contabilidad");
                            break;
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    /**
     * Función que permite hacer una petición de forma GET
     * @param {String} url URL a consulta 
     * @param {String} state Opción del case 
     */
    methodGET_API = (url, state) => {
        let url_intern = url;
        axios.get(url_intern)
            .then(response => {
                if (response.status >= 200 && response.status < 400) {
                    switch (state) {
                        default:
                            break;
                    }
                }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    render() {
        return (
            <Modal isOpen={this.props.toogle_consulta} toggle={this.handleCerrar} backdrop="static" size="xl">
                <ModalHeader toggle={this.handleCerrar}>Consulta Contabilidad Electronica</ModalHeader>
                <ModalBody>
                    <Form className="container-fluid">
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-5 col-12" id="periodo_consulta_contabilidad" nameLabel="Periodo"
                                value={this.state.periodo} onChange={this.handlePeriodo} />
                            <MyInput type="text" bootstrap="col-lg-5 col-12" id="tipo_consulta_contabilidad" nameLabel="Tipo"
                                value={this.state.tipo} onChange={this.handleTipo} />
                            <Button color="primary" className="col-lg-2 col-12 mb-2" onClick={this.handleConsulta}>Consulta</Button>
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-6 col-12" id="fecha_consulta_contabilidad" nameLabel="Fecha"
                                value={this.state.fecha} disabled="true" />
                            <MyInput type="text" bootstrap="col-lg-6 col-12" id="archivo_consulta_contabilidad" nameLabel="Archivo"
                                value={this.state.archivo} disabled="true" />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-6 col-12" id="folio_consulta_contabilidad" nameLabel="Folio"
                                value={this.state.folio} disabled="true" />
                            <MyInput type="text" bootstrap="col-lg-6 col-12" id="status_consulta_contabilidad" nameLabel="Estado"
                                value={this.state.status} disabled="true" />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.handleCerrar}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default ModalAlta;