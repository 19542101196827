export const url_bancos = '/DjangoWeb/BaseApp/consulta/bancos/lista';
export const url_verify = '/DjangoWeb/UsuariosApp/verica/usuario';



/**
 * Función que nos permite filtar la tabla por el input general que serra de tipo text.
 */
export const general_search = (e) => {
    e.preventDefault();
    let tr = document.getElementById('tabla_poliza').getElementsByTagName('tr');
    let data_input = document.getElementById('busqueda_general_poliza').value.toUpperCase();
    for (let i = 0; i < tr.length; i++) {
        let mostrar = false;
        let data = tr[i].getElementsByTagName('p');
        for (let j = 0; j < data.length; j++) {
            if (data_input === "") {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
            else if (data[j].textContent.toUpperCase().includes(data_input)) {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "rgba(0,123,255,1)";
                data[j].style.color = "#fff";
            }
            else {
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que nos permite filtrar latabla solamnete por la fecha.
 */
export const date_search = (e) => {
    e.preventDefault();
    var tabla_anticipo, input_general, tr, dato, input_dato, mostrar;
    tabla_anticipo = document.getElementById("tabla_poliza");
    input_general = document.getElementById("busqueda_date_poliza");
    input_dato = input_general.value;
    tr = tabla_anticipo.getElementsByTagName("tr");
    for (var i = 0; i < tr.length; i++) {
        mostrar = false;
        dato = tr[i].getElementsByTagName("p");
        for (var j = 0; j < dato.length; j++) {
            if (dato[j].textContent === input_dato) {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "rgba(0,123,255,1)";
                dato[j].style.color = "#fff";
            }
            else if (input_dato === "") {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
            else {
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}

/**
 * Función que permirte retonar la fecha y hora actual.
 * @returns {Object} Fecha y hora actual.
 */
 export const get_fecha_actual = () => {
    const actual = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    return {
        fecha: actual.toISOString().slice(0, 10),
        hora: actual.toISOString().slice(11, 16),
        fecha_string: actual.toLocaleDateString("es-MX", options)
    }
}

/**
 * Función que genera la fecha actual del día y su hora axacta.
 * @returns {Object} Contiene fecha y hora.
 */
 export const get_date_actual = () => {
    const actual = new Date();
    return {
        fecha: actual.toISOString().slice(0, 10),
        hora: actual.toISOString().slice(11, 16)
    }
}

/* ------------------------------------------- Funciones para cambio de input desactivado ----------------------------------------------- */
export const add_class_disabled = (id) => {
    document.getElementById(id).classList.add('input-animatedd');
}

/**
 * 
 * @param {number} cliente Número de cliente.
 * @param {array} arreglo Arreglo de anticipos.
 */
 export const repeticion_cliente = (cliente, arreglo) => {
    let contador = 0;
    for (let i = 0; i < arreglo.length; i++)
        if (cliente === parseInt(arreglo[i].cliente))
            contador++;
    return contador;
}
/**
 * Función que permite retornar el nombre de un cliente ne especifico.
 * @param {String} numero Número de cliente.
 * @param {Array} array Arreglo de clientes.
 * @returns {String} Nombre del cliente.
 */
export const get_name_cliente = (numero, array) => {
    let complete_number = "";
    switch (numero.length) {
        case 1: complete_number = '000' + numero; break;
        case 2: complete_number = '00' + numero; break;
        case 3: complete_number = '0' + numero; break;
        case 4: complete_number = numero; break;
        default: break;
    }
    for (let i = 0; i < array.length; i++)
        if (complete_number === array[i].numero)
            return (array[i].nombre);
}
/**
 * Función que obtiene los nombres de todos los clientes
 * @param {array} arreglo 
 * @returns {array} Arreglo de nombres.
 */
export const get_nombres_clientes = (arreglo) => {
    var aux = [];
    for (var i = 0; i < arreglo.length; i++) {
        aux.push(arreglo[i].nombre);
    }
    return (aux);
}
/**
 * Finción que permite el retorno del número de cliente por su nombre.
 * @param {Sring} nombre Nombre del cliente. 
 * @param {Array} array Arreglo de clientes.
 * @returns {String} Número del cliente.
 */
export const get_number_cliente = (nombre, array) => {
    let only_number = "";
    let bandera = 0;
    for (let i = 0; i < array.length; i++)
        if (nombre === array[i].nombre) {
            for (let j = 0; j < 4; j++) {
                if (array[i].numero[j] !== '0')
                    bandera = 1;
                if (bandera === 1)
                    only_number += array[i].numero[j];
            }
            return (only_number);
        }
}