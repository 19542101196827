import React from 'react'

const Usuario = (props) => {
    return (
        <div className="p-2 d-flex align-items-center position-relative border-bottom bg-white" onClick={props.onClick} id={props.id}
            value={props.value} >
            <div className="d-flex justify-content-center align-items-center imagen-usuario-chat"
                style={{ background: `rgba(${props.value},0.3)`, border: `solid rgb(${props.value}) 2px`, color: `rgb(${props.value})` }}>
                <p className="mb-0">{props.name[0].toUpperCase()}</p>
            </div>
            <div className="ml-3 mr-auto">
                <p className="mb-0 d-inline-block" style={{ fontSize: "18px", fontWeight: "bolder" }}>{props.name}</p>
                <p className="mb-0" style={{ fontSize: "13px" }}>
                    {props.message.length > 14 ? `${props.message.slice(0, 14)} ...` : props.message}
                </p>
            </div>
            <span className="pt-3 pr-3 pl-2" style={{ position: "absolute", top: "0px", right: "0px", fontSize: "13px", zIndex:"2", background:"#fff" }}>
                {props.date}
            </span>
        </div>
    );
}

export default Usuario;