import * as actionsTypes from "../actions/actionsTypes";
import { updateObject } from "./utility";

const initialState = {
  messages: [],
  chats: []
};

const addMessage = (state, action) => {
  return updateObject(state, {
    messages: action.message
  });
};

const setMessages = (state, action) => {
  let messages = state.messages;
  messages['mensajes'] = [...messages['mensajes'], action.message];
  return updateObject(state, {
    messages: { ...messages }
  });
};

const setChats = (state, action) => {
  return updateObject(state, {
    chats: action.chats.chats
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.ADD_MESSAGE:
      return addMessage(state, action);
    case actionsTypes.SET_MESSAGES:
      return setMessages(state, action);
    case actionsTypes.GET_CHATS_SUCCESS:
      return setChats(state, action);
    default:
      return state;
  }
};

export default reducer;