/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente general de la aplicación empleados. 
*/
import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Form, Input, ListGroup, ListGroupItem } from 'reactstrap';
import * as messageActions from '../../utils/actions/message';
import MyInput from '../../utils/custom/MyInput';
import MySelect from '../../utils/custom/MySelect';
import MyTextArea from '../../utils/custom/MyTextArea';
import * as message from '../../utils/messages/message';
import WebSocketInstance from "../../utils/websocket";
import Mensajes from './utils/Mensajes';
import Usuario from './utils/Usuario';
import * as utility from './utils/utility';

class Chat extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            conversaciones: [],
            usuarios: [],
            departamentos: [],
            clientes: [],
            mensajes: [],

            nombre_cliente_select: "",
            nombre_suggestions: [],
            nombre_select: "",
            departamento_suggestions: [],
            departamento_select: "",
            tipo_usuario: 0,

            nuevo_chat: 0,
            chat_movil: 0,
            data_chat: {},
        };
    }
    /*--------------------------------------------------------------------- */
    /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
    addMensaje(mensajes_data) {
        if (mensajes_data.tipo === 1) {
            let mensajes = mensajes_data.mensajes;
            this.setState({ mensajes });
        }
        if (mensajes_data.tipo === 2) {
            this.setState({
                nuevo_chat: 2, data_chat: mensajes_data, mensajes: [{
                    fecha: mensajes_data.fecha_mensaje,
                    mensaje: mensajes_data.ultimo_mensaje,
                    mio: true
                }]
            });
        }
        if (mensajes_data.tipo === 3) {
            let mensajes = [...this.state.mensajes];
            mensajes.push(mensajes_data);
            this.setState({ mensajes });
        }
        if (mensajes_data.tipo === 4) {
            let conversaciones = mensajes_data.chats;
            this.setState({ conversaciones })
        }
    }
    sendMessage = (data) => {
        const notificacionObject = { ...data }
        WebSocketInstance.newNotificacion(notificacionObject);
    }
    /*--------------------------------------------------------------------- */
    componentDidMount() {
        this._isMounted = true;
        this.sendMessage({ tipo: 4 });
        this.methodGET_API(utility.url_usuarios, 'usuarios');
        this.methodGET_API(utility.url_departamentos, 'departamentos');
        this.methodGET_API(utility.url_clientes, 'clientes');
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    handleNuevo = () => {
        this.setState({ nuevo_chat: 1, chat_movil: 1 });
    }
    handleCrear = () => {
        let is_cliente = localStorage.getItem('puesto') === "Cliente" ? true : false;
        let usuario_name = is_cliente ? document.getElementById('nombre_departamento').value : document.getElementById('nombre_usuario').value;
        let destino = this.state.tipo_usuario === 0 ? utility.obtener_first_name(this.state.usuarios, usuario_name) :
            `${parseInt(this.state.nombre_cliente_select)}-c`;
        let data_notificacion = { tipo: 0, aplicacion: 'mensaje', motivo: 'crear' };
        let data = { tipo: 2, mensaje: document.getElementById('mensaje_usuario').value };
        if (is_cliente) {
            data['departamento'] = this.state.departamento_select;
            data_notificacion['departamento'] = this.state.departamento_select;
        }
        else {
            data['destino'] = destino;
            data_notificacion['destino'] = destino;
        }
        this.sendMessage(data);
        this.sendMessage(data_notificacion);
        setTimeout(() => {
            let data_chat = this.props.chats[0];
            this.handleChat(data_chat.id_chat);
        }, 1000)
    }
    handleChat = (id_chat) => {
        let firts_name = `${localStorage.getItem('id_usr')}${localStorage.getItem('puesto') === "Cliente" ? "-c" : "-e"}`;
        let data = { tipo: 1, id_chat: id_chat, origen: firts_name };
        let data_chat;
        for (let i = 0; i < this.props.chats.length; i++)
            if (this.props.chats[i].id_chat === id_chat)
                data_chat = { ...this.props.chats[i] }
        this.sendMessage(data);
        this.setState({ nuevo_chat: 2, mensajes: [], data_chat });
    }
    handleEnviar = (e) => {
        e.preventDefault();
        let data_notificacion = { tipo: 0, aplicacion: 'mensaje', motivo: 'enviar', chat: this.state.data_chat.id_chat };
        let data_mensaje = {
            tipo: 3,
            mensaje: document.getElementById('mensaje_nuevo').value,
            id_chat: this.state.data_chat.id_chat
        }
        this.sendMessage(data_mensaje);
        this.sendMessage(data_notificacion);
        document.getElementById('mensaje_nuevo').value = "";
    }
    handleEnviar_movil = () => {
        let data_notificacion = { tipo: 0, aplicacion: 'mensaje', motivo: 'enviar', chat: this.state.data_chat.id_chat };
        let data_mensaje = {
            tipo: 3,
            mensaje: document.getElementById('mensaje_nuevo_movil').value,
            id_chat: this.state.data_chat.id_chat
        }
        this.sendMessage(data_mensaje);
        this.sendMessage(data_notificacion);
        document.getElementById('mensaje_nuevo_movil').value = "";
    }
    handleTipo_usuario = (e) => {
        let tipo_usuario = parseInt(e.target.value);
        this.setState({ tipo_usuario });
    }
    handleCliente = (e) => {
        let nombre_cliente_select = e.target.value.slice(0, 4);
        this.setState({ nombre_cliente_select });
    }
    handleRegresar = () => {
        this.setState({ chat_movil: 0 });
    }
    methodGET_API = (url, state) => {
        let url_intern = url;
        axios.get(url_intern)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        case 'usuarios':
                            if (this._isMounted)
                                this.setState({ usuarios: response.data.results });
                            break;
                        case 'departamentos':
                            if (this._isMounted)
                                this.setState({ departamentos: response.data.results });
                            break;
                        case 'clientes':
                            if (this._isMounted) {
                                this.setState({ clientes: response.data.results }, () => {
                                    this.sendMessage({ tipo: 4 });
                                });
                                this.handleChat(this.props.chats[0].id_chat);
                            }
                            break;
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    methodPOST_API = (url, state, data) => {
        let url_intern = url;
        axios.post(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    renderTimestamp = timestamp => {
        let prefix = "";
        const timeDiff = Math.round(
            (new Date().getTime() - new Date(timestamp).getTime()) / 60000
        );
        if (timeDiff < 1) {
            // less than one minute ago
            prefix = "justo ahora...";
        } else if (timeDiff < 60 && timeDiff > 1) {
            // less than sixty minutes ago
            prefix = `${timeDiff} min`;
        } else if (timeDiff < 24 * 60 && timeDiff > 60) {
            // less than 24 hours ago
            prefix = `${Math.round(timeDiff / 60)} hrs`;
        } else if (timeDiff < 31 * 24 * 60 && timeDiff > 24 * 60) {
            // less than 7 days ago
            prefix = `${Math.round(timeDiff / (60 * 24))} días`;
        } else {
            prefix = `${new Date(timestamp).toISOString().slice(0, 10)}`;
        }
        return prefix;
    };

    suggestionSelected = (value) => {
        this.setState({
            nombre_select: value,
            nombre_suggestions: [],
        });
    }
    onNameChange = (e) => {
        const value = e.target.value;
        let suggestions = [];
        if (value.length > 0) {
            const regex = new RegExp(`^${value}`, 'i');
            suggestions = utility.nombres_usuarios(this.state.usuarios).sort().filter(v => regex.test(v));
        }
        this.setState({
            nombre_suggestions: suggestions,
            nombre_select: value,
        });
    }
    renderSuggestion = () => {
        if (this.state.nombre_suggestions.length === 0)
            return null;
        return (
            <ListGroup className="col-12" flush>
                {this.state.nombre_suggestions.map(data => (<ListGroupItem key={data} onClick={() => this.suggestionSelected(data)} action>{data}</ListGroupItem>))}
            </ListGroup>
        );
    }
    onNameChangeDepartamento = (e) => {
        const value = e.target.value;
        this.setState({
            departamento_select: value,
        });
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="row mt-3">
                    <div className="d-none d-md-block col-md-4 col-lg-3">
                        <div>
                            <Input placeholder="Buscar" />
                        </div>
                        <div className="mt-3" style={{ overflowY: "auto", maxHeight: "600px", height: "600px" }}>
                            {this.props.chats.length > 0 ? this.props.chats.map((conversacion, index) => {
                                return (
                                    <Usuario name={conversacion.asunto} date={this.renderTimestamp(conversacion.fecha_mensaje)} message={conversacion.ultimo_mensaje}
                                        onClick={() => this.handleChat(conversacion.id_chat)} id={`id_chat_${conversacion.id_chat}`} key={index}
                                        value={`${conversacion.color_chat[0]},${conversacion.color_chat[1]},${conversacion.color_chat[2]}`} />)
                            }) : null}
                            <button style={{ padding: "5px 10px", border: "none", background: "#fff" }} className="col-12 align-self-end border-top-0 border"
                                onClick={this.handleNuevo}>
                                Nuevo chat
                            </button>
                        </div>
                    </div>
                    <div className="d-none d-md-block col-md-8 col-lg-9 pl-0">
                        {this.state.nuevo_chat === 0 ?
                            (<div className="h-100 bg-white">
                                Cree un chat o seleccione uno
                            </div>) :
                            this.state.nuevo_chat === 1 ?
                                (<div className="d-flex justify-content-center align-items-center "
                                    style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0" }} >
                                    <Card className="col-6">
                                        <CardBody>
                                            <MySelect id="tipo_usuario" nameLabel="Tipo de Usuario" first_option="Seleccione un tipo de usuario"
                                                onChange={this.handleTipo_usuario} defaultValue="0">
                                                <option value={0}>Empleado</option>
                                                {localStorage.getItem('puesto') === 'Cliente' ? null : (<option value={1}>Cliente</option>)}
                                            </MySelect>
                                            {localStorage.getItem('puesto') === "Cliente" ?
                                                (<MySelect id="nombre_departamento" nameLabel="Departamento" first_option="Seleccione un departamento"
                                                    onChange={this.onNameChangeDepartamento}>
                                                    {this.state.departamentos.map(depa => (
                                                        <option value={depa.id_departamento}>{depa.nombre}</option>
                                                    ))}
                                                </MySelect>) :
                                                this.state.tipo_usuario === 1 ?
                                                    (<MySelect id="nombre_cliente" nameLabel="Clientes" first_option="Seleccione un cliente"
                                                        onChange={this.handleCliente}>
                                                        {this.state.clientes.map(cliente => (
                                                            <option value={cliente.numero}>{`${cliente.numero} - ${cliente.nombre}`}</option>
                                                        ))}
                                                    </MySelect>) :
                                                    (<React.Fragment>
                                                        <MyInput type="text" id="nombre_usuario" nameLabel="Nombre del Empleado" value={this.state.nombre_select}
                                                            bootstrap=""  onChange={this.onNameChange} />
                                                        {this.renderSuggestion()}
                                                    </React.Fragment>)
                                            }

                                            <MyTextArea id="mensaje_usuario" nameLabel="Mensaje" />
                                            <div className="d-flex justify-content-end">
                                                <Button color="primary" onClick={this.handleCrear} >Enviar</Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>) :
                                <Mensajes data={this.state.data_chat} mess={this.props.messages.mensajes ? this.props.messages.mensajes : []} />}
                        {this.state.nuevo_chat === 2 ?
                            (<Form onSubmit={this.handleEnviar} className="d-flex" style={{ border: "solid 1px #AAA", background: "#fff", borderRadius: "0px 0px 5px 5px" }}>
                                <Input type="text" id="mensaje_nuevo" placeholder="Aa" className="mx-3 my-2"
                                    maxLength="500" />
                                <Button color="primary" className="mr-3 my-2" onClick={this.handleEnviar}>Enviar</Button>
                            </Form>) : null}
                    </div>
                    {/* APARTADO PARA MOSTAR CHAT EN DISPOSITIVOS MOVILES */}
                    {this.state.chat_movil === 0 ?
                        (<div className="d-block d-md-none col-12">
                            <div>
                                <Input placeholder="Buscar" />
                            </div>
                            <div className="mt-3" style={{ overflowY: "auto", maxHeight: "600px", height: "600px" }}>
                                {this.props.chats.length > 0 ? this.props.chats.map((conversacion, index) => {
                                    return (
                                        <Usuario name={conversacion.asunto} date={this.renderTimestamp(conversacion.fecha_mensaje)} message={conversacion.ultimo_mensaje}
                                            onClick={() => {
                                                this.handleChat(conversacion.id_chat);
                                                this.setState({ chat_movil: 1 });
                                            }
                                            } id={`id_chat_${conversacion.id_chat}`} key={index}
                                            value={`${conversacion.color_chat[0]},${conversacion.color_chat[1]},${conversacion.color_chat[2]}`} />)
                                }) : null}
                                <button style={{ padding: "5px 10px", border: "none", background: "#fff" }} className="col-12 align-self-end border-top-0 border"
                                    onClick={this.handleNuevo}>
                                    Nuevo chat
                                </button>
                            </div>
                        </div>) :
                        (<div className="d-block d-md-none col-12 mb-3">
                            {this.state.nuevo_chat === 0 ?
                                (<div className="h-100 bg-white">
                                    Cree un chat o seleccione uno
                                </div>) :
                                this.state.nuevo_chat === 1 ?
                                    (<div className="d-flex justify-content-center align-items-center flex-column"
                                        style={{ position: "absolute", top: "180px", left: "0", right: "0", bottom: "0" }} >
                                        <Card className="col-11">
                                            <CardBody>
                                                <MySelect id="tipo_usuario" nameLabel="Tipo de Usuario" first_option="Seleccione un tipo de usuario"
                                                    onChange={this.handleTipo_usuario} defaultValue="0">
                                                    <option value={0}>Empleado</option>
                                                    <option value={1}>Cliente</option>
                                                </MySelect>
                                                {localStorage.getItem('puesto') === "Cliente" ?
                                                    (<MySelect id="nombre_departamento" nameLabel="Departamento" first_option="Seleccione un departamento"
                                                        onChange={this.onNameChangeDepartamento}>
                                                        {this.state.departamentos.map(depa => (
                                                            <option value={depa.id_departamento}>{depa.nombre}</option>
                                                        ))}
                                                    </MySelect>) :
                                                    this.state.tipo_usuario === 1 ?
                                                        (<MySelect id="nombre_cliente" nameLabel="Clientes" first_option="Seleccione un cliente"
                                                            onChange={this.handleCliente}>
                                                            {this.state.clientes.map(cliente => (
                                                                <option value={cliente.numero}>{`${cliente.numero} - ${cliente.nombre}`}</option>
                                                            ))}
                                                        </MySelect>) :
                                                        (<React.Fragment>
                                                            <MyInput type="text" id="nombre_usuario" nameLabel="Nombre del Empleado" value={this.state.nombre_select}
                                                                bootstrap=""  onChange={this.onNameChange} />
                                                            {this.renderSuggestion()}
                                                        </React.Fragment>)
                                                }
                                                <MyTextArea id="mensaje_usuario" nameLabel="Mensaje" />
                                                <div className="d-flex justify-content-end">
                                                    <Button color="primary" onClick={this.handleCrear} >Enviar</Button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                        <Button color="danger" className="mt-3 col-11" onClick={this.handleRegresar}>Regresar</Button>
                                    </div>) :
                                    <Mensajes data={this.state.data_chat} mess={this.props.messages.mensajes ? this.props.messages.mensajes : []}
                                        handleRegresar={this.handleRegresar} />}
                            {this.state.nuevo_chat === 2 ?
                                (<div className="d-flex" style={{ border: "solid 1px #AAA", background: "#fff", borderRadius: "0px 0px 5px 5px" }}>
                                    <Input type="text" id="mensaje_nuevo_movil" placeholder="Aa" className="mx-3 my-2"
                                        maxLength="500" />
                                    <Button color="primary" className="mr-3 my-2" onClick={this.handleEnviar_movil}>Enviar</Button>
                                </div>) : null}
                        </div>)}
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        messages: state.message.messages,
        chats: state.message.chats,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addMessage: message => dispatch(messageActions.addMessage(message)),
        getChats: chats => dispatch(messageActions.getChats(chats)),
        setMessages: message => dispatch(messageActions.setMessages(message))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Chat);