import axios from 'axios';
import * as actionsTypes from './actionsTypes';
import WebSocketInstance from '../websocket';

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export const authStart = () => {
    return {
        type: actionsTypes.AUTH_START
    };
}

export const authEnd = () => {
    return {
        type: actionsTypes.AUTH_END
    };
}

export const authSuccess = (userid, csrfToken) => {
    WebSocketInstance.connect(userid);
    return {
        type: actionsTypes.AUTH_SUCCESS,
        csrfToken: csrfToken,
        userid: userid,
    };
}

export const authFail = (error) => {
    return {
        type: actionsTypes.AUTH_FAIL,
        error: error
    };
}

export const authLogout = (username) => {
    localStorage.removeItem("csrfToken");
    localStorage.removeItem("expirationDate");
    localStorage.removeItem("areas");
    localStorage.removeItem("id_usr");
    localStorage.removeItem("user_name");
    localStorage.removeItem("apps");
    localStorage.removeItem("perm");
    localStorage.removeItem("puesto");
    localStorage.removeItem("emp_name");
    WebSocketInstance.disconnect();
    return dispatch => {
        dispatch(authEnd());
        axios.post('/DjangoWeb/UsuariosApp/cerrar/sesion', { usuario: username });
    };

}

export const authTimeOut = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(authLogout());
        }, expirationTime * 1000);
    }
}

export const authLogin = (username, password) => {
    return dispatch => {
        dispatch(authStart());
        axios.post('/DjangoWeb/UsuariosApp/iniciar/sesion', { usuario: username, password: password })
            .then(response => {
                const csrfToken = getCookie('csrftoken');
                const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
                const user_name = response.data.results.info_user.nombre_usuario;
                const empleado_name = response.data.results.info_user.nombre_empleado;
                const perm = response.data.results.perm_user;
                const id_usr = response.data.results.info_user.clave_socket;
                const puesto = response.data.results.info_user.puesto;
                const userid = puesto === "Cliente" ? id_usr + "c" : id_usr + "e";
                let areas = [];
                let apps = [];
                if (response.data.results.info_user.Contabilidad) {
                    let menu_apps = [];
                    areas.push('1');
                    response.data.results.info_user.Contabilidad.forEach(name_app => {
                        menu_apps.push(name_app.split('_').slice(1, name_app.split('_').length - 1).join('_'));
                    });
                    apps.push({ menu: '1', apps_menu: menu_apps });
                }
                if (response.data.results.info_user.FacturacionCobranza) {
                    let menu_apps = [];
                    areas.push('2');
                    response.data.results.info_user.FacturacionCobranza.forEach(name_app => {
                        menu_apps.push(name_app.split('_').slice(1, name_app.split('_').length - 1).join('_'));
                    });
                    apps.push({ menu: '2', apps_menu: menu_apps });
                }
                if (response.data.results.info_user.Bancos) {
                    let menu_apps = [];
                    areas.push('3');
                    response.data.results.info_user.Bancos.forEach(name_app => {
                        menu_apps.push(name_app.split('_').slice(1, name_app.split('_').length - 1).join('_'));
                    });
                    apps.push({ menu: '3', apps_menu: menu_apps });
                }
                if (response.data.results.info_user.Operacion) {
                    let menu_apps = [];
                    areas.push('4');
                    response.data.results.info_user.Operacion.forEach(name_app => {
                        menu_apps.push(name_app.split('_').slice(1, name_app.split('_').length - 1).join('_'));
                    });
                    apps.push({ menu: '4', apps_menu: menu_apps });
                }
                if (response.data.results.info_user.Catalogos) {
                    let menu_apps = [];
                    areas.push('5');
                    response.data.results.info_user.Catalogos.forEach(name_app => {
                        menu_apps.push(name_app.split('_').slice(1, name_app.split('_').length - 1).join('_'));
                    });
                    apps.push({ menu: '5', apps_menu: menu_apps });
                }
                if (response.data.results.info_user.Utilerias) {
                    let menu_apps = [];
                    areas.push('6');
                    response.data.results.info_user.Utilerias.forEach(name_app => {
                        menu_apps.push(name_app.split('_').slice(1, name_app.split('_').length - 1).join('_'));
                    });
                    apps.push({ menu: '6', apps_menu: menu_apps });
                }
                if (response.data.results.info_user.Administracion) {
                    let menu_apps = [];
                    areas.push('7');
                    response.data.results.info_user.Administracion.forEach(name_app => {
                        menu_apps.push(name_app.split('_').slice(1, name_app.split('_').length - 1).join('_'));
                    });
                    apps.push({ menu: '7', apps_menu: menu_apps });
                }
                localStorage.setItem('csrfToken', csrfToken);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('areas', areas);
                localStorage.setItem('apps', JSON.stringify(apps));
                localStorage.setItem('user_name', user_name);
                localStorage.setItem('emp_name', empleado_name);
                localStorage.setItem('id_usr', id_usr);
                localStorage.setItem('puesto', puesto)
                localStorage.setItem('perm', JSON.stringify(perm));
                dispatch(authSuccess(userid, csrfToken));
                dispatch(authTimeOut(3600));
            })
            .catch(error => {
                dispatch(authFail(error));
            });
    };
}

export const authCheckState = () => {
    return dispatch => {
        const csrfToken = localStorage.getItem('csrfToken');
        const userid = localStorage.getItem('puesto') === "Cliente" ? localStorage.getItem('id_usr') + "c" : localStorage.getItem('id_usr') + "e";
        if (csrfToken === null) {
            dispatch(authEnd());
        }
        else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(authLogout(localStorage.getItem('user_name')));
            }
            else {
                dispatch(authSuccess(userid, csrfToken));
                dispatch(authTimeOut((expirationDate.getTime() - new Date().getTime()) / 1000));
            }
        }
    }
}