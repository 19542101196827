/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un anticipo. 
*/
import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Collapse,
  Card,
  Table,
  CardBody,
  Alert,
  FormGroup,
} from "reactstrap";
import * as utility from "../utils/utility";
import * as message from "../messages/messages";
import axios from "axios";
import swal from "@sweetalert/with-react";
import * as permisos from "../../../utils/permisos/permisos";
import { faFileDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WebSocketInstance from "../../../utils/websocket";
import MyInput from "../../../utils/custom/MyInput";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalDevCon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toogle_consolida: false,
      toogle_devuelvo: false,
      toogle_cancelar: false,
    };
    this.toogleConso = this.toogleConso.bind(this);
    this.toogleDev = this.toogleDev.bind(this);
    this.toogleCan = this.toogleCan.bind(this);
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folios = [];
    let clientes = [];
    this.props.checked.forEach((data) => folios.push(data.numero));
    this.props.checked.forEach((data) => clientes.push(parseInt(data.cliente)));
    const notificacionObject = {
      tipo: 0,
      aplicacion: "anticipo",
      motivo: motivo,
      folio: folios,
    };
    if (motivo === "devolver" && this.props.on_cliente === false)
      notificacionObject["cliente"] = clientes;
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  toogleConso = () => {
    this.setState((prevState) => ({
      toogle_consolida: !prevState.toogle_consolida,
      toogle_devuelvo: false,
      toogle_cancelar: false,
    }));
  };
  toogleDev = () => {
    this.setState((prevState) => ({
      toogle_devuelvo: !prevState.toogle_devuelvo,
      toogle_consolida: false,
      toogle_cancelar: false,
    }));
  };
  toogleCan = () => {
    this.setState((prevState) => ({
      toogle_cancelar: !prevState.toogle_cancelar,
      toogle_consolida: false,
      toogle_devuelvo: false,
    }));
  };
  handleCerrar = () => {
    this.setState({
      toogle_consolida: false,
      toogle_devuelvo: false,
      toogle_cancelar: false,
    });
  };
  handleConsolida = () => {
    message
      .message_confirmacion_only_password(
        "¿Desea continuar con la operación consolidación?",
        "Ingrese su contraseña"
      )
      .then((res) => {
        if (res === true) {
          let mov = {
            user: document.getElementById("usuario_modificador").value,
            pass: document.getElementById("contraseña_modificador").value,
          };
          axios
            .post(utility.url_verify, { usuario: mov.user, password: mov.pass })
            .then((response) => {
              if (response.status >= 200 && response.status < 400) {
                let numeros = utility.obtener_numeros_consolidacion(
                  this.props.checked
                );
                const modifica_POST = () => {
                  return axios.post(utility.url_consolida, {
                    numeros: numeros,
                    observaciones: "Se consolida",
                  });
                };
                this.sendNotificacionHandler("consolidar");
                axios
                  .all([modifica_POST()])
                  .then(
                    axios.spread((post) => {
                      //this.methodPDF_API(utility.url_pdf_poliza, 'consolidacion', numeros[0]);
                      this.props.toogleDevCon();
                      swal({
                        icon: "success",
                        title: post.data.title,
                        text: post.data.text,
                        button: true,
                        content: (
                          <div>
                            <LinkD
                              href=""
                              id="dowload_pdf_consolidacion_anticipo"
                              download={`Documento ${post.data.results[0]}`}
                            />
                            <br />
                            <LinkV
                              href=""
                              id="window_pdf_consolidacion_anticipo"
                              target="_blank"
                            />
                          </div>
                        ),
                      });
                      this.props.update_con_dev(1);
                    })
                  )
                  .catch((error) => {
                    if (error.response)
                      message.message_modal(
                        error.response.data.title,
                        error.response.data.text,
                        error.response.status
                      );
                  });
              }
            })
            .catch((error) => {
              if (error.response)
                message.message_modal(
                  error.response.data.title,
                  error.response.data.text,
                  error.response.status
                );
            });
        }
      });
  };
  handleDevolver = () => {
    message
      .message_confirmacion_password(
        "¿Desea continuar con la operación devolución?",
        "Ingrese su contraseña y observaciones"
      )
      .then((res) => {
        if (res === true) {
          let mov = {
            user: document.getElementById("usuario_modificador").value,
            pass: document.getElementById("contraseña_modificador").value,
            motivo: document.getElementById("observaciones_textarea").value,
          };
          if (mov.motivo.length === 0)
            swal("Completar el campo:", "Motivo de Notificación", "warning");
          else
            axios
              .post(utility.url_verify, {
                usuario: mov.user,
                password: mov.pass,
              })
              .then((response) => {
                if (response.status >= 200 && response.status < 400) {
                  let numeros = utility.obtener_numeros_consolidacion(
                    this.props.checked
                  );
                  const modifica_POST = () => {
                    return axios.post(utility.url_devuelve, {
                      numeros: numeros,
                      observaciones: mov.motivo,
                    });
                  };
                  axios
                    .all([modifica_POST()])
                    .then(
                      axios.spread((post) => {
                        this.setState({}, () => {
                          this.sendNotificacionHandler("devolver");
                          this.methodPDF_API(
                            utility.url_pdf_devolucion,
                            "devolucion",
                            numeros
                          );
                        });
                        this.props.toogleDevCon();
                        if (numeros.length > 1) {
                          swal({
                            icon: "success",
                            title: post.data.title,
                            text: post.data.text,
                            button: true,
                            content: (
                              <div>
                                <LinkD
                                  href=""
                                  id="dowload_pdf_devolucion_anticipo"
                                  download="Anticipos devueltos"
                                />
                                <br />
                              </div>
                            ),
                          });
                          this.props.update_con_dev(1);
                        } else {
                          swal({
                            icon: "success",
                            title: post.data.title,
                            text: post.data.text,
                            button: true,
                            content: (
                              <div>
                                <LinkD
                                  href=""
                                  id="dowload_pdf_devolucion_anticipo"
                                  download={`Documento ${post.data.results[0]}`}
                                />
                                <br />
                                <LinkV
                                  href=""
                                  id="window_pdf_devolucion_anticipo"
                                  target="_blank"
                                />
                              </div>
                            ),
                          });
                          this.props.update_con_dev(1);
                        }
                      })
                    )
                    .catch((error) => {
                      if (error.response)
                        message.message_modal(
                          error.response.data.title,
                          error.response.data.text,
                          error.response.status
                        );
                    });
                }
              })
              .catch((error) => {
                if (error.response)
                  message.message_modal(
                    error.response.data.title,
                    error.response.data.text,
                    error.response.status
                  );
              });
        }
      });
  };
  handleCancelar = () => {
    message
      .message_confirmacion_password(
        "¿Desea continuar con la operación Cancelar?",
        "Ingrese su contraseña"
      )
      .then((res) => {
        if (res === true) {
          let mov = {
            user: document.getElementById("usuario_modificador").value,
            pass: document.getElementById("contraseña_modificador").value,
            motivo: document.getElementById("observaciones_textarea").value,
          };
          if (mov.motivo.length === 0)
            swal("Completar el campo:", "Motivo de Notificación", "warning");
          else
            axios
              .post(utility.url_verify, {
                usuario: mov.user,
                password: mov.pass,
              })
              .then((response) => {
                if (response.status >= 200 && response.status < 400) {
                  let numeros = utility.obtener_numeros_consolidacion(
                    this.props.checked
                  );
                  const modifica_POST = () => {
                    return axios.post(utility.url_devuelve, {
                      numeros: numeros,
                      observaciones: mov.motivo,
                    });
                  };
                  axios
                    .all([modifica_POST()])
                    .then(
                      axios.spread((post) => {
                        this.setState({}, () => {
                          this.sendNotificacionHandler("devolver");
                          this.methodPDF_API(
                            utility.url_pdf_devolucion,
                            "devolucion",
                            numeros
                          );
                        });
                        this.props.toogleDevCon();
                        if (numeros.length > 1) {
                          swal({
                            icon: "success",
                            title: post.data.title,
                            text: post.data.text,
                            button: true,
                            content: (
                              <div>
                                <LinkD
                                  href=""
                                  id="dowload_pdf_devolucion_anticipo"
                                  download="Anticipos devueltos"
                                />
                                <br />
                              </div>
                            ),
                          });
                          this.props.update_con_dev(1);
                        } else {
                          swal({
                            icon: "success",
                            title: post.data.title,
                            text: post.data.text,
                            button: true,
                            content: (
                              <div>
                                <LinkD
                                  href=""
                                  id="dowload_pdf_devolucion_anticipo"
                                  download={`Documento ${post.data.results[0]}`}
                                />
                                <br />
                                <LinkV
                                  href=""
                                  id="window_pdf_devolucion_anticipo"
                                  target="_blank"
                                />
                              </div>
                            ),
                          });
                          this.props.update_con_dev(1);
                        }
                      })
                    )
                    .catch((error) => {
                      if (error.response)
                        message.message_modal(
                          error.response.data.title,
                          error.response.data.text,
                          error.response.status
                        );
                    });
                }
              })
              .catch((error) => {
                if (error.response)
                  message.message_modal(
                    error.response.data.title,
                    error.response.data.text,
                    error.response.status
                  );
              });
        }
      });
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consolidar":
              this.props.toogleDevCon();
              break;
            case "devolver":
              this.props.toogleDevCon();
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPDF_API = (url, state, num) => {
    let numeros = "" + num.map((numero) => numero);
    let url_intern = url + `${numeros}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "devolucion":
              if (num.length > 1) {
                const file = new Blob([response.data], {
                  type: "application/zip",
                });
                const fileURL = URL.createObjectURL(file);
                document.getElementById(`dowload_pdf_${state}_anticipo`).href =
                  fileURL;
              } else {
                const file = new Blob([response.data], {
                  type: "application/pdf",
                });
                const fileURL = URL.createObjectURL(file);
                document.getElementById(`dowload_pdf_${state}_anticipo`).href =
                  fileURL;
                document.getElementById(`window_pdf_${state}_anticipo`).href =
                  fileURL;
              }
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_dev_con}
        toggle={() => { this.props.toogleDevCon(); this.handleCerrar(); }}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={() => { this.props.toogleDevCon(); this.handleCerrar(); }}>Operaciones</ModalHeader>
        <ModalBody>
          <div className="row mb-2 d-flex justify-content-center">
            <div className="col-lg-10 col-12 d-flex justify-content-around">
              <Button
                color="primary"
                onClick={this.toogleConso}
                disabled={permisos.obtener_permiso_especifico(
                  "consolidar",
                  this.props.permisos
                )}
              >
                Consolidar
              </Button>
              <Button
                color="info"
                onClick={this.toogleDev}
                disabled={permisos.obtener_permiso_especifico(
                  "modificar",
                  this.props.permisos
                )}
              >
                Devolver
              </Button>
              <Button
                color="danger"
                onClick={this.toogleCan}
                disabled={permisos.obtener_permiso_especifico(
                  "modificar",
                  this.props.permisos
                )}
              >
                Cancelar
              </Button>
            </div>
          </div>
          <Collapse isOpen={this.state.toogle_consolida}>
            <Card outline color="primary">
              <CardBody>
                {this.props.checked.length === 0 ? (
                  <Alert color="warning" className="text-center mt-3">
                    <h2>Debe selecionar algún Anticipo.</h2>
                  </Alert>
                ) : utility.valida_consolidacion(this.props.checked) ? (
                  <React.Fragment>
                    <div className="container fluid">
                      <div className="row">
                        <MyInput
                          type="date"
                          bootstrap="col-lg-12"
                          nameLabel="Fecha Actual"
                          value={utility.get_fecha_actual().fecha}
                          disabled
                        />
                      </div>
                    </div>
                    <Table size="sm" id="" bordered hover responsive>
                      <thead className="text-center header-table">
                        <tr>
                          <th>Cliente</th>
                          <th>Nombre</th>
                          <th>No. Pago a Tercero</th>
                          <th>Banco</th>
                          <th>Fecha Alta</th>
                          <th>Fecha Vencimiento</th>
                          <th>Importe</th>
                          <th>Vigencia</th>
                        </tr>
                      </thead>
                      <tbody className="body-table">
                        {this.props.checked.map((ant) => (
                          <tr key={ant.numero}>
                            <td className="text-center">{ant.cliente}</td>
                            <td className="text-center">
                              {utility.get_name_cliente(
                                ant.cliente,
                                this.props.clientes
                              )}
                            </td>
                            <td className="text-center">{ant.numero}</td>
                            <td className="text-center">{ant.banco}</td>
                            <td className="text-center">{ant.fecha}</td>
                            <td className="text-center">
                              {ant.fecha_vencimiento}
                            </td>
                            <td className="text-center">$ {ant.importe}</td>
                            <td>
                              {utility.dif_fechas(ant.fecha_vencimiento) >=
                              22 ? (
                                <p className="mb-0 text-center background-green">
                                  {utility.dif_fechas(ant.fecha_vencimiento)}
                                </p>
                              ) : utility.dif_fechas(ant.fecha_vencimiento) >=
                                  11 &&
                                utility.dif_fechas(ant.fecha_vencimiento) <
                                  22 ? (
                                <p className="mb-0 text-center background-yellow">
                                  {utility.dif_fechas(ant.fecha_vencimiento)}
                                </p>
                              ) : utility.dif_fechas(ant.fecha_vencimiento) >=
                                  1 &&
                                utility.dif_fechas(ant.fecha_vencimiento) <
                                  11 ? (
                                <p className="mb-0 text-center background-red">
                                  {utility.dif_fechas(ant.fecha_vencimiento)}
                                </p>
                              ) : (
                                <p
                                  className="mb-0 text-center"
                                  style={{ background: "#000", color: "#fff" }}
                                >
                                  {utility.dif_fechas(ant.fecha_vencimiento)}
                                </p>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                      <Button
                        type="button"
                        color="success"
                        className="mr-2"
                        onClick={this.handleConsolida}
                      >
                        Consolidar
                      </Button>
                      <Button
                        type="button"
                        color="primary"
                        className="mr-3"
                        onClick={() => { this.props.toogleDevCon(); this.handleCerrar(); }}
                      >
                        Cerrar
                      </Button>
                    </FormGroup>
                  </React.Fragment>
                ) : (
                  <Alert color="danger" className="text-center mt-3">
                    <h2>
                      Debe seleccionar Pagos a Terceros que no hayan sido
                      consolidados.
                    </h2>
                  </Alert>
                )}
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_devuelvo}>
            <Card outline color="info">
              <CardBody>
                {this.props.checked.length === 0 ? (
                  <Alert color="warning" className="text-center mt-3">
                    <h2>Debe selecionar algún Pago a Tercero.</h2>
                  </Alert>
                ) : utility.valida_devolucion(this.props.checked) ? (
                  <React.Fragment>
                    <div className="container fluid">
                      <div className="row">
                        <MyInput
                          type="date"
                          bootstrap="col-lg-12"
                          nameLabel="Fecha Actual"
                          value={utility.get_fecha_actual().fecha}
                          disabled
                        />
                      </div>
                    </div>

                    <Table size="sm" id="" bordered hover responsive>
                      <thead className="text-center header-table">
                        <tr>
                          <th>Cliente</th>
                          <th>Nombre</th>
                          <th>No. Pago a Tercero</th>
                          <th>Banco</th>
                          <th>Fecha Alta</th>
                          <th>Fecha Vencimiento</th>
                          <th>Importe</th>
                          <th>Vigencia</th>
                        </tr>
                      </thead>
                      <tbody className="body-table">
                        {this.props.checked.map((ant) => (
                          <tr key={ant.numero}>
                            <td className="text-center">{ant.cliente}</td>
                            <td className="text-center">
                              {utility.get_name_cliente(
                                ant.cliente,
                                this.props.clientes
                              )}
                            </td>
                            <td className="text-center">{ant.numero}</td>
                            <td className="text-center">{ant.banco}</td>
                            <td className="text-center">{ant.fecha}</td>
                            <td className="text-center">
                              {ant.fecha_vencimiento}
                            </td>
                            <td className="text-center">$ {ant.importe}</td>
                            <td>
                              {utility.dif_fechas(ant.fecha_vencimiento) >=
                              22 ? (
                                <p className="mb-0 text-center background-green">
                                  {utility.dif_fechas(ant.fecha_vencimiento)}
                                </p>
                              ) : utility.dif_fechas(ant.fecha_vencimiento) >=
                                  11 &&
                                utility.dif_fechas(ant.fecha_vencimiento) <
                                  22 ? (
                                <p className="mb-0 text-center background-yellow">
                                  {utility.dif_fechas(ant.fecha_vencimiento)}
                                </p>
                              ) : utility.dif_fechas(ant.fecha_vencimiento) >=
                                  1 &&
                                utility.dif_fechas(ant.fecha_vencimiento) <
                                  11 ? (
                                <p className="mb-0 text-center background-red">
                                  {utility.dif_fechas(ant.fecha_vencimiento)}
                                </p>
                              ) : (
                                <p
                                  className="mb-0 text-center"
                                  style={{ background: "#000", color: "#fff" }}
                                >
                                  {utility.dif_fechas(ant.fecha_vencimiento)}
                                </p>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                      <Button
                        type="button"
                        color="success"
                        className="mr-2"
                        onClick={this.handleDevolver}
                      >
                        Devolver
                      </Button>
                      <Button
                        type="button"
                        color="primary"
                        className="mr-3"
                        onClick={() => { this.props.toogleDevCon(); this.handleCerrar(); }}
                      >
                        Cerrar
                      </Button>
                    </FormGroup>
                  </React.Fragment>
                ) : (
                  <Alert color="danger" className="text-center mt-3">
                    <h2>
                      Debe selecionar solamente Pagos a Terceros previamente
                      consolidados.
                    </h2>
                  </Alert>
                )}
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_cancelar}>
            <Card outline color="danger">
              <CardBody>
                {this.props.checked.length === 0 ? (
                  <Alert color="warning" className="text-center mt-3">
                    <h2>Debe selecionar algún Pago a Tercero.</h2>
                  </Alert>
                ) : utility.valida_cancelar(this.props.checked) ? (
                  <React.Fragment>
                    <div className="container fluid">
                      <div className="row">
                        <MyInput
                          type="date"
                          bootstrap="col-lg-12"
                          nameLabel="Fecha Actual"
                          value={utility.get_fecha_actual().fecha}
                          disabled
                        />
                      </div>
                    </div>

                    <Table size="sm" id="" bordered hover responsive>
                      <thead className="text-center header-table">
                        <tr>
                          <th>Cliente</th>
                          <th>Nombre</th>
                          <th>No. Pago a Tercero</th>
                          <th>Banco</th>
                          <th>Fecha Alta</th>
                          <th>Fecha Vencimiento</th>
                          <th>Importe</th>
                          <th>Vigencia</th>
                        </tr>
                      </thead>
                      <tbody className="body-table">
                        {this.props.checked.map((ant) => (
                          <tr key={ant.numero}>
                            <td className="text-center">{ant.cliente}</td>
                            <td className="text-center">
                              {utility.get_name_cliente(
                                ant.cliente,
                                this.props.clientes
                              )}
                            </td>
                            <td className="text-center">{ant.numero}</td>
                            <td className="text-center">{ant.banco}</td>
                            <td className="text-center">{ant.fecha}</td>
                            <td className="text-center">
                              {ant.fecha_vencimiento}
                            </td>
                            <td className="text-center">$ {ant.importe}</td>
                            <td>
                              {utility.dif_fechas(ant.fecha_vencimiento) >=
                              22 ? (
                                <p className="mb-0 text-center background-green">
                                  {utility.dif_fechas(ant.fecha_vencimiento)}
                                </p>
                              ) : utility.dif_fechas(ant.fecha_vencimiento) >=
                                  11 &&
                                utility.dif_fechas(ant.fecha_vencimiento) <
                                  22 ? (
                                <p className="mb-0 text-center background-yellow">
                                  {utility.dif_fechas(ant.fecha_vencimiento)}
                                </p>
                              ) : utility.dif_fechas(ant.fecha_vencimiento) >=
                                  1 &&
                                utility.dif_fechas(ant.fecha_vencimiento) <
                                  11 ? (
                                <p className="mb-0 text-center background-red">
                                  {utility.dif_fechas(ant.fecha_vencimiento)}
                                </p>
                              ) : (
                                <p
                                  className="mb-0 text-center"
                                  style={{ background: "#000", color: "#fff" }}
                                >
                                  {utility.dif_fechas(ant.fecha_vencimiento)}
                                </p>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                      <Button
                        type="button"
                        color="success"
                        className="mr-2"
                        onClick={this.handleCancelar}
                      >
                        Cancelar
                      </Button>
                      <Button
                        type="button"
                        color="primary"
                        className="mr-3"
                        onClick={() => { this.props.toogleDevCon(); this.handleCerrar(); }}
                      >
                        Cerrar
                      </Button>
                    </FormGroup>
                  </React.Fragment>
                ) : (
                  <Alert color="danger" className="text-center mt-3">
                    <h2>Debe selecionar Pagos a Terceros a cancelar.</h2>
                  </Alert>
                )}
              </CardBody>
            </Card>
          </Collapse>
        </ModalBody>
      </Modal>
    );
  }
}
export default ModalDevCon;

/**
 * PENDIENTES:
 * 1.- AGREGAR LOGICA PARA DESCARGAR .zip CUANDP HAY VARIOS PDF'S (CONSOLIDACIÓN)
 */
