/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la consulta de un anticipo. 
*/
import { faEye, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faDownload } from "@fortawesome/free-solid-svg-icons";
import swal from "@sweetalert/with-react";
import axios from "axios";
import React, { Component } from "react";
import AutocompleteInput from "../utils/AutocompleteInput";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Collapse,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Table,
  FormText,
  CustomInput,
  Input,
} from "reactstrap";
import CustomSelect from "../../../utils/custom/CustomSelect";
import CustomSelectArray from "../../../utils/custom/CustomSelectArray";
import MyInput from "../../../utils/custom/MyInput";
import MySelect from "../../../utils/custom/MySelect";
import MyTextArea from "../../../utils/custom/MyTextArea";
import * as message from "../../../utils/messages/message";
import * as permisos from "../../../utils/permisos/permisos";
import MyDropzone from "../utils/MyDropzone";
import Dropzone from "../utils/MyDropzone";
import XMLParser from "react-xml-parser";
import * as utility from "../utils/utility";
const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalConsulta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      enable_pdf: false,
      agregarCatalogo: false,
      fraccionData: [],
      numParteArray: [1],
      facIdSeleccionado: null,
      tipoDocumento: "",
      folio: "",
      folioDoc: "",
      numero_select: "",
      nombre_select: "",
      empleado: "",
      pedimento_text: "",
      pedimento_id: "",
      proveedor_text: "",
      proveedor_id: "",
      tipo_text: "Seleccione un tipo de operación",
      tipo_id: "",
      registro_entrada: "",
      guia_principal: "",
      guia_secundaria: "",
      consolidadora_text: "",
      consolidadora_id: "",
      aduana: "",
      patente_text: "Seleccione una patente",
      patente_id: "",
      procedencia_text: "",
      procedencia_id: "",
      numero_pedido: "",
      cantidad: "",
      fecha_alta: "",
      fecha_llegada: "",
      fecha_despacho: "",
      descripcion: "",
      referencia_archivo: "",
      tipo_archivo_text: "Seleccione el tipo de archivo",
      tipo_archivo_factura: "Seleccione el tipo de archivo",
      tipo_archivo_id: "",
      num_operacion: "",
      observaciones: "",
      referencia: "",
      procedencia: "",
      consolidadora: "",

      toogle_archivos: false,
      toogle_factura: false,
      toogle_consulta: false,
      toogle_previo: false,
      enable_toogle_archvos: true, //Habilitar Carga de archivos
      enable_toogle_previo: true,
      enable_toogle_factura: true,
      phxcdcList: [],

      url_pdf: ["", "", "", "", "", "", "", "", "", "", "", "", ""],
      tipo_documentos_data: [
        "guia",
        "anexo_guia",
        "hoja_calculo",
        "pedimento",
        "anexo_pedimento",
        "manifestacion_valor",
        "cove",
        "doda",
        "e_documento",
        "previo",
        "declaracion_valor",
        "carta_porte",
        "carta_encomienda",
      ],

      tipo_documentos_previo: ["guia", "previo"],

      factura_data: ["factura"],

      archivo_select: [],
      archivo_factura: [],
      archivoFactura: [],
      archivoFacturaC: [],
      expediente: [],
      archivos: [],
      nombre_suggestions: [],
      patentes_select: [],
      tipo_operacion: [
        { clave: 0, nombre: "Importación" },
        { clave: 1, nombre: "Exportación" },
      ],

      subir_imagen: false,

      tipo_documentos: [
        { name: "Guia", existe: false },
        { name: "Anexo Guia", existe: false },
        { name: "Hoja de Cálculo", existe: false },
        { name: "Pedimento", existe: false },
        { name: "Anexo Pedimento", existe: false },
        { name: "Manifestación de Valor", existe: false },
        { name: "COVE", existe: false },
        { name: "DODA", existe: false },
        { name: "e-Documentos", existe: false },
        { name: "Previo", existe: false },
        { name: "Declaración de Valor", existe: false },
        { name: "Carta Porte", existe: false },
        { name: "Carta Encomienda", existe: false },
      ],
      tipo_documentosPrevio: [
        { name: "Cove", existe: false },
        { name: "Previo", existe: false },
      ],
      tipo_archivo_options: [
        { clave: "guia", nombre: "Guia" },
        { clave: "anexo_guia", nombre: "Anexo de Guia" },
        { clave: "hoja_calculo", nombre: "Hoja de Calculo" },
        { clave: "pedimento", nombre: "Pedimento" },
        { clave: "anexo_pedimento", nombre: "Anexo de Pedimento" },
        { clave: "manifestacion_valor", nombre: "Manifestación de Valor" },
        { clave: "cove", nombre: "COVE" },
        { clave: "e_documento", nombre: "e-Documentos" },
        { clave: "doda", nombre: "DODA" },
        { clave: "previo", nombre: "Previo" },
        { clave: "declaracion_valor", nombre: "Declaracion Valor" },
        { clave: "carta_porte", nombre: "Carta Porte" },
        { clave: "carta_encomienda", nombre: "Carta Encomienda" },
      ],
      tipoFactura: [{ name: "Factura", existe: false }],
      OptionFactura: [{ clave: "factura", nombre: "Factura" }],
      datosModificados: {},

      archivo_xml: null,
      archivo_pdf: null,
      archivo_valido: false,
      enable_add: true,
      comprobante_campos: {},
      emisor_campos: {},
      receptor_campos: {},
      timbre_campos: {},

      tipo_transaccion_array: [
        { id: "CC", text: "Comprobable del cliente" },
        { id: "CE", text: "Comprobable de la empresa" },
        { id: "PC", text: "Por comprobar del cliente" },
        { id: "PE", text: "Por comprobar de la empresa" },
      ],
      tipo_movimiento_array: [
        { id: 0, nombre: "Transacción" },
        { id: 1, nombre: "Cheque" },
      ],

      tap_factura: "1",

      modalAbierto: false,
      modalAbiertoRUD: false,
      archivoPDF: null,
      archivoZIP: null,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    // Verifica las condiciones y establece el estado del checkbox
    if (
      this.state.numParteArray.length > 0 &&
      this.state.fraccionData.length === 0
    ) {
      this.setState({ agregarCatalogo: true });
    }

    this.methodGET_API(utility.url_phxcc, "phxcdc");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.tipo_archivo_text !== prevState.tipo_archivo_text) {
      this.setState({ archivo_select: [] });
    }

    if (prevState.modalAbierto && !this.state.modalAbierto) {
      this.setState({ archivo_factura: [], tipoDocumento: "", folioDoc: "" });
    }
  }

  toogleArchivos = () => {
    this.setState((prevState) => ({
      toogle_archivos: !prevState.toogle_archivos,
      toogle_consulta: false,
      toogle_factura: false,
      toogle_previo: false,
    }));
  };
  tooglePrevio = () => {
    this.setState((prevState) => ({
      toogle_previo: !prevState.toogle_previo,
      toogle_consulta: false,
      toogle_factura: false,
      toogle_archivos: false,
    }));
  };
  toogleFactura = () => {
    this.setState((prevState) => ({
      toogle_factura: !prevState.toogle_factura,
      toogle_consulta: false,
      toogle_archivos: false,
      toogle_previo: false,
    }));
  };
  toogleConsulta = () => {
    this.setState((prevState) => ({
      toogle_consulta: !prevState.toogle_consulta,
      toogle_archivos: false,
      toogle_factura: false,
      toogle_previo: false,
    }));
  };
  toggleModal = (id) => {
    this.setState((prevState) => ({
      modalAbierto: !prevState.modalAbierto,
      facIdSeleccionado: id,
    }));
    this.setState({ tipo_archivo_id: "factura" });
  };

  toggleModalRUD = (id) => {
    this.setState((prevState) => ({
      modalAbiertoRUD: !prevState.modalAbiertoRUD,
      facIdSeleccionado: id,
    }));
    this.setState({ tipo_archivo_id: "factura" });
    if (this.state.modalAbiertoRUD === false) {
      this.cargarArchivoPDF(id);
      this.cargarArchivoZIP(id);
    }
  };

  cargarArchivoPDF = async (id) => {
    try {
      const response = await axios.get(`${utility.url_factura}/${id}/1`, {
        responseType: "arraybuffer",
      });

      const contentType = response.headers["content-type"];

      if (contentType === "application/pdf") {
        const archivoPDF = Buffer.from(response.data, "binary").toString(
          "base64"
        );
        this.setState({ archivoPDF });
      } else {
        console.error("No se recibió un archivo PDF:", contentType);
      }
    } catch (error) {
      console.error("Error al cargar el archivo PDF:", error);
    }
  };

  cargarArchivoZIP = async (id) => {
    try {
      const response = await axios.get(`${utility.url_desc_factura}/${id}/1`, {
        responseType: "arraybuffer",
      });

      const contentType = response.headers["content-type"];

      if (contentType === "application/zip") {
        const archivoZIP = Buffer.from(response.data, "binary").toString(
          "base64"
        );
        this.setState({ archivoZIP });
      } else {
        console.error("No se recibió un archivo ZIP:", contentType);
      }
    } catch (error) {
      console.error("Error al cargar el archivo ZIP:", error);
    }
  };

  /* ------ Bloque de funciones que nos permitiran obtener el texto en los inputs ------ */
  handleTipoDoc = (event) => {
    const tipoDocumento = event.target.value;
    this.setState({ tipoDocumento });
  };

  handleReferencia = (e) => {
    let referencia = e.target.value;
    this.setState({ referencia });
  };
  handleEmpleado = (e) => {
    let empleado = e.target.value;
    this.setState({ empleado });
  };
  handleProveedor = (option) => {
    const proveedor_text = option.nombre;
    const proveedor_id = option.numero;
    this.setState({ proveedor_text, proveedor_id });
  };
  getProveedor = (numero) => {
    const numeroString = numero.toString();
    return this.props.proveedores.find((item) => item.numero === numeroString);
  };
  handlePedimento = (e) => {
    let pedimento_text = e.target.value;
    this.setState({ pedimento_text });
  };

  handleTipo = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_text = e[label];
    let tipo_id = e[label_id];
    this.setState({ tipo_text, tipo_id });
  };
  handleRegistro_entrada = (e) => {
    let registro_entrada = e.target.value;
    this.setState({ registro_entrada });
  };
  handleGuia_principal = (e) => {
    let guia_principal = e.target.value;
    this.setState({ guia_principal });
  };
  handleNum_operacion = (e) => {
    let num_operacion = e.target.value;
    this.setState({ num_operacion });
  };
  handleGuia_secundaria = (e) => {
    let guia_secundaria = e.target.value;
    this.setState({ guia_secundaria });
  };
  handleConsolidadora = (option) => {
    const consolidadora_text = option.nombre;
    const consolidadora_id = option.numero;
    this.setState({
      consolidadora_text,
      consolidadora_id,
    });
  };
  getConsolidadora = (numero) => {
    const numeroString = numero.toString();
    return this.props.consolidadoras.find(
      (item) => item.numero === numeroString
    );
  };
  handleAduana = (e) => {
    let aduana = e.target.value;
    let patentes_select = this.state.patentes_select;
    for (let i = 0; i < this.props.patentes.length; i++)
      if (this.props.patentes[i].aduana === aduana)
        patentes_select.push(this.props.patentes[i]);
    this.setState({ aduana, patentes_select });
  };
  handlePatente = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let patente_text = e[label];
    let patente_id = e[label_id];
    this.setState({ patente_text, patente_id });
  };
  handleProcedencia = (option) => {
    const procedencia_text = option.nombre;
    const procedencia_id = option.clave;
    this.setState({ procedencia_text, procedencia_id });
  };
  getProveedor = (clave) => {
    const numeroString = clave.toString();
    return this.props.paises.find((item) => item.clave === numeroString);
  };
  handleNumero_pedido = (e) => {
    let numero_pedido = e.target.value;
    this.setState({ numero_pedido });
  };
  handleClase = (e) => {
    let clase = e.target.value;
    this.setState({ clase });
  };
  handleCodigo_fase = (e) => {
    let codigo_fase = e.target.value;
    this.setState({ codigo_fase });
  };
  handlePeso_bruto = (e) => {
    let peso_bruto = e.target.value;
    this.setState({ peso_bruto });
  };
  handleCantidad = (e) => {
    let cantidad = e.target.value;
    this.setState({ cantidad });
  };
  handleFecha_alta = (e) => {
    let fecha_alta = e.target.value;
    this.setState({ fecha_alta });
  };
  handleFecha_llegada = (e) => {
    let fecha_llegada = e.target.value;
    this.setState({ fecha_llegada });
  };
  handleFecha_despacho = (e) => {
    let fecha_despacho = e.target.value;
    this.setState({ fecha_despacho });
  };
  handleDescripcion = (e) => {
    let descripcion = e.target.value;
    this.setState({ descripcion });
  };
  handleFraccionChange = (event, index) => {
    const nuevaFraccion = event.target.value;

    // Actualiza el estado fraccionData en la posición correspondiente
    this.setState((prevState) => {
      const nuevosDatos = [...prevState.fraccionData];
      nuevosDatos[index] = nuevaFraccion;
      return { fraccionData: nuevosDatos };
    });
  };

  handleChangeFolioDoc = (event) => {
    this.setState({ folioDoc: event.target.value });
  };
  /* ************************** Funciones que interactuan con el back ************************ */
  handleLimpiar = () => {
    this.setState({
      enable_pdf: false,
      agregarCatalogo: false,
      fraccionData: [],
      numParteArray: [1],
      facIdSeleccionado: null,
      tipoDocumento: "",
      folio: "",
      folioDoc: "",
      numero_select: "",
      nombre_select: "",
      empleado: "",
      pedimento_text: "",
      pedimento_id: "",
      proveedor_text: "",
      proveedor_id: "",
      tipo_text: "Seleccione un tipo de operación",
      tipo_id: "",
      registro_entrada: "",
      guia_principal: "",
      guia_secundaria: "",
      consolidadora_text: "",
      consolidadora_id: "",
      aduana: "",
      patente_text: "Seleccione una patente",
      patente_id: "",
      procedencia_text: "",
      procedencia_id: "",
      numero_pedido: "",
      cantidad: "",
      fecha_alta: "",
      fecha_llegada: "",
      fecha_despacho: "",
      descripcion: "",
      referencia_archivo: "",
      tipo_archivo_text: "Seleccione el tipo de archivo",
      tipo_archivo_factura: "Seleccione el tipo de archivo",
      tipo_archivo_id: "",
      num_operacion: "",
      observaciones: "",
      referencia: "",
      procedencia: "",
      consolidadora: "",

      toogle_archivos: false,
      toogle_factura: false,
      toogle_consulta: false,
      toogle_previo: false,
      enable_toogle_archvos: true, //Habilitar Carga de archivos
      enable_toogle_previo: true,
      enable_toogle_factura: true,
      phxcdcList: [],

      url_pdf: ["", "", "", "", "", "", "", "", "", "", "", "", ""],
      tipo_documentos_data: [
        "guia",
        "anexo_guia",
        "hoja_calculo",
        "pedimento",
        "anexo_pedimento",
        "manifestacion_valor",
        "cove",
        "doda",
        "e_documento",
        "previo",
        "declaracion_valor",
        "carta_porte",
        "carta_encomienda",
      ],

      tipo_documentos_previo: ["guia", "previo"],

      factura_data: ["factura"],

      archivo_select: [],
      archivo_factura: [],
      archivoFactura: [],
      archivoFacturaC: [],
      expediente: [],
      archivos: [],
      nombre_suggestions: [],
      patentes_select: [],
      tipo_operacion: [
        { clave: 0, nombre: "Importación" },
        { clave: 1, nombre: "Exportación" },
      ],

      subir_imagen: false,

      tipo_documentos: [
        { name: "Guia", existe: false },
        { name: "Anexo Guia", existe: false },
        { name: "Hoja de Cálculo", existe: false },
        { name: "Pedimento", existe: false },
        { name: "Anexo Pedimento", existe: false },
        { name: "Manifestación de Valor", existe: false },
        { name: "COVE", existe: false },
        { name: "DODA", existe: false },
        { name: "e-Documentos", existe: false },
        { name: "Previo", existe: false },
        { name: "Declaración de Valor", existe: false },
        { name: "Carta Porte", existe: false },
        { name: "Carta Encomienda", existe: false },
      ],
      tipo_documentosPrevio: [
        { name: "Cove", existe: false },
        { name: "Previo", existe: false },
      ],
      tipo_archivo_options: [
        { clave: "guia", nombre: "Guia" },
        { clave: "anexo_guia", nombre: "Anexo de Guia" },
        { clave: "hoja_calculo", nombre: "Hoja de Calculo" },
        { clave: "pedimento", nombre: "Pedimento" },
        { clave: "anexo_pedimento", nombre: "Anexo de Pedimento" },
        { clave: "manifestacion_valor", nombre: "Manifestación de Valor" },
        { clave: "cove", nombre: "COVE" },
        { clave: "e_documento", nombre: "e-Documentos" },
        { clave: "doda", nombre: "DODA" },
        { clave: "previo", nombre: "Previo" },
        { clave: "declaracion_valor", nombre: "Declaracion Valor" },
        { clave: "carta_porte", nombre: "Carta Porte" },
        { clave: "carta_encomienda", nombre: "Carta Encomienda" },
      ],
      tipoFactura: [{ name: "Factura", existe: false }],
      OptionFactura: [{ clave: "factura", nombre: "Factura" }],
      datosModificados: {},

      archivo_xml: null,
      archivo_pdf: null,
      archivo_valido: false,
      enable_add: true,
      comprobante_campos: {},
      emisor_campos: {},
      receptor_campos: {},
      timbre_campos: {},

      tipo_transaccion_array: [
        { id: "CC", text: "Comprobable del cliente" },
        { id: "CE", text: "Comprobable de la empresa" },
        { id: "PC", text: "Por comprobar del cliente" },
        { id: "PE", text: "Por comprobar de la empresa" },
      ],
      tipo_movimiento_array: [
        { id: 0, nombre: "Transacción" },
        { id: 1, nombre: "Cheque" },
      ],

      tap_factura: "1",

      modalAbierto: false,
      modalAbiertoRUD: false,
      archivoPDF: null,
      archivoZIP: null,
    });
  };
  handleCerrar = () => {
    this.handleLimpiar();
    this.props.toogleConsulta();
  };
  handleTipo_archivo = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_archivo_text = e[label];
    let tipo_archivo_id = e[label_id];
    this.setState({ tipo_archivo_text, tipo_archivo_id });
  };
  handleTipoFactura = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_archivo_factura = e[label];
    let tipo_archivo_id = e[label_id];
    this.setState({ tipo_archivo_factura, tipo_archivo_id });
  };
  handleAgregarCatalogoChange = () => {
    this.setState((prevState) => ({
      agregarCatalogo: !prevState.agregarCatalogo,
    }));
  };

  handleArchivo = (file) => {
    let archivos_arr = this.state.archivo_select;

    if (archivos_arr.length >= 10) {
      swal("Advertencia", "No se pueden subir más de 10 archivos.", "warning");
      return;
    }

    let archivo = file;
    if (archivo.type === "text/xml") {
      let arch = new FileReader();
      arch.onload = (event) => {
        this.setState({ archivo_xml: archivo });
        this.handleXMLS(event.target.result);
      };
      arch.readAsText(archivo);
    }
    let archvios_arr = this.state.archivo_select;
    archvios_arr.push(file);
    this.setState({ archivo_select: archvios_arr });
  };

  handleArchivoFactura = (file) => {
    if (this.state.tipoDocumento === "") {
      swal("Advertencia", "Seleccione antes una opción", "warning");
      return;
    }

    if (this.state.archivo_factura.length >= 2) {
      swal(
        "Advertencia",
        "Solo se pueden subir como máximo dos archivos",
        "warning"
      );
      return;
    }

    let archivo = file;

    if (archivo.type === "text/xml") {
      let arch = new FileReader();
      arch.onload = (event) => {
        this.setState({ archivo_xml: archivo });
        if (this.state.tipoDocumento === "N") {
          this.handleXMLS(event.target.result);
        }
      };
      arch.readAsText(archivo);
    }

    // Agregar el archivo a la lista solo si no excede los dos archivos
    this.setState((prevState) => ({
      archivo_factura: [...prevState.archivo_factura, file],
    }));
  };

  handleFolioXML = (xmlContent) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlContent, "text/xml");

    // Encuentra todos los elementos cfdi:Concepto
    const conceptoNodes = xmlDoc.getElementsByTagName("cfdi:Concepto");

    // Busca entre los conceptos para encontrar aquellos con un atributo NoIdentificacion
    let folioDoc = null;
    for (let i = 0; i < conceptoNodes.length; i++) {
      const conceptoNode = conceptoNodes[i];
      const noIdentificacion = conceptoNode.getAttribute("NoIdentificacion");
      if (noIdentificacion) {
        folioDoc = noIdentificacion;
        break;
      }
    }

    // Si se encuentra el elemento, actualiza el estado folioDoc
    if (folioDoc) {
      console.log("NoIdentificacion encontrado:", folioDoc);
      this.setState({ folioDoc: folioDoc });
    } else {
      console.log("No se encontró un nodo con el atributo NoIdentificacion");
    }
  };

  handleEliminaArchivo = (e) => {
    let archivos_arr = this.state.archivo_select;
    archivos_arr.splice(parseInt(e.target.id), 1);
    this.setState({ archivo_select: archivos_arr });
  };
  handleEliminaFactura = (e) => {
    let archivos_arr = this.state.archivo_factura;
    archivos_arr.splice(parseInt(e.target.id), 1);
    this.setState({ archivo_factura: archivos_arr });
  };

  handleSubirArchivos = () => {
    let archivos = this.state.archivos;
    let tipo_archivo = this.state.tipo_archivo_id;
    let documentos = this.state.archivo_select;
    let data_archivos = { tipo_archivo, documentos };
    if (data_archivos.tipo_archivo.length === 0)
      message.message_modal(
        "Advertencia",
        "Debe seleccionar un tipo de documento",
        400
      );
    else {
      archivos.push(data_archivos);
      let data = {
        referencia: this.state.referencia,
        archivos,
      };
      swal({
        title: "Subiendo archivo...",
        buttons: false,
        closeOnClickOutside: false,
        content: (
          <div>
            <Spinner
              color="success"
              style={{
                width: "5rem",
                height: "5rem",
                marginBottom: "26px",
                animation: "1.25s linear infinite spinner-border",
              }}
            >
              Loading...
            </Spinner>
          </div>
        ),
      });
      axios
        .post(
          "/DjangoWeb/ExpedienteApp/cargar/archivo_pdf",
          this.jsonToFormData(data),
          { headers: { "Content-type": "multipart/form-data" } }
        )
        .then((response) => {
          message.message_modal(
            response.data.title,
            response.data.text,
            response.status
          );
          this.setState(
            {
              archivos: [],
              archivo_select: [],
              tipo_archivo: "",
              tipo_archivo_operacion: null,
              subir_imagen: false,
            },
            () => {
              swal.close();
            }
          );
          this.methodPOST_API(utility.url_consulta_operacion, "consulta", {
            referencia: this.state.referencia,
          });
        })
        .catch((error) => {
          if (error.response)
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
        });
    }
  };

  handleSubirFactura = () => {
    let tipo_seleccionado = this.state.tipoDocumento;
    let documentos = this.state.archivo_factura;

    if (tipo_seleccionado.length === 0) {
      message.message_modal(
        "Advertencia",
        "Debe seleccionar un tipo de documento",
        400
      );
      return;
    }

    const facId = this.state.facIdSeleccionado;

    // Verificar si el tipo seleccionado es Nacional (N)
    if (tipo_seleccionado === "N") {
      const tieneXML = documentos.some(
        (archivo) => archivo.type === "text/xml"
      );
      const tienePDF = documentos.some(
        (archivo) => archivo.type === "application/pdf"
      );

      // Verificar si se tienen ambos archivos
      if (tieneXML || tienePDF) {
        // Si se tienen ambos archivos, proceder sin mostrar ninguna advertencia
      } else {
        swal(
          "Advertencia",
          "Debe cargar un archivo XML y un archivo PDF",
          "warning"
        );
        return;
      }
    } else if (tipo_seleccionado === "E") {
      // Si el tipo seleccionado es Extranjero (E)
      const tienePDF = documentos.some(
        (archivo) => archivo.type === "application/pdf"
      );

      if (!tienePDF) {
        swal("Advertencia", "Debe cargar un archivo PDF", "warning");
        return;
      }
    }

    // Crear un FormData manualmente
    const formData = new FormData();
    formData.append("referencia", this.state.referencia);
    formData.append("tipo", "e");
    formData.append("id_pgo", facId);
    formData.append("folio", this.state.folioDoc);

    // Organizar los documentos en el formato esperado
    documentos.forEach((archivo) => {
      if (archivo.type === "text/xml") {
        formData.append("xml", archivo, archivo.name);
      } else if (archivo.type === "application/pdf") {
        formData.append("pdf", archivo, archivo.name);
      }
    });

    this.toggleModal();

    swal({
      title: "Subiendo archivo...",
      buttons: false,
      closeOnClickOutside: false,
      content: (
        <div>
          <Spinner
            color="success"
            style={{
              width: "5rem",
              height: "5rem",
              marginBottom: "26px",
              animation: "1.25s linear infinite spinner-border",
            }}
          >
            Loading...
          </Spinner>
        </div>
      ),
    });

    axios
      .post("/DjangoWeb/FacturasApp/carga/facturas", formData)
      .then((response) => {
        message.message_modal(
          response.data.title,
          response.data.text,
          response.status
        );
        this.setState(
          {
            archivos: [],
            archivo_factura: [],
            tipo_archivo: "",
            tipo_archivo_operacion: null,
            subir_imagen: false,
          },
          () => {
            swal.close();
          }
        );
        this.methodPOST_API(utility.url_consulta_operacion, "consulta", {
          referencia: this.state.referencia,
        });
        this.handleConsultaFactura();
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  buildFormData(formData, data, parentKey) {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;
      formData.append(parentKey, value);
    }
  }
  jsonToFormData(data) {
    const formData = new FormData();
    this.buildFormData(formData, data);
    return formData;
  }
  handleConsulta = () => {
    if (this.state.referencia.length === 0)
      message.message_modal("Advertencia", "Debe tener una Referencia", 400);
    else {
      this.methodPOST_API(utility.url_consulta_operacion, "consulta", {
        referencia: this.state.referencia,
      });
    }
  };

  handleConsultaFactura = () => {
    if (this.state.referencia.length === 0)
      message.message_modal("Advertencia", "Debe tener una Referencia", 400);
    else {
      this.methodPOST_APII(utility.url_pagos_lista, "listapagos", {
        referencia: this.state.referencia,
        status: "L",
      });
      this.methodPOST_APII(utility.url_pagos_lista, "listapagosC", {
        referencia: this.state.referencia,
        status: "C",
      });
    }
  };

  methodPOST_APII = (url, state, data) => {
    this.setState({ archivoFactura: [], archivoFacturaC: [] });

    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "listapagos":
              if (this._isMounted) {
                this.setState({ archivoFactura: response.data.results });
              }
              break;
            case "listapagosC":
              if (this._isMounted) {
                this.setState({ archivoFacturaC: response.data.results });
              }
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        /*  if (error.response)
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            ); */
      });
  };

  handleModificar = () => {
    axios
      .post(utility.url_consulta_operacion, {
        referencia: this.state.referencia,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted) {
            const resNumCliente = response.data.results.cliente;
            /* const resNomCliente = utility.get_name_cliente(response.data.results.cliente,this.props.clientes); */
            const resEmpleado = response.data.results.empleado;
            const proveedor_text = response.data.results.proveedor;
            const registro_entrada = response.data.results.registro_entrada;
            const procedencia = response.data.results.procedencia;
            const numero_pedido = response.data.results.no_pedido;
            const consolidadora = response.data.results.consolidadora;
            const aduana = response.data.results.aduana;
            const patente = response.data.results.patente;
            const num_operacion = response.data.results.num_operacion;
            const guia_principal = response.data.results.guia_principal;
            const guia_secundaria = response.data.results.guia_secundaria;
            const fecha_llegada = response.data.results.fecha_llegada;
            const fecha_despacho = response.data.results.fecha_despacho;
            const descripcion = response.data.results.descripcion;

            const datos = {};

            if (this.state.numero_select != resNumCliente)
              datos.cliente = this.state.numero_select;
            /*   if (this.state.nombre_select != resNomCliente)
            datos.cliente = this.state.numero_select; */
            if (this.state.empleado != resEmpleado)
              datos.empleado = this.state.empleado;
            if (this.state.proveedor_text != proveedor_text)
              datos.proveedor = this.state.proveedor_id;
            //Caso cuando en la consulta tengo importación y hay cambio a exportación
            if (this.state.tipo === 0 && this.state.tipo_text === "Exportación")
              datos.tipo = 1;
            //Caso cuando en la consulta tengo exportación y hay cambio a importación
            if (this.state.tipo === 1 && this.state.tipo_text === "Importación")
              datos.tipo = 0;
            if (this.state.registro_entrada != registro_entrada)
              datos.registro_entrada = this.state.registro_entrada;
            if (this.state.procedencia_text != procedencia)
              datos.procedencia = this.state.procedencia_id;
            if (this.state.numero_pedido != numero_pedido)
              datos.no_pedido = this.state.numero_pedido;
            if (
              this.state.consolidadora_id != "" &&
              this.state.consolidadora_id != consolidadora
            )
              datos.consolidadora = this.state.consolidadora_id;
            if (this.state.aduana != aduana) datos.aduana = this.state.aduana;
            if (this.state.patente != patente)
              datos.patente = this.state.patente;
            if (this.state.num_operacion != num_operacion)
              datos.num_operacion = this.state.num_operacion;
            if (this.state.guia_principal != guia_principal)
              datos.guia_principal = this.state.guia_principal;
            if (this.state.guia_secundaria != guia_secundaria)
              datos.guia_secundaria = this.state.guia_secundaria;
            if (this.state.fecha_llegada != fecha_llegada)
              datos.fecha_llegada = this.state.fecha_llegada;
            if (this.state.fecha_despacho != fecha_despacho)
              datos.fecha_despacho = this.state.fecha_despacho;
            if (this.state.descripcion != descripcion)
              datos.descripcion = this.state.descripcion;

            this.setState({ datosModificados: datos });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });

    message
      .message_confimacion_textarea(
        "¿Desea continuar con la operación modificar?",
        "Ingrese sus observaciones "
      )
      .then((res) => {
        if (res) {
          let observaciones = document.getElementById(
            "observaciones_textarea"
          ).value;
          if (observaciones.length === 0)
            message.message_modal(
              "Completar el campo:",
              "Motivo de Modificación",
              400
            );
          if (Object.entries(this.state.datosModificados).length === 0) {
            message.message_modal(
              "Advertencia",
              "No hay alguna modificación",
              400
            );
          } else {
            const datosEnviar = this.state.datosModificados;
            datosEnviar.referencia = this.state.referencia;
            datosEnviar.observaciones = observaciones;

            this.methodPUT_API_Modifica(
              utility.url_alta_operacion,
              "modifica",
              datosEnviar
            );
          }
        }
      });
  };

  handleAsignarPedimento = () => {
    const datosEnviar = {};

    datosEnviar.pedimento = this.state.pedimento_text;
    datosEnviar.referencia = this.state.referencia;

    this.methodPUT_API_Modifica(
      utility.url_alta_operacion,
      "modifica",
      datosEnviar
    );
  };

  handleElimina_archivo = (nombreArchivo) => {
    const archivoMapping = {
      Guia: "guia",
      "Anexo Guia": "anexo_guia",
      "Hoja de Cálculo": "hoja_calculo",
      Pedimento: "pedimento",
      "Anexo Pedimento": "anexo_pedimento",
      "Manifestación de Valor": "manifestacion_valor",
      COVE: "cove",
      DODA: "doda",
      "e-Documentos": "e_documento",
      Previo: "previo",
      "Declaración de Valor": "declaracion_valor",
      "Carta Porte": "carta_porte",
      "Carta Encomienda": "carta_encomienda",
    };

    const archivoAsignado = archivoMapping[nombreArchivo];
    let data = {
      referencia: this.state.referencia,
      archivo: archivoAsignado,
    };

    message
      .message_confimacion(
        "¿Desea continuar con la operación eliminar archivo?",
        ""
      )
      .then((res) => {
        if (res)
          this.methodPOST_API(utility.url_elimina_pdf, "elimina_archivo", data);
      });
  };

  /**
   * Función que permite hacer una petición de forma Get para colsultar un PDF.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} num Número del documento.
   */
  methodPDF_Operacion = (url, state, num) => {
    let url_intern = url + `${num}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta_operacion":
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);

              document.getElementById("window_pdf_consulta_operacion").href =
                fileURL;

              const linkD = document.getElementById(
                "dowload_pdf_consulta_operacion"
              );
              linkD.href = fileURL;
              linkD.download = `Operacion${this.state.referencia}`;

              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  /* ************************** Funciones que interactuan con la lógica del programa ************************ */
  methodPDF_API = (url, state, campos) => {
    let url_intern = url + `${campos}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          let url_pdf = this.state.url_pdf;
          switch (state) {
            case "guia":
              const guia = new Blob([response.data], {
                type: "application/pdf",
              });
              const guiaURL = URL.createObjectURL(guia);
              url_pdf[0] = guiaURL;
              this.setState({ url_pdf });
              break;
            case "anexo_guia":
              const anexo_guia = new Blob([response.data], {
                type: "application/pdf",
              });
              const anexo_guiaURL = URL.createObjectURL(anexo_guia);
              url_pdf[1] = anexo_guiaURL;
              this.setState({ url_pdf });
              break;
            case "hoja_calculo":
              const hoja_calculo = new Blob([response.data], {
                type: "application/pdf",
              });
              const hoja_calculoURL = URL.createObjectURL(hoja_calculo);
              url_pdf[2] = hoja_calculoURL;
              this.setState({ url_pdf });
              break;
            case "pedimento":
              const pedimento = new Blob([response.data], {
                type: "application/pdf",
              });
              const pedimentoURL = URL.createObjectURL(pedimento);
              url_pdf[3] = pedimentoURL;
              this.setState({ url_pdf });
              break;
            case "anexo_pedimento":
              const anexo_pedimento = new Blob([response.data], {
                type: "application/pdf",
              });
              const anexo_pedimentoURL = URL.createObjectURL(anexo_pedimento);
              url_pdf[4] = anexo_pedimentoURL;
              this.setState({ url_pdf });
              break;
            case "manifestacion_valor":
              const manifestacion_valor = new Blob([response.data], {
                type: "application/pdf",
              });
              const manifestacion_valorURL =
                URL.createObjectURL(manifestacion_valor);
              url_pdf[5] = manifestacion_valorURL;
              this.setState({ url_pdf });
              break;
            /*             case "factura":
              const factura = new Blob([response.data], {
                type: "application/pdf",
              });
              const facturaURL = URL.createObjectURL(factura);
              url_pdf[6] = facturaURL;
              this.setState({ url_pdf });
              break; */
            case "cove":
              const cove = new Blob([response.data], {
                type: "application/pdf",
              });
              const coveURL = URL.createObjectURL(cove);
              url_pdf[6] = coveURL;
              this.setState({ url_pdf });
              break;
            case "doda":
              const doda = new Blob([response.data], {
                type: "application/pdf",
              });
              const dodaURL = URL.createObjectURL(doda);
              url_pdf[7] = dodaURL;
              this.setState({ url_pdf });
              break;
            case "e_documento":
              const e_documento = new Blob([response.data], {
                type: "application/pdf",
              });
              const e_documentoURL = URL.createObjectURL(e_documento);
              url_pdf[8] = e_documentoURL;
              this.setState({ url_pdf });
              break;
            case "previo":
              const previo = new Blob([response.data], {
                type: "application/pdf",
              });
              const previoURL = URL.createObjectURL(previo);
              url_pdf[9] = previoURL;
              this.setState({ url_pdf });
              break;
            case "declaracion_valor":
              const declaracion_valor = new Blob([response.data], {
                type: "application/pdf",
              });
              const declaracion_valorURL =
                URL.createObjectURL(declaracion_valor);
              url_pdf[10] = declaracion_valorURL;
              this.setState({ url_pdf });
              break;
            case "carta_porte":
              const carta_porte = new Blob([response.data], {
                type: "application/pdf",
              });
              const carta_porteURL = URL.createObjectURL(carta_porte);
              url_pdf[11] = carta_porteURL;
              this.setState({ url_pdf });
              break;
            case "carta_encomienda":
              const carta_encomienda = new Blob([response.data], {
                type: "application/pdf",
              });
              const carta_encomiendaURL = URL.createObjectURL(carta_encomienda);
              url_pdf[12] = carta_encomiendaURL;
              this.setState({ url_pdf });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "departamentos":
              if (this._isMounted)
                this.setState({ departamentos: response.data.results });
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "elimina_archivo":
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.methodPOST_API(utility.url_consulta_operacion, "consulta", {
                referencia: this.state.referencia,
              });
              break;
            case "consulta":
              this.setState({ enable_pdf: true }, () => {
                this.methodPDF_Operacion(
                  utility.url_consulta_pdf,
                  "consulta_operacion",
                  this.state.referencia
                );
              });
              let tipo_documentos = this.state.tipo_documentos;
              tipo_documentos.forEach((doc, index) => {
                if (index === 0) doc.existe = response.data.expediente.guias;
                if (index === 1)
                  doc.existe = response.data.expediente.anexos_guias;
                if (index === 2)
                  doc.existe = response.data.expediente.hoja_calculo;
                if (index === 3)
                  doc.existe = response.data.expediente.pedimento;
                if (index === 4)
                  doc.existe = response.data.expediente.anexos_pedimento;
                if (index === 5)
                  doc.existe = response.data.expediente.manifestacion_valor;
                /* if (index === 6) doc.existe = response.data.expediente.facturas; */
                if (index === 6) doc.existe = response.data.expediente.cove;
                if (index === 7) doc.existe = response.data.expediente.doda;
                if (index === 8)
                  doc.existe = response.data.expediente.Edocumento;
                if (index === 9) doc.existe = response.data.expediente.previo;
                if (index === 10)
                  doc.existe = response.data.expediente.declaracion_valor;
                if (index === 11)
                  doc.existe = response.data.expediente.carta_porte;
                if (index === 12)
                  doc.existe = response.data.expediente.carta_encomienda;
              });
              const consolidadora = this.getConsolidadora(
                response.data.results.consolidadora
              );
              this.setState({
                consolidadora_text: consolidadora ? consolidadora.nombre : "",
                consolidadora_id: consolidadora ? consolidadora.numero : "",
              });
              /* const proveedor = this.getProveedor(
                response.data.results.proveedor
              );
              this.setState({
                proveedor_text: proveedor
                  ? proveedor.nombre
                  : ""
              });
              const procedencia = this.getProcedencia(
                response.data.results.procedencia
              );
              this.setState({
                procedencia_text: procedencia
                  ? procedencia.nombre
                  : ""
              }); */
              this.setState(
                {
                  folio: response.data.results.folio,
                  fecha_alta: response.data.results.fecha_alta,
                  fecha_despacho: response.data.results.fecha_despacho,
                  numero_select: response.data.results.cliente,
                  nombre_select: utility.get_name_cliente(
                    response.data.results.cliente,
                    this.props.clientes
                  ),
                  empleado: response.data.results.empleado,
                  tipo: response.data.results.tipo,
                  registro_entrada: response.data.results.registro_entrada,
                  fecha_llegada: response.data.results.fecha_llegada,
                  fecha_entrega: response.data.results.fecha_entrega,
                  guia_principal: response.data.results.guia_principal,
                  guia_secundaria: response.data.results.guia_secundaria,
                  aduana: response.data.results.aduana,
                  // consolidadora: response.data.results.consolidadora,
                  proveedor_text: response.data.results.proveedor,
                  procedencia_text: response.data.results.procedencia,
                  descripcion: response.data.results.descripcion,
                  numero_pedido: response.data.results.no_pedido,
                  clase: response.data.results.clase,
                  peso_bruto: response.data.results.peso_bruto,
                  cantidad: response.data.results.cantidad,
                  factura: response.data.results.factura,
                  codigo_fase: response.data.results.codigo_fase,
                  guia_entrada: response.data.results.guia_entrada,
                  patente: response.data.results.patente,
                  num_operacion: response.data.results.num_operacion,
                  observaciones: response.data.results.observaciones,
                  expediente: response.data.results.expediente,
                  enable_toogle_archvos: false,
                  enable_toogle_previo: false,
                  enable_toogle_factura: false,
                  tipo_documentos,
                },
                () => {
                  if (this.state.tipo === "0") {
                    this.setState({ tipo_text: "Importación" });
                  }
                  if (this.state.tipo === "1") {
                    this.setState({ tipo_text: "Exportación" });
                  }
                  if (this.state.tipo_documentos[0].existe)
                    this.methodPDF_API(
                      utility.url_pdf,
                      "guia",
                      `${this.state.referencia}/guia`
                    );
                  if (this.state.tipo_documentos[1].existe)
                    this.methodPDF_API(
                      utility.url_pdf,
                      "anexo_guia",
                      `${this.state.referencia}/anexo_guia`
                    );
                  if (this.state.tipo_documentos[2].existe)
                    this.methodPDF_API(
                      utility.url_pdf,
                      "hoja_calculo",
                      `${this.state.referencia}/hoja_calculo`
                    );
                  if (this.state.tipo_documentos[3].existe)
                    this.methodPDF_API(
                      utility.url_pdf,
                      "pedimento",
                      `${this.state.referencia}/pedimento`
                    );
                  if (this.state.tipo_documentos[4].existe)
                    this.methodPDF_API(
                      utility.url_pdf,
                      "anexo_pedimento",
                      `${this.state.referencia}/anexo_pedimento`
                    );
                  if (this.state.tipo_documentos[5].existe)
                    this.methodPDF_API(
                      utility.url_pdf,
                      "manifestacion_valor",
                      `${this.state.referencia}/manifestacion_valor`
                    );
                  /* if (this.state.tipo_documentos[6].existe)
                    this.methodPDF_API(
                      utility.url_pdf,
                      "factura",
                      `${this.state.referencia}/factura`
                    ); */
                  if (this.state.tipo_documentos[6].existe)
                    this.methodPDF_API(
                      utility.url_pdf,
                      "cove",
                      `${this.state.referencia}/cove`
                    );
                  if (this.state.tipo_documentos[7].existe)
                    this.methodPDF_API(
                      utility.url_pdf,
                      "doda",
                      `${this.state.referencia}/doda`
                    );
                  if (this.state.tipo_documentos[8].existe)
                    this.methodPDF_API(
                      utility.url_pdf,
                      "e_documento",
                      `${this.state.referencia}/e_documento`
                    );
                  if (this.state.tipo_documentos[9].existe)
                    this.methodPDF_API(
                      utility.url_pdf,
                      "previo",
                      `${this.state.referencia}/previo`
                    );
                  if (this.state.tipo_documentos[10].existe)
                    this.methodPDF_API(
                      utility.url_pdf,
                      "declaracion_valor",
                      `${this.state.referencia}/declaracion_valor`
                    );
                  if (this.state.tipo_documentos[11].existe)
                    this.methodPDF_API(
                      utility.url_pdf,
                      "carta_porte",
                      `${this.state.referencia}/carta_porte`
                    );
                  if (this.state.tipo_documentos[12].existe)
                    this.methodPDF_API(
                      utility.url_pdf,
                      "carta_encomienda",
                      `${this.state.referencia}/carta_encomienda`
                    );
                }
              );

              /* let options = document.getElementById("departamento_consulta_puesto").options;
                            for (let i = 0; i < options.length; i++)
                                if (parseInt(options[i].value) === this.state.id_departamento)
                                    document.getElementById("departamento_consulta_puesto").value = options[i].value; */
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma PUT
   * @param {String} url URL a consultar
   * @param {String} state Onpción del case
   * @param {Object} data Objeto a mandar atraves del metodo PUT
   */
  methodPUT_API_Modifica = (url, state, data) => {
    let url_intern = url;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "modifica":
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
        if (response.status === 400) {
          swal({
            icon: "warning",
            title: response.data.results.mensaje,
            button: true,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          // En caso de error de respuesta
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
        }
      });
  };

  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") text_add = "";
    else if (numero_cliente.length <= 4) {
      switch (numero_cliente.length) {
        case 1:
          text_add = "000";
          text_add += numero_cliente;
          break;
        case 2:
          text_add = "00";
          text_add += numero_cliente;
          break;
        case 3:
          text_add = "0";
          text_add += numero_cliente;
          break;
        case 4:
          text_add = numero_cliente;
          break;
        default:
          return null;
      }
    } else text_add = "";
    for (let i = 0; i < this.props.clientes.length; i++) {
      if (text_add.length === 0) {
        document.getElementById("nombre_cliente_operacion").value = "";
        this.suggestionSelected("");
        break;
      }
      if (text_add === this.props.clientes[i].numero) {
        document.getElementById("nombre_cliente_operacion").value =
          this.props.clientes[i].nombre;
        this.suggestionSelected(this.props.clientes[i].nombre);
        break;
      }
    }
  };
  suggestionSelected = (value) => {
    let number = utility.get_number_cliente(value, this.props.clientes);
    if (number === undefined) number = "";
    document.getElementById("numero_cliente_operacion").value = number;
    this.setState({
      nombre_select: value,
      numero_select: number,
      nombre_suggestions: [],
    });
  };
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_nombres_clientes(this.props.clientes)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      nombre_suggestions: suggestions,
      nombre_select: value,
    });
  };
  renderSuggestion = () => {
    if (this.state.nombre_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.resultsSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */

  handleXMLS = (XMLData) => {
    let comprobante = new XMLParser().parseFromString(XMLData);
    let emisor = comprobante.getElementsByTagName("cfdi:Emisor")[0];
    let receptor = comprobante.getElementsByTagName("cfdi:Receptor")[0];
    let timbre = comprobante.getElementsByTagName("tfd:TimbreFiscalDigital")[0];
    if (
      emisor === undefined ||
      receptor === undefined ||
      timbre === undefined
    ) {
      message.message_modal(
        "Error",
        "La factura no cumple con el formato del SAT",
        500
      );
      this.setState({
        comprobante_campos: {},
        emisor_campos: {},
        receptor_campos: {},
        timbre_campos: {},
        enable_add: true,
        archivo: {},
      });
    } else
      this.setState(
        {
          comprobante_campos: {
            folio: comprobante.attributes.Folio,
            fecha: comprobante.attributes.Fecha,
            forma_de_pago: comprobante.attributes.FormaPago,
            no_certificado: comprobante.attributes.NoCertificado,
            moneda: comprobante.attributes.Moneda,
            total: comprobante.attributes.Total,
            metodo_pago: comprobante.attributes.MetodoPago,
            tipo_comprobante: comprobante.attributes.TipoDeComprobante,
            numero_pago: comprobante.attributes.NumCtaPago,
            certificado: comprobante.attributes.Certificado,
            version: comprobante.attributes.Version,
          },
          emisor_campos: {
            rfc: emisor.attributes.Rfc,
            nombre: emisor.attributes.Nombre,
          },
          receptor_campos: {
            rfc: receptor.attributes.Rfc,
          },
          timbre_campos: {
            version: timbre.attributes.Version,
            uuid: timbre.attributes.UUID,
            fecha_timbrado: timbre.attributes.FechaTimbrado,
            sello_cfd: timbre.attributes.SelloCFD,
            sello_sat: timbre.attributes.SelloSAT,
          },
        },
        () => {
          this.methodPOST_SOAP();
          this.handleFolioXML(XMLData);
        }
      );
  };

  methodPOST_SOAP = () => {
    let sr =
      '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">' +
      "<soapenv:Header/>" +
      "<soapenv:Body>" +
      "<tem:Consulta>" +
      "<!--Optional:-->" +
      `<tem:expresionImpresa><![CDATA[?re=${this.state.emisor_campos.rfc}&rr=${this.state.receptor_campos.rfc}&tt=${this.state.comprobante_campos.total}
                &id=${this.state.timbre_campos.uuid}]]></tem:expresionImpresa>` +
      "</tem:Consulta>" +
      "</soapenv:Body>" +
      "</soapenv:Envelope>";
    swal({
      title: "Validando factura...",
      buttons: false,
      closeOnClickOutside: false,
      content: (
        <div>
          <Spinner
            color="success"
            style={{
              width: "5rem",
              height: "5rem",
              marginBottom: "26px",
              animation: "1.25s linear infinite spinner-border",
            }}
          >
            Loading...
          </Spinner>
        </div>
      ),
    });
    axios
      .post("/ConsultaCFDIService.svc", sr, {
        headers: {
          "Content-type": 'text/xml;charset="utf-8"',
          Accept: "text/xml",
          SOAPAction: "http://tempuri.org/IConsultaCFDIService/Consulta",
          "cache-control": "no-cache",
          Host: "consultaqr.facturaelectronica.sat.gob.mx",
        },
      })
      .then((res) => {
        let comprobante = new XMLParser().parseFromString(res.data);
        let codigo_estatus =
          comprobante.getElementsByTagName("a:CodigoEstatus")[0];
        let estado = comprobante.getElementsByTagName("a:Estado")[0];
        swal.close();
        if (
          codigo_estatus.value ===
          "S - Comprobante obtenido satisfactoriamente."
        )
          if (estado.value === "Vigente") {
            message.message_modal(
              "Factura valida",
              "Puede subir el archivo",
              200
            );
            this.setState({ enable_add: false });
          } else {
            message.message_modal(
              "Factura cancelada",
              "No Puede subir el archivo",
              400
            );
            this.setState({ enable_add: true });
          }
        else {
          message.message_modal(
            "No existe la factura",
            "Seleccione otro archivo",
            500
          );
          this.setState({ enable_add: true });
        }
      })
      .catch((err) => {
        if (err)
          message.message_modal("Ocurrio un error", "Intentelo de nuevo", 500);
      });
  };

  eliminarArchivo = () => {
    const { facIdSeleccionado } = this.state;

    if (!facIdSeleccionado) {
      console.error("No se ha seleccionado ningún ID de factura.");
      return;
    }

    axios
      .post(utility.url_elimina_factura, {
        id_pgo: facIdSeleccionado,
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });

    this.handleConsultaFactura();
  };

  render() {
    const obtNombre = (clave) => {
      const elementoEncontrado = this.props.phxcc.find(
        (item) => item.clave === clave
      );
      if (elementoEncontrado) {
        return elementoEncontrado.nombre;
      } else {
        return `Clave no encontrada en Phxcdc`;
      }
    };

    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>Consulta Operación</ModalHeader>
        <ModalBody>
          <div className="row mb-2 d-flex justify-content-center">
            <div className="col-lg-10 col-12 d-flex justify-content-around">
              <Button color="primary" onClick={this.toogleConsulta}>
                Consulta De Operación
              </Button>
              <Button
                color="info"
                onClick={this.tooglePrevio}
                disabled={this.state.enable_toogle_previo}
              >
                Previo
              </Button>
              <Button
                color="info"
                onClick={this.toogleArchivos}
                disabled={this.state.enable_toogle_archvos}
              >
                Expediente Digital
              </Button>
              <Button
                color="info"
                onClick={this.toogleFactura}
                disabled={this.state.enable_toogle_factura}
              >
                Expediente Factura
              </Button>
            </div>
          </div>
          <Collapse isOpen={this.state.toogle_consulta}>
            <Card outline color="primary">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-10 col-8"
                      id="referencia_operacion"
                      onChange={this.handleReferencia}
                      value={this.state.referencia}
                      nameLabel="Referencia"
                    />
                    <Button
                      color="primary"
                      className="col-lg-2 col-4 mb-2"
                      onClick={this.handleConsulta}
                    >
                      Consulta
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="numero_cliente_operacion"
                      onChange={this.nameGenerator}
                      value={this.state.numero_select}
                      nameLabel="Número de Cliente"
                      /* disabled={true} */
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="nombre_cliente_operacion"
                      onChange={this.onNameChange}
                      value={this.state.nombre_select}
                      nameLabel="Nombre de Cliente"
                      /* disabled={true} */
                    />
                    {this.renderSuggestion()}
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4"
                      id="empleado_operacion"
                      onChange={this.handleEmpleado}
                      value={this.state.empleado}
                      nameLabel="Empleado"
                      /* disabled={true} */
                    />
                    <AutocompleteInput
                      bootstrap="col-lg-4 col-12"
                      name_text="nombre"
                      name_index="nombre_corto"
                      id="proveedor_operacion"
                      nameLabel="Proveedor"
                      value={this.state.proveedor_text}
                      onSelect={this.handleProveedor}
                      options={this.props.proveedores}
                    />
                    <CustomSelect
                      bootstrap="col-lg-4 col-12"
                      name_text="nombre"
                      name_index="clave"
                      id="tipo_operacion"
                      nameLabel="Tipo"
                      value={this.state.tipo_text}
                      onChange={this.handleTipo}
                      options={this.state.tipo_operacion}
                      /* disabled={true} */
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4"
                      id="registro_entrada_operacion"
                      onChange={this.handleRegistro_entrada}
                      value={this.state.registro_entrada}
                      nameLabel="Registro entrada"
                      /* disabled={true} */
                    />
                    <AutocompleteInput
                      bootstrap="col-lg-4 col-12"
                      name_text="nombre"
                      name_index="clave"
                      id="procedencia_operacion"
                      nameLabel="Procedencia"
                      value={this.state.procedencia_text}
                      onSelect={this.handleProcedencia}
                      options={this.props.paises}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4"
                      id="numero_pedido_operacion"
                      onChange={this.handleNumero_pedido}
                      value={this.state.numero_pedido}
                      nameLabel="Número de pedido"
                      /* disabled={true} */
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <AutocompleteInput
                      bootstrap="col-lg-4 col-12"
                      name_text="nombre"
                      name_index="numero"
                      id="consolidadora_operacion"
                      nameLabel="Consolidadora"
                      value={this.state.consolidadora_text}
                      onSelect={this.handleConsolidadora}
                      options={this.props.consolidadoras}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4"
                      id="aduana_operacion"
                      onChange={this.handleAduana}
                      value={this.state.aduana}
                      nameLabel="Aduana"
                      /*  disabled={true} */
                    />
                    <CustomSelect
                      bootstrap="col-lg-4 col-12"
                      name_text="patente"
                      name_index="patente"
                      id="patente_operacion"
                      nameLabel="Patente"
                      value={this.state.patente}
                      onChange={this.handlePatente}
                      options={this.state.patentes_select}
                      /* disabled={true} */
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4"
                      id="num_operacion_principal_operacion"
                      onChange={this.handleNum_operacion}
                      value={this.state.num_operacion}
                      nameLabel="Número de Operación"
                      /* disabled={true} */
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4"
                      id="guia_principal_operacion"
                      /* disabled={true} */
                      onChange={this.handleGuia_principal}
                      value={this.state.guia_principal}
                      nameLabel="Guia principal"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4"
                      id="guia_secundaria_operacion"
                      /*  disabled={true} */
                      onChange={this.handleGuia_secundaria}
                      value={this.state.guia_secundaria}
                      nameLabel="Guia secundaria"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="date"
                      bootstrap="col-lg-6"
                      id="fecha_alta_operacion"
                      /* disabled={true} */
                      onChange={this.handleFecha_llegada}
                      value={this.state.fecha_llegada}
                      nameLabel="Fecha llegada"
                    />
                    <MyInput
                      type="date"
                      bootstrap="col-lg-6"
                      id="fecha_despacho_operacion"
                      /* disabled={true} */
                      onChange={this.handleFecha_despacho}
                      value={this.state.fecha_despacho}
                      nameLabel="Fecha despacho"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      bootstrap="col-12"
                      id="descripcion_operacion"
                      /* disabled={true} */
                      onChange={this.handleDescripcion}
                      value={this.state.descripcion}
                      nameLabel="Descripción"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      bootstrap="col-lg-10 col-12"
                      id="pedimento_operacion"
                      nameLabel="Pedimento"
                      onChange={this.handlePedimento}
                      value={this.state.pedimento_text}
                    />
                    <Button
                      color="success"
                      className="col-lg-2 col-4 mb-2"
                      onClick={this.handleAsignarPedimento}
                    >
                      Asignar
                    </Button>
                    <MyTextArea
                      bootstrap="col-12"
                      id="descripcion_operacion"
                      /* disabled={true} */
                      onChange={this.handleObservacion}
                      value={this.state.observaciones}
                      nameLabel="Observaciones"
                    />
                  </FormGroup>
                  <div className="d-flex justify-content-center mt-3">
                    <LinkD href="" id="dowload_pdf_consulta_operacion">
                      <FontAwesomeIcon icon={faFileDownload} /> Descargar
                    </LinkD>

                    <LinkV
                      href="#"
                      id="window_pdf_consulta_operacion"
                      target={this.state.enable_pdf ? "_blank" : "_self"}
                      className="ml-5"
                    />
                  </div>
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      color="info"
                      className="mr-2"
                      onClick={this.handleModificar}
                      disabled={permisos.obtener_permiso_especifico(
                        "modificar",
                        this.props.permisos
                      )}
                    >
                      Modificar
                    </Button>
                    <Button color="primary" onClick={this.handleCerrar}>
                      Cerrar
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_previo}>
            <Card outline color="info">
              <CardBody>
                {/* <FormGroup className="row mb-0">
                  <MyInput
                    type="text"
                    bootstrap="col-lg-8"
                    id="guia_principal_previo"
                    disabled={true}
                    onChange={this.handleGuia_principal}
                    value={this.state.guia_principal}
                    nameLabel="Guia principal"
                  />
                  <Button
                    color="primary"
                    className="col-lg-4 col-4 mb-2"
                    onClick={this.handleConsultaPrevio}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </FormGroup>
                <FormGroup className="row mb-0">
                  <MyInput
                    type="text"
                    bootstrap="col-lg-4"
                    id="guia_principal_previo"
                    disabled={true}
                    onChange={this.handleFraccion}
                    value={this.state.fraccion}
                    nameLabel="Fracción"
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-4"
                    id="guia_principal_previo"
                    onChange={this.handleNumParte}
                    value={this.state.numParte}
                    nameLabel="Número Parte"
                  />
                  <Button
                    color="primary"
                    className="col-lg-4 col-4 mb-2"
                    onClick={this.handlePrevio}
                  >
                    Consulta
                  </Button>
                </FormGroup>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0 mt-2 mt-lg-0">
                    <MyDropzone
                      handleArchivo={this.handleArchivo}
                      accept="image/*, .pdf, .jpg, .jpeg, .png"
                    />
                    <div className="col-12 my-2 d-flex justify-content-center flex-wrap">
                      {this.state.archivo_select.map((archivo, index) => (
                        <Badge color="light" className="border">
                          {archivo.name}&nbsp;
                          <spam
                            style={{ cursor: "pointer" }}
                            id={index}
                            onClick={this.handleEliminaArchivo}
                          >
                            &times;
                          </spam>
                        </Badge>
                      ))}
                    </div>
                    <Button
                      color="primary"
                      className="col-12"
                      onClick={this.handleSubirArchivos}
                    >
                      Subir Archivos
                    </Button>
                  </FormGroup>
                </Form> */}
                <Card className="mt-2" outline>
                  <CardBody>
                    <FormGroup className="row mb-1 d-flex justify-content-center general_sub">
                      <FormText color="muted">Guía</FormText>
                    </FormGroup>
                    <Table size="sm" className="mt-2" bordered hover responsive>
                      <thead className="text-center header-table">
                        <tr>
                          <td>Tipo de Archivo</td>
                          <td>Descarga</td>
                          <td>Visualiza</td>
                          <td>Elimina</td>
                        </tr>
                      </thead>
                      <tbody className="body-table">
                        {this.state.tipo_documentosPrevio.map((doc, index) => (
                          <tr>
                            <td className="text-center">{doc.name}</td>
                            {doc.existe ? (
                              <React.Fragment>
                                <td className="text-center">
                                  <LinkD
                                    href={this.state.url_pdf[index]}
                                    id={`dowload_pdf_${index}`}
                                    download={doc.name}
                                  />
                                </td>
                                <td className="text-center">
                                  <LinkV
                                    href={this.state.url_pdf[index]}
                                    id={`dowload_pdf_${index}`}
                                    target="_blank"
                                  />
                                </td>
                                <td className="text-center">
                                  <Button
                                    color="danger"
                                    size="sm"
                                    onClick={() =>
                                      this.handleElimina_archivo(doc.name)
                                    }
                                  >
                                    Eliminar
                                  </Button>
                                </td>
                              </React.Fragment>
                            ) : (
                              <td
                                className="text-center"
                                colSpan={doc.existe ? 1 : 3}
                              >
                                No existe registro de archivos
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
                <Card className="mt-2" outline>
                  <CardBody>
                    <FormGroup className="row mb-1 d-flex justify-content-center general_sub">
                      <FormText color="muted">Clasificación</FormText>
                    </FormGroup>
                    <Table size="sm" className="mt-2" bordered hover responsive>
                      <thead className="text-center header-table">
                        <tr>
                          <td>Número Parte</td>
                          <td>Fracción</td>
                          <td>Descarga</td>
                          <td>Visualiza</td>
                          <td>Elimina</td>
                        </tr>
                      </thead>
                      <tbody className="body-table">
                        {this.state.numParteArray.map((doc, index) => (
                          <tr key={index}>
                            <td className="text-center">{doc.name}</td>
                            <td className="text-center">
                              {this.state.fraccionData[index] ? (
                                <input
                                  type="text"
                                  value={this.state.fraccionData[index]}
                                  readOnly
                                  className="form-control"
                                />
                              ) : (
                                <input
                                  type="text"
                                  placeholder="Ingrese la fracción"
                                  onChange={(e) =>
                                    this.handleFraccionChange(e, index)
                                  }
                                  className="form-control"
                                />
                              )}
                            </td>
                            {doc.existe ? (
                              <React.Fragment>
                                <td className="text-center">
                                  <LinkD
                                    href={this.state.url_pdf[index]}
                                    id={`download_pdf_${index}`}
                                    download={doc.name}
                                  />
                                </td>
                                <td className="text-center">
                                  <LinkV
                                    href={this.state.url_pdf[index]}
                                    id={`view_pdf_${index}`}
                                    target="_blank"
                                  />
                                </td>
                                <td className="text-center">
                                  <Button
                                    color="danger"
                                    size="sm"
                                    onClick={() =>
                                      this.handleElimina_archivo(doc.name)
                                    }
                                  >
                                    Eliminar
                                  </Button>
                                </td>
                              </React.Fragment>
                            ) : (
                              <td
                                className="text-center"
                                colSpan={doc.existe ? 1 : 3}
                              >
                                No existe registro de archivos
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {/* <CustomInput
                      type="checkbox"
                      id="agregar_catalogo"
                      label="Agregar Catálogo"
                      checked={this.state.agregarCatalogo}
                      onChange={this.handleAgregarCatalogoChange}
                    /> */}
                  </CardBody>
                </Card>

                <div className="container-fluid">
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button color="primary" onClick={this.handleCerrar}>
                      Cerrar
                    </Button>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_archivos}>
            <Card outline color="info">
              <CardBody>
                <FormGroup className="row mb-0">
                  <MyInput
                    type="text"
                    bootstrap="col-lg-6"
                    id="referencia_archivo_operacion"
                    value={this.state.referencia}
                    nameLabel="Referencia"
                    /* disabled={true} */
                  />
                  <CustomSelect
                    bootstrap="col-lg-6 col-12"
                    name_text="nombre"
                    name_index="clave"
                    id="tipo_archivo_operacion"
                    nameLabel="Tipo de Archivo"
                    value={this.state.tipo_archivo_text}
                    onChange={this.handleTipo_archivo}
                    options={this.state.tipo_archivo_options}
                  />
                </FormGroup>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0 mt-2 mt-lg-0">
                    <MyDropzone
                      handleArchivo={this.handleArchivo}
                      accept="image/*, .pdf, .jpg, .jpeg, .png"
                    />
                    <div className="col-12 my-2 d-flex justify-content-center flex-wrap">
                      {this.state.archivo_select.map((archivo, index) => (
                        <Badge color="light" className="border">
                          {archivo.name}&nbsp;
                          <spam
                            style={{ cursor: "pointer" }}
                            id={index}
                            onClick={this.handleEliminaArchivo}
                          >
                            &times;
                          </spam>
                        </Badge>
                      ))}
                    </div>
                    <Button
                      color="primary"
                      className="col-12"
                      onClick={this.handleSubirArchivos}
                    >
                      Subir Archivos
                    </Button>
                  </FormGroup>
                </Form>
                <Table size="sm" className="mt-2" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>Tipo de Archivo</td>
                      <td>Descarga</td>
                      <td>Visualiza</td>
                      <td>Elimina</td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.tipo_documentos.map((doc, index) => (
                      <tr>
                        <td className="text-center">{doc.name}</td>
                        {doc.existe ? (
                          <React.Fragment>
                            <td className="text-center">
                              <LinkD
                                href={this.state.url_pdf[index]}
                                id={`dowload_pdf_${index}`}
                                download={doc.name}
                              />
                            </td>
                            <td className="text-center">
                              <LinkV
                                href={this.state.url_pdf[index]}
                                id={`dowload_pdf_${index}`}
                                target="_blank"
                              />
                            </td>
                            <td className="text-center">
                              <Button
                                color="danger"
                                size="sm"
                                onClick={() =>
                                  this.handleElimina_archivo(doc.name)
                                }
                              >
                                Eliminar
                              </Button>
                            </td>
                          </React.Fragment>
                        ) : (
                          <td
                            className="text-center"
                            colSpan={doc.existe ? 1 : 3}
                          >
                            No existe registro de archivos
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="container-fluid">
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button color="primary" onClick={this.handleCerrar}>
                      Cerrar
                    </Button>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_factura}>
            <Card outline color="info">
              <CardBody>
                <FormGroup className="row mb-0">
                  <MyInput
                    type="text"
                    bootstrap="col-lg-10 col-8"
                    id="referencia_archivo_operacion"
                    onChange={this.handleReferencia}
                    value={this.state.referencia}
                    nameLabel="Referencia"
                  />
                  <Button
                    color="primary"
                    className="col-lg-2 col-4 mb-2"
                    onClick={this.handleConsultaFactura}
                  >
                    Consulta
                  </Button>
                </FormGroup>
                {/* <Table size="sm" className="mt-2" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>Tipo de Archivo</td>
                      <td>Descarga</td>
                      <td>Visualiza</td>
                      <td>Elimina</td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.tipoFactura.map((doc, index) => (
                      <tr>
                        <td className="text-center">{doc.name}</td>
                        {doc.existe ? (
                          <React.Fragment>
                            <td className="text-center">
                              <LinkD
                                href={this.state.url_pdf[index]}
                                id={`dowload_pdf_${index}`}
                                download={doc.name}
                              />
                            </td>
                            <td className="text-center">
                              <LinkV
                                href={this.state.url_pdf[index]}
                                id={`dowload_pdf_${index}`}
                                target="_blank"
                              />
                            </td>
                            <td className="text-center">
                              <Button
                                color="danger"
                                size="sm"
                                value={this.state.tipoFactura_data[index]}
                                onClick={this.handleElimina_archivo}
                              >
                                Eliminar
                              </Button>
                            </td>
                          </React.Fragment>
                        ) : (
                          <td
                            className="text-center"
                            colSpan={doc.existe ? 1 : 3}
                          >
                            No existe registro de archivos
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table> */}
                {this.state.archivoFacturaC.length > 0 && (
                  <Card className="mt-2" outline>
                    <CardBody>
                      <FormGroup className="row mb-1 d-flex justify-content-center general_sub">
                        <FormText color="muted">Pagos Comprobados</FormText>
                      </FormGroup>
                      <Table
                        size="sm"
                        className="mt-2"
                        bordered
                        hover
                        responsive
                      >
                        <thead className="text-center header-table">
                          <tr>
                            <td>Número</td>
                            <td>Fecha</td>
                            <td>Tipo De Pago</td>
                            <td>Factura</td>
                            <td>Cantidad</td>
                            <td>Observaciones</td>
                            <td>Documento</td>
                          </tr>
                        </thead>
                        <tbody className="body-table">
                          {this.state.archivoFacturaC.map((fac) => (
                            <tr key={fac.id}>
                              <td className="text-center">{fac.id}</td>
                              <td className="text-center">{fac.fecha}</td>
                              <td className="text-center">
                                {obtNombre(fac.tpo_pago)}
                              </td>
                              <td className="text-center">{fac.factura}</td>
                              <td className="text-center">{fac.cantidad}</td>
                              <td className="text-center">
                                {fac.observaciones}
                              </td>
                              <td className="text-center">
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    this.toggleModalRUD(fac.id);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                )}

                {this.state.archivoFactura.length > 0 && (
                  <Card className="mt-2" outline>
                    <CardBody>
                      <FormGroup className="row mb-1 d-flex justify-content-center general_sub">
                        <FormText color="muted">Pagos Liquidados</FormText>
                      </FormGroup>
                      <Table
                        size="sm"
                        className="mt-2"
                        bordered
                        hover
                        responsive
                      >
                        <thead className="text-center header-table">
                          <tr>
                            <td>Número</td>
                            <td>Fecha</td>
                            <td>Tipo De Pago</td>
                            <td>Factura</td>
                            <td>Cantidad</td>
                            <td>Observaciones</td>
                            <td>Documento</td>
                          </tr>
                        </thead>
                        <tbody className="body-table">
                          {this.state.archivoFactura.map((fac) => (
                            <tr key={fac.id}>
                              <td className="text-center">{fac.id}</td>
                              <td className="text-center">{fac.fecha}</td>
                              <td className="text-center">
                                {obtNombre(fac.tpo_pago)}
                              </td>
                              <td className="text-center">{fac.factura}</td>
                              <td className="text-center">{fac.cantidad}</td>
                              <td className="text-center">
                                {fac.observaciones}
                              </td>
                              <td className="text-center">
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    this.toggleModal(fac.id);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                )}

                <div className="container-fluid">
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button color="primary" onClick={this.handleCerrar}>
                      Cerrar
                    </Button>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Collapse>
        </ModalBody>

        {/* Modal Para subir Facturas*/}
        <Modal
          isOpen={this.state.modalAbierto}
          toggle={this.toggleModal}
          size="lg"
        >
          <ModalHeader toggle={this.toggleModal}>Agregar Documento</ModalHeader>
          <ModalBody>
            <Form className="container-fluid">
              <FormGroup className="row mb-0">
                <MyInput
                  type="text"
                  bootstrap="col-lg-6 col-8"
                  id="folio_modal"
                  value={this.state.folioDoc}
                  onChange={this.handleChangeFolioDoc}
                  nameLabel="Folio"
                />
                <MySelect
                  bootstrap="col-lg-6"
                  id="tipo_opcion"
                  first_option="Seleccione una opción"
                  onChange={this.handleTipoDoc}
                  nameLabel=""
                >
                  <option value="N">Nacional</option>
                  <option value="E">Extranjero</option>
                </MySelect>
              </FormGroup>
              <div style={{ marginBottom: "1rem" }} />
              <FormGroup className="row mb-0 mt-2 mt-lg-0">
                <Dropzone
                  handleArchivo={this.handleArchivoFactura}
                  accept=".xml, .pdf"
                />
                <div className="col-12 my-2 d-flex justify-content-center flex-wrap">
                  {this.state.archivo_factura.map((archivo, index) => (
                    <Badge color="light" className="border">
                      {archivo.name}&nbsp;
                      <spam
                        style={{ cursor: "pointer" }}
                        id={index}
                        onClick={this.handleEliminaFactura}
                      >
                        &times;
                      </spam>
                    </Badge>
                  ))}
                </div>
                <Button
                  color="primary"
                  className="col-12"
                  onClick={this.handleSubirFactura}
                >
                  Subir Archivos
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>

        {/* Modal Para RUD Facturas*/}
        <Modal
          isOpen={this.state.modalAbiertoRUD}
          toggle={this.toggleModalRUD}
          size="lg"
        >
          <ModalHeader toggle={this.toggleModalRUD}>Documento</ModalHeader>
          <ModalBody>
            {this.state.archivoPDF ? (
              <div className="text-center">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <Button
                      color="primary"
                      onClick={() => {
                        if (this.state.archivoPDF) {
                          window.open(
                            `data:application/pdf;base64,${this.state.archivoPDF}`,
                            "_blank"
                          );
                        }
                      }}
                    >
                      Visualizar <FontAwesomeIcon icon={faEye} />
                    </Button>
                  </div>
                  <div className="col-md-4 mb-3">
                    <Button
                      color="success"
                      href={`data:application/zip;base64,${this.state.archivoZIP}`}
                      download={`factura${this.state.facIdSeleccionado}.zip`}
                    >
                      Descargar <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </div>
                  <div className="col-md-4">
                    <Button color="danger" onClick={this.eliminarArchivo}>
                      Eliminar <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <p className="text-center">No existen archivos disponibles</p>
            )}
          </ModalBody>
        </Modal>
      </Modal>
    );
  }
}
export default ModalConsulta;
