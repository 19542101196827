import React, { Component } from "react";
import { Button, Form, FormGroup, Input, Col, Row } from "reactstrap";
import axios from "axios";
import "../../assets/style.css";
import * as message from "../../utils/messages/message";
import * as permisos from "../../utils/permisos/permisos";
import * as utility from './utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';



class BusquedaTabla extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state = {
      fecha1: '',
      fecha2: '',
      showForm: false,
      data: [] 
    };

    this.handleFecha1 = this.handleFecha1.bind(this);
    this.handleFecha2 = this.handleFecha2.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }
  handleTable = (e) => {
    let num_table = e.target.value;
    this.props.changeTable(num_table);
  };

  handleFecha1(e) {
    this.setState({ fecha1: e.target.value });
  }

  handleFecha2(e) {
    this.setState({ fecha2: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { fecha1, fecha2 } = this.state;
    
    if (!fecha1 || !fecha2) {
      return;
    }
    
    const fechas = `${fecha1},${fecha2}`;
  
    axios.post(utility.url_conciliacion_facturacion, { fechas })
      .then(response => {
        this.setState({ data: response.data });
        this.props.sendDataToTable(response.data);
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }
  
  handleTableClick() {
    this.setState(prevState => ({
      showForm: !prevState.showForm
    }));
  }

  handleButtonClick() {
    this.setState({ data: [] }, () => {
        this.props.sendDataToTable(this.state.data);
    });
}

  render() {
    return (
      <div className="row">
        <div className="col-lg-9 col-sm-12 my-2">
          {this.state.showForm && (
            <Form onSubmit={this.handleSubmit}>
              <FormGroup className="d-flex justify-content-center flex-column mb-0">
                <Input
                  type="text"
                  placeholder="Buscar"
                  id="busqueda_general_conciliacionfactura"
                  onKeyUp={utility.general_search}
                />
                <Row className="mt-1">
                  <Col lg="4" className="p-0">
                    <Input
                      type="date"
                      id="busqueda_date_conciliacionfactura1"
                      onChange={this.handleFecha1}
                      value={this.state.fecha1}
                      nameLabel="Fecha 1"
                      className="ml-3"
                    />
                  </Col>
                  <Col lg="4" className="p-0 ml-1">
                    <Input
                      type="date"
                      id="busqueda_date_conciliacionfactura2"
                      onChange={this.handleFecha2}
                      value={this.state.fecha2}
                      nameLabel="Fecha 2"
                      className="ml-3"
                    />
                  </Col>
                  <Col lg="2" className="p-0 ml-4">
                    <Button color="info" className="w-100">
                      Buscar
                    </Button>
                  </Col>
                  <Col lg="1" className="p-0 ml-2">
                    <Button color="danger" className="w-100" onClick={this.handleButtonClick}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          )}
        </div>
        <div
          className="col-lg-3 col-sm-12 mb-2 my-md-2 rounded overflow-auto"
          style={{ height: "80px" }}
        >
          <Button
            color="primary"
            className="col-12 rounded-0"
            value="0"
            onClick={this.handleTableClick}
            disabled={permisos.obtener_permiso_especifico(
              "visualizar",
              this.props.permisos.alerta
            )}
          >
            Conciliación De Factura
          </Button>
        </div>
      </div>
    );
  }
}
export default BusquedaTabla;
