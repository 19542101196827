import axios from "axios";
import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Badge,
  FormText,
  Toast,
  ToastHeader,
  ToastBody,
  Button,
  Alert,
} from "reactstrap";
import "../../assets/style.css";
import * as message from "../../utils/messages/message";
import WebSocketInstance from "../../utils/websocket";
import * as utility from "./utils/utility";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Principal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      pendientes: [],
      altas: [],
      advertencias: [],
      urgentes: [],

      paginador: 0,
    };
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_paginacion, "pagination");
  }
  update = (cambio) => {
    this.setState({ acutalizar: cambio });
  };
  nextNotificacion = () => {
    let actual = this.state.paginador;
    if (actual === 3) this.setState({ paginador: 0 });
    else this.setState({ paginador: actual + 1 });
  };
  prevNotificacion = () => {
    let actual = this.state.paginador;
    if (actual === 0) this.setState({ paginador: 3 });
    else this.setState({ paginador: actual - 1 });
  };
  checkColor = () => {
    let actual = this.state.paginador;
    if (actual === 0) return { background: "#cbcbcb" };
    else if (actual === 1) return { background: "#ff3131a8" };
    else if (actual === 2) return { background: "#ffcc40b6" };
    else return { background: "#3fca469f" };
  };
  checkColorBack = () => {
    let actual = this.state.paginador;
    if (actual === 0) return { background: "rgba(203,203,203,0.2)" };
    else if (actual === 1) return { background: "rgba(255,49,49,0.2)" };
    else if (actual === 2) return { background: "rgba(255,204,64,0.2)" };
    else return { background: "rgba(63,202,70,0.2)" };
  };
  checkTipo = () => {
    let actual = this.state.paginador;
    if (actual === 0) return "PENDIENTES";
    else if (actual === 1) return "URGENTES";
    else if (actual === 2) return "ADVERTENCIAS";
    else return "VIGENTES";
  };
  checkToast = () => {
    let actual = this.state.paginador;
    if (actual === 0)
      return this.state.pendientes.map((pendiente, index) => (
        <Toast key={index} className="mx-auto">
          <ToastHeader style={{ background: "#cbcbcb" }}>
            {pendiente.aplicacion.toUpperCase()}
          </ToastHeader>
          <ToastBody>
            {pendiente.descripcion}
            <FormText className="mb-0" color="none">
              {pendiente.fecha.slice(0, 10)} - {pendiente.fecha.slice(11, 16)}
            </FormText>
          </ToastBody>
        </Toast>
      ));
    else if (actual === 1)
      return this.state.urgentes.map((urgente, index) => (
        <Toast key={index} className="mx-auto" isOpen={urgente.toogle}>
          <ToastHeader
            style={{ background: "#ff3131a8" }}
            toggle={() => {
              let urgentes = this.state.urgentes;
              urgentes.forEach((urgente, i) => {
                if (i === index) urgente.toogle = false;
              });
              this.setState({}, () => {
                this.methodPOST_API(utility.url_cambio_status, "pendientes", {
                  notificaciones: [urgente.consecutivo],
                  status: "V",
                });
              });
            }}
          >
            {urgente.aplicacion.toUpperCase()}
          </ToastHeader>
          <ToastBody>
            {urgente.descripcion}
            <FormText className="mb-0" color="none">
              {urgente.fecha.slice(0, 10)} - {urgente.fecha.slice(11, 16)}
            </FormText>
          </ToastBody>
        </Toast>
      ));
    else if (actual === 2)
      return this.state.advertencias.map((advertencia, index) => (
        <Toast key={index} className="mx-auto" isOpen={advertencia.toogle}>
          <ToastHeader
            style={{ background: "#ffcc40b6" }}
            toggle={() => {
              let advertencias = this.state.advertencias;
              advertencias.forEach((advertencia, i) => {
                if (i === index) advertencia.toogle = false;
              });
              this.setState({}, () => {
                this.methodPOST_API(utility.url_cambio_status, "pendientes", {
                  notificaciones: [advertencia.consecutivo],
                  status: "V",
                });
              });
            }}
          >
            {advertencia.aplicacion.toUpperCase()}
          </ToastHeader>
          <ToastBody>
            {advertencia.descripcion}
            <FormText className="mb-0" color="none">
              {advertencia.fecha.slice(0, 10)} -{" "}
              {advertencia.fecha.slice(11, 16)}
            </FormText>
          </ToastBody>
        </Toast>
      ));
    else
      return this.state.altas.map((alta, index) => (
        <Toast key={index} className="mx-auto" isOpen={alta.toogle}>
          <ToastHeader
            style={{ background: "#3fca469f" }}
            toggle={() => {
              let altas = this.state.altas;
              altas.forEach((alta, i) => {
                if (i === index) alta.toogle = false;
              });
              this.setState({}, () => {
                this.methodPOST_API(utility.url_cambio_status, "pendientes", {
                  notificaciones: [alta.consecutivo],
                  status: "V",
                });
              });
            }}
          >
            {alta.aplicacion.toUpperCase()}
          </ToastHeader>
          <ToastBody>
            {alta.descripcion}
            <FormText className="mb-0" color="none">
              {alta.fecha.slice(0, 10)} - {alta.fecha.slice(11, 16)}
            </FormText>
          </ToastBody>
        </Toast>
      ));
  };
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = () => {
    let folio = 666;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "puesto",
      motivo: "crear",
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  handlePendientes = () => {
    let data = { notificaciones: [], status: "A" };
    this.state.pendientes.forEach((pendiente) => {
      data.notificaciones.push(pendiente.consecutivo);
    });
    message
      .message_confimacion("¿Desea limpiar sus pendientes?", "")
      .then((res) => {
        if (res) {
          this.methodPOST_API(utility.url_cambio_status, "pendientes", data);
        }
      });
  };
  toogleVigentes = () => {};
  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    if (state === "pagination")
      url_intern +=
        localStorage.getItem("puesto") === "Cliente"
          ? `${localStorage.getItem("id_usr")}-c/`
          : `${localStorage.getItem("id_usr")}-e/`;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status === 204)
          message.message_modal(
            response.data.title,
            response.data.text,
            response.status
          );
        else {
          switch (state) {
            case "pagination":
              if (this._isMounted) {
                let altas = response.data.results.altas;
                let pendientes = response.data.results.pendientes;
                let advertencias = response.data.results.advertencias;
                let urgentes = response.data.results.urgentes;
                altas.forEach((alta) => (alta["toogle"] = true));
                pendientes.forEach((pendiente) => (pendiente["toogle"] = true));
                advertencias.forEach(
                  (advertencia) => (advertencia["toogle"] = true)
                );
                urgentes.forEach((urgente) => (urgente["toogle"] = true));
                this.setState({
                  pendientes: pendientes,
                  altas: altas,
                  advertencias: advertencias,
                  urgentes: urgentes,
                });
              }
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma POST
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "pendientes":
              this.setState({}, () => {
                this.methodGET_API(utility.url_paginacion, "pagination");
              });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  render() {
    const puesto = localStorage.getItem("puesto");

    if (puesto === "Transportista") {
      return (
        <div className="container-fluid mt-3">
          <div className="row">
            <div className="col-12 text-center mb-2">
              <h1 className="display-4">
                Bienvenido de vuelta {localStorage.getItem("user_name")}
              </h1>
            </div>
            <Alert color="warning" className="mt-5 col-lg-8 col-12 mx-auto">
              <h2 className="mb-0 text-center">
                Por favor, accede al menú de 'Operación' y selecciona
                'Notificación De Embarque' para continuar.
              </h2>
            </Alert>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container-fluid mt-3">
          <div className="row">
            <div className="col-12 d-none d-md-inline-block text-center mb-2">
              <h1>Bienvenido de vuelta {localStorage.getItem("user_name")}</h1>
              <h3>Tus notificaciones del día son las siguientes:</h3>
            </div>
            <div className="col-12 d-lg-none">
              <Card>
                <CardHeader style={this.checkColor()}>
                  <div className="d-flex justify-content-between">
                    <h5
                      className="mb-0"
                      style={{ cursor: "pointer", fontSize: "25px" }}
                      onClick={this.prevNotificacion}
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </h5>
                    <h5 className="mb-0">{this.checkTipo()}</h5>
                    <h5
                      className="mb-0"
                      style={{ cursor: "pointer", fontSize: "25px" }}
                      onClick={this.nextNotificacion}
                    >
                      <FontAwesomeIcon icon={faAngleRight} />
                    </h5>
                  </div>
                </CardHeader>
                <CardBody
                  className="tamaño-notificacion"
                  style={this.checkColorBack()}
                >
                  {this.checkToast()}
                </CardBody>
                {this.state.paginador === 0 ? (
                  <CardFooter>
                    <Button
                      color="danger"
                      className="col-12"
                      size="sm"
                      onClick={this.handlePendientes}
                    >
                      Limpiar
                    </Button>
                  </CardFooter>
                ) : null}
              </Card>
            </div>
            <div className="col-3 d-none d-lg-inline-block pr-0">
              <Card>
                <CardHeader style={{ background: "#ff3131a8" }}>
                  <div className="d-flex justify-content-between">
                    <h5 className="mb-0">URGENTES</h5>
                    <h5 className="mb-0">
                      <Badge color="dark" pill>
                        {this.state.urgentes.length}
                      </Badge>
                    </h5>
                  </div>
                </CardHeader>
                <CardBody
                  className="tamaño-notificacion"
                  style={{ background: "rgba(255,49,49,0.2)" }}
                >
                  {this.state.urgentes.map((urgente, index) => (
                    <Toast
                      key={index}
                      className="mx-auto"
                      isOpen={urgente.toogle}
                    >
                      <ToastHeader
                        style={{ background: "#ff3131a8" }}
                        toggle={() => {
                          let urgentes = this.state.urgentes;
                          urgentes.forEach((urgente, i) => {
                            if (i === index) urgente.toogle = false;
                          });
                          this.setState({}, () => {
                            this.methodPOST_API(
                              utility.url_cambio_status,
                              "pendientes",
                              {
                                notificaciones: [urgente.consecutivo],
                                status: "V",
                              }
                            );
                          });
                        }}
                      >
                        {urgente.aplicacion.toUpperCase()}
                      </ToastHeader>
                      <ToastBody>
                        {urgente.descripcion}
                        <FormText className="mb-0" color="none">
                          {urgente.fecha.slice(0, 10)} -{" "}
                          {urgente.fecha.slice(11, 16)}
                        </FormText>
                      </ToastBody>
                    </Toast>
                  ))}
                </CardBody>
              </Card>
            </div>
            <div className="col-3 d-none d-lg-inline-block pr-0">
              <Card>
                <CardHeader style={{ background: "#ffcc40b6" }}>
                  <div className="d-flex justify-content-between">
                    <h5 className="mb-0">ADVERTENCIAS</h5>
                    <h5 className="mb-0">
                      <Badge color="dark" pill>
                        {this.state.advertencias.length}
                      </Badge>
                    </h5>
                  </div>
                </CardHeader>
                <CardBody
                  className="tamaño-notificacion"
                  style={{ background: "rgba(255,204,64,0.2)" }}
                >
                  {this.state.advertencias.map((advertencia, index) => (
                    <Toast
                      key={index}
                      className="mx-auto"
                      isOpen={advertencia.toogle}
                    >
                      <ToastHeader
                        style={{ background: "#ffcc40b6" }}
                        toggle={() => {
                          let advertencias = this.state.advertencias;
                          advertencias.forEach((advertencia, i) => {
                            if (i === index) advertencia.toogle = false;
                          });
                          this.setState({}, () => {
                            this.methodPOST_API(
                              utility.url_cambio_status,
                              "pendientes",
                              {
                                notificaciones: [advertencia.consecutivo],
                                status: "V",
                              }
                            );
                          });
                        }}
                      >
                        {advertencia.aplicacion.toUpperCase()}
                      </ToastHeader>
                      <ToastBody>
                        {advertencia.descripcion}
                        <FormText className="mb-0" color="none">
                          {advertencia.fecha.slice(0, 10)} -{" "}
                          {advertencia.fecha.slice(11, 16)}
                        </FormText>
                      </ToastBody>
                    </Toast>
                  ))}
                </CardBody>
              </Card>
            </div>
            <div className="col-3 d-none d-lg-inline-block pr-0">
              <Card>
                <CardHeader style={{ background: "#3fca469f" }}>
                  <div className="d-flex justify-content-between">
                    <h5 className="mb-0">VIGENTES</h5>
                    <h5 className="mb-0">
                      <Badge color="dark" pill>
                        {this.state.altas.length}
                      </Badge>
                    </h5>
                  </div>
                </CardHeader>
                <CardBody
                  className="tamaño-notificacion"
                  style={{ background: "rgba(63,202,70,0.2)" }}
                >
                  {this.state.altas.map((alta, index) => (
                    <Toast key={index} className="mx-auto" isOpen={alta.toogle}>
                      <ToastHeader
                        style={{ background: "#3fca469f" }}
                        toggle={() => {
                          let altas = this.state.altas;
                          altas.forEach((alta, i) => {
                            if (i === index) alta.toogle = false;
                          });
                          this.setState({}, () => {
                            this.methodPOST_API(
                              utility.url_cambio_status,
                              "pendientes",
                              {
                                notificaciones: [alta.consecutivo],
                                status: "V",
                              }
                            );
                          });
                        }}
                      >
                        {alta.aplicacion.toUpperCase()}
                      </ToastHeader>
                      <ToastBody>
                        {alta.descripcion}
                        <FormText className="mb-0" color="none">
                          {alta.fecha.slice(0, 10)} - {alta.fecha.slice(11, 16)}
                        </FormText>
                      </ToastBody>
                    </Toast>
                  ))}
                </CardBody>
              </Card>
            </div>
            <div className="col-3 d-none d-lg-inline-block">
              <Card>
                <CardHeader style={{ background: "#cbcbcb" }}>
                  <div className="d-flex justify-content-between">
                    <h5 className="mb-0">PENDIENTES</h5>
                    <Button
                      color="danger"
                      className="ml-auto mr-3"
                      size="sm"
                      onClick={this.handlePendientes}
                    >
                      Limpiar
                    </Button>
                    <h5 className="mb-0">
                      <Badge color="dark" pill>
                        {this.state.pendientes.length}
                      </Badge>
                    </h5>
                  </div>
                </CardHeader>
                <CardBody
                  className="tamaño-notificacion"
                  style={{ background: "rgba(203,203,203,0.2)" }}
                >
                  {this.state.pendientes.map((pendiente, index) => (
                    <Toast key={index} className="mx-auto">
                      <ToastHeader style={{ background: "#cbcbcb" }}>
                        {pendiente.aplicacion.toUpperCase()}
                      </ToastHeader>
                      <ToastBody>
                        {pendiente.descripcion}
                        <FormText className="mb-0" color="none">
                          {pendiente.fecha.slice(0, 10)} -{" "}
                          {pendiente.fecha.slice(11, 16)}
                        </FormText>
                      </ToastBody>
                    </Toast>
                  ))}
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Principal;
