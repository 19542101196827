/* 
Autor: Alexis Yael Hernández Grimaldo
Descripción: El componenete genera un bloque correspondiente a la busqueda dinamica en la tabla para acceder a la información.
*/
import React, { Component } from "react";
import { Form, FormGroup, Input } from "reactstrap";
import * as utility from "./utils/utility";
import ModalAction from "./Modal/ModalAction";

class BusquedaTabla extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-lg-9 col-sm-12 my-2">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <FormGroup className="d-flex justify-content-center flex-column mb-0">
              <Input
                type="text"
                placeholder="Buscar"
                id="busqueda_general_operacion"
                onKeyUp={utility.general_search}
              />
              <Input
                type="date"
                placeholder="Buscar"
                id="busqueda_date_operacion"
                onKeyUp={utility.date_search}
                className="mt-1"
              />
            </FormGroup>
          </Form>
        </div>
        <ModalAction
          update={this.props.update}
          permisos={this.props.permisos}
          clientes={this.props.clientes}
          patentes={this.props.patentes}
          paises={this.props.paises}
          consolidadoras={this.props.consolidadoras}
          proveedores={this.props.proveedores}
          phxcc={this.props.phxcc}
          beneficiarios={this.props.beneficiarios}
          pedimento={this.props.pedimento}
        />
      </div>
    );
  }
}
export default BusquedaTabla;
