export const url_paginacion = '/DjangoWeb/OperacionesApp/consulta/operacion/paginacion/principal';
export const url_alta_operacion = '/DjangoWeb/OperacionesApp/crea_modfica/operacion';
export const url_consulta_operacion = '/DjangoWeb/OperacionesApp/consulta/operacion';
export const url_consulta_pago = '/DjangoWeb/OperacionesApp/consulta/operacion/pagos';
export const url_alta_pago = '/DjangoWeb/OperacionesApp/crea_modifica/operacion/pagos';

export const url_consulta_alerta = '/DjangoWeb/AlertasApp/consulta/alerta';

export const url_clientes = '/DjangoWeb/BaseApp/consulta/clientes/lista';
export const url_referencia = '/DjangoWeb/BaseApp/consulta/operacion/referencias_pendientes';
export const url_patentes = '/DjangoWeb/BaseApp/consulta/agente_aduanal/lista';
export const url_paises = '/DjangoWeb/BaseApp/consulta/paises/lista';
export const url_consolidadoras = '/DjangoWeb/BaseApp/consulta/consolidadoras/lista';
export const url_proveedor = '/DjangoWeb/BaseApp/consulta/proveedor/lista';

export const url_phxcc = '/DjangoWeb/BaseApp/consulta/cat_phxcdc/lista';

export const url_pdf = '/DjangoWeb/ExpedienteApp/descargar/archivo_pdf/operacion/';
export const url_elimina_pdf = '/DjangoWeb/ExpedienteApp/eliminar/archivo_pdf';
export const url_cat_beneficiarios= 'DjangoWeb/BaseApp/consulta/beneficiarios/lista';

export const url_solicitud_pagos = 'DjangoWeb/OperacionesApp/solicita_pago/operacion/pagos';
export const url_liquida_pagos = 'DjangoWeb/OperacionesApp/liquida/operacion/pagos';
export const url_cancela_pagos = 'DjangoWeb/OperacionesApp/cancela/operacion/pagos';

export const url_pagos_lista = 'DjangoWeb/OperacionesApp/consulta/operacion/pagos/lista';

export const url_factura = '/DjangoWeb/FacturasApp/consulta/expedinte/facturas';
export const url_desc_factura = '/DjangoWeb/FacturasApp/descarga/expedinte/facturas';
export const url_elimina_factura = '/DjangoWeb/FacturasApp/elimina/facturas';

export const url_pedimento = '/DjangoWeb/PedimentosApp/consulta/pedimento/paginacion/principal';

export const url_consulta_pdf = '/DjangoWeb/OperacionesApp/consulta/operacion/pdf/';
export const url_consulta_pdf_pagos = '/DjangoWeb/OperacionesApp/consulta/operacion/pagos/pdf/';

export const url_consulta_beneficiarios = '/DjangoWeb/BaseApp/consulta/beneficiarios';
export const url_modifica_beneficiarios = '/DjangoWeb/BaseApp/crea_modifica/beneficiarios';

export const url_pdf_comprobante = '/DjangoWeb/OperacionesApp/consulta/operacion/pagos/comprobante/';
/**
 * Función que nos permite filtar la tabla por el input general que serra de tipo text.
 */
export const general_search = (e) => {
    e.preventDefault();
    let tr = document.getElementById('tabla_operacion').getElementsByTagName('tr');
    let data_input = document.getElementById('busqueda_general_operacion').value.toUpperCase();
    for (let i = 0; i < tr.length; i++) {
        let mostrar = false;
        let data = tr[i].getElementsByTagName('p');
        for (let j = 0; j < data.length; j++) {
            if (data_input === "") {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
            else if (data[j].textContent.toUpperCase().includes(data_input)) {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "rgba(0,123,255,1)";
                data[j].style.color = "#fff";
            }
            else {
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que nos permite filtrar latabla solamnete por la fecha.
 */
export const date_search = (e) => {
    e.preventDefault();
    var tabla_anticipo, input_general, tr, dato, input_dato, mostrar;
    tabla_anticipo = document.getElementById("tabla_operacion");
    input_general = document.getElementById("busqueda_date_operacion");
    input_dato = input_general.value;
    tr = tabla_anticipo.getElementsByTagName("tr");
    for (var i = 0; i < tr.length; i++) {
        mostrar = false;
        dato = tr[i].getElementsByTagName("p");
        for (var j = 0; j < dato.length; j++) {
            if (dato[j].textContent === input_dato) {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "rgba(0,123,255,1)";
                dato[j].style.color = "#fff";
            }
            else if (input_dato === "") {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
            else {
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que obtiene los nombres de todos los clientes
 * @param {array} arreglo 
 * @returns {array} Arreglo de nombres.
 */
export const get_nombres_clientes = (arreglo) => {
    var aux = [];
    for (var i = 0; i < arreglo.length; i++) {
        aux.push(arreglo[i].nombre);
    }
    return (aux);
}
/**
 * Finción que permite el retorno del número de cliente por su nombre.
 * @param {Sring} nombre Nombre del cliente. 
 * @param {Array} array Arreglo de clientes.
 * @returns {String} Número del cliente.
 */
export const get_number_cliente = (nombre, array) => {
    let only_number = "";
    let bandera = 0;
    for (let i = 0; i < array.length; i++)
        if (nombre === array[i].nombre) {
            for (let j = 0; j < 4; j++) {
                if (array[i].numero[j] !== '0')
                    bandera = 1;
                if (bandera === 1)
                    only_number += array[i].numero[j];
            }
            return (only_number);
        }
}

/**
 * Función que permite retornar el nombre de un cliente ne especifico.
 * @param {String} numero Número de cliente.
 * @param {Array} array Arreglo de clientes.
 * @returns {String} Nombre del cliente.
 */
export const get_name_cliente = (numero, array) => {
    let complete_number = "";
    switch (numero.length) {
        case 1: complete_number = '000' + numero; break;
        case 2: complete_number = '00' + numero; break;
        case 3: complete_number = '0' + numero; break;
        case 4: complete_number = numero; break;
        default: break;
    }
    for (let i = 0; i < array.length; i++)
        if (complete_number === array[i].numero) {
            return (array[i].nombre);
        }
}
/**
 * Función que elimina los datos vacios para la petición POST
 * @param {Object} data Objeto que contiene los datos para POST 
 */
export const eliminaCamposPOST = (data) => {
    if (data.empleado.length === 0)
        delete data.empleado;
    if (data.registro_entrada.length === 0)
        delete data.registro_entrada;
    if (data.fecha_llegada == null || data.fecha_llegada.length === 0)
        delete data.fecha_llegada;
    if (data.guia_principal.length === 0)
        delete data.guia_principal;
    if (data.consolidadora.length === 0)
        delete data.consolidadora;
    if (data.proveedor.length === 0)
        delete data.proveedor;
    if (data.procedencia.length === 0)
        delete data.procedencia;
    if (data.descripcion.length === 0)
        delete data.descripcion;
    if (data.no_pedido.length === 0)
        delete data.no_pedido;
    if (data.num_operacion.length === 0)
        delete data.num_operacion;
    if (data.fecha_despacho == null || data.observaciones.length === 0)
        delete data.observaciones;
    if (data.guia_secundaria.length === 0)
        delete data.guia_secundaria;
    if (data.fecha_despacho == null || data.fecha_despacho.length === 0)
        delete data.fecha_despacho;
    if (data.folio.length === 0)
        delete data.folio;
}

/**
 * Función que valida los datos vacios para la petición POST
 * @param {Object} data Objeto que contiene los datos para POST 
 * @return {String} Mensaje de los campos vacios.
 */
export const validadCamposPOST = (data) => {
    let message = "";
    if (!data.folio || data.folio.length === 0)
      message += "Folio, ";
    if (!data.cliente || data.cliente.length === 0)
      message += "Cliente, ";
    if (!data.procedencia || data.procedencia.length === 0)
      message += "Procedencia, ";
    if (!data.proveedor || data.proveedor.length === 0)
      message += "Proveedor, ";
    if (!data.consolidadora || data.consolidadora.length === 0)
      message += "Consolidadora, ";
    if (!data.aduana || data.aduana.length === 0)
      message += "Aduana, ";
    if (!data.patente || data.patente.length === 0)
      message += "Patente, ";
    if (!data.guia_principal || data.guia_principal.length === 0)
      message += "Guía Principal, ";
  
    message = message.slice(0, -2);
    return message;
  }
  

export const get_name_pagos = (numero, array) => {
    for (let i = 0; i < array.length; i++)
        if (numero === array[i].clave)
            return array[i].corto;
}

export const valida_data_pago = (data) => {
    let message = "";
    if (data.fecha.length === 0)
        message += "Fecha, ";
    if (data.tpo_pago.length === 0)
        message += "Tipo Pago, ";
    if (data.cantidad.length === 0)
        message += "Importe, ";
    if (data.observaciones.length === 0)
        message += "Observaciones, ";
    message = message.slice(0, message.length - 2);
    return message;
}

