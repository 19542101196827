/* 
Autor: Alexis Yael Hernández Grimaldo
Descripción: El componenete genera un bloque correspondiente a la busqueda dinamica en la tabla para acceder a la información.
*/
import React, { Component } from "react";
import { Form, FormGroup, Button, Collapse, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import MyInput from "../../utils/custom/MyInput";
import MySelect from "../../utils/custom/MySelect";
import * as utility from "./utils/utility";

class BusquedaEspecifica extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      toogle_collapse: false,
      tipo: "",
    };
  }

  toogleCollapse = (e) => {
    this.setState((prevState) => ({
      toogle_collapse: !prevState.toogle_collapse,
    }));
  };

  handleTipoChange = (e) => {
    this.setState({ tipo: e.target.value });
  };

  handleFiltrada = () => {
    let data = {
      folio: document.getElementById("folio").value,
      cliente: document.getElementById("cliente").value,
      referencia: document.getElementById("referencia").value,
      guia_principal: document.getElementById("guia_principal").value,
      consolidadora: document.getElementById("consolidadora").value,
      tipo: this.state.tipo,
      patente: document.getElementById("patente").value,
      proveedor: document.getElementById("proveedor").value,
      procedencia: document.getElementById("procedencia").value,
      fecha_alta: document.getElementById("fecha_alta").value,
      fecha_llegada: document.getElementById("fecha_llegada").value,
      status: document.getElementById("estado").value,
    };
  
    // Filtrar solo los campos que tienen contenido
    let filteredData = {};
    for (let key in data) {
      if (data[key]) {
        filteredData[key] = data[key];
      }
    }
  
    if (Object.keys(filteredData).length > 0) {
      this.props.onChangeFiltrada(filteredData);
    } else {
      console.log("No hay datos para filtrar");
    }
  };
  

  render() {
    return (
      <React.Fragment>
        <div className="row mb-2 mx-1">
          <h3 className="col-md-11 col-9 text-md-left px-0 mb-0">
            Consulta Específica
          </h3>
          <Button
            color="info"
            onClick={this.toogleCollapse}
            className="col-md-1 col-3"
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
        <Collapse isOpen={this.state.toogle_collapse}>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                id="folio"
                nameLabel="Folio"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                id="cliente"
                nameLabel="Cliente"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                id="referencia"
                nameLabel="Referencia"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                id="guia_principal"
                nameLabel="Guía Principal"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                id="consolidadora"
                nameLabel="Consolidadora"
              />
              <MySelect
                bootstrap="col-lg-3 col-12"
                id="tipo"
                nameLabel="Tipo"
                first_option="Seleccione un opción"
                onChange={this.handleTipoChange}
                value={this.state.tipo}
              >
                <option value="1">Exportación</option>
                <option value="0">Importación</option>
              </MySelect>

              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                id="patente"
                nameLabel="Patente"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                id="proveedor"
                nameLabel="Proveedor"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                id="procedencia"
                nameLabel="Procedencia"
              />
              <MyInput
                type="date"
                bootstrap="col-lg-3 col-12"
                id="fecha_alta"
                nameLabel="Fecha Alta"
              />
              <MyInput
                type="date"
                bootstrap="col-lg-3 col-12"
                id="fecha_llegada"
                nameLabel="Fecha Llegada"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                id="estado"
                nameLabel="Estado"
              />
            </FormGroup>
          </Form>
          <FormGroup className="row d-flex justify-content-center mb-0">
            <Button
              color="danger"
              className="mb-1"
              onClick={this.props.onBackTable}
            >
              Regresar
            </Button>
            <Button color="success ml-2 mb-1" onClick={this.handleFiltrada}>
              Consultar
            </Button>
          </FormGroup>
        </Collapse>
      </React.Fragment>
    );
  }
}

export default BusquedaEspecifica;
