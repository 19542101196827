/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente general de la aplicación anticipos. 
*/
import axios from "axios";
import React, { Component } from "react";
import SearchBar from "./SearchBar";
import TableFracciones from "./Table";
import * as permisos from "../../utils/permisos/permisos";
import * as utility from "./utils/utility";
import * as message from "../../utils/messages/message";

class Fracciones extends Component {
    _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      actualizar: 0,

      permisos: permisos.obtener_permisos_by_app(
        "catnumeroparte",
        localStorage.getItem("perm")
      ),
      codigos_sat: [],
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_codigos_sat, "codigo_sat");
  }
  update = (cambio) => {
    this.setState({ actualizar: cambio });
  };
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "codigo_sat":
              this.setState({ codigos_sat: response.data.results });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  render() {
    return (
      <div className="container-fluid">
        <SearchBar update={this.update} permisos={this.state.permisos} codigos_sat={this.state.codigos_sat} />
        <TableFracciones
          update={this.update}
          actualizar={this.state.actualizar}
        />
      </div>
    );
  }
}
export default Fracciones;
