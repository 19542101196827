import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Table,
  ListGroup,
  ListGroupItem,
  Tooltip,
} from "reactstrap";
import * as utility from "../utils/utility";
import * as message from "../messages/messages";
import axios from "axios";
import swal from "@sweetalert/with-react";
import {
  faEye,
  faFileDownload,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyInput from "../utils/MyInput";
import MySelect from "../../../utils/custom/MySelect";
import CustomSelect from "../../../utils/custom/CustomSelect";
import ModalAuxiliar from "./ModalAuxiliar";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      cliente: "",
      subtipo: "",
      tipo: "",
      concepto: "",
      referencia: "",
      auxiliar: "",
      documento: "",
      cargo: "",
      abono: "",
      tipoMovimiento: "",
      movimientos: [],
      listaMovimientos: [],
      listaSubtipos: [],
      nombre_suggestions: [],
      enable_pdf: false,
      poliza_select_text: "Seleccione una poliza",
      poliza_select_id: "",
      polizaTipos: "",
      tipos_poliza: [
        { nombre: "Ingresos", clave: "1" },
        { nombre: "Egresos", clave: "2" },
        { nombre: "Diario", clave: "3" },
      ],
      toogle_auxiliar: false,
      auxiliares: [],
      tipoMov: [],
      subtipoclave: "",

      showTooltip: false,
    };
    this.toogleAuxiliar = this.toogleAuxiliar.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    document.addEventListener("keyup", this.handleEnterKeyPress);
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("keyup", this.handleEnterKeyPress);
  }

  handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      // Si la tecla presionada fue 'Enter', llamamos a la función para manejar el evento
      if (e.target.id === "cargo_poliza") {
        this.handleCargoBlur();
      }
      if (e.target.id === "abono_poliza") {
        this.handleAbonoBlur();
      }
    }
  };

  toggleTooltip() {
    this.setState((prevState) => ({
      showTooltip: !prevState.showTooltip,
    }));
  }

  toogleAuxiliar = () => {
    this.setState((prevState) => ({
      toogle_auxiliar: !prevState.toogle_auxiliar,
    }));
    if (this.state.auxiliar === "") {
      this.getAuxiliaresMayores();
    } else {
      this.postAuxiliarSegundoNivel();
    }
  };

  getAuxiliaresMayores() {
    const url = utility.url_auxiliares;
    axios
      .get(url)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              auxiliares: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  postAuxiliarSegundoNivel() {
    axios
      .post(utility.url_auxiliares, {
        auxiliar: this.state.auxiliar,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              auxiliares: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  handleConsulta = () => {
    this.methodPOST_API(utility.url_consulta_poliza, "consulta", {
      cliente_poliza: this.state.cliente,
      tipo: this.state.tipo,
    });
    utility.add_class_disabled("fecha_consulta_poliza");
    utility.add_class_disabled("subtipo_consulta_poliza");
    utility.add_class_disabled("tipo_consulta_poliza");
    utility.add_class_disabled("concepto_consulta_poliza");
  };
  handleNumero = (e) => {
    let cliente = e.target.value;
    this.setState({ cliente });
  };
  handleConcepto = (e) => {
    let concepto = e.target.value;
    this.setState({ concepto });
  };
  handleReferencia = (e) => {
    let referencia = e.target.value;
    this.setState({ referencia });
  };
  handleAuxiliar = (e) => {
    let auxiliar = e.target.value;
    this.setState({ auxiliar });
  };
  handleCallback = (childData) => {
    this.setState({ auxiliar: childData });
  };

  handleCargo = (e) => {
    let cargo = e.target.value;

    // Remover caracteres que no son números, puntos o comas
    cargo = cargo.replace(/[^\d.,]/g, "");

    // Reemplazar comas por puntos
    cargo = cargo.replace(/,/g, ".");

    // Si hay más de un punto, eliminar los puntos extras
    const dotCount = cargo.split(".").length - 1;
    if (dotCount > 1) {
      cargo = cargo.replace(/\./g, (match, offset) => (offset ? "" : match));
    }

    // Limitar a dos decimales
    const parts = cargo.split(".");
    if (parts[1] && parts[1].length > 2) {
      cargo = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    this.setState({ cargo });
  };

  handleCargoBlur = () => {
    let cargo = this.state.cargo;

    // Si el campo está vacío, establecer el valor como '0.00'
    if (!cargo) {
      cargo = "0.00";
    } else {
      // Convertir el valor a un número
      let num = parseFloat(cargo);

      // Verificar si el valor es un número válido
      if (!isNaN(num)) {
        // Formatear el número con dos decimales
        cargo = num.toFixed(2);
      } else {
        // Si no es un número válido, establecer el valor como '0.00'
        cargo = "0.00";
      }
    }

    this.setState({ cargo });
  };

  handleDocumento = (e) => {
    let documento = e.target.value;
    this.setState({ documento });
  };

  handleAbono = (e) => {
    let abono = e.target.value;

    // Remover caracteres que no son números, puntos o comas
    abono = abono.replace(/[^\d.,]/g, "");

    // Reemplazar comas por puntos
    abono = abono.replace(/,/g, ".");

    // Si hay más de un punto, eliminar los puntos extras
    const dotCount = abono.split(".").length - 1;
    if (dotCount > 1) {
      abono = abono.replace(/\./g, (match, offset) => (offset ? "" : match));
    }

    // Limitar a dos decimales
    const parts = abono.split(".");
    if (parts[1] && parts[1].length > 2) {
      abono = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    this.setState({ abono });
  };

  handleAbonoBlur = () => {
    let abono = this.state.abono;

    // Si el campo está vacío, establecer el valor como '0.00'
    if (!abono) {
      abono = "0.00";
    } else {
      // Convertir el valor a un número
      let num = parseFloat(abono);

      // Verificar si el valor es un número válido
      if (!isNaN(num)) {
        // Formatear el número con dos decimales
        abono = num.toFixed(2);
      } else {
        // Si no es un número válido, establecer el valor como '0.00'
        abono = "0.00";
      }
    }

    this.setState({ abono });
  };

  handleCerrar = () => {
    this.props.toogleNuevo();
    this.handleLimpiar();
  };
  handleTipoMov = (e) => {
    let tipoMovimiento = e.target.value;
    this.setState({ tipoMovimiento });
  };
  handleLimpiar = () => {
    this.setState({
      cliente: "",
      subtipo: "",
      auxiliar: "",
      documento: "",
      tipo: "",
      poliza_select_text: "Seleccione una poliza",
      concepto: "",
      cargo: "",
      abono: "",
      movimientos: [],
      tipoMov: [],
      listaSubtipos: [],
      nombreCliente: "",
      referencia: "",
      listaMovimientos: [],
    });
  };

  handleAgregar = () => {
    const today = new Date();
    const cadena = today.toISOString();
    const cadenaExtraida = cadena.substring(0, 10);
    const fecha = document.getElementById("fecha_poliza").value;
  
    if (fecha === cadenaExtraida) {
      let warningMessage = "";
      if (this.state.cliente === "") {
        warningMessage += "Cliente, ";
      }
      if (this.state.poliza_select_text === "Seleccione una poliza") {
        warningMessage += "Tipo de Poliza, ";
      }
      if (
        this.state.subtipo === "" ||
        this.state.subtipo === "Seleccione un subtipo"
      ) {
        warningMessage += "Subtipo, ";
      }
      if (this.state.concepto === "") {
        warningMessage += "Concepto, ";
      }
      if (this.state.auxiliar === "") {
        warningMessage += "Auxiliar, ";
      }
      if (this.state.documento === "") {
        warningMessage += "Documento, ";
      }
      if (
        this.state.tipoMovimiento === "" ||
        this.state.tipoMovimiento === "null" ||
        this.state.tipoMovimiento === null
      ) {
        warningMessage += "Tipo de Movimiento, ";
      }
  
      if (warningMessage !== "") {
        message.completeMessage(warningMessage.slice(0, -2)); // Mostrar campos faltantes al usuario
      } else {
        // Establecer "0.00" en cargo si está vacío
        const cargoValue = this.state.cargo === "" ? "0.00" : this.state.cargo;
        // Establecer "0.00" en abono si está vacío
        const abonoValue = this.state.abono === "" ? "0.00" : this.state.abono;
  
        let movimientos = this.state.movimientos;
        let data = {
          cliente: this.state.cliente,
          referencia: this.state.referencia,
          auxiliar: this.state.auxiliar,
          documento: this.state.documento,
          tipoMovimiento: this.state.tipoMovimiento,
          cargo: cargoValue,
          abono: abonoValue,
        };
  
        movimientos.push(data);
        this.setState({ movimientos: movimientos });
  
        // Limpiar cargo y abono después de agregar al estado movimientos
        this.setState({
          cargo: "",
          abono: "",
        });
      }
    } else {
      message.warningMessage("Por favor, ingrese la fecha actual");
    }
  };
  

  postSubtiposIngresos() {
    const clave = "1";
    axios
      .post(utility.url_subtipos, {
        clave: clave,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              listaSubtipos: response.data.results,
            });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  postSubtiposEgresos() {
    axios
      .post(utility.url_subtipos, {
        clave: "2",
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              listaSubtipos: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  postSubtiposDiario() {
    axios
      .post(utility.url_subtipos, {
        clave: "3",
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              listaSubtipos: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  getMovimientosIngresos() {
    const url = utility.url_mov_Ingresos;
    axios
      .get(url)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              listaMovimientos: response.data.results,
            });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  getMovimientosEgresos() {
    const url = utility.url_mov_Egresos;
    axios
      .get(url)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              listaMovimientos: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  getMovimientosDiario() {
    const url = utility.url_Diario;
    axios
      .get(url)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              listaMovimientos: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  handleTipo = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let poliza_select_text = e[label];
    let poliza_select_id = e[label_id];
    if (poliza_select_id + poliza_select_text === "1Ingresos") {
      this.setState({ poliza_select_text, poliza_select_id });
      this.setState({ polizaTipos: "Ig" });
      this.postSubtiposIngresos();
      this.getMovimientosIngresos();
    }
    if (poliza_select_id + poliza_select_text === "2Egresos") {
      this.setState({ poliza_select_text, poliza_select_id });
      this.setState({ polizaTipos: "Eg" });
      this.postSubtiposEgresos();
      this.getMovimientosEgresos();
    }
    if (poliza_select_id + poliza_select_text === "3Diario") {
      this.setState({ poliza_select_text, poliza_select_id });
      this.setState({ polizaTipos: "Dr" });
      this.postSubtiposDiario();
      this.getMovimientosDiario();
    }
  };

  handleSubtipo = (e) => {
    let subtipo = e.target.value;
    this.setState({ subtipo });
  };

  handleEliminar = (tipoMovimiento) => {
    const toFind = tipoMovimiento;
    const buscar = tipoMovimiento.slice(0, 1);
    const movimientos = this.state.movimientos.filter(
      (movimiento) => movimiento.tipoMovimiento !== toFind
    );
    const mov = this.state.tipoMov.filter(
      (movimiento) => movimiento.tpo_mov !== buscar
    );
    this.setState({
      movimientos: movimientos,
      tipoMov: mov,
    });
  };

  handleSubClave = (clave) => {
    this.setState({ subtipoclave: clave });
  };

  handleConfirmacion = (e) => {
    e.preventDefault();

    if (this.sumarAbonos() !== this.sumarCargos()) {
      message
        .message_confirmacion_password(
          "Cargo y Abono son diferentes. ¿Desea continuar?",
          "Ingrese su contraseña y observaciones"
        )
        .then((res) => {
          if (res === true) {
            let mov = {
              user: document.getElementById("usuario_modificador").value,
              pass: document.getElementById("contraseña_modificador").value,
              motivo: document.getElementById("observaciones_textarea").value,
            };
            if (mov.motivo.length === 0)
              swal("Completar el campo:", "Motivo de Notificación", "warning");
            else
              axios
                .post(utility.url_verify, {
                  usuario: mov.user,
                  password: mov.pass,
                })
                .then((response) => {
                  if (response.status >= 200 && response.status < 400) {
                    if (this._isMounted) {
                      //Comienza el post para crear poliza

                      axios
                        .post(utility.url_crea_poliza, {
                          tipo: this.state.polizaTipos,
                          subtipo: this.state.subtipoclave,
                          fecha: utility.get_date_actual().fecha,
                          concepto: this.state.concepto,
                          movimientos: this.state.tipoMov,
                          observaciones: mov.motivo,
                        })
                        .then((response) => {
                          if (response.status >= 200 && response.status < 400) {
                            if (this._isMounted) {
                              swal({
                                icon: "success",
                                title: response.data.title,
                                text: response.data.text,
                                button: true,
                              });
                            }
                            this.props.update(1);
                            this.handleCerrar();
                            this.setState({
                              movimientos: [],
                              tipoMov: [],
                            });
                          }
                        })
                        .catch((error) => {
                          if (error.response)
                            message.message_modal(
                              error.response.data.title,
                              error.response.data.text,
                              error.response.status
                            );
                        });
                    }
                  }
                })
                .catch((error) => {
                  if (error.response)
                    message.message_modal(
                      error.response.data.title,
                      error.response.data.text,
                      error.response.status
                    );
                });
          }
        });
    } else {
      this.handleAltaPoliza();
    }
  };

  handleAltaPoliza = () => {
    message
      .message_confirmacion_password(
        "¿Desea continuar con la operación alta?",
        "Ingrese su contraseña y observaciones"
      )
      .then((res) => {
        if (res === true) {
          let mov = {
            user: document.getElementById("usuario_modificador").value,
            pass: document.getElementById("contraseña_modificador").value,
            motivo: document.getElementById("observaciones_textarea").value,
          };
          if (mov.motivo.length === 0)
            swal("Completar el campo:", "Motivo de Notificación", "warning");
          else
            axios
              .post(utility.url_verify, {
                usuario: mov.user,
                password: mov.pass,
              })
              .then((response) => {
                if (response.status >= 200 && response.status < 400) {
                  if (this._isMounted) {
                    //Comienza el post para crear poliza

                    axios
                      .post(utility.url_crea_poliza, {
                        tipo: this.state.polizaTipos,
                        subtipo: this.state.subtipoclave,
                        fecha: utility.get_date_actual().fecha,
                        concepto: this.state.concepto,
                        movimientos: this.state.tipoMov,
                        observaciones: mov.motivo,
                      })
                      .then((response) => {
                        if (response.status >= 200 && response.status < 400) {
                          if (this._isMounted) {
                            swal({
                              icon: "success",
                              title: response.data.title,
                              text: response.data.text,
                              button: true,
                            });
                          }
                          this.props.update(1);
                          this.setState({
                            movimientos: [],
                            tipoMov: [],
                          });
                        }
                      })
                      .catch((error) => {
                        if (error.response)
                          message.message_modal(
                            error.response.data.title,
                            error.response.data.text,
                            error.response.status
                          );
                      });
                  }
                }
              })
              .catch((error) => {
                if (error.response)
                  message.message_modal(
                    error.response.data.title,
                    error.response.data.text,
                    error.response.status
                  );
              });
        }
      });
  };

  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") text_add = "";
    else if (numero_cliente.length <= 4) {
      switch (numero_cliente.length) {
        case 1:
          text_add = "000";
          text_add += numero_cliente;
          break;
        case 2:
          text_add = "00";
          text_add += numero_cliente;
          break;
        case 3:
          text_add = "0";
          text_add += numero_cliente;
          break;
        case 4:
          text_add = numero_cliente;
          break;
        default:
          return null;
      }
    } else text_add = "";
    for (let i = 0; i < this.props.clientes.length; i++) {
      if (text_add.length === 0) {
        document.getElementById("nombre_cliente_poliza").value = "";
        this.suggestionSelected("");
        break;
      }
      if (text_add === this.props.clientes[i].numero) {
        document.getElementById("nombre_cliente_poliza").value =
          this.props.clientes[i].nombre;
        this.suggestionSelected(this.props.clientes[i].nombre);
        break;
      }
    }
  };
  suggestionSelected = (value) => {
    let number = utility.get_number_cliente(value, this.props.clientes);
    if (number === undefined) number = "";
    document.getElementById("num_cliente_poliza").value = number;
    this.setState({
      nombreCliente: value,
      cliente: number,
      nombre_suggestions: [],
    });
  };
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_nombres_clientes(this.props.clientes)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      nombre_suggestions: suggestions,
      nombreCliente: value,
    });
  };
  renderSuggestion = () => {
    if (this.state.nombre_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.resultsSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  /* ------ Termina bloque de funciones del autocompletado de nombre de cliente dependiendo su número ------ */

  sumarAbonos() {
    let totalAbonos = 0;
    this.state.movimientos.forEach((mov) => {
      // Verificar si el valor es numérico antes de sumarlo
      if (!isNaN(parseFloat(mov.abono))) {
        totalAbonos += parseFloat(mov.abono);
      }
    });
    return totalAbonos.toFixed(2);
  }

  sumarCargos() {
    let totalCargos = 0;
    this.state.movimientos.forEach((mov) => {
      // Verificar si el valor es numérico antes de sumarlo
      if (!isNaN(parseFloat(mov.cargo))) {
        totalCargos += parseFloat(mov.cargo);
      }
    });
    return totalCargos.toFixed(2);
  }

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_nuevo}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>Alta Poliza</ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="num_cliente_poliza"
                onChange={this.nameGenerator}
                required
                value={this.state.cliente}
                nameLabel="Número de cliente"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="nombre_cliente_poliza"
                onChange={this.onNameChange}
                required
                value={this.state.nombreCliente}
                nameLabel="Nombre de cliente"
              />
              {this.renderSuggestion()}
            </FormGroup>
            <FormGroup className="row mb-0">
              <CustomSelect
                bootstrap="col-lg-6 col-12"
                name_text="nombre"
                name_index="clave"
                id="tipo_alta_poliza"
                nameLabel="Tipo"
                value={this.state.poliza_select_text}
                onChange={this.handleTipo}
                options={this.state.tipos_poliza}
              />
              <MySelect
                bootstrap="col-lg-6 col-12"
                nameLabel="Subtipo"
                first_option="Seleccione un subtipo"
                required
                id="subtipo_alta_poliza"
                value={this.state.subtipo}
                onChange={this.handleSubtipo}
              >
                {this.state.listaSubtipos.map((sub) => (
                  <option
                    key={sub.id}
                    onClick={() => this.handleSubClave(sub.clave)}
                  >
                    {sub.nombre}
                  </option>
                ))}
              </MySelect>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="fecha_poliza"
                nameLabel="Fecha Actual"
                value={utility.get_date_actual().fecha}
                disabled
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="concepto_alta_poliza"
                nameLabel="Concepto"
                value={this.state.concepto}
                onChange={this.handleConcepto}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-10 col-12"
                id="auxiliar_poliza"
                nameLabel="Auxiliar"
                value={this.state.auxiliar}
                onChange={this.handleAuxiliar}
              />
              <Button
                color="primary"
                className="col-lg-2 col-12 mb-2"
                onClick={this.toogleAuxiliar}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="referencia_poliza"
                nameLabel="Referencia"
                value={this.state.referencia}
                onChange={this.handleReferencia}
              />

              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="documento_poliza"
                nameLabel="Documento"
                value={this.state.documento}
                onChange={this.handleDocumento}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-2 col-12"
                id="cargo_poliza"
                nameLabel="Cargo"
                value={this.state.cargo}
                onChange={this.handleCargo}
                onBlur={this.handleCargoBlur}
                disabled={this.state.abono != "" ? true : false}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-2 col-12"
                id="abono_poliza"
                nameLabel="Abono"
                value={this.state.abono}
                onChange={this.handleAbono}
                onBlur={this.handleAbonoBlur}
                disabled={this.state.cargo != "" ? true : false}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MySelect
                bootstrap="col-lg-10 col-12"
                nameLabel="Catálogo de Movimientos"
                first_option="Seleccione un movimiento"
                required
                id="lista_movimientos"
                value={this.state.tipoMovimiento}
                onChange={this.handleTipoMov}
              >
                {this.state.listaMovimientos.map((mov) => (
                  <option key={mov.clave}>
                    {mov.clave} - {mov.nombre}
                  </option>
                ))}
              </MySelect>
              <Button
                color="primary"
                className="col-lg-2 col-12 mb-2"
                onClick={this.handleAgregar}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </FormGroup>
          </Form>
          <Table size="sm" id="tabla_poliza" bordered hover responsive>
            <thead className="text-center header-table">
              <tr>
                <th>Cliente</th>
                <th>Referencia</th>
                <th>Auxiliar</th>
                <th>Documento</th>
                <th>Tipo de Mov.</th>
                <th>Cargo</th>
                <th>Abono</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="body-table">
              {this.state.movimientos.map((mov) => (
                <tr key={mov.tipoMovimiento}>
                  <td>
                    <p className="mb-0 text-center">{mov.cliente}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{mov.referencia}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{mov.auxiliar}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{mov.documento}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{mov.tipoMovimiento}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{mov.cargo}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{mov.abono}</p>
                  </td>
                  <td className="text-center">
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => this.handleEliminar(mov.tipoMovimiento)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="5"></td>
                <td>
                  <p
                    id="tooltip_poliza"
                    className={`mb-0 text-center font-weight-bold ${
                      this.sumarAbonos() !== this.sumarCargos()
                        ? "text-danger"
                        : this.sumarCargos() !== "0.00"
                        ? "text-success"
                        : "text-black"
                    }`}
                  >
                    Total: {this.sumarCargos()}
                  </p>
                </td>
                <td>
                  <p
                    id="tooltip_poliza"
                    className={`mb-0 text-center font-weight-bold ${
                      this.sumarAbonos() !== this.sumarCargos()
                        ? "text-danger"
                        : this.sumarCargos() !== "0.00"
                        ? "text-success"
                        : "text-black"
                    }`}
                  >
                    Total: {this.sumarAbonos()}
                  </p>
                </td>
              </tr>
            </tbody>
            <div className="text-center" data-toggle="tooltip">
              {this.sumarAbonos() !== this.sumarCargos() && (
                <Tooltip
                  placement="top"
                  isOpen={this.state.showTooltip}
                  target="tooltip_poliza"
                  toggle={this.toggleTooltip}
                >
                  Existe diferencia entre Cargo y Abono.
                </Tooltip>
              )}
            </div>
          </Table>

          {/* <div className="d-flex justify-content-center">
            <LinkD href="#" id="dowload_pdf_consulta_poliza" download={`Poliza ${this.state.cliente}`} />
                        <LinkV href="#" id="window_pdf_consulta_poliza" target={this.state.enable_pdf ? "_blank" : "_self"} className="ml-5" /> 
          </div> */}
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={this.handleConfirmacion}
            disabled={this.state.movimientos.length === 0 ? true : false}
          >
            Aceptar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
        <ModalAuxiliar
          toogle_catalogo={this.state.toogle_auxiliar}
          toogleCatalogo={this.toogleAuxiliar}
          listaAuxiliares={this.state.auxiliares}
          parentCallback={this.handleCallback}
        />
      </Modal>
    );
  }
}
export default ModalAlta;
