/* 
    Descripción: Componente único para el modal, genera la alta de un anticipo. 
*/
import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Collapse,
  Card,
  Table,
  CardBody,
  Alert,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import * as utility from "../utils/utility";
import * as message from "../messages/messages";
import axios from "axios";
import swal from "@sweetalert/with-react";
import * as permisos from "../../../utils/permisos/permisos";
import { faFileDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WebSocketInstance from "../../../utils/websocket";
import MyInput from "../../../utils/custom/MyInput";
import MySelect from "../../../utils/custom/MySelect";
import MyTextArea from "../../../utils/custom/MyTextArea";
import CustomSelect from "../../../utils/custom/CustomSelect";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalDevCon extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      toogle_consolida: false,
      toogle_devuelvo: false,
      toogle_cancelo: false,
      nombre_suggestions: [],
      operacionesAnticipos: [],
      operacionesAnt_select_id: "",
      operacionesAnt_select_text: "Seleccione una operación",
      tiposDevolucion_select_text: "",
      arr_operacionesAnt: [
        { clave: 0, nombre: "Cancelación" },
        { clave: 1, nombre: "Consolidación" },
        { clave: 2, nombre: "Devolución" },
      ],
      arr_tiposDevolucion: [
        { clave: 0, nombre: "Devolución por transferencia" },
        { clave: 1, nombre: "Devolución por cheque" },
      ],
      operaciones_ant: [],
      fechaInicial: "",
      fechaFinal: "",
      status: "",
      numAnticipo: Number,
      anticiposList: [],
      banco: "",
      imported: "",
      numeroCliente: "",
      nombreCliente: "",
      numAnticipo: "",
      numerosAnt: [],
      movimientos: [],
    };
    this.toogleConso = this.toogleConso.bind(this);
    this.toogleDev = this.toogleDev.bind(this);
    this.toogleCan = this.toogleCan.bind(this);
    this.AnticiposList = this.AnticiposList.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folios = [];
    let clientes = [];
    this.props.checked.forEach((data) => folios.push(data.numero));
    this.props.checked.forEach((data) => clientes.push(parseInt(data.cliente)));
    const notificacionObject = {
      tipo: 0,
      aplicacion: "anticipo",
      motivo: motivo,
      folio: folios,
    };
    if (motivo === "devolver" && this.props.on_cliente === false)
      notificacionObject["cliente"] = clientes;
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  toogleConso = () => {
    this.setState((prevState) => ({
      toogle_consolida: !prevState.toogle_consolida,
      toogle_devuelvo: false,
      toogle_cancelo: false,
    }));
  };
  toogleDev = () => {
    this.setState((prevState) => ({
      toogle_devuelvo: !prevState.toogle_devuelvo,
      toogle_consolida: false,
      toogle_cancelo: false,
    }));
  };
  toogleCan = () => {
    this.setState((prevState) => ({
      toogle_cancelo: !prevState.toogle_cancelo,
      toogle_consolida: false,
      toogle_devuelvo: false,
    }));
  };
  handleConsolida = () => {
    message
      .message_confirmacion_only_password(
        "¿Desea continuar con la operación Consolidación?",
        "Ingrese su contraseña"
      )
      .then((res) => {
        if (res === true) {
          let mov = {
            user: document.getElementById("usuario_modificador").value,
            pass: document.getElementById("contraseña_modificador").value,
          };
          axios
            .post(utility.url_verify, {
              usuario: mov.user,
              password: mov.pass,
            })
            .then((response) => {
              if (response.status >= 200 && response.status < 400) {
                axios
                  .post(utility.url_consolidar, {
                    numeros: this.state.numerosAnt,
                  })
                  .then((response) => {
                    if (response.status >= 200 && response.status < 400) {
                      if (this._isMounted) {
                        swal({
                          icon: "success",
                          title: response.data.title,
                          text: response.data.text+" "+response.data.numeros,
                          button: true,
                        });
                        this.setState({movimientos: []});
                      }
                    }
                  })
                  .catch((error) => {
                    if (error.response)
                      message.message_modal(
                        error.response.data.title,
                        error.response.data.text,
                        error.response.status
                      );
                  });
              }
            })
            .catch((error) => {
              if (error.response)
                message.message_modal(
                  error.response.data.title,
                  error.response.data.text,
                  error.response.status
                );
            });
        }
      });
  };

  handleDevolver = () => {
    let tipoDevolucion = null;

    if (
      this.state.tiposDevolucion_select_text === "Devolución por transferencia"
    ) {
      tipoDevolucion = "DT";
    } else {
      tipoDevolucion = "DC";
    }

    message
      .message_confirmacion_only_password(
        "¿Desea continuar con la operación Devolución?",
        "Ingrese su contraseña"
      )
      .then((res) => {
        if (res === true) {
          let mov = {
            user: document.getElementById("usuario_modificador").value,
            pass: document.getElementById("contraseña_modificador").value,
          };
          axios
            .post(utility.url_verify, {
              usuario: mov.user,
              password: mov.pass,
            })
            .then((response) => {
              if (response.status >= 200 && response.status < 400) {
                axios
                  .post(utility.url_devolver, {
                    numeros: this.state.numerosAnt,
                    tpo_devolucion: tipoDevolucion,
                  })
                  .then((response) => {
                    if (response.status >= 200 && response.status < 400) {
                      if (this._isMounted) {
                        swal({
                          icon: "success",
                          title: response.data.title,
                          text: response.data.text+" "+response.data.numeros,
                          button: true,
                        });
                        this.setState({movimientos: []});
                      }
                    }
                  })
                  .catch((error) => {
                    if (error.response)
                      message.message_modal(
                        error.response.data.title,
                        error.response.data.text,
                        error.response.status
                      );
                  });
              }
            })
            .catch((error) => {
              if (error.response)
                message.message_modal(
                  error.response.data.title,
                  error.response.data.text,
                  error.response.status
                );
            });
        }
      });
  };

  handleCancelacion = () => {
    message
      .message_confirmacion_password(
        "¿Desea continuar con la operación Cancelación?",
        "Ingrese su contraseña y observaciones"
      )
      .then((res) => {
        if (res === true) {
          let mov = {
            user: document.getElementById("usuario_modificador").value,
            pass: document.getElementById("contraseña_modificador").value,
            motivo: document.getElementById("observaciones_textarea").value,
          };
          if (mov.motivo.length === 0)
            swal("Completar el campo:", "Motivo de Notificación", "warning");
          else
            axios
              .post(utility.url_verify, {
                usuario: mov.user,
                password: mov.pass,
              })
              .then((response) => {
                if (response.status >= 200 && response.status < 400) {
                  axios
                    .post(utility.url_cancelar, {
                      numeros: this.state.numerosAnt,
                      observaciones: mov.motivo,
                    })
                    .then((response) => {
                      if (response.status >= 200 && response.status < 400) {
                        if (this._isMounted) {
                          swal({
                            icon: "success",
                            title: response.data.title,
                            text: response.data.text+" "+response.data.numeros,
                            button: true,
                          });
                          this.setState({movimientos: []});
                        }
                      }
                    })
                    .catch((error) => {
                      if (error.response)
                        message.message_modal(
                          error.response.data.title,
                          error.response.data.text,
                          error.response.status
                        );
                    });
                }
              })
              .catch((error) => {
                if (error.response)
                  message.message_modal(
                    error.response.data.title,
                    error.response.data.text,
                    error.response.status
                  );
              });
        }
      });
  };

  handleCaseOperations = () => {
    if (this.state.operacionesAnt_select_text === "Consolidación") {
      this.handleConsolida();
    }
    if (this.state.operacionesAnt_select_text === "Devolución") {
      this.handleDevolver();
    }
    if (this.state.operacionesAnt_select_text === "Cancelación") {
      this.handleCancelacion();
    }
  };

  AnticiposList(e) {
    this.setState({ numAnticipo: e.target.value });
    if (
      this.state.operacionesAnt_select_text === "Consolidación" ||
      this.state.operacionesAnt_select_text === "Cancelación"
    ) {
      const status = "A";
      this.PostAnticiposList(status);
    }
    if (this.state.operacionesAnt_select_text === "Devolución") {
      const status = "C";
      this.PostAnticiposList(status);
    }
  }

  PostAnticiposList(status) {

    let datos = {};

    // Caso cuando tenemos fecha inicial y final
    if (this.state.fechaInicial !== "" && this.state.fechaFinal !== "") {
      //Juntar fechas
      const fechaIni = this.state.fechaInicial;
      const fechaFin = this.state.fechaFinal;
      const fechas = fechaIni + "," + fechaFin;

      datos.fechas = fechas;
    }

    // Caso cuando tenemos fecha inicial pero no final
    if (this.state.fechaInicial !== "" && this.state.fechaFinal === "") {
      //Obtener fecha actual
      const today = new Date();
      const cadena = today.toISOString();
      const cadenaExtraida = cadena.substring(0, 10);

      //Juntar fechas
      const fechaIni = this.state.fechaInicial;
      const fechaFin = cadenaExtraida;
      const fechas = fechaIni + "," + fechaFin;

      this.setState({ fechaFinal: cadenaExtraida });

      datos.fechas = fechas;
    }

    // Caso cuando tenemos fecha final pero no inicial
    if (this.state.fechaInicial === "" && this.state.fechaFinal !== "") {
      //Juntar fechas
      const fechaIni = "0001-01-01";
      const fechaFin = this.state.fechaFinal;
      const fechas = fechaIni + "," + fechaFin;

      this.setState({ fechaInicial: "0001-01-01" });

      datos.fechas = fechas;
    }

    let complemento = {
      cliente: this.state.numeroCliente,
      status,
      numero: this.state.numAnticipo,
    }
    
    let dataJson = Object.assign(datos, complemento);

    axios
      .post(utility.url_lista_anticipos, dataJson)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              anticiposList: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
        this.setState({
          numAnticipo: Number,
        });
      });
  }

  renderAnticiposList = () => {
    return (
      <ListGroup className="col-10" flush>
        {this.state.anticiposList.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.resultsSelectedList(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };

  resultsSelectedList = (data) => {
    this.setState({
      numAnticipo: data,
      anticiposList: [],
    });
  };

  postBancoImporteAnticipos = () => {
    const numAnticipo = this.state.numAnticipo;
    const clientNum = document.getElementById("numero_cliente_anticipo").value;
    axios
      .post(utility.url_consulta_anticipo, {
        numero: numAnticipo,
        cliente: clientNum,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          
          if (this._isMounted) {
            /* const movimientos = this.state.operacionesAnticipos; */
            console.log(this._isMounted)
            const movimientos = Array.from(this.state.movimientos);
            console.log(movimientos)
            const data = {
              numAnticipo: this.state.numAnticipo,
              numeroCliente: this.state.numeroCliente,
              nombreCliente: this.state.nombreCliente,
              banco: response.data.results.banco,
              importe: response.data.results.importe,
            };
            movimientos.push(data);
            console.log(movimientos)
            this.setState({ movimientos: movimientos });
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handleCleanNumAnt = () => {
    this.setState({ numAnticipo: Number });
  };

  handleAgregar = () => {
    if (
      this.state.operacionesAnt_select_text === "Devolución" &&
      this.state.tiposDevolucion_select_text === "Seleccione un tipo"
    ) {
      message.warningMessage("Por favor, ingrese los campos faltantes");
    } else {
      if (
        this.state.operacionesAnt_select_text === "Seleccione una operación" ||
        this.state.numeroCliente === "" ||
        this.state.nombreCliente === "" ||
        this.state.numAnticipo === Number ||
        this.state.numAnticipo === ""
      ) {
        message.warningMessage("Por favor, ingrese los campos faltantes");
      } else {
        this.postBancoImporteAnticipos();

        let num = this.state.numerosAnt;
        let numData = this.state.numAnticipo;
        num.push(numData);
      }
    }
  };

  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consolidar":
              this.props.toogleDevCon();
              break;
            case "devolver":
              this.props.toogleDevCon();
              break;
            case "cancelar":
              this.props.toogleDevCon();
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  methodPDF_API = (url, state, num) => {
    let numeros = "" + num.map((numero) => numero);
    let url_intern = url + `${numeros}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "devolucion":
              if (num.length > 1) {
                const file = new Blob([response.data], {
                  type: "application/zip",
                });
                const fileURL = URL.createObjectURL(file);
                document.getElementById(`dowload_pdf_${state}_anticipo`).href =
                  fileURL;
              } else {
                const file = new Blob([response.data], {
                  type: "application/pdf",
                });
                const fileURL = URL.createObjectURL(file);
                document.getElementById(`dowload_pdf_${state}_anticipo`).href =
                  fileURL;
                document.getElementById(`window_pdf_${state}_anticipo`).href =
                  fileURL;
              }
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") text_add = "";
    else if (numero_cliente.length <= 4) {
      switch (numero_cliente.length) {
        case 1:
          text_add = "000";
          text_add += numero_cliente;
          break;
        case 2:
          text_add = "00";
          text_add += numero_cliente;
          break;
        case 3:
          text_add = "0";
          text_add += numero_cliente;
          break;
        case 4:
          text_add = numero_cliente;
          break;
        default:
          return null;
      }
    } else text_add = "";
    for (let i = 0; i < this.props.clientes.length; i++) {
      if (text_add.length === 0) {
        document.getElementById("nombre_cliente_anticipo").value = "";
        this.suggestionSelected("");
        break;
      }
      if (text_add === this.props.clientes[i].numero) {
        document.getElementById("nombre_cliente_anticipo").value =
          this.props.clientes[i].nombre;
        this.suggestionSelected(this.props.clientes[i].nombre);
        break;
      }
    }
  };
  suggestionSelected = (value) => {
    let number = utility.get_number_cliente(value, this.props.clientes);
    if (number === undefined) number = "";
    document.getElementById("numero_cliente_anticipo").value = number;
    this.setState({
      nombreCliente: value,
      numeroCliente: number,
      nombre_suggestions: [],
    });
  };
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_nombres_clientes(this.props.clientes)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      nombre_suggestions: suggestions,
      nombreCliente: value,
    });
  };
  renderSuggestion = () => {
    if (this.state.nombre_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.resultsSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  renderResult = () => {
    if (this.state.results.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.results.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.resultsSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };

  handleoperacionesAnt_PT = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let operacionesAnt_select_text = e[label];
    let operacionesAnt_select_id = e[label_id];
    this.setState({ operacionesAnt_select_text, operacionesAnt_select_id });
    if (operacionesAnt_select_text != "Devolución") {
      this.setState({ tiposDevolucion_select_text: "" });
    }
    if (operacionesAnt_select_text === "Devolución") {
      this.setState({ tiposDevolucion_select_text: "Seleccione un tipo" });
    }
  };

  handletiposDevolucion = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tiposDevolucion_select_text = e[label];
    let tiposDevolucion_select_id = e[label_id];
    this.setState({ tiposDevolucion_select_text, tiposDevolucion_select_id });
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */

  handleEliminar = (numeroCliente) => {
    let movimientos = this.state.movimientos;
    console.log(numeroCliente, movimientos);
    for (let i = 0; i < movimientos.length; i++)
      if (movimientos[i].numeroCliente === numeroCliente)
        console.log(movimientos.splice(i, 1));
    this.setState({
      movimientos: movimientos,
    });
  };

  handleConsolidar = () => {
    swal("Consolidación exitosa", message, "success");
    /* message.message_confimacion('¿Desea continuar con la operación alta?', '')
            .then(res => {
                    if (res) {
                    let message = "";
                    let sol = utility.get_data_solicitud_anticipo();
                    if (sol.aduana === "")
                        message += 'Aduana, ';
                    if (sol.referencia === "")
                        message += 'Referencia, ';
                    if (sol.observaciones === "")
                        message += 'Observaciones';
                    if (message.length === 0) {
                        let data;
                        if (this.state.movimientos_new_sol.length > 0) {
                            let importe = 0;
                            this.state.movimientos_new_sol.forEach(mov => importe += parseFloat(mov.monto))
                            data = { ...utility.get_data_solicitud_anticipo(), movimientos: this.state.movimientos_new_sol, importe: importe };
                        }
                        else
                            data = { ...utility.get_data_solicitud_anticipo(), importe: 0 };
                        this.methodPOST_API(utility.url_crear_modifica_solicitud, 'alta', data);
                    }
                    else
                        swal('Completar los campos:', message, 'warning');
                } 
            }); */
  };

  handleFechaI = (e) => {
    let fechaInicial = e.target.value;
    this.setState({ fechaInicial });
  };

  handleFechaF = (e) => {
    let fechaFinal = e.target.value;
    this.setState({ fechaFinal });
  };

  handleCerrar = () => {
    this.props.toogleDevCon();
    this.handleLimpiar();
  };

  handleLimpiar = () => {
    this.setState({
      toogle_consolida: false,
      toogle_devuelvo: false,
      toogle_cancelo: false,
      nombre_suggestions: [],
      operacionesAnticipos: [],
      operacionesAnt_select_id: "",
      operacionesAnt_select_text: "Seleccione una operación",
      tiposDevolucion_select_text: "",
      arr_operacionesAnt: [
        { clave: 0, nombre: "Cancelación" },
        { clave: 1, nombre: "Consolidación" },
        { clave: 2, nombre: "Devolución" },
      ],
      arr_tiposDevolucion: [
        { clave: 0, nombre: "Devolución por transferencia" },
        { clave: 1, nombre: "Devolución por cheque" },
      ],
      operaciones_ant: [],
      fechaInicial: "",
      fechaFinal: "",
      status: "",
      numAnticipo: Number,
      anticiposList: [],
      banco: "",
      imported: "",
      numeroCliente: "",
      nombreCliente: "",
      numAnticipo: "",
      numerosAnt: [],
      movimientos: [],
    });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_dev_con}
        toggle={this.props.toogleDevCon}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.props.toogleDevCon}>
          Operaciones De Anticipos
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <CustomSelect
                bootstrap="col-lg-6 col-12"
                name_text="nombre"
                name_index="clave"
                id="operaciones_anticipos"
                nameLabel="Operaciones de anticipos"
                required
                value={this.state.operacionesAnt_select_text}
                onChange={this.handleoperacionesAnt_PT}
                options={this.state.arr_operacionesAnt}
              />
              <CustomSelect
                bootstrap="col-lg-6 col-12"
                name_text="nombre"
                name_index="clave"
                id="tipos_devolucion_anticipos"
                nameLabel="Tipos de devolución"
                required
                value={this.state.tiposDevolucion_select_text}
                onChange={this.handletiposDevolucion}
                options={this.state.arr_tiposDevolucion}
                disabled={
                  this.state.operacionesAnt_select_text ===
                  "Seleccione una operación"
                    ? true
                    : false ||
                      this.state.operacionesAnt_select_text === "Cancelación"
                    ? true
                    : false ||
                      this.state.operacionesAnt_select_text === "Consolidación"
                    ? true
                    : false
                }
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="numero_cliente_anticipo"
                onChange={this.nameGenerator}
                required
                value={this.state.numeroCliente}
                nameLabel="Número de cliente"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="nombre_cliente_anticipo"
                onChange={this.onNameChange}
                required
                value={this.state.nombreCliente}
                nameLabel="Nombre de cliente"
              />
              {this.renderSuggestion()}
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="date"
                bootstrap="col-lg-6"
                nameLabel="Fecha inicial"
                id="fechaInicial_anticipo_consol"
                required
                value={this.state.fechaInicial}
                onChange={this.handleFechaI}
              />
              <MyInput
                type="date"
                bootstrap="col-lg-6"
                nameLabel="Fecha final"
                id="fechaFinal_anticipo_consol"
                required
                value={this.state.fechaFinal}
                onChange={this.handleFechaF}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                bootstrap="col-lg-10"
                nameLabel="Número de anticipo"
                id="resultados_num_anticipos"
                required
                value={this.state.numAnticipo}
                onChange={this.AnticiposList}
              />
              <Button
                color="primary"
                className="col-lg-2 col-4 mb-2"
                onClick={this.handleAgregar}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
              {this.renderAnticiposList()}
            </FormGroup>
          </Form>
          <Table size="sm" bordered hover responsive>
            <thead className="text-center header-table">
              <tr>
                <td>Número Anticipo</td>
                <td>Número Cliente</td>
                <td>Cliente</td>
                <td>Banco</td>
                <td>Importe</td>
                <td></td>
              </tr>
            </thead>
            <tbody className="body-table">
              {this.state.movimientos.map((mov) => (
                <tr key={mov.numAnticipo}>
                  <td className="text-center">{mov.numAnticipo}</td>
                  <td className="text-center">{mov.numeroCliente}</td>
                  <td className="text-center">{mov.nombreCliente}</td>
                  <td className="text-center">{mov.banco}</td>
                  <td className="text-center">${mov.importe}</td>
                  <td className="text-center">
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => this.handleEliminar(mov.numeroCliente)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <ModalFooter>
            <Button
              color="success"
              onClick={this.handleCaseOperations}
              disabled={
                this.state.movimientos.length === 0 ? true : false
              }
            >
              Aceptar
            </Button>
            <Button color="info" onClick={this.handleCerrar}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    );
  }
}
export default ModalDevCon;

/**
 * PENDIENTES:
 * 1.- AGREGAR LOGICA PARA DESCARGAR .zip CUANDP HAY VARIOS PDF'S (CONSOLIDACIÓN)
 */
