/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la orden de pago. 
*/
import swal from "@sweetalert/with-react";
import axios from "axios";
import React, { Component } from "react";
import {
  Alert,
  Button,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap";
import * as message from "../../../utils/messages/message";
import * as utility from "../utils/utility";
import MyTextArea from "../utils/MyTextArea";
import MyInput from "../../../utils/custom/MyInput";
import CustomSelect from "../../../utils/custom/CustomSelect";
import MySelect from "../../../utils/custom/MySelect";

class   NotificacionDeposito extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      toogle_nueva: false,
      toogle_consulta: false,
      arr_ingresos: [],
      arr_pagos: [],
      arr_terceros: [],
      arr_bancos: [],
      sum_total: 0,
      banco_select_id: "",
      banco_select_text: "Seleccione un banco",
      id_deposito_select: "",
      importe_orden_select: "",
      solicitudes_orden_select: [],
      solicitudes_modify_orden: [],
      cliente_order_select: "",
      name_cliente_order_select: "",
      fecha_alta_orden_select: "",
      fecha_notificacion_orden_select: "",
      status_orden_select: "",
      sum_ingresos: 0,
      sum_contribuciones: 0,
      sum_terceros: 0,
      bancos: "",
    };
    this.toogleNueva = this.toogleNueva.bind(this);
    this.toogleConsulta = this.toogleConsulta.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_bancos, "bancos");
    this.methodGET_API(utility.url_ingresos, "ingresos");
    this.methodGET_API(utility.url_contribuciones, "pagos");
    this.methodGET_API(utility.url_phxcc, "tercero");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps) {
    if (this.props.sum_solicitudes !== prevProps.sum_solicitudes) {
      this.setState({
        sum_total: this.props.sum_solicitudes,
      });
    }
  }
  /**
   * Función que nos permite cambiar el estado del colapse de Nueva orden de Pago.
   */
  toogleNueva = () => {
    this.setState((prevState) => ({
      toogle_nueva: !prevState.toogle_nueva,
      toogle_consulta: false,
    }));
  };
  /**
   * Función que nos permite cambiar el estado del colapse de Consulta orden de Pago.
   */
  toogleConsulta = () => {
    this.setState((prevState) => ({
      toogle_consulta: !prevState.toogle_consulta,
      toogle_nueva: false,
    }));
  };

  handleSum = (e) => {
    this.setState({
      sum_total: e.target.value,
    });
  };
  handleModSum = (e) => {
    this.setState({
      importe_orden_select: e.target.value,
    });
  };
  handleBanco = (e) => {
    const selectedValue = e.target.value;
    const [numero] = selectedValue.split(" - ");
    this.setState({
      bancos: numero,
    });
  };
  handleModificar = (e) => {
    swal({
      icon: "warning",
      closeOnClickOutside: false,
      title: "Verificar",
      text: "Ingresar su contraseña:",
      buttons: ["Cancelar", true],
      dangerMode: true,
      content: (
        <div>
          <div className="grupo col-lg-12 col-8 p-0">
            <input
              type="text"
              className="input-animated input-animatedd col-12 mb-2"
              id="user_orden_modificar"
              value={`${localStorage.getItem("user_name")}Admin`}
              disabled
              required
            />
            <span className="barra"></span>
            <br />
            <label className="label-animated" htmlFor="user_orden_modificar">
              Usuario
            </label>
          </div>
          <div className="grupo col-lg-12 col-8 p-0">
            <input
              type="password"
              className="input-animated col-12 mb-2"
              id="pass_orden_modificar"
              required
            />
            <span className="barra"></span>
            <br />
            <label className="label-animated" htmlFor="pass_orden_modificar">
              Contraseña
            </label>
          </div>
          <div className="grupo col-lg-12 p-0" style={{ height: "90px" }}>
            <textarea
              className="input-animated col-12"
              id="mod_orden_modificar"
              style={{ resize: "none" }}
              required
            ></textarea>
            <span className="barra"></span>
            <br />
            <label className="label-animated" htmlFor="mod_orden_modificar">
              Observaciones
            </label>
          </div>
        </div>
      ),
    }).then((res) => {
      if (res === true) {
        let mov = {
          pass: document.getElementById("pass_orden_modificar").value,
          motivo: document.getElementById("mod_orden_modificar").value,
          user: document.getElementById("user_orden_modificar").value,
        };
        if (mov.motivo.length === 0)
          swal("Copletar el campo:", "Motivo de Notificación", "warning");
        else {
          axios
            .post(utility.url_verify, { usuario: mov.user, password: mov.pass })
            .then((response) => {
              if (response.status >= 200 && response.status < 400) {
                const notificar_POST = () => {
                  return axios.post(utility.url_crea_modifica_orden, {
                    id_deposito: this.state.id_deposito_select,
                    monto: this.state.importe_orden_select,
                    observaciones: document.getElementById(
                      "mod_orden_modificar"
                    ).value,
                    usuario: localStorage.getItem("id_usr"),
                  });
                };
                axios.all([notificar_POST()]).then(
                  axios.spread((response) => {
                    this.methodPOST_API(
                      utility.url_consulta_orden_especifica,
                      "orden_select",
                      {
                        id_deposito:
                          document.getElementById("id_deposito_select").value,
                      }
                    );
                    swal(
                      "La operación fue exitosa",
                      `El nuevo monto es: ${response.data.results[0].importe}`,
                      "success"
                    );
                  })
                );
              }
            })
            .catch((error) => {
              message.message_modal(
                error.response.data.title,
                error.response.data.text,
                error.response.status
              );
            });
        }
      }
    });
  };
  /**
   * Función que nos permite crear una nueva Orden de pago
   */
  handleAlta = () => {
    let solicitudes_ant = [];
    for (let i = 0; i < this.props.checked.length; i++)
      solicitudes_ant.push(
        this.props.checked[i].solicitud_ant[0].id_solicitud.toString()
      );
    let data = {
      importe: this.state.sum_total,
      solicitudes: solicitudes_ant,
      cliente: this.props.checked[0].cliente,
      observaciones: document.getElementById("observaciones_alta_deposito")
        .value,
      banco: this.state.bancos,
      tipo_deposito: "Sd",
    };
    if (utility.valida_data_deposito_anticipo(data).length > 0)
      message.message_modal(
        "Verificar datos",
        utility.valida_data_deposito_anticipo(data),
        400
      );
    else {
      this.methodPOST_API(utility.url_crea_modifica_orden, "crear_orden", data);
      this.props.update_pago(1);
    }
  };
  handleNotificar = (e) => {
    swal({
      icon: "warning",
      closeOnClickOutside: false,
      title: "Verificar",
      text: "Ingresar su contraseña:",
      buttons: ["Cancelar", true],
      dangerMode: true,
      content: (
        <div>
          <div className="grupo col-lg-12 col-8 p-0">
            <input
              type="text"
              className="input-animated input-animatedd col-12 mb-2"
              id="user_orden_notificar"
              value={`${localStorage.getItem("user_name")}Admin`}
              disabled
              required
            />
            <span className="barra"></span>
            <br />
            <label className="label-animated" htmlFor="user_orden_notificar">
              Usuario
            </label>
          </div>
          <div className="grupo col-lg-12 col-8 p-0">
            <input
              type="password"
              className="input-animated col-12 mb-2"
              id="pass_orden_notificar"
              required
            />
            <span className="barra"></span>
            <br />
            <label className="label-animated" htmlFor="pass_orden_notificar">
              Contraseña
            </label>
          </div>
          <div className="grupo col-lg-12 p-0" style={{ height: "90px" }}>
            <textarea
              className="input-animated col-12"
              id="mod_orden_notificar"
              style={{ resize: "none" }}
              required
            ></textarea>
            <span className="barra"></span>
            <br />
            <label className="label-animated" htmlFor="mod_orden_notificar">
              Observaciones
            </label>
          </div>
        </div>
      ),
    }).then((res) => {
      if (res === true) {
        let mov = {
          pass: document.getElementById("pass_orden_notificar").value,
          motivo: document.getElementById("mod_orden_notificar").value,
          user: document.getElementById("user_orden_notificar").value,
        };
        if (mov.motivo.length === 0)
          swal("Copletar el campo:", "Motivo de Notificación", "warning");
        else {
          axios
            .post(utility.url_verify, { usuario: mov.user, password: mov.pass })
            .then((response) => {
              if (response.status >= 200 && response.status < 400) {
                const notificar_POST = () => {
                  return axios.post(utility.url_notificar_orden, {
                    id_deposito:
                      document.getElementById("id_deposito_select").value,
                    observaciones: document.getElementById(
                      "mod_orden_notificar"
                    ).value,
                    usuario: document.getElementById("user_orden_notificar")
                      .value,
                  });
                };
                axios.all([notificar_POST()]).then(
                  axios.spread((put) => {
                    this.props.tooglePago();
                    this.clear_state_consulta();
                    swal(
                      "La operación fue exitosa",
                      `El nuevo monto es: ${put.data.results[0].importe}`,
                      "success"
                    );
                  })
                );
              }
            })
            .catch((error) => {
              message.message_modal(
                error.response.data.title,
                error.response.data.text,
                error.response.status
              );
            });
        }
      }
    });
  };
  handleCancelacion = (e) => {
    swal({
      icon: "warning",
      closeOnClickOutside: false,
      title: "Verificar",
      text: "Ingresar su contraseña:",
      buttons: ["Cancelar", true],
      dangerMode: true,
      content: (
        <div>
          <div className="grupo col-lg-12 col-8 p-0">
            <input
              type="text"
              className="input-animated input-animatedd col-12 mb-2"
              id="user_orden_cancelar"
              value={`${localStorage.getItem("user_name")}Admin`}
              disabled
              required
            />
            <span className="barra"></span>
            <br />
            <label className="label-animated" htmlFor="user_orden_cancelar">
              Usuario
            </label>
          </div>
          <div className="grupo col-lg-12 col-8 p-0">
            <input
              type="password"
              className="input-animated col-12 mb-2"
              id="pass_orden_cancelar"
              required
            />
            <span className="barra"></span>
            <br />
            <label className="label-animated" htmlFor="pass_orden_cancelar">
              Contraseña
            </label>
          </div>
          <div className="grupo col-lg-12 p-0" style={{ height: "90px" }}>
            <textarea
              className="input-animated col-12"
              id="mod_orden_cancelar"
              style={{ resize: "none" }}
              required
            ></textarea>
            <span className="barra"></span>
            <br />
            <label className="label-animated" htmlFor="mod_orden_cancelar">
              Observaciones
            </label>
          </div>
        </div>
      ),
    }).then((res) => {
      if (res === true) {
        let mov = {
          pass: document.getElementById("pass_orden_cancelar").value,
          motivo: document.getElementById("mod_orden_cancelar").value,
          user: document.getElementById("user_orden_cancelar").value,
        };
        if (mov.motivo.length === 0)
          swal("Copletar el campo:", "Motivo de Cancelación", "warning");
        else {
          axios
            .post(utility.url_verify, { usuario: mov.user, password: mov.pass })
            .then((response) => {
              if (response.status >= 200 && response.status < 400) {
                const notificar_POST = () => {
                  return axios.post(utility.url_cancelar_orden, {
                    id_deposito:
                      document.getElementById("id_deposito_select").value,
                    usuario: document.getElementById("user_orden_cancelar")
                      .value,
                    observaciones:
                      document.getElementById("mod_orden_cancelar").value,
                  });
                };
                axios.all([notificar_POST()]).then(
                  axios.spread((put) => {
                    this.props.tooglePago();
                    swal(
                      "La operación fue exitosa",
                      `Se cancelo la orden: ${this.state.id_deposito_select}`,
                      "success"
                    );
                    this.clear_state_consulta();
                  })
                );
              }
            })
            .catch((error) => {
              message.message_modal(
                error.response.data.title,
                error.response.data.text,
                error.response.status
              );
            });
        }
      }
    });
  };
  handleOrdenDeposito = (e) => {
    if (this.props.on_cliente !== null)
      this.methodPOST_API(
        utility.url_consulta_orden_especifica,
        "orden_select",
        {
          id_deposito: document.getElementById("id_deposito_select").value,
          cliente: this.props.on_cliente,
        }
      );
    else
      this.methodPOST_API(
        utility.url_consulta_orden_especifica,
        "orden_select",
        { id_deposito: document.getElementById("id_deposito_select").value }
      );
    utility.add_class_disabled("numero_cliente_sol_anticipo");
    utility.add_class_disabled("nombre_cliente_sol_anticipo");
    utility.add_class_disabled("fecha_alta_sol_anticipo");
    utility.add_class_disabled("fecha_notificacion_sol_anticipo");
  };
  handleEliminarSol = (e) => {
    swal({
      icon: "warning",
      closeOnClickOutside: false,
      title: "Verificar",
      text: "Ingresar su contraseña:",
      buttons: ["Cancelar", true],
      dangerMode: true,
      content: (
        <div>
          <button
            value={e.target.value}
            id="datos_orden_elim"
            style={{ display: "none" }}
          ></button>
          <div className="grupo col-lg-12 col-8 p-0">
            <input
              type="text"
              className="input-animated input-animatedd col-12 mb-2"
              id="user_orden_eliminar"
              value={`${localStorage.getItem("user_name")}Admin`}
              disabled
              required
            />
            <span className="barra"></span>
            <br />
            <label className="label-animated" htmlFor="user_orden_eliminar">
              Usuario
            </label>
          </div>
          <div className="grupo col-lg-12 col-8 p-0">
            <input
              type="password"
              className="input-animated col-12 mb-2"
              id="pass_orden_eliminar"
              required
            />
            <span className="barra"></span>
            <br />
            <label className="label-animated" htmlFor="pass_orden_eliminar">
              Contraseña
            </label>
          </div>
          <div className="grupo col-lg-12 p-0" style={{ height: "90px" }}>
            <textarea
              className="input-animated col-12"
              id="mod_orden_eliminar"
              style={{ resize: "none" }}
              required
            ></textarea>
            <span className="barra"></span>
            <br />
            <label className="label-animated" htmlFor="mod_orden_eliminar">
              Observaciones
            </label>
          </div>
        </div>
      ),
    }).then((res) => {
      if (res === true) {
        let mov = {
          pass: document.getElementById("pass_orden_eliminar").value,
          motivo: document.getElementById("mod_orden_eliminar").value,
          user: document.getElementById("user_orden_eliminar").value,
          data: document.getElementById("datos_orden_elim").value,
        };
        if (mov.motivo.length === 0)
          swal("Copletar el campo:", "Motivo de Baja", "warning");
        else {
          axios
            .post(utility.url_verify, { usuario: mov.user, password: mov.pass })
            .then((response) => {
              if (response.status >= 200 && response.status < 400) {
                const eliminar_PUT = () => {
                  return axios.put(utility.url_crea_modifica_orden, {
                    id_deposito:
                      document.getElementById("id_deposito_select").value,
                    elimina: [mov.data],
                    agrega: [],
                    observaciones: mov.motivo,
                    usuario: localStorage.getItem("id_usr"),
                  });
                };
                axios.all([eliminar_PUT()]).then(
                  axios.spread((put) => {
                    this.setState({}, () => {
                      this.methodPOST_API(
                        utility.url_consulta_orden_especifica,
                        "orden_select",
                        { id_deposito: this.state.id_deposito_select }
                      );
                    });
                    swal(
                      "La operación fue exitosa",
                      `El nuevo monto es: ${put.data.results[0].importe}`,
                      "success"
                    );
                    this.props.update_pago(1);
                  })
                );
              }
            })
            .catch((error) => {
              message.message_modal(
                error.response.data.title,
                error.response.data.text,
                error.response.status
              );
            });
        }
      }
    });
  };
  handleAgregar = (e) => {
    swal({
      icon: "warning",
      closeOnClickOutside: false,
      title: "Verificar",
      text: "Ingresar su contraseña:",
      buttons: ["Cancelar", true],
      dangerMode: true,
      content: (
        <div>
          <button
            value={e.target.value}
            id="datos_orden_elim"
            style={{ display: "none" }}
          ></button>
          <div className="grupo col-lg-12 col-8 p-0">
            <input
              type="text"
              className="input-animated input-animatedd col-12 mb-2"
              id="user_orden_agregar"
              value={`${localStorage.getItem("user_name")}Admin`}
              disabled
              required
            />
            <span className="barra"></span>
            <br />
            <label className="label-animated" htmlFor="user_orden_agregar">
              Usuario
            </label>
          </div>
          <div className="grupo col-lg-12 col-8 p-0">
            <input
              type="password"
              className="input-animated col-12 mb-2"
              id="pass_orden_agregar"
              required
            />
            <span className="barra"></span>
            <br />
            <label className="label-animated" htmlFor="pass_orden_agregar">
              Contraseña
            </label>
          </div>
          <div className="grupo col-lg-12 p-0" style={{ height: "90px" }}>
            <textarea
              className="input-animated col-12"
              id="mod_orden_agregar"
              style={{ resize: "none" }}
              required
            ></textarea>
            <span className="barra"></span>
            <br />
            <label className="label-animated" htmlFor="mod_orden_agregar">
              Observaciones
            </label>
          </div>
        </div>
      ),
    }).then((res) => {
      if (res === true) {
        let mov = {
          pass: document.getElementById("pass_orden_agregar").value,
          motivo: document.getElementById("mod_orden_agregar").value,
          user: document.getElementById("user_orden_agregar").value,
        };
        if (mov.motivo.length === 0)
          swal("Copletar el campo:", "Motivo de Modificación", "warning");
        else {
          axios
            .post(utility.url_verify, { usuario: mov.user, password: mov.pass })
            .then((response) => {
              if (response.status >= 200 && response.status < 400) {
                const agregar_PUT = () => {
                  return axios.put(utility.url_crea_modifica_orden, {
                    id_deposito: this.state.id_deposito_select,
                    elimina: [],
                    agrega: [
                      document.getElementById("solicitud_agregar_orden").value,
                    ],
                    usuario: localStorage.getItem("id_usr"),
                    observaciones:
                      document.getElementById("mod_orden_agregar").value,
                  });
                };
                axios.all([agregar_PUT()]).then(
                  axios.spread((put) => {
                    this.setState({}, () => {
                      this.methodPOST_API(
                        utility.url_consulta_orden_especifica,
                        "orden_select",
                        { id_deposito: this.state.id_deposito_select }
                      );
                    });
                    swal(
                      "La operación fue exitosa",
                      `El nuevo monto es: ${put.data.results[0].importe}`,
                      "success"
                    );
                    this.props.update_pago(1);
                  })
                );
              }
            })
            .catch((error) => {
              message.message_modal(
                error.response.data.title,
                error.response.data.text,
                error.response.status
              );
            });
        }
      }
    });
  };
  /**
   * Función que nois permite realizar un consumo tipo GET.
   * @param url Url a consumir.
   * @param state Estado a actualizar.
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "ingresos":
              if (this._isMounted === true)
                this.setState({
                  arr_ingresos: response.data.results,
                });
              break;
            case "pagos":
              if (this._isMounted === true)
                this.setState({
                  arr_pagos: response.data.results,
                });
              break;
            case "tercero":
              if (this._isMounted === true)
                this.setState({
                  arr_terceros: response.data.results,
                });
              break;
            case "bancos":
              if (this._isMounted === true)
                this.setState({
                  arr_bancos: response.data.results,
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "crear_orden":
              this.props.tooglePago();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              break;
            case "orden_select":
              this.setState(
                {
                  id_deposito_select: response.data.results[0].id_deposito,
                  importe_orden_select: response.data.results[0].importe,
                  solicitudes_orden_select:
                    response.data.results[0].solicitudes,
                  fecha_alta_orden_select: response.data.results[0].fecha_alta,
                  fecha_notificacion_orden_select:
                    response.data.results[0].fecha_notificacion,
                  status_orden_select: response.data.results[0].status,
                },
                () => {
                  if (this.state.solicitudes_orden_select.length === 0)
                    return null;
                  this.methodPOST_API(
                    utility.url_referencias,
                    "cliente_by_ref",
                    {
                      referencia:
                        this.state.solicitudes_orden_select[0].referencia,
                    }
                  );
                  this.verify_movimiento(this.state.solicitudes_orden_select);
                }
              );
              break;
            case "cliente_by_ref":
              this.setState(
                {
                  cliente_order_select: response.data.results.cliente,
                },
                () => {
                  //this.methodPUT_API(utility.url_pagination, 'solicitud_cliente', { cliente: this.state.cliente_order_select, status: "A" });
                  this.setState({
                    name_cliente_order_select: utility.get_name_cliente(
                      this.state.cliente_order_select,
                      this.props.clientes.results
                    ),
                  });
                }
              );
              break;
            case "elimina_sol_orden":
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  methodPUT_API = (url, state, data) => {
    let url_intern = url;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "solicitud_cliente":
              this.setState({
                solicitudes_modify_orden: response.data.results,
              });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        message.message_modal(
          error.response.data.title,
          error.response.data.text,
          error.response.status
        );
      });
  };
  verify_cliente = () => {
    let actual = "";
    for (let i = 0; i < this.props.checked.length; i++) {
      if (actual !== this.props.checked[i].cliente)
        if (i === 0) actual = this.props.checked[i].cliente;
        else return false;
      else actual = this.props.checked[i].cliente;
    }
    return true;
  };
  verify_orden = () => {
    for (let i = 0; i < this.props.checked.length; i++)
      switch (this.props.checked[i].solicitud_ant[0].id_deposito) {
        case null:
          continue;
        case 0:
          continue;
        default:
          return false;
      }

    return true;
  };
  verify_movimiento = (solicitudes) => {
    let tipo_0 = 0;
    let tipo_1 = 0;
    let tipo_2 = 0;
    for (let i = 0; i < solicitudes.length; i++)
      for (let j = 0; j < solicitudes[i].movimientos.length; j++) {
        if (solicitudes[i].movimientos[j].tipo_solicitud === 0) {
          tipo_0 += parseInt(solicitudes[i].movimientos[j].monto);
        }
        if (solicitudes[i].movimientos[j].tipo_solicitud === 1)
          tipo_1 += parseInt(solicitudes[i].movimientos[j].monto);
        if (solicitudes[i].movimientos[j].tipo_solicitud === 2)
          tipo_2 += parseInt(solicitudes[i].movimientos[j].monto);
      }
    this.setState({
      sum_ingresos: tipo_0,
      sum_contribuciones: tipo_1,
      sum_terceros: tipo_2,
    });
  };
  clear_state_consulta = () => {
    this.props.tooglePago();
    this.setState({
      id_deposito_select: "",
      importe_orden_select: "",
      solicitudes_orden_select: [],
      fecha_alta_orden_select: "",
      fecha_notificacion_orden_select: "",
      cliente_order_select: "",
      name_cliente_order_select: "",
      status_orden_select: "",
      sum_contribuciones: 0,
      sum_ingresos: 0,
      sum_solicitudes: 0,
      sum_terceros: 0,
      sum_total: 0,
    });
  };
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_pago}
        toggle={this.clear_state_consulta}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.clear_state_consulta}>
          Notificación de Depósito{" "}
        </ModalHeader>
        <ModalBody>
          {this.props.checked.length === 0 ? (
            <Alert color="warning" className="text-center mt-4">
              <h2>Debe selecionar alguna solicitud de anticipo.</h2>
            </Alert>
          ) : this.verify_cliente() ? (
            this.verify_orden() ? (
              <React.Fragment>
                <Form className="container-fluid">
                  <FormGroup className="row mb-1">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      nameLabel="Número de Cliente"
                      id="number_cliente_orden_alta"
                      value={
                        this.props.checked.length !== 0
                          ? this.props.checked[0].cliente
                          : ""
                      }
                      disabled
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      nameLabel="Nombre de Cliente"
                      id="number_cliente_orden_alta"
                      value={
                        this.props.checked.length !== 0
                          ? utility.get_name_cliente(
                              this.props.checked[0].cliente,
                              this.props.clientes.results
                            )
                          : ""
                      }
                      disabled
                    />
                  </FormGroup>
                  <FormGroup className="row mb-1">
                  <MySelect
                      bootstrap="col-lg-6 col-12"
                      nameLabel="Bancos"
                      first_option="Seleccione un banco"
                      required
                      onChange={this.handleBanco}
                      id="banco_orden_alta"
                    >
                      {this.state.arr_bancos.map((ban) => (
                        <option key={ban.numero}>
                          {ban.numero} - {ban.nombre}
                        </option>
                      ))}
                    </MySelect>
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      nameLabel="Fecha de alta"
                      disabled="true"
                      required
                      value={utility.get_date_actual().fecha}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyTextArea
                      bootstrap="col-12"
                      id="observaciones_alta_deposito"
                      nameLabel="Observaciones"
                    />
                  </FormGroup>
                </Form>
                <Table size="sm" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>No. Solicitud</td>
                      <td>Referencia</td>
                      <td>Tipo de Movimiento</td>
                      <td>Concepto</td>
                      <td>Cargo</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.props.checked.map((sol) => {
                      return (
                        <tr key={sol.solicitud_ant[0].id_solicitud}>
                          <td className="text-center">
                            {sol.solicitud_ant[0].id_solicitud}
                          </td>
                          <td className="text-center">
                            {sol.solicitud_ant[0].referencia}
                          </td>
                          <td className="text-center">
                            <p className="mb-0 text-white">-</p>
                            {sol.solicitud_ant[0].movimientos.map(
                              (mov, index, array) => {
                                return (
                                  <p
                                    className="mb-0 text-center mx-auto"
                                    style={{ width: "170px" }}
                                    key={mov.tipo_solicitud + "mov" + index}
                                  >
                                    {utility.tipo_solicitud(mov.tipo_solicitud)}
                                  </p>
                                );
                              }
                            )}
                          </td>
                          <td className="text-center">
                            <p className="mb-0 text-white">-</p>
                            {sol.solicitud_ant[0].movimientos.map(
                              (mov, index, array) => {
                                return (
                                  <p
                                    className="mb-0 text-center"
                                    key={mov.clave_concepto + "con" + index}
                                  >
                                    {utility.get_type_conceptoDos(
                                      mov.clave_concepto,
                                      mov.tipo_solicitud,
                                      this.state.arr_ingresos,
                                      this.state.arr_pagos,
                                      this.state.arr_terceros
                                    )}
                                  </p>
                                );
                              }
                            )}
                          </td>
                          <td>
                            <p className="mb-0 text-white">-</p>
                            {sol.solicitud_ant[0].movimientos.map(
                              (mov, index, array) => {
                                return (
                                  <p
                                    className="mb-0 text-center mx-auto"
                                    style={{ width: "90px" }}
                                    key={mov.monto + "car" + index}
                                  >
                                    $ {mov.monto}
                                  </p>
                                );
                              }
                            )}
                          </td>
                          <td>
                            <div className="d-flex justify-content-center">
                              <Button
                                color="danger"
                                size="sm"
                                id={`btn-erase-${sol.solicitud_ant[0].id_solicitud}`}
                                onClick={this.handleEliminar}
                              >
                                Eliminar
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan="5" className="text-right">
                        <p className="mb-0 pl-2 mt-2 font-weight-bold">
                          TOTAL:
                        </p>
                      </td>
                      <td className="text-center">
                        <Input
                          value={this.state.sum_total}
                          className="mx-auto"
                          style={{ width: "80px" }}
                          onChange={this.handleSum}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Form>
                  <FormGroup className="row">
                    <div className="col-md-4 col-12 mt-2">
                      <Label for="ingresos_sol">Ingresos</Label>
                      <Input
                        id="ingresos_sol"
                        value={this.props.sum_ingresos}
                        disabled
                      />
                    </div>
                    <div className="col-md-4 col-12 mt-2">
                      <Label for="contribuciones_sol">
                        Cuadro de Liquidación
                      </Label>
                      <Input
                        id="contribuciones_sol"
                        value={this.props.sum_contribuciones}
                        disabled
                      />
                    </div>
                    <div className="col-md-4 col-12 mt-2">
                      <Label for="terceros_sol">Terceros</Label>
                      <Input
                        id="terceros_sol"
                        value={this.props.sum_terceros}
                        disabled
                      />
                    </div>
                  </FormGroup>
                </Form>
              </React.Fragment>
            ) : (
              <Alert color="danger" className="text-center mt-4">
                <h2>
                  Debe seleccionar solicitudes que no tengan una orden de pago.
                </h2>
              </Alert>
            )
          ) : (
            <Alert color="danger" className="text-center mt-4">
              <h2>
                Debe seleccionar solicitudes de anticipo del mismo cliente.
              </h2>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="success"
            className="mr-2"
            onClick={this.handleAlta}
          >
            Aceptar
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={this.clear_state_consulta}
          >
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default   NotificacionDeposito;
