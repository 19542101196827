/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un anticipo. 
*/
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as message from "../messages/messages";
import MyInput from "../utils/MyInput";
import * as utility from "../utils/utility";
import swal from "@sweetalert/with-react";
import WebSocketInstance from "../../../utils/websocket";
import * as permisos from "../../../utils/permisos/permisos";

class ModalConsulta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      nombre_select: "",
      codigo: "",
      tipo: "",
      mayor: "",
      subcuenta: "",
      detalle: "",
      descripcion: "",
      naturaleza: "",
      tpo_cuenta: "",
      cuenta: "",
      nivel: 0,
      cuentas_mayor: [],
      cuentas_menor: [],
      data_modificacion: {},
      input_disabled: [true, true, true, true, true, true, true],
    };
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.cuenta;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "cuenta_auxiliar",
      motivo: motivo,
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  /* Funciones para operaciones de la misma aplicación. */
  handleConsulta = () => {
    if (this.state.cuenta.length === 0)
      message.message_modal("Advertencia", "Debe tener un folio", 400);
    else {
      this.methodPOST_API(utility.url_consulta_cuenta, "consulta", {
        auxiliar: this.state.cuenta,
      });
    }
  };
  handleCerrar = () => {
    this.props.toogleConsulta();
    this.setState({
      nombre_select: "",
      codigo: "",
      tipo: "",
      mayor: "",
      subcuenta: "",
      detalle: "",
      descripcion: "",
      naturaleza: "",
      tpo_cuenta: "",
      cuenta: "",
      nivel: 0,
      cuentas_mayor: [],
      cuentas_menor: [],
      data_modificacion: {},
      input_disabled: [true, true, true, true, true, true, true],
    });
  };
  handleClean = () => {
    this.setState({
      nombre_select: "",
      codigo: "",
      tipo: "",
      mayor: "",
      subcuenta: "",
      detalle: "",
      descripcion: "",
      naturaleza: "",
      tpo_cuenta: "",
      cuenta: "",
      nivel: 0,
      cuentas_mayor: [],
      cuentas_menor: [],
      data_modificacion: {},
      input_disabled: [true, true, true, true, true, true, true],
    });
  };
  handleModificar = () => {
    if (this.state.cuenta.length === 0)
      message.message_modal("Advertencia", "Debe tener un folio", 400);
    else {
      message
        .message_confirmacion_password(
          "¿Desea continuar con la operación modificar?",
          "Ingrese sus observaciones "
        )
        .then((res) => {
          if (res === true) {
            let mov = {
              user: document.getElementById("usuario_modificador").value,
              pass: document.getElementById("contraseña_modificador").value,
              motivo: document.getElementById("observaciones_textarea").value,
            };
            if (mov.motivo.length === 0)
              swal("Completar el campo:", "Motivo de Notificación", "warning");
            else {
              let observaciones = document.getElementById(
                "observaciones_textarea"
              ).value;
              axios
                .post(utility.url_verify, {
                  usuario: mov.user,
                  password: mov.pass,
                })
                .then((response) => {
                  if (response.status >= 200 && response.status < 400) {
                    if (this._isMounted) {
                      let data_modificacion = this.state.data_modificacion;
                      if (Object.values(data_modificacion).length > 0) {
                        data_modificacion["auxiliar"] = this.state.cuenta;
                        data_modificacion["observaciones"] = observaciones;
                        this.methodPOST_MODIFICAR(
                          utility.url_crea_modifica_cuenta,
                          "modificar",
                          data_modificacion
                        );
                        this.sendNotificacionHandler("modificar");
                      } else
                        message.message_modal(
                          "Advertencia",
                          "No hay modificaciones",
                          400
                        );
                    }
                  }
                })
                .catch((error) => {
                  if (error.response)
                    message.message_modal(
                      error.response.data.title,
                      error.response.data.text,
                      error.response.status
                    );
                });
            }
          }
        });
    }
  };

  handleEliminar = () => {
    if (this.state.cuenta.length === 0)
      message.message_modal("Advertencia", "Debe tener un folio", 400);
    else {
      message
        .message_confirmacion_only_password(
          `¿Desea eliminar permanentemente la cuenta ${this.state.cuenta}?`,
          "Ingrese su contraseña"
        )
        .then((res) => {
          if (res === true) {
            let mov = {
              user: document.getElementById("usuario_modificador").value,
              pass: document.getElementById("contraseña_modificador").value,
            };
            axios
              .post(utility.url_verify, {
                usuario: mov.user,
                password: mov.pass,
              })
              .then((response) => {
                if (response.status >= 200 && response.status < 400) {
                  if (this._isMounted) {
                    axios
                      .post(utility.url_elimina_cuenta, {
                        auxiliar: this.state.cuenta,
                      })
                      .then((response) => {
                        if (response.status >= 200 && response.status < 400) {
                          if (this._isMounted) {
                            swal({
                              icon: "success",
                              title: response.data.title,
                              text: response.data.text,
                              button: true,
                            });
                            this.handleClean();
                            this.sendNotificacionHandler("eliminar");
                          }
                        }
                      })
                      .catch((error) => {
                        if (error.response)
                          message.message_modal(
                            error.response.data.title,
                            error.response.data.text,
                            error.response.status
                          );
                      });
                  }
                }
              })
              .catch((error) => {
                if (error.response)
                  message.message_modal(
                    error.response.data.title,
                    error.response.data.text,
                    error.response.status
                  );
              });
          }
        });
    }
  };

  /* Funciones para obtener el cambio de estando en inputs. */
  handleMayor = (e) => {
    let mayor = e.target.value;
    this.setState({ mayor });
  };
  handleSubcuenta = (e) => {
    let subcuenta = e.target.value;
    this.setState({ subcuenta });
  };
  handleDetalle = (e) => {
    let detalle = e.target.value;
    this.setState({ detalle });
  };
  handleCuenta = (e) => {
    let cuenta = e.target.value;
    this.setState({ cuenta });
  };
  handleNivel = (e) => {
    let nivel = parseInt(e.target.value);
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["nivel"] = nivel;
    this.setState({ nivel, data_modificacion });
  };
  handleTipo_cuenta = (e) => {
    let tpo_cuenta = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["tpo_cuenta"] = tpo_cuenta;
    this.setState({ tpo_cuenta, data_modificacion });
  };
  handleNaturaleza = (e) => {
    let naturaleza = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["naturaleza"] = naturaleza;
    this.setState({ naturaleza, data_modificacion });
  };
  handleTipo = (e) => {
    let tipo = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["tipo"] = tipo;
    this.setState({ tipo, data_modificacion });
  };
  handleDescripcion = (e) => {
    let descripcion = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["descripcion"] = descripcion;
    this.setState({ descripcion, data_modificacion });
  };
  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let codigo = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["codigo"] = codigo;
    this.setState({ codigo, data_modificacion });
    for (let i = 0; i < this.props.codigos_sat.length; i++) {
      if (codigo.length === 0) this.suggestionSelected("");
      if (codigo === this.props.codigos_sat[i].codigo)
        this.suggestionSelected(this.props.codigos_sat[i].descripcion);
    }
  };
  suggestionSelected = (value) => {
    this.setState({
      nombre_select: value,
    });
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  /**
   * Función que permite hacer una petición de forma POST.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} data Objeto a mandar atraves del metodo POST.
   */
  methodPOST_API = (url, state, data) => {
    /*  SI SE REALIZA UNA OPERACION QUE DEBE CERRAR EL MODAL, MOSTAR UN MENSAJE Y ACTUALIZAR LA 
        TABLA PRINCIPAL ESTA ES LA ESTRCUTURA.
    
        this.props.toogleXXXXX(); -> FUNCIÓN QUE CIRRA EL MODAL.
        message.message_modal(response.data.title, response.data.text, response.status); -> MENSAJE A MOSTAR.
        this.props.update(1); -> ACTUALIZACIÓN DE LA TABLA PRINCIPAL.   */

    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "consulta":
              this.setState({
                descripcion: response.data.results.descripcion,
                tipo: response.data.results.tipo,
                nivel: response.data.results.nivel,
                codigo: response.data.results.codigo,
                naturaleza: response.data.results.naturaleza,
                tpo_cuenta: response.data.results.tpo_cuenta,
                nombre_select: utility.nombre_by_codigo(
                  response.data.results.codigo,
                  this.props.codigos_sat
                ),
                input_disabled: [
                  false,
                  false,
                  true,
                  false,
                  false,
                  false,
                  false,
                ],
              });
              let niveles = document.getElementById("nivel_cuenta").options;
              let tpo_cuenta = document.getElementById("tipo_cuenta").options;
              let naturaleza =
                document.getElementById("naturaleza_cuenta").options;
              let tipo = document.getElementById("tipo").options;
              for (let i = 0; i < niveles.length; i++)
                if (niveles[i].value === this.state.nivel)
                  document.getElementById("nivel_cuenta").value =
                    niveles[i].value;
              for (let i = 0; i < tpo_cuenta.length; i++)
                if (tpo_cuenta[i].value === this.state.tpo_cuenta)
                  document.getElementById("tipo_cuenta").value =
                    tpo_cuenta[i].value;
              for (let i = 0; i < naturaleza.length; i++)
                if (naturaleza[i].value === this.state.naturaleza)
                  document.getElementById("naturaleza_cuenta").value =
                    naturaleza[i].value;
              for (let i = 0; i < tipo.length; i++)
                if (tipo[i].value === this.state.tipo)
                  document.getElementById("tipo").value = tipo[i].value;
              break;
            case "modificar":
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  /**
   * Función que permite hacer una petición de forma POST.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} data Objeto a mandar atraves del metodo POST.
   */
  methodPOST_MODIFICAR = (url, state, data) => {
    /*  SI SE REALIZA UNA OPERACION QUE DEBE CERRAR EL MODAL, MOSTAR UN MENSAJE Y ACTUALIZAR LA 
        TABLA PRINCIPAL ESTA ES LA ESTRCUTURA.
    
        this.props.toogleXXXXX(); -> FUNCIÓN QUE CIRRA EL MODAL.
        message.message_modal(response.data.title, response.data.text, response.status); -> MENSAJE A MOSTAR.
        this.props.update(1); -> ACTUALIZACIÓN DE LA TABLA PRINCIPAL.   */

    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "modificar":
              this.handleCerrar();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Consulta Cuenta Auxiliar
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              {/* <div className="grupo col-lg-3 p-0">
                                <input type="text" className="input-animated col-12 mb-2" id="cuenta_mayor" maxLength="4" required
                                    value={this.state.mayor} onChange={this.handleMayor} />
                                <span className="barra"></span>
                                <br /><label className="label-animated" htmlFor="cuenta_mayor">Cuenta Mayor</label>
                            </div>
                            <div className="grupo col-lg-3 p-0">
                                <input type="text" className="input-animated col-12 mb-2" id="subcuenta" maxLength="3" required
                                    value={this.state.subcuenta} onChange={this.handleSubcuenta} />
                                <span className="barra"></span>
                                <br /><label className="label-animated" htmlFor="subcuenta">Subcuenta</label>
                            </div>
                            <div className="grupo col-lg-3 p-0">
                                <input type="text" className="input-animated col-12 mb-2" id="detalle" maxLength="4" required
                                    value={this.state.detalle} onChange={this.handleDetalle} />
                                <span className="barra"></span>
                                <br /><label className="label-animated" htmlFor="detalle">Detalle</label>
                            </div> */}
              <MyInput
                type="text"
                bootstrap="col-lg-10 col-8"
                id="cuenta"
                nameLabel="Número de Cuenta"
                value={this.state.cuenta}
                onChange={this.handleCuenta}
              />
              <Button
                color="primary"
                className="col-lg-2 col-4 mb-2"
                onClick={this.handleConsulta}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <div className="grupo col-lg-12 p-0">
                <select
                  className="input-animated col-12 mb-2"
                  id="nivel_cuenta"
                  onChange={this.handleNivel}
                  style={{ background: "#fff", padding: "7px 10px" }}
                  disabled={this.state.input_disabled[0]}
                  required
                >
                  <option value="0">Seleccione un Nivel</option>
                  <option value="1">Mayor</option>
                  <option value="2">Subcuenta</option>
                  <option value="3">Detalle</option>
                </select>
                <span className="barra"></span>
                <br />
                <label className="label-animated" htmlFor="nivel_cuenta">
                  Nivel de la cuenta
                </label>
              </div>
            </FormGroup>
            <FormGroup className="row mb-0">
              <div className="grupo col-lg-6 p-0">
                <input
                  type="text"
                  className="input-animated col-12 mb-2"
                  id="codigo_cuenta"
                  disabled={this.state.input_disabled[1]}
                  onChange={this.nameGenerator}
                  value={this.state.codigo}
                  required
                />
                <span className="barra"></span>
                <br />
                <label className="label-animated" htmlFor="codigo_cuenta">
                  Código
                </label>
              </div>
              <div className="grupo col-lg-6 p-0">
                <input
                  type="text"
                  className="input-animated input-animatedd col-12 mb-2"
                  id="codigo_cuenta_descripcion"
                  onChange={this.onNameChange}
                  value={this.state.nombre_select}
                  disabled={this.state.input_disabled[2]}
                />
                <span className="barra"></span>
                <br />
                <label
                  className="label-animated"
                  htmlFor="codigo_cuenta_descripcion"
                >
                  Código Descripción
                </label>
              </div>
            </FormGroup>
            <FormGroup className="row mb-0">
              <div className="grupo col-lg-4 p-0">
                <select
                  className="input-animated col-12 mb-2"
                  id="tipo_cuenta"
                  onChange={this.handleTipo_cuenta}
                  style={{ background: "#fff", padding: "7px 10px" }}
                  disabled={this.state.input_disabled[3]}
                  required
                >
                  <option value="null">Seleccione un Tipo de cuenta</option>
                  <option value="D">Detalle</option>
                  <option value="A">Acumulativa</option>
                </select>
                <span className="barra"></span>
                <br />
                <label className="label-animated" htmlFor="tipo_cuenta">
                  Tipo de cuenta
                </label>
              </div>
              <div className="grupo col-lg-4 p-0">
                <select
                  className="input-animated col-12 mb-2"
                  id="naturaleza_cuenta"
                  onChange={this.handleNaturaleza}
                  style={{ background: "#fff", padding: "7px 10px" }}
                  disabled={this.state.input_disabled[4]}
                  required
                >
                  <option value="null">Seleccione una Naturaleza</option>
                  <option value="D">Detalle</option>
                  <option value="A">Acumulativa</option>
                </select>
                <span className="barra"></span>
                <br />
                <label className="label-animated" htmlFor="naturaleza_cuenta">
                  Naturaleza
                </label>
              </div>
              <div className="grupo col-lg-4 p-0">
                <select
                  className="input-animated col-12 mb-2"
                  id="tipo"
                  onChange={this.handleTipo}
                  style={{ background: "#fff", padding: "7px 10px" }}
                  disabled={this.state.input_disabled[5]}
                  required
                >
                  <option value="null">Seleccione un Tipo</option>
                  <option value="01">Activo</option>
                  <option value="02">Pasivo</option>
                  <option value="03">Capital</option>
                  <option value="04">Ingreso</option>
                  <option value="05">Costo</option>
                  <option value="06">Gasto</option>
                  <option value="07">Orden</option>
                </select>
                <span className="barra"></span>
                <br />
                <label className="label-animated" htmlFor="tipo">
                  Tipo
                </label>
              </div>
            </FormGroup>
            <FormGroup className="row mb-0">
              <div className="grupo col-lg-12 p-0" style={{ height: "90px" }}>
                <textarea
                  className="input-animated col-12"
                  id="descripcion_cuenta"
                  onChange={this.handleDescripcion}
                  value={this.state.descripcion}
                  style={{ resize: "none" }}
                  disabled={this.state.input_disabled[6]}
                  required
                ></textarea>
                <span className="barra"></span>
                <br />
                <label className="label-animated" htmlFor="">
                  Descripción
                </label>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={this.handleEliminar}
            disabled={permisos.obtener_permiso_especifico(
              "cancelar",
              this.props.permisos
            )}
          >
            Eliminar
          </Button>
          <Button
            color="info"
            onClick={this.handleModificar}
            disabled={permisos.obtener_permiso_especifico(
              "modificar",
              this.props.permisos
            )}
          >
            Modificar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalConsulta;
