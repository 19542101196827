import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import MyInput from "../../../utils/custom/MyInput";
import WebSocketInstance from "../../../utils/websocket";
import * as message from "../../../utils/messages/message";
import * as utility from "../utils/utility";
import * as permisos from "../../../utils/permisos/permisos";
import { faArrowRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "@sweetalert/with-react";

class ModalConsulta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      fraccion: "",
      numParte: "",
      numLote: "",
      numAux: "",

      data_modificacion: {},
      input_disabled: [true, true],
    };
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  /* sendNotificacionHandler = (motivo) => {
        let folio = this.state.numParte;
        const notificacionObject = {
            tipo: 0,
            aplicacion: 'proveedor',
            motivo: motivo,
            folio: [folio]
        }
        WebSocketInstance.newNotificacion(notificacionObject);
    } */
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleNumParte = (e) => {
    let numParte = e.target.value;
    this.setState({ numParte });
  };

  handleFraccion = (e) => {
    let fraccion = e.target.value;
    this.setState({ fraccion });
  };
  handlenumLote = (e) => {
    let numLote = e.target.value;
    this.setState({ numLote });
  };
  handlenumAux = (e) => {
    let numAux = e.target.value;
    this.setState({ numAux });
  };

  handleConsulta = () => {
    if (this.state.numParte.length === 0)
      message.message_modal("Advertencia", "Debe tener Número Parte", 400);
    else {
      this.methodPOST_API(utility.url_consulta_numparte, "consulta", {
        id_parte: this.state.numParte,
      });
    }
  };
  handleModificar = () => {
    if (this.state.numParte.length === 0)
      message.message_modal("Advertencia", "Debe tener Número Parte", 400);
    else {
      message
        .message_confimacion("¿Desea continuar con la operación modificar?", "")
        .then((res) => {
          const { numLote, numAux, fraccion, numParte } = this.state;

          // Comparar con los datos originales
          if (
            numLote !== this.props.numLote ||
            numAux !== this.props.numAux ||
            fraccion !== this.props.fraccion
          ) {
            const data = {
              id_parte: numParte,
              num_lote: numLote,
              num_auxiliar: numAux,
              id_fraccion: fraccion,
            };

            this.methodPUT_API(utility.url_crea_numParte, "modificar", data);
          } else {
            swal("Información", "No se han realizado cambios", "info");
          }
        });
    }
  };
  
  handleCerrar = () => {
    this.setState({
      fraccion: "",
      numParte: "",
      numLote: "",
      numAux: "",

      data_modificacion: {},
      input_disabled: [true, true],
    });
    this.props.toogleConsulta();
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta":
              this.setState({
                numLote: response.data.results.num_lote,
                numAux: response.data.results.num_auxiliar,
                fraccion: response.data.results.id_fraccion,
              });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPUT_API = (url, state, data) => {
    let url_intern = url;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "modificar":
              this.handleCerrar();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Consulta Número Parte
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-10 col-8"
                id="numParte_consulta"
                value={this.state.numParte}
                onChange={this.handleNumParte}
                nameLabel="Número Parte"
              />
              <Button
                color="primary"
                className="col-lg-2 col-4 mb-2"
                onClick={this.handleConsulta}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-12"
                id="nombre_corto_ingreso_alta"
                nameLabel="Fracción"
                value={this.state.fraccion}
                onChange={this.handleFraccion}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="nombre_ingreso_alta"
                nameLabel="Número de Lote"
                value={this.state.numLote}
                onChange={this.handlenumLote}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="nombre_corto_ingreso_alta"
                nameLabel="Número Auxiliar"
                value={this.state.numAux}
                onChange={this.handlenumAux}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={this.handleEliminar}
            disabled={permisos.obtener_permiso_especifico(
              "modificar",
              this.props.permisos
            )}
          >
            Eliminar
          </Button>
          <Button
            color="info"
            onClick={this.handleModificar}
            disabled={permisos.obtener_permiso_especifico(
              "modificar",
              this.props.permisos
            )}
          >
            Modificar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalConsulta;
