import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardBody,
  Form,
  FormGroup,
  FormText,
  Collapse,
} from "reactstrap";
import * as message from "../messages/messages";
import * as utility from "../utility/utility";
import MyTextArea from "../utility/MyTextArea";
import MySelect from "../utility/MySelect";
import MyInput from "../utility/MyInput";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WebSocketInstance from "../../../utils/websocket";
import ModalCrearEnCatalogos from "../../CuentasAux/Modal/ModalCrearEnCatalogos";
import ModalAuxiliar from "../../Poliza/Modal/ModalAuxiliar";

class ModalAuxiliarSaldo extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      auxiliar: "",
      codigo: "",
      nivel: 0,
      naturaleza: "",
      tipoCuenta: "",
      descripcion: "",
      infoAuxiliar: [],
      toogle_collapse: false,

      nombre_select: "",
      tipo: "",
      mayor: "",
      subcuenta: "",
      detalle: "",
      cuentas_mayor: [],
      cuentas_menor: [],

      auxiliares:[]
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.identificativo;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "cuenta_auxiliar",
      motivo: motivo,
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */

  handleAuxiliar = (e) => {
    let auxiliar = e.target.value;
    this.setState({ auxiliar });
  };
  handleNivel = (e) => {
    let nivel = parseInt(e.target.value);
    if (nivel >= 2)
      this.methodPOST_API(utility.url_pagination_cuentas, "cuentas_mayor", {
        nivel: 1,
      });
    this.setState({ nivel, mayor: "", subcuenta: "", detalle: "" });
  };
  handleMayor = (e) => {
    let mayor = e.target.value;
    if (this.state.nivel === 3)
      this.methodPOST_API(utility.url_pagination_cuentas, "cuentas_menor", {
        mayor: mayor,
        nivel: 2,
      });
    this.setState({ mayor });
  };
  handleSubcuenta = (e) => {
    let subcuenta = e.target.value;
    this.setState({ subcuenta });
  };
  handleDetalle = (e) => {
    let detalle = e.target.value;
    this.setState({ detalle });
  };
  handleNivel = (e) => {
    let nivel = parseInt(e.target.value);
    if (nivel >= 2)
      this.methodPOST_API(utility.url_pagination_cuentas, "cuentas_mayor", {
        nivel: 1,
      });
    this.setState({ nivel, mayor: "", subcuenta: "", detalle: "" });
  };
  handleNaturaleza = (e) => {
    let naturaleza = e.target.value;
    this.setState({ naturaleza });
  };
  handleTipoCuenta = (e) => {
    let tipoCuenta = e.target.value;
    this.setState({ tipoCuenta });
  };
  handleTipo = (e) => {
    let tipo = e.target.value;
    this.setState({ tipo });
  };
  handleDescripcion = (e) => {
    let descripcion = e.target.value;
    this.setState({ descripcion });
  };

  toogleCollapse = (e) => {
    this.setState((prevState) => ({
      toogle_collapse: !prevState.toogle_collapse,
    }));
  };

  handleCerrar = () => {
    this.props.toogleCatalogo();
    this.handleLimpiar();
  };

  handleLimpiar = () => {
    this.setState({
      auxiliar: "",
      codigo: "",
      nivel: 0,
      naturaleza: "",
      tipoCuenta: "",
      descripcion: "",
      infoAuxiliar: [],
      toogle_collapse: false,

      nombre_select: "",
      tipo: "",
      mayor: "",
      subcuenta: "",
      detalle: "",
      cuentas_mayor: [],
      cuentas_menor: [],

      auxiliares:[]
    });
  };

  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let codigo = e.target.value;
    this.setState({ codigo });
    for (let i = 0; i < this.props.codigos_sat.length; i++) {
      if (codigo.length === 0) this.suggestionSelected("");
      if (codigo === this.props.codigos_sat[i].codigo)
        this.suggestionSelected(this.props.codigos_sat[i].descripcion);
    }
  };
  suggestionSelected = (value) => {
    this.setState({
      nombre_select: value,
    });
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  /**
   * Función que permite hacer una petición de forma POST.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} data Objeto a mandar atraves del metodo POST.
   */
  methodPOST_API = (url, state, data) => {
    /*  SI SE REALIZA UNA OPERACION QUE DEBE CERRAR EL MODAL, MOSTAR UN MENSAJE Y ACTUALIZAR LA 
      TABLA PRINCIPAL ESTA ES LA ESTRCUTURA.
  
      this.props.toogleXXXXX(); -> FUNCIÓN QUE CIRRA EL MODAL.
      message.message_modal(response.data.title, response.data.text, response.status); -> MENSAJE A MOSTAR.
      this.props.update(1); -> ACTUALIZACIÓN DE LA TABLA PRINCIPAL.   */

    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "alta":
              this.setState(
                { identificativo: response.data.results.auxiliar },
                () => {
                  this.sendNotificacionHandler("crear");
                }
              );
              this.props.toogleNuevo();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            case "cuentas_mayor":
              this.setState({
                cuentas_mayor: response.data.results,
              });
              break;
            case "cuentas_menor":
              this.setState({
                cuentas_menor: response.data.results,
              });
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handleAlta = () => {
    if (this.state.auxiliar != "") {
      const auxDos = this.state.auxiliar;
      this.props.parentCallback(auxDos);
      this.handleCerrar();
    } else {
      message.warningMessage("Por favor, ingrese una cuenta auxiliar");
    }
  };

  toogleAuxiliar = () => {
    this.setState((prevState) => ({
      toogle_auxiliar: !prevState.toogle_auxiliar,
    }));
    if (this.state.auxiliar === "") {
      this.getAuxiliaresMayores();
    } else {
      this.postAuxiliarSegundoNivel();
    }
  };

  getAuxiliaresMayores() {
    const url = utility.url_auxiliares;
    axios
      .get(url)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              auxiliares: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  postAuxiliarSegundoNivel() {
    axios
      .post(utility.url_auxiliares, {
        auxiliar: this.state.auxiliar,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              auxiliares: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  handleCallback = (childData) => {
    this.setState({ auxiliar: childData });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_catalogo}
        toggle={this.handleCerrar}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Cuenta Auxiliar Saldo
        </ModalHeader>
        <ModalBody>
          <CardBody>
            <Form className="container-fluid">
              <FormGroup className="row mb-0">
                <MyInput
                  type="text"
                  bootstrap="col-lg-8 col-12"
                  id="cuenta_auxiliar"
                  nameLabel="Cuenta Auxiliar"
                  value={this.state.auxiliar}
                  onChange={this.handleAuxiliar}
                />
                <Button
                  color="primary"
                  className="col-lg-4 col-12 mb-2"
                  onClick={this.toogleAuxiliar}
                >
                  Consulta
                </Button>
              </FormGroup>
            </Form>
            
            {this.props.toogle_catalogo&& (
              <ModalCrearEnCatalogos
                toogle_catalogo={this.state.toogle_cuentaux}
                toogleCatalogo={this.toogleAuxiliar}
                codigos_sat={this.props.codigos_sat}
              />
            )}
            <br></br>
            
          </CardBody>
        </ModalBody>
        <ModalFooter>
          <Button color="success" className="mr-2" onClick={this.handleAlta}>
            Aceptar
          </Button>
          <Button type="button" color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
        <ModalAuxiliar
          toogle_catalogo={this.state.toogle_auxiliar}
          toogleCatalogo={this.toogleAuxiliar}
          listaAuxiliares={this.state.auxiliares}
          parentCallback={this.handleCallback}
        />
      </Modal>
    );
  }
}
export default ModalAuxiliarSaldo;