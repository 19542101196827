/* 
Autor: Alexis Yael Hernández Grimaldo
Descripción: El componenete genera un bloque correspondiente a la busqueda dinamica en la tabla para acceder a la información.
*/
import React, { Component } from "react";
import { Button, Collapse, Form, FormGroup } from "reactstrap";
import "../../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import MyInput from "../../utils/custom/MyInput";
import MySelect from "../../utils/custom/MySelect";

class BusquedaEspecifica extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toogle_collapse: false,
    };
  }
  toogleCollapse = (e) => {
    this.setState((prevState) => ({
      toogle_collapse: !prevState.toogle_collapse,
    }));
  };
  /**
   * Función que valida la información a colsultar de manre afiltrada en la tabla
   * @param {Object} data Objeto con información a consultar para filtar la tabla
   */
  handleValida = (data) => {
    if (data.tipo_alta.length === 0) delete data.tipo_alta;
    if (data.folio.length === 0) delete data.folio;
    if (data.guia_principal.length === 0) delete data.guia_principal;
    if (data.cliente.length === 0) delete data.cliente;
    if (data.numero_vuelo.length === 0) delete data.numero_vuelo;
    if (data.consolidadora.length === 0) delete data.consolidadora;
    if (data.status.length === 0) delete data.status;
    if (data.fecha_alta_inicio.length > 0 && data.fecha_alta_final.length > 0)
      data["fecha_alta"] = data.fecha_alta_inicio + "," + data.fecha_alta_final;
    else if (
      data.fecha_alta_inicio.length > 0 &&
      data.fecha_alta_final.length === 0
    )
      data["fecha_alta"] =
        data.fecha_alta_inicio + "," + data.fecha_alta_inicio;
    else if (
      data.fecha_alta_inicio.length === 0 &&
      data.fecha_alta_final.length > 0
    )
      data["fecha_alta"] = data.fecha_alta_final + "," + data.fecha_alta_final;
    else {
      delete data.fecha_alta_inicio;
      delete data.fecha_alta_final;
    }
    if (
      data.fecha_llegada_inicio.length > 0 &&
      data.fecha_llegada_final.length > 0
    )
      data["fecha_llegada"] =
        data.fecha_llegada_inicio + "," + data.fecha_llegada_final;
    else if (
      data.fecha_llegada_inicio.length > 0 &&
      data.fecha_llegada_final.length === 0
    )
      data["fecha_llegada"] =
        data.fecha_llegada_inicio + "," + data.fecha_llegada_inicio;
    else if (
      data.fecha_llegada_inicio.length === 0 &&
      data.fecha_llegada_final.length > 0
    )
      data["fecha_llegada"] =
        data.fecha_llegada_final + "," + data.fecha_llegada_final;
    else {
      delete data.fecha_llegada_inicio;
      delete data.fecha_llegada_final;
    }
    if (
      data.fecha_recoleccion_inicio.length > 0 &&
      data.fecha_recoleccion_final.length > 0
    )
      data["fecha_recoleccion"] =
        data.fecha_recoleccion_inicio + "," + data.fecha_recoleccion_final;
    else if (
      data.fecha_recoleccion_inicio.length > 0 &&
      data.fecha_recoleccion_final.length === 0
    )
      data["fecha_recoleccion"] =
        data.fecha_recoleccion_inicio + "," + data.fecha_recoleccion_inicio;
    else if (
      data.fecha_recoleccion_inicio.length === 0 &&
      data.fecha_recoleccion_final.length > 0
    )
      data["fecha_recoleccion"] =
        data.fecha_recoleccion_final + "," + data.fecha_recoleccion_final;
    else {
      delete data.fecha_recoleccion_inicio;
      delete data.fecha_recoleccion_final;
    }
    if (
      data.fecha_entrega_inicio.length > 0 &&
      data.fecha_entrega_final.length > 0
    )
      data["fecha_entrega"] =
        data.fecha_entrega_inicio + "," + data.fecha_entrega_final;
    else if (
      data.fecha_entrega_inicio.length > 0 &&
      data.fecha_entrega_final.length === 0
    )
      data["fecha_entrega"] =
        data.fecha_entrega_inicio + "," + data.fecha_entrega_inicio;
    else if (
      data.fecha_entrega_inicio.length === 0 &&
      data.fecha_entrega_final.length > 0
    )
      data["fecha_entrega"] =
        data.fecha_entrega_final + "," + data.fecha_entrega_final;
    else {
      delete data.fecha_entrega_inicio;
      delete data.fecha_entrega_final;
    }
  };
  handleFiltrada = () => {
    let data = {
      tipo_alta: document.getElementById("specific_tipo_alta").value,
      folio: document.getElementById("specific_folio").value,
      guia_principal: document.getElementById("specific_guia_principal").value,
      cliente: document.getElementById("specific_cliente").value,
      numero_vuelo: document.getElementById("specific_numero_vuelo").value,
      consolidadora: document.getElementById("specific_consolidadora").value,
      fecha_llegada_inicio: document.getElementById("specific_fecha_llegadaI")
        .value,
      fecha_llegada_final: document.getElementById("specific_fecha_llegadaF")
        .value,
      fecha_recoleccion_inicio: document.getElementById(
        "specific_fecha_recoleccionI"
      ).value,
      fecha_recoleccion_final: document.getElementById(
        "specific_fecha_recoleccionF"
      ).value,
      fecha_entrega_inicio: document.getElementById("specific_fecha_entregaI")
        .value,
      fecha_entrega_final: document.getElementById("specific_fecha_entregaF")
        .value,
      fecha_alta_inicio: document.getElementById("specific_fecha_altaI").value,
      fecha_alta_final: document.getElementById("specific_fecha_altaF").value,
      status: document.getElementById("specific_status").value,
    };
    this.handleValida(data);
    if (data.fecha_llegada_inicio) {
      delete data.fecha_llegada_inicio;
      delete data.fecha_llegada_final;
    }
    if (data.fecha_recoleccion_inicio) {
      delete data.fecha_recoleccion_inicio;
      delete data.fecha_recoleccion_final;
    }
    if (data.fecha_entrega_inicio) {
      delete data.fecha_entrega_inicio;
      delete data.fecha_entrega_final;
    }
    if (data.fecha_alta_inicio) {
      delete data.fecha_alta_inicio;
      delete data.fecha_alta_final;
    }
    this.props.onChangeFiltrada(data);
  };
  render() {
    return (
      <React.Fragment>
        <div className="row mb-2 mx-1">
          <h3 className="col-md-11 col-9 text-md-left px-0 mb-0">
            Consulta Específica
          </h3>
          <Button
            color="info"
            onClick={this.toogleCollapse}
            className="col-md-1 col-3"
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
        <Collapse isOpen={this.state.toogle_collapse}>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                nameLabel="Folio"
                id="specific_folio"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                nameLabel="Número de cliente"
                id="specific_cliente"
                defaultValue={
                  this.props.on_cliente !== false ? this.props.on_cliente : ""
                }
                disabled={this.props.on_cliente !== false ? true : false}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                nameLabel="Número de consolidadora"
                id="specific_consolidadora"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                nameLabel="Tipo alta"
                id="specific_tipo_alta"
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MySelect
                type="text"
                bootstrap="col-lg-3 col-12"
                nameLabel="Estado"
                id="specific_status"
                first_option="Seleccione un estado"
              >
                <option value={"A"}>Alta de alerta</option>
                <option value={"C"}>Cancelación de guía</option>
                <option value={"E"}>Entrega de mercancía</option>
                <option value={"R"}>Alta de referencia</option>
              </MySelect>
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-12"
                nameLabel="Número de vuelo"
                id="specific_numero_vuelo"
              />
              <MyInput
                type="date"
                bootstrap="col-lg-3 col-12"
                nameLabel="Fecha Alta (Inicial)"
                id="specific_fecha_altaI"
              />
              <MyInput
                type="date"
                bootstrap="col-lg-3 col-12"
                nameLabel="Fecha Alta (Final)"
                id="specific_fecha_altaF"
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="date"
                bootstrap="col-lg-3 col-12"
                nameLabel="Fecha Llegada (Inicial)"
                id="specific_fecha_llegadaI"
              />
              <MyInput
                type="date"
                bootstrap="col-lg-3 col-12"
                nameLabel="Fecha Llegada (Final)"
                id="specific_fecha_llegadaF"
              />
              <MyInput
                type="date"
                bootstrap="col-lg-3 col-12"
                nameLabel="Fecha Recolección (Inicial)"
                id="specific_fecha_recoleccionI"
              />
              <MyInput
                type="date"
                bootstrap="col-lg-3 col-12"
                nameLabel="Fecha Recolección (Final)"
                id="specific_fecha_recoleccionF"
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="date"
                bootstrap="col-lg-3 col-12"
                nameLabel="Fecha Entrega (Inicial)"
                id="specific_fecha_entregaI"
              />
              <MyInput
                type="date"
                bootstrap="col-lg-3 col-12"
                nameLabel="Fecha Entrega (Final)"
                id="specific_fecha_entregaF"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                nameLabel="Guía principal"
                id="specific_guia_principal"
              />
            </FormGroup>
            <FormGroup className="row d-flex justify-content-center mb-0">
              <Button
                color="danger"
                className="mb-1"
                onClick={this.props.onBackTable}
              >
                Regresar
              </Button>
              <Button color="success ml-2 mb-1" onClick={this.handleFiltrada}>
                Consultar
              </Button>
            </FormGroup>
          </Form>
        </Collapse>
      </React.Fragment>
    );
  }
}
export default BusquedaEspecifica;
