/*
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente Principal para el Modal de las distintas opciones de operación para Alertas.
*/

import React, { Component } from "react";
import { ButtonGroup, Button } from "reactstrap";
import ModalAlta from "./ModalAlta";
import ModalConsulta from "./ModalConsulta";
import ModalReporte from "./ModalReporte";
import ModalOperacion from "./ModalOperacion";
import * as utility from "../utils/utility";

class ModalAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toogle_nuevo: false,
      toogle_consulta: false,
      toogle_reporte: false,
      toogle_operacion: false,
    };
    this.toogleNuevo = this.toogleNuevo.bind(this);
    this.toogleConsulta = this.toogleConsulta.bind(this);
    this.toogleReporte = this.toogleReporte.bind(this);
  }
  toogleNuevo = () => {
    this.setState((prevState) => ({ toogle_nuevo: !prevState.toogle_nuevo }));
  };
  toogleConsulta = () => {
    this.setState((prevState) => ({
      toogle_consulta: !prevState.toogle_consulta,
    }));
  };
  toogleReporte = () => {
    this.setState((prevState) => ({
      toogle_reporte: !prevState.toogle_reporte,
    }));
  };
  toogleOperacion = () => {
    this.setState((prevState) => ({
      toogle_operacion: !prevState.toogle_operacion,
    }));
  };
  render() {
    return (
      <div className="col-lg-3 col-sm-12 d-flex justify-content-center flex-column">
        <ButtonGroup className="mb-1">
          <Button
            color="primary"
            onClick={this.toogleNuevo}
            disabled={utility.obtener_permiso_especifico(
              "crear",
              this.props.permisos
            )}
          >
            Nuevo
          </Button>
          <Button
            color="primary"
            onClick={this.toogleConsulta}
            disabled={utility.obtener_permiso_especifico(
              "visualizar",
              this.props.permisos
            )}
          >
            Consulta
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button color="success" onClick={this.toogleOperacion}>
            Reportes
          </Button>
          <Button color="success" onClick={this.toogleReporte}>
            Operación
          </Button>
        </ButtonGroup>
        <ModalAlta
          toogle_nuevo={this.state.toogle_nuevo}
          toogleNuevo={this.toogleNuevo}
          clientes={this.props.clientes}
          consolidadoras={this.props.consolidadoras}
          update={this.props.update}
          on_cliente={this.props.on_cliente}
        />
        <ModalConsulta
          toogle_consulta={this.state.toogle_consulta}
          toogleConsulta={this.toogleConsulta}
          clientes={this.props.clientes}
          consolidadoras={this.props.consolidadoras}
          permisos={this.props.permisos}
          on_cliente={this.props.on_cliente}
        />
        <ModalOperacion
          toogle_reporte={this.state.toogle_reporte}
          toogleReporte={this.toogleReporte}
          checked_recolectar={this.props.checked_recolectar}
          consolidadoras={this.props.consolidadoras}
          checked_llegada={this.props.checked_llegada}
          checked_entrega={this.props.checked_entrega}
          checkedRecoleccion={this.props.checkedRecoleccion}
          checkedLlegada={this.props.checkedLlegada}
          checkedEntrega={this.props.checkedEntrega}
          update={this.props.update}
          on_cliente={this.props.on_cliente}
          permisos={this.props.permisos}
        />
        <ModalReporte
          toogle_operacion={this.state.toogle_operacion}
          toogleOperacion={this.toogleOperacion}
        />
      </div>
    );
  }
}
export default ModalAction;
