/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente general de la aplicación anticipos. 
*/
import axios from "axios";
import React, { Component } from "react";
import * as message from "./messages/messages";
import SearchBar from "./SearchBar";
import TableSol from "./Table";
import * as utility from "./utils/utility";
import * as permisos from "../../utils/permisos/permisos";

class Correo extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      codigos_sat: [],
      acutalizar: 0,
      mostrar: 0,
      onback: false,
      permisos: permisos.obtener_permisos_by_app(
        "correoselectronicos",
        localStorage.getItem("perm")
      )
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  update = (cambio) => {
    this.setState({ acutalizar: cambio });
  };
  desglose_cuenta = (cambio) => {
    this.setState({ mostrar: cambio });
  };
  on_back = (cambio) => {
    this.setState({ onback: cambio });
  };
  render() {
    return (
      <div className="container-fluid">
        <SearchBar
          codigos_sat={this.state.codigos_sat}
          update={this.update}
          desglose_cuenta={this.desglose_cuenta}
          onback={this.state.onback}
          on_back={this.on_back}
          permisos={this.state.permisos}
        />
        <TableSol
          actualizar={this.state.acutalizar}
          update={this.update}
          mostrar={this.state.mostrar}
          desglose_cuenta={this.desglose_cuenta}
          on_back={this.on_back}
          onback={this.state.onback}
          permisos={this.state.permisos}
        />
      </div>
    );
  }
}
export default Correo;
