import axios from 'axios';
import React, { Component } from 'react';
import { Table, Alert } from 'reactstrap';
import * as message from '../../utils/messages/message';
import * as utility from './utility';
import Paginacion from '../Pagination';

class TableHistoricoFac extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pagination_alerta: { count: 1, results: [] },
            page_actual: 1,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }
    handlePage = (page_actual) => {
        if (this.props.change_table === "0")
            this.setState({ page_actual }, () => { this.methodGET_API(utility.url_conciliacion_facturacion, 'pagination_alerta'); });
        else
            return null;
    }
    handleTipo_count = (tipo_app) => {
        if (tipo_app === "0")
            return this.state.pagination_alerta.count;
        else
            return 1;
    }
    methodGET_API = (url, state) => {
        /* let url_intern = url + `?page=${this.state.page_actual}`; */
        axios.get(url)
            .then(response => {
                if (response.status >= 200 && response.status < 400) {
                    switch (state) {
                        case 'pagination_alerta':
                            if (this._isMounted === true) {
                                let arr = [];
                                arr = utility.Burbuja_Alerta(response.data.results);
                                this.setState({
                                    pagination_alerta: { count: response.data.count, results: arr }
                                });
                            }
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    render() {
        return (
          <React.Fragment>
            <div className="overflow-auto tm-table">
              {this.props.data && this.props.data.results && this.props.data.results.length > 0 ? (
                <Table size="sm" id="tabla_conciliacionfac" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <th>Cuenta</th>
                      <th>Factura</th>
                      <th>Referencia</th>
                      <th>Cliente</th>
                      <th>Base IVA</th>
                      <th>IVA Total</th>
                      <th>Total Factura</th>
                      <th>Total Cuenta</th>
                      <th>Total PHXCDC</th>
                      <th>Pago Inicial</th>
                      <th>Pagos Posteriores</th>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.props.data.results.map((item, index) => (
                      <tr key={index} /* className={parseFloat(item.pago_inicial) === parseFloat(item.total_factura) ? "table-success" : "table-danger"} */>
                        <td><p className="text-center mb-0">{item.cuenta}</p></td>
                        <td><p className="text-center mb-0">{item.factura}</p></td>
                        <td><p className="text-center mb-0">{item.referencia}</p></td>
                        <td><p className="text-center mb-0">{item.cliente}</p></td>
                        <td><p className="text-center mb-0">{item.base_iva}</p></td>
                        <td><p className="text-center mb-0">{item.iva_total}</p></td>
                        <td><p className="text-center mb-0">{item.total_factura}</p></td>
                        <td><p className="text-center mb-0">{item.total_cuenta}</p></td>
                        <td><p className="text-center mb-0">{item.total_phxcdc}</p></td>
                        <td><p className="text-center mb-0">{item.pago_inicial}</p></td>
                        <td>
                    {item.pagos_posteriores !== "0.00" && Array.isArray(item.pagos_posteriores) ? (
                      <Table size="sm" bordered>
                        <thead>
                          <tr>
                            <th>Número</th>
                            <th>Fecha</th>
                            <th>Tipo</th>
                            <th>Abono</th>
                            <th>Cargo</th>
                            <th>Documento</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.pagos_posteriores.map((pago, idx) => (
                            <tr key={idx}>
                              <td><p className="text-center mb-0">{pago.numero}</p></td>
                              <td><p className="text-center mb-0">{pago.fecha}</p></td>
                              <td><p className="text-center mb-0">{pago.tipo}</p></td>
                              <td><p className="text-center mb-0">{pago.abono}</p></td>
                              <td><p className="text-center mb-0">{pago.cargo}</p></td>
                              <td><p className="text-center mb-0">{pago.documento}</p></td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <p className="text-center mb-0">-</p>
                    )}
                  </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <Alert color="danger" className="mt-5 col-lg-8 col-12 mx-auto">
                  <h2 className="mb-0 text-center">Debe seleccionar una aplicación y llenar los campos necesarios.</h2>
                </Alert>
              )}
            </div>
            {this.props.change_table === "0" ? null : (
              <Paginacion actual={this.state.page_actual} count={this.handleTipo_count(this.props.change_table)} paginate={this.handlePage} />
            )}
          </React.Fragment>
        );
      }
    }
    
    export default TableHistoricoFac;