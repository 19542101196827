/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la consulta de un proveedor. 
*/
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import MyInput from "../../../utils/custom/MyInput";
import WebSocketInstance from "../../../utils/websocket";
import * as message from "../../../utils/messages/message";
import * as utility from "../utils/utility";
import * as permisos from "../../../utils/permisos/permisos";
import { faArrowRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalAuxiliar from "../../Poliza/Modal/ModalAuxiliar";

class ModalConsulta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      fracccion: "",
      descripcion: "",
      fechaInicial: "",
      fechaFinal: "",
      umt: "",
      status: "",

      data_modificacion: {},
      input_disabled: [true, true],

      auxiliar: "",
      auxiliarInicial:"",
      toogle_auxiliar: false,
      auxiliares: [],
    };
    this.toogleAuxiliar = this.toogleAuxiliar.bind(this);
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  /* sendNotificacionHandler = (motivo) => {
        let folio = this.state.numero_proveedor;
        const notificacionObject = {
            tipo: 0,
            aplicacion: 'proveedor',
            motivo: motivo,
            folio: [folio]
        }
        WebSocketInstance.newNotificacion(notificacionObject);
    } */
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleFraccion = (e) => {
    this.setState({ fracccion: e.target.value });
  };
  handleConsulta = () => {
    if (this.state.fracccion.length === 0)
      message.message_modal("Advertencia", "Debe tener un ID de ingreso", 400);
    else {
      this.methodPOST_API(utility.url_consulta_Fraccion, "consulta", {
        fraccion: this.state.fracccion,
      });
    }
  };
  handleModificar = () => {
    if (this.state.fracccion.length === 0)
      message.message_modal("Advertencia", "Debe tener un ID de ingreso", 400);
    else {
      message
        .message_confimacion("¿Desea continuar con la operación modificar?", "")
        .then((res) => {
          if (res) {
            let data_modificacion = this.state.data_modificacion;
             // Verificar si el campo auxiliar se ha modificado
             if (this.state.auxiliar !== this.state.auxiliarInicial) {
              data_modificacion["auxiliar"] = this.state.auxiliar;
            }
            if (Object.values(data_modificacion).length > 0) {
              data_modificacion["clave"] = this.state.numero;
              this.methodPUT_API(
                utility.url_crea_diario,
                "modificar",
                data_modificacion
              );
              //this.sendNotificacionHandler('modificar');
            } else
              message.message_modal(
                "Advertencia",
                "No hay modificaciones",
                400
              );
          }
        });
    }
  };
  handleCerrar = () => {
    this.setState({
      fracccion: "",
      descripcion: "",
      fechaInicial: "",
      fechaFinal: "",
      umt: "",
      status: "",

      data_modificacion: {},
      input_disabled: [true, true],

      auxiliar: "",
      auxiliarInicial:"",
      toogle_auxiliar: false,
      auxiliares: [],
    });
    this.props.toogleConsulta();
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta":
              // Acceder a los datos del primer objeto en el array de respuesta
              const firstData = response.data.results[0];
              if (firstData) { // Verificar si hay datos en la respuesta
                this.setState({
                  descripcion: firstData.descripcion,
                  fechaInicial: firstData.fecha_inicial,
                  fechaFinal: firstData.fecha_final,
                  umt: firstData.umt,
                  status: firstData.status
                });
              } else {
                console.error("No hay datos en la respuesta");
              }
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  
  methodPUT_API = (url, state, data) => {
    let url_intern = url;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "modificar":
              this.handleCerrar();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  handleDescripcion = (e) => {
    let descripcion = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["descripcion"] = descripcion;
    this.setState({ descripcion, data_modificacion });
  };

  handleFechaInicial = (e) => {
    let fechaInicial = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["fechaInicial"] = fechaInicial;
    this.setState({ fechaInicial, data_modificacion });
  };

  handleFechaFinal = (e) => {
    let fechaFinal = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["fechaFinal"] = fechaFinal;
    this.setState({ fechaFinal, data_modificacion });
  };

  handleUMT = (e) => {
    let umt = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["umt"] = umt;
    this.setState({ umt, data_modificacion });
  };

  handleStatus = (e) => {
    let status = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["status"] = status;
    this.setState({ status, data_modificacion });
  };

  handleAuxiliar = (e) => {
    let auxiliar = e.target.value;
    this.setState({ auxiliar });
  };
  handleCallback = (childData) => {
    this.setState({ auxiliar: childData });
  };
  toogleAuxiliar = () => {
    this.setState((prevState) => ({
      toogle_auxiliar: !prevState.toogle_auxiliar,
    }));
    
    if (this.state.auxiliar === "") {
      this.getAuxiliaresMayores();
    } else {
      this.postAuxiliarSegundoNivel();
    }
  };
  getAuxiliaresMayores() {
    const url = utility.url_auxiliares;
    axios
      .get(url)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted){
            this.setState({
              auxiliares: response.data.results,
            });
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  postAuxiliarSegundoNivel() {
    axios
      .post(utility.url_auxiliares, {
        auxiliar: this.state.auxiliar,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted){
            this.setState({
              auxiliares: response.data.results,
            });
          }
            
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>Consulta Fracción</ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-10 col-8"
                id="numero_proveedor_consulta"
                value={this.state.fracccion}
                onChange={this.handleFraccion}
                nameLabel="Fracción"
              />
              <Button
                color="primary"
                className="col-lg-2 col-4 mb-2"
                onClick={this.handleConsulta}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-12"
                id="descripcion"
                nameLabel="Descripción"
                value={this.state.descripcion}
                onChange={this.handleDescripcion}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="date"
                bootstrap="col-lg-6"
                id="fechaInicial"
                nameLabel="Fecha Inicial"
                value={this.state.fechaInicial}
                onChange={this.handleFechaInicial}
              />
              <MyInput
                type="date"
                bootstrap="col-lg-6"
                id="fechaFinal"
                nameLabel="Fecha Final"
                value={this.state.fechaFinal}
                onChange={this.handleFechaFinal}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="umt"
                nameLabel="UMT"
                value={this.state.umt}
                onChange={this.handleUMT}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="status"
                nameLabel="Status"
                value={this.state.status}
                onChange={this.handleStatus}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          {/* <Button
            color="info"
            onClick={this.handleModificar}
            disabled={permisos.obtener_permiso_especifico(
              "modificar",
              this.props.permisos
            )}
          >
            Modificar
          </Button> */}
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalConsulta;
