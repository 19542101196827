/*
Componente que permite abrir un modal para dar de alta usuarios en el sistema
*/
import {
  faEye,
  faEyeSlash,
  faServer,
  faUser,
  faUserTie,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  CustomInput,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as messages from "../messages/messages";
import MyInput from "../../../utils/custom/MyInput";
import MySelect from "../utils/MySelect";
import PermisosApp from "../utils/PermisosApp";
import * as utility from "../utils/utility";

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      permisos: "",
      form_act: -1,
      show_pass: false,
      show_pass_other: false,
      email_valid: "",
      permisos_empleado: [],
      empleados: [],
      clientes: [],
      transportistas: [],
      usuario_mod: false,
      /* Colapse para las areas */
      contabilidad_open: false,
      facturacion_open: false,
      bancos_open: false,
      operacion_open: false,
      catalogos_open: false,
      administracion_open: false,

      contabilidad_visible: false,
      facturacion_visible: false,
      bancos_visible: false,
      operacion_visible: false,
      catalogos_visible: false,
      administracion_visible: false,
    };
    this.contabilidad_toggle = this.contabilidad_toggle.bind(this);
    this.facturacion_toggle = this.facturacion_toggle.bind(this);
    this.bancos_toggle = this.bancos_toggle.bind(this);
    this.operacion_toggle = this.operacion_toggle.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_lista_permisos, "permisos");
    this.methodGET_API(utility.url_lista_empleados, "empleados");
    this.methodGET_API(utility.url_lista_clientes, "clientes");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  contabilidad_toggle = () => {
    this.setState((prevState) => ({
      contabilidad_open: !prevState.contabilidad_open,
      facturacion_open: false,
      bancos_open: false,
      operacion_open: false,
      administracion_open: false,
      catalogos_open: false,
    }));
  };
  facturacion_toggle = () => {
    this.setState((prevState) => ({
      facturacion_open: !prevState.facturacion_open,
      bancos_open: false,
      operacion_open: false,
      contabilidad_open: false,
      administracion_open: false,
      catalogos_open: false,
    }));
  };
  bancos_toggle = () => {
    this.setState((prevState) => ({
      bancos_open: !prevState.bancos_open,
      operacion_open: false,
      contabilidad_open: false,
      facturacion_open: false,
      administracion_open: false,
      catalogos_open: false,
    }));
  };
  operacion_toggle = () => {
    this.setState((prevState) => ({
      operacion_open: !prevState.operacion_open,
      contabilidad_open: false,
      facturacion_open: false,
      bancos_open: false,
      administracion_open: false,
      catalogos_open: false,
    }));
  };
  catalogos_toggle = () => {
    this.setState((prevState) => ({
      catalogos_open: !prevState.catalogos_open,
      operacion_open: false,
      contabilidad_open: false,
      facturacion_open: false,
      bancos_open: false,
      administracion_open: false,
    }));
  };
  administracion_toggle = () => {
    this.setState((prevState) => ({
      administracion_open: !prevState.administracion_open,
      catalogos_open: false,
      operacion_open: false,
      contabilidad_open: false,
      facturacion_open: false,
      bancos_open: false,
    }));
  };
  handleChecked = (e) => {
    let usuario_mod = e.target.checked;
    this.setState({ usuario_mod });
  };
  /* ----------------------------------------- Funciones para el tratamiento del DOM ----------------------------------------- */
  contabilidadVisible = (e) => {
    let switch_contabilidad = e.target.checked;
    if (switch_contabilidad === false) {
      document.getElementById("contabilidad_visible").style.display = "none";
      this.setState({ contabilidad_visible: false });
    } else {
      document.getElementById("contabilidad_visible").style.display = "flex";
      this.setState({ contabilidad_visible: true });
    }
  };
  facturacionVisible = (e) => {
    let switch_facturacion = e.target.checked;
    if (switch_facturacion === false) {
      document.getElementById("facturacion_visible").style.display = "none";
      this.setState({ facturacion_visible: false });
    } else {
      document.getElementById("facturacion_visible").style.display = "flex";
      this.setState({ facturacion_visible: true });
    }
  };
  bancosVisible = (e) => {
    let switch_bancos = e.target.checked;
    if (switch_bancos === false) {
      document.getElementById("bancos_visible").style.display = "none";
      this.setState({ bancos_visible: false });
    } else {
      document.getElementById("bancos_visible").style.display = "flex";
      this.setState({ bancos_visible: true });
    }
  };
  operacionVisible = (e) => {
    let switch_operacion = e.target.checked;
    if (switch_operacion === false) {
      document.getElementById("operacion_visible").style.display = "none";
      this.setState({ operacion_visible: false });
    } else {
      document.getElementById("operacion_visible").style.display = "flex";
      this.setState({ operacion_visible: true });
    }
  };
  catalogosVisible = (e) => {
    let switch_catalogos = e.target.checked;
    if (switch_catalogos === false) {
      document.getElementById("catalogos_visible").style.display = "none";
      this.setState({ catalogos_visible: false });
    } else {
      document.getElementById("catalogos_visible").style.display = "flex";
      this.setState({ catalogos_visible: true });
    }
  };
  administracionVisible = (e) => {
    let switch_catalogos = e.target.checked;
    if (switch_catalogos === false) {
      document.getElementById("administracion_visible").style.display = "none";
      this.setState({ administracion_visible: false });
    } else {
      document.getElementById("administracion_visible").style.display = "flex";
      this.setState({ administracion_visible: true });
    }
  };
  /* ----------------------------------------- Fin de funciones para el tratamiento del DOM ----------------------------------------- */
  /**
   * Función que permite agregar permisos a la data general de alta
   * @param {String} permiso Número de permiso
   */
  agregaPermisos = (permiso) => {
    let permisos_empleado = this.state.permisos_empleado;
    permisos_empleado.push(permiso);
    this.setState({ permisos_empleado });
  };
  /**
   * Función que permite eliminar permisos a la data general de alta
   * @param {String} permiso Número de permiso
   */
  eliminaPermisos = (permiso) => {
    let permisos_empleado = this.state.permisos_empleado.filter(
      (perm) => perm !== permiso
    );
    this.setState({ permisos_empleado });
  };
  /**
   * Función que permite hacer una petición de forma POST
   * @param {String} url URL a consultar
   * @param {String} state Onpción del case
   * @param {Object} data Objeto a mandar atraves del metodo POST
   */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta_empleado":
              if (this._isMounted) {
                this.props.toogleNuevo();
                messages.message_modal(
                  response.data.title,
                  response.data.text,
                  response.status
                );
                this.props.update(1);
              }
              break;
            case "alta_cliente":
              if (this._isMounted) {
                this.props.toogleNuevo();
                messages.message_modal(
                  response.data.title,
                  response.data.text,
                  response.status
                );
                this.props.update(1);
              }
              break;
            case "alta_transportista":
              if (this._isMounted) {
                this.props.toogleNuevo();
                messages.message_modal(
                  response.data.title,
                  response.data.text,
                  response.status
                );
                this.props.update(1);
              }
              break;
            case "alta_administrador":
              if (this._isMounted) {
                this.props.toogleNuevo();
                messages.message_modal(
                  response.data.title,
                  response.data.text,
                  response.status
                );
                this.props.update(1);
              }
              break;
            case "elimina":
              if (this._isMounted) {
                this.props.toogleNuevo();
                messages.message_modal(
                  response.data.title,
                  response.data.text,
                  response.status
                );
                this.props.update(1);
              }
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          messages.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "permisos":
              if (this._isMounted)
                this.setState({
                  permisos: response.data.results,
                });
              break;
            case "empleados":
              if (this._isMounted)
                this.setState({
                  empleados: response.data.results,
                });
              break;
            case "clientes":
              if (this._isMounted)
                this.setState({
                  clientes: response.data.results,
                });
              break;
            case "transportista":
              if (this._isMounted)
                this.setState({
                  transportistas: response.data.results,
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          messages.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite crear un Empelado
   */
  onCreaEmpleado = () => {
    let data = {
      usuario: document.getElementById("nombre_usuario_empleado").value,
      empleado: document.getElementById("empleado_usuario_empleado").value,
      email: document.getElementById("correo_empleado").value,
      password: document.getElementById("password_empleado").value,
      password_m: document.getElementById("passwordMod_empleado").value,
      permisos: this.state.permisos_empleado,
      grupos: [],
    };
    if (this.state.contabilidad_visible === true) data.grupos.push(1);
    if (this.state.facturacion_visible === true) data.grupos.push(2);
    if (this.state.bancos_visible === true) data.grupos.push(3);
    if (this.state.operacion_visible === true) data.grupos.push(4);
    if (this.state.catalogos_visible === true) data.grupos.push(5);
    if (this.state.administracion_visible === true) data.grupos.push(7);
    let validacion = utility.valida_datos_alta("Empleado", data);
    if (validacion.valida)
      this.methodPOST_API(utility.url_alta_usuario, "alta_empleado", data);
    else messages.message_modal("Verificar datos", validacion.mensaje, 400);
  };
  /**
   * Función que permite crear un Cliente
   */
  onCreaCliente = () => {
    let data = {
      usuario: document.getElementById("nombre_usuario_cliente").value,
      name: document.getElementById("numero_usuario_cliente").value,
      email: document.getElementById("correo_cliente").value,
      password: document.getElementById("password_cliente").value,
      password_m: document.getElementById("passwordMod_cliente").value,
    };
    let validacion = utility.valida_datos_alta("Cliente", data);
    if (validacion.valida) {
      this.methodPOST_API(utility.url_alta_cliente, "alta_cliente", data);
    } else messages.message_modal("Verificar datos", validacion.mensaje, 400);
  };
  /**
   * Función que permite crear un Transportista
   */
  onCreaTransportista = () => {
    let data = {
      usuario: document.getElementById("razonsocial_usuario_transportista")
        .value,
      name: document.getElementById("rfc_usuario_transportista").value,
      email: document.getElementById("correo_transportista").value,
      password: document.getElementById("password_transportista").value,
    };
    let validacion = utility.valida_datos_alta("Transportista", data);
    if (validacion.valida) {
      this.methodPOST_API(
        utility.url_alta_transportista,
        "alta_transportista",
        data
      );
    } else messages.message_modal("Verificar datos", validacion.mensaje, 400);
  };
  /**
   * Función que permite crear un Administrador
   */
  onCreaAdministrador = () => {
    let data = {
      usuario: document.getElementById("nombre_usuario_administrador").value,
      email: document.getElementById("correo_administrador").value,
      password: document.getElementById("password_administrador").value,
      password_m: document.getElementById("passwordMod_administrador").value,
      empleado: document.getElementById("empleado_administrador").value,
    };
    let validacion = utility.valida_datos_alta("Administrador", data);
    if (validacion.valida) {
      this.methodPOST_API(
        utility.url_alta_administrador,
        "alta_administrador",
        data
      );
    } else messages.message_modal("Verificar datos", validacion.mensaje, 400);
  };
  /**
   * Función que permite validar si el correo cumple con la expresión regualar de correo
   * @param {Event} e Evento generado por un onChange()
   */
  onValidaCorreo = (e) => {
    let correo = e.target.value;
    if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/.test(correo))
      this.setState({ email_valid: "form-control is-valid" });
    else this.setState({ email_valid: "form-control is-invalid" });
  };
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_nuevo}
        toggle={this.props.toogleNuevo}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.props.toogleNuevo}>
          Alta De Solicitud
        </ModalHeader>
        <ModalBody>
          <div className="row mb-2 d-flex justify-content-center">
            <div className="col-lg-10 col-12 d-flex justify-content-around">
              <Button
                outline
                color="primary"
                onClick={() => {
                  this.setState({
                    form_act: 0,
                    show_pass: false,
                    show_pass_other: false,
                    email_valid: false,
                  });
                }}
                active={this.state.form_act === 0 ? true : false}
              >
                <FontAwesomeIcon icon={faServer} />
                &nbsp;&nbsp;&nbsp;Nuevo Administrador
              </Button>
              <Button
                outline
                color="primary"
                onClick={() => {
                  this.setState({
                    form_act: 1,
                    show_pass: false,
                    show_pass_other: false,
                    email_valid: false,
                  });
                }}
                active={this.state.form_act === 1 ? true : false}
              >
                <FontAwesomeIcon icon={faUser} />
                &nbsp;&nbsp;&nbsp;Nuevo Empleado
              </Button>
              <Button
                outline
                color="primary"
                onClick={() => {
                  this.setState({
                    form_act: 2,
                    show_pass: false,
                    show_pass_other: false,
                    email_valid: false,
                  });
                }}
                active={this.state.form_act === 2 ? true : false}
              >
                <FontAwesomeIcon icon={faUserTie} />
                &nbsp;&nbsp;&nbsp;Nuevo Cliente
              </Button>

              <Button
                outline
                color="primary"
                onClick={() => {
                  this.setState({
                    form_act: 3,
                    show_pass: false,
                    show_pass_other: false,
                    email_valid: false,
                  });
                }}
                active={this.state.form_act === 3 ? true : false}
              >
                <FontAwesomeIcon icon={faTruck} />
                &nbsp;&nbsp;&nbsp;Nuevo Transportista
              </Button>
            </div>
          </div>
          <Card>
            <CardBody>
              {this.state.form_act < 0 ? (
                <Alert className="text-center" color="danger">
                  <h3>Debe seleccionar un tipo de usuario.</h3>
                </Alert>
              ) : this.state.form_act === 1 ? (
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MySelect
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="empleado_usuario_empleado"
                      nameLabel="Empleado"
                      first_option="Seleccione un empleado"
                    >
                      {this.state.empleados.map((empleado) => (
                        <option value={empleado.id_empleado}>
                          {empleado.nombre} {empleado.apellido_paterno}
                        </option>
                      ))}
                    </MySelect>
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="nombre_usuario_empleado"
                      nameLabel="Nombre de usuario"
                    />
                    <div className="col-lg-4 col-12 d-flex justify-content-center align-items-center">
                      <p className="mb-1">Usuario Modificador:</p>&nbsp;&nbsp;
                      <label className="switch mt-1" htmlFor="usuario_mod">
                        <input
                          type="checkbox"
                          id="usuario_mod"
                          onChange={this.handleChecked}
                        />
                        <div className="slider round"></div>
                      </label>
                    </div>
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      cssInput={this.state.email_valid}
                      id="correo_empleado"
                      nameLabel="Correo Electronico"
                      onChange={this.onValidaCorreo}
                    />
                    <div className="grupo col-lg-4 p-0">
                      <input
                        type={
                          this.state.show_pass === false ? "password" : "text"
                        }
                        className="input-animated input-animatedd col-12 mb-2"
                        id="password_empleado"
                        required
                      />
                      <span className="barra"></span>
                      <br />
                      <label className="label-animated" htmlFor="">
                        Contraseña
                      </label>
                      <Button
                        color="link"
                        style={{ position: "absolute", top: 0, right: 0 }}
                        onClick={(e) => {
                          let tipo =
                            document.getElementById("password_empleado");
                          if (tipo.type === "password") {
                            tipo.type = "text";
                            this.setState({ show_pass: true });
                          } else {
                            tipo.type = "password";
                            this.setState({ show_pass: false });
                          }
                        }}
                      >
                        {this.state.show_pass === false ? (
                          <FontAwesomeIcon icon={faEye} />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        )}
                      </Button>
                    </div>
                    <div className="grupo col-lg-4 p-0">
                      <input
                        type={
                          this.state.show_pass_other === false
                            ? "password"
                            : "text"
                        }
                        className="input-animated col-12 mb-2"
                        id="passwordMod_empleado"
                        disabled={!this.state.usuario_mod}
                        required
                      />
                      <span className="barra"></span>
                      <br />
                      <label className="label-animated" htmlFor="">
                        Contraseña de modificación
                      </label>
                      <Button
                        color="link"
                        style={{ position: "absolute", top: 0, right: 0 }}
                        onClick={(e) => {
                          let tipo = document.getElementById(
                            "passwordMod_empleado"
                          );
                          if (tipo.type === "password") {
                            tipo.type = "text";
                            this.setState({ show_pass_other: true });
                          } else {
                            tipo.type = "password";
                            this.setState({ show_pass_other: false });
                          }
                        }}
                      >
                        {this.state.show_pass_other === false ? (
                          <FontAwesomeIcon icon={faEye} />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        )}
                      </Button>
                    </div>
                  </FormGroup>
                  <h4 className="mb-2">Seleccione áreas del usuario:</h4>
                  <div className="row d-flex justify-content-around mb-2 flex-lg-row flex-sm-column align-items-center">
                    <CustomInput
                      type="switch"
                      id="facturacion_open"
                      name="customSwitch"
                      label="Facturación y Cobranza"
                      onClick={this.facturacionVisible}
                    />
                    <CustomInput
                      type="switch"
                      className="col-12 col-lg-1 text-center"
                      id="contabilidad_open"
                      name="customSwitch"
                      label="Contabilidad"
                      onClick={this.contabilidadVisible}
                    />
                    <CustomInput
                      type="switch"
                      className="col-12 col-lg-1 text-center"
                      id="operacion_open"
                      name="customSwitch"
                      label="Operación"
                      onClick={this.operacionVisible}
                    />
                    <CustomInput
                      type="switch"
                      className="col-12 col-lg-1 text-center"
                      id="bancos_open"
                      name="customSwitch"
                      label="Bancos"
                      onClick={this.bancosVisible}
                    />
                    <CustomInput
                      type="switch"
                      className="col-12 col-lg-1 text-center"
                      id="catalogos_open"
                      name="customSwitch"
                      label="Catalogos"
                      onClick={this.catalogosVisible}
                    />
                    <CustomInput
                      type="switch"
                      className="col-12 col-lg-1 text-center"
                      id="administracion_open"
                      name="customSwitch"
                      label="Administración"
                      onClick={this.administracionVisible}
                    />
                  </div>
                  <Card
                    className="rounded-0 row"
                    id="contabilidad_visible"
                    style={{ display: "none" }}
                  >
                    <CardHeader
                      className="border-bottom-0"
                      onClick={this.contabilidad_toggle}
                    >
                      Contabilidad
                    </CardHeader>
                    <Collapse
                      isOpen={this.state.contabilidad_open}
                      style={{ height: "387px" }}
                      className="overflow-auto"
                    >
                      <CardBody className="border-top">
                        <PermisosApp
                          name_app="Polizas"
                          permisos={utility.permisos_for_app(
                            "polizas",
                            this.state.permisos
                          )}
                          agregaPermisos={this.agregaPermisos}
                          eliminaPermisos={this.eliminaPermisos}
                        />
                        {/* <PermisosApp name_app="Balance" /> Falta desarrollar app */}
                        <PermisosApp
                          name_app="Cuentas Auxiliares"
                          permisos={utility.permisos_for_app(
                            "cuentas_auxiliares",
                            this.state.permisos
                          )}
                          agregaPermisos={this.agregaPermisos}
                          eliminaPermisos={this.eliminaPermisos}
                        />
                        {/* <PermisosApp name_app="Estado de Resultados" /> Falta desarrollar app */}
                        <PermisosApp
                          name_app="Clientes"
                          permisos={utility.permisos_for_app(
                            "clientes",
                            this.state.permisos
                          )}
                          agregaPermisos={this.agregaPermisos}
                          eliminaPermisos={this.eliminaPermisos}
                        />
                        {/* <PermisosApp name_app="Balanza de Comprobación" /> Falta desarrollar app */}
                        <PermisosApp
                          name_app="Proveedores"
                          permisos={utility.permisos_for_app(
                            "proveedores",
                            this.state.permisos
                          )}
                          agregaPermisos={this.agregaPermisos}
                          eliminaPermisos={this.eliminaPermisos}
                        />
                        <PermisosApp
                          name_app="Historico Polizas"
                          permisos={utility.permisos_for_app(
                            "historico_poliza",
                            this.state.permisos
                          )}
                          agregaPermisos={this.agregaPermisos}
                          eliminaPermisos={this.eliminaPermisos}
                        />
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card
                    className="rounded-0 row"
                    id="facturacion_visible"
                    style={{ display: "none" }}
                  >
                    <CardHeader
                      className="border-bottom-0"
                      onClick={this.facturacion_toggle}
                    >
                      Facturación
                    </CardHeader>
                    <Collapse
                      isOpen={this.state.facturacion_open}
                      style={{ height: "214px" }}
                      className="overflow-auto"
                    >
                      <CardBody className="border-top">
                        {/* <PermisosApp name_app="Cuenta de Gastos" />
                                                        <PermisosApp name_app="Pago de Cuentas" />
                                                        <PermisosApp name_app="Cartera" />
                                                        <PermisosApp name_app="Historico Facturación" /> */}
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card
                    className="rounded-0 row"
                    id="bancos_visible"
                    style={{ display: "none" }}
                  >
                    <CardHeader
                      className="border-bottom-0"
                      onClick={this.bancos_toggle}
                    >
                      Bancos
                    </CardHeader>
                    <Collapse
                      isOpen={this.state.bancos_open}
                      style={{ height: "257px" }}
                      className="overflow-auto"
                    >
                      <CardBody className="border-top">
                        <PermisosApp
                          name_app="Solicitud de Anticipo"
                          permisos={utility.permisos_for_app(
                            "solicitud_anticipo",
                            this.state.permisos
                          )}
                          agregaPermisos={this.agregaPermisos}
                          eliminaPermisos={this.eliminaPermisos}
                        />
                        <PermisosApp
                          name_app="Anticipo"
                          permisos={utility.permisos_for_app(
                            "anticipo",
                            this.state.permisos
                          )}
                          agregaPermisos={this.agregaPermisos}
                          eliminaPermisos={this.eliminaPermisos}
                        />
                        {/* <PermisosApp name_app="Cheques" />
                                                        <PermisosApp name_app="Consilicación Bancaria" /> */}
                        <PermisosApp
                          name_app="Hitorico Solicitud de Anticipos"
                          permisos={utility.permisos_for_app(
                            "solicitud_anticipo_historico",
                            this.state.permisos
                          )}
                          agregaPermisos={this.agregaPermisos}
                          eliminaPermisos={this.eliminaPermisos}
                        />
                        <PermisosApp
                          name_app="Hitorico Anticipos"
                          permisos={utility.permisos_for_app(
                            "anticipo_historico",
                            this.state.permisos
                          )}
                          agregaPermisos={this.agregaPermisos}
                          eliminaPermisos={this.eliminaPermisos}
                        />
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card
                    className="rounded-0 row"
                    id="operacion_visible"
                    style={{ display: "none" }}
                  >
                    <CardHeader
                      className="border-bottom-0"
                      onClick={this.operacion_toggle}
                    >
                      Operación
                    </CardHeader>
                    <Collapse
                      isOpen={this.state.operacion_open}
                      style={{ height: "214px" }}
                      className="overflow-auto"
                    >
                      <CardBody className="border-top">
                        <PermisosApp
                          name_app="Notificación de Embarque"
                          permisos={utility.permisos_for_app(
                            "notificacion",
                            this.state.permisos
                          )}
                          agregaPermisos={this.agregaPermisos}
                          eliminaPermisos={this.eliminaPermisos}
                        />
                        {/* <PermisosApp name_app="Trafico" />
                                                        <PermisosApp name_app="Comprobación de Pedimento" /> */}
                        <PermisosApp
                          name_app="Historico Notificación"
                          permisos={utility.permisos_for_app(
                            "notificacion_historico",
                            this.state.permisos
                          )}
                          agregaPermisos={this.agregaPermisos}
                          eliminaPermisos={this.eliminaPermisos}
                        />
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card
                    className="rounded-0 row"
                    id="catalogos_visible"
                    style={{ display: "none" }}
                  >
                    <CardHeader
                      className="border-bottom-0"
                      onClick={this.catalogos_toggle}
                    >
                      Catalogos
                    </CardHeader>
                    <Collapse
                      isOpen={this.state.catalogos_open}
                      style={{ height: "214px" }}
                      className="overflow-auto"
                    >
                      <CardBody className="border-top">
                        <PermisosApp
                          name_app="Consolidadoras"
                          permisos={utility.permisos_for_app(
                            "consolidadoras",
                            this.state.permisos
                          )}
                          agregaPermisos={this.agregaPermisos}
                          eliminaPermisos={this.eliminaPermisos}
                        />
                        <PermisosApp
                          name_app="Patentes"
                          permisos={utility.permisos_for_app(
                            "patentes",
                            this.state.permisos
                          )}
                          agregaPermisos={this.agregaPermisos}
                          eliminaPermisos={this.eliminaPermisos}
                        />
                        <PermisosApp
                          name_app="Contabilidad Electronica"
                          permisos={utility.permisos_for_app(
                            "contabilidad_electronica",
                            this.state.permisos
                          )}
                          agregaPermisos={this.agregaPermisos}
                          eliminaPermisos={this.eliminaPermisos}
                        />
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card
                    className="rounded-0 row"
                    id="administracion_visible"
                    style={{ display: "none" }}
                  >
                    <CardHeader
                      className="border-bottom-0"
                      onClick={this.administracion_toggle}
                    >
                      Administración
                    </CardHeader>
                    <Collapse
                      isOpen={this.state.administracion_open}
                      style={{ height: "214px" }}
                      className="overflow-auto"
                    >
                      <CardBody className="border-top">
                        <PermisosApp
                          name_app="Usuarios"
                          permisos={utility.permisos_for_app(
                            "usuarios",
                            this.state.permisos
                          )}
                          agregaPermisos={this.agregaPermisos}
                          eliminaPermisos={this.eliminaPermisos}
                        />
                        <PermisosApp
                          name_app="Departamentos"
                          permisos={utility.permisos_for_app(
                            "departamentos",
                            this.state.permisos
                          )}
                          agregaPermisos={this.agregaPermisos}
                          eliminaPermisos={this.eliminaPermisos}
                        />
                        <PermisosApp
                          name_app="Empelados"
                          permisos={utility.permisos_for_app(
                            "empleados",
                            this.state.permisos
                          )}
                          agregaPermisos={this.agregaPermisos}
                          eliminaPermisos={this.eliminaPermisos}
                        />
                        <PermisosApp
                          name_app="Puestos"
                          permisos={utility.permisos_for_app(
                            "puestos",
                            this.state.permisos
                          )}
                          agregaPermisos={this.agregaPermisos}
                          eliminaPermisos={this.eliminaPermisos}
                        />
                      </CardBody>
                    </Collapse>
                  </Card>
                  <div className="row d-flex justify-content-end mt-3">
                    <Button
                      color="success"
                      className="col-12 col-lg-2"
                      onClick={this.onCreaEmpleado}
                    >
                      Crear Empleado
                    </Button>
                  </div>
                </Form>
              ) : this.state.form_act === 2 ? (
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MySelect
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="numero_usuario_cliente"
                      nameLabel="Cliente"
                      first_option="Seleccione un cliente"
                    >
                      {this.state.clientes.map((cliente) => (
                        <option value={cliente.numero}>
                          {cliente.numero} {cliente.nombre}
                        </option>
                      ))}
                    </MySelect>
                    <div className="grupo col-lg-6 p-0">
                      <input
                        type="text"
                        className="input-animated col-12 mb-2"
                        id="nombre_usuario_cliente"
                        required
                      />
                      <span className="barra"></span>
                      <br />
                      <label className="label-animated" htmlFor="">
                        Nombre de usuario
                      </label>
                    </div>
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      cssInput={this.state.email_valid}
                      id="correo_cliente"
                      nameLabel="Correo Electronico"
                      onChange={this.onValidaCorreo}
                    />
                    <div className="grupo col-lg-4 p-0">
                      <input
                        type={
                          this.state.show_pass === false ? "password" : "text"
                        }
                        className="input-animated col-12 mb-2"
                        id="password_cliente"
                        required
                      />
                      <span className="barra"></span>
                      <br />
                      <label className="label-animated" htmlFor="">
                        Contraseña
                      </label>
                      <Button
                        color="link"
                        style={{ position: "absolute", top: 0, right: 0 }}
                        onClick={(e) => {
                          let tipo =
                            document.getElementById("password_cliente");
                          if (tipo.type === "password") {
                            tipo.type = "text";
                            this.setState({ show_pass: true });
                          } else {
                            tipo.type = "password";
                            this.setState({ show_pass: false });
                          }
                        }}
                      >
                        {this.state.show_pass === false ? (
                          <FontAwesomeIcon icon={faEye} />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        )}
                      </Button>
                    </div>
                    <div className="grupo col-lg-4 p-0">
                      <input
                        type={
                          this.state.show_pass_other === false
                            ? "password"
                            : "text"
                        }
                        className="input-animated col-12 mb-2"
                        id="passwordMod_cliente"
                        required
                      />
                      <span className="barra"></span>
                      <br />
                      <label className="label-animated" htmlFor="">
                        Contraseña de modificación
                      </label>
                      <Button
                        color="link"
                        style={{ position: "absolute", top: 0, right: 0 }}
                        onClick={(e) => {
                          let tipo = document.getElementById(
                            "passwordMod_cliente"
                          );
                          if (tipo.type === "password") {
                            tipo.type = "text";
                            this.setState({ show_pass_other: true });
                          } else {
                            tipo.type = "password";
                            this.setState({ show_pass_other: false });
                          }
                        }}
                      >
                        {this.state.show_pass_other === false ? (
                          <FontAwesomeIcon icon={faEye} />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        )}
                      </Button>
                    </div>
                  </FormGroup>
                  <Alert color="primary" className="row">
                    <h4 className="col-12 px-0 mb-3 text-center">
                      El cliente contara con las siguientes aplicaciones y
                      permisos.
                    </h4>
                    <div className="col-3">
                      <p className="font-weight-bold mb-0">CONTABILIDAD</p>
                      <ul className="pl-3"></ul>
                    </div>
                    <div className="col-3">
                      <p className="font-weight-bold mb-0">
                        FACTURACIÓN Y COBRANZA
                      </p>
                      <ul className="pl-3"></ul>
                    </div>
                    <div className="col-3">
                      <p className="font-weight-bold mb-0">BANCOS</p>
                      <ul className="pl-3">
                        <li>
                          Anticipos: <br />
                          <i>Visualizar.</i>
                        </li>
                        <li>
                          Solicitud de Anticipos: <br />
                          <i>Visualizar.</i>
                        </li>
                        <li>
                          Notificación de Deposito: <br />
                          <i>Visualizar, Crear y Modificar</i>
                        </li>
                        <li>
                          Historico Anticipos: <br />
                          <i>Visualizar.</i>
                        </li>
                        <li>
                          Historico Solicitud: <br />
                          <i>Visualizar.</i>
                        </li>
                      </ul>
                    </div>
                    <div className="col-3">
                      <p className="font-weight-bold mb-0">OPERACIÓN</p>
                      <ul className="pl-3">
                        <li>
                          Notificación de Embarque: <br />
                          <i>Visualizar, Crear y Modificar.</i>
                        </li>
                        <li>
                          Operación: <br />
                          <i>Visualizar.</i>
                        </li>
                      </ul>
                    </div>
                  </Alert>
                  <div className="row d-flex justify-content-end mt-3">
                    <Button
                      color="success"
                      className="col-12 col-lg-2"
                      onClick={this.onCreaCliente}
                    >
                      Crear Cliente
                    </Button>
                  </div>
                </Form>
              ) : this.state.form_act === 3 ? (
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <div className="grupo col-lg-6 p-0">
                      <input
                        type="text"
                        className="input-animated col-12 mb-2"
                        id="razonsocial_usuario_transportista"
                        required
                      />
                      <span className="barra"></span>
                      <br />
                      <label className="label-animated" htmlFor="">
                        Razón Social
                      </label>
                    </div>
                    <div className="grupo col-lg-6 p-0">
                      <input
                        type="text"
                        className="input-animated col-12 mb-2"
                        id="rfc_usuario_transportista"
                        required
                      />
                      <span className="barra"></span>
                      <br />
                      <label className="label-animated" htmlFor="">
                        RFC
                      </label>
                    </div>
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      cssInput={this.state.email_valid}
                      id="correo_transportista"
                      nameLabel="Correo Electronico"
                      onChange={this.onValidaCorreo}
                    />
                    <div className="grupo col-lg-6 p-0">
                      <input
                        type={
                          this.state.show_pass === false ? "password" : "text"
                        }
                        className="input-animated col-12 mb-2"
                        id="password_transportista"
                        required
                      />
                      <span className="barra"></span>
                      <br />
                      <label className="label-animated" htmlFor="">
                        Contraseña
                      </label>
                      <Button
                        color="link"
                        style={{ position: "absolute", top: 0, right: 0 }}
                        onClick={(e) => {
                          let tipo = document.getElementById(
                            "password_transportista"
                          );
                          if (tipo.type === "password") {
                            tipo.type = "text";
                            this.setState({ show_pass: true });
                          } else {
                            tipo.type = "password";
                            this.setState({ show_pass: false });
                          }
                        }}
                      >
                        {this.state.show_pass === false ? (
                          <FontAwesomeIcon icon={faEye} />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        )}
                      </Button>
                    </div>
                  </FormGroup>
                  <Alert color="primary" className="row">
                    <h4 className="col-12 px-0 mb-3 text-center">
                      El transportista contara con las siguientes aplicaciones y
                      permisos.
                    </h4>
                    <div className="col-3">
                      <p className="font-weight-bold mb-0">CONTABILIDAD</p>
                      <ul className="pl-3"></ul>
                    </div>
                    <div className="col-3">
                      <p className="font-weight-bold mb-0">
                        FACTURACIÓN Y COBRANZA
                      </p>
                      <ul className="pl-3"></ul>
                    </div>
                    <div className="col-3">
                      <p className="font-weight-bold mb-0">BANCOS</p>
                      <ul className="pl-3">
                        <li>
                          Anticipos: <br />
                          <i>Visualizar.</i>
                        </li>
                        <li>
                          Solicitud de Anticipos: <br />
                          <i>Visualizar.</i>
                        </li>
                        <li>
                          Notificación de Deposito: <br />
                          <i>Visualizar, Crear y Modificar</i>
                        </li>
                        <li>
                          Historico Anticipos: <br />
                          <i>Visualizar.</i>
                        </li>
                        <li>
                          Historico Solicitud: <br />
                          <i>Visualizar.</i>
                        </li>
                      </ul>
                    </div>
                    <div className="col-3">
                      <p className="font-weight-bold mb-0">OPERACIÓN</p>
                      <ul className="pl-3">
                        <li>
                          Notificación de Embarque: <br />
                          <i>Visualizar, Crear y Modificar.</i>
                        </li>
                        <li>
                          Operación: <br />
                          <i>Visualizar.</i>
                        </li>
                      </ul>
                    </div>
                  </Alert>
                  <div className="row d-flex justify-content-end mt-3">
                    <Button
                      color="success"
                      className="col-12 col-lg-2"
                      onClick={this.onCreaTransportista}
                    >
                      Crear Transportista
                    </Button>
                  </div>
                </Form>
              ) : (
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MySelect
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="empleado_administrador"
                      nameLabel="Empleado"
                      first_option="Seleccione un empleado"
                    >
                      {this.state.empleados.map((empleado) => (
                        <option value={empleado.id_empleado}>
                          {empleado.nombre} {empleado.apellido_paterno}
                        </option>
                      ))}
                    </MySelect>
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="nombre_usuario_administrador"
                      nameLabel="Nombre de usuario"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      cssInput={this.state.email_valid}
                      id="correo_administrador"
                      nameLabel="Correo Electronico"
                      onChange={this.onValidaCorreo}
                    />
                    <div className="grupo col-lg-4 p-0">
                      <input
                        type={
                          this.state.show_pass === false ? "password" : "text"
                        }
                        className="input-animated col-12 mb-2"
                        id="password_administrador"
                        required
                      />
                      <span className="barra"></span>
                      <br />
                      <label className="label-animated" htmlFor="">
                        Contraseña
                      </label>
                      <Button
                        color="link"
                        style={{ position: "absolute", top: 0, right: 0 }}
                        onClick={(e) => {
                          let tipo = document.getElementById(
                            "password_administrador"
                          );
                          if (tipo.type === "password") {
                            tipo.type = "text";
                            this.setState({ show_pass: true });
                          } else {
                            tipo.type = "password";
                            this.setState({ show_pass: false });
                          }
                        }}
                      >
                        {this.state.show_pass === false ? (
                          <FontAwesomeIcon icon={faEye} />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        )}
                      </Button>
                    </div>
                    <div className="grupo col-lg-4 p-0">
                      <input
                        type={
                          this.state.show_pass_other === false
                            ? "password"
                            : "text"
                        }
                        className="input-animated col-12 mb-2"
                        id="passwordMod_administrador"
                        required
                      />
                      <span className="barra"></span>
                      <br />
                      <label className="label-animated" htmlFor="">
                        Contraseña de modificación
                      </label>
                      <Button
                        color="link"
                        style={{ position: "absolute", top: 0, right: 0 }}
                        onClick={(e) => {
                          let tipo = document.getElementById(
                            "passwordMod_administrador"
                          );
                          if (tipo.type === "password") {
                            tipo.type = "text";
                            this.setState({ show_pass_other: true });
                          } else {
                            tipo.type = "password";
                            this.setState({ show_pass_other: false });
                          }
                        }}
                      >
                        {this.state.show_pass_other === false ? (
                          <FontAwesomeIcon icon={faEye} />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        )}
                      </Button>
                    </div>
                  </FormGroup>
                  <Alert color="primary" className="row">
                    <h4 className="col-12 px-0 mb-0 text-center">
                      El administrador contara con todas las aplicaciones y el
                      nivel máximo.
                    </h4>
                  </Alert>
                  <div className="row d-flex justify-content-end mt-3">
                    <Button
                      color="success"
                      className="col-12 col-lg-2"
                      onClick={this.onCreaAdministrador}
                    >
                      Crear Administrador
                    </Button>
                  </div>
                </Form>
              )}
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    );
  }
}
export default ModalAlta;
