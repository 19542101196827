/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente Principal para el Modal de las distintas opciones de operación para solicitud de anticipos. 
*/
import React, { Component } from "react";
import { ButtonGroup, Button } from "reactstrap";
import ModalAlta from "./ModalAlta";
import ModalConsulta from "./ModalConsulta";
import ModalReporte from "./ModalReporte";
import ModalPago from "./ModalPago";
import * as permisos from "../../../utils/permisos/permisos";

class ModalAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toogle_nuevo: false,
      toogle_consulta: false,
      toogle_pago: false,
      toogle_reporte: false,
      button_consulta: true,
      button_reporte: true,
      button_nuevo: true,
    };
    this.toogleNuevo = this.toogleNuevo.bind(this);
    this.toogleConsulta = this.toogleConsulta.bind(this);
    this.tooglePago = this.tooglePago.bind(this);
    this.toogleReporte = this.toogleReporte.bind(this);
  }
  componentDidMount() {
    this.renderNivel();
  }
  toogleNuevo = () => {
    this.setState((prevState) => ({ toogle_nuevo: !prevState.toogle_nuevo }));
  };
  toogleConsulta = () => {
    this.setState((prevState) => ({
      toogle_consulta: !prevState.toogle_consulta,
    }));
  };
  toogleReporte = () => {
    this.setState((prevState) => ({
      toogle_reporte: !prevState.toogle_reporte,
    }));
  };
  tooglePago = () => {
    this.setState((prevState) => ({ toogle_pago: !prevState.toogle_pago }));
  };
  renderNivel = () => {
    let nivel_app = this.props.on_nivel;
    if (nivel_app === 1)
      this.setState({
        button_consulta: false,
        button_reporte: false,
        button_nuevo: true,
      });
    if (nivel_app >= 2)
      this.setState({
        button_consulta: false,
        button_reporte: false,
        button_nuevo: false,
      });
  };
  render() {
    return (
      <div className="col-lg-3 col-sm-12 d-flex justify-content-center flex-column mb-2 my-md-2">
        <ButtonGroup className="mb-1">
          <Button
            color="primary"
            onClick={this.toogleNuevo}
            disabled={permisos.obtener_permiso_especifico(
              "crear",
              this.props.permisos
            )}
          >
            Nuevo
          </Button>
          <Button color="primary" onClick={this.toogleConsulta}>
            Consulta
          </Button>
        </ButtonGroup>
        <ButtonGroup className="mb-1">
        <Button color="success" onClick={this.tooglePago}>
            Notificación de Depósito
          </Button>
        <Button color="success" onClick={this.toogleReporte}>
            Reportes
          </Button>
        </ButtonGroup>
        <ModalAlta
          toogle_nuevo={this.state.toogle_nuevo}
          toogleNuevo={this.toogleNuevo}
          referencias={this.props.referencias}
          clientes={this.props.clientes}
          update={this.props.update}
          on_cliente={this.props.on_cliente}
          ingresos={this.props.ingresos}
          contribuciones={this.props.contribuciones}
          phxcc={this.props.phxcc}
        />
        <ModalConsulta
          toogle_consulta={this.state.toogle_consulta}
          toogleConsulta={this.toogleConsulta}
          clientes={this.props.clientes}
          update={this.props.update}
          on_cliente={this.props.on_cliente}
          permisos={this.props.permisos}
          ingresos={this.props.ingresos}
          contribuciones={this.props.contribuciones}
          phxcc={this.props.phxcc}
        />
        <ModalPago
          toogle_pago={this.state.toogle_pago}
          tooglePago={this.tooglePago}
          checked={this.props.checked}
          clientes={this.props.clientes}
          sum_solicitudes={this.props.sum_solicitudes}
          sum_ingresos={this.props.sum_ingresos}
          sum_contribuciones={this.props.sum_contribuciones}
          sum_terceros={this.props.sum_terceros}
          update_pago={this.props.update_pago}
          on_cliente={this.props.on_cliente}
          on_nivel={this.props.on_nivel}
        />
         <ModalReporte
          toogle_reporte={this.state.toogle_reporte}
          toogleReporte={this.toogleReporte}
          clientes={this.props.clientes}
          bancos={this.props.bancos}
          update={this.props.update}
          on_cliente={this.props.on_cliente}
          permisos={this.props.permisos}
        />
      </div>
    );
  }
}
export default ModalAction;
