/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente general de la aplicación anticipos. 
*/
import axios from "axios";
import React, { Component } from "react";
import * as message from "./messages/messages";
import SearchBar from "./SearchBar";
import TableSol from "./Table";
import * as utility from "./utils/utility";
import * as permisos from "../../utils/permisos/permisos";

class CuentasAux extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      codigos_sat: [],
      acutalizar: 0,
      mostrar: 0,
      onback: false,
      permisos: permisos.obtener_permisos_by_app(
        "cuentasauxiliares",
        localStorage.getItem("perm")
      )
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_codigos_sat, "codigo_sat");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "codigo_sat":
              this.setState({ codigos_sat: response.data.results });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  update = (cambio) => {
    this.setState({ acutalizar: cambio });
  };
  desglose_cuenta = (cambio) => {
    this.setState({ mostrar: cambio });
  };
  on_back = (cambio) => {
    this.setState({ onback: cambio });
  };
  render() {
    return (
      <div className="container-fluid">
        <SearchBar
          codigos_sat={this.state.codigos_sat}
          update={this.update}
          desglose_cuenta={this.desglose_cuenta}
          onback={this.state.onback}
          on_back={this.on_back}
          permisos={this.state.permisos}
        />
        <TableSol
          actualizar={this.state.acutalizar}
          update={this.update}
          mostrar={this.state.mostrar}
          desglose_cuenta={this.desglose_cuenta}
          on_back={this.on_back}
          onback={this.state.onback}
          permisos={this.state.permisos}
        />
      </div>
    );
  }
}
export default CuentasAux;
