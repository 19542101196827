/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la consulta de una patente. 
*/
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Spinner,
  CustomInput,
  Badge,
  Collapse,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";
import MyInput from "../../../utils/custom/MyInput";
import {
  faPlus,
  faFileExport,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyTextArea from "../../../utils/custom/MyTextArea";
import XMLParser from "react-xml-parser";
import SelectComponent from "../utils/CustomSelect";
import MyDropzone from "../utils/MyDropzone";
import swal from "@sweetalert/with-react";
import MySelect from "../../../utils/custom/MySelect";
import WebSocketInstance from "../../../utils/websocket";
import ModalAuxiliar from "../../Poliza/Modal/ModalAuxiliar";
import classnames from "classnames";

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      numero: "",
      concepto: "",
      importe: "",
      fecha: "",
      observaciones: "",
      referencia: "",
      idAuxiliar: "",
      archivo_xml: null,
      archivo_pdf: null,
      archivo_valido: false,
      enable_add: true,
      desglose: [],
      desglose_operacion: [],
      movimientos: [],
      movimientos_select: [],
      identificativo: "",
      toogle_collapse: false,
      auxiliarTransacciones: "",
      toogle_auxiliar: false,
      auxiliares: [],

      beneficiario: "Seleccione un beneficiario",
      id_beneficiario: "",
      tipo_transaccion: "Seleccione un tipo",
      id_transaccion: "",
      banco: "Seleccione un banco",
      id_banco: "",
      tpo_banco: "",
      tipo_movimiento: "Seleccione un tipo",
      id_tipo_movimiento: "",
      tipo: "Seleccione un tipo",
      id_tipo: "",

      comprobante_campos: {},
      emisor_campos: {},
      receptor_campos: {},
      timbre_campos: {},

      tipo_transaccion_array: [
        { id: "CC", text: "Comprobable del cliente" },
        { id: "CE", text: "Comprobable de la empresa" },
        { id: "PC", text: "Por comprobar del cliente" },
        { id: "PE", text: "Por comprobar de la empresa" },
      ],
      tipo_movimiento_array: [
        { id: 0, nombre: "Transacción" },
        { id: 1, nombre: "Cheque" },
      ],
      enviarDatos: {},

      tap_factura: "1",
    };
    this.toogleAuxiliar = this.toogleAuxiliar.bind(this);
    this.toogleTap_factura = this.toogleTap_factura.bind(this);
  }

  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.identificativo;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "transaccion",
      motivo: motivo,
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */

  componentDidMount() {
    this._isMounted = true;
    document.addEventListener("keyup", this.handleEnterKeyPress);
  }
  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("keyup", this.handleEnterKeyPress);
  }

  /* --------------- Funciones que actualizan las variables de los inputs --------------- */
  handleCerrar = () => {
    this.props.toogleAlta();
    this.clearState();
  };
  toogleTap_factura = (value) => {
    this.setState({ tap_factura: value });
  };
  handleNumero = (e) => {
    let numero = e.target.value;
    this.setState({ numero });
  };
  handleConcepto = (e) => {
    let concepto = e.target.value;
    this.setState({ concepto });
  };
  handleFecha = (e) => {
    let fecha = e.target.value;
    this.setState({ fecha });
  };
  handleAuxiliar = (e) => {
    let auxiliarTransacciones = e.target.value;
    this.setState({ auxiliarTransacciones });
  };
  handleCallback = (childData, id) => {
    this.setState({ auxiliarTransacciones: childData });
    this.setState({ idAuxiliar: id });
  };
  handleEliminaArchivoXML = () => {
    this.setState({ archivo_xml: null });
  };
  handleEliminaArchivoPDF = () => {
    this.setState({ archivo_pdf: null });
  };
  handleTipo_transaccion = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_transaccion = e[label];
    let id_transaccion = e[label_id];
    this.setState({
      tipo_transaccion,
      id_transaccion,
      tipo_movimiento: "Seleccione un tipo",
      id_tipo_movimiento: "",
    });
  };
  handleBeneficiario = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let beneficiario = e[label];
    let id_beneficiario = e[label_id];
    this.setState({ beneficiario, id_beneficiario });
  };
  handleObservaciones = (e) => {
    let observaciones = e.target.value;
    this.setState({ observaciones });
  };
  handleTipo_movimiento = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_movimiento = e[label];
    let id_tipo_movimiento = e[label_id];
    this.setState({ tipo_movimiento, id_tipo_movimiento });
  };
  handleDocumento = (e) => {
    let documento = e.target.value;
    this.setState({ documento });
  };
  handleReferencia = (e) => {
    let referencia = e.target.value;
    this.setState({ referencia });
  };

  /* handleBancos = (e) => {
    const selectedValue = e.target.value;
    const [numero, tpo_bancoFull] = selectedValue.split(".");
    const [tpo_banco] = tpo_bancoFull.split(" - ");

    this.setState({
      banco: numero,
      tpo_banco: tpo_banco,
    });
  }; */
  handleBancos = (e) => {
    const selectedValue = e.target.value;
    const [numero] = selectedValue.split(" - ");
    this.setState({
      banco: numero,
    });
  };

  handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      // Si la tecla presionada fue 'Enter', llamamos a la función para manejar el evento
      if (e.target.id === "importe_alta_transaccion") {
        this.handleImporteBlur();
      }
      if (e.target.id === "abono_alta_transaccion") {
        this.handleAbonoBlur();
      }
    }
  };

  handleAbono = (e) => {
    let abono = e.target.value;

    // Remover caracteres que no son números, puntos o comas
    abono = abono.replace(/[^\d.,]/g, "");

    // Reemplazar comas por puntos
    abono = abono.replace(/,/g, ".");

    // Si hay más de un punto, eliminar los puntos extras
    const dotCount = abono.split(".").length - 1;
    if (dotCount > 1) {
      abono = abono.replace(/\./g, (match, offset) => (offset ? "" : match));
    }

    // Limitar a dos decimales
    const parts = abono.split(".");
    if (parts[1] && parts[1].length > 2) {
      abono = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    this.setState({ abono });
  };

  handleAbonoBlur = () => {
    let abono = this.state.abono;

    // Si el campo está vacío, establecer el valor como '0.00'
    if (!abono) {
      abono = "0.00";
    } else {
      // Convertir el valor a un número
      let num = parseFloat(abono);

      // Verificar si el valor es un número válido
      if (!isNaN(num)) {
        // Formatear el número con dos decimales
        abono = num.toFixed(2);
      } else {
        // Si no es un número válido, establecer el valor como '0.00'
        abono = "0.00";
      }
    }

    this.setState({ abono });
  };

  handleImporte = (e) => {
    let importe = e.target.value;

    // Remover caracteres que no son números, puntos o comas
    importe = importe.replace(/[^\d.,]/g, "");

    // Reemplazar comas por puntos
    importe = importe.replace(/,/g, ".");

    // Si hay más de un punto, eliminar los puntos extras
    const dotCount = importe.split(".").length - 1;
    if (dotCount > 1) {
      importe = importe.replace(/\./g, (match, offset) =>
        offset ? "" : match
      );
    }

    // Limitar a dos decimales
    const parts = importe.split(".");
    if (parts[1] && parts[1].length > 2) {
      importe = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    this.setState({ importe });
  };

  handleImporteBlur = () => {
    let importe = this.state.importe;

    // Si el campo está vacío, establecer el valor como '0.00'
    if (!importe) {
      importe = "0.00";
    } else {
      // Convertir el valor a un número
      let num = parseFloat(importe);

      // Verificar si el valor es un número válido
      if (!isNaN(num)) {
        // Formatear el número con dos decimales
        importe = num.toFixed(2);
      } else {
        // Si no es un número válido, establecer el valor como '0.00'
        importe = "0.00";
      }
    }

    this.setState({ importe });
  };

  handleArchivo = (file) => {
    let archivo = file;
    if (archivo.type === "text/xml") {
      let arch = new FileReader();
      arch.onload = (event) => {
        this.setState({ archivo_xml: archivo });
        this.handleXMLS(event.target.result);
      };
      arch.readAsText(archivo);
    } else if (archivo.type === "application/pdf")
      this.setState({ archivo_pdf: archivo });
    else message.message_modal("Error", "El archivo no es compatible", 500);
  };
  handleTipo_movimento = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo = e[label];
    let id_tipo = e[label_id];
    this.setState({ tipo, id_tipo });
  };
  toogleCollapse = (e) => {
    this.setState((prevState) => ({
      toogle_collapse: !prevState.toogle_collapse,
    }));
  };
  toogleAuxiliar = () => {
    this.setState((prevState) => ({
      toogle_auxiliar: !prevState.toogle_auxiliar,
    }));
    if (this.state.auxiliarTransacciones === "") {
      this.getAuxiliaresMayores();
    } else {
      this.postAuxiliarSegundoNivel();
    }
  };
  getAuxiliaresMayores() {
    const url = utility.url_auxiliares;
    axios
      .get(url)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              auxiliares: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  postAuxiliarSegundoNivel() {
    axios
      .post(utility.url_auxiliares, {
        auxiliar: this.state.auxiliarTransacciones,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              auxiliares: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }
  handleXML = (XMLData) => {
    axios
      .get(XMLData, {
        "Content-Type": "application/xml; charset=utf-8",
      })
      .then((res) => {
        let comprobante = new XMLParser().parseFromString(res.data);
        let emisor = comprobante.getElementsByTagName("cfdi:Emisor")[0];
        let receptor = comprobante.getElementsByTagName("cfdi:Receptor")[0];
        let timbre = comprobante.getElementsByTagName(
          "tfd:TimbreFiscalDigital"
        )[0];
        this.setState(
          {
            comprobante_campos: {
              folio: comprobante.attributes.Folio,
              fecha: comprobante.attributes.Fecha,
              forma_de_pago: comprobante.attributes.FormaPago,
              no_certificado: comprobante.attributes.NoCertificado,
              moneda: comprobante.attributes.Moneda,
              total: comprobante.attributes.Total,
              metodo_pago: comprobante.attributes.MetodoPago,
              tipo_comprobante: comprobante.attributes.TipoDeComprobante,
              numero_pago: comprobante.attributes.NumCtaPago,
              certificado: comprobante.attributes.Certificado,
              version: comprobante.attributes.Version,
            },
            emisor_campos: {
              rfc: emisor.attributes.Rfc,
              nombre: emisor.attributes.Nombre,
            },
            receptor_campos: {
              rfc: receptor.attributes.Rfc,
            },
            timbre_campos: {
              version: timbre.attributes.Version,
              uuid: timbre.attributes.UUID,
              fecha_timbrado: timbre.attributes.FechaTimbrado,
              sello_cfd: timbre.attributes.SelloCFD,
              sello_sat: timbre.attributes.SelloSAT,
            },
          },
          () => {
            this.methodPOST_SOAP();
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleXMLS = (XMLData) => {
    let comprobante = new XMLParser().parseFromString(XMLData);
    let emisor = comprobante.getElementsByTagName("cfdi:Emisor")[0];
    let receptor = comprobante.getElementsByTagName("cfdi:Receptor")[0];
    let timbre = comprobante.getElementsByTagName("tfd:TimbreFiscalDigital")[0];
    if (
      emisor === undefined ||
      receptor === undefined ||
      timbre === undefined
    ) {
      message.message_modal(
        "Error",
        "La factura no cumple con el formato del SAT",
        500
      );
      this.setState({
        comprobante_campos: {},
        emisor_campos: {},
        receptor_campos: {},
        timbre_campos: {},
        enable_add: true,
        archivo: {},
      });
    } else
      this.setState(
        {
          comprobante_campos: {
            folio: comprobante.attributes.Folio,
            fecha: comprobante.attributes.Fecha,
            forma_de_pago: comprobante.attributes.FormaPago,
            no_certificado: comprobante.attributes.NoCertificado,
            moneda: comprobante.attributes.Moneda,
            total: comprobante.attributes.Total,
            metodo_pago: comprobante.attributes.MetodoPago,
            tipo_comprobante: comprobante.attributes.TipoDeComprobante,
            numero_pago: comprobante.attributes.NumCtaPago,
            certificado: comprobante.attributes.Certificado,
            version: comprobante.attributes.Version,
          },
          emisor_campos: {
            rfc: emisor.attributes.Rfc,
            nombre: emisor.attributes.Nombre,
          },
          receptor_campos: {
            rfc: receptor.attributes.Rfc,
          },
          timbre_campos: {
            version: timbre.attributes.Version,
            uuid: timbre.attributes.UUID,
            fecha_timbrado: timbre.attributes.FechaTimbrado,
            sello_cfd: timbre.attributes.SelloCFD,
            sello_sat: timbre.attributes.SelloSAT,
          },
        },
        () => {
          this.methodPOST_SOAP();
        }
      );
  };
  handleMovimientos_desglose = () => {
    if (this.state.referencia.length === 0)
      message.message_modal("Advertencia", "Debe tener una Referencia", 400);
    else
      this.methodPOST_API(utility.url_desglose_operacion, "movimientos", {
        referencia: this.state.referencia,
      });
  };
  buildFormData(formData, data, parentKey) {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;
      formData.append(parentKey, value);
    }
  }
  jsonToFormData(data) {
    const formData = new FormData();
    this.buildFormData(formData, data);
    return formData;
  }
  handleAgregar = () => {
    if (
      this.state.id_tipo_movimiento === "" ||
      this.state.documento === "" ||
      this.state.abono === "" ||
      this.state.auxiliarTransacciones === ""
    ) {
      message.warningMessage("Por favor, ingrese los campos faltantes");
    } else {
      let movimientos = this.state.movimientos;
      let data = {
        movimiento: this.state.id_tipo_movimiento,
        auxiliar: this.state.auxiliarTransacciones,
        factura: this.state.documento,
        cantidad: this.state.abono,
      };
      movimientos.push(data);
      this.setState({
        movimientos,
        archivo: {},
        tipo_movimiento: "Seleccione un tipo",
        documento: "",
        referencia: "",
        abono: "",
        auxiliarTransacciones: "",
        comprobante_campos: {},
        emisor_campos: {},
        receptor_campos: {},
        timbre_campos: {},
      });
    }
  };
  handleElimina = (e) => {
    let movimientos = this.state.movimientos;
    let desglose = this.state.desglose;
    let id = parseInt(e.target.id);
    for (let i = 0; i < desglose.length; i++)
      for (let j = 0; j < desglose[i].movimientos.length; j++)
        if (id === desglose[i].movimientos[j].auxiliar)
          desglose[i].movimientos.splice(j, 1);
    movimientos.splice(e.target.value, 1);
    this.setState({ movimientos, desglose });
  };
  handleSet_archivo = () => {
    let archivo_xml = this.state.archivo_xml;
    let archivo_pdf = this.state.archivo_pdf;
    let movimientos_select = this.state.movimientos_select;
    let movimientos = this.state.movimientos;
    let desglose = this.state.desglose;
    let data = {
      xml: archivo_xml,
      pdf: archivo_pdf,
      movimientos: [],
    };
    for (let i = 0; i < movimientos_select.length; i++) {
      movimientos[
        parseInt(movimientos_select[i].slice(4, "mov_0".length))
      ].archivo = archivo_xml.name;
      data.movimientos.push(
        movimientos[parseInt(movimientos_select[i].slice(4, "mov_0".length))]
      );
    }
    desglose.push(data);
    this.setState({
      desglose,
      movimientos,
      archivo_xml: null,
      archivo_pdf: null,
      enable_add: true,
    });
  };
  handleSelect = (e) => {
    let id = e.target.id;
    let movimientos_select = this.state.movimientos_select;
    let is_checked = e.target.checked;
    if (is_checked) {
      movimientos_select.push(id);
      this.setState({ movimientos_select });
    } else
      for (let i = 0; i < movimientos_select.length; i++)
        if (movimientos_select[i] === id) movimientos_select.splice(i, 1);
    this.setState({ movimientos_select });
  };
  /* --------------- Fin de funciones que actualizan las variables de los inputs --------------- */
  /* headers: { "Content-type": "multipart/form-data"} */
  /* --------------- Funciones de eventos que generan una petición --------------- */

  handleAlta = () => {
    const {
      numero,
      banco,
      id_beneficiario,
      concepto,
      fecha,
      observaciones,
      importe,
      id_transaccion,
      auxiliarTransacciones,
      movimientos,
      tipo,
      idAuxiliar,
      abono,
      tipo_transaccion,
      beneficiario,
    } = this.state;

    // Verificar si alguna de las variables obligatorias está vacía
    if (
      !numero ||
      banco === "Seleccione un banco" ||
      beneficiario === "Seleccione un beneficiario" ||
      tipo_transaccion === "" ||
      fecha === "" ||
      importe === "" ||
      concepto === "" ||
      tipo === "Seleccione un tipo" ||
      observaciones === ""
    ) {
      // Construir el mensaje de advertencia sobre los campos faltantes
      let camposFaltantes = [];
      if (tipo === "Seleccione un tipo")
        camposFaltantes.push("Tipo de Movimiento");
      if (concepto === "") camposFaltantes.push("Concepto");
      if (importe === "") camposFaltantes.push("Importe");
      if (fecha === "") camposFaltantes.push("Fecha");
      if (tipo_transaccion === "Seleccione un tipo")
        camposFaltantes.push("Tipo de Transacción");
      if (beneficiario === "Seleccione un beneficiario")
        camposFaltantes.push("Beneficiario");
      if (banco === "Seleccione un banco") camposFaltantes.push("Banco");
      if (observaciones === "") camposFaltantes.push("Observaciones");

      // Mostrar mensaje de advertencia sobre campos faltantes
      message.completeMessage(`${camposFaltantes.join(", ")}`);
      return;
    }

    let data = {
      banco: banco || "",
      tipo: id_transaccion || "",
      beneficiario: id_beneficiario || "",
      concepto: concepto || "",
      fecha: fecha || "",

      observaciones: observaciones || "",
      importe: importe || "",
    };

    // Si el tipo es "Cheque", se incluye el número
    if (tipo === "Cheque") {
      // Verificar si se proporcionó un número de cheque
      if (!numero) {
        message.warningMessage("Por favor, ingrese el número del cheque");
        return;
      }
      data.numero = numero;
    } else {
      data.numero = "";
    }

    let dosData = [
      {
        auxiliar: auxiliarTransacciones || "",
        id: idAuxiliar || "",
      },
    ];

    if (id_transaccion === "CC") {
      data.pagos = dosData;
    } else {
      data.pagos = movimientos;
    }

    if (importe !== abono) {
      message.warningMessage(
        "Por favor, asegúrese de que el importe y el abono sean precisos."
      );
      return;
    }

    if (!importe.includes(".")) {
      // Si no tiene un punto decimal, agregar dos ceros decimales
      data.importe = importe + ".00";
    } else {
      // Si ya tiene un punto decimal, verificar si tiene dos decimales
      const partesImporte = importe.split(".");
      if (partesImporte[1].length !== 2) {
        // Si no tiene dos decimales, agregar ceros decimales adicionales
        data.importe = importe + "0"; // Agregar un cero decimal adicional
      } else {
        // Si ya tiene dos decimales, no es necesario hacer cambios
        data.importe = importe;
      }
    }

    // Confirmación de operación alta
    message
      .message_confimacion("¿Desea continuar con la operación alta?", "")
      .then((res) => {
        if (res) {
          // Realizar la llamada POST a la API
          axios
            .post(utility.url_alta_transaccion, data)
            .then((response) => {
              const identificativo = response.data.results.results[0].numero;
              this.setState({ identificativo }, () => {
                this.sendNotificacionHandler("crear");
              });
              this.handleCerrar();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
            })
            .catch((error) => {
              if (error.response) {
                message.message_modal(
                  error.response.data.title,
                  error.response.data.text,
                  error.response.status
                );
              }
            });
        }
      });
  };

  /* --------------- Fin de funciones de eventos que generan una petición --------------- */
  clearState = () => {
    this.setState({
      numero: "",
      concepto: "",
      fecha: "",
      observaciones: "",
      importe: "",
      referencia: "",
      archivo_xml: null,
      archivo_pdf: null,
      archivo_valido: false,
      enable_add: true,
      desglose: [],
      desglose_operacion: [],
      movimientos: [],
      movimientos_select: [],

      beneficiario: "Seleccione un beneficiario",
      id_beneficiario: "",
      tipo_transaccion: "Seleccione un tipo",
      id_transaccion: "",
      banco: "Seleccione un banco",
      id_banco: "",
      tipo_movimiento: "Seleccione un tipo",
      id_tipo_movimiento: "",
      tipo: "Seleccione un tipo",
      id_tipo: "",

      comprobante_campos: {},
      emisor_campos: {},
      receptor_campos: {},
      timbre_campos: {},

      tipo_transaccion_array: [
        { id: "CC", text: "Comprobable del cliente" },
        { id: "CE", text: "Comprobable de la empresa" },
        { id: "PC", text: "Por comprobar del cliente" },
        { id: "PE", text: "Por comprobar de la empresa" },
      ],
      tipo_movimiento_array: [
        { id: 0, nombre: "Transacción" },
        { id: 1, nombre: "Cheque" },
      ],
      toogle_collapse: false,
      auxiliarTransacciones: "",
      toogle_auxiliar: false,
      auxiliares: [],
    });
  };
  /**
   * Función que permite hacer una petición de forma POST.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} data Objeto a mandar atraves del metodo POST.
   */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "codigo_postal":
              if (this._isMounted)
                this.setState({
                  colonias: response.data.results,
                });
              break;
            case "alta":
              this.props.toogleAlta();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            case "movimientos":
              let movimientos = this.state.movimientos;
              let lista = response.data.results.lista_pagos;
              for (let i = 0; i < lista.length; i++) {
                let data = {
                  auxiliar: lista[i].tpo_pago,
                  documento: lista[i].factura,
                  referencia: lista[i].referencia,
                  abono: lista[i].cantidad,
                  archivo: "-",
                };
                movimientos.push(data);
              }

              this.setState({
                desglose_operacion: response.data.results.lista_pagos,
                movimientos,
              });
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  methodPOST_SOAP = () => {
    let sr =
      '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">' +
      "<soapenv:Header/>" +
      "<soapenv:Body>" +
      "<tem:Consulta>" +
      "<!--Optional:-->" +
      `<tem:expresionImpresa><![CDATA[?re=${this.state.emisor_campos.rfc}&rr=${this.state.receptor_campos.rfc}&tt=${this.state.comprobante_campos.total}
                &id=${this.state.timbre_campos.uuid}]]></tem:expresionImpresa>` +
      "</tem:Consulta>" +
      "</soapenv:Body>" +
      "</soapenv:Envelope>";
    swal({
      title: "Validando factura...",
      buttons: false,
      closeOnClickOutside: false,
      content: (
        <div>
          <Spinner
            color="success"
            style={{
              width: "5rem",
              height: "5rem",
              marginBottom: "26px",
              animation: "1.25s linear infinite spinner-border",
            }}
          >
            Loading...
          </Spinner>
        </div>
      ),
    });
    axios
      .post("/ConsultaCFDIService.svc", sr, {
        headers: {
          "Content-type": 'text/xml;charset="utf-8"',
          Accept: "text/xml",
          SOAPAction: "http://tempuri.org/IConsultaCFDIService/Consulta",
          "cache-control": "no-cache",
          Host: "consultaqr.facturaelectronica.sat.gob.mx",
        },
      })
      .then((res) => {
        let comprobante = new XMLParser().parseFromString(res.data);
        let codigo_estatus =
          comprobante.getElementsByTagName("a:CodigoEstatus")[0];
        let estado = comprobante.getElementsByTagName("a:Estado")[0];
        swal.close();
        if (
          codigo_estatus.value ===
          "S - Comprobante obtenido satisfactoriamente."
        )
          if (estado.value === "Vigente") {
            message.message_modal(
              "Factura valida",
              "Puede agregar el movimiento",
              200
            );
            this.setState({ enable_add: false });
          } else {
            message.message_modal(
              "Factura cancelada",
              "No puede agregar el movimiento",
              400
            );
            this.setState({ enable_add: true });
          }
        else {
          message.message_modal(
            "No existe la factura",
            "Seleccione otro archivo",
            500
          );
          this.setState({ enable_add: true });
        }
      })
      .catch((err) => {
        if (err)
          message.message_modal("Ocurrio un error", "Intentelo de nuevo", 500);
      });
  };
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_alta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>Alta Transacción</ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <SelectComponent
                bootstrap="col-lg-6 col-12"
                name_text="nombre"
                name_index="id"
                id="tipo_movimiento_alta_transaccion"
                nameLabel="Tipo de Movimiento"
                options={this.state.tipo_movimiento_array}
                value={this.state.tipo}
                onChange={this.handleTipo_movimento}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="numero_alta_transaccion"
                nameLabel="Número de Cheque"
                value={this.state.numero}
                onChange={this.handleNumero}
                disabled={this.state.id_tipo === 1 ? false : true}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="concepto_alta_transaccion"
                nameLabel="Concepto"
                value={this.state.concepto}
                onChange={this.handleConcepto}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="importe_alta_transaccion"
                nameLabel="Importe"
                value={this.state.importe}
                onChange={this.handleImporte}
                onBlur={this.handleImporteBlur}
              />
              <MyInput
                type="date"
                bootstrap="col-lg-4 col-12"
                id="fecha_alta_transaccion"
                nameLabel="Fecha de alta"
                value={this.state.fecha}
                onChange={this.handleFecha}
              />
            </FormGroup>
            <FormGroup className="row mb-2">
              <SelectComponent
                bootstrap="col-lg-4 col-12"
                name_text="text"
                name_index="id"
                id="tipo_transaccion_alta_transaccion"
                nameLabel="Tipo de Transacción"
                options={this.state.tipo_transaccion_array}
                value={this.state.tipo_transaccion}
                onChange={this.handleTipo_transaccion}
              />
              <SelectComponent
                bootstrap="col-lg-4 col-12"
                name_text="nombre"
                name_index="id"
                id="id"
                nameLabel="Beneficiario"
                options={this.props.beneficiarios}
                value={this.state.beneficiario}
                onChange={this.handleBeneficiario}
              />
              <MySelect
                bootstrap="col-lg-4 col-12"
                nameLabel="Bancos"
                first_option="Seleccione un banco"
                required
                value={this.state.banco}
                onChange={this.handleBancos}
                id="bancos_alta_anticipo"
              >
                {this.props.bancos.map((ban) => (
                  <option key={ban.numero}>
                    {ban.numero} - {ban.nombre}
                  </option>
                ))}
              </MySelect>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyTextArea
                bootstrap="col-12"
                nameLabel="Observaciones"
                id="observaciones_alta_transaccion"
                onChange={this.handleObservaciones}
                value={this.state.observaciones}
              />
            </FormGroup>
            {/* <Nav className="mb-1" tabs>
              <NavItem className="pointer-cursor">
                <NavLink
                  onClick={() => this.toogleTap_factura("1")}
                  className={classnames({
                    active: this.state.tap_factura === "1",
                  })}
                >
                  Con Factura
                </NavLink>
              </NavItem>
              <NavItem className="pointer-cursor">
                <NavLink
                  onClick={() => this.toogleTap_factura("2")}
                  className={classnames({
                    active: this.state.tap_factura === "2",
                  })}
                >
                  Sin Factura
                </NavLink>
              </NavItem>
            </Nav> */}
            <TabContent activeTab={this.state.tap_factura}>
              <TabPane tabId="1">
                <FormGroup className="row mb-0">
                  <MyInput
                    type="text"
                    bootstrap="col-lg-10 col-12"
                    id="auxiliar_transacciones"
                    nameLabel="Auxiliar"
                    value={this.state.auxiliarTransacciones}
                    onChange={this.handleAuxiliar}
                  />
                  <Button
                    color="primary"
                    className="col-lg-2 col-12 mb-2"
                    onClick={this.toogleAuxiliar}
                  >
                    Consulta
                  </Button>
                </FormGroup>
                <FormGroup className="row mb-2">
                  <MyInput
                    type="text"
                    bootstrap="col-lg-3 col-12"
                    id="documento_alta_transaccion"
                    nameLabel="Documento"
                    value={this.state.documento}
                    onChange={this.handleDocumento}
                    disabled={
                      this.state.id_transaccion === "PC" ||
                      this.state.id_transaccion === "PE"
                    }
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-3 col-12"
                    id="abono_alta_transaccion"
                    nameLabel="Abono"
                    value={this.state.abono}
                    onChange={this.handleAbono}
                  />
                  {this.state.id_transaccion === "CE" ||
                  this.state.id_transaccion === "PE" ? (
                    <SelectComponent
                      bootstrap="col-lg-4 col-12"
                      name_text="nombre"
                      name_index="clave"
                      id="egreso_alta_transaccion"
                      nameLabel=" Tipo de Egreso"
                      options={this.props.egresos}
                      value={this.state.tipo_movimiento}
                      onChange={this.handleTipo_movimiento}
                    />
                  ) : (
                    <SelectComponent
                      bootstrap="col-lg-4 col-12"
                      name_text="nombre"
                      name_index="clave"
                      id="phxcdc_alta_transaccion"
                      nameLabel="Tipo de PHXCDC"
                      options={this.props.phxcdc}
                      value={this.state.tipo_movimiento}
                      onChange={this.handleTipo_movimiento}
                    />
                  )}
                  <Button
                    color="primary"
                    className="col-lg-2 col-12 mb-2"
                    onClick={this.handleAgregar}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </FormGroup>
                {this.state.id_transaccion === "PC" ||
                this.state.id_transaccion === "PE" ? (
                  <Table size="sm" bordered hover responsive>
                    <thead className="text-center header-table">
                      <tr>
                        <td>Movimiento</td>
                        <td>Auxiliar</td>
                        <td>Abono</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody className="body-table">
                      {this.state.movimientos.map((data, index) => (
                        <tr key={index}>
                          <td className="text-center">
                            {this.state.id_transaccion === "CE" ||
                            this.state.id_transaccion === "PE"
                              ? utility.obtener_nombre_pagos(
                                  data.movimiento,
                                  this.props.egresos
                                )
                              : utility.obtener_nombre_pagos(
                                  data.movimiento,
                                  this.props.phxcdc
                                )}
                          </td>
                          <td className="text-center">{data.auxiliar}</td>
                          <td className="text-center">{data.cantidad}</td>
                          <td className="text-center">
                            <Button
                              size="sm"
                              color="danger"
                              id={data.auxiliar}
                              value={index}
                              onClick={this.handleElimina}
                            >
                              Eliminar
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <Table size="sm" bordered hover responsive>
                    <thead className="text-center header-table">
                      <tr>
                        <td>Movimiento</td>
                        <td>Documento</td>
                        <td>Auxiliar</td>
                        <td>Abono</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody className="body-table">
                      {this.state.movimientos.map((data, index) => (
                        <tr key={index}>
                          <td className="text-center">
                            {this.state.id_transaccion === "CE" ||
                            this.state.id_transaccion === "PE"
                              ? utility.obtener_nombre_pagos(
                                  data.movimiento,
                                  this.props.egresos
                                )
                              : utility.obtener_nombre_pagos(
                                  data.movimiento,
                                  this.props.phxcdc
                                )}
                          </td>
                          <td className="text-center">{data.factura}</td>
                          <td className="text-center">{data.auxiliar}</td>
                          <td className="text-center">{data.cantidad}</td>
                          <td className="text-center">
                            <Button
                              size="sm"
                              color="danger"
                              id={data.auxiliar}
                              value={index}
                              onClick={this.handleElimina}
                            >
                              Eliminar
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </TabPane>

              {/* <TabPane tabId="2">
                <FormGroup className="row mb-2">
                  <MyInput
                    type="text"
                    bootstrap="col-lg-10 col-8"
                    id="referencia_alta_transaccion"
                    nameLabel="Referencia"
                    value={this.state.referencia}
                    onChange={this.handleReferencia}
                  />
                  <Button
                    color="primary"
                    className="col-lg-2 col-4 mb-2"
                    onClick={this.handleMovimientos_desglose}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </FormGroup>
                <FormGroup className="row mb-0 mt-2 mt-lg-0">
                  <MyDropzone handleArchivo={this.handleArchivo} />
                  <div className="col-12 mt-2 d-flex justify-content-center flex-wrap">
                    {this.state.archivo_xml !== null ? (
                      <Badge color="light" className="border mb-2">
                        {this.state.archivo_xml.name}&nbsp;
                        <spam
                          style={{ cursor: "pointer" }}
                          onClick={this.handleEliminaArchivoXML}
                        >
                          &times;
                        </spam>
                      </Badge>
                    ) : null}
                    {this.state.archivo_pdf !== null ? (
                      <Badge color="light" className="border mb-2">
                        {this.state.archivo_pdf.name}&nbsp;
                        <spam
                          style={{ cursor: "pointer" }}
                          onClick={this.handleEliminaArchivoPDF}
                        >
                          &times;
                        </spam>
                      </Badge>
                    ) : null}
                  </div>
                  <Button
                    color="primary"
                    className="col-12 mb-2"
                    onClick={this.handleSet_archivo}
                    disabled={this.state.enable_add}
                  >
                    <FontAwesomeIcon icon={faFileExport} />
                  </Button>
                </FormGroup>
              </TabPane> */}
            </TabContent>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" className="mr-2" onClick={this.handleAlta}>
            Aceptar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
        <ModalAuxiliar
          toogle_catalogo={this.state.toogle_auxiliar}
          toogleCatalogo={this.toogleAuxiliar}
          listaAuxiliares={this.state.auxiliares}
          parentCallback={this.handleCallback}
        />
      </Modal>
    );
  }
}
export default ModalAlta;
