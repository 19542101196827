export const url_paginacion = '/DjangoWeb/NotificacionesDepositoApp/consulta/notificacion_deposito/paginacion/principal';
export const url_alta_deposito = '/DjangoWeb/NotificacionesDepositoApp/crea_modifica/notificacion_deposito';
export const url_consulta_deposito = '/DjangoWeb/NotificacionesDepositoApp/consulta/notificacion_deposito';
export const url_notifica_depoisto = '/DjangoWeb/NotificacionesDepositoApp/notifica/notificacion_deposito';
export const url_cancela_deposito = '/DjangoWeb/NotificacionesDepositoApp/cancela/notificacion_depositos';
export const url_pdf_notificacion = '/DjangoWeb/NotificacionesDepositoApp/consulta/pdf/notificacion_deposito/';

export const url_solicitudes_disponibles = '/DjangoWeb/SolicitudAnticipoApp/consulta/lista/solicitud_anticipo';

export const url_ingresos = '/DjangoWeb/BaseApp/consulta/cat_ingresos/lista';
export const url_phxcc = '/DjangoWeb/BaseApp/consulta/cat_phxcdc/lista';

export const url_clientes = '/DjangoWeb/BaseApp/consulta/clientes/lista';
export const url_bancos = '/DjangoWeb/BaseApp/consulta/bancos/lista';
export const url_contribuciones = '/DjangoWeb/BaseApp/consulta/contribuciones/lista';

export const url_verify = '/DjangoWeb/UsuariosApp/verica/usuario';
export const url_info_bancos = '/DjangoWeb/BaseApp/consulta/bancos'; 

export const url_pedimento = '/DjangoWeb/PedimentosApp/consulta/pedimento/paginacion/principal'; 
/**
 * Función que nos permite filtar la tabla por el input general que serra de tipo text.
 */
export const general_search = (e) => {
    e.preventDefault();
    let tr = document.getElementById('tabla_deposito').getElementsByTagName('tr');
    let data_input = document.getElementById('busqueda_general_deposito').value.toUpperCase();
    for (let i = 0; i < tr.length; i++) {
        let mostrar = false;
        let data = tr[i].getElementsByTagName('p');
        for (let j = 0; j < data.length; j++) {
            if (data_input === "") {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
            else if (data[j].textContent.toUpperCase().includes(data_input)) {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "rgba(0,123,255,1)";
                data[j].style.color = "#fff";
            }
            else {
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que nos permite filtrar latabla solamnete por la fecha.
 */
export const date_search = (e) => {
    e.preventDefault();
    var tabla_anticipo, input_general, tr, dato, input_dato, mostrar;
    tabla_anticipo = document.getElementById("tabla_deposito");
    input_general = document.getElementById("busqueda_date_deposito");
    input_dato = input_general.value;
    tr = tabla_anticipo.getElementsByTagName("tr");
    for (var i = 0; i < tr.length; i++) {
        mostrar = false;
        dato = tr[i].getElementsByTagName("p");
        for (var j = 0; j < dato.length; j++) {
            if (dato[j].textContent === input_dato) {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "rgba(0,123,255,1)";
                dato[j].style.color = "#fff";
            }
            else if (input_dato === "") {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
            else {
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que obtiene los nombres de todos los clientes
 * @param {array} arreglo 
 * @returns {array} Arreglo de nombres.
 */
export const get_nombres_clientes = (arreglo) => {
    var aux = [];
    for (var i = 0; i < arreglo.length; i++) {
        aux.push(arreglo[i].nombre);
    }
    return (aux);
}
/**
 * Finción que permite el retorno del número de cliente por su nombre.
 * @param {Sring} nombre Nombre del cliente. 
 * @param {Array} array Arreglo de clientes.
 * @returns {String} Número del cliente.
 */
export const get_number_cliente = (nombre, array) => {
    let only_number = "";
    let bandera = 0;
    for (let i = 0; i < array.length; i++)
        if (nombre === array[i].nombre) {
            for (let j = 0; j < 4; j++) {
                if (array[i].numero[j] !== '0')
                    bandera = 1;
                if (bandera === 1)
                    only_number += array[i].numero[j];
            }
            return (only_number);
        }
}
export const get_name_cliente = (numero, array) => {
    let complete_number = "";
    switch (numero.length) {
        case 1: complete_number = '000' + numero; break;
        case 2: complete_number = '00' + numero; break;
        case 3: complete_number = '0' + numero; break;
        case 4: complete_number = numero; break;
        default: break;
    }
    for (let i = 0; i < array.length; i++)
        if (complete_number === array[i].numero)
            return (array[i].nombre);
}
/**
 * Función que genera la fecha actual del día y su hora axacta.
 * @returns {Object} Contiene fecha y hora.
 */
export const get_fecha_actual = () => {
    const actual = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    return {
        fecha: actual.toISOString().slice(0, 10),
        hora: actual.toISOString().slice(11, 16),
        fecha_string: actual.toLocaleDateString("es-MX", options)
    }
}
export const valida_data_deposito_anticipo = (data) => {
    let message = "";
    if (data.solicitudes)
        if (data.solicitudes.length === 0)
            message += "Agregar una solicitud,"
    if (data.importe.length === 0)
        message += "Importe, ";
    if (data.cliente.length === 0)
        message += "Cliente, ";
    if (data.observaciones.length === 0)
        message += "Observaciones, ";
    if (data.banco.length === 0)
        message += "Banco, ";
    return message;
}
/**
 * Función que retorna el tipo de solicitud que es.
 * @param {number} type_sol 
 * @returns {String} Tipo de solicitud.
 */
export const tipo_solicitud = (type_sol) => {
    switch (type_sol) {
        case 0: return 'Ingresos a Agencia';
        case 1: return 'Cuadro Liquidación';
        case 2: return 'Pagos a Terceros';
        default: break;
    }
}
/**
 * 
 * @param {String} type_concepto 
 * @param {String} type_mov 
 * @param {Array} arr_ing 
 * @param {Array} arr_pag 
 * @param {Array} arr_ter 
 * @returns 
 */
export const get_type_concepto = (type_concepto, type_mov, arr_ing, arr_pag, arr_ter) => {
    if (type_mov === 0)
        for (let i = 0; i < arr_ing.length; i++)
            if (parseInt(type_concepto) === arr_ing[i].clave) {
                return (arr_ing[i].corto);
            }
    if (type_mov === 1)
        for (let i = 0; i < arr_pag.length; i++)
            if (parseInt(type_concepto) === arr_pag[i].contribution_cd) {
                return (arr_pag[i].abreviation);
            }
    if (type_mov === 2)
        for (let i = 0; i < arr_ter.length; i++)
            if (parseInt(type_concepto) === arr_ter[i].clave) {
                return (arr_ter[i].corto);
            }
}
export const add_class_disabled = (id) => {
    document.getElementById(id).classList.add('input-animatedd');
}
export const objeto_vacio = (object) => {
    for (var propiedad in object)
        if (object.hasOwnProperty(propiedad))
            return false;
    return true;
}

export const validacion_data_filtrada = (data) => {
    if (data.id_deposito.length === 0)
        delete data.id_deposito;
    if (data.cliente.length === 0)
        delete data.cliente;
    if (data.banco.length === 0)
        delete data.banco;
    if (data.documento.length === 0)
        delete data.documento;
    if (data.importe.length === 0)
        delete data.importe;
    if (data.tipo_deposito.length === 0)
        delete data.tipo_deposito;
    if (data.observaciones.length === 0)
        delete data.observaciones;
    if (data.fecha_alta_inicio.length > 0 && data.fecha_alta_final.length > 0) {
        data['fechas_alta'] = data.fecha_alta_inicio + "," + data.fecha_alta_final;
        delete data.fecha_alta_inicio
        delete data.fecha_alta_final
    }
    else if (data.fecha_alta_inicio.length > 0 && data.fecha_alta_final.length === 0) {
        data['fechas_alta'] = data.fecha_alta_inicio + "," + data.fecha_alta_inicio;
        delete data.fecha_alta_inicio
        delete data.fecha_alta_final
    }
    else if (data.fecha_alta_inicio.length === 0 && data.fecha_alta_final.length > 0) {
        data['fechas_alta'] = data.fecha_alta_final + "," + data.fecha_alta_final;
        delete data.fecha_alta_inicio
        delete data.fecha_alta_final
    }
    else {
        delete data.fecha_alta_inicio
        delete data.fecha_alta_final
    }
    if (data.fecha_notificacion_inicio.length > 0 && data.fecha_notificacion_final.length > 0) {
        data['fechas_notificacion'] = data.fecha_notificacion_inicio + "," + data.fecha_notificacion_final;
        delete data.fecha_notificacion_inicio
        delete data.fecha_notificacion_final
    }
    else if (data.fecha_notificacion_inicio.length > 0 && data.fecha_notificacion_final.length === 0) {
        data['fechas_notificacion'] = data.fecha_notificacion_inicio + "," + data.fecha_notificacion_inicio;
        delete data.fecha_notificacion_inicio
        delete data.fecha_notificacion_final
    }
    else if (data.fecha_notificacion_inicio.length === 0 && data.fecha_notificacion_final.length > 0) {
        data['fechas_notificacion'] = data.fecha_notificacion_final + "," + data.fecha_notificacion_final;
        delete data.fecha_notificacion_inicio
        delete data.fecha_notificacion_final
    }
    else {
        delete data.fecha_notificacion_inicio
        delete data.fecha_notificacion_final
    }
}
