/* 
Autor: Alexis Yael Hernández Grimaldo
Descripción: El componenete genera un bloque correspondiente a la busqueda dinamica en la tabla para acceder a la información.
*/
import React, { Component } from 'react';
import { Button, Collapse, Form, FormGroup } from 'reactstrap';
import '../../assets/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import MyInput from '../../utils/custom/MyInput';

class BusquedaEspecifica extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toogle_collapse: false,
        }
    }
    toogleCollapse = (e) => {
        this.setState(prevState => ({ toogle_collapse: !prevState.toogle_collapse }))
    }
    handleValida = (data) => {
        if (data.cuenta.length === 0)
            delete data.cuenta;
        if (data.cliente.length === 0)
            delete data.cliente;
        if (data.referencia.length === 0)
            delete data.referencia;
        if (data.status.length === 0)
            delete data.status;
        if (data.fecha.length === 0)
            delete data.fecha;
    }
    handleFiltrada = () => {
        let data = {
            cuenta: document.getElementById('specific_cuenta').value,
            cliente: document.getElementById('specific_cliente').value,
            referencia: document.getElementById('specific_referencia').value,
            status: document.getElementById('specific_status').value,
            fecha: document.getElementById('specific_fecha').value,
        }
        this.handleValida(data);
        this.props.onChangeFiltrada(data);
    }
    render() {
        return (
            <React.Fragment>
                <div className="row mb-2 mx-1">
                    <h3 className="col-md-11 col-9 text-md-left px-0 mb-0">
                        Consulta Específica
                    </h3>
                    <Button
                        color="info"
                        onClick={this.toogleCollapse}
                        className="col-md-1 col-3">
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </div>
                <Collapse isOpen={this.state.toogle_collapse}>
                    <Form className="container-fluid">
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-4" id="specific_cuenta" nameLabel="Cuenta" />
                            <MyInput type="text" bootstrap="col-lg-4" id="specific_cliente" nameLabel="Cliente" />
                            <MyInput type="text" bootstrap="col-lg-4" id="specific_referencia" nameLabel="Referencia" />
                            <MyInput type="text" bootstrap="col-lg-4" id="specific_status" nameLabel="Status" />
                            <MyInput type="date" bootstrap="col-lg-4" id="specific_fecha" nameLabel="Fecha" />
                        </FormGroup>

                        <FormGroup className="row d-flex justify-content-center mb-0">
                            <Button color="danger" className="mb-1" onClick={this.props.onBackTable}>Regresar</Button>
                            <Button color="success ml-2 mb-1" onClick={this.handleFiltrada}>Consultar</Button>
                        </FormGroup>
                    </Form>
                </Collapse>
            </React.Fragment>

        );
    }
}
export default BusquedaEspecifica;