export const url_pagination_consolidadoras = '/DjangoWeb/BaseApp/consulta/bancos/paginacion/principal';
export const url_consulta_bancos = '/DjangoWeb/BaseApp/consulta/bancos';
export const url_estados = '/DjangoWeb/BaseApp/consulta/estados/lista';
export const url_codigo_postal = '/DjangoWeb/BaseApp/consulta/codigo_postal';
export const url_bancos = '/DjangoWeb/BaseApp/consulta/bancos/lista';

export const url_crea_mod_bancos = '/DjangoWeb/BaseApp/crea_modifica/bancos';

/**
 * Función que nos permite filtar la tabla por el input general que serra de tipo text.
 */
 export const general_search = (e) => {
    e.preventDefault();
    let tr = document.getElementById('tabla_consolidadoras').getElementsByTagName('tr');
    let data_input = document.getElementById('busqueda_general_consolidadoras').value.toUpperCase();
    for (let i = 0; i < tr.length; i++) {
        let mostrar = false;
        let data = tr[i].getElementsByTagName('p');
        for (let j = 0; j < data.length; j++) {
            if (data_input === "") {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
            else if (data[j].textContent.toUpperCase().includes(data_input)) {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "rgba(0,123,255,1)";
                data[j].style.color = "#fff";
            }
            else {
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que nos permite filtrar latabla solamnete por la fecha.
 */
export const date_search = (e) => {
    e.preventDefault();
    var tabla_anticipo, input_general, tr, dato, input_dato, mostrar;
    tabla_anticipo = document.getElementById("tabla_consolidadoras");
    input_general = document.getElementById("busqueda_date_consolidadoras");
    input_dato = input_general.value;
    tr = tabla_anticipo.getElementsByTagName("tr");
    for (var i = 0; i < tr.length; i++) {
        mostrar = false;
        dato = tr[i].getElementsByTagName("p");
        for (var j = 0; j < dato.length; j++) {
            if (dato[j].textContent === input_dato) {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "rgba(0,123,255,1)";
                dato[j].style.color = "#fff";
            }
            else if (input_dato === "") {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
            else {
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
export const add_class_disabled = (id) => {
    document.getElementById(id).classList.add('input-animatedd');
}
