import * as actionsTypes from './actionsTypes';


export const addNotificacion = (notificaciones) => {
    return {
        type: actionsTypes.ADD_NOTIFICATION,
        notificaciones: notificaciones
    }
}

export const removeNotificaciones = () => {
    return {
        type: actionsTypes.REMOVE_NOTIFICATION,
        notificaciones: []
    }
}
