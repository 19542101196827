import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from './utility';

const initialState = {
    notificaciones: []
}

const addNotificacion = (state, action) => {
    return updateObject(state, { notificaciones: [...state.notificaciones, action.notificaciones] });
}

const removeNotificaciones = (state, action) => {
    return updateObject(state, {notificaciones: []});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_NOTIFICATION: return addNotificacion(state, action);
        case actionTypes.REMOVE_NOTIFICATION: return removeNotificaciones(state, action);
        default: return state;
    }
}

export default reducer;