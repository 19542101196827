import React, { Component } from 'react';
import { FormText } from 'reactstrap';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Mensajes extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    renderTimestamp = timestamp => {
        let prefix = "";
        const timeDiff = Math.round(
            (new Date().getTime() - new Date(timestamp).getTime()) / 60000
        );
        if (timeDiff < 1) {
            // less than one minute ago
            prefix = "justo ahora...";
        } else if (timeDiff < 60 && timeDiff > 1) {
            // less than sixty minutes ago
            prefix = `${timeDiff} min`;
        } else if (timeDiff < 24 * 60 && timeDiff > 60) {
            // less than 24 hours ago
            prefix = `${Math.round(timeDiff / 60)} hrs`;
        } else if (timeDiff < 31 * 24 * 60 && timeDiff > 24 * 60) {
            // less than 7 days ago
            prefix = `${Math.round(timeDiff / (60 * 24))} días`;
        } else {
            prefix = `${new Date(timestamp).toISOString().slice(0, 10)}`;
        }
        return prefix;
    };
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    };

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ border: "solid 1px #AAA", background: "#fff", borderRadius: " 5px 5px 0px 0px" }} className="d-flex align-items-center" >
                    <button className="d-block d-md-none" style={{ border: "2px solid white", borderRadius: "100%", position: "relative" }}
                        onClick={this.props.handleRegresar}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <div className="d-flex justify-content-center align-items-center imagen-usuario-chat my-2 mr-3 mx-md-3"
                        style={{
                            background: `rgba(${this.props.data.color_chat[0]},${this.props.data.color_chat[1]},${this.props.data.color_chat[2]},0.3)`,
                            border: `solid rgb(${this.props.data.color_chat[0]},${this.props.data.color_chat[1]},${this.props.data.color_chat[2]}) 2px`,
                            color: `rgb(${this.props.data.color_chat[0]},${this.props.data.color_chat[1]},${this.props.data.color_chat[2]})`
                        }}>
                        <p className="mb-0">{this.props.data.asunto[0].toUpperCase()}</p>
                    </div>
                    <p className="mb-0 d-inline-block" style={{ fontSize: "20px" }}>
                        {this.props.data.asunto}
                    </p>
                </div>
                <div className="tamaño-chat">
                    <div className="pt-3 overflow-auto" style={{ height: "100%" }}>
                        {this.props.mess.map(mensaje => mensaje.mio ?
                            (<div className="d-flex justify-content-end" >
                                <div className="mx-3 mb-3 py-2 px-4 bg-primary text-white rounded w-auto" style={{ maxWidth: "350px" }}>
                                    <p className="mb-0">{mensaje.mensaje}</p>
                                    <FormText color="ligth" style={{ textAlign: "end" }}>{this.renderTimestamp(mensaje.fecha)}</FormText>
                                </div>
                            </div>)
                            :
                            (<div className="d-flex justify-content-start">
                                <div className="mx-3 mb-3 py-2 px-4 rounded w-auto" style={mensaje.autor.includes('c') ?
                                    { background: "rgba(5,188,235,0.2)", maxWidth: "350px" } : { background: "#dfdfdf", maxWidth: "350px" }}>
                                    <p className="mb-0 text">{mensaje.mensaje}</p>
                                    <FormText color="dark" style={{ textAlign: "start" }}>{this.renderTimestamp(mensaje.fecha)}</FormText>
                                </div>
                            </div>)
                        )}
                        <div style={{ float: "left", clear: "both" }} ref={el => { this.messagesEnd = el; }} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default Mensajes;