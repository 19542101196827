/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un anticipo. 
*/
import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Collapse,
  Card,
  CardBody,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import * as utility from "../utils/utility";
import * as message from "../messages/messages";
import axios from "axios";
import swal from "@sweetalert/with-react";
import { faFileDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyInput from "../../../utils/custom/MyInput";
import MySelect from "../../../utils/custom/MySelect";
import MyTextArea from "../../../utils/custom/MyTextArea";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      toogle_solicitud: false,
      toogle_anticipo: false,
      fecha_vencimiento: "",
      fecha_alta: "",
      numero_select: "",
      nombre_select: "",
      identificativo: "",
      nombre_suggestions: [],
      bancos: "",
      bancosNot: "Seleccione un banco",
      referencia_bancaria: "",
      importe: "",
      observaciones: "",
      numDeposito: "",
      importeNot: "",
      tpo_banco: "",
      tpo_bancoNot: "",
      fechaActual: "",
    };
    this.toogleSolicitud = this.toogleSolicitud.bind(this);
    this.toogleAnticipo = this.toogleAnticipo.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    document.addEventListener('keyup', this.handleEnterKeyPress);
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener('keyup', this.handleEnterKeyPress);
  }

  toogleSolicitud = () => {
    this.setState(
      (prevState) => ({
        toogle_solicitud: !prevState.toogle_solicitud,
        toogle_anticipo: false,
      }),
      this.handleLimpiar()
    );
  };
  toogleAnticipo = () => {
    this.setState(
      (prevState) => ({
        toogle_anticipo: !prevState.toogle_anticipo,
        toogle_solicitud: false,
      }),
      this.handleLimpiar()
    );
  };
  handlebancos = (e) => {
    const selectedValue = e.target.value;
    const [numero] = selectedValue.split(" - ");
    this.setState({
      bancos: numero,
    });
  };

  handlebancosNot = (e) => {
    const selectedValue = e.target.value;
    const [numero] = selectedValue.split(" - ");

    this.setState({
      bancosNot: numero,
    });
  };

  /* handlebancosNot = (e) => {
    const selectedValue = e.target.value;
    const [numero, tpo_bancoFull] = selectedValue.split(".");
    const [tpo_banco] = tpo_bancoFull.split(" - ");

    this.setState({
      bancosNot: numero,
      tpo_bancoNot: tpo_banco,
    });
  }; */
  handleReferencia = (e) => {
    this.setState({ referencia_bancaria: e.target.value });
  };
  
  handleImporte = (e) => {
    let importe = e.target.value;
  
    // Remover caracteres que no son números, puntos o comas
    importe = importe.replace(/[^\d.,]/g, '');
  
    // Reemplazar comas por puntos
    importe = importe.replace(/,/g, '.');
  
    // Si hay más de un punto, eliminar los puntos extras
    const dotCount = importe.split('.').length - 1;
    if (dotCount > 1) {
      importe = importe.replace(/\./g, (match, offset) => offset ? '' : match);
    }
  
    // Limitar a dos decimales
    const parts = importe.split('.');
    if (parts[1] && parts[1].length > 2) {
      importe = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }
  
    this.setState({ importe: importe });
  };
  
  handleEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      // Si la tecla presionada fue 'Enter', llamamos a la función para manejar el evento
      this.handleImporteBlur();
      this.handleImporteBlurNot();
    }
  };

  handleImporteBlur = () => {
    let importe = this.state.importe;
  
    // Si el campo está vacío, establecer el valor como '0.00'
    if (!importe) {
      importe = '0.00';
    } else {
      // Convertir el valor a un número
      let num = parseFloat(importe);
  
      // Verificar si el valor es un número válido
      if (!isNaN(num)) {
        // Formatear el número con dos decimales
        importe = num.toFixed(2);
      } else {
        // Si no es un número válido, establecer el valor como '0.00'
        importe = '0.00';
      }
    }
  
    this.setState({ importe: importe });
  };
  
  handleImporteNot = (e) => {
    let importeNot = e.target.value;
  
    // Remover caracteres que no son números, puntos o comas
    importeNot = importeNot.replace(/[^\d.,]/g, '');
  
    // Reemplazar comas por puntos
    importeNot = importeNot.replace(/,/g, '.');
  
    // Si hay más de un punto, eliminar los puntos extras
    const dotCount = importeNot.split('.').length - 1;
    if (dotCount > 1) {
      importeNot = importeNot.replace(/\./g, (match, offset) => offset ? '' : match);
    }
  
    // Limitar a dos decimales
    const parts = importeNot.split('.');
    if (parts[1] && parts[1].length > 2) {
      importeNot = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }
  
    this.setState({ importeNot: importeNot });
  };

  handleImporteBlurNot = () => {
    let importeNot = this.state.importeNot;
  
    // Si el campo está vacío, establecer el valor como '0.00'
    if (!importeNot) {
      importeNot = '0.00';
    } else {
      // Convertir el valor a un número
      let num = parseFloat(importeNot);
  
      // Verificar si el valor es un número válido
      if (!isNaN(num)) {
        // Formatear el número con dos decimales
        importeNot = num.toFixed(2);
      } else {
        // Si no es un número válido, establecer el valor como '0.00'
        importeNot = '0.00';
      }
    }
  
    this.setState({ importeNot });
  };


  handleObservaciones = (e) => {
    this.setState({ observaciones: e.target.value });
  };
  /* ------ EVENTOS DE SUBMIT ------ */
  handleAltaSolAnticipo = (e) => {
    e.preventDefault();
  
    const {
      numDeposito,
      fechaActual,
      referencia_bancaria,
      observaciones,
      numero_select,
      importeNot,
      bancosNot
    } = this.state;
  
    // Array para almacenar los nombres de los campos faltantes
    const camposFaltantes = [];
  
    // Verificar si algún campo obligatorio está vacío y agregar su nombre al array
    if (!numDeposito) camposFaltantes.push("Número de Depósito");
    if (!numero_select) camposFaltantes.push("Número de Cliente");
    if (!fechaActual) camposFaltantes.push("Fecha Alta");
    if (bancosNot === "Seleccione un banco") camposFaltantes.push("Banco");
    if (!importeNot) camposFaltantes.push("Importe");
    if (!referencia_bancaria) camposFaltantes.push("Referencia Bancaria");
    if (!observaciones) camposFaltantes.push("Observaciones");
    
  
    // Si hay campos faltantes, mostrar mensaje de advertencia
    if (camposFaltantes.length > 0) {
      const mensaje = `${camposFaltantes.join(", ")}`;
      message.completeMessage(mensaje);
      return;
    }
  
    // Confirmar la operación alta
    message.message_confimacion("¿Desea continuar con la operación alta?", "").then((res) => {
      if (res) {
        // Realizar la operación de alta
        const [numero] = bancosNot.split(" - ");
        this.methodPOST_API(utility.url_alta_anticipo, "alta", {
          id_deposito: numDeposito,
          banco: numero,
          importe: fechaActual,
          referencia_bancaria: referencia_bancaria,
          fecha: document.getElementById("fecha_actual_notificacion").value,
          observaciones: observaciones,
          cliente: numero_select,
        });
      }
    });
  };
  

  handleAltaAnticipo = (e) => {
    e.preventDefault();
  
    const {
      numero_select,
      nombre_select,
      importe,
      bancos,
      referencia_bancaria,
      observaciones,
      fecha_alta
    } = this.state;
  
    // Verificar si algún campo obligatorio está vacío
    const camposFaltantes = [];
    if (!numero_select) camposFaltantes.push("Número de Cliente");
    if (!nombre_select) camposFaltantes.push("Nombre de Cliente");
    if (!importe) camposFaltantes.push("Importe");
    if (!fecha_alta) camposFaltantes.push("Fecha Alta");
    if (!bancos) camposFaltantes.push("Banco");
    if (!referencia_bancaria) camposFaltantes.push("Referencia Bancaria");
    if (!observaciones) camposFaltantes.push("Observaciones");
  
    // Si hay campos faltantes, mostrar mensaje de advertencia
    if (camposFaltantes.length > 0) {
      const mensaje = `${camposFaltantes.join(", ")}`;
      message.completeMessage(mensaje);
      return;
    }
  
    // Confirmar la operación alta
    message.message_confimacion("¿Desea continuar con la operación alta?", "").then((res) => {
      if (res) {
        // Realizar la operación de alta
        this.methodPOST_API(utility.url_alta_anticipo, "alta", {
          cliente: numero_select,
          banco: bancos,
          referencia_bancaria: referencia_bancaria,
          observaciones: observaciones,
          importe: importe,
          fecha: document.getElementById("fecha_actual_anticipo").value,
        });
      }
    });
  };
  

  /* ------ FIN EVENTOS DE SUBMIT ------ */
  handleFecha_alta = (e) => {
    this.setState({ fecha_alta: e.target.value });
  };
  handleFecha_altaSol = () => {
    let fecha = utility.get_date_actual().fecha;
    this.setState({ fechaActual: fecha });
  };
  /* handleFecha_alta = (e) => {
        let fecha = e.target.value;
        let vencimiento = new Date(fecha);
        let dias = parseInt(document.getElementById('vigencia_alta_anticipo').value);
        vencimiento.setDate(vencimiento.getDate() + dias);
        utility.add_class_disabled('vencimiento_alta_anticipo');
        this.setState({
            fecha_vencimiento: vencimiento.toISOString().slice(0, 10),
            fecha_alta: fecha,
        })
    } 
  /* onFechaVencimiento = (e) => {
        if (document.getElementById('fecha_actual_anticipo').value.length === 0)
            return null;
        else {
            let dias = parseInt(e.target.value);
            let vencimiento = new Date(document.getElementById('fecha_actual_anticipo').value);
            vencimiento.setDate(vencimiento.getDate() + dias);
            utility.add_class_disabled('vencimiento_alta_anticipo');
            this.setState({
                fecha_vencimiento: vencimiento.toISOString().slice(0, 10),
            });
        }
    } */
  /* handleFecha_altaSol = (e) => {
        let fecha = e.target.value;
        let vencimiento = new Date(fecha);
        let dias = parseInt(document.getElementById('vigencia_alta_solAnticipo').value);
        vencimiento.setDate(vencimiento.getDate() + dias);
        utility.add_class_disabled('vencimiento_alta_solAnticipo');
        this.setState({
            fecha_vencimiento: vencimiento.toISOString().slice(0, 10),
            fecha_alta: fecha,
        })
    } */
  /* onFechaVencimientoSol = (e) => {
        if (document.getElementById('fecha_actual_solAnticipo').value.length === 0)
            return null;
        else {
            let dias = parseInt(e.target.value);
            let vencimiento = new Date(document.getElementById('fecha_actual_solAnticipo').value);
            vencimiento.setDate(vencimiento.getDate() + dias);
            utility.add_class_disabled('vencimiento_alta_solAnticipo');
            this.setState({
                fecha_vencimiento: vencimiento.toISOString().slice(0, 10),
            });
        }
    } */
  handleCerrar = () => {
    this.setState({
      toogle_solicitud: false,
      toogle_anticipo: false,
      fecha_vencimiento: "",
      fecha_alta: "",
      fecha: "",
      fechaActual: "",
      numero_select: "",
      nombre_select: "",
      identificativo: "",
      nombre_suggestions: [],
      bancos: "",
      bancosNot: "",
      referencia_bancaria: "",
      importe: "",
      observaciones: "",
      numDeposito: "",
      importeNot: "",
    });
    this.props.toogleNuevo();
  };
  handleLimpiar = () => {
    this.setState({
      fecha_vencimiento: "",
      numero_select: "",
      nombre_select: "",
      nombre_suggestions: [],
      bancos: "",
      bancosNot: "Seleccione un banco",
      referencia_bancaria: "",
      importe: "",
      importeNot: "",
      observaciones: "",
      fecha_alta: "",
    });
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta":
              this.setState(
                { identificativo: response.data.results.numero },
                () => {
                  this.methodPDF_API(
                    utility.url_pdf_alta_anticipo,
                    "alta_anticipo",
                    response.data.results.numero + "/0"
                  );
                  //this.sendNotificacionHandler('crear');
                }
              );
              this.handleCerrar();
              swal({
                icon: "success",
                title: response.data.title,
                text: response.data.text,
                button: true,
                content: (
                  <div>
                    <LinkD
                      href=""
                      id="dowload_pdf_alta_anticipo"
                      download={`Anticipo ${response.data.results.numero}/0`}
                    />
                    <br />
                    <LinkV
                      href=""
                      id="window_pdf_alta_anticipo"
                      target="_blank"
                    />
                  </div>
                ),
              });
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPUT_API = (url, state, data) => {
    let url_intern = url;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta":
              this.setState(
                { identificativo: response.data.results.numero },
                () => {
                  this.methodPDF_API(
                    utility.url_pdf_alta_anticipo,
                    "alta_anticipo",
                    response.data.results.numero
                  );
                  this.sendNotificacionHandler("crear");
                }
              );
              this.handleCerrar();
              swal({
                icon: "success",
                title: response.data.title,
                text: response.data.text,
                button: true,
                content: (
                  <div>
                    <LinkD
                      href=""
                      id="dowload_pdf_alta_anticipo"
                      download={`Anticipo ${response.data.results.numero}`}
                    />
                    <br />
                    <LinkV
                      href=""
                      id="window_pdf_alta_anticipo"
                      target="_blank"
                    />
                  </div>
                ),
              });
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPDF_API = (url, state, num) => {
    let url_intern = url + `${num}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta_anticipo":
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);
              document.getElementById("dowload_pdf_alta_anticipo").href =
                fileURL;
              document.getElementById("window_pdf_alta_anticipo").href =
                fileURL;
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") text_add = "";
    else if (numero_cliente.length <= 4) {
      switch (numero_cliente.length) {
        case 1:
          text_add = "000";
          text_add += numero_cliente;
          break;
        case 2:
          text_add = "00";
          text_add += numero_cliente;
          break;
        case 3:
          text_add = "0";
          text_add += numero_cliente;
          break;
        case 4:
          text_add = numero_cliente;
          break;
        default:
          return null;
      }
    } else text_add = "";
    for (let i = 0; i < this.props.clientes.length; i++) {
      if (text_add.length === 0) {
        document.getElementById("nombre_cliente_anticipo").value = "";
        this.suggestionSelected("");
        break;
      }
      if (text_add === this.props.clientes[i].numero) {
        document.getElementById("nombre_cliente_anticipo").value =
          this.props.clientes[i].nombre;
        this.suggestionSelected(this.props.clientes[i].nombre);
        break;
      }
    }
  };
  suggestionSelected = (value) => {
    let number = utility.get_number_cliente(value, this.props.clientes);
    if (number === undefined) number = "";
    document.getElementById("numero_cliente_anticipo").value = number;
    this.setState({
      nombre_select: value,
      numero_select: number,
      nombre_suggestions: [],
    });
  };
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_nombres_clientes(this.props.clientes)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      nombre_suggestions: suggestions,
      nombre_select: value,
    });
  };
  renderSuggestion = () => {
    if (this.state.nombre_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  handleNumDeposito = (e) => {
    let numDeposito = e.target.value;
    this.setState({ numDeposito });
  };

  bancoCompleto = (banco) => {
    const selectedValue = banco;
    const [numero] = selectedValue.split(" - ");

    axios
      .post(utility.url_info_bancos, {
        numero,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted) {
            const nombre = response.data.text.replace("Banco ", "");
            let nombreCompleto = banco + " - " + nombre;

            this.setState({ bancosNot: nombreCompleto });
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handleConsulta = () => {
    if (this.state.numDeposito === "") {
      message.warningMessage(
        "Por favor, ingrese el número de notificación de depósito"
      );
    } else {
      axios
        .post(utility.url_num_deposito, {
          id_deposito: this.state.numDeposito,
        })
        .then((response) => {
          if (response.status >= 200 && response.status < 400) {
            if (this._isMounted) {
              const data = Array.isArray(response.data.results)
                ? response.data.results[0]
                : response.data.results;

              this.setState({
                bancosNot: this.bancoCompleto(data.banco),
                importeNot: data.importe !== null ? data.importe : "",
                numero_select: data.cliente !== null ? data.cliente : "",
                fechaActual: data.fecha_alta !== null ? data.fecha_alta : "",
                observaciones:
                  data.observaciones !== null ? data.observaciones : "",
              });
            }

            if (this.state.numero_select !== null) {
              let text_add = "";
              let numero_cliente = this.state.numero_select;
              if (numero_cliente === "") text_add = "";
              else if (numero_cliente.length <= 4) {
                switch (numero_cliente.length) {
                  case 1:
                    text_add = "000";
                    text_add += numero_cliente;
                    break;
                  case 2:
                    text_add = "00";
                    text_add += numero_cliente;
                    break;
                  case 3:
                    text_add = "0";
                    text_add += numero_cliente;
                    break;
                  case 4:
                    text_add = numero_cliente;
                    break;
                  default:
                    return null;
                }
              } else text_add = "";
              for (let i = 0; i < this.props.clientes.length; i++) {
                if (text_add.length === 0) {
                  document.getElementById("nombre_cliente_anticipo").value = "";
                  this.suggestionSelected("");
                  break;
                }
                if (text_add === this.props.clientes[i].numero) {
                  document.getElementById("nombre_cliente_anticipo").value =
                    this.props.clientes[i].nombre;
                  this.suggestionSelected(this.props.clientes[i].nombre);
                  break;
                }
              }
            }
          }
        })
        .catch((error) => {
          if (error.response)
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
        });
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_nuevo}
        toggle={this.props.toogleNuevo}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.props.toogleNuevo}>
          {" "}
          Alta Anticipo / Notificación De Depósito
        </ModalHeader>
        <ModalBody>
          <div className="row mb-2 d-flex justify-content-center">
            <div className="col-lg-10 col-12 d-flex justify-content-around">
              <Button color="primary" onClick={this.toogleAnticipo}>
                Anticipo
              </Button>
              <Button color="info" onClick={this.toogleSolicitud}>
                Notificación De Depósito
              </Button>
            </div>
          </div>
          <Collapse isOpen={this.state.toogle_anticipo}>
            <Card outline color="primary">
              <CardBody>
                <Form
                  className="container-fluid"
                  onSubmit={this.handleAltaAnticipo}
                >
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="numero_cliente_anticipo"
                      required
                      onChange={this.nameGenerator}
                      value={this.state.numero_select}
                      nameLabel="Número de Cliente"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="nombre_cliente_anticipo"
                      required
                      onChange={this.onNameChange}
                      value={this.state.nombre_select}
                      nameLabel="Nombre de Cliente"
                    />
                    {this.renderSuggestion()}
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="importe_anticipo"
                      value={this.state.importe}
                      required
                      onChange={this.handleImporte}
                      onBlur={this.handleImporteBlur}
                      nameLabel="Importe"
                    />
                    <MyInput
                      type="date"
                      bootstrap="col-lg-6"
                      id="fecha_actual_anticipo"
                      max={utility.get_fecha_actual().fecha}
                      value={this.state.fecha}
                      required
                      onChange={this.handleFecha_alta}
                      nameLabel="Fecha Alta"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MySelect
                      bootstrap="col-lg-6"
                      nameLabel="Bancos"
                      first_option="Seleccione un banco"
                      required
                      onChange={this.handlebancos}
                      id="bancos_alta_anticipo"
                    >
                      {this.props.bancos.map((ban) => (
                        <option key={ban.numero}>
                          {ban.numero} - {ban.nombre}
                        </option>
                      ))}
                    </MySelect>
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      value={this.state.referencia_bancaria}
                      required
                      onChange={this.handleReferencia}
                      nameLabel="Referencia Bancaria"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyTextArea
                      bootstrap="col-12"
                      value={this.state.observaciones}
                      required
                      onChange={this.handleObservaciones}
                      nameLabel="Observaciones"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      type="button"
                      color="success"
                      className="mr-2"
                      onClick={this.handleAltaAnticipo}
                    >
                      Aceptar
                    </Button>
                    <Button
                      type="button"
                      color="info"
                      onClick={this.handleCerrar}
                    >
                      Cerrar
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_solicitud}>
            <Card outline color="info">
              <CardBody>
                <Form
                  onSubmit={this.handleAltaSolAnticipo}
                  className="container-fluid"
                >
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-8"
                      id="numero_alta_solAnticipo"
                      nameLabel="Número de Notificación de Depósito"
                      required
                      value={this.state.numDeposito}
                      onChange={this.handleNumDeposito}
                    />
                    <Button
                      color="primary"
                      className="col-lg-4 col-12 mb-2"
                      onClick={this.handleConsulta}
                    >
                      Consulta
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="numero_cliente_notificacion"
                      required
                      onChange={this.nameGenerator}
                      value={this.state.numero_select}
                      nameLabel="Número de Cliente"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="nombre_cliente_notificacion"
                      required
                      onChange={this.onNameChange}
                      value={this.state.nombre_select}
                      nameLabel="Nombre de Cliente"
                    />
                    {this.renderSuggestion()}
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="date"
                      bootstrap="col-lg-6"
                      id="fecha_actual_notificacion"
                      nameLabel="Fecha Alta"
                      value={this.state.fechaActual}
                      required
                      onChange={this.handleFecha_altaSol}
                    />
                    <MySelect
                      bootstrap="col-lg-6"
                      nameLabel="Bancos"
                      required
                      first_option={
                        this.state.bancosNot
                          ? this.state.bancosNot
                          : "Seleccione un banco"
                      }
                      value={this.state.bancos}
                      onChange={this.handlebancosNot}
                      id="bancos_notificacion"
                    >
                      {this.props.bancos.map((ban) => (
                        <option key={ban.numero}>
                          {ban.numero} - {ban.nombre}
                        </option>
                      ))}
                    </MySelect>
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      value={this.state.importeNot}
                      required
                      onChange={this.handleImporteNot}
                      onBlur={this.handleImporteBlurNot}
                      nameLabel="Importe"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="fecha_actual_refbancaria"
                      nameLabel="Referencia Bancaria"
                      value={this.state.referencia_bancaria}
                      required
                      onChange={this.handleReferencia}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyTextArea
                      bootstrap="col-12"
                      nameLabel="Observaciones"
                      required
                      value={this.state.observaciones}
                      onChange={this.handleObservaciones}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      type="button"
                      color="success"
                      className="mr-2"
                      onClick={this.handleAltaSolAnticipo}
                    >
                      Aceptar
                    </Button>
                    <Button
                      type="button"
                      color="info"
                      onClick={this.handleCerrar}
                    >
                      Cerrar
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
        </ModalBody>
      </Modal>
    );
  }
}
export default ModalAlta;
