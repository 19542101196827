/* ---------- URL para consumos de Alerta ---------- */
export const url_paginacion = '/DjangoWeb/AlertasApp/consulta/alerta_embarque/paginacion/principal';
export const url_alta_alerta = '/DjangoWeb/AlertasApp/crea_modifica/alerta';
export const url_consulta_alerta = '/DjangoWeb/AlertasApp/consulta/alerta';
export const url_reporte_recoleccion_hora = '/DjangoWeb/AlertasApp/recolecta/alerta';
export const url_reporte_recoleccion_fecha = '/DjangoWeb/AlertasApp/crea/alerta/reporte_recoleccion';
export const url_consulta_reporte = '/DjangoWeb/ReportesApp/notificaciones_embarque_app/recoleccion/';
export const url_llegada_alerta = '/DjangoWeb/AlertasApp/llegada/alerta';
export const url_entrega_alerta = '/DjangoWeb/AlertasApp/entregar/alerta';
export const url_pdf_alerta = '/DjangoWeb/AlertasApp/consulta/alerta/pdf/';
export const url_notifica_alerta = '/DjangoWeb/AlertasApp/notifica/alerta';
export const url_cancela_alerta = '/DjangoWeb/AlertasApp/cancela/alerta';

export const url_checar_guia = '/DjangoWeb/AlertasApp/consulta/alerta/guia_referenicas';
export const url_referenciapdf = '/DjangoWeb/ExpedienteApp/descargar/archivo_pdf/operacion';
export const url_eliminapdf_guia = '/DjangoWeb/ExpedienteApp/eliminar/archivo_pdf';

export const url_crea_numParte = '/DjangoWeb/BaseApp/crea_modifica/cat_num_parte';
export const url_consulta_numparte = '/DjangoWeb/BaseApp/consulta/cat_num_parte';

export const url_cliente = '/DjangoWeb/BaseApp/consulta/clientes/lista';
export const url_patentes = '/DjangoWeb/BaseApp/consulta/agente_aduanal/lista';
export const url_consolidadoras = '/DjangoWeb/BaseApp/consulta/consolidadoras/lista';

export const url_verify = '/DjangoWeb/UsuariosApp/verica/usuario';
export const url_pdf_recoleccion = '/DjangoWeb/ReportesApp/notificaciones_embarque_app/recoleccion/pdf/';


/* export const  = '/DjangoWeb/ReportesApp/notificaciones_embarque_app/recoleccion/pdf/'; */
/**
 * Función que nos permite filtar la tabla por el input general que serra de tipo text.
 */
export const general_search = (e) => {
    e.preventDefault();
    let tr = document.getElementById('tabla_alerta').getElementsByTagName('tr');
    let data_input = document.getElementById('busqueda_general_alerta').value.toUpperCase();
    for (let i = 0; i < tr.length; i++) {
        let mostrar = false;
        let data = tr[i].getElementsByTagName('p');
        for (let j = 0; j < data.length; j++) {
            if (data_input === "") {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
            else if (data[j].textContent.toUpperCase().includes(data_input)) {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "rgba(0,123,255,1)";
                data[j].style.color = "#fff";
            }
            else {
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que nos permite filtrar latabla solamnete por la fecha.
 */
export const date_search = (e) => {
    e.preventDefault();
    var tabla_anticipo, input_general, tr, dato, input_dato, mostrar;
    tabla_anticipo = document.getElementById("tabla_alerta");
    input_general = document.getElementById("busqueda_date_alerta");
    input_dato = input_general.value;
    tr = tabla_anticipo.getElementsByTagName("tr");
    for (var i = 0; i < tr.length; i++) {
        mostrar = false;
        dato = tr[i].getElementsByTagName("p");
        for (var j = 0; j < dato.length; j++) {
            if (dato[j].textContent === input_dato) {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "rgba(0,123,255,1)";
                dato[j].style.color = "#fff";
            }
            else if (input_dato === "") {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
            else {
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}

/* ---------- Funciones para el proceso de información de Alerta ---------- */
/**
 * Función que permite retornar el nombre de un cliente ne especifico.
 * @param {String} numero Número de cliente.
 * @param {Array} array Arreglo de clientes.
 * @returns {String} Nombre del cliente.
 */
export const get_name_cliente = (numero, array) => {
    let complete_number = "";
    switch (numero.length) {
        case 1: complete_number = '000' + numero; break;
        case 2: complete_number = '00' + numero; break;
        case 3: complete_number = '0' + numero; break;
        case 4: complete_number = numero; break;
        default: break;
    }
    for (let i = 0; i < array.length; i++)
        if (complete_number === array[i].numero) {
            return (array[i].nombre);
        }
}
/**
 * Generación de dias naturales para Notificaciones.
 * @param {string} fecha_alta La fecha en que se dio de alta dicha operación
 * @returns Los que lleva desde que se dio de alta
 */
export const generar_Dias_Naturales = (fecha_alta) => {
    let date_alta = new Date(fecha_alta);
    let date_actual = new Date();
    let dias = Math.floor((date_actual.getTime() - date_alta.getTime()) / (1000 * 60 * 60 * 24));
    return (dias);
}
/**
 * Finción que permite el retorno del número de cliente por su nombre.
 * @param {Sring} nombre Nombre del cliente.
 * @param {Array} array Arreglo de clientes.
 * @returns {String} Número del cliente.
 */
export const get_number_cliente = (nombre, array) => {
    let only_number = "";
    let bandera = 0;
    for (let i = 0; i < array.length; i++)
        if (nombre === array[i].nombre) {
            for (let j = 0; j < 4; j++) {
                if (array[i].numero[j] !== '0')
                    bandera = 1;
                if (bandera === 1)
                    only_number += array[i].numero[j];
            }
            return (only_number);
        }
}
/**
 * Finción que permite el retorno del número de consolidadora por su nombre.
 * @param {Sring} nombre Nombre de la consolidadora.
 * @param {Array} array Arreglo de consolidadoras.
 * @returns {String} Número del consolidadoras.
 */
export const get_number_consolidaodras = (nombre, array) => {
    for (let i = 0; i < array.length; i++)
        if (nombre === array[i].nombre)
            return array[i].numero;
}
/**
 * Finción que permite el retorno del nombre de consolidadora por su número.
 * @param {Sring} nombre Número de la consolidadora.
 * @param {Array} array Arreglo de consolidadoras.
 * @returns {String} Nombre del consolidadoras.
 */
export const get_name_consolidadoras = (numero, array) => {
    for (let i = 0; i < array.length; i++)
        if (numero + "" === array[i].numero)
            return array[i].nombre;
}
/**
 * Función que obtiene los nombres de todos los clientes
 * @param {array} arreglo
 * @returns {array} Arreglo de nombres.
 */
export const get_nombres_clientes = (arreglo) => {
    var aux = [];
    for (var i = 0; i < arreglo.length; i++) {
        aux.push(arreglo[i].nombre);
    }
    return (aux);
}
/**
 * Función que obtiene los nombres de todos las consolidadoras
 * @param {array} arreglo
 * @returns {array} Arreglo de nombres.
 */
export const get_nombres_consolidadoras = (arreglo) => {
    var aux = [];
    for (var i = 0; i < arreglo.length; i++) {
        aux.push(arreglo[i].nombre);
    }
    return (aux);
}
export const get_fecha_actual = () => {
    const actual = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    return {
        fecha: actual.toISOString().slice(0, 10),
        hora: actual.toISOString().slice(11, 16),
        fecha_string: actual.toLocaleDateString("es-MX", options)
    }
}
/* ------------------------------------------- Funciones para cambio de input desactivado ----------------------------------------------- */
export const add_class_disabled = (id) => {
    document.getElementById(id).classList.add('input-animatedd');
}
/**
 * Función que permite filtrar los permisos de una app para obtener solo el nombre
 * @param {Object} permisos_by_app Permisos de app
 */
const filtro_permisos = (permisos_by_app) => {
    let permisos_string = JSON.stringify(permisos_by_app);
    let expresion = /"|{|}/ig;
    let permisos_number = permisos_string.replaceAll(expresion, '').split(',');
    let permisos_app = [];
    permisos_number.forEach(perm => {
        permisos_app.push(perm.split(':')[0]);
    });
    return (permisos_app);
}

export const obtener_permisos_by_app = (app, all_permisos) => {
    const permisos = JSON.parse(all_permisos);
    switch (app) {
        case 'alerta':
            return filtro_permisos(permisos.alerta);
        default:
            break;
    }
}

export const obtener_permiso_especifico = (permiso, all_permisos) => {
    for (let i = 0; i < all_permisos.length; i++)
        if (all_permisos[i].includes(permiso))
            return false;
    return true;
}
