import swal from '@sweetalert/with-react';
import MyTextArea from '../utils/MyTextArea';
import MyInput from '../utils/MyInput';

/**
 * Función que permite retornar el mensaje pertienente a su llamado.
 * @param {String} title Titulo del mensaje. 
 * @param {String} text Texto de la información importante del mensaje. 
 * @param {Number} estado Status HTTP.
 */
export const message_modal = (title, text, estado) => {
    if (estado >= 100 && estado <= 199)
        return swal(title, text, "info");
    else if (estado >= 200 && estado <= 299)
        return swal(title, text, "success");
    else if (estado >= 300 && estado <= 299)
        return swal(title, text, "warning");
    else if (estado >= 400 && estado <= 499)
        return swal(title, text, "warning");
    else
        return swal(title, text, "error");
}

export const message_confimacion_textarea = (title, text) => {
    return swal({
        icon: 'warning',
        closeOnClickOutside: false,
        title: title,
        text: text,
        buttons: ['Cancelar', true],
        dangerMode: false,
        content: (
            <div>
                <MyTextArea bootstrap="col-12" id="observaciones_textarea" nameLabel="Observaciones" required />
            </div>
        )
    })
}

export const message_confirmacion_password = (title, text) => {
    return swal({
        icon: 'warning',
        closeOnClickOutside: false,
        title: title,
        text: text,
        buttons: ['Cancelar', true],
        dangerMode: false,
        content: (
            <div>
                <div className="grupo col-12 p-0">
                    <input type="text" className="input-animated input-animatedd col-12 mb-2" id="usuario_modificador"
                        value={`${localStorage.getItem('user_name')}Admin`} disabled required />
                    <span className="barra"></span>
                    <br /><label className="label-animated" htmlFor="usuario_modificador">Usuario</label>
                </div>
                <MyInput type="password" bootstrap="col-12" id="contraseña_modificador" nameLabel="Contraseña" required />
                <MyTextArea bootstrap="col-12" id="observaciones_textarea" nameLabel="Observaciones" required />
            </div >
        )
    })
}

export const message_confirmacion_only_password = (title, text) => {
    return swal({
        icon: 'warning',
        closeOnClickOutside: false,
        title: title,
        text: text,
        buttons: ['Cancelar', true],
        dangerMode: false,
        content: (
            <div>
                <div className="grupo col-12 p-0">
                    <input type="text" className="input-animated input-animatedd col-12 mb-2" id="usuario_modificador"
                        value={`${localStorage.getItem('user_name')}Admin`} disabled required />
                    <span className="barra"></span>
                    <br /><label className="label-animated" htmlFor="usuario_modificador">Usuario</label>
                </div>
                <MyInput type="password" bootstrap="col-12" id="contraseña_modificador" nameLabel="Contraseña" required />
            </div >
        )
    })
}

export const message_confimacion = (title, text) => {
    return swal({
        icon: 'warning',
        closeOnClickOutside: false,
        title: title,
        text: text,
        buttons: ['Cancelar', true],
        dangerMode: false,
    })
}