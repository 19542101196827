import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { FormText } from 'reactstrap';
import Content from './components/Content';
import AppRoutes from './routes';
import * as authActions from './utils/actions/auth';
import * as messageActions from './utils/actions/message';
import * as notifyActions from './utils/actions/notify';
import WebSocketInstance from './utils/websocket';

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}

class App extends Component {

  componentDidMount() {
    this.props.onTryAutoSignup();
  }

  constructor(props) {
    super(props);
    this.state = {
      location: ""
    }
    this.waitForSocketConnection(() => {
      WebSocketInstance.addCallbacks(
        this.props.addNotificacion.bind(this),
        this.props.addMessage.bind(this),
        this.props.getChats.bind(this),
        this.props.setMessages.bind(this),
      );
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.notificaciones !== prevProps.notificaciones) {
      let ultimo_dato = this.props.notificaciones.length - 1;
      if (ultimo_dato >= 0)
        if (this.state.location === '/mensajes' && this.props.notificaciones[ultimo_dato].aplicacion === 'mensaje')
          return;
        else
          this.props.addToast(<div>
            <p className="text-uppercase mb-1"><b>{this.props.notificaciones[ultimo_dato].aplicacion}</b></p>
            <p className="mb-0">{this.props.notificaciones[ultimo_dato].mensaje}</p>
            <FormText className="mb-0" color="none">{this.props.notificaciones[ultimo_dato].hora}</FormText>
          </div>
            , {
              appearance: this.props.notificaciones[ultimo_dato].tipo_n === 0 ? 'success' : 'warning',
              autoDismiss: true
            });
    }
  }

  waitForSocketConnection(callback) {
    const component = this;
    setTimeout(() => {
      if (WebSocketInstance.state() === 1) {
        console.log('conexión segura');
        callback();
        return;
      }
      else {
        console.log('Esperando conexión...');
        component.waitForSocketConnection(callback);
      }
    }, 100);
  }
  getLocation = (location) => {
    this.setState({ location })
  }

  render() {
    return (
      <Router>
        <Content {...this.props} getLocation={this.getLocation} >
          <AppRoutes />
        </Content>
      </Router >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.csrfToken !== null,
    userId: state.auth.userid,
    notificaciones: state.notify.notificaciones
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(authActions.authCheckState()),
    addNotificacion: notificaciones => dispatch(notifyActions.addNotificacion(notificaciones)),
    addMessage: mensajes => dispatch(messageActions.addMessage(mensajes)),
    getChats: chats => dispatch(messageActions.getChats(chats)),
    setMessages: message => dispatch(messageActions.setMessages(message)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withToast(App));

