import axios from "axios";
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import swal from "@sweetalert/with-react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Row,
  Table,
  Collapse,
  Badge,
} from "reactstrap";
import * as message from "../messages/messages";
import MySelect from "../utils/MySelect";
import * as utility from "../utils/utility";
import WebSocketInstance from "../../../../utils/websocket";
import MyInput from "../utils/MyInput";
import MyTextArea from "../utils/MyTextArea";
import MyDropzone from "../utils/MyDropzone";

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      toogle_collapse: false,

      response: null,

      uuid: "EEEEEEEE-BB2C-4926-A19C-E1C26BA68D92", // Reemplazar con el UUID del documento

      xmlResponse: null,

      satAcuseResponse: null,

      regenerate: true, // Cambiar a false si no se desea regenerar el PDF
      template: "template_name", // Reemplazar con nombre de la plantilla deseada
      pdfResponse: null,
      xmlData: null,
      archivo_select: [],
    };
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.identificativo;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "complemento_pago",
      motivo: motivo,
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  toogleCollapse = (e) => {
    this.setState((prevState) => ({
      toogle_collapse: !prevState.toogle_collapse,
    }));
  };

  handleCerrar = () => {
    this.props.toogleNuevo();
    this.handleLimpiar();
  };

  handleLimpiar = () => {
    this.setState({
      toogle_collapse: false,

      response: null,

      uuid: "EEEEEEEE-BB2C-4926-A19C-E1C26BA68D92",

      xmlResponse: null,

      satAcuseResponse: null,

      regenerate: true,
      template: "template_name",
      pdfResponse: null,
    });
  };

  handleArchivo = async (file) => {
    if (file) {
      // Verifica si el archivo es de tipo XML
      if (
        file.type === "text/xml" ||
        file.name.toLowerCase().endsWith(".xml")
      ) {
        // Convierte el archivo en base64
        try {
          const base64 = await this.fileToBase64(file);

          // Aquí puedes guardar el archivo cifrado en base64 en el estado
          this.setState({ xmlData: base64 });

          // Agrega el archivo seleccionado a la lista de archivos
          const archivosSeleccionados = this.state.archivo_select;
          archivosSeleccionados.push(file);
          this.setState({ archivo_select: archivosSeleccionados });
        } catch (error) {
          console.error("Error al convertir el archivo XML en base64:", error);
        }
      } else {
        console.error("El archivo seleccionado no es un archivo XML válido.");
      }
    }
  };

  handleEliminaArchivo = (e) => {
    let archivos_arr = this.state.archivo_select;
    archivos_arr.splice(parseInt(e.target.id), 1);
    this.setState({ archivo_select: archivos_arr });
    this.setState({ xmlData: archivos_arr });
  };

  fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  //Timbrar documento
  handleTimbrarDoc = async () => {
    try {
      // Datos del documento XML en formato base64 (reemplaza "PD94..." con tus datos reales)
      const xmlData = this.state.xmlData; 

      console.log("Datos del XML a enviar:", xmlData); 

      if (!xmlData) {
        swal("Advertencia", "No hay datos XML para enviar.", "warning");
        return; 
      }

      // Datos para el cuerpo de la solicitud
      const requestData = {
        xml: xmlData,
        return_method: "base64",
      };

      // Token de autorización
      const authToken =
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJfNDdJRTh5M3d6ZGZ3ZEVmU1pOUTI3YWpXSmc8IwAA";

      // Realiza la solicitud POST a la API EMITE
      const response = await axios.post(
        "https://api-stage.emite.dev/v1/documents",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
          },
        }
      );

      // Verifica el tipo de documento devuelto por la API
      if (
        response.data.object === "cfdi33" ||
        response.data.object === "cfdi40" ||
        response.data.object === "ret10" ||
        response.data.object === "ret20"
      ) {
        // Si el documento es correcto, muestra el XML timbrado
        this.setState({ response: response.data });

        // Verifica si el documento se entrega en base64 o URL
        if (response.data.data.xml_info.url) {
          // El XML está disponible como URL firmada
          const xmlUrl = response.data.data.xml_info.url;
          console.log("XML disponible en URL:", xmlUrl);
        } else {
          // El XML está en base64
          const xmlBase64 = response.data.data.xml_info.xml;
          console.log("XML en base64:", xmlBase64);
        }
      } else {
        // Si la API devuelve un objeto de error, muestra los detalles de error
        this.setState({ response: response.data });
      }
    } catch (error) {
      console.error("Error al realizar la solicitud a la API EMITE:", error);
    }
  };

  RecuperarXMLTimbrado = async () => {
    try {
      // UUID del documento que se desea recuperar
      const { uuid } = this.state;

      // Token de autorización
      const authToken =
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJfNDdJRTh5M3d6ZGZ3ZEVmU1pOUTI3YWpXSmc8IwAA";

      // Realiza la solicitud GET para recuperar el XML timbrado
      const response = await axios.get(
        `https://api-stage.emite.dev/v1/documents/${uuid}/xml`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
          },
        }
      );

      // Actualiza el estado con la respuesta de la API
      this.setState({ xmlResponse: response.data });
    } catch (error) {
      // Manejo de errores
      console.error(
        "Error al realizar la solicitud para recuperar XML:",
        error
      );
    }
  };

  RecuperarAcuseSAT = async () => {
    try {
      // UUID del documento que se desea recuperar
      const { uuid } = this.state;

      // Token de autorización
      const authToken =
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJfNDdJRTh5M3d6ZGZ3ZEVmU1pOUTI3YWpXSmc8IwAA";

      // Realiza la solicitud GET para recuperar el acuse de recepción del SAT
      const response = await axios.get(
        `https://api-stage.emite.dev/v1/documents/${uuid}/sat`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
          },
        }
      );

      // Actualiza el estado con la respuesta de la API
      this.setState({ satAcuseResponse: response.data });
    } catch (error) {
      // Manejo de errores
      console.error(
        "Error al realizar la solicitud para recuperar el acuse de recepción SAT:",
        error
      );
    }
  };

  RecuperarPDF = async () => {
    try {
      // UUID del documento que se desea recuperar
      const { uuid } = this.state;

      // Token de autorización
      const authToken =
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJfNDdJRTh5M3d6ZGZ3ZEVmU1pOUTI3YWpXSmc8IwAA";

      // Parámetros de la solicitud GET (regenerate y template)
      const { regenerate, template } = this.state;

      // Realiza la solicitud GET para recuperar el PDF del documento
      const response = await axios.get(
        `https://api-stage.emite.dev/v1/documents/${uuid}/pdf`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
          },
          params: {
            regenerate,
            template,
          },
        }
      );

      // Actualiza el estado con la respuesta de la API
      this.setState({ pdfResponse: response.data });
    } catch (error) {
      // Manejo de errores
      console.error(
        "Error al realizar la solicitud para recuperar el PDF:",
        error
      );
    }
  };

  render() {
    const { response, xmlResponse, satAcuseResponse, pdfResponse } = this.state;
    return (
      <Modal
        isOpen={this.props.toogle_nuevo}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Complemento De Pago
        </ModalHeader>
        <ModalBody>
          <FormGroup className="row mb-0 mt-2 mt-lg-0">
            <MyDropzone handleArchivo={this.handleArchivo} accept=".xml" />
            <div className="col-12 my-2 d-flex justify-content-center flex-wrap">
              {this.state.archivo_select.map((archivo, index) => (
                <Badge color="light" className="border">
                  {archivo.name}&nbsp;
                  <spam
                    style={{ cursor: "pointer" }}
                    id={index}
                    onClick={this.handleEliminaArchivo}
                  >
                    &times;
                  </spam>
                </Badge>
              ))}
            </div>
          </FormGroup>
          <Form className="container-fluid">
            <div>
              <Row>
                <Col>
                  <Button color="primary" onClick={this.handleTimbrarDoc}>
                    Realizar Timbrado
                  </Button>
                  {response && (
                    <div>
                      <h2>Respuesta de la API EMITE:</h2>
                      <pre>{JSON.stringify(response, null, 2)}</pre>
                    </div>
                  )}
                </Col>
                <Col>
                  <Button color="info" onClick={this.RecuperarXMLTimbrado}>
                    Recuperar XML Timbrado
                  </Button>
                  {xmlResponse && (
                    <div>
                      <h2>XML Timbrado Recuperado:</h2>
                      <pre>{xmlResponse.data.document}</pre>
                    </div>
                  )}
                </Col>
                <Col>
                  <Button color="success" onClick={this.RecuperarAcuseSAT}>
                    Recuperar Acuse SAT
                  </Button>
                  {satAcuseResponse && (
                    <div>
                      <h2>Acuse SAT Recuperado:</h2>
                      <pre>{satAcuseResponse.data.document}</pre>
                    </div>
                  )}
                </Col>
                <Col>
                  <Button color="warning" onClick={this.RecuperarPDF}>
                    Recuperar PDF
                  </Button>
                  {pdfResponse && (
                    <div>
                      <h2>PDF Recuperado:</h2>
                      <pre>{pdfResponse.data.document}</pre>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
            {/* <div className="row mb-2 mx-1">
              <h4 className="col-md-11 col-9 text-md-left px-0 mb-0">
                Adjuntar
              </h4>
              <Button
                color="info"
                onClick={this.toogleCollapse}
                className="col-md-1 col-3"
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div> */}
            <Collapse isOpen={this.state.toogle_collapse}></Collapse>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalAlta;
