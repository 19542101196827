/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente Principal para el Modal de las distintas opciones de operación para solicitud de anticipos. 
*/
import React, { Component } from "react";
import { ButtonGroup, Button } from "reactstrap";
import ModalAlta from "./ModalAlta";
import ModalConsulta from "./ModalConsulta";
import ModalCrearEnCatalogos from "./ModalCrearEnCatalogos";
import * as permisos from "../../../utils/permisos/permisos";

class ModalAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toogle_nuevo: false,
      toogle_consulta: false,
      toogle_cuentauxiliar: false,
      on_back: false,
    };
    this.toogleNuevo = this.toogleNuevo.bind(this);
    this.toogleConsulta = this.toogleConsulta.bind(this);
    this.toogleCuentaAuxiliar = this.toogleCuentaAuxiliar.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  toogleNuevo = () => {
    this.setState((prevState) => ({ toogle_nuevo: !prevState.toogle_nuevo }));
  };
  toogleConsulta = () => {
    this.setState((prevState) => ({
      toogle_consulta: !prevState.toogle_consulta,
    }));
  };
  toogleCuentaAuxiliar = () => {
    this.setState((prevState) => ({
      toogle_cuentauxiliar: !prevState.toogle_cuentauxiliar,
    }));
  };
  desgloseCuenta = () => {
    this.props.desglose_cuenta(1);
  };
  onBack = () => {
    this.props.update(1);
    this.props.on_back(false);
  };
  render() {
    return (
      <div className="col-lg-3 col-md-4 col-sm-12 d-flex justify-content-center flex-column mb-2 my-md-2">
        <ButtonGroup className="mb-1">
          <Button
            color="primary"
            onClick={this.toogleNuevo}
            disabled={permisos.obtener_permiso_especifico(
              "crear",
              this.props.permisos
            )}
          >
            Nuevo
          </Button>
          {/* {
                        this.props.onback ?
                            (<Button color="danger" onClick={this.onBack}>
                                Regresar
                            </Button>) :
                            (<Button color="primary" onClick={this.desgloseCuenta}>
                                Desglose cuenta
                            </Button>)
                    } */}
          <Button color="success" onClick={this.toogleConsulta}>
            Consulta
          </Button>
        </ButtonGroup>
        <ModalAlta
          toogle_nuevo={this.state.toogle_nuevo}
          toogleNuevo={this.toogleNuevo}
          codigos_sat={this.props.codigos_sat}
          update={this.props.update}
          permisos={this.props.permisos}
        />
        <ModalConsulta
          toogle_consulta={this.state.toogle_consulta}
          toogleConsulta={this.toogleConsulta}
          codigos_sat={this.props.codigos_sat}
          update={this.props.update}
          permisos={this.props.permisos}
        />
      </div>
    );
  }
}
export default ModalAction;
