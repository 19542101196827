import { faEye, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import swal from "@sweetalert/with-react";
import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";
import MyInput from "../utils/MyInput";
import ModalAuxiliar from "./ModalAuxiliar";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalModifiPolizas extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    console.warn(this.props);
    console.warn(props);
    this.state = {
      modificarCliente: this.props.polizaCliente,
      modificarRef: this.props.polizaRef,
      modificarAux: this.props.polizaAux,
      modificarDoc: this.props.polizaDoc,
      modificarTipoMov: this.props.polizaTipoMov,
      modificarCargo: this.props.polizaCargo,
      modificarAbono: this.props.polizaAbono,

      consecutivo: this.props.consecutivo,
      modificarId: this.props.polizaId,

      toogle_auxiliar: false,
      auxiliares: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toogleAuxiliar = () => {
    this.setState((prevState) => ({
      toogle_auxiliar: !prevState.toogle_auxiliar,
    }));
    const n = null;
    if (n === null) {
      this.getAuxiliaresMayores();
    } else {
      this.postAuxiliarSegundoNivel();
    }
  };

  getAuxiliaresMayores() {
    const url = utility.url_auxiliares;
    axios
      .get(url)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              auxiliares: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  postAuxiliarSegundoNivel() {
    axios
      .post(utility.url_auxiliares, {
        auxiliar: this.state.auxiliar,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              auxiliares: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  handleCerrar = () => {
    this.props.toogleConsulta();
    
  };

  handleAuxiliar = (e) => {
    let modificarAux = e.target.value;
    this.setState({ modificarAux });
  };

  handleCallback = (childData) => {
    this.setState({ modificarAux: childData });
  };

  handleReferencia = (e) => {
    let modificarRef = e.target.value;
    this.setState({ modificarRef });
  };

  handleDocumento = (e) => {
    let modificarDoc = e.target.value;
    this.setState({ modificarDoc });
  };

  handleTipoMov = (e) => {
    let modificarTipoMov = e.target.value;
    this.setState({ modificarTipoMov });
  };

  handleCargo = (e) => {
    let modificarCargo = e.target.value;
    this.setState({ modificarCargo });
  };

  handleAbono = (e) => {
    let modificarAbono = e.target.value;
    this.setState({ modificarAbono });
  };

  handleCliente = (e) => {
    let modificarCliente = e.target.value;
    this.setState({ modificarCliente });
  };

  handleGuardar = () => {

    if (
      this.state.modificarCliente === this.props.polizaCliente &&
      this.state.modificarRef === this.props.polizaRef &&
      this.state.modificarAux === this.props.polizaAux &&
      this.state.modificarDoc === this.props.polizaDoc &&
      this.state.modificarTipoMov === this.props.polizaTipoMov &&
      this.state.modificarCargo === this.props.polizaCargo &&
      this.state.modificarAbono === this.props.polizaAbono 
    ) {
      message.warningMessage("No existió alguna modificación");
    } else {
    message
      .message_confirmacion_password(
        "¿Desea guardar las modificaciones?",
        "Ingrese su contraseña y observaciones"
      )
      .then((res) => {
        if (res === true) {
          let mov = {
            user: document.getElementById("usuario_modificador").value,
            pass: document.getElementById("contraseña_modificador").value,
            motivo: document.getElementById("observaciones_textarea").value,
          };
          if (mov.motivo.length === 0)
            swal("Completar el campo:", "Motivo de Notificación", "warning");
          else
            axios
              .post(utility.url_verify, {
                usuario: mov.user,
                password: mov.pass,
              })
              .then((response) => {
                if (response.status >= 200 && response.status < 400) {
                  if (this._isMounted) {
                    //Comienza el post para modificar movimientos de polizas
                    const arrayModificados = [];
                    const modificados = {id:this.state.modificarId}

                    if(this.state.modificarRef != this.props.polizaRef)
                    modificados.referencia = this.state.modificarRef

                    if(this.state.modificarAux != this.props.polizaAux)
                    modificados.auxiliar = this.state.modificarAux

                    if(this.state.modificarDoc != this.props.polizaDoc)
                    modificados.documento = this.state.modificarDoc

                    if(this.state.modificarTipoMov != this.props.polizaTipoMov)
                    modificados.tipo_mov = this.state.modificarTipoMov

                    if(this.state.modificarCargo != this.props.polizaCargo)
                    modificados.cargo = this.state.modificarCargo

                    if(this.state.modificarAbono!= this.props.polizaAbono)
                    modificados.abono = this.state.modificarAbono

                    if(this.state.modificarCliente != this.props.polizaCliente)
                    modificados.cliente = this.state.modificarCliente

                    arrayModificados.push(modificados);
                      
                      axios
                        .post(utility.url_modificar_poliza, {
                          consecutivo: this.state.consecutivo,
                          tipo: this.props.tipo,
                          movimientos: arrayModificados,
                          observaciones: mov.motivo
                        })
                        .then((response) => {
                          if (response.status >= 200 && response.status < 400) {
                            if (this._isMounted) {
                              swal({
                                icon: "success",
                                title: response.data.title,
                                text: response.data.text,
                                button: true,
                              });
                            }
                            this.props.update(1);
                          }
                        })
                        .catch((error) => {
                          if (error.response)
                            message.message_modal(
                              error.response.data.title,
                              error.response.data.text,
                              error.response.status
                            );
                        });
                    
                  }
                }
              })
              .catch((error) => {
                if (error.response)
                  message.message_modal(
                    error.response.data.title,
                    error.response.data.text,
                    error.response.status
                  );
              });
        }
      });
    }
  };

  render() {
 
    const referencia = this.state.modificarRef;
    const auxiliar = this.state.modificarAux;
    const documento = this.state.modificarDoc;
    const tipoMov = this.state.modificarTipoMov;
    const cargo = this.state.modificarCargo;
    const abono = this.state.modificarAbono;
    const cliente = this.state.modificarCliente;

    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Modificación Movimientos Poliza
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="movimiento_poliza"
                nameLabel="Número Cliente"
                value={cliente}
                onChange={this.handleCliente}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="referencia_poliza"
                nameLabel="Referencia"
                value={referencia}
                onChange={this.handleReferencia}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="documento_poliza"
                nameLabel="Documento"
                value={documento}
                onChange={this.handleDocumento}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-10 col-12"
                id="auxiliar_poliza"
                nameLabel="Auxiliar"
                value={auxiliar}
                onChange={this.handleAuxiliar}
              />
              <Button
                color="primary"
                className="col-lg-2 col-12 mb-2"
                onClick={this.toogleAuxiliar}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="tipo_mov_poliza"
                nameLabel="Tipo de Movimiento"
                value={tipoMov}
                onChange={this.handleTipoMov}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="cargo_poliza"
                nameLabel="Cargo"
                value={cargo}
                onChange={this.handleCargo}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="abono_poliza"
                nameLabel="Abono"
                value={abono}
                onChange={this.handleAbono}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="success"
            className="mr-2"
            onClick={this.handleGuardar}
          >
            Guardar
          </Button>
          <Button type="button" color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
        <ModalAuxiliar
          toogle_catalogo={this.state.toogle_auxiliar}
          toogleCatalogo={this.toogleAuxiliar}
          listaAuxiliares={this.state.auxiliares}
          parentCallback={this.handleCallback}
        />
      </Modal>
    );
  }
}
export default ModalModifiPolizas;
