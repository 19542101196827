import { faEye, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import swal from "@sweetalert/with-react";
import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";
import MyInput from "../utils/MyInput";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalModificarCat extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      modificarId: null,
      modificarNombre: null,
      modificarCorto: null,
      modificarClave: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCerrar = () => {
    this.props.toogleCatalogo();
    this.setState({
      modificarId: null,
      modificarNombre: null,
      modificarCorto: null,
      modificarClave: null,
    });
  };

  handleConcepto = (e) => {
    let modificarNombre = e.target.value;
    this.setState({ modificarNombre });
  };

  handleCorto = (e) => {
    let modificarCorto = e.target.value;
    this.setState({ modificarCorto });
  };

  handleClave = (e) => {
    let modificarClave = e.target.value;
    this.setState({ modificarClave });
  };

  handleGuardar = () => {
    message
      .message_confirmacion_password(
        "¿Desea modificar el movimiento?",
        "Ingrese su contraseña y observaciones"
      )
      .then((res) => {
        if (res === true) {
          let mov = {
            user: document.getElementById("usuario_modificador").value,
            pass: document.getElementById("contraseña_modificador").value,
            motivo: document.getElementById("observaciones_textarea").value,
          };
          if (mov.motivo.length === 0)
            swal("Completar el campo:", "Motivo de Notificación", "warning");

          let claveCatalogo = null;
          if (this.props.tipoPoliza === "Ingresos") claveCatalogo = "1";
          else if (this.props.tipoPoliza === "Egresos") claveCatalogo = "2";
          else if (this.props.tipoPoliza === "Diario") claveCatalogo = "3";

          if (this.props.subId !== null){
            if (
              this.state.modificarCorto === null &&
              this.state.modificarNombre === null &&
              this.state.modificarClave === null
            ) {
              message.warningMessage("No hubo modificación");
            }
            else{
  
              if (
                this.state.modificarClave === null &&
                this.state.modificarCorto === null 
              ) {
                const registros = [
                  { id: this.props.subId, nombre: this.state.modificarNombre },
                ];
    
                axios
                  .post(utility.url_crea_modifica, {
                    claveCatalogo,
                    registros,
                  })
                  .then((response) => {
                    if (response.status >= 200 && response.status < 400) {
                      if (this._isMounted) {
                        swal({
                          icon: "success",
                          title: response.data.title,
                          text: response.data.text,
                          button: true,
                        });
                        this.setState({
                          modificarId: null,
                          modificarNombre: null,
                          modificarCorto: null,
                          modificarClave: null,
                        });
                      }
                    }
                  })
                  .catch((error) => {
                    if (error.response)
                      message.message_modal(
                        error.response.data.title,
                        error.response.data.text,
                        error.response.status
                      );
                  });
              }
    
              else if(
                this.state.modificarClave === null &&
                this.state.modificarNombre === null
              ) {
                const registros = [
                  { id: this.props.subId, corto: this.state.modificarCorto },
                ];
    
                axios
                  .post(utility.url_crea_modifica, {
                    claveCatalogo,
                    registros,
                  })
                  .then((response) => {
                    if (response.status >= 200 && response.status < 400) {
                      if (this._isMounted) {
                        swal({
                          icon: "success",
                          title: response.data.title,
                          text: response.data.text,
                          button: true,
                        });
                        this.setState({
                          modificarId: null,
                          modificarNombre: null,
                          modificarCorto: null,
                          modificarClave: null,
                        });
                      }
                    }
                  })
                  .catch((error) => {
                    if (error.response)
                      message.message_modal(
                        error.response.data.title,
                        error.response.data.text,
                        error.response.status
                      );
                  });
              }
    
              else if (
                this.state.modificarCorto === null &&
                this.state.modificarNombre === null
              ) {
                const registros = [
                  { id: this.props.subId, clave: this.state.modificarClave },
                ];
    
                axios
                  .post(utility.url_crea_modifica, {
                    claveCatalogo,
                    registros,
                  })
                  .then((response) => {
                    if (response.status >= 200 && response.status < 400) {
                      if (this._isMounted) {
                        swal({
                          icon: "success",
                          title: response.data.title,
                          text: response.data.text,
                          button: true,
                        });
                        this.setState({
                          modificarId: null,
                          modificarNombre: null,
                          modificarCorto: null,
                          modificarClave: null,
                        });
                      }
                    }
                  })
                  .catch((error) => {
                    if (error.response)
                      message.message_modal(
                        error.response.data.title,
                        error.response.data.text,
                        error.response.status
                      );
                  });
              }
    
              else if (
                this.state.modificarCorto !== null &&
                this.state.modificarNombre !== null &&
                this.state.modificarClave !== null
              ) {
                const registros = [
                  {
                    id: this.props.subId,
                    nombre: this.state.modificarNombre,
                    corto: this.state.modificarCorto,
                    clave: this.state.modificarClave,
                  },
                ];
    
                axios
                  .post(utility.url_crea_modifica, {
                    claveCatalogo,
                    registros,
                  })
                  .then((response) => {
                    if (response.status >= 200 && response.status < 400) {
                      if (this._isMounted) {
                        swal({
                          icon: "success",
                          title: response.data.title,
                          text: response.data.text,
                          button: true,
                        });
                        this.setState({
                          modificarId: null,
                          modificarNombre: null,
                          modificarCorto: null,
                          modificarClave: null,
                        });
                      }
                    }
                  })
                  .catch((error) => {
                    if (error.response)
                      message.message_modal(
                        error.response.data.title,
                        error.response.data.text,
                        error.response.status
                      );
                  });
              }
    
              else if(
                this.state.modificarCorto !== null &&
                this.state.modificarNombre !== null
              ) {
                const registros = [
                  {
                    id: this.props.subId,
                    nombre: this.state.modificarNombre,
                    corto: this.state.modificarCorto,
                  },
                ];
    
                axios
                  .post(utility.url_crea_modifica, {
                    claveCatalogo,
                    registros,
                  })
                  .then((response) => {
                    if (response.status >= 200 && response.status < 400) {
                      if (this._isMounted) {
                        swal({
                          icon: "success",
                          title: response.data.title,
                          text: response.data.text,
                          button: true,
                        });
                        this.setState({
                          modificarId: null,
                          modificarNombre: null,
                          modificarCorto: null,
                          modificarClave: null,
                        });
                      }
                    }
                  })
                  .catch((error) => {
                    if (error.response)
                      message.message_modal(
                        error.response.data.title,
                        error.response.data.text,
                        error.response.status
                      );
                  });
              }
    
             
    
              else if(
                this.state.modificarClave !== null &&
                this.state.modificarCorto !== null
              ) {
                const registros = [
                  {
                    id: this.props.subId,
                    corto: this.state.modificarCorto,
                    clave: this.state.modificarClave,
                  },
                ];
    
                axios
                  .post(utility.url_crea_modifica, {
                    claveCatalogo,
                    registros,
                  })
                  .then((response) => {
                    if (response.status >= 200 && response.status < 400) {
                      if (this._isMounted) {
                        swal({
                          icon: "success",
                          title: response.data.title,
                          text: response.data.text,
                          button: true,
                        });
                        this.setState({
                          modificarId: null,
                          modificarNombre: null,
                          modificarCorto: null,
                          modificarClave: null,
                        });
                      }
                    }
                  })
                  .catch((error) => {
                    if (error.response)
                      message.message_modal(
                        error.response.data.title,
                        error.response.data.text,
                        error.response.status
                      );
                  });
              }
  
              else if (
                this.state.modificarClave !== null &&
                this.state.modificarNombre !== null
              ) {
                const registros = [
                  {
                    id: this.props.subId,
                    corto: this.state.modificarNombre,
                    clave: this.state.modificarClave,
                  },
                ];
    
                axios
                  .post(utility.url_crea_modifica, {
                    claveCatalogo,
                    registros,
                  })
                  .then((response) => {
                    if (response.status >= 200 && response.status < 400) {
                      if (this._isMounted) {
                        swal({
                          icon: "success",
                          title: response.data.title,
                          text: response.data.text,
                          button: true,
                        });
                        this.setState({
                          modificarId: null,
                          modificarNombre: null,
                          modificarCorto: null,
                          modificarClave: null,
                        });
                      }
                    }
                  })
                  .catch((error) => {
                    if (error.response)
                      message.message_modal(
                        error.response.data.title,
                        error.response.data.text,
                        error.response.status
                      );
                  });
              }
              
            }
          }
        }
      });
  };

  render() {
    const nombre =
      this.state.modificarNombre !== null
        ? this.state.modificarNombre
        : this.props.subNombre;
    const corto =
      this.state.modificarCorto !== null
        ? this.state.modificarCorto
        : this.props.subCorto;
    const clave =
      this.state.modificarClave !== null
        ? this.state.modificarClave
        : this.props.subClave;
    return (
      <Modal
        isOpen={this.props.toogle_catalogo}
        toggle={this.handleCerrar}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Modificación Subtipos
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-12 col-12"
                id="concepto_subtipo"
                nameLabel="Concepto"
                value={nombre}
                onChange={this.handleConcepto}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="corto_subtipo"
                nameLabel="Concepto Abreviado"
                value={corto}
                onChange={this.handleCorto}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="clave_subtipo"
                nameLabel="Clave"
                value={clave}
                onChange={this.handleClave}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="success"
            className="mr-2"
            onClick={this.handleGuardar}
          >
            Guardar
          </Button>
          <Button type="button" color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalModificarCat;
