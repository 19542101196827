import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";

class ModalAuxiliar extends Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      auxiliar: "",
      auxiliarSegundoNivel: "",
      idAux: "",
      listaAux: null,
    };
  
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleAuxiliar = (aux, id) => {
    this.setState({
      auxiliarSegundoNivel: aux,
      idAux: id
    });
  };

  handleCerrar = () => {
    this.props.toogleCatalogo();
    this.handleLimpiar();
  };

  handleLimpiar = () => {
    this.setState({
      auxiliares: [],
    });
  };

  handleSeleccionar = () => {
    const auxDos = this.state.auxiliarSegundoNivel;
    const idAux = this.state.idAux;
    this.props.parentCallback(auxDos,idAux);
    this.handleCerrar();
  };

  handleVerMas = (aux) => {
    const segundoNivel = aux.slice(7, 8);
    
    if (segundoNivel == 0) {
      const auxiliar = aux.slice(0, 5);
      axios
        .post(utility.url_auxiliares, {
          auxiliar: auxiliar,
          nivel:"2"
        })
        .then((response) => {
          if (response.status >= 200 && response.status < 400) {
            if (this._isMounted)
              this.setState({
                listaAux: response.data.results,
              });
          }
        })
        .catch((error) => {
          if (error.response)
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
        });
    } else {
      axios
        .post(utility.url_auxiliares, {
          auxiliar: aux.slice(0, 9),
          nivel:"3"
        })
        .then((response) => {
          if (response.status >= 200 && response.status < 400) {
            if (this._isMounted)
              this.setState({
                listaAux: response.data.results,
              });
          }
        })
        .catch((error) => {
          if (error.response)
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
        });
    }
  };

  handleRegresar = () => {
    const url = utility.url_auxiliares;
    axios
      .get(url)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              listaAux: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  render() {
    const mostrarLista = this.state.listaAux != null ? this.state.listaAux: this.props.listaAuxiliares
    return (
      <Modal
        isOpen={this.props.toogle_catalogo}
        toggle={this.handleCerrar}
        backdrop="static"
        size="lg"
        
      >
        <ModalHeader toggle={this.handleCerrar} >
          Lista De Auxiliares
        </ModalHeader>
        <ModalBody>
          <div className="overflow-auto tm-table">
            <Table size="sm" bordered hover responsive>
              <thead className="text-center header-table">
                <tr>
                  <td></td>
                  <td>Auxiliar</td>
                  <td>Descripción</td>
                  <td>Código</td>
                  <td></td>
                </tr>
              </thead>
              <tbody className="body-table hover-gray">
                {mostrarLista?.map((aux) => (
                  <tr key={aux.auxiliar}>
                    <td>
                      <div className="d-flex justify-content-center my-auto">
                        <input
                          type="checkbox"
                          id={`listAux${aux.auxiliar}`}
                          checked={aux.auxiliar===this.state.auxiliarSegundoNivel}
                          onClick={() => this.handleAuxiliar(aux.auxiliar, aux.id)}
                        />
                        <label
                          htmlFor={`listAux${aux.auxiliar}`}
                          className="check-circle"
                          style={{ width: "30px" }}
                        ></label>
                      </div>
                    </td>
                    <td>
                      <p className="mb-0 text-center">{aux.auxiliar}</p>
                    </td>
                    <td>
                      <p className="mb-0 text-center">{aux.descripcion}</p>
                    </td>
                    <td>
                      <p className="mb-0 text-center">{aux.codigo}</p>
                    </td>
                    <td className="text-center">
                      <Button
                        color="info"
                        size="sm"
                        onClick={() => this.handleVerMas(aux.auxiliar)}
                        disabled={aux.tpo_cuenta === "D" ? true : false}
                      >
                        Ver más
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="success"
            className="mr-2"
            onClick={this.handleSeleccionar}
            disabled={mostrarLista?.length === 0 ? true : false}
          >
            Seleccionar
          </Button>
          <Button
            type="button"
            color="info"
            className="mr-2"
            onClick={this.handleRegresar}
            disabled={mostrarLista?.length === 0 ? true : false}
          >
            Regresar
          </Button>
          <Button type="button" color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalAuxiliar;
