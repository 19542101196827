/* 
Componente que permite generar una aplicación y sus permisos para agregarlos a un usuario en su momento de creación
*/
import React, { Component } from 'react';
import { FormText } from 'reactstrap';
import '../../../assets/style.css';

class PermisosApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ver: false,
            crear: false,
            modificar: false,
            cancelar: false,
        };
    }
    componentDidMount() {
        if (this.props.permisos_usuario)
            this.setPermisosDOM();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.grupo !== this.props.grupo) {
            if (this.props.grupo === false)
                this.quitPermisosDOM();
            else
                this.setPermisosDOM();
        }
    }
    getPermisos = () => {
        let permisos = [];
        permisos.push(this.setPermisionUsuario('visualizar'));
        permisos.push(this.setPermisionUsuario('crear'));
        permisos.push(this.setPermisionUsuario('modificar'));
        permisos.push(this.setPermisionUsuario('cancelar'));
        permisos = permisos.filter(permiso => permiso !== undefined);
        return permisos;
    }
    setPermisosDOM = () => {
        let permisos = this.getPermisos();
        let name_app = this.props.name_app.toLowerCase().replaceAll(' ', '_');
        permisos.forEach(permiso => this.props.agregaPermisos(permiso));
        permisos.forEach(permiso => {
            if (document.getElementById(`${name_app}_ver`).value === permiso)
                this.setState({ ver: true });
            if (document.getElementById(`${name_app}_crear`).value === permiso)
                this.setState({ crear: true });
            if (document.getElementById(`${name_app}_modificar`).value === permiso)
                this.setState({ modificar: true });
            if (document.getElementById(`${name_app}_cancelar`).value === permiso)
                this.setState({ cancelar: true });
        })
    }
    quitPermisosDOM = () => {
        let permisos = this.getPermisos();
        let name_app = this.props.name_app.toLowerCase().replaceAll(' ', '_');
        console.log(permisos)
        permisos.forEach(permiso => { this.props.eliminaPermisos(permiso) });
        permisos.forEach(permiso => {
            if (document.getElementById(`${name_app}_ver`).value === permiso)
                this.setState({ ver: false });
            if (document.getElementById(`${name_app}_crear`).value === permiso)
                this.setState({ crear: false });
            if (document.getElementById(`${name_app}_modificar`).value === permiso)
                this.setState({ modificar: false });
            if (document.getElementById(`${name_app}_cancelar`).value === permiso)
                this.setState({ cancelar: false });
        })
    }
    /**
     * Función que agrega el permiso Visualizar
     * @param {Event} e Evento de click en checkbox 
     */
    changeVer = (e) => {
        let param = e.target.value;
        this.setState((prevProps) => ({ ver: !prevProps.ver }), (permiso = param) => {
            if (this.state.ver)
                this.props.agregaPermisos(permiso);
            else
                this.props.eliminaPermisos(permiso);
        });
    }
    /**
     * Función que agrega el permiso Crear
     * @param {Event} e Evento de click en checkbox 
     */
    changeCrear = (e) => {
        let param = e.target.value;
        this.setState((prevProps) => ({ crear: !prevProps.crear }), (permiso = param) => {
            if (this.state.crear)
                this.props.agregaPermisos(permiso);
            else
                this.props.eliminaPermisos(permiso);
        });
    }
    /**
     * Función que agrega el permiso Modificar
     * @param {Event} e Evento de click en checkbox 
     */
    changeModificar = (e) => {
        let param = e.target.value;
        this.setState((prevProps) => ({ modificar: !prevProps.modificar }), (permiso = param) => {
            if (this.state.modificar)
                this.props.agregaPermisos(permiso);
            else
                this.props.eliminaPermisos(permiso);
        });
    }
    /**
     * Función que agrega el permiso Cancelar
     * @param {Event} e Evento de click en checkbox 
     */
    changeCancelar = (e) => {
        let param = e.target.value;
        this.setState((prevProps) => ({ cancelar: !prevProps.cancelar }), (permiso = param) => {
            if (this.state.cancelar)
                this.props.agregaPermisos(permiso);
            else
                this.props.eliminaPermisos(permiso);
        });
    }
    /**
     * Función que permite agregar el número de permiso a el checkbox corrrespondiente.
     * @param {String} type_permiso El tipo de permiso como visualizar, crear, modificar o cancelar
     * @returns {String} Número del permiso
     */
    setPermision = (type_permiso) => {
        for (let i = 0; i < this.props.permisos.length; i++) {
            if (type_permiso === "visualizar" && this.props.permisos[i].includes("visualizar"))
                return this.props.permisos[i].split(':')[1];
            if (type_permiso === "crear" && this.props.permisos[i].includes("crear"))
                return this.props.permisos[i].split(':')[1];
            if (type_permiso === "modificar" && this.props.permisos[i].includes("modificar"))
                return this.props.permisos[i].split(':')[1];
            if (type_permiso === "cancelar" && this.props.permisos[i].includes("cancelar"))
                return this.props.permisos[i].split(':')[1];
        }
    }
    /**
     * Función que permite agregar el número de permiso a el checkbox corrrespondiente.
     * @param {String} type_permiso El tipo de permiso como visualizar, crear, modificar o cancelar
     * @returns {String} Número del permiso
     */
    setPermisionUsuario = (type_permiso) => {
        for (let i = 0; i < this.props.permisos_usuario.length; i++) {
            if (type_permiso === "visualizar" && this.props.permisos_usuario[i].includes("visualizar"))
                return this.props.permisos_usuario[i].split(':')[1];
            if (type_permiso === "crear" && this.props.permisos_usuario[i].includes("crear"))
                return this.props.permisos_usuario[i].split(':')[1];
            if (type_permiso === "modificar" && this.props.permisos_usuario[i].includes("modificar"))
                return this.props.permisos_usuario[i].split(':')[1];
            if (type_permiso === "cancelar" && this.props.permisos_usuario[i].includes("cancelar"))
                return this.props.permisos_usuario[i].split(':')[1];
        }
    }
    render() {
        return (
            <div className="row d-flex align-items-center mb-1">
                <div className="col-12 col-lg-4">
                    <h4 className="text-center mb-0">{this.props.name_app}</h4>
                </div>
                <div className="col-6 col-lg-2">
                    <div className="d-flex justify-content-center">
                        <input type="checkbox" id={`${this.props.name_app.toLowerCase().replaceAll(' ', '_')}_ver`} checked={this.state.ver}
                            onChange={this.changeVer} value={this.setPermision("visualizar")} />
                        <label htmlFor={`${this.props.name_app.toLowerCase().replaceAll(' ', '_')}_ver`} className="check-circle" style={{ width: '25px' }}></label>
                    </div>
                    <FormText className="text-center mt-3">VER</FormText>
                </div>
                <div className="col-6 col-lg-2">
                    <div className="d-flex justify-content-center">
                        <input type="checkbox" id={`${this.props.name_app.toLowerCase().replaceAll(' ', '_')}_crear`} checked={this.state.crear}
                            onChange={this.changeCrear} value={this.setPermision("crear")} />
                        <label htmlFor={`${this.props.name_app.toLowerCase().replaceAll(' ', '_')}_crear`} className="check-circle" style={{ width: '25px' }}></label>
                    </div>
                    <FormText className="text-center mt-3">CREAR</FormText>
                </div>
                <div className="col-6 col-lg-2">
                    <div className="d-flex justify-content-center">
                        <input type="checkbox" id={`${this.props.name_app.toLowerCase().replaceAll(' ', '_')}_modificar`} checked={this.state.modificar}
                            onChange={this.changeModificar} value={this.setPermision("modificar")}
                            disabled={this.setPermision("modificar") === undefined ? (true) : (false)} />
                        <label htmlFor={`${this.props.name_app.toLowerCase().replaceAll(' ', '_')}_modificar`}
                            className={this.setPermision("modificar") === undefined ? ("check-circle-disabled") : ("check-circle")}
                            style={{ width: '25px' }}></label>
                    </div>
                    <FormText className="text-center mt-3">MODIFICAR</FormText>
                </div>
                <div className="col-6 col-lg-2">
                    <div className="d-flex justify-content-center">
                        <input type="checkbox" id={`${this.props.name_app.toLowerCase().replaceAll(' ', '_')}_cancelar`} checked={this.state.cancelar}
                            onChange={this.changeCancelar} value={this.setPermision("cancelar")}
                            disabled={this.setPermision("cancelar") === undefined ? (true) : (false)} />
                        <label htmlFor={`${this.props.name_app.toLowerCase().replaceAll(' ', '_')}_cancelar`}
                            className={this.setPermision("cancelar") === undefined ? ("check-circle-disabled") : ("check-circle")}
                            style={{ width: '25px' }}></label>
                    </div>
                    <FormText className="text-center mt-3">CANCELAR</FormText>
                </div>
            </div>
        );
    }
}
export default PermisosApp;
