/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la consulta de un proveedor. 
*/
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ListGroup,
  ListGroupItem,
  Collapse,
  Card,
  CardBody,
  Badge,
  FormText,
  Table,
  CustomInput,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import MyInput from "../../../utils/custom/MyInput";
import CustomSelect from "../../../utils/custom/CustomSelect";
import WebSocketInstance from "../../../utils/websocket";
import * as message from "../../../utils/messages/message";
import * as utility from "../utils/utility";
import {
  faPlus,
  faEye,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import * as permisos from "../../../utils/permisos/permisos";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalConsulta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cuenta_gastos: "",
      referencias_actuales: [],
      referecias_eliminar: [],
      ingresos_eliminar: [],
      pagos_elimina_phxcdc: [],
      pagos_elimina_ingresos: [],
      //PARTE DE PHXCDC E INGRESOS
      referencia_phxcdc: "",
      refrencia_ingresos: "Seleccione una referencia",
      text_ingresos: "Seleccione un tipo de ingreso",
      importe_ingreso: "",
      id_ingresos: "",
      numero_select: "",
      nombre_select: "",
      valor_aduanal: "",
      porcentaje: "",
      retencion: "",
      nombre_suggestions: [],
      polizas_referencia: [],
      referencias_select: [],
      referencias_ingresos: [],

      toogle_pago: false,
      toogle_ingresos: true,
      enable_pagos: false,
      enable_pdf: true,
      //PARTE DE PAGOS
      numero_cuenta: "",
      id_referencia_select_pago: null,
      referencias_cuenta: [],
      documentos_grupo_anticipo: [],
      documentos_grupo_terceros: [],
      documentos_uso: [],
      pagos: [],
      movimientos_select: [],
      agregados: [],
      saldo_documento: "",
      importe_documento: "",
      tipos_movimiento: [
        { id: "Ig", text: "Ingreso" },
        { id: "Pt", text: "Pago de terceros" },
      ],
      tipos_pago: [
        { id: 1, text: "Anticipo" },
        { id: 2, text: "Notas de Credito" },
        { id: 3, text: "Pagos a terceros" },
      ],
      tipo_movimiento: "Seleccione un movimiento",
      tipo_pago: "Seleccione un tipo de pago",
      referencia_select_pago: "Seleccione una referencia",
      documento: "Seleccione un documento",
      id_documento: "",
      id_tipo_movimiento: "",
      id_tipo_pago: "",

      tap_phxcdc: "1",
      tap_ingresos: "1",

      disabled: [true, true, true, true, true, true, true, true],
      data_modificacion: {},
    };
    this.toogleIngresos = this.toogleIngresos.bind(this);
    this.tooglePago = this.tooglePago.bind(this);
    this.toogleTap_phxcdc = this.toogleTap_phxcdc.bind(this);
    this.toogleTap_ingresos = this.toogleTap_ingresos.bind(this);
  }
  toogleIngresos = () => {
    this.setState((prevState) => ({
      toogle_ingresos: !prevState.toogle_ingresos,
      toogle_pago: false,
    }));
  };
  tooglePago = () => {
    this.setState((prevState) => ({
      toogle_pago: !prevState.toogle_pago,
      toogle_ingresos: false,
    }));
  };
  toogleTap_phxcdc = (value) => {
    this.setState({ tap_phxcdc: value });
  };
  toogleTap_ingresos = (value) => {
    this.setState({ tap_ingresos: value });
  };
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  /* sendNotificacionHandler = (motivo) => {
        let folio = this.state.numero_proveedor;
        const notificacionObject = {
            tipo: 0,
            aplicacion: 'proveedor',
            motivo: motivo,
            folio: [folio]
        }
        WebSocketInstance.newNotificacion(notificacionObject);
    } */
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  /* --------------- Funciones que actualizan las variables de los inputs --------------- */
  handleCuenta_gastos = (e) => {
    let cuenta_gastos = e.target.value;
    this.setState({ cuenta_gastos });
  };
  handleReferencia_phxcdc = (e) => {
    let referencia_phxcdc = e.target.value;
    this.setState({ referencia_phxcdc });
  };
  handleReferencia_ingresos = (e) => {
    let label = e.name_text;
    let refrencia_ingresos = e[label];
    this.methodPOST_API(utility.url_valor_aduanal, "valor_aduana", {
      referencia: refrencia_ingresos,
    });
    this.setState({ refrencia_ingresos });
  };
  handleValor_aduanal = (e) => {
    let is_checked = e.target.checked;
    let referencias_ingresos = this.state.referencias_ingresos;
    let referencia_select = this.state.refrencia_ingresos;
    let valor_aduanal = this.state.valor_aduanal;
    let porcentaje = this.state.porcentaje;
    if (is_checked) {
      for (let i = 0; i < referencias_ingresos.length; i++)
        if (referencias_ingresos[i].tipo_ingreso === 1) {
          message.message_modal("Advertencia", "Ya existe el movimiento", 400);
          e.target.checked = false;
          return null;
        }
      if (referencia_select.length > 9) {
        message.message_modal(
          "Advertencia",
          "Debe seleccionar una referencia valida",
          400
        );
        e.target.checked = false;
      } else {
        if (valor_aduanal.length === 0 || porcentaje.length === 0) {
          message.message_modal(
            "Advertencia",
            "Debe ingresar un porcentaje y tener un valor aduanal",
            400
          );
          e.target.checked = false;
        } else {
          referencias_ingresos.push({
            tipo_ingreso: 1,
            importe: (
              parseFloat(valor_aduanal) *
              (parseFloat(porcentaje) / 100)
            ).toFixed(2),
            observaciones: "",
            referencia: referencia_select,
          });
          this.setState(referencias_ingresos);
        }
      }
    } else {
      for (let i = 0; i < referencias_ingresos.length; i++)
        if (referencias_ingresos[i].tipo_ingreso === 1)
          referencias_ingresos.splice(i, 1);
      this.setState(referencias_ingresos);
    }
  };
  handleIngreso = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let text_ingresos = e[label];
    let id_ingresos = e[label_id];
    this.setState({ text_ingresos, id_ingresos });
  };
  handlePorcentaje = (e) => {
    let porcentaje = e.target.value;
    this.setState({ porcentaje });
  };
  handleRetencion = (e) => {
    let retencion = e.target.value;
    this.setState({ retencion });
  };
  handleImporte_ingreso = (e) => {
    let importe_ingreso = e.target.value;
    this.setState({ importe_ingreso });
  };
  handleReferencia_pagos = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let referencia_select_pago = e[label];
    let id_referencia_select_pago = e[label_id];
    this.setState({ referencia_select_pago, id_referencia_select_pago });
  };
  handleTipo_movimiento = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_movimiento = e[label];
    let id_tipo_movimiento = e[label_id];
    this.setState({ tipo_movimiento, id_tipo_movimiento });
  };
  handleTipo_pago = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_pago = e[label];
    let id_tipo_pago = e[label_id];
    this.setState({ tipo_pago, id_tipo_pago });
  };
  handleDocumento = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let id_documento = e[label_id];
    let documento = e[label];
    let documentos_grupo_anticipo = this.state.documentos_grupo_anticipo;
    let documentos_grupo_terceros = this.state.documentos_grupo_terceros;
    let saldo_documento = 0;
    let importe_documento = 0;
    if (this.state.id_tipo_pago === 1)
      documentos_grupo_anticipo.forEach((doc) => {
        if (id_documento === doc.id) saldo_documento = doc.saldo;
        importe_documento = doc.importe;
      });
    if (this.state.id_tipo_pago === 3)
      documentos_grupo_terceros.forEach((doc) => {
        if (id_documento === doc.id) saldo_documento = doc.saldo;
        importe_documento = doc.importe;
      });
    this.setState({
      documento,
      id_documento,
      saldo_documento,
      importe_documento,
    });
  };
  /* --------------- Fin de funciones que actualizan las variables de los inputs --------------- */
  handleConsulta = () => {
    if (this.state.cuenta_gastos.length === 0)
      message.message_modal(
        "Advertencia",
        "Debe tener una Cuenta de Gastos",
        400
      );
    else {
      this.methodPOST_API(utility.url_consulta_cta_gastos, "consulta", {
        cuenta: this.state.cuenta_gastos,
      });
    }
  };
  handleModificar_movimientos = () => {
    if (this.state.cuenta_gastos.length === 0)
      message.message_modal("Advertencia", "Debe tener un ID de ingreso", 400);
    else {
      message
        .message_confimacion("¿Desea continuar con la operación modificar?", "")
        .then((res) => {
          if (res) {
            let data_modificacion = this.state.data_modificacion;
            let ingresos_eliminar = this.state.ingresos_eliminar;
            let referencias = [];
            //PARTE QUE AGREGA REFERENCIAS A ELIMINAR
            this.state.referecias_eliminar.forEach((ref) =>
              referencias.push({ eliminar: ref })
            );
            //PARTE QUE AGREGA INGRESOS Y PHXCDC
            for (let i = 0; i < this.state.referencias_select.length; i++) {
              referencias.push({
                referencia: this.state.referencias_select[i].id,
                phxcdc: {
                  agregados: utility.contruye_alta_phxcdc(
                    this.state.referencias_select[i].id,
                    this.state.polizas_referencia
                  ),
                },
                ingresos: {
                  agregados: utility.construye_alta_ingresos(
                    this.state.referencias_select[i].id,
                    this.state.referencias_ingresos
                  ),
                },
              });
            }
            //PARTE QUE ELIMINA INGRESOS
            referencias.forEach((ref) => {
              ingresos_eliminar.forEach((ingreso) => {
                if (ref.referencia === ingreso.referencia) {
                  ref.ingresos["eliminados"] = ingreso.movimientos;
                  ingreso.contado = true;
                }
              });
            });
            ingresos_eliminar.forEach((ingreso) => {
              if (ingreso.contado === false)
                referencias.push({
                  referencia: ingreso.referencia,
                  ingresos: {
                    eliminados: ingreso.movimientos,
                  },
                });
            });
            //PARTE QUE AGREGA LOS CAMBIOS EXISTENTES
            data_modificacion["referencias"] = referencias;
            if (Object.values(data_modificacion).length > 0) {
              data_modificacion["cuenta"] = this.state.cuenta_gastos;
              this.methodPUT_API(
                utility.url_crea_cta_gastos,
                "modificar",
                data_modificacion
              );
              //this.sendNotificacionHandler('modificar');
            } else
              message.message_modal(
                "Advertencia",
                "No hay modificaciones",
                400
              );
          }
        });
    }
  };
  handleModificar_pagos = () => {
    if (this.state.cuenta_gastos.length === 0)
      message.message_modal("Advertencia", "Debe tener un ID de ingreso", 400);
    else {
      message
        .message_confimacion("¿Desea continuar con la operación modificar?", "")
        .then((res) => {
          if (res) {
            let data_modificacion = this.state.data_modificacion;
            //PARTE QUE ELIMINA PAGOS
            data_modificacion["eliminados"] = {
              phxcdc: this.state.pagos_elimina_phxcdc,
              ingresos: this.state.pagos_elimina_ingresos,
            };
            //PARTE QUE AGREGA LOS CAMBIOS EXISTENTES
            data_modificacion["agregados"] = this.state.agregados;
            utility.valida_data_modifica_pagos(data_modificacion);
            if (Object.values(data_modificacion).length > 0) {
              data_modificacion["cuenta"] = this.state.cuenta_gastos;
              this.methodPUT_API(
                utility.url_crea_cta_pagos,
                "modificar",
                data_modificacion
              );
              //this.sendNotificacionHandler('modificar');
            } else
              message.message_modal(
                "Advertencia",
                "No hay modificaciones",
                400
              );
          }
        });
    }
  };
  /* --------------- Funciones de eventos que generan una petición --------------- */
  handleCerrar = () => {
    this.setState({
      cuenta_gastos: "",
      referencias_actuales: [],
      referecias_eliminar: [],
      ingresos_eliminar: [],
      //PARTE DE PHXCDC E INGRESOS
      referencia_phxcdc: "",
      refrencia_ingresos: "Seleccione una referencia",
      text_ingresos: "Seleccione un tipo de ingreso",
      importe_ingreso: "",
      id_ingresos: "",
      numero_select: "",
      nombre_select: "",
      valor_aduanal: "",
      porcentaje: "",
      retencion: "",
      nombre_suggestions: [],
      polizas_referencia: [],
      referencias_select: [],
      referencias_ingresos: [],

      toogle_pago: false,
      toogle_ingresos: true,
      enable_pagos: false,
      //PARTE DE PAGOS
      numero_cuenta: "",
      id_referencia_select_pago: null,
      referencias_cuenta: [],
      documentos_grupo_anticipo: [],
      documentos_uso: [],
      pagos: [],
      movimientos_select: [],
      agregados: [],
      saldo_documento: "",
      importe_documento: "",
      tipos_movimiento: [
        { id: "Ig", text: "Ingreso" },
        { id: "Pt", text: "Pago de terceros" },
      ],
      tipos_pago: [
        { id: 1, text: "Anticipo" },
        { id: 2, text: "Notas de Credito" },
        { id: 3, text: "Pagos a terceros" },
      ],
      tipo_movimiento: "Seleccione un movimiento",
      tipo_pago: "Seleccione un tipo de pago",
      referencia_select_pago: "Seleccione una referencia",
      documento: "Seleccione un documento",
      id_documento: "",
      id_tipo_movimiento: "",
      id_tipo_pago: "",

      tap_phxcdc: "1",
      tap_ingresos: "1",

      disabled: [true, true, true, true, true, true, true, true],
      data_modificacion: {},
    });
    this.props.toogleConsulta();
  };
  handleAgregar_referencia_phxcdc = () => {
    if (
      utility.valida_referencia_repeticion(
        this.state.referencia_phxcdc,
        this.state.polizas_referencia
      )
    )
      this.methodPOST_API(utility.url_consulta_phxcdc_poliza, "phxcdc_poliza", {
        referencia: this.state.referencia_phxcdc,
      });
    else
      message.message_modal(
        "Advertencia",
        "Seleccionar una Referencia distinta.",
        400
      );
  };
  handleElimina_phxcdc = (e) => {
    let polizas_referencia = this.state.polizas_referencia;
    let referencia = e.target.value;
    for (let i = 0; i < polizas_referencia.length; i++)
      if (referencia === polizas_referencia[i].referencia) {
        polizas_referencia.splice(i, 1);
        i--;
      }
    this.setState({ polizas_referencia });
  };
  handleElimina_referencia = (e) => {
    let indice = e.target.id;
    let referencias_select = this.state.referencias_select;
    let polizas_referencia = this.state.polizas_referencia;
    let referecias_eliminar = this.state.referecias_eliminar;
    let referencias_actuales = this.state.referencias_actuales;
    let referencia = referencias_select[indice].id;
    for (let i = 0; i < referencias_actuales.length; i++) {
      if (referencia === referencias_actuales[i].referencia) {
        referencias_actuales.splice(i, 1);
        referecias_eliminar.push(referencia);
        i--;
      }
    }
    for (let i = 0; i < polizas_referencia.length; i++)
      if (referencia === polizas_referencia[i].referencia) {
        polizas_referencia.splice(i, 1);
        i--;
      }
    referencias_select.splice(indice, 1);
    this.setState({ referencias_select, polizas_referencia });
  };
  handleAgrega_ingreso = () => {
    let referencias_ingresos = this.state.referencias_ingresos;
    let referencia_select = this.state.refrencia_ingresos;
    for (let i = 0; i < referencias_ingresos.length; i++)
      if (referencias_ingresos[i].tipo_ingreso === this.state.id_ingresos) {
        message.message_modal("Advertencia", "Ya existe el movimiento", 400);
        return null;
      }
    if (referencia_select.length > 9)
      message.message_modal(
        "Advertencia",
        "Debe seleccionar una referencia valida",
        400
      );
    else {
      if (this.state.retencion.length === 0)
        referencias_ingresos.push({
          tipo_ingreso: this.state.id_ingresos,
          importe: this.state.importe_ingreso,
          observaciones: "",
          referencia: referencia_select,
        });
      else
        referencias_ingresos.push({
          tipo_ingreso: this.state.id_ingresos,
          importe: this.state.importe_ingreso,
          retencion: this.state.retencion,
          observaciones: "",
          referencia: referencia_select,
        });
      this.setState({
        referencias_ingresos,
        text_ingresos: "Seleccione un tipo de ingreso",
        id_ingresos: "",
        retencion: "",
        importe_ingreso: "",
      });
    }
  };
  handleElimina_ingreso = (e) => {
    let referencias_ingresos = this.state.referencias_ingresos;
    let indice = e.target.value;
    referencias_ingresos.splice(indice, 1);
    this.setState({ referencias_ingresos });
  };
  handleAgrega_pago = () => {
    let agregados = this.state.agregados;
    let movimientos_select = this.state.movimientos_select;
    let saldo_documento = this.state.saldo_documento;
    let referencias_cuenta = this.state.referencias_actuales;
    let documentos_grupo_anticipo = this.state.documentos_grupo_anticipo;
    let documentos_grupo_terceros = this.state.documentos_grupo_terceros;
    let documentos_uso = this.state.documentos_uso;
    if (movimientos_select.length === 0) {
      message.message_modal(
        "Advertencia",
        "Debe seleccionar algun movimiento",
        400
      );
      return null;
    }
    if (this.state.id_documento === "") {
      message.message_modal(
        "Advertencia",
        "Debe seleccionar un movimiento",
        400
      );
      return null;
    }
    for (let i = 0; i < movimientos_select.length; i++) {
      let pago = {
        importe: 0,
        id_documento: this.state.id_documento,
        tipo_pago: this.state.id_tipo_pago,
      };
      let diferencia =
        saldo_documento - parseFloat(movimientos_select[i].importe);
      if (diferencia === 0) {
        pago["importe"] = saldo_documento;
        saldo_documento = 0;
        movimientos_select[i].importe = 0;
      } else if (diferencia < 0) {
        pago["importe"] = saldo_documento;
        saldo_documento = 0;
        movimientos_select[i].importe = diferencia * -1;
      } else {
        pago["importe"] = parseFloat(movimientos_select[i].importe);
        movimientos_select[i].importe = 0;
        saldo_documento = diferencia;
      }
      if (movimientos_select[i].tipo_phxcdc) {
        utility.cambia_importe(
          referencias_cuenta[
            utility.posicion_referencia(
              referencias_cuenta,
              this.state.id_referencia_select_pago
            )
          ].cg_phxcdc_cuenta_referencia,
          movimientos_select[i].id,
          movimientos_select[i].importe
        );
        let info = utility.valida_movimiento_pago(
          agregados,
          movimientos_select[i].id,
          "Pt"
        );
        if (info.valido) agregados[info.position].pagos.push(pago);
        else
          agregados.push({
            id_movimiento: movimientos_select[i].id,
            id: movimientos_select[i].tipo_phxcdc,
            tipo_movimiento: "Pt",
            pagos: [pago],
          });
        if (diferencia <= 0) break;
      } else if (movimientos_select[i].tipo_ingreso) {
        utility.cambia_importe(
          referencias_cuenta[
            utility.posicion_referencia(
              referencias_cuenta,
              this.state.id_referencia_select_pago
            )
          ].cg_ingresos_cuenta_referencia,
          movimientos_select[i].id,
          movimientos_select[i].importe
        );
        let info = utility.valida_movimiento_pago(
          agregados,
          movimientos_select[i].id,
          "Ig"
        );
        if (info.valido) agregados[info.position].pagos.push(pago);
        else
          agregados.push({
            id_movimiento: movimientos_select[i].id,
            id: movimientos_select[i].tipo_ingreso,
            tipo_movimiento: "Ig",
            pagos: [pago],
          });
        if (diferencia <= 0) break;
      } else continue;
    }
    let importe_documento = "";
    if (this.state.id_tipo_pago === 1)
      documentos_grupo_anticipo.forEach((doc) => {
        if (doc.id === this.state.id_documento) {
          doc.saldo = saldo_documento;
          importe_documento = doc.importe;
        }
      });
    if (this.state.id_tipo_pago === 3)
      documentos_grupo_terceros.forEach((doc) => {
        if (doc.id === this.state.id_documento) {
          doc.saldo = saldo_documento;
          importe_documento = doc.importe;
        }
      });
    if (saldo_documento === 0) {
      documentos_uso.push({
        text: this.state.documento,
        id: this.state.id_documento,
        tipo: this.state.id_tipo_pago,
        importe: importe_documento,
      });
      if (this.state.id_tipo_pago === 1) {
        utility.elimina_documento(
          documentos_grupo_anticipo,
          this.state.id_documento
        );
        saldo_documento = "";
        this.setState({
          documento: "Seleccione un documento",
          id_documento: "",
          importe_documento: "",
        });
      }
      if (this.state.id_tipo_pago === 3) {
        utility.elimina_documento(
          documentos_grupo_terceros,
          this.state.id_documento
        );
        saldo_documento = "";
        this.setState({
          documento: "Seleccione un documento",
          id_documento: "",
          importe_documento: "",
        });
      }
    }
    for (let i = 0; i < movimientos_select.length; i++) {
      if (movimientos_select[i].tipo_phxcdc)
        document.getElementById(
          `tercero_${movimientos_select[i].id}`
        ).checked = false;
      else
        document.getElementById(
          `ingreso_${movimientos_select[i].id}`
        ).checked = false;
    }
    this.setState({
      movimientos_select: [],
      saldo_documento,
      agregados,
      referencias_cuenta,
      documentos_grupo_anticipo,
      documentos_uso,
    });
  };
  handleElimina_pago = (e) => {
    let valor = JSON.parse(e.target.value);
    let documento_uso = this.state.documentos_uso;
    let documentos_grupo_anticipo = this.state.documentos_grupo_anticipo;
    let documentos_grupo_terceros = this.state.documentos_grupo_terceros;
    let agregados = this.state.agregados;
    let saldo_documento = this.state.saldo_documento;
    let referencias_cuenta = this.state.referencias_cuenta;
    let response = utility.valida_documento_array(
      documento_uso,
      valor.id_documento
    );
    if (response.valido) {
      if (response.info.tipo === 1) {
        //VALIDA QUE SEA ANTICIPO
        documentos_grupo_anticipo.push({
          id: response.info.id,
          importe: response.info.importe,
          saldo: valor.importe,
          text: response.info.text,
        });
        documento_uso.splice(response.pos, 1);
      }
      if (response.info.tipo === 3) {
        //VALIDA QUE SEA PAGO A TERCEROS
        documentos_grupo_terceros.push({
          id: response.info.id,
          importe: response.info.importe,
          saldo: valor.importe,
          text: response.info.text,
        });
        documento_uso.splice(response.pos, 1);
      }
    } else {
      if (this.state.id_tipo_pago === 1)
        for (let i = 0; i < documentos_grupo_anticipo.length; i++) {
          if (documentos_grupo_anticipo[i].id === valor.id_documento) {
            documentos_grupo_anticipo[i].saldo += valor.importe;
            saldo_documento = documentos_grupo_anticipo[i].saldo;
            break;
          }
        }
      if (this.state.id_tipo_pago === 3)
        for (let i = 0; i < documentos_grupo_terceros.length; i++) {
          if (documentos_grupo_terceros[i].id === valor.id_documento) {
            documentos_grupo_terceros[i].saldo += valor.importe;
            saldo_documento = documentos_grupo_terceros[i].saldo;
            break;
          }
        }
    }
    if (agregados.length === 1 && valor.index === 0) agregados = [];
    else agregados.splice(valor.index, 1);
    if (valor.tipo !== "Ig")
      utility.cambia_importe_suma(
        referencias_cuenta[
          utility.posicion_referencia(
            referencias_cuenta,
            this.state.id_referencia_select_pago
          )
        ].cg_phxcdc_cuenta_referencia,
        valor.id_movimiento,
        valor.importe
      );
    else
      utility.cambia_importe_suma(
        referencias_cuenta[
          utility.posicion_referencia(
            referencias_cuenta,
            this.state.id_referencia_select_pago
          )
        ].cg_ingresos_cuenta_referencia,
        valor.id_movimiento,
        valor.importe
      );
    this.setState({
      documento_uso,
      documentos_grupo_anticipo,
      documentos_grupo_terceros,
      agregados,
      saldo_documento,
    });
  };
  handleAgrega_movimiento = (e) => {
    let info_mov = JSON.parse(e.target.value);
    let movimientos_select = this.state.movimientos_select;
    if (e.target.checked) movimientos_select.push(info_mov);
    else
      for (let i = 0; i < movimientos_select.length; i++)
        if (
          info_mov.id === movimientos_select[i].id &&
          (info_mov.tipo_phxcdc === movimientos_select[i].tipo_phxcdc ||
            info_mov.tipo_ingreso === movimientos_select[i].tipo_ingreso)
        )
          movimientos_select.splice(i, 1);
    this.setState({ movimientos_select });
  };

  handleElimina_referencia_actual = (e) => {
    let referencias_actuales = this.state.referencias_actuales;
    let referecias_eliminar = this.state.referecias_eliminar;
    let referencia = e.target.value;
    for (let i = 0; i < referencias_actuales.length; i++)
      if (referencia === referencias_actuales[i].referencia) {
        referencias_actuales.splice(i, 1);
        referecias_eliminar.push(referencia);
      }
    this.setState({ referencias_actuales, referecias_eliminar });
  };
  handleElimina_ingreso_actual = (e) => {
    let ingresos_eliminar = this.state.ingresos_eliminar;
    let referencias_actuales = this.state.referencias_actuales;
    let movimiento = JSON.parse(e.target.value);
    let bandera = 0;
    for (let i = 0; i < ingresos_eliminar.length; i++)
      if (ingresos_eliminar[i].referencia === movimiento.referencia) {
        ingresos_eliminar[i]["movimientos"] = [
          ...ingresos_eliminar[i]["movimientos"],
          movimiento.id,
        ];
        ingresos_eliminar[i]["contado"] = false;
        bandera = 1;
      }
    for (let i = 0; i < referencias_actuales.length; i++)
      if (referencias_actuales[i].referencia === movimiento.referencia) {
        let pos = referencias_actuales[i].cg_ingresos_cuenta_referencia.find(
          (data, index) => index
        );
        referencias_actuales[i].cg_ingresos_cuenta_referencia.splice(pos, 1);
      }
    if (bandera === 0)
      ingresos_eliminar.push({
        referencia: movimiento.referencia,
        movimientos: [movimiento.id],
        contado: false,
      });
    this.setState({ ingresos_eliminar });
  };
  handleElimina_pago_actual = (e) => {
    let data = JSON.parse(e.target.value);
    let pagos_elimina_phxcdc = this.state.pagos_elimina_phxcdc;
    let pagos_elimina_ingresos = this.state.pagos_elimina_ingresos;
    let referencias_actuales = this.state.referencias_actuales;
    if (data.tipo_ingreso) {
      pagos_elimina_ingresos.push(data.id);
      for (
        let i = 0;
        i <
        referencias_actuales[data.referencia_select]
          .cg_ingresos_cuenta_referencia.length;
        i++
      )
        if (
          parseInt(
            referencias_actuales[data.referencia_select]
              .cg_ingresos_cuenta_referencia[i].id
          ) === data.id
        )
          referencias_actuales[
            data.referencia_select
          ].cg_ingresos_cuenta_referencia.splice(i, 1);
    }
    if (data.tipo_phxcdc) {
      pagos_elimina_phxcdc.push(data.id);
      for (
        let i = 0;
        i <
        referencias_actuales[data.referencia_select].cg_phxcdc_cuenta_referencia
          .length;
        i++
      )
        if (
          parseInt(
            referencias_actuales[data.referencia_select]
              .cg_phxcdc_cuenta_referencia[i].id
          ) === data.id
        )
          referencias_actuales[
            data.referencia_select
          ].cg_phxcdc_cuenta_referencia.splice(i, 1);
    }
    this.setState({
      pagos_elimina_ingresos,
      pagos_elimina_phxcdc,
      referencias_actuales,
    });
  };
  /**
   * Función que permite hacer una petición de forma POST.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} data Objeto a mandar atraves del metodo POST.
   */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta":
              //this.handleCerrar();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.setState({
                enable_pagos: false,
                numero_cuenta: response.data.results.result.cuenta,
                referencias_cuenta:
                  response.data.results.result.cg_referencias_cuenta,
              });
              this.methodPOST_API(
                utility.url_consulta_documentos_grupo,
                "documentos_anticipo",
                { cliente: this.state.numero_select, tipo: "An" }
              );
              this.methodPOST_API(
                utility.url_consulta_documentos_grupo,
                "documentos_terceros",
                { cliente: this.state.numero_select, tipo: "Pt" }
              );
              //this.methodPOST_API(utility.url_consulta_documentos_grupo, 'documentos', { cliente: this.state.numero_select, tipo: 'Nc' });
              //this.props.update(1);
              break;
            case "consulta":
              let referencias = [];
              response.data.results.cg_referencias_cuenta.forEach((ref) =>
                referencias.push({ id: ref.referencia })
              );
              this.setState({
                numero_select: response.data.results.cliente,
                nombre_select: utility.get_name_cliente(
                  response.data.results.cliente,
                  this.props.clientes
                ),
                referencias_actuales:
                  response.data.results.cg_referencias_cuenta,
                referencias_select: referencias,
                enable_pdf: false,
                disabled: [
                  response.data.results.cg_referencias_cuenta.length > 0
                    ? true
                    : false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                ],
              });
              this.methodPDF_API(
                utility.url_consulta_pdf_gta_gastos,
                "consulta_cta_gastos",
                this.state.cuenta_gastos
              );
              this.methodPDF_API(
                utility.url_consulta_pdf_gta_gastos,
                "consulta_pagos",
                this.state.cuenta_gastos
              );
              this.methodPOST_API(
                utility.url_consulta_documentos_grupo,
                "documentos_anticipo",
                { cliente: response.data.results.cliente, tipo: "An" }
              );
              this.methodPOST_API(
                utility.url_consulta_documentos_grupo,
                "documentos_terceros",
                { cliente: response.data.results.cliente, tipo: "Pt" }
              );
              //this.methodPOST_API(utility.url_consulta_documentos_grupo, 'documentos', { cliente: this.state.numero_select, tipo: 'Nc' });
              break;
            case "alta_pagos":
              this.handleCerrar();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            case "phxcdc_poliza":
              let polizas_referencia = this.state.polizas_referencia;
              let referencias_select = this.state.referencias_select;
              let tam_actual =
                polizas_referencia.length > 0 ? polizas_referencia.length : 0;
              if (
                utility.valida_referencia_repeticion_select(
                  this.state.referencia_phxcdc,
                  this.state.referencias_select
                )
              )
                referencias_select.push({ id: this.state.referencia_phxcdc });
              polizas_referencia.push(...response.data.results);
              for (let i = tam_actual; i < polizas_referencia.length; i++)
                polizas_referencia[i]["referencia"] =
                  this.state.referencia_phxcdc;
              this.setState({ polizas_referencia });
              break;
            case "valor_aduana":
              this.setState({ valor_aduanal: response.data.total });
              break;
            case "documentos_anticipo":
              this.setState({
                documentos_grupo_anticipo: utility.organiza_documentos_cliente(
                  response.data.results
                ),
              });
              break;
            case "documentos_terceros":
              this.setState({
                documentos_grupo_terceros: utility.organiza_documentos_cliente(
                  response.data.results
                ),
              });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          let otro = "";
          if (error.response.data.results) {
            error.response.data.results.errors.forEach(
              (error) => (otro += `\n${error}`)
            );
            message.message_modal(
              error.response.data.title,
              error.response.data.text + ":" + otro,
              error.response.status
            );
          } else
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
        }
      });
  };
  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma Put.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} data Objeto a mandar atraves del metodo Put.
   */
  methodPUT_API = (url, state, data) => {
    let url_intern = url;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "modificar":
              let otro = "";
              if (response.data.results.messages.length > 0)
                response.data.results.messages.forEach(
                  (message) => (otro += `\n${message}`)
                );
              message.message_modal(
                response.data.title,
                response.data.text + ":" + otro,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          let otro = "";
          if (error.response.data.results.errors) {
            error.response.data.results.errors.forEach(
              (error) => (otro += `\n${error}`)
            );
            message.message_modal(
              error.response.data.title,
              error.response.data.text + ":" + otro,
              error.response.status
            );
          } else
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
        }
      });
  };
  methodPDF_API = (url, state, num) => {
    let url_intern = url + `${num}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta_cta_gastos":
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);
              document.getElementById("dowload_pdf_consulta_cta_gastos").href =
                fileURL;
              document.getElementById("window_pdf_consulta_cta_gastos").href =
                fileURL;
              break;
            case "consulta_pagos":
              const file_pagos = new Blob([response.data], {
                type: "application/pdf",
              });
              const file_pagos_URL = URL.createObjectURL(file_pagos);
              document.getElementById("dowload_pdf_consulta_pagos").href =
                file_pagos_URL;
              document.getElementById("window_pdf_consulta_pagos").href =
                file_pagos_URL;
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") text_add = "";
    else if (numero_cliente.length <= 4) {
      switch (numero_cliente.length) {
        case 1:
          text_add = "000";
          text_add += numero_cliente;
          break;
        case 2:
          text_add = "00";
          text_add += numero_cliente;
          break;
        case 3:
          text_add = "0";
          text_add += numero_cliente;
          break;
        case 4:
          text_add = numero_cliente;
          break;
        default:
          return null;
      }
    } else text_add = "";
    for (let i = 0; i < this.props.clientes.length; i++) {
      if (text_add.length === 0) {
        document.getElementById("nombre_cliente_cta_gastos_consulta").value =
          "";
        this.suggestionSelected("");
        break;
      }
      if (text_add === this.props.clientes[i].numero) {
        document.getElementById("nombre_cliente_cta_gastos_consulta").value =
          this.props.clientes[i].nombre;
        this.suggestionSelected(this.props.clientes[i].nombre);
        break;
      }
    }
  };
  suggestionSelected = (value) => {
    let number = utility.get_number_cliente(value, this.props.clientes);
    if (number === undefined) number = "";
    document.getElementById("numero_cliente_cta_gastos_consulta").value =
      number;
    this.setState({
      nombre_select: value,
      numero_select: number,
      nombre_suggestions: [],
    });
  };
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_nombres_clientes(this.props.clientes)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      nombre_suggestions: suggestions,
      nombre_select: value,
    });
  };
  renderSuggestion = () => {
    if (this.state.nombre_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Consulta Cuenta Gastos
        </ModalHeader>
        <ModalBody>
          <div className="row mb-2 d-flex justify-content-center">
            <div className="col-lg-10 col-12 d-flex justify-content-around">
              <Button color="primary" onClick={this.toogleIngresos}>
                Ingresos / Terceros
              </Button>
              <Button
                color="info"
                disabled={this.state.enable_pagos}
                onClick={this.tooglePago}
              >
                Pagos
              </Button>
            </div>
          </div>
          <Collapse isOpen={this.state.toogle_ingresos}>
            <Card outline color="primary">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-1">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-10 col-8"
                      id="cuenta_cta_gastos_consulta"
                      nameLabel="Cuenta de Gastos"
                      value={this.state.cuenta_gastos}
                      onChange={this.handleCuenta_gastos}
                    />
                    <Button
                      color="primary"
                      className="col-lg-2 col-4 mb-2"
                      onClick={this.handleConsulta}
                    >
                      Consulta
                    </Button>
                  </FormGroup>
                </Form>
                <Card outline>
                  <CardBody>
                    <Form className="container-fluid">
                      <FormGroup className="row mb-1 d-flex justify-content-center">
                        <FormText color="muted">Pagos A Terceros</FormText>
                      </FormGroup>
                      <FormGroup className="row mb-0">
                        <MyInput
                          type="text"
                          bootstrap="col-lg-6"
                          id="numero_cliente_cta_gastos_consulta"
                          disabled={this.state.disabled[0]}
                          onChange={this.nameGenerator}
                          value={this.state.numero_select}
                          nameLabel="Número de Cliente"
                        />
                        <MyInput
                          type="text"
                          bootstrap="col-lg-6"
                          id="nombre_cliente_cta_gastos_consulta"
                          disabled={this.state.disabled[0]}
                          onChange={this.onNameChange}
                          value={this.state.nombre_select}
                          nameLabel="Nombre de Cliente"
                        />
                        {this.renderSuggestion()}
                      </FormGroup>
                      <FormGroup className="row mb-0">
                        <MyInput
                          type="text"
                          bootstrap="col-lg-10 col-8"
                          id="referencia_phxcdc_cta_gastos_consulta"
                          nameLabel="Referencia"
                          value={this.state.referencia_phxcdc}
                          onChange={this.handleReferencia_phxcdc}
                          disabled={this.state.disabled[1]}
                        />
                        <Button
                          color="primary"
                          className="col-lg-2 col-4 mb-2"
                          onClick={this.handleAgregar_referencia_phxcdc}
                          disabled={this.state.disabled[1]}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      </FormGroup>
                    </Form>
                    <div className="col-12 my-2 d-flex justify-content-center flex-wrap">
                      {this.state.referencias_select.map((data, index) => (
                        <Badge color="light" className="border">
                          {data.id}&nbsp;
                          <spam
                            style={{ cursor: "pointer" }}
                            id={index}
                            onClick={this.handleElimina_referencia}
                          >
                            &times;
                          </spam>
                        </Badge>
                      ))}
                    </div>
                    <Nav className="mb-1" tabs>
                      <NavItem>
                        <NavLink
                          onClick={() => this.toogleTap_phxcdc("1")}
                          className={classnames({
                            active: this.state.tap_phxcdc === "1",
                          })}
                        >
                          Actuales
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => this.toogleTap_phxcdc("2")}
                          className={classnames({
                            active: this.state.tap_phxcdc === "2",
                          })}
                        >
                          Nuevos
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.tap_phxcdc}>
                      <TabPane tabId="1">
                        <Table size="sm" bordered hover responsive>
                          <thead className="text-center header-table">
                            <tr>
                              <td>Referencia</td>
                              <td>Movimiento</td>
                              <td>Importe</td>
                              <td>Pagado</td>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody className="body-table">
                            {this.state.referencias_actuales.map((ref) => {
                              if (ref.cg_phxcdc_cuenta_referencia.length > 0)
                                return (
                                  <React.Fragment>
                                    <tr>
                                      <td
                                        className="text-center"
                                        rowSpan={
                                          ref.cg_phxcdc_cuenta_referencia.length
                                        }
                                      >
                                        {ref.referencia}
                                      </td>
                                      <td className="text-center">
                                        {utility.get_nombre_phxcdc(
                                          this.props.phxcdc,
                                          ref.cg_phxcdc_cuenta_referencia[0]
                                            .tipo_phxcdc
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {
                                          ref.cg_phxcdc_cuenta_referencia[0]
                                            .importe
                                        }
                                      </td>
                                      <td className="text-center">
                                        {ref.cg_phxcdc_cuenta_referencia[0]
                                          .pagos.length > 0
                                          ? "Si"
                                          : "No"}
                                      </td>
                                      <td
                                        className="text-center"
                                        rowSpan={
                                          ref.cg_phxcdc_cuenta_referencia.length
                                        }
                                      >
                                        <Button
                                          color="danger"
                                          size="sm"
                                          disabled={
                                            ref.cg_phxcdc_cuenta_referencia[0]
                                              .pagos.length > 0
                                              ? true
                                              : false
                                          }
                                          value={ref.referencia}
                                          onClick={
                                            this.handleElimina_referencia_actual
                                          }
                                        >
                                          Eliminar
                                        </Button>
                                      </td>
                                    </tr>
                                    {ref.cg_phxcdc_cuenta_referencia.map(
                                      (mov, index) => {
                                        if (index > 0)
                                          return (
                                            <tr>
                                              <td className="text-center">
                                                {utility.get_nombre_phxcdc(
                                                  this.props.phxcdc,
                                                  mov.tipo_phxcdc
                                                )}
                                              </td>
                                              <td className="text-center">
                                                {mov.importe}
                                              </td>
                                              <td className="text-center">
                                                {mov.pagos.length > 0
                                                  ? "Si"
                                                  : "No"}
                                              </td>
                                            </tr>
                                          );
                                      }
                                    )}
                                  </React.Fragment>
                                );
                            })}
                          </tbody>
                        </Table>
                      </TabPane>
                      <TabPane tabId="2">
                        <Table size="sm" bordered hover responsive>
                          <thead className="text-center header-table">
                            <tr>
                              <td>Referencia</td>
                              <td>Poliza</td>
                              <td>Movimiento</td>
                              <td>Importe</td>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody className="body-table">
                            {this.state.polizas_referencia.map(
                              (data, i, array) => {
                                let referencia = "";
                                if (i > 0) referencia = array[i - 1].referencia;
                                return (
                                  <tr key={data.referencia}>
                                    {referencia == data.referencia ? null : (
                                      <td
                                        rowSpan={utility.repeticion_referencia(
                                          data.referencia,
                                          this.state.polizas_referencia
                                        )}
                                      >
                                        <p className="m-auto text-center">
                                          {data.referencia}
                                        </p>
                                      </td>
                                    )}
                                    <td>
                                      {data.movimientos.map((mov) => (
                                        <p className="mb-0 text-center">
                                          {mov.poliza}
                                        </p>
                                      ))}
                                    </td>
                                    <td>
                                      {data.movimientos.map((mov) => (
                                        <p className="mb-0 text-center">
                                          {mov.auxiliar}
                                        </p>
                                      ))}
                                    </td>
                                    <td>
                                      {data.movimientos.map((mov) => (
                                        <p className="mb-0 text-center">
                                          $ {mov.cargo}
                                        </p>
                                      ))}
                                    </td>
                                    {referencia == data.referencia ? null : (
                                      <td
                                        align="center"
                                        rowSpan={utility.repeticion_referencia(
                                          data.referencia,
                                          array
                                        )}
                                      >
                                        <Button
                                          color="danger"
                                          size="sm"
                                          value={data.referencia}
                                          onClick={this.handleElimina_phxcdc}
                                        >
                                          Eliminar
                                        </Button>
                                      </td>
                                    )}
                                  </tr>
                                );
                              }
                            )}
                            <tr>
                              <td colSpan={3}></td>
                              <td className="text-center" colSpan={2}>
                                TOTAL: ${" "}
                                {utility
                                  .suma_importes_phxcdc(
                                    this.state.polizas_referencia
                                  )
                                  .toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
                <Card className="mt-2" outline>
                  <CardBody>
                    <Form className="container-fluid">
                      <FormGroup className="row mb-1 d-flex justify-content-center">
                        <FormText color="muted">Ingresos</FormText>
                      </FormGroup>
                      <FormGroup className="row mb-0">
                        <CustomSelect
                          bootstrap="col-12 mb-2"
                          name_text="id"
                          name_index="id"
                          id="referencia_ingresos_consulta_cta_gastos"
                          nameLabel="Referencia"
                          options={this.state.referencias_select}
                          value={this.state.refrencia_ingresos}
                          onChange={this.handleReferencia_ingresos}
                          disabled={this.state.disabled[2]}
                        />
                      </FormGroup>
                      <FormGroup className="row mb-0">
                        <MyInput
                          type="text"
                          bootstrap="col-lg-6 col-12"
                          id="valor_aduanal_cta_gastos_consulta"
                          nameLabel="Valor Aduanal"
                          disabled="true"
                          value={this.state.valor_aduanal}
                        />
                        <MyInput
                          type="text"
                          bootstrap="col-lg-3 col-12"
                          id="porcentaje_aduanal_cta_gastos_consulta"
                          nameLabel="Porcentaje (%)"
                          onChange={this.handlePorcentaje}
                          value={this.state.porcentaje}
                          disabled={this.state.disabled[3]}
                        />
                        <div className="d-flex justify-content-center align-items-center col-lg-3 col-12">
                          <CustomInput
                            type="switch"
                            id="opcion_aduanal_cta_gastos_consulta"
                            name="valorAduanal"
                            label="Valor Aduanal"
                            onChange={this.handleValor_aduanal}
                            disabled={this.state.disabled[3]}
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="row mb-1">
                        <CustomSelect
                          bootstrap="col-lg-4 col-12 mb-2"
                          name_text="nombre"
                          name_index="clave"
                          id="ingresos_consulta_cta_gastos"
                          nameLabel="Ingresos"
                          options={this.props.ingresos}
                          value={this.state.text_ingresos}
                          onChange={this.handleIngreso}
                          disabled={this.state.disabled[4]}
                        />
                        <MyInput
                          type="text"
                          bootstrap="col-lg-3 col-12"
                          id="cantidad_cta_gastos_consulta"
                          nameLabel="Importe"
                          value={this.state.importe_ingreso}
                          onChange={this.handleImporte_ingreso}
                          disabled={this.state.disabled[5]}
                        />
                        <MyInput
                          type="text"
                          bootstrap="col-lg-3 col-12"
                          id="retencion_cta_gastos_consulta"
                          nameLabel="Retencion (%)"
                          value={this.state.retencion}
                          onChange={this.handleRetencion}
                          disabled={this.state.disabled[6]}
                        />
                        <Button
                          color="primary"
                          className="col-lg-2 col-12 mb-2"
                          onClick={this.handleAgrega_ingreso}
                          disabled={this.state.disabled[6]}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      </FormGroup>
                    </Form>
                    <Nav className="mb-1" tabs>
                      <NavItem>
                        <NavLink
                          onClick={() => this.toogleTap_ingresos("1")}
                          className={classnames({
                            active: this.state.tap_ingresos === "1",
                          })}
                        >
                          Actuales
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => this.toogleTap_ingresos("2")}
                          className={classnames({
                            active: this.state.tap_ingresos === "2",
                          })}
                        >
                          Nuevos
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.tap_ingresos}>
                      <TabPane tabId="1">
                        <Table size="sm" bordered hover responsive>
                          <thead className="text-center header-table">
                            <tr>
                              <td>Referencia</td>
                              <td>Movimiento</td>
                              <td>Importe</td>
                              <td>Pagado</td>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody className="body-table">
                            {this.state.referencias_actuales.map((ref) => {
                              if (ref.cg_ingresos_cuenta_referencia.length > 0)
                                return (
                                  <React.Fragment>
                                    <tr>
                                      <td
                                        className="text-center"
                                        rowSpan={
                                          ref.cg_ingresos_cuenta_referencia
                                            .length
                                        }
                                      >
                                        {ref.referencia}
                                      </td>
                                      <td className="text-center">
                                        {utility.get_nombre_ingreso(
                                          this.props.ingresos,
                                          ref.cg_ingresos_cuenta_referencia[0]
                                            .tipo_ingreso
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {
                                          ref.cg_ingresos_cuenta_referencia[0]
                                            .importe
                                        }
                                      </td>
                                      <td className="text-center">
                                        {ref.cg_ingresos_cuenta_referencia[0]
                                          .pagos.length > 0
                                          ? "Si"
                                          : "No"}
                                      </td>
                                      <td className="text-center">
                                        <Button
                                          color="danger"
                                          size="sm"
                                          disabled={
                                            ref.cg_ingresos_cuenta_referencia[0]
                                              .pagos.length > 0
                                              ? true
                                              : false
                                          }
                                          value={JSON.stringify({
                                            ...ref
                                              .cg_ingresos_cuenta_referencia[0],
                                            referencia: ref.referencia,
                                          })}
                                          onClick={
                                            this.handleElimina_ingreso_actual
                                          }
                                        >
                                          Eliminar
                                        </Button>
                                      </td>
                                    </tr>
                                    {ref.cg_ingresos_cuenta_referencia.map(
                                      (mov, index) => {
                                        if (index > 0)
                                          return (
                                            <tr>
                                              <td className="text-center">
                                                {utility.get_nombre_ingreso(
                                                  this.props.ingresos,
                                                  mov.tipo_ingreso
                                                )}
                                              </td>
                                              <td className="text-center">
                                                {mov.importe}
                                              </td>
                                              <td className="text-center">
                                                {mov.pagos.length > 0
                                                  ? "Si"
                                                  : "No"}
                                              </td>
                                              <td className="text-center">
                                                <Button
                                                  color="danger"
                                                  size="sm"
                                                  disabled={
                                                    mov.pagos.length > 0
                                                      ? true
                                                      : false
                                                  }
                                                  value={JSON.stringify({
                                                    ...mov,
                                                    referencia: ref.referencia,
                                                  })}
                                                  onClick={
                                                    this
                                                      .handleElimina_ingreso_actual
                                                  }
                                                >
                                                  Eliminar
                                                </Button>
                                              </td>
                                            </tr>
                                          );
                                      }
                                    )}
                                  </React.Fragment>
                                );
                            })}
                          </tbody>
                        </Table>
                      </TabPane>
                      <TabPane tabId="2">
                        <Table size="sm" bordered hover responsive>
                          <thead className="text-center header-table">
                            <tr>
                              <td>Referencia</td>
                              <td>Movimiento</td>
                              <td>Importe</td>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody className="body-table">
                            {this.state.referencias_ingresos.map(
                              (ref, index, array) => {
                                let referencia = "";
                                if (index !== 0)
                                  referencia = array[index - 1].referencia;
                                return (
                                  <tr>
                                    {ref.referencia === referencia ? null : (
                                      <td
                                        className="text-center"
                                        rowSpan={utility.repeticion_referencia(
                                          ref.referencia,
                                          this.state.referencias_ingresos
                                        )}
                                      >
                                        {ref.referencia}
                                      </td>
                                    )}
                                    <td className="text-center">
                                      <p className="text-center mb-0">
                                        {utility.get_nombre_ingreso(
                                          this.props.ingresos,
                                          ref.tipo_ingreso
                                        )}
                                      </p>
                                    </td>
                                    <td className="text-center">
                                      <p className="text-center mb-0">
                                        $ {ref.importe}
                                      </p>
                                    </td>
                                    <td className="text-center">
                                      <Button
                                        size="sm"
                                        color="danger"
                                        value={index}
                                        onClick={this.handleElimina_ingreso}
                                      >
                                        Eliminar
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            <tr>
                              <td colSpan={2}></td>
                              <td className="text-center" colSpan={2}>
                                SUBTOTAL: ${" "}
                                {utility
                                  .suma_importes_ingresos(
                                    this.state.referencias_ingresos
                                  )
                                  .toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}></td>
                              <td className="text-center" colSpan={2}>
                                TOTAL: ${" "}
                                {utility
                                  .suma_importes_ingresos_total(
                                    this.state.referencias_ingresos
                                  )
                                  .toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </TabPane>
                    </TabContent>
                    <div className="d-flex justify-content-center mb-2">
                      <LinkD
                        href="#"
                        id="dowload_pdf_consulta_cta_gastos"
                        target="_self"
                        download={`Cuenta de Gastos ${this.state.cuenta_gastos}`}
                      />
                      <LinkV
                        href="#"
                        id="window_pdf_consulta_cta_gastos"
                        target={this.state.enable_pdf ? "_self" : "_blank"}
                        className="ml-5"
                      />
                    </div>
                    <FormText color="muted" className="row mb-1 text-center">
                      EL TOTAL ya incluye el IVA (16%) y sus retenciones si lo
                      amerita.
                    </FormText>
                    <Form className="container-fluid">
                      <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                        <Button
                          type="button"
                          color="info"
                          className="mr-2"
                          onClick={this.handleModificar_movimientos}
                          disabled={permisos.obtener_permiso_especifico(
                            "modificar",
                            this.props.permisos
                          )}
                        >
                          Modificar
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          onClick={this.handleCerrar}
                        >
                          Cerrar
                        </Button>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_pago}>
            <Card outline color="info">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-1">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="cuenta_pagos_gastos_consulta"
                      nameLabel="Número de Cuenta"
                      value={this.state.cuenta_gastos}
                      disabled
                    />
                    <CustomSelect
                      bootstrap="col-lg-6 col-12 mb-2"
                      name_text="referencia"
                      name_index="id"
                      id="referencia_pagos_gastos_consulta"
                      nameLabel="Referencias"
                      options={this.state.referencias_actuales}
                      value={this.state.referencia_select_pago}
                      onChange={this.handleReferencia_pagos}
                    />
                  </FormGroup>
                </Form>
                <Table size="sm" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>Tipo</td>
                      <td>Movimiento</td>
                      <td>Importe</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.id_referencia_select_pago !== null
                      ? this.state.referencias_actuales[
                          utility.posicion_referencia(
                            this.state.referencias_actuales,
                            this.state.id_referencia_select_pago
                          )
                        ].cg_phxcdc_cuenta_referencia.map((mov) => {
                          if (mov.importe > 0)
                            if (mov.pagos.length === 0)
                              return (
                                <tr>
                                  <td className="text-center">
                                    Pago de terceros
                                  </td>
                                  <td className="text-center">
                                    {mov.tipo_phxcdc}
                                  </td>
                                  <td className="text-center">
                                    $ {mov.importe}
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-center my-auto">
                                      <input
                                        type="checkbox"
                                        id={`tercero_${mov.id}`}
                                        value={JSON.stringify(mov)}
                                        onChange={this.handleAgrega_movimiento}
                                      />
                                      <label
                                        htmlFor={`tercero_${mov.id}`}
                                        className="check-circle"
                                        style={{ width: "30px" }}
                                      ></label>
                                    </div>
                                  </td>
                                </tr>
                              );
                        })
                      : null}
                    {this.state.id_referencia_select_pago !== null
                      ? this.state.referencias_actuales[
                          utility.posicion_referencia(
                            this.state.referencias_actuales,
                            this.state.id_referencia_select_pago
                          )
                        ].cg_ingresos_cuenta_referencia.map((mov) => {
                          if (mov.importe > 0)
                            if (mov.pagos.length === 0)
                              return (
                                <tr>
                                  <td className="text-center">Ingreso</td>
                                  <td className="text-center">
                                    {mov.tipo_ingreso}
                                  </td>
                                  <td className="text-center">
                                    $ {mov.importe}
                                  </td>
                                  <td>
                                    {mov.pagos.length > 0 ? (
                                      <p className="text-center mb-0">Pagado</p>
                                    ) : (
                                      <div className="d-flex justify-content-center my-auto">
                                        <input
                                          type="checkbox"
                                          id={`ingreso_${mov.id}`}
                                          value={JSON.stringify(mov)}
                                          onChange={
                                            this.handleAgrega_movimiento
                                          }
                                        />
                                        <label
                                          htmlFor={`ingreso_${mov.id}`}
                                          className="check-circle"
                                          style={{ width: "30px" }}
                                        ></label>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                        })
                      : null}
                  </tbody>
                </Table>
                <Form className="container-fluid">
                  <FormGroup className="row mb-1">
                    <CustomSelect
                      bootstrap="col-lg-5 col-12 mb-2"
                      name_text="text"
                      name_index="id"
                      id="tipo_pago_pagos_gastos_consulta"
                      nameLabel="Tipo de Pago"
                      options={this.state.tipos_pago}
                      value={this.state.tipo_pago}
                      onChange={this.handleTipo_pago}
                    />
                    <CustomSelect
                      bootstrap="col-lg-5 col-12 mb-2"
                      name_text="text"
                      name_index="id"
                      id="tipo_pago_pagos_gastos_consulta"
                      nameLabel="Documento"
                      options={
                        this.state.id_tipo_pago === 1
                          ? this.state.documentos_grupo_anticipo
                          : this.state.id_tipo_pago === 3
                          ? this.state.documentos_grupo_terceros
                          : []
                      }
                      value={this.state.documento}
                      onChange={this.handleDocumento}
                    />
                    <Button
                      color="primary"
                      className="col-lg-2 col-12 mb-2"
                      onClick={this.handleAgrega_pago}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-1">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="importe_doc_pagos_gastos_consulta"
                      nameLabel="Importe Documento"
                      value={this.state.importe_documento}
                      disabled
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="saldo_doc_pagos_gastos_consulta"
                      nameLabel="Saldo Documento"
                      value={this.state.saldo_documento}
                      disabled
                    />
                  </FormGroup>
                </Form>
                <Table size="sm" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>Tipo</td>
                      <td>Movimiento</td>
                      <td>Tipo de Documento</td>
                      <td>Documento</td>
                      <td>Importe</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.id_referencia_select_pago !== null
                      ? this.state.referencias_actuales[
                          utility.posicion_referencia(
                            this.state.referencias_actuales,
                            this.state.id_referencia_select_pago
                          )
                        ].cg_phxcdc_cuenta_referencia.map((ing) => {
                          if (ing.pagos.length > 0)
                            return (
                              <tr>
                                <td className="text-center">Pago a terceros</td>
                                <td className="text-center">
                                  {ing.tipo_phxcdc}
                                </td>
                                <td className="text-center">
                                  <p className="text-center text-white mb-0">
                                    -
                                  </p>
                                  {ing.pagos.map((pago) => (
                                    <p className="text-center mb-0">
                                      {pago.tipo_pago === "1"
                                        ? "Anticipo"
                                        : pago.tipo_pago === "2"
                                        ? "Nota de credito"
                                        : "Pago de terceros"}
                                    </p>
                                  ))}
                                </td>
                                <td className="text-center">
                                  <p className="text-center text-white mb-0">
                                    -
                                  </p>
                                  {ing.pagos.map((pago) => (
                                    <p className="text-center mb-0">
                                      {pago.id_documento}
                                    </p>
                                  ))}
                                </td>
                                <td className="text-center">
                                  <p className="text-center text-white mb-0">
                                    -
                                  </p>
                                  {ing.pagos.map((pago) => (
                                    <p className="text-center mb-0">
                                      {pago.importe}
                                    </p>
                                  ))}
                                </td>
                                <td className="text-center">
                                  <Button
                                    color="danger"
                                    size="sm"
                                    value={JSON.stringify({
                                      ...ing,
                                      referencia_select:
                                        utility.posicion_referencia(
                                          this.state.referencias_actuales,
                                          this.state.id_referencia_select_pago
                                        ),
                                    })}
                                    onClick={this.handleElimina_pago_actual}
                                  >
                                    Eliminar
                                  </Button>
                                </td>
                              </tr>
                            );
                        })
                      : null}
                    {this.state.id_referencia_select_pago !== null
                      ? this.state.referencias_actuales[
                          utility.posicion_referencia(
                            this.state.referencias_actuales,
                            this.state.id_referencia_select_pago
                          )
                        ].cg_ingresos_cuenta_referencia.map((ing) => {
                          if (ing.pagos.length > 0)
                            return (
                              <tr>
                                <td className="text-center">Ingreso</td>
                                <td className="text-center">
                                  {ing.tipo_ingreso}
                                </td>
                                <td className="text-center">
                                  <p className="text-center text-white mb-0">
                                    -
                                  </p>
                                  {ing.pagos.map((pago) => (
                                    <p className="text-center mb-0">
                                      {pago.tipo_pago === "1"
                                        ? "Anticipo"
                                        : pago.tipo_pago === "2"
                                        ? "Nota de credito"
                                        : "Pago de terceros"}
                                    </p>
                                  ))}
                                </td>
                                <td className="text-center">
                                  <p className="text-center text-white mb-0">
                                    -
                                  </p>
                                  {ing.pagos.map((pago) => (
                                    <p className="text-center mb-0">
                                      {pago.id_documento}
                                    </p>
                                  ))}
                                </td>
                                <td className="text-center">
                                  <p className="text-center text-white mb-0">
                                    -
                                  </p>
                                  {ing.pagos.map((pago) => (
                                    <p className="text-center mb-0">
                                      {pago.importe}
                                    </p>
                                  ))}
                                </td>
                                <td className="text-center">
                                  <Button
                                    color="danger"
                                    size="sm"
                                    value={JSON.stringify({
                                      ...ing,
                                      referencia_select:
                                        utility.posicion_referencia(
                                          this.state.referencias_actuales,
                                          this.state.id_referencia_select_pago
                                        ),
                                    })}
                                    onClick={this.handleElimina_pago_actual}
                                  >
                                    Eliminar
                                  </Button>
                                </td>
                              </tr>
                            );
                        })
                      : null}
                    {this.state.agregados.map((movimiento) => (
                      <React.Fragment>
                        <tr>
                          <td
                            className="text-center"
                            rowSpan={movimiento.pagos.length}
                          >
                            {movimiento.tipo_movimiento === "Ig"
                              ? "Ingreso"
                              : "Pago a terceros"}
                          </td>
                          <td
                            className="text-center"
                            rowSpan={movimiento.pagos.length}
                          >
                            {movimiento.id}
                          </td>
                          <td className="text-center">
                            {movimiento.pagos[0].tipo_pago === 1
                              ? "Anticipo"
                              : movimiento.pagos[0].tipo_pago === 2
                              ? "Nota de credito"
                              : "Pago de terceros"}
                          </td>
                          <td className="text-center">
                            {movimiento.pagos[0].id_documento}
                          </td>
                          <td className="text-center">
                            {movimiento.pagos[0].importe}
                          </td>
                          <td className="text-center">
                            <Button
                              color="danger"
                              size="sm"
                              onClick={this.handleElimina_pago}
                              value={JSON.stringify({
                                id_movimiento: movimiento.id_movimiento,
                                tipo_pago: movimiento.pagos[0].tipo_pago,
                                importe: movimiento.pagos[0].importe,
                                id_documento: movimiento.pagos[0].id_documento,
                                index: 0,
                                tipo: movimiento.tipo_movimiento,
                              })}
                            >
                              Eliminar
                            </Button>
                          </td>
                        </tr>
                        {movimiento.pagos.map((pago, index, array) => {
                          if (index > 0)
                            return (
                              <tr>
                                <td className="text-center">
                                  {pago.tipo_pago === 1
                                    ? "Anticipo"
                                    : pago.tipo_pago === 2
                                    ? "Nota de credito"
                                    : "Pago de terceros"}
                                </td>
                                <td className="text-center">
                                  {pago.id_documento}
                                </td>
                                <td className="text-center">{pago.importe}</td>
                                <td className="text-center">
                                  <Button
                                    color="danger"
                                    size="sm"
                                    onClick={this.handleElimina_pago}
                                    value={JSON.stringify({
                                      id_movimiento: movimiento.id_movimiento,
                                      tipo_pago: pago.tipo_pago,
                                      importe: pago.importe,
                                      id_documento: pago.id_documento,
                                      index: index,
                                      tipo: movimiento.tipo_movimiento,
                                    })}
                                  >
                                    Eliminar
                                  </Button>
                                </td>
                              </tr>
                            );
                        })}
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-center mb-2">
                  <LinkD
                    href="#"
                    id="dowload_pdf_consulta_pagos"
                    target="_self"
                    download={`Relación de Pagos ${this.state.cuenta_gastos}`}
                  />
                  <LinkV
                    href="#"
                    id="window_pdf_consulta_pagos"
                    target={this.state.enable_pdf ? "_self" : "_blank"}
                    className="ml-5"
                  />
                </div>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      type="button"
                      color="info"
                      className="mr-2"
                      onClick={this.handleModificar_pagos}
                      disabled={permisos.obtener_permiso_especifico(
                        "modificar",
                        this.props.permisos
                      )}
                    >
                      Modificar
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      onClick={this.handleCerrar}
                    >
                      Cerrar
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
        </ModalBody>
      </Modal>
    );
  }
}
export default ModalConsulta;
