/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente general de la aplicación empleados. 
*/
import React, { Component } from "react";
import SearchBar from "./SearchBar";
import TableDeposito from "./Table";
import axios from "axios";
import * as messages from "./messages/messages";
import * as utility from "./utils/utility";
import * as permisos from "../../utils/permisos/permisos";

class NotificacionDeposito extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      actualizar: 0,
      permisos: permisos.obtener_permisos_by_app(
        "notificaciondeposito",
        localStorage.getItem("perm")
      ),
      on_cliente:
        localStorage.getItem("puesto") === "Cliente"
          ? localStorage.getItem("id_usr")
          : false,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_clientes, "clientes");
    this.methodGET_API(utility.url_pedimento, "pedimento");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "clientes":
              if (this._isMounted === true)
                this.setState({ clientes: response.data.results });
              break;
            case "bancos":
              if (this._isMounted === true)
                this.setState({ bancos: response.data.results });
              break;
            case "tipo_movimiento":
              if (this._isMounted === true)
                this.setState({ tipo_movimiento: response.data.results });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          messages.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  update = (cambio) => {
    this.setState({ actualizar: cambio });
  };
  render() {
    return (
      <div className="container-fluid">
        <SearchBar
          update={this.update}
          clientes={this.state.clientes}
          permisos={this.state.permisos}
          on_cliente={this.state.on_cliente}
          pedimento={this.state.pedimento}
        />
        <TableDeposito
          update={this.update}
          actualizar={this.state.actualizar}
          on_cliente={this.state.on_cliente}
        />
      </div>
    );
  }
}
export default NotificacionDeposito;
