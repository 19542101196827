/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente general de la aplicación anticipos. 
*/
import axios from "axios";
import React, { Component } from "react";
import * as message from "./messages/messages";
import * as utility from "./utils/utility";
import SearchBar from "./SearchBar";
import TablaAnticipo from "./Table";
import * as permisos from "../../utils/permisos/permisos";

class Anticipos extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      clientes: [],
      bancos: [],
      checked: [],
      tipo_movimiento: [],
      actualizar: 0,
      actualizar_con_dev: 0,
      permisos: permisos.obtener_permisos_by_app(
        "anticipo",
        localStorage.getItem("perm")
      ),
      on_cliente:
        localStorage.getItem("puesto") === "Cliente"
          ? localStorage.getItem("id_usr")
          : false,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_clientes, "clientes");
    this.methodGET_API(utility.url_bancos, "bancos");
    this.methodGET_API(utility.url_tipo_movimiento, "tipo_movimiento");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "clientes":
              if (this._isMounted === true)
                this.setState({ clientes: response.data.results });
              break;
            case "bancos":
              if (this._isMounted === true)
                this.setState({ bancos: response.data.results });
              break;
            case "tipo_movimiento":
              if (this._isMounted === true)
                this.setState({ tipo_movimiento: response.data.results });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  update = (cambio) => {
    this.setState({ actualizar: cambio });
  };
  update_con_dev = (cambio) => {
    this.setState({ actualizar_con_dev: cambio });
  };
  onCheckedSol = (data) => {
    this.setState({
      checked: data,
    });
  };
  render() {
    return (
      <div className="container-fluid">
        <SearchBar
          clientes={this.state.clientes}
          bancos={this.state.bancos}
          update={this.update}
          update_con_dev={this.update_con_dev}
          checked={this.state.checked}
          permisos={this.state.permisos}
          on_cliente={this.state.on_cliente}
        />
        <TablaAnticipo
          clientes={this.state.clientes}
          bancos={this.state.bancos}
          tipo_movimiento={this.state.tipo_movimiento}
          update={this.update}
          update_con_dev={this.update_con_dev}
          actualizar_con_dev={this.state.actualizar_con_dev}
          actualizar={this.state.actualizar}
          checked={this.onCheckedSol}
          on_cliente={this.state.on_cliente}
          permisos={this.state.permisos}
        />
      </div>
    );
  }
}
export default Anticipos;
