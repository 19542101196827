/* 
Componente que permite ver todos los usuarios en una tabla de la pagina principal de la app de Usuarios
*/
import axios from 'axios';
import React, { Component } from 'react';
import * as messages from './messages/messages';
import * as utility from './utils/utility';
import { Table, Button } from 'reactstrap';
import swal from '@sweetalert/with-react';
import Paginacion from '../Pagination';

class TableUsu extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            pagination: { count: 1, results: [] },
            checked_recolectar: [],
            checked_llegada: [],
            checked_entrega: [],
            page_actual: 1,
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.methodGET_API(utility.url_paginacion, 'all_usuarios');
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (this.props.actualizar !== prevProps.actualizar) {
            this.handlePage(this.state.page_actual);
            this.props.update(0);
        }
    }
    /**
     * Función que permite hacer una petición de forma POST 
     * @param {String} url URL a consultar 
     * @param {String} state Onpción del case  
     * @param {Object} data Objeto a mandar atraves del metodo POST 
     */
    methodPOST_API = (url, state, data) => {
        let url_intern = url;
        axios.post(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400) {
                    switch (state) {
                        case 'activa':
                            if (this._isMounted === true)
                                messages.message_modal(response.data.title, response.data.text, response.status);
                            break;
                        case 'desactiva':
                            if (this._isMounted === true)
                                messages.message_modal(response.data.title, response.data.text, response.status);
                            break;
                        case 'elimina':
                            if (this._isMounted === true)
                                messages.message_modal(response.data.title, response.data.text, response.status);
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch(error => {
                if (error.response)
                    messages.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    /**
     * Función que permite hacer una petición de forma GET
     * @param {String} url URL a consulta 
     * @param {String} state Opción del case 
     */
    methodGET_API = (url, state) => {
        let url_intern = url;
        axios.get(url_intern)
            .then(response => {
                if (response.status >= 200 && response.status < 400) {
                    switch (state) {
                        case 'all_usuarios':
                            if (this._isMounted === true)
                                this.setState({
                                    pagination: { count: response.data.count, results: response.data.results }
                                });
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch(error => {
                if (error.response)
                    messages.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    /**
     * Función que permite activar o desactivar un usuario atraves de un checkbox
     * @param {Event} e Evento que se ejecuto al dar click en chechbox 
     */
    onDesActUsuario = (e) => {
        let first_message = e.target.value ? `Desactivara el usuario: ${e.target.id}` : `Activara al usuario: ${e.target.id}`;
        let username = e.target.id;
        let is_active = e.target.value;
        swal({
            title: first_message,
            buttons: ["Cancelar", true],
            content: (<div className="d-none">
                <Button className="d-none" id="button_name" value={username}></Button>
                <Button className="d-none" id="button_check" value={is_active}></Button>
            </div>),
            text: "¿Desea continuar?",
            icon: "warning"
        })
            .then((res) => {
                let name = document.getElementById('button_name').value;
                let check = document.getElementById('button_check').value;
                if (res === null) {
                    document.getElementById(name).checked = (check === 'true' ? true : false);
                }
                else {
                    if (check === 'true') {
                        this.methodPOST_API(utility.url_desactiva_usuario, 'desactiva', { usuario: name })
                        this.methodGET_API(utility.url_paginacion, 'all_usuarios');
                    }
                    else {
                        this.methodPOST_API(utility.url_activa_usuario, 'activa', { usuario: name })
                        this.methodGET_API(utility.url_paginacion, 'all_usuarios');
                    }
                }
            })
    }
    /**
     * Función que permite eliminar un usuario dando click en el boton "Eliminar"
     * @param {Event} e evento que genero el click del boton eliminar 
     */
    onEliminaUsuario = (e) => {
        let username = e.target.value;
        swal({
            title: `Eliminara el usuario: ${username}`,
            buttons: ["Cancelar", true],
            content: (<div className="d-none">
                <Button className="d-none" id="button_name" value={username}></Button>
            </div>),
            text: "¿Desea continuar?",
            icon: "warning"
        })
            .then((res) => {
                let name = document.getElementById('button_name').value;
                if (res === true) {
                    this.methodPOST_API(utility.url_elimina_usuario, 'elimina', { usuario: name })
                    this.methodGET_API(utility.url_paginacion, 'all_usuarios');
                }
            })
    }
    handlePage = (page_actual) => {
        this.setState({ page_actual }, () => { this.methodGET_API(utility.url_paginacion, 'pagination'); });
    }
    render() {
        return (
            <React.Fragment>
                <div className="overflow-auto tm-table">
                    <Table size="sm" id="tabla_usuario" bordered hover responsive>
                        <thead className="text-center header-table">
                            <tr>
                                <th>Nombre de Usuario</th>
                                <th>Correo Electrónico</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="body-table hover-gray">
                            {
                                this.state.pagination.results.map(usuario => {
                                    return (<tr key={usuario.username}>
                                        <td><p className="text-center pt-1 mb-0">{usuario.username}</p></td>
                                        <td><p className="text-center pt-1 mb-0">{usuario.email}</p></td>
                                        <td className="text-center">
                                            <label className="switch mt-1" htmlFor={usuario.username}>
                                                <input type="checkbox" id={usuario.username} defaultChecked={usuario.is_active}
                                                    value={usuario.is_active} onClick={this.onDesActUsuario} />
                                                <div className="slider round"></div>
                                            </label>
                                        </td>
                                        <td className="text-center">
                                            <Button color="danger" size="sm" value={usuario.username} onClick={this.onEliminaUsuario} >Eliminar</Button>
                                        </td>
                                    </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
                <Paginacion actual={this.state.page_actual} count={this.state.pagination.count} paginate={this.handlePage} />
            </React.Fragment>
        );
    }
}
export default TableUsu;