
import axios from "axios";
import React, { Component } from "react";
import * as message from "./messages/messages";
import SearchBar from "./SearchBar";
import TableSol from "./Table";
import * as utility from "./utils/utility";

class ComplementoPago extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      codigos_sat: [],
      acutalizar: 0,
      mostrar: 0,
      onback: false,
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  update = (cambio) => {
    this.setState({ acutalizar: cambio });
  };
  desglose_cuenta = (cambio) => {
    this.setState({ mostrar: cambio });
  };
  on_back = (cambio) => {
    this.setState({ onback: cambio });
  };
  render() {
    return (
      <div className="container-fluid">
        <SearchBar
          codigos_sat={this.state.codigos_sat}
          update={this.update}
          desglose_cuenta={this.desglose_cuenta}
          onback={this.state.onback}
          on_back={this.on_back}
        />
        <TableSol
          actualizar={this.state.acutalizar}
          update={this.update}
          mostrar={this.state.mostrar}
          desglose_cuenta={this.desglose_cuenta}
          on_back={this.on_back}
          onback={this.state.onback}
        />
      </div>
    );
  }
}
export default ComplementoPago;
