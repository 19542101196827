import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormText } from 'reactstrap';

function MyDropzone(props) {
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0)
      props.handleArchivo(acceptedFiles[0]);
  }, [props]);
  let extension = "";
  let i = 1;
  for (; i <= 366; i++)
    if (i !== 366)
      switch (i.toString().length) {
        case 1: extension += `.00${i}, `; break;
        case 2: extension += `.0${i}, `; break;
        case 3: extension += `.${i}, `; break;
        default: break;
      }
    else
      extension += `.${i}`;
  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: extension
  });
  return (
    <div {...getRootProps()} className="col-12 dropzone-container">
      <input {...getInputProps()} id="archivos" />
      <p className="mb-0 text-center">{
        isDragActive ?
          'Suelte sus archivos aquí' :
          'Seleccione o arraste sus archivos'
      }<br />
        <FormText color="muted" className="d-block">
          Sólo archivos M serán aceptados.
        </FormText>
      </p>
    </div>

  )
}
export default MyDropzone;