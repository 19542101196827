/*
Componente que permite abrir un modal para dar de consultar usuarios del sistema
*/
//import swal from '@sweetalert/with-react';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  CustomInput,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as message from "../messages/messages";
import MyInput from "../utils/MyInput";
import PermisosApp from "../utils/PermisosApp";
import * as utility from "../utils/utility";
import * as permisos from "../../../utils/permisos/permisos";

class ModalConsulta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      usuario_mod: "",
      email: "",
      conexion: "",
      creacion: "",
      activo: false,
      all_permisos: "",
      permisos_empleado: [],
      select_permisos: [],
      tipo_usuario: 0,
      grupos: [],
      password: "",
      password_r: "",
      password_mod: "",
      password_mod_r: "",
      password_ant: "",
      show_pass: false,
      show_pass_other: false,
      show_pass_ant: false,
      show_pass_new: false,
      show_pass_new_other: false,
      pass_equal: false,
      pass_equal_mod: false,
      input_mod: [true, true, true, true, true, true, true, true, true, true],
      grupos_checked: [false, false, false, false, false, false],
      data_modificacion: {},
      data_modificacion_mod: {},

      contabilidad_open: false,
      facturacion_open: false,
      bancos_open: false,
      operacion_open: false,
      catalogos_open: false,
      administracion_open: false,

      contabilidad_visible: false,
      facturacion_visible: false,
      bancos_visible: false,
      operacion_visible: false,
      catalogos_visible: false,
      administracion_visible: false,
    };
    this.contabilidad_toggle = this.contabilidad_toggle.bind(this);
    this.facturacion_toggle = this.facturacion_toggle.bind(this);
    this.bancos_toggle = this.bancos_toggle.bind(this);
    this.operacion_toggle = this.operacion_toggle.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_lista_permisos, "permisos");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  contabilidad_toggle = () => {
    this.setState((prevState) => ({
      contabilidad_open: !prevState.contabilidad_open,
      facturacion_open: false,
      bancos_open: false,
      operacion_open: false,
      administracion_open: false,
      catalogos_open: false,
    }));
  };
  facturacion_toggle = () => {
    this.setState((prevState) => ({
      facturacion_open: !prevState.facturacion_open,
      bancos_open: false,
      operacion_open: false,
      contabilidad_open: false,
      administracion_open: false,
      catalogos_open: false,
    }));
  };
  bancos_toggle = () => {
    this.setState((prevState) => ({
      bancos_open: !prevState.bancos_open,
      operacion_open: false,
      contabilidad_open: false,
      facturacion_open: false,
      administracion_open: false,
      catalogos_open: false,
    }));
  };
  operacion_toggle = () => {
    this.setState((prevState) => ({
      operacion_open: !prevState.operacion_open,
      contabilidad_open: false,
      facturacion_open: false,
      bancos_open: false,
      administracion_open: false,
      catalogos_open: false,
    }));
  };
  catalogos_toggle = () => {
    this.setState((prevState) => ({
      catalogos_open: !prevState.catalogos_open,
      operacion_open: false,
      contabilidad_open: false,
      facturacion_open: false,
      bancos_open: false,
      administracion_open: false,
    }));
  };
  administracion_toggle = () => {
    this.setState((prevState) => ({
      administracion_open: !prevState.administracion_open,
      catalogos_open: false,
      operacion_open: false,
      contabilidad_open: false,
      facturacion_open: false,
      bancos_open: false,
    }));
  };
  /*---------------------------------- Funciones para modificación -------------------------------------*/
  handleModifica = () => {
    if (document.getElementById("nombre_usuario_consulta").value.length === 0)
      message.message_modal(
        "Advertencia",
        "Debe tener un nombre de usuario",
        400
      );
    else {
      message
        .message_confimacion("¿Desea continuar con la operación modificar?")
        .then((res) => {
          if (res) {
            let data_modificacion = this.state.data_modificacion;
            if (Object.values(data_modificacion).length > 0) {
              data_modificacion["usuario"] = document.getElementById(
                "nombre_usuario_consulta"
              ).value;
              if (this.state.tipo_usuario === 0)
                this.methodPUT_API(
                  utility.url_alta_cliente,
                  "modificar",
                  data_modificacion
                );
              else if (this.state.tipo_usuario === 1)
                this.methodPUT_API(
                  utility.url_alta_usuario,
                  "modificar",
                  data_modificacion
                );
              else
                this.methodPUT_API(
                  utility.url_alta_administrador,
                  "modificar",
                  data_modificacion
                );
            } else
              message.message_modal(
                "Advertencia",
                "No hay modificaciones",
                400
              );
          }
        });
    }
  };
  handleCorreo = (e) => {
    let email = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["email"] = email;
    this.setState({ email, data_modificacion });
  };
  handlePassword = (e) => {
    let password = e.target.value;
    let password_r = this.state.password_r;
    let pass_equal = this.state.pass_equal;
    let data_modificacion = this.state.data_modificacion;
    if (password === password_r) {
      data_modificacion["password"] = password;
      pass_equal = true;
    } else {
      delete data_modificacion.password;
      pass_equal = false;
    }
    this.setState({ pass_equal, password, data_modificacion });
  };
  handlePasswordR = (e) => {
    let password_r = e.target.value;
    let password = this.state.password;
    let pass_equal = this.state.pass_equal;
    let data_modificacion = this.state.data_modificacion;
    if (password_r === password) {
      data_modificacion["password"] = password_r;
      pass_equal = true;
    } else {
      delete data_modificacion.password;
      pass_equal = false;
    }
    this.setState({ pass_equal, password_r, data_modificacion });
  };
  handlePassword_MOD = (e) => {
    let password_mod = e.target.value;
    let password_mod_r = this.state.password_mod_r;
    let pass_equal_mod = this.state.pass_equal_mod;
    let data_modificacion_mod = this.state.data_modificacion_mod;
    if (password_mod === password_mod_r) {
      data_modificacion_mod["new_password"] = password_mod;
      pass_equal_mod = true;
    } else {
      delete data_modificacion_mod.password;
      pass_equal_mod = false;
    }
    this.setState({ pass_equal_mod, password_mod, data_modificacion_mod });
  };
  handlePasswordR_MOD = (e) => {
    let password_mod_r = e.target.value;
    let password_mod = this.state.password_mod;
    let pass_equal_mod = this.state.pass_equal_mod;
    let data_modificacion_mod = this.state.data_modificacion_mod;
    if (password_mod_r === password_mod) {
      data_modificacion_mod["new_password"] = password_mod_r;
      pass_equal_mod = true;
    } else {
      delete data_modificacion_mod.password;
      pass_equal_mod = false;
    }
    this.setState({ pass_equal_mod, password_mod_r, data_modificacion_mod });
  };
  handlePassword_ANT = (e) => {
    let password_ant = e.target.checked;
    let data_modificacion_mod = this.state.data_modificacion_mod;
    data_modificacion_mod["password"] = password_ant;
    this.setState({ password_ant, data_modificacion_mod });
  };
  handleCerrar = () => {
    this.setState({
      usuario_mod: "",
      email: "",
      conexion: "",
      creacion: "",
      activo: false,
      permisos_empleado: [],
      select_permisos: [],
      tipo_usuario: 0,
      grupos: [],
      password: "",
      password_r: "",
      password_mod: "",
      password_mod_r: "",
      password_ant: "",
      show_pass: false,
      show_pass_other: false,
      show_pass_ant: false,
      show_pass_new: false,
      show_pass_new_other: false,
      pass_equal: false,
      pass_equal_mod: false,
      input_mod: [true, true, true, true, true, true, true, true, true, true],
      grupos_checked: [false, false, false, false, false, false],
      data_modificacion: {},
      data_modificacion_mod: {},

      contabilidad_open: false,
      facturacion_open: false,
      bancos_open: false,
      operacion_open: false,
      catalogos_open: false,
      administracion_open: false,

      contabilidad_visible: false,
      facturacion_visible: false,
      bancos_visible: false,
      operacion_visible: false,
      catalogos_visible: false,
      administracion_visible: false,
    });
    this.props.toogleConsulta();
  };
  /* ----------------------------------------- Funciones para el tratamiento del DOM ----------------------------------------- */
  contabilidadVisible = (e) => {
    let switch_contabilidad = e.target.checked;
    let data_modificacion = this.state.data_modificacion;
    let grupos = this.state.grupos;
    let index = 0;
    if (switch_contabilidad === false) {
      document.getElementById("contabilidad_visible").style.display = "none";
      index = grupos.indexOf(1);
      grupos.splice(index, 1);
      data_modificacion["grupos"] = grupos;
      this.setState({ contabilidad_visible: false, data_modificacion });
    } else {
      document.getElementById("contabilidad_visible").style.display = "flex";
      grupos.push(1);
      data_modificacion["grupos"] = grupos;
      this.setState({ contabilidad_visible: true, data_modificacion });
    }
  };
  facturacionVisible = (e) => {
    let switch_facturacion = e.target.checked;
    let data_modificacion = this.state.data_modificacion;
    let grupos = this.state.grupos;
    let index = 0;
    if (switch_facturacion === false) {
      document.getElementById("facturacion_visible").style.display = "none";
      index = grupos.indexOf(2);
      grupos.splice(index, 1);
      data_modificacion["grupos"] = grupos;
      this.setState({ facturacion_visible: false, data_modificacion });
    } else {
      document.getElementById("facturacion_visible").style.display = "flex";
      grupos.push(2);
      data_modificacion["grupos"] = grupos;
      this.setState({ facturacion_visible: true, data_modificacion });
    }
  };
  bancosVisible = (e) => {
    let switch_bancos = e.target.checked;
    let data_modificacion = this.state.data_modificacion;
    let grupos = this.state.grupos;
    let index = 0;
    if (switch_bancos === false) {
      document.getElementById("bancos_visible").style.display = "none";
      index = grupos.indexOf(3);
      grupos.splice(index, 1);
      data_modificacion["grupos"] = grupos;
      this.setState({ bancos_visible: false, data_modificacion });
    } else {
      document.getElementById("bancos_visible").style.display = "flex";
      grupos.push(3);
      data_modificacion["grupos"] = grupos;
      this.setState({ bancos_visible: true, data_modificacion });
    }
  };
  operacionVisible = (e) => {
    let switch_operacion = e.target.checked;
    let data_modificacion = this.state.data_modificacion;
    let grupos = this.state.grupos;
    let index = 0;
    if (switch_operacion === false) {
      document.getElementById("operacion_visible").style.display = "none";
      index = grupos.indexOf(4);
      grupos.splice(index, 1);
      data_modificacion["grupos"] = grupos;
      this.setState({ operacion_visible: false, data_modificacion });
    } else {
      document.getElementById("operacion_visible").style.display = "flex";
      grupos.push(4);
      data_modificacion["grupos"] = grupos;
      this.setState({ operacion_visible: true, data_modificacion });
    }
  };
  catalogosVisible = (e) => {
    let switch_catalogos = e.target.checked;
    let data_modificacion = this.state.data_modificacion;
    let grupos = this.state.grupos;
    let index = 0;
    if (switch_catalogos === false) {
      document.getElementById("catalogos_visible").style.display = "none";
      index = grupos.indexOf(5);
      grupos.splice(index, 1);
      data_modificacion["grupos"] = grupos;
      this.setState({ catalogos_visible: false, data_modificacion });
    } else {
      document.getElementById("catalogos_visible").style.display = "flex";
      grupos.push(5);
      data_modificacion["grupos"] = grupos;
      this.setState({ catalogos_visible: true, data_modificacion });
    }
  };
  administracionVisible = (e) => {
    let switch_catalogos = e.target.checked;
    let data_modificacion = this.state.data_modificacion;
    let grupos = this.state.grupos;
    let index = 0;
    if (switch_catalogos === false) {
      document.getElementById("administracion_visible").style.display = "none";
      index = grupos.indexOf(7);
      grupos.splice(index, 1);
      data_modificacion["grupos"] = grupos;
      this.setState({ administracion_visible: false });
    } else {
      document.getElementById("administracion_visible").style.display = "flex";
      grupos.push(7);
      data_modificacion["grupos"] = grupos;
      this.setState({ administracion_visible: true });
    }
  };
  handleConsulta = () => {
    let data = {
      usuario: document.getElementById("nombre_usuario_consulta").value,
    };
    this.methodPOST_API(utility.url_consulta_usuario, "consulta", data);
    utility.add_class_disabled("nombre_usuario_modificador_consulta");
    utility.add_class_disabled("email_usuario_consulta");
    utility.add_class_disabled("ultima_conexion_usuario_consulta");
    utility.add_class_disabled("creacion_usuario_consulta");
  };
  /**
   * Función que permite agregar permisos a la data general de alta
   * @param {String} permiso Número de permiso
   */
  agregaPermisos = (permiso) => {
    let select_permisos = this.state.select_permisos;
    let data_modificacion = this.state.data_modificacion;
    select_permisos.push(permiso);
    data_modificacion["permisos"] = select_permisos;
    this.setState({ select_permisos, data_modificacion });
  };
  /**
   * Función que permite eliminar permisos a la data general de alta
   * @param {String} permiso Número de permiso
   */
  eliminaPermisos = (permiso) => {
    let select_permisos = this.state.select_permisos;
    let indice = select_permisos.indexOf(permiso);
    let data_modificacion = this.state.data_modificacion;
    select_permisos.splice(indice, 1);
    data_modificacion["permisos"] = select_permisos;
    this.setState({ select_permisos, data_modificacion });
  };
  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "permisos":
              if (this._isMounted)
                this.setState({
                  all_permisos: response.data.results,
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPUT_API = (url, state, data) => {
    let url_intern = url;
    if (
      this.state.pass_equal === false &&
      this.state.password.length > 0 &&
      this.state.password_r.length > 0
    ) {
      message.message_modal("Advertencia", "Las contraseñas no coinciden", 400);
      return null;
    }
    if (
      this.state.pass_equal_mod === false &&
      this.state.password_mod.length > 0 &&
      this.state.password_mod_r.length > 0
    ) {
      message.message_modal(
        "Advertencia",
        "Las contraseñas modificadoras no coinciden",
        400
      );
      return null;
    }
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "modificar":
              this.handleCerrar();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma POST
   * @param {String} url URL a consultar
   * @param {String} state Onpción del case
   * @param {Object} data Objeto a mandar atraves del metodo POST
   */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta":
              if (this._isMounted) {
                let grupos = response.data.results_usr[0].groups;
                this.setState(
                  {
                    usuario_mod: response.data.results_mod.username,
                    email: response.data.results_usr[0].email,
                    conexion: response.data.results_usr[0].date_joined.slice(
                      0,
                      10
                    ),
                    creacion: response.data.results_usr[0].last_login.slice(
                      0,
                      10
                    ),
                    activo: response.data.results_usr[0].is_active,
                    permisos_empleado: response.data.results_perm,
                    facturacion_visible: grupos.includes(2),
                    contabilidad_visible: grupos.includes(1),
                    operacion_visible: grupos.includes(4),
                    bancos_visible: grupos.includes(3),
                    catalogos_visible: grupos.includes(5),
                    administracion_visible: grupos.includes(7),
                    input_mod: [true, true, true, true, true, false, false],
                    grupos,
                    tipo_usuario: response.data.results_usr[0].tipo_usuario,
                  },
                  () => {
                    if (this.state.tipo_usuario === 1) {
                      if (this.state.contabilidad_visible)
                        document.getElementById(
                          "contabilidad_visible"
                        ).style.display = "flex";
                      if (this.state.operacion_visible)
                        document.getElementById(
                          "operacion_visible"
                        ).style.display = "flex";
                      if (this.state.facturacion_visible)
                        document.getElementById(
                          "facturacion_visible"
                        ).style.display = "flex";
                      if (this.state.bancos_visible)
                        document.getElementById(
                          "bancos_visible"
                        ).style.display = "flex";
                      if (this.state.catalogos_visible)
                        document.getElementById(
                          "catalogos_visible"
                        ).style.display = "flex";
                      if (this.state.administracion_visible)
                        document.getElementById(
                          "administracion_visible"
                        ).style.display = "flex";
                    }
                  }
                );
              }
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>Consulta Usuario</ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-10 col-8"
                id="nombre_usuario_consulta"
                nameLabel="Nombre de usuario"
              />
              <Button
                color="primary"
                className="col-lg-2 col-4 mb-2"
                onClick={this.handleConsulta}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="nombre_usuario_modificador_consulta"
                nameLabel="Usuario modificador"
                value={this.state.usuario_mod}
                disabled={this.state.input_mod[0]}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="email_usuario_consulta"
                nameLabel="Correo electrónico"
                value={this.state.email}
                onChange={this.handleCorreo}
                disabled={this.state.input_mod[1]}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="ultima_conexion_usuario_consulta"
                nameLabel="Ultima conexión"
                value={this.state.conexion}
                disabled={this.state.input_mod[2]}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="creacion_usuario_consulta"
                nameLabel="Fecha de creación"
                value={this.state.creacion}
                disabled={this.state.input_mod[3]}
              />
              <div className="col-lg-2 d-flex justify-content-center align-items-center">
                <p className="mb-1">Activo:</p>&nbsp;&nbsp;
                <label
                  className="switch mt-1"
                  htmlFor="activo_usuario_consulta"
                >
                  <input
                    type="checkbox"
                    id="activo_usuario_consulta"
                    disabled={this.state.input_mod[4]}
                    checked={this.state.activo}
                  />
                  <div className="slider round"></div>
                </label>
              </div>
            </FormGroup>
            <FormGroup className="row mb-0">
              <div className="grupo col-lg-6 p-0">
                <input
                  type={this.state.show_pass === false ? "password" : "text"}
                  className="input-animated col-12 mb-2"
                  id="password_nueva"
                  value={this.state.password}
                  onChange={this.handlePassword}
                  disabled={this.state.input_mod[5]}
                  required
                />
                <span className="barra"></span>
                <br />
                <label className="label-animated" htmlFor="">
                  Nueva contraseña
                </label>
                <Button
                  color="link"
                  style={{ position: "absolute", top: 0, right: 0 }}
                  disabled={this.state.input_mod[5]}
                  onClick={(e) => {
                    let tipo = document.getElementById("password_nueva");
                    if (tipo.type === "password") {
                      tipo.type = "text";
                      this.setState({ show_pass: true });
                    } else {
                      tipo.type = "password";
                      this.setState({ show_pass: false });
                    }
                  }}
                >
                  {this.state.show_pass === false ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </Button>
              </div>
              <div className="grupo col-lg-6 p-0">
                <input
                  type={
                    this.state.show_pass_other === false ? "password" : "text"
                  }
                  className="input-animated col-12 mb-2"
                  id="password_nueva_rep"
                  value={this.state.password_r}
                  onChange={this.handlePasswordR}
                  required
                  disabled={this.state.input_mod[6]}
                />
                <span className="barra"></span>
                <br />
                <label className="label-animated" htmlFor="">
                  Repetir nueva contraseña
                </label>
                <Button
                  color="link"
                  style={{ position: "absolute", top: 0, right: 0 }}
                  disabled={this.state.input_mod[6]}
                  onClick={(e) => {
                    let tipo = document.getElementById("password_nueva_rep");
                    if (tipo.type === "password") {
                      tipo.type = "text";
                      this.setState({ show_pass_other: true });
                    } else {
                      tipo.type = "password";
                      this.setState({ show_pass_other: false });
                    }
                  }}
                >
                  {this.state.show_pass_other === false ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </Button>
              </div>
            </FormGroup>
            <FormGroup className="row mb-0">
              <div className="grupo col-lg-4 p-0">
                <input
                  type={this.state.show_pass === false ? "password" : "text"}
                  className="input-animated col-12 mb-2"
                  id="password_mod_vieja"
                  required
                  disabled={this.state.input_mod[7]}
                />
                <span className="barra"></span>
                <br />
                <label className="label-animated" htmlFor="">
                  Anterior contraseña modificador
                </label>
                <Button
                  color="link"
                  style={{ position: "absolute", top: 0, right: 0 }}
                  disabled={this.state.input_mod[7]}
                  onClick={(e) => {
                    let tipo = document.getElementById("password_mod_vieja");
                    if (tipo.type === "password") {
                      tipo.type = "text";
                      this.setState({ show_pass_ant: true });
                    } else {
                      tipo.type = "password";
                      this.setState({ show_pass_ant: false });
                    }
                  }}
                >
                  {this.state.show_pass_ant === false ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </Button>
              </div>
              <div className="grupo col-lg-4 p-0">
                <input
                  type={this.state.show_pass === false ? "password" : "text"}
                  className="input-animated col-12 mb-2"
                  id="password_mod_nueva"
                  value={this.state.password_mod}
                  onChange={this.handlePassword_MOD}
                  required
                  disabled={this.state.input_mod[8]}
                />
                <span className="barra"></span>
                <br />
                <label className="label-animated" htmlFor="">
                  Nueva contraseña modificador
                </label>
                <Button
                  color="link"
                  style={{ position: "absolute", top: 0, right: 0 }}
                  disabled={this.state.input_mod[8]}
                  onClick={(e) => {
                    let tipo = document.getElementById("password_mod_nueva");
                    if (tipo.type === "password") {
                      tipo.type = "text";
                      this.setState({ show_pass_new: true });
                    } else {
                      tipo.type = "password";
                      this.setState({ show_pass_new: false });
                    }
                  }}
                >
                  {this.state.show_pass_new === false ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </Button>
              </div>
              <div className="grupo col-lg-4 p-0">
                <input
                  type={
                    this.state.show_pass_other === false ? "password" : "text"
                  }
                  className="input-animated col-12 mb-2"
                  id="password_mod_nueva_rep"
                  value={this.state.password_mod_r}
                  onChange={this.handlePasswordR_MOD}
                  required
                  disabled={this.state.input_mod[9]}
                />
                <span className="barra"></span>
                <br />
                <label className="label-animated" htmlFor="">
                  Repetir nueva contraseña modificador
                </label>
                <Button
                  color="link"
                  style={{ position: "absolute", top: 0, right: 0 }}
                  disabled={this.state.input_mod[9]}
                  onClick={(e) => {
                    let tipo = document.getElementById(
                      "password_mod_nueva_rep"
                    );
                    if (tipo.type === "password") {
                      tipo.type = "text";
                      this.setState({ show_pass_new_other: true });
                    } else {
                      tipo.type = "password";
                      this.setState({ show_pass_new_other: false });
                    }
                  }}
                >
                  {this.state.show_pass_new_other === false ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </Button>
              </div>
            </FormGroup>
            {this.state.tipo_usuario === 0 ||
            this.state.tipo_usuario === 2 ? null : (
              <div>
                <h4 className="mb-3 mt-1 text-center">
                  Áreas y permisos del usuario:
                </h4>
                <div className="row d-flex justify-content-around mb-2 flex-lg-row flex-sm-column align-items-center">
                  <CustomInput
                    type="switch"
                    id="facturacion_open"
                    name="customSwitch"
                    label="Facturación y Cobranza"
                    checked={this.state.facturacion_visible}
                    onClick={this.facturacionVisible}
                  />
                  <CustomInput
                    type="switch"
                    className="col-12 col-lg-1 text-center"
                    id="contabilidad_open"
                    name="customSwitch"
                    label="Contabilidad"
                    checked={this.state.contabilidad_visible}
                    onClick={this.contabilidadVisible}
                  />
                  <CustomInput
                    type="switch"
                    className="col-12 col-lg-1 text-center"
                    id="operacion_open"
                    name="customSwitch"
                    label="Operación"
                    checked={this.state.operacion_visible}
                    onClick={this.operacionVisible}
                  />
                  <CustomInput
                    type="switch"
                    className="col-12 col-lg-1 text-center"
                    id="bancos_open"
                    name="customSwitch"
                    label="Bancos"
                    checked={this.state.bancos_visible}
                    onClick={this.bancosVisible}
                  />
                  <CustomInput
                    type="switch"
                    className="col-12 col-lg-1 text-center"
                    id="catalogos_open"
                    name="customSwitch"
                    label="Catalogos"
                    checked={this.state.catalogos_visible}
                    onClick={this.catalogosVisible}
                  />
                  <CustomInput
                    type="switch"
                    className="col-12 col-lg-1 text-center"
                    id="administracion_open"
                    name="customSwitch"
                    label="Administración"
                    checked={this.state.administracion_visible}
                    onClick={this.administracionVisible}
                  />
                </div>
                <Card
                  className="rounded-0 row"
                  id="contabilidad_visible"
                  style={{ display: "none" }}
                >
                  <CardHeader
                    className="border-bottom-0"
                    onClick={this.contabilidad_toggle}
                  >
                    Contabilidad
                  </CardHeader>
                  <Collapse
                    isOpen={this.state.contabilidad_open}
                    style={{ height: "387px" }}
                    className="overflow-auto"
                  >
                    <CardBody className="border-top">
                      <PermisosApp
                        name_app="Polizas"
                        permisos={utility.permisos_for_app(
                          "polizas",
                          this.state.all_permisos
                        )}
                        permisos_usuario={utility.permisos_for_app(
                          "polizas",
                          this.state.permisos_empleado
                        )}
                        grupo={this.state.contabilidad_visible}
                        agregaPermisos={this.agregaPermisos}
                        eliminaPermisos={this.eliminaPermisos}
                      />
                      {/* <PermisosApp name_app="Balance" /> Falta desarrollar app */}
                      <PermisosApp
                        name_app="Cuentas Auxiliares"
                        permisos={utility.permisos_for_app(
                          "cuentas_auxiliares",
                          this.state.all_permisos
                        )}
                        permisos_usuario={utility.permisos_for_app(
                          "cuentas_auxiliares",
                          this.state.permisos_empleado
                        )}
                        grupo={this.state.contabilidad_visible}
                        agregaPermisos={this.agregaPermisos}
                        eliminaPermisos={this.eliminaPermisos}
                      />
                      {/* <PermisosApp name_app="Estado de Resultados" /> Falta desarrollar app */}
                      <PermisosApp
                        name_app="Clientes"
                        permisos={utility.permisos_for_app(
                          "clientes",
                          this.state.all_permisos
                        )}
                        permisos_usuario={utility.permisos_for_app(
                          "clientes",
                          this.state.permisos_empleado
                        )}
                        grupo={this.state.contabilidad_visible}
                        agregaPermisos={this.agregaPermisos}
                        eliminaPermisos={this.eliminaPermisos}
                      />
                      {/* <PermisosApp name_app="Balanza de Comprobación" /> Falta desarrollar app */}
                      <PermisosApp
                        name_app="Proveedores"
                        permisos={utility.permisos_for_app(
                          "proveedores",
                          this.state.all_permisos
                        )}
                        permisos_usuario={utility.permisos_for_app(
                          "proveedores",
                          this.state.permisos_empleado
                        )}
                        grupo={this.state.contabilidad_visible}
                        agregaPermisos={this.agregaPermisos}
                        eliminaPermisos={this.eliminaPermisos}
                      />
                      <PermisosApp
                        name_app="Historico Polizas"
                        permisos={utility.permisos_for_app(
                          "historico_poliza",
                          this.state.all_permisos
                        )}
                        permisos_usuario={utility.permisos_for_app(
                          "historico_poliza",
                          this.state.permisos_empleado
                        )}
                        grupo={this.state.contabilidad_visible}
                        agregaPermisos={this.agregaPermisos}
                        eliminaPermisos={this.eliminaPermisos}
                      />
                    </CardBody>
                  </Collapse>
                </Card>
                <Card
                  className="rounded-0 row"
                  id="facturacion_visible"
                  style={{ display: "none" }}
                >
                  <CardHeader
                    className="border-bottom-0"
                    onClick={this.facturacion_toggle}
                  >
                    Facturación
                  </CardHeader>
                  <Collapse
                    isOpen={this.state.facturacion_open}
                    style={{ height: "214px" }}
                    className="overflow-auto"
                  >
                    <CardBody className="border-top">
                      {/* <PermisosApp name_app="Cuenta de Gastos" />
                                                            <PermisosApp name_app="Pago de Cuentas" />
                                                            <PermisosApp name_app="Cartera" />
                                                            <PermisosApp name_app="Historico Facturación" /> */}
                    </CardBody>
                  </Collapse>
                </Card>
                <Card
                  className="rounded-0 row"
                  id="bancos_visible"
                  style={{ display: "none" }}
                >
                  <CardHeader
                    className="border-bottom-0"
                    onClick={this.bancos_toggle}
                  >
                    Bancos
                  </CardHeader>
                  <Collapse
                    isOpen={this.state.bancos_open}
                    style={{ height: "257px" }}
                    className="overflow-auto"
                  >
                    <CardBody className="border-top">
                      <PermisosApp
                        name_app="Solicitud de Anticipo"
                        permisos={utility.permisos_for_app(
                          "solicitud_anticipo",
                          this.state.all_permisos
                        )}
                        permisos_usuario={utility.permisos_for_app(
                          "solicitud_anticipo",
                          this.state.permisos_empleado
                        )}
                        grupo={this.state.bancos_visible}
                        agregaPermisos={this.agregaPermisos}
                        eliminaPermisos={this.eliminaPermisos}
                      />
                      <PermisosApp
                        name_app="Anticipo"
                        permisos={utility.permisos_for_app(
                          "anticipo",
                          this.state.all_permisos
                        )}
                        permisos_usuario={utility.permisos_for_app(
                          "anticipo",
                          this.state.permisos_empleado
                        )}
                        grupo={this.state.bancos_visible}
                        agregaPermisos={this.agregaPermisos}
                        eliminaPermisos={this.eliminaPermisos}
                      />
                      {/* <PermisosApp name_app="Cheques" />
                                                            <PermisosApp name_app="Consilicación Bancaria" /> */}
                      <PermisosApp
                        name_app="Hitorico Solicitud de Anticipos"
                        permisos={utility.permisos_for_app(
                          "solicitud_anticipo_historico",
                          this.state.all_permisos
                        )}
                        permisos_usuario={utility.permisos_for_app(
                          "solicitud_anticipo_historico",
                          this.state.permisos_empleado
                        )}
                        grupo={this.state.bancos_visible}
                        agregaPermisos={this.agregaPermisos}
                        eliminaPermisos={this.eliminaPermisos}
                      />
                      <PermisosApp
                        name_app="Hitorico Anticipos"
                        permisos={utility.permisos_for_app(
                          "anticipo_historico",
                          this.state.all_permisos
                        )}
                        permisos_usuario={utility.permisos_for_app(
                          "anticipo_historico",
                          this.state.permisos_empleado
                        )}
                        grupo={this.state.bancos_visible}
                        agregaPermisos={this.agregaPermisos}
                        eliminaPermisos={this.eliminaPermisos}
                      />
                    </CardBody>
                  </Collapse>
                </Card>
                <Card
                  className="rounded-0 row"
                  id="operacion_visible"
                  style={{ display: "none" }}
                >
                  <CardHeader
                    className="border-bottom-0"
                    onClick={this.operacion_toggle}
                  >
                    Operación
                  </CardHeader>
                  <Collapse
                    isOpen={this.state.operacion_open}
                    style={{ height: "214px" }}
                    className="overflow-auto"
                  >
                    <CardBody className="border-top">
                      <PermisosApp
                        name_app="Notificación de Embarque"
                        permisos={utility.permisos_for_app(
                          "notificacion",
                          this.state.all_permisos
                        )}
                        permisos_usuario={utility.permisos_for_app(
                          "notificacion",
                          this.state.permisos_empleado
                        )}
                        grupo={this.state.operacion_visible}
                        agregaPermisos={this.agregaPermisos}
                        eliminaPermisos={this.eliminaPermisos}
                      />
                      {/* <PermisosApp name_app="Trafico" />
                                                            <PermisosApp name_app="Comprobación de Pedimento" /> */}
                      <PermisosApp
                        name_app="Historico Notificación"
                        permisos={utility.permisos_for_app(
                          "notificacion_historico",
                          this.state.all_permisos
                        )}
                        permisos_usuario={utility.permisos_for_app(
                          "notificacion_historico",
                          this.state.permisos_empleado
                        )}
                        grupo={this.state.operacion_visible}
                        agregaPermisos={this.agregaPermisos}
                        eliminaPermisos={this.eliminaPermisos}
                      />
                    </CardBody>
                  </Collapse>
                </Card>
                <Card
                  className="rounded-0 row"
                  id="catalogos_visible"
                  style={{ display: "none" }}
                >
                  <CardHeader
                    className="border-bottom-0"
                    onClick={this.catalogos_toggle}
                  >
                    Catalogos
                  </CardHeader>
                  <Collapse
                    isOpen={this.state.catalogos_open}
                    style={{ height: "214px" }}
                    className="overflow-auto"
                  >
                    <CardBody className="border-top">
                      <PermisosApp
                        name_app="Consolidadoras"
                        permisos={utility.permisos_for_app(
                          "consolidadoras",
                          this.state.all_permisos
                        )}
                        permisos_usuario={utility.permisos_for_app(
                          "consolidadoras",
                          this.state.permisos_empleado
                        )}
                        grupo={this.state.catalogos_visible}
                        agregaPermisos={this.agregaPermisos}
                        eliminaPermisos={this.eliminaPermisos}
                      />
                      <PermisosApp
                        name_app="Patentes"
                        permisos={utility.permisos_for_app(
                          "patentes",
                          this.state.all_permisos
                        )}
                        permisos_usuario={utility.permisos_for_app(
                          "patentes",
                          this.state.permisos_empleado
                        )}
                        grupo={this.state.catalogos_visible}
                        agregaPermisos={this.agregaPermisos}
                        eliminaPermisos={this.eliminaPermisos}
                      />
                      <PermisosApp
                        name_app="Contabilidad Electronica"
                        permisos={utility.permisos_for_app(
                          "contabilidad_electronica",
                          this.state.all_permisos
                        )}
                        permisos_usuario={utility.permisos_for_app(
                          "contabilidad_electronica",
                          this.state.permisos_empleado
                        )}
                        grupo={this.state.catalogos_visible}
                        agregaPermisos={this.agregaPermisos}
                        eliminaPermisos={this.eliminaPermisos}
                      />
                    </CardBody>
                  </Collapse>
                </Card>
                <Card
                  className="rounded-0 row"
                  id="administracion_visible"
                  style={{ display: "none" }}
                >
                  <CardHeader
                    className="border-bottom-0"
                    onClick={this.administracion_toggle}
                  >
                    Administración
                  </CardHeader>
                  <Collapse
                    isOpen={this.state.administracion_open}
                    style={{ height: "214px" }}
                    className="overflow-auto"
                  >
                    <CardBody className="border-top">
                      <PermisosApp
                        name_app="Usuarios"
                        permisos={utility.permisos_for_app(
                          "usuarios",
                          this.state.all_permisos
                        )}
                        permisos_usuario={utility.permisos_for_app(
                          "usuarios",
                          this.state.permisos_empleado
                        )}
                        grupo={this.state.administracion_visible}
                        agregaPermisos={this.agregaPermisos}
                        eliminaPermisos={this.eliminaPermisos}
                      />
                      <PermisosApp
                        name_app="Departamentos"
                        permisos={utility.permisos_for_app(
                          "departamentos",
                          this.state.all_permisos
                        )}
                        permisos_usuario={utility.permisos_for_app(
                          "departamentos",
                          this.state.permisos_empleado
                        )}
                        grupo={this.state.administracion_visible}
                        agregaPermisos={this.agregaPermisos}
                        eliminaPermisos={this.eliminaPermisos}
                      />
                      <PermisosApp
                        name_app="Empelados"
                        permisos={utility.permisos_for_app(
                          "empleados",
                          this.state.all_permisos
                        )}
                        permisos_usuario={utility.permisos_for_app(
                          "empleados",
                          this.state.permisos_empleado
                        )}
                        grupo={this.state.administracion_visible}
                        agregaPermisos={this.agregaPermisos}
                        eliminaPermisos={this.eliminaPermisos}
                      />
                      <PermisosApp
                        name_app="Puestos"
                        permisos={utility.permisos_for_app(
                          "puestos",
                          this.state.all_permisos
                        )}
                        permisos_usuario={utility.permisos_for_app(
                          "puestos",
                          this.state.permisos_empleado
                        )}
                        grupo={this.state.administracion_visible}
                        agregaPermisos={this.agregaPermisos}
                        eliminaPermisos={this.eliminaPermisos}
                      />
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={this.handleModifica}
            disabled={permisos.obtener_permiso_especifico(
              "modificar",
              this.props.permisos
            )}
          >
            Modificar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalConsulta;
