import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import App from './App';
import * as serviceWorker from './serviceWorker';
import authReducer from './utils/reducers/auth';
import notifyReducer from './utils/reducers/notify';
import messageReducer from './utils/reducers/message';
import { ToastProvider } from 'react-toast-notifications';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

function configureStore() {
    const rootReducer = combineReducers({
        auth: authReducer,
        notify: notifyReducer,
        message: messageReducer,
    });
    const store = createStore(
        rootReducer,
        composeEnhances(applyMiddleware(thunk))
    );

    return store;
}

const store = configureStore();

const app = (
    <Provider store={store}>
        <ToastProvider placement="bottom-right" autoDismissTimeout="8000" >
            <App />
        </ToastProvider>
    </Provider>
);
ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
