import axios from "axios";
import React, { Component } from "react";
import { Spinner, Table, Button } from "reactstrap";
import "../../assets/style.css";
import * as message from "./messages/messages";
import Paginacion from "../Pagination";
import ConsultaEspecifica from "./SearchSpecific";
import * as utility from "./utils/utility";
import swal from "@sweetalert/with-react";
import * as permisos from "../../utils/permisos/permisos";
import { faFileDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WebSocketInstance from "../../utils/websocket";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class TablePagoTerceros extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      pagination: { count: 1, results: [] },
      page_actual: 1,
      ant_checked: [],
    };
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  /* sendNotificacionHandler = (motivo, data, cliente) => {
        const notificacionObject = {
            tipo: 0,
            aplicacion: 'anticipo',
            motivo: motivo,
            folio: data
        }
        if (motivo === 'devolver' && this.props.on_cliente === false)
            notificacionObject['cliente'] = [parseInt(cliente)];
        WebSocketInstance.newNotificacion(notificacionObject);
    } */
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_paginacion, "pagination");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps) {
    if (this.props.actualizar !== prevProps.actualizar) {
      this.handlePage(this.state.page_actual);
      this.props.update(0);
    }
    if (this.props.actualizar_con_dev !== prevProps.actualizar_con_dev) {
      this.handlePage(this.state.page_actual);
      for (let i = 0; i < this.state.ant_checked.length; i++)
        document.getElementById(
          `ant_${this.state.ant_checked[i].numero}`
        ).checked = false;
      this.setState({ ant_checked: [] }, () => {
        this.props.checked(this.state.ant_checked);
      });

      this.props.update_con_dev(0);
    }
  }
  handlePage = (page_actual) => {
    this.setState({ page_actual }, () => {
      this.methodGET_API(utility.url_paginacion, "pagination");
    });
  };
  handleDevolver = (e) => {
    swal({
      icon: "warning",
      closeOnClickOutside: false,
      title: "¿Desea continuar con la operación devolución?",
      text: "Ingrese su contraseña y observaciones",
      buttons: ["Cancelar", true],
      dangerMode: true,
      content: (
        <div>
          <button
            value={e.target.value}
            id="datos_devolucion_boton"
            style={{ display: "none" }}
          ></button>
          <div className="grupo col-lg-12 p-0">
            <input
              type="text"
              className="input-animated input-animatedd col-12 mb-2"
              id="user_devolucion_boton"
              value={`${localStorage.getItem("user_name")}Admin`}
              disabled
              required
            />
            <span className="barra"></span>
            <br />
            <label className="label-animated" htmlFor="user_devolucion_boton">
              Usuario
            </label>
          </div>
          <div className="grupo col-lg-12 p-0">
            <input
              type="password"
              className="input-animated col-12 mb-2"
              id="pass_devolucion_boton"
              required
            />
            <span className="barra"></span>
            <br />
            <label className="label-animated" htmlFor="pass_devolucion_boton">
              Contraseña
            </label>
          </div>
          <div className="grupo col-lg-12 p-0" style={{ height: "90px" }}>
            <textarea
              className="input-animated col-12"
              id="observacion_devolucion_boton"
              style={{ resize: "none" }}
              required
            ></textarea>
            <span className="barra"></span>
            <br />
            <label
              className="label-animated"
              htmlFor="observacion_devolucion_boton"
            >
              Observaciones
            </label>
          </div>
        </div>
      ),
    }).then((res) => {
      if (res === true) {
        let mov = {
          pass: document.getElementById("pass_devolucion_boton").value,
          user: document.getElementById("user_devolucion_boton").value,
          observacion: document.getElementById("observacion_devolucion_boton")
            .value,
          data: [
            document
              .getElementById("datos_devolucion_boton")
              .value.split("_")[0],
          ],
        };
        axios
          .post(utility.url_verify, { usuario: mov.user, password: mov.pass })
          .then((response) => {
            if (response.status >= 200 && response.status < 400) {
              let numeros = mov.data;
              const modifica_POST = () => {
                return axios.post(utility.url_devuelve, {
                  numeros: numeros,
                  observaciones: mov.observacion,
                });
              };

              axios
                .all([modifica_POST()])
                .then(
                  axios.spread((post) => {
                    this.methodPDF_API(
                      utility.url_pdf_alta_anticipo,
                      "devolucion",
                      mov.data[0]
                    );
                    //this.sendNotificacionHandler('devolver', mov.data, document.getElementById('datos_devolucion_boton').value.split('_')[1]);
                    this.handlePage(this.state.page_actual);
                    swal({
                      icon: "success",
                      title: post.data.title,
                      text: post.data.text,
                      button: true,
                      content: (
                        <div>
                          <LinkD
                            href=""
                            id="dowload_pdf_devolucion_anticipo"
                            download={`Documento ${post.data.results[0]}`}
                          />
                          <br />
                          <LinkV
                            href=""
                            id="window_pdf_devolucion_anticipo"
                            target="_blank"
                          />
                        </div>
                      ),
                    });
                  })
                )
                .catch((error) => {
                  if (error.response)
                    message.message_modal(
                      error.response.data.title,
                      error.response.data.text,
                      error.response.status
                    );
                });
            }
          })
          .catch((error) => {
            if (error.response)
              message.message_modal(
                error.response.data.title,
                error.response.data.text,
                error.response.status
              );
          });
      }
    });
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consolidar":
              this.props.toogleDevCon();
              break;
            case "devolver":
              this.props.toogleDevCon();
              break;
            case "pagination":
              if (this._isMounted)
                this.setState({
                  pagination: {
                    count: response.data.count,
                    results: utility.anticipo_by_cliente(response.data.results),
                  },
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodGET_API = (url, state) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "pagination":
              if (this._isMounted)
                this.setState({
                  pagination: {
                    count: response.data.count,
                    results: utility.anticipo_by_cliente(response.data.results),
                  },
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPUT_API = (url, state, data) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "pagination":
              if (this._isMounted === true)
                this.setState({
                  pagination: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPDF_API = (url, state, num) => {
    let url_intern = url + `${num}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "devolucion":
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);
              document.getElementById("dowload_pdf_devolucion_anticipo").href =
                fileURL;
              document.getElementById("window_pdf_devolucion_anticipo").href =
                fileURL;
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  onChecked = (e) => {
    let checked = [...this.state.ant_checked];
    let ant = e.target.id;
    let ant_complete = this.state.pagination.results.find(
      (data) => data.numero === parseInt(ant.slice(4, 6))
    );
    if (e.target.checked) {
      checked.push(ant_complete);
      this.setState(
        {
          ant_checked: checked,
        },
        () => {
          this.props.checked(this.state.ant_checked);
        }
      );
    } else {
      checked = checked.filter((index) => index.numero !== ant_complete.numero);
      this.setState(
        {
          ant_checked: checked,
        },
        () => {
          this.props.checked(this.state.ant_checked);
        }
      );
    }
  };
  onBackTable = () => {
    if (this.props.on_cliente === false)
      this.methodGET_API(utility.url_paginacion, "pagination");
    else
      this.methodPUT_API(utility.url_paginacion, "pagination", {
        cliente: this.props.on_cliente,
      });
  };
  onChangeFiltrada = (data) => {
    if (this.props.on_cliente === false)
      this.setState({ page_actual: 1 }, () => {
        this.methodPOST_API(utility.url_paginacion, "pagination", data);
      });
    else
      this.setState({ page_actual: 1 }, () => {
        this.methodPOST_API(utility.url_paginacion, "pagination", {
          ...data,
          cliente: this.props.on_cliente,
        });
      });
  };
  render() {
    return (
      <React.Fragment>
        <ConsultaEspecifica
          onBackTable={this.onBackTable}
          onChangeFiltrada={this.onChangeFiltrada}
          on_cliente={this.props.on_cliente}
        />
        <div className="overflow-auto tm-table">
          <Table size="sm" id="tabla_anticipos" bordered hover responsive>
            <thead className="text-center header-table">
              <tr>
                <th>Cliente</th>
                <th>Nombre</th>
                <th>No. Pago a Tercero</th>
                <th>Banco</th>
                <th>Fecha</th>
                <th>Doc.</th>
                <th>No. Referencia</th>
                <th>Tipo Mov.</th>
                <th>Cantidad</th>
                <th>Saldo</th>
                <th>Operación</th>
                <th>Vigencia</th>
              </tr>
            </thead>
            <tbody className="body-table hover-gray">
              {this.state.pagination.results.length === 0 ? (
                <tr>
                  <td colSpan="12" align="center">
                    <Spinner
                      style={{ width: "10rem", height: "10rem" }}
                      color="danger"
                      type="grow"
                      className="align-self-center"
                    />
                  </td>
                </tr>
              ) : (
                <React.Fragment>
                  {this.state.pagination.results.map((ant, i, array) => {
                    let ant_cli = "";
                    let abono = 0;
                    if (i !== 0) ant_cli = parseInt(array[i - 1].cliente);
                    return (
                      <tr key={ant.numero}>
                        {ant_cli === parseInt(ant.cliente) ? null : (
                          <td
                            rowSpan={utility.repeticion_cliente(
                              parseInt(ant.cliente),
                              this.state.pagination.results
                            )}
                          >
                            <p className="mb-0 text-center" key={ant.cliente}>
                              {ant.cliente}
                            </p>
                          </td>
                        )}
                        {ant_cli === parseInt(ant.cliente) ? null : (
                          <td
                            rowSpan={utility.repeticion_cliente(
                              parseInt(ant.cliente),
                              this.state.pagination.results
                            )}
                          >
                            <p className="mb-0 text-center">
                              {utility.get_name_cliente(
                                ant.cliente,
                                this.props.clientes
                              )}
                            </p>
                          </td>
                        )}
                        <td>
                          <p className="mb-0 text-center">{ant.numero}</p>
                        </td>
                        <td>
                          <p className="mb-0 text-center">{ant.banco}</p>
                        </td>
                        <td>
                          <p className="mb-0 text-center">{ant.fecha}</p>
                        </td>
                        <td>
                          <p className="mb-0 text-center">{ant.documento}</p>
                        </td>
                        <td>
                          <p className="mb-0 text-center text-white">-</p>
                          {ant.movimientos.map((mov) => {
                            return (
                              <p
                                className="mb-0 text-center"
                                key={mov.referencia}
                              >
                                {mov.referencia}
                              </p>
                            );
                          })}
                        </td>
                        <td>
                          <p className="mb-0 text-center text-white">-</p>
                          {ant.movimientos.map((mov) => {
                            return (
                              <p
                                className="mb-0 text-center"
                                key={ant.numero + "-" + mov.tipo}
                              >
                                {utility.tipo_movimiento(
                                  parseInt(mov.tipo),
                                  this.props.tipo_movimiento
                                )}
                              </p>
                            );
                          })}
                        </td>
                        <td>
                          <p className="mb-0 text-center text-white">-</p>
                          {ant.movimientos.map((mov, index, array) => {
                            abono -= parseFloat(mov.abono);
                            return (
                              <p
                                className="mb-0 text-center"
                                key={mov.abono - index}
                              >
                                -$ {mov.abono}
                              </p>
                            );
                          })}
                        </td>
                        <td>
                          <p className="mb-0 text-center">
                            $ {parseFloat(ant.importe) + abono}
                          </p>
                        </td>
                        <td>
                          {utility.dif_fechas(ant.fecha_vencimiento) <= 0 ? (
                            <p className="mb-0 text-center">-</p>
                          ) : (
                            <div className="d-flex justify-content-center my-auto">
                              <input
                                type="checkbox"
                                id={`ant_${ant.numero}`}
                                onChange={this.onChecked}
                              />
                              <label
                                htmlFor={`ant_${ant.numero}`}
                                className="check-circle"
                                style={{ width: "30px" }}
                              ></label>
                            </div>
                          )}
                        </td>
                        <td>
                          {utility.dif_fechas(ant.fecha_vencimiento) >= 22 ? (
                            <p className="mb-0 text-center background-green">
                              {utility.dif_fechas(ant.fecha_vencimiento)}
                            </p>
                          ) : utility.dif_fechas(ant.fecha_vencimiento) >= 11 &&
                            utility.dif_fechas(ant.fecha_vencimiento) < 22 ? (
                            <p className="mb-0 text-center background-yellow">
                              {utility.dif_fechas(ant.fecha_vencimiento)}
                            </p>
                          ) : utility.dif_fechas(ant.fecha_vencimiento) >= 1 &&
                            utility.dif_fechas(ant.fecha_vencimiento) < 11 ? (
                            <p className="mb-0 text-center background-red">
                              {utility.dif_fechas(ant.fecha_vencimiento)}
                            </p>
                          ) : (
                            <p className="mb-0 text-center">
                              <Button
                                color="danger"
                                size="sm"
                                value={ant.numero + "_" + parseInt(ant.cliente)}
                                onClick={this.handleDevolver}
                              >
                                Devolución
                              </Button>
                            </p>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              )}
            </tbody>
          </Table>
        </div>
        <Paginacion
          actual={this.state.page_actual}
          count={this.state.pagination.count}
          paginate={this.handlePage}
        />
      </React.Fragment>
    );
  }
}
export default TablePagoTerceros;
