/**
 * Función que permite filtar los permisos de una app para obtener solo el nombre
 * @param {Object} permisos_by_app Permisos de app
 * @return {Array} Arreglo de permisos por aplicacion.
 */
const filtro_permisos = (permisos_by_app) => {
  let permisos_string = JSON.stringify(permisos_by_app);
  let expresion = /"|{|}/gi;
  let permisos_number = permisos_string.replaceAll(expresion, "").split(",");
  let permisos_app = [];
  permisos_number.forEach((perm) => {
    permisos_app.push(perm.split(":")[0]);
  });
  return permisos_app;
};

/**
 * Función que permite obtener el arreglo de permisos de un app en especifico.
 * @param {String} app Nombre de la app a filtrar.
 * @param {Object} all_permisos Todos los permisos alojados en el localStorage.
 * @returns {Array} Arreglo de permisos por aplicación.
 */
export const obtener_permisos_by_app = (app, all_permisos) => {
  const permisos = JSON.parse(all_permisos);
  switch (app) {
    case "alerta":
      return filtro_permisos(permisos.alerta);
    case "anticipo":
      return filtro_permisos(permisos.anticipo);
    case "anticiposolicitud":
      return filtro_permisos(permisos.anticiposolicitud);
    case "notificaciondeposito":
      return filtro_permisos(permisos.notificaciondeposito);
    case "catconsolidadoras":
      return filtro_permisos(permisos.catconsolidadoras);
    case "contabilidadelectronicaarchivo":
      return filtro_permisos(permisos.contabilidadelectronicaarchivo);
    case "catdepartamentos":
      return filtro_permisos(permisos.catdepartamentos);
    case "catempleados":
      return filtro_permisos(permisos.catempleados);
    case "catpuestos":
      return filtro_permisos(permisos.catpuestos);
    case "anticipohistorico":
      return filtro_permisos(permisos.anticipohistorico);
    case "anticiposolicitudhistorico":
      return filtro_permisos(permisos.anticiposolicitudhistorico);
    case "alertahistorico":
      return filtro_permisos(permisos.alertahistorico);
    case "poliza":
      return filtro_permisos(permisos.poliza);
    case "polizahistorico":
      return filtro_permisos(permisos.polizahistorico);
    case "operacion":
      return filtro_permisos(permisos.operacion);
    case "catagentesaduanales":
      return filtro_permisos(permisos.catagentesaduanales);
    case "transacciones":
      return filtro_permisos(permisos.transacciones);
    case "transaccioneshistorico":
      return filtro_permisos(permisos.transaccioneshistorico);
    case "m500":
      return filtro_permisos(permisos.m500);
    case "clientes":
      return filtro_permisos(permisos.clientes);
    case "cfdisat":
      return filtro_permisos(permisos.cfdisat);
    case "pagoterceros":
      return filtro_permisos(permisos.pagoterceros);
    case "catbancos":
      return filtro_permisos(permisos.catbancos);
    case "catingresos":
      return filtro_permisos(permisos.catingresos);
    case "catbeneficiarios":
      return filtro_permisos(permisos.catbeneficiarios);
    case "catdiario":
      return filtro_permisos(permisos.catdiario);
    case "cat_numeroparte":
      return filtro_permisos(permisos.catnumeroparte);
    case "catfracciones":
      return filtro_permisos(permisos.catfracciones);
    case "facturas":
      return filtro_permisos(permisos.facturas);
    case "user":
      return filtro_permisos(permisos.user);
    case "correoselectronicos":
      return filtro_permisos(permisos.correoselectronicos);
    case "cuentasauxiliares":
      return filtro_permisos(permisos.cuentasauxiliares);
    case "cuentasgastos":
      return filtro_permisos(permisos.cuentasgastos);
    default:
      break;
  }
};

/**
 * Función que permite saber si existe el permiso en la aplicación.
 * @param {String} permiso Nombre del permiso que se requiera
 * @param {Array} all_permisos Arreglo de permisos de aplicación especifica.
 * @returns {Boolean}
 */
export const obtener_permiso_especifico = (permiso, all_permisos) => {
  for (let i = 0; i < all_permisos.length; i++)
    if (all_permisos[i].includes(permiso)) return false;
  return true;
};
