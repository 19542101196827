/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un departamento. 
*/
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Component } from 'react';
import { Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import CustomSelect from '../../../utils/custom/CustomSelect';
import MyInput from '../../../utils/custom/MyInput';
import MyTextArea from '../../../utils/custom/MyTextArea';
import WebSocketInstance from "../../../utils/websocket";
import * as message from '../messages/messages';
import * as utility from '../utils/utility';

class ModalAlta extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            area_text: "Seleccione un area",
            area_id: "",
            areas: [
                { numero: 1, nombre: "Contabilidad" },
                { numero: 2, nombre: "Facturación y Cobranza" },
                { numero: 3, nombre: "Bancos" },
                { numero: 4, nombre: "Operación" },
                { numero: 5, nombre: "Catalogos" },
            ],
            areas_select: [],
        };
    }
    /*--------------------------------------------------------------------- */
    /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
    sendNotificacionHandler = (motivo) => {
        let folio = this.state.identificativo;
        const notificacionObject = {
            tipo: 0,
            aplicacion: 'departamento',
            motivo: motivo,
            folio: [folio]
        }
        WebSocketInstance.newNotificacion(notificacionObject);
    }
    /*--------------------------------------------------------------------- */
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    handleMovimiento = (e) => {
        let label = e.name_text;
        let label_id = e.name_index;
        let area_text = e[label];
        let area_id = e[label_id];
        this.setState({ area_text, area_id });
    }
    handleAlta = () => {
        message.message_confimacion('¿Desea continuar con la operación alta?', '')
            .then(res => {
                if (res) {
                    let areas = "";
                    this.state.areas_select.forEach((area, i, array) => array.length - 1 !== i ? areas += area.numero + "," : areas += area.numero);
                    let data = {
                        nombre: document.getElementById('nombre_alta_departamento').value,
                        area: areas,
                        descripcion: document.getElementById('descripcion_alta_departamento').value,
                    };
                    if (utility.valida_data(data).length > 0)
                        message.message_modal("Verificar datos", utility.valida_data(data), 400);
                    else
                        this.methodPOST_API(utility.url_crea_departamento, 'alta', data);
                }
            });
    }
    handleCerrar = () => {
        this.setState({
            areas: [
                { numero: 1, nombre: "Contabilidad" },
                { numero: 2, nombre: "Facturación y Cobranza" },
                { numero: 3, nombre: "Bancos" },
                { numero: 4, nombre: "Operación" },
                { numero: 5, nombre: "Catalogos" },
            ],
            areas_select: [],
        });
        this.props.toogleNuevo();
    }
    agregaArea = () => {
        let area_num = this.state.area_id;
        let areas_select = this.state.areas_select;
        let areas = this.state.areas;
        for (let i = 0; i < areas.length; i++)
            if (area_num === areas[i].numero) {
                areas_select.push(areas[i]);
                areas.splice(i, 1);
            }
        this.setState({
            areas, areas_select
        })
    }
    eliminaArea = (e) => {
        let area_num = parseInt(e.target.value);
        let areas_select = this.state.areas_select;
        let areas = this.state.areas;
        for (let i = 0; i < areas_select.length; i++)
            if (area_num === areas_select[i].numero) {
                areas.push(areas_select[i]);
                areas_select.splice(i, 1);
            }
        this.setState({
            areas, areas_select
        })
    }
    methodGET_API = (url, state) => {
        let url_intern = url;
        axios.get(url_intern)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    methodPOST_API = (url, state, data) => {
        let url_intern = url;
        axios.post(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        case 'alta':
                            this.setState({ identificativo: response.data.results.nombre }, () => {
                                this.sendNotificacionHandler('crear');
                            })
                            this.handleCerrar();
                            message.message_modal(response.data.title, response.data.text, response.status);
                            this.props.update(1);
                            break;
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    render() {
        return (
            <Modal isOpen={this.props.toogle_nuevo} toggle={this.handleCerrar} backdrop="static" size="xl"  >
                <ModalHeader toggle={this.handleCerrar}>Nuevo Departamento</ModalHeader>
                <ModalBody>
                    <Form className="container-fluid">
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-6 col-12" id="nombre_alta_departamento" nameLabel="Nombre(s)" />
                            <CustomSelect bootstrap="col-lg-4 col-12" name_text='nombre' name_index='numero' id="area_alta_departamento"
                                nameLabel="Area" value={this.state.area_text} onChange={this.handleMovimiento} options={this.state.areas} />
                            <Button color="primary" className="col-lg-2 col-4 mb-2" onClick={this.agregaArea}>
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                            <MyTextArea bootstrap="col-lg-12 col-12" id="descripcion_alta_departamento" nameLabel="Descripción" />
                        </FormGroup>
                    </Form>
                    <Table size="sm" bordered hover responsive>
                        <thead className="text-center header-table">
                            <tr>
                                <td>Número departamento</td>
                                <td>Nombre departamento</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody className="body-table">
                            {this.state.areas_select.map(area => (
                                <tr key={area.numero}>
                                    <td className="text-center">{area.numero}</td>
                                    <td className="text-center">{area.nombre}</td>
                                    <td className="text-center">
                                        <Button color="danger" size="sm" onClick={this.eliminaArea} value={area.numero}>
                                            Eliminar
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={this.handleAlta} >Aceptar</Button>
                    <Button color="primary" onClick={this.handleCerrar}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default ModalAlta;