import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
  Form,
  FormGroup,
  Alert,
  Table,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import * as utility from "../utils/utility";
import * as message from "../messages/messages";
import swal from "@sweetalert/with-react";
import axios from "axios";
import { faFileDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyInput from "../../../utils/custom/MyInput";
import MySelect from "../../../utils/custom/MySelect";
import * as permisos from "../../../utils/permisos/permisos";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalReporte extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      tipo_reporte: 0,
      enable_pdf: false,
      nombre_suggestions: [],
      url_tipo_reporte: "",
      url_tipo_reporte_pdf: "",
      estado_pdf: "",
      bancos: [],
      arregloTablaUno: [],
      numeroCliente: "",
      nombreCliente: "",
      fechaInicial: "",
      fechaFinal: "",
      importeMin: "0.00",
      importeMax: "0.00",
      bancoSelect: "Seleccione un banco",
      numeroSolAnt: "",
      aduana: "",
      referenciaSolAnt: "",
      prueba: "",
    };
  }

  //Ciclo de vida del componente
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_bancos, "bancos");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  // Funciones onChange
  handleFechaInicial = (e) => {
    let fechaInicial = e.target.value;
    this.setState({ fechaInicial });
  };
  handleFechaFinal = (e) => {
    let fechaFinal = e.target.value;
    this.setState({ fechaFinal });
  };
  handleImporteMin = (e) => {
    let importeMin = e.target.value;
    this.setState({ importeMin });
  };
  handleImporteMax = (e) => {
    let importeMax = e.target.value;
    this.setState({ importeMax });
  };
  handleSelectBanco = (e) => {
    this.setState({ bancoSelect: e.target.value.slice(0, 4) });
  };
  handleReporte = (e) => {
    let tipo_reporte = parseInt(e.target.value);
    this.setState({ tipo_reporte: tipo_reporte });
  };
  handelNumSolAnt = (e) => {
    let numeroSolAnt = parseInt(e.target.value);
    this.setState({ numeroSolAnt: numeroSolAnt });
  };
  handleReferenciaSolAnt = (e) => {
    let referenciaSolAnt = parseInt(e.target.value);
    this.setState({ referenciaSolAnt: referenciaSolAnt });
  };
  handleAduana = (e) => {
    let aduana = parseInt(e.target.value);
    this.setState({ aduana: aduana });
  };
  handlePrueba = (e) => {
    let prueba = e.target.value;
    this.setState({ prueba });
  };

  //Cerrar Modal
  handleCerrar = () => {
    this.props.toogleReporte();
    this.handleLimpiar();
  };
  //Limpiar Campos
  handleLimpiar = () => {
    this.setState({
      arregloTablaUno: [],
      numeroCliente: "",
      nombreCliente: "",
      fechaInicial: "",
      fechaFinal: "",
      importeMin: "0.00",
      importeMax: "0.00",
      bancoSelect: "Seleccione un banco",
      tipo_reporte: 0,
    });
  };

  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    const { value } = e.target;
    let text_add = "";
  
    if (value === "") {
      text_add = "";
    } else if (value.length <= 4) {
      text_add = value.padStart(4, "0");
    }
  
    let nombreCliente = "";
    let numeroCliente = "";
  
    for (let i = 0; i < this.props.clientes.length; i++) {
      const cliente = this.props.clientes[i];
      if (text_add === cliente.numero) {
        nombreCliente = cliente.nombre;
        numeroCliente = cliente.numero;
        break;
      }
    }
  
    this.suggestionSelected(nombreCliente, numeroCliente);
  };
  
  suggestionSelected = (nombreCliente, numeroCliente) => {
    this.setState({
      nombreCliente: nombreCliente,
      numeroCliente: numeroCliente,
      nombre_suggestions: [],
    });
  };
  
  onNameChange = (e) => {
    const { value } = e.target;
    let suggestions = [];

    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_nombres_clientes(this.props.clientes)
        .sort()
        .filter((v) => regex.test(v));
    }

    this.setState({
      nombre_suggestions: suggestions,
      nombreCliente: value,
    });
  };

  renderSuggestion = () => {
    const { nombre_suggestions } = this.state;
  
    if (nombre_suggestions.length === 0) return null;
  
    return (
      <ListGroup className="col-12" flush>
        {nombre_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  

  /* ------ Termina bloque de funciones del autocompletado de nombre de cliente dependiendo su número ------ */

  /**
   * Función que permite hacer una petición de forma Get para consultar un PDF.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} num Número del documento.
   */
  methodPDF_API = (url, state, num) => {
    let url_intern = url + `${num}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          let file = "";
          let fileURL = "";

          switch (state) {
            case "solicitudes_notificadas":
              file = new Blob([response.data], {
                type: "application/pdf",
              });
              fileURL = URL.createObjectURL(file);
              document.getElementById(
                "dowload_pdf_solicitudes_notificadas"
              ).href = fileURL;
              document.getElementById(
                "window_pdf_solicitudes_notificadas"
              ).href = fileURL;
              break;

            case "movimiento_banco":
              file = new Blob([response.data], {
                type: "application/pdf",
              });
              fileURL = URL.createObjectURL(file);
              document.getElementById(
                "dowload_pdf_solicitudes_notificadas"
              ).href = fileURL;
              document.getElementById(
                "window_pdf_solicitudes_notificadas"
              ).href = fileURL;
              break;

            case "reporte_desplose":
              file = new Blob([response.data], {
                type: "application/pdf",
              });
              fileURL = URL.createObjectURL(file);
              document.getElementById("dowload_pdf_reporte_desplose").href =
                fileURL;
              document.getElementById("window_pdf_reporte_desplose").href =
                fileURL;
              break;

            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  // Obtiene la información de los bancos
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "bancos":
              if (this._isMounted === true)
                this.setState({
                  bancos: response.data.results,
                });
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handleConsultaOpcionUno = () => {
    const datos = {};

    if (this.state.numeroCliente !== "")
      datos.cliente = this.state.numeroCliente;

    // Caso cuando tenemos fecha inicial y final
    if (this.state.fechaInicial !== "" && this.state.fechaFinal !== "") {
      //Juntar fechas
      const fechaIni = this.state.fechaInicial;
      const fechaFin = this.state.fechaFinal;
      const fechas = fechaIni + "," + fechaFin;

      datos.fechas = fechas;
    }

    // Caso cuando tenemos fecha inicial pero no final
    if (this.state.fechaInicial !== "" && this.state.fechaFinal === "") {
      //Obtener fecha actual
      const today = new Date();
      const cadena = today.toISOString();
      const cadenaExtraida = cadena.substring(0, 10);

      //Juntar fechas
      const fechaIni = this.state.fechaInicial;
      const fechaFin = cadenaExtraida;
      const fechas = fechaIni + "," + fechaFin;

      this.setState({ fechaFinal: cadenaExtraida });

      datos.fechas = fechas;
    }

    // Caso cuando tenemos fecha final pero no inicial
    if (this.state.fechaInicial === "" && this.state.fechaFinal !== "") {
      //Juntar fechas
      const fechaIni = "0001-01-01";
      const fechaFin = this.state.fechaFinal;
      const fechas = fechaIni + "," + fechaFin;

      this.setState({ fechaInicial: "0001-01-01" });

      datos.fechas = fechas;
    }

    // Caso cuando tenemos los importes mínimo y máximo
    if (this.state.importeMin !== "0.00" && this.state.importeMax !== "0.00") {
      //Juntar importes
      const importes = this.state.importeMin + "," + this.state.importeMax;

      datos.importes = importes;
    }

    // Caso cuando tenemos importe mínimo pero no máximo
    if (this.state.importeMin !== "0.00" && this.state.importeMax === "0.00") {
      //Juntar importes
      const importeMin = this.state.importeMin;
      const importes = importeMin + "," + importeMin;

      this.setState({ importeMax: importeMin });

      datos.importes = importes;
    }

    // Caso cuando tenemos importe máximo pero no mínimo
    if (this.state.importeMin === "0.00" && this.state.importeMax !== "0.00") {
      //Juntar importes
      const importeMin = "0.00";
      const importeMax = this.state.importeMax;
      const importes = importeMin + "," + importeMax;

      this.setState({ importeMin: importeMin });

      datos.importes = importes;
    }

    // Caso cuando no se llenaron los datos
    if (Object.keys(datos).length === 0)
      return swal(
        "Llenar formulario",
        "Favor de llenar al menos un campo",
        "warning"
      );

    // la variable datos se convierte a una cadena de texto JSON
    const datosJson = JSON.stringify(datos);

    // Verifica el tipo de url que se utilizara segun el tipo de reporte
    console.log("TIPO DE REPORTE " + this.state.tipo_reporte);

    switch (this.state.tipo_reporte) {
      case 1:
        this.setState({
          estado_pdf: "solicitudes_notificadas",
        });
        break;
      case 2:
        this.setState({
          estado_pdf: "reporte_desplose",
        });
        break;

      default:
        break;
    }

    //Link de PDF
    this.setState({ enable_pdf: true }, () => {
      this.methodPDF_API(
        this.state.url_tipo_reporte_pdf,
        this.state.estado_pdf,
        datosJson
      );
    });

    //Se manda el paquete de datos al backend
    axios
      .post(this.state.url_tipo_reporte, datos)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              arregloTablaUno: response.data.results,
            });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handleConsultaOpcionDos = () => {
    const datos = {};

    if (this.state.numeroSolAnt !== "")
      datos.numeroSolicitud = this.state.numeroSolAnt;

    if (this.state.referenciaSolAnt !== "")
      datos.referencia = this.state.referenciaSolAnt;

    if (this.state.aduana !== "") datos.aduana = this.state.aduana;

    if (this.state.numeroCliente !== "")
      datos.cliente = this.state.numeroCliente;

    // Caso cuando tenemos fecha inicial y final
    if (this.state.fechaInicial !== "" && this.state.fechaFinal !== "") {
      //Juntar fechas
      const fechaIni = this.state.fechaInicial;
      const fechaFin = this.state.fechaFinal;
      const fechas = fechaIni + "," + fechaFin;

      datos.fechas = fechas;
    }

    // Caso cuando tenemos fecha inicial pero no final
    if (this.state.fechaInicial !== "" && this.state.fechaFinal === "") {
      //Obtener fecha actual
      const today = new Date();
      const cadena = today.toISOString();
      const cadenaExtraida = cadena.substring(0, 10);

      //Juntar fechas
      const fechaIni = this.state.fechaInicial;
      const fechaFin = cadenaExtraida;
      const fechas = fechaIni + "," + fechaFin;

      this.setState({ fechaFinal: cadenaExtraida });

      datos.fechas = fechas;
    }

    // Caso cuando tenemos fecha final pero no inicial
    if (this.state.fechaInicial === "" && this.state.fechaFinal !== "") {
      //Juntar fechas
      const fechaIni = "0001-01-01";
      const fechaFin = this.state.fechaFinal;
      const fechas = fechaIni + "," + fechaFin;

      this.setState({ fechaInicial: "0001-01-01" });

      datos.fechas = fechas;
    }

    // Caso cuando tenemos los importes mínimo y máximo
    if (this.state.importeMin !== "0.00" && this.state.importeMax !== "0.00") {
      //Juntar importes
      const importes = this.state.importeMin + "," + this.state.importeMax;

      datos.importes = importes;
    }

    // Caso cuando tenemos importe mínimo pero no máximo
    if (this.state.importeMin !== "0.00" && this.state.importeMax === "0.00") {
      //Juntar importes
      const importeMin = this.state.importeMin;
      const importes = importeMin + "," + importeMin;

      this.setState({ importeMax: importeMin });

      datos.importes = importes;
    }

    // Caso cuando tenemos importe máximo pero no mínimo
    if (this.state.importeMin === "0.00" && this.state.importeMax !== "0.00") {
      //Juntar importes
      const importeMin = "0.00";
      const importeMax = this.state.importeMax;
      const importes = importeMin + "," + importeMax;

      this.setState({ importeMin: importeMin });

      datos.importes = importes;
    }

    console.log(datos);
    // Caso cuando no se llenaron los datos
    if (Object.keys(datos).length === 0)
      return swal(
        "Llenar formulario",
        "Favor de llenar al menos un campo",
        "warning"
      );

    // la variable datos se convierte a una cadena de texto JSON
    const datosJson = JSON.stringify(datos);

    // Verifica el tipo de url que se utilizara segun el tipo de reporte
    console.log("TIPO DE REPORTE " + this.state.tipo_reporte);

    switch (this.state.tipo_reporte) {
      case 1:
        this.setState({
          estado_pdf: "solicitudes_notificadas",
        });
        break;
      case 2:
        this.setState({
          estado_pdf: "reporte_desplose",
        });
        break;

      default:
        break;
    }

    //Link de PDF
    this.setState({ enable_pdf: true }, () => {
      this.methodPDF_API(
        this.state.url_tipo_reporte_pdf,
        this.state.estado_pdf,
        datosJson
      );
    });

    //Se manda el paquete de datos al backend
    axios
      .post(this.state.url_tipo_reporte, datos)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              arregloTablaUno: response.data.results,
            });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_reporte}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Reportes Solicitud De Depósito
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <div className="grupo col-12 p-0">
                <select
                  className="input-animated col-12 mb-2"
                  style={{ background: "#fff", padding: "7px 10px" }}
                  onChange={this.handleReporte}
                  required
                >
                  <option value={0}>Seleccione un Reporte</option>
                  <option value={1}>Solicitudes Notificadas</option>
                  <option value={2}>Reporte Desglose</option>
                </select>
                <span className="barra"></span>
                <br />
                <label className="label-animated">Reporte</label>
              </div>
            </FormGroup>
          </Form>
          {this.state.tipo_reporte === 0 ? (
            <Alert color="warning" className="text-center mt-3">
              <h2>Debe Seleccionar Algún Reporte</h2>
            </Alert>
          ) : this.state.tipo_reporte === 1 ? (
            <React.Fragment>
              <Form className="container-fluid">
                <FormGroup className="row mb-0">
                  <MyInput
                    type="text"
                    bootstrap="col-lg-6"
                    id="numero_cliente"
                    nameLabel="Número de cliente"
                    required
                    onChange={this.nameGenerator}
                    value={this.state.numeroCliente}
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-6"
                    id="nombre_cliente"
                    nameLabel="Nombre de cliente"
                    required
                    onChange={this.onNameChange}
                    value={this.state.nombreCliente}
                  />

                  {this.renderSuggestion()}
                </FormGroup>
                <FormGroup className="row mb-0">
                  <MyInput
                    type="date"
                    bootstrap="col-lg-6"
                    id="fecha_inicial"
                    nameLabel="Fecha Inicial"
                    required
                    onChange={this.handleFechaInicial}
                    value={this.state.fechaInicial}
                  />
                  <MyInput
                    type="date"
                    bootstrap="col-lg-6"
                    id="fecha_final"
                    nameLabel="Fecha Final"
                    required
                    onChange={this.handleFechaFinal}
                    value={this.state.fechaFinal}
                  />
                </FormGroup>
                <FormGroup className="row mb-0">
                  <MyInput
                    type="text"
                    bootstrap="col-lg-6"
                    id="fecha_inicial"
                    nameLabel="Importe Mínimo"
                    required
                    onChange={this.handleImporteMin}
                    value={this.state.importeMin}
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-6"
                    id="fecha_final"
                    nameLabel="Importe Máximo"
                    required
                    onChange={this.handleImporteMax}
                    value={this.state.importeMax}
                  />
                </FormGroup>
                <FormGroup className="row mb-0">
                  <Button
                    color="primary"
                    className="col-lg-6 col-4 mb-2"
                    onClick={this.handleConsultaOpcionUno}
                  >
                    Consulta
                  </Button>
                </FormGroup>
              </Form>
              <Table size="s" id="tabla_uno" bordered hover responsive>
                <thead className="text-center header-table">
                  <tr>
                    <td>Número Cliente</td>
                    <td>Número Solicitud</td>
                    <td>Fecha</td>
                    <td>Documento</td>
                    <td>Observaciones</td>
                    <td>Importe</td>
                    <td>Saldo</td>
                  </tr>
                </thead>
                <tbody className="body-table hover-gray">
                  {this.state.arregloTablaUno.map((array) => (
                    <tr key={array.cliente}>
                      <td className="text-center">{array.cliente}</td>
                      <td>
                        {array.anticipos.map((ant) => (
                          <tr className="d-flex justify-content-center">
                            {ant.numero}
                          </tr>
                        ))}
                      </td>
                      <td>
                        {array.anticipos.map((ant) => (
                          <tr className="d-flex justify-content-center">
                            {ant.fecha}
                          </tr>
                        ))}
                      </td>
                      <td>
                        {array.anticipos.map((ant) => (
                          <tr className="d-flex justify-content-center">
                            {ant.documento}
                          </tr>
                        ))}
                      </td>
                      <td>
                        {array.anticipos.map((ant) => (
                          <tr className="d-flex justify-content-center">
                            {ant.observaciones}
                          </tr>
                        ))}
                      </td>
                      <td>
                        {array.anticipos.map((ant) => (
                          <tr className="d-flex justify-content-center">
                            ${ant.importe}
                          </tr>
                        ))}
                      </td>
                      <td>
                        {array.anticipos.map((ant) => (
                          <tr className="d-flex justify-content-center">
                            ${ant.saldo}
                          </tr>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="d-flex justify-content-center">
                <LinkD
                  href=""
                  id="dowload_pdf_solicitudes_notificadas"
                  download={`Reporte Anticipo Activo`}
                />
                <LinkV
                  href=""
                  id="window_pdf_solicitudes_notificadas"
                  target={this.state.enable_pdf ? "_self" : "_blank"}
                  className="ml-5"
                />
              </div>
            </React.Fragment>
          ) : this.state.tipo_reporte === 2 ? (
            <React.Fragment>
              <Form className="container-fluid">
                <FormGroup className="row mb-0">
                  <MyInput
                    type="text"
                    bootstrap="col-lg-6"
                    id="numero_solicitud_ant"
                    nameLabel="Número Solicitud de Anticipo"
                    required
                    onChange={this.handelNumSolAnt}
                    value={this.state.numeroSolAnt}
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-6"
                    id="referencia_solicitud_ant"
                    nameLabel="Referencia"
                    required
                    onChange={this.handleReferenciaSolAnt}
                    value={this.state.referenciaSolAnt}
                  />
                </FormGroup>
                <FormGroup className="row mb-0">
                  <MyInput
                    type="text"
                    bootstrap="col-lg-6"
                    id="numero_cliente"
                    nameLabel="Número de cliente"
                    required
                    onChange={this.nameGenerator}
                    value={this.state.numeroCliente}
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-6"
                    id="nombre_cliente"
                    nameLabel="Nombre de cliente"
                    required
                    onChange={this.onNameChange}
                    value={this.state.nombreCliente}
                  />
                  {this.renderSuggestion()}
                </FormGroup>
                <FormGroup className="row mb-0">
                  <MyInput
                    type="date"
                    bootstrap="col-lg-6"
                    id="fecha_inicial"
                    nameLabel="Fecha Inicial"
                    required
                    onChange={this.handleFechaInicial}
                    value={this.state.fechaInicial}
                  />
                  <MyInput
                    type="date"
                    bootstrap="col-lg-6"
                    id="fecha_final"
                    nameLabel="Fecha Final"
                    required
                    onChange={this.handleFechaFinal}
                    value={this.state.fechaFinal}
                  />
                </FormGroup>
                <FormGroup className="row mb-0">
                  <MyInput
                    type="text"
                    bootstrap="col-lg-6"
                    id="fecha_inicial"
                    nameLabel="Importe Mínimo"
                    required
                    onChange={this.handleImporteMin}
                    value={this.state.importeMin}
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-6"
                    id="fecha_final"
                    nameLabel="Importe Máximo"
                    required
                    onChange={this.handleImporteMax}
                    value={this.state.importeMax}
                  />
                </FormGroup>
                <FormGroup className="row mb-0">
                  <MyInput
                    type="text"
                    bootstrap="col-lg-6"
                    id="aduana"
                    nameLabel="Aduana"
                    required
                    onChange={this.handleAduana}
                    value={this.state.aduana}
                  />
                  <Button
                    color="primary"
                    className="col-lg-6 col-4 mb-2"
                    onClick={this.handleConsultaOpcionDos}
                  >
                    Consulta
                  </Button>
                </FormGroup>
              </Form>
              <Table size="s" id="tabla_uno" bordered hover responsive>
                <thead className="text-center header-table">
                  <tr>
                    <td>Número Cliente</td>
                    <td>Número Solicitud</td>
                    <td>Referencia</td>
                    <td>Fecha</td>
                    <td>Aduana</td>
                    <td>Documento</td>
                    <td>Observaciones</td>
                    <td>Importe</td>
                    <td>Saldo</td>
                  </tr>
                </thead>
                <tbody className="body-table hover-gray">
                  {this.state.arregloTablaUno.map((array) => (
                    <tr key={array.cliente}>
                      <td className="text-center">{array.cliente}</td>
                      <td>
                        {array.anticipos.map((ant) => (
                          <tr className="d-flex justify-content-center">
                            {ant.numero}
                          </tr>
                        ))}
                      </td>
                      <td>
                        {array.anticipos.map((ant) => (
                          <tr className="d-flex justify-content-center">
                            {ant.referencia}
                          </tr>
                        ))}
                      </td>
                      <td>
                        {array.anticipos.map((ant) => (
                          <tr className="d-flex justify-content-center">
                            {ant.fecha}
                          </tr>
                        ))}
                      </td>
                      <td>
                        {array.anticipos.map((ant) => (
                          <tr className="d-flex justify-content-center">
                            {ant.aduana}
                          </tr>
                        ))}
                      </td>
                      <td>
                        {array.anticipos.map((ant) => (
                          <tr className="d-flex justify-content-center">
                            {ant.documento}
                          </tr>
                        ))}
                      </td>
                      <td>
                        {array.anticipos.map((ant) => (
                          <tr className="d-flex justify-content-center">
                            {ant.observaciones}
                          </tr>
                        ))}
                      </td>
                      <td>
                        {array.anticipos.map((ant) => (
                          <tr className="d-flex justify-content-center">
                            ${ant.importe}
                          </tr>
                        ))}
                      </td>
                      <td>
                        {array.anticipos.map((ant) => (
                          <tr className="d-flex justify-content-center">
                            ${ant.saldo}
                          </tr>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="d-flex justify-content-center">
                <LinkD
                  href=""
                  id="dowload_pdf_reporte_desplose"
                  download={`Reporte Anticipo Desglose`}
                />
                <LinkV
                  href=""
                  id="window_pdf_reporte_desplose"
                  target={this.state.enable_pdf ? "_self" : "_blank"}
                  className="ml-5"
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalReporte;
