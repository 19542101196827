import { Button, Card, CardBody, Form, FormGroup } from "reactstrap";
import axios from "axios";
import React, { Component } from "react";
import * as utility from "./utils/utility";
import * as message from "../../utils/messages/message";
import * as permisos from "../../utils/permisos/permisos";
import MyInput from "../../utils/custom/MyInput";
import MySelect from "../../utils/custom/MySelect";
import MyTextArea from "../../utils/custom/MyTextArea";
import WebSocketInstance from "../../utils/websocket";

class Pruebas extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      codigos_sat: [],
      actualizar: 0,
      app: "",
      motivo: "crear",
      folio: "",
      permisos: permisos.obtener_permisos_by_app(
        "transacciones",
        localStorage.getItem("perm")
      ),
      on_cliente:
        localStorage.getItem("puesto") === "Cliente"
          ? localStorage.getItem("id_usr")
          : false,
    };
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = () => {
    let folio = this.state.folio;
    const notificacionObject = {
      tipo: 0,
      aplicacion: this.state.app,
      motivo: this.state.motivo,
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */

  componentDidMount() {
    this._isMounted = true;
    document.addEventListener("keyup", this.handleEnterKeyPress);
  }
  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("keyup", this.handleEnterKeyPress);
  }
  update = (cambio) => {
    this.setState({ actualizar: cambio });
  };
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "codigo_sat":
              break;
            case "clientes":
              if (this._isMounted === true) break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handleProbarNotificaciones = () => {
    this.sendNotificacionHandler();
  };

  handleApp = (e) => {
    let app = e.target.value;
    this.setState({ app });
  };

  handlemotivo = (e) => {
    let motivo = e.target.value;
    this.setState({ motivo });
  };

  handlefolio = (e) => {
    let folio = e.target.value;
    this.setState({ folio });
  };

  render() {
    return (
      <div className="container-fluid">
        <br></br>

        <h1>Notificaciones</h1>
        <Card outline color="primary">
          <CardBody>
            <Form
              className="container-fluid"
              onSubmit={this.handleAltaAnticipo}
            >
              <FormGroup className="row mb-0">
                <MyInput
                  type="text"
                  bootstrap="col-lg-3"
                  id="app"
                  value={this.state.app}
                  required
                  onChange={this.handleApp}
                  nameLabel="App"
                />
                 <MyInput
                  type="text"
                  bootstrap="col-lg-3"
                  id="motivo"
                  value={this.state.motivo}
                  required
                  onChange={this.handlemotivo}
                  nameLabel="Motivo"
                />
                <MyInput
                  type="text"
                  bootstrap="col-lg-3"
                  id="folio"
                  value={this.state.folio}
                  required
                  onChange={this.handlefolio}
                  nameLabel="Folio"
                />
              </FormGroup>
              <p>Funciona con la App "transaccion"</p>

              <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                <Button
                  type="button"
                  color="success"
                  className="mr-2"
                  onClick={this.handleProbarNotificaciones}
                >
                  Probar
                </Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
        <br></br>

        {/* <h1>Peticiones CEDI</h1>
        <Card outline color="primary">
          <CardBody>
            <Form
              className="container-fluid"
              onSubmit={this.handleAltaAnticipo}
            >
              <FormGroup className="row mb-0"></FormGroup>
              <FormGroup className="row mb-0">
                <MyInput
                  type="text"
                  bootstrap="col-lg-3"
                  id="importe_anticipo"
                  value={this.state.importe}
                  required
                  onChange={this.handleImporte}
                  onBlur={this.handleImporteBlur}
                  nameLabel="App"
                />
              </FormGroup>

              <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                <Button
                  type="button"
                  color="success"
                  className="mr-2"
                  onClick={this.handleProbarNotificaciones}
                >
                  Probar
                </Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card> */}
      </div>
    );
  }
}
export default Pruebas;
