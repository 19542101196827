/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un Proveedor. 
*/
import swal from "@sweetalert/with-react";
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ListGroup,
  ListGroupItem,
  FormText,
  Collapse,
  Card,
  CardBody,
} from "reactstrap";
import MyInput from "../../../utils/custom/MyInput";
import MySelect from "../../../utils/custom/MySelect";
import WebSocketInstance from "../../../utils/websocket";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";
import MyTextArea from "../utils/MyTextArea";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalAuxiliar from "../../Poliza/Modal/ModalAuxiliar";
import ModalCrearEnCatalogos from "../../CuentasAux/Modal/ModalCrearEnCatalogos";

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      fraccion: "",
      numParte: "",

      text_cfdi_unidad: "",
      cfdi_unidad_suggestions: [],
      cat_cfdi_unidad: [],

      text_cfdi_productos: "",
      cfdi_productos_suggestions: [],
      cat_cfdi_productos: [],

      auxiliar: "",
      claveAlta: "",
      codigo: "",
      nivel: 0,
      naturaleza: "",
      tipoCuenta: "",
      descripcion: "",
      toogle_collapse: false,
      nombre_select: "",
      tipo: "",
      mayor: "",
      subcuenta: "",
      detalle: "",
      cuentas_mayor: [],
      cuentas_menor: [],

      toogle_ingreso: false,
      toogle_cuentaux: false,

      clave: "",
      toogle_auxiliar: false,
      auxiliares: [],
      enableCuentaAuxiliar: true,

      numLote: "",
      numAux: "",
    };
    this.toogleAltaIngreso = this.toogleAltaIngreso.bind(this);
    this.toogleCuentaAux = this.toogleCuentaAux.bind(this);
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  /*  sendNotificacionHandler = (motivo) => {
         let folio = this.state.identificativo;
         const notificacionObject = {
             tipo: 0,
             aplicacion: 'proveedor',
             motivo: motivo,
             folio: [folio]
         }
         WebSocketInstance.newNotificacion(notificacionObject);
     } */
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_cfdi_unidad, "cfdi_unidad");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleNumParte = (e) => {
    let numParte = e.target.value;
    this.setState({ numParte });
  };
  handleFraccion = (e) => {
    let fraccion = e.target.value;
    this.setState({ fraccion });
  };
  handlenumLote = (e) => {
    let numLote = e.target.value;
    this.setState({ numLote });
  };
  handlenumAux = (e) => {
    let numAux = e.target.value;
    this.setState({ numAux });
  };
  handleClave = (e) => {
    let claveAlta = e.target.value;
    this.setState({ claveAlta });
  };
  toogleCollapse = (e) => {
    this.setState((prevState) => ({
      toogle_collapse: !prevState.toogle_collapse,
    }));
  };
  toogleAltaIngreso = () => {
    this.setState((prevState) => ({
      toogle_ingreso: !prevState.toogle_ingreso,
      toogle_cuentaux: false,
    }));
  };
  toogleCuentaAux = () => {
    this.setState((prevState) => ({
      toogle_cuentaux: !prevState.toogle_cuentaux,
      toogle_ingreso: false,
    }));
  };

  handleCallback = (childData) => {
    this.setState({ auxiliar: childData });
  };

  handleAltaNumParte = () => {
    const { numParte, fraccion, numLote, numAux } = this.state;

    if (numParte === "" || fraccion === "" || numLote === "") {
      let camposFaltantes = [];
      if (numParte === "") camposFaltantes.push("Número de Parte");
      if (fraccion === "") camposFaltantes.push("Fracción");
      if (numLote === "") camposFaltantes.push("Número de Lote");

      let mensaje = `Por favor llena los siguientes campos: ${camposFaltantes.join(
        ", "
      )}.`;
      message.warningMessage(mensaje);
    } else {
      let data = {
        id_parte: numParte,
        id_fraccion: fraccion,
        num_lote: numLote,
      };

      if (numAux !== "") {
        data.num_auxilar = numAux;
      }

      axios
        .post(utility.url_crea_numParte, data)
        .then((response) => {
          if (response.status >= 200 && response.status < 400) {
            if (this._isMounted) {
              message.message_success(
                "Se creó exitosamente",
                "Número Parte creado"
              );
            }
            this.handleCerrar();
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response)
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
        });
    }
  };

  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta":
              if (this._isMounted) {
                this.setState({
                  clave: response.data.results.clave,
                });
                message.message_modal(
                  response.data.title,
                  response.data.text,
                  response.status
                );
                this.props.update(1);
              }
              break;
            case "cfdi_productos":
              this.setState({
                cat_cfdi_productos: response.data.results,
                cfdi_productos_suggestions: utility.organiza_cfdi_productos(
                  response.data.results
                ),
              });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "cfdi_unidad":
              this.setState({
                cat_cfdi_unidad: response.data.results,
              });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  handleCerrar = () => {
    this.setState({
      nombre: "",
      nombre_corto: "",
      fraccion: "",
      numParte: "",

      text_cfdi_unidad: "",
      cfdi_unidad_suggestions: [],
      cat_cfdi_unidad: [],

      text_cfdi_productos: "",
      cfdi_productos_suggestions: [],
      cat_cfdi_productos: [],

      auxiliar: "",
      codigo: "",
      nivel: 0,
      naturaleza: "",
      tipoCuenta: "",
      descripcion: "",
      toogle_collapse: false,
      nombre_select: "",
      tipo: "",
      mayor: "",
      subcuenta: "",
      detalle: "",
      cuentas_mayor: [],
      cuentas_menor: [],

      toogle_ingreso: false,
      toogle_cuentaux: false,

      clave: "",
      toogle_auxiliar: false,
      auxiliares: [],
      enableCuentaAuxiliar: true,
    });
    this.props.toogleNuevo();
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_nuevo}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>Alta Número Parte</ModalHeader>
        <ModalBody>
          <CardBody>
            <Form className="container-fluid">
              <FormGroup className="row mb-0">
                <MyInput
                  type="text"
                  bootstrap="col-lg-6"
                  id="nombre_ingreso_alta"
                  nameLabel="Número de Parte"
                  value={this.state.numParte}
                  onChange={this.handleNumParte}
                />
                <MyInput
                  type="text"
                  bootstrap="col-lg-6"
                  id="nombre_corto_ingreso_alta"
                  nameLabel="Fracción"
                  value={this.state.fraccion}
                  onChange={this.handleFraccion}
                />
              </FormGroup>
              <FormGroup className="row mb-0">
                <MyInput
                  type="text"
                  bootstrap="col-lg-6"
                  id="nombre_ingreso_alta"
                  nameLabel="Número de Lote"
                  value={this.state.numLote}
                  onChange={this.handlenumLote}
                />
                <MyInput
                  type="text"
                  bootstrap="col-lg-6"
                  id="nombre_corto_ingreso_alta"
                  nameLabel="Número de Auxiliar"
                  value={this.state.numAux}
                  onChange={this.handlenumAux}
                />
              </FormGroup>
            </Form>
          </CardBody>
        </ModalBody>
        <ModalFooter>
          <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
            <Button
              color="success"
              className="mr-2"
              onClick={this.handleAltaNumParte}
            >
              Aceptar
            </Button>
            <Button color="primary" onClick={this.handleCerrar}>
              Cerrar
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalAlta;
