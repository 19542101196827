
import axios from 'axios';
import React, { Component } from 'react';
import { Spinner, Table } from 'reactstrap';
import '../../assets/style.css';
import Paginacion from '../Pagination';
import ConsultaEspecifica from './SearchSpecific';
import * as utility from './utils/utility';
import * as message from './messages/messages'

class TableTransacciones extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            page_actual: 1,
            pagination: { count: 1, results: [] },
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.methodGET_API(utility.url_pagination_transacciones, 'pagination');
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (this.props.actualizar !== prevProps.actualizar) {
            this.handlePage(this.state.page_actual);
            this.props.update(0);
        }
    }
    handlePage = (page_actual) => {
        this.setState({ page_actual }, () => { this.methodGET_API(utility.url_pagination_transacciones, 'pagination') });
    }
    methodGET_API = (url, state) => {
        let url_intern = url;
        if (state === 'pagination')
            url_intern += `?page=${this.state.page_actual}`;
        axios.get(url_intern)
            .then(response => {
                if (response.status >= 200 && response.status < 400) {
                    switch (state) {
                        case 'pagination':
                            if (this._isMounted === true)
                                this.setState({
                                    pagination: { count: response.data.count, results: response.data.results }
                                });
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    methodPOST_API = (url, state, data) => {
        let url_intern = url;
        if (state === 'pagination')
            url_intern += `?page=${this.state.page_actual}`;
        axios.post(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400) {
                    switch (state) {
                        case 'pagination':
                            if (this._isMounted === true)
                                this.setState({
                                    pagination: { count: response.data.count, results: response.data.results }
                                });
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    onBackTable = () => {
        this.methodGET_API(utility.url_pagination_transacciones, 'pagination')
    }
    onChangeFiltrada = (data) => {
        this.setState({ page_actual: 1 }, () => { this.methodPOST_API(utility.url_pagination_transacciones, 'pagination', data); })
    }
    render() {
        return (
            <React.Fragment>
                <ConsultaEspecifica onBackTable={this.onBackTable} onChangeFiltrada={this.onChangeFiltrada}
                    beneficiarios={this.props.beneficiarios} bancos={this.props.bancos} />
                <div className="overflow-auto tm-table">
                    <Table size="sm" id="tabla_transaccions" bordered hover responsive>
                        <thead className="text-center header-table">
                            <tr>
                                <th>Número</th>
                                <th>Banco</th>
                                <th>Tipo</th>
                                <th>Beneficiario</th>
                                <th>Concepto</th>
                                <th>Fecha</th>
                                <th>Comprobado</th>
                                <th>Pago</th>
                                <th>Cantidad</th>
                                <th>Importe</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody className="body-table hover-gray">
                            {
                                this.state.pagination.results.length === 0 ? (
                                    <tr>
                                        <td colSpan="12" align="center">
                                            <Spinner
                                                style={{ width: '10rem', height: '10rem' }}
                                                color="danger"
                                                type="grow"
                                                className="align-self-center"
                                            />
                                        </td>
                                    </tr>
                                ) : (
                                    <React.Fragment>
                                        {
                                            this.state.pagination.results.map(transaccion => (
                                                <tr key={transaccion.numero}>
                                                    <td><p className="mb-0 text-center">{transaccion.numero}</p></td>
                                                    <td><p className="mb-0 text-center">{transaccion.banco}</p></td>
                                                    <td><p className="mb-0 text-center">{transaccion.tipo}</p></td>
                                                    <td><p className="mb-0 text-center">{transaccion.beneficiario.nombre}</p></td>
                                                    <td><p className="mb-0 text-center">{transaccion.concepto}</p></td>
                                                    <td><p className="mb-0 text-center">{transaccion.fecha}</p></td>
                                                    <td><p className="mb-0 text-center">{transaccion.comprobado}</p></td>
                                                    <td>
                                                        <p className="mb-0 text-center text-white">-</p>
                                                        {transaccion.t_desglose.map(mov => {
                                                            return (<p className="mb-0 text-center" key={mov.auxiliar}>
                                                                {this.state.id_transaccion === "CE" || this.state.id_transaccion === "PE" ?
                                                                    utility.obtener_nombre_pagos(mov.auxiliar, this.props.egresos) :
                                                                    utility.obtener_nombre_pagos(mov.auxiliar, this.props.phxcdc)}
                                                            </p>);
                                                        })}
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-center text-white">-</p>
                                                        {transaccion.t_desglose.map(mov => {
                                                            return (<p className="mb-0 text-center" key={mov.auxiliar}>{mov.abono}</p>);
                                                        })}
                                                    </td>
                                                    <td><p className="mb-0 text-center">{transaccion.importe}</p></td>
                                                    <td><p className="mb-0 text-center">{transaccion.status}</p></td>
                                                </tr>
                                            ))
                                        }
                                    </React.Fragment>
                                )}

                        </tbody>
                    </Table>
                </div>
                <Paginacion actual={this.state.page_actual} count={this.state.pagination.count} paginate={this.handlePage} />
            </React.Fragment>
        );
    }
}
export default TableTransacciones;