/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la consulta de un anticipo. 
*/
import {
  faEye,
  faFileDownload,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "@sweetalert/with-react";
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import * as permisos from "../../../utils/permisos/permisos";
import WebSocketInstance from "../../../utils/websocket";
import * as message from "../messages/messages";
import MyInput from "../../../utils/custom/MyInput";
import MySelect from "../../../utils/custom/MySelect";
import MyTextArea from "../../../utils/custom/MyTextArea";
import CustomSelect from "../../../utils/custom/CustomSelect";
import * as utility from "../utils/utility";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalConsulta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      numero_select: "",
      nombre_select: "",
      nombre_suggestions: [],
      enable_pdf: false,

      bancos: "",
      banco_select_id: "",
      banco_select_text: "Seleccione un banco",
      documento: "",
      importe: "",
      tipo_text: "Seleccione un tipo de deposito",
      tipo_id: "",
      fecha_alta: "",
      fecha_notificacion: "",
      status: "",
      solicitudes: [],
      observaciones: "",
      desactive_input: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],

      data_modificacion: {},
      solicitudes_disponibles: [],

      arr_ingresos: [],
      arr_pagos: [],
      arr_terceros: [],
      arr_bancos: [],
      arr_tipos_notificacion: [
        { clave: "Pt", nombre: "Pago a Terceros" },
        { clave: "An", nombre: "Anticipo" },
        { clave: "Sd", nombre: "Solicitud de Anticipo" },
      ],
      numDeposito: "",
      identificativo:""
    };
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.identificativo;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "transaccion",
      motivo: motivo,
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_ingresos, "ingresos");
    this.methodGET_API(utility.url_contribuciones, "pagos");
    this.methodGET_API(utility.url_phxcc, "tercero");
    this.methodGET_API(utility.url_bancos, "bancos");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  closeModal = () => {
    this.props.toogleConsulta();
    this.setState({
      numDeposito: "",
      numero_select: "",
      nombre_select: "",
      nombre_suggestions: [],
      enable_pdf: false,
      bancos: "",
      banco_select_id: "",
      banco_select_text: "Seleccione un banco",
      documento: "",
      importe: "",
      tipo_text: "Seleccione un tipo de deposito",
      tipo_id: "",
      fecha_alta: "",
      fecha_notificacion: "",
      status: "",
      solicitudes: [],
      observaciones: "",
      desactive_input: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],

      data_modificacion: {},
      solicitudes_disponibles: [],

      arr_ingresos: [],
      arr_pagos: [],
      arr_terceros: [],
      arr_bancos: [],
      arr_tipos_notificacion: [
        { clave: "Pt", nombre: "Pago a Terceros" },
        { clave: "An", nombre: "Anticipo" },
        { clave: "Sd", nombre: "Solicitud de Anticipo" },
      ],
    });
  };
  /* FUNCIONES PARA EL FUNCIONAMIENTO DE ALTAS, MODIFICACIONES Y ETC. */
  handleConsulta = () => {
    if (document.getElementById("id_consulta_deposito").value.length === 0)
      message.message_modal("Advertencia", "Debe tener un ID de Deposito", 400);
    else {
      let data = {
        id_deposito: document.getElementById("id_consulta_deposito").value,
      };
      if (this.props.on_cliente !== false)
        data["cliente"] = this.props.on_cliente;
      this.methodPOST_API(utility.url_consulta_deposito, "consulta", data);
      this.setState({ enable_pdf: true }, () => {
        this.methodPDF_API(
          utility.url_pdf_notificacion,
          "consulta_notificacion",
          this.state.numDeposito
        );
      });
    }
  };
  handleLimpiar = () => {
    this.setState({
      numero_select: "",
      nombre_select: "",
      bancos: "",
      nombre_suggestions: [],
      enable_pdf: false,
      banco_select_id: "",
      banco_select_text: "Seleccione un banco",
      documento: "",
      importe: "",
      tipo_text: "Seleccione un tipo de deposito",
      tipo_id: "",
      fecha_alta: "",
      fecha_notificacion: "",
      status: "",
      solicitudes: [],
      observaciones: "",
      desactive_input: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],

      data_modificacion: {},
      solicitudes_disponibles: [],

      arr_ingresos: [],
      arr_pagos: [],
      arr_terceros: [],
      arr_bancos: [],
      arr_tipos_notificacion: [
        { clave: "Pt", nombre: "Pago a Terceros" },
        { clave: "An", nombre: "Anticipo" },
        { clave: "Sd", nombre: "Solicitud de Anticipo" },
      ],
    });
  };
  handleModifica = () => {
    if (document.getElementById("id_consulta_deposito").value.length === 0)
      message.message_modal("Advertencia", "Debe tener un ID de Depósito", 400);
    else {
      let data_modificacion = this.state.data_modificacion;
      if (utility.objeto_vacio(data_modificacion))
        message.message_modal(
          "Advertencia",
          "No ha modificado algun campo",
          400
        );
      else
        swal({
          icon: "warning",
          closeOnClickOutside: false,
          title: "Realizara una modificación",
          text: "En necesario dar la razon del cambio.",
          buttons: ["Cancelar", true],
          dangerMode: false,
          content: (
            <div>
              <MyTextArea
                bootstrap="col-12"
                id="observaciones_modificacion"
                nameLabel="Observaciones"
              />
            </div>
          ),
        }).then((res) => {
          if (res) {
            this.methodPUT_API(utility.url_alta_deposito, "modifica", {
              id_deposito: document.getElementById("id_consulta_deposito")
                .value,
              observaciones: document.getElementById(
                "observaciones_modificacion"
              ).value,
              ...this.state.data_modificacion,
            });
          }
        });
    }
  };
  handleSolicitud_agrega = () => {
    let sol_select = parseInt(
      document.getElementById("solicitudes_consulta_deposito").value
    );
    let data_modificacion = this.state.data_modificacion;
    let solicitudes = this.state.solicitudes;
    let solicitudes_disponibles = this.state.solicitudes_disponibles;
    let suma = 0;
    if (!data_modificacion.agrega) data_modificacion["agrega"] = [];
    if (Number.isNaN(sol_select))
      message.message_modal(
        "Advertencia",
        "Seleccionar una solicitud valida.",
        400
      );
    else {
      for (let i = 0; i < this.state.solicitudes_disponibles.length; i++)
        if (sol_select === this.state.solicitudes_disponibles[i].id_solicitud) {
          data_modificacion.agrega.push(
            this.state.solicitudes_disponibles[i].id_solicitud
          );
          solicitudes.push(this.state.solicitudes_disponibles[i]);
          solicitudes_disponibles.splice(i, 1);
        }
      for (let i = 0; i < solicitudes.length; i++)
        suma += parseInt(solicitudes[i].importe);
      data_modificacion["importe"] = suma;
      this.setState({
        data_modificacion,
        solicitudes_disponibles,
        solicitudes,
        importe: suma,
      });
    }
  };
  handleSolicitud_elimina = (e) => {
    let sol_select = parseInt(e.target.value);
    let data_modificacion = this.state.data_modificacion;
    let solicitudes = this.state.solicitudes;
    let solicitudes_disponibles = this.state.solicitudes_disponibles;
    let suma = 0;
    if (!data_modificacion.elimina) data_modificacion["elimina"] = [];
    for (let i = 0; i < this.state.solicitudes.length; i++)
      if (sol_select === this.state.solicitudes[i].id_solicitud) {
        data_modificacion.elimina.push(this.state.solicitudes[i].id_solicitud);
        solicitudes_disponibles.push(this.state.solicitudes[i]);
        solicitudes.splice(i, 1);
      }
    for (let i = 0; i < solicitudes.length; i++)
      suma += parseInt(solicitudes[i].importe);
    data_modificacion["importe"] = suma;
    this.setState({
      data_modificacion,
      solicitudes_disponibles,
      solicitudes,
      importe: suma,
    });
  };
  handleCancelar = () => {
    message
      .message_confirmacion_password(
        "¿Desea continuar con la operación modificar?",
        "Ingrese su contraseña y observaciones"
      )
      .then((res) => {
        if (res === true) {
          let mov = {
            user: document.getElementById("usuario_modificador").value,
            pass: document.getElementById("contraseña_modificador").value,
            motivo: document.getElementById("observaciones_textarea").value,
          };
          if (mov.motivo.length === 0)
            swal("Completar el campo:", "Motivo de Cancelación", "warning");
          else
            axios
              .post(utility.url_verify, {
                usuario: mov.user,
                password: mov.pass,
              })
              .then((response) => {
                if (response.status >= 200 && response.status < 400) {
                  this.methodPOST_API(
                    utility.url_cancela_deposito,
                    "cancelar",
                    {
                      id_deposito: document.getElementById(
                        "id_consulta_deposito"
                      ).value,
                      observaciones: mov.motivo,
                    }
                  );
                  this.sendNotificacionHandler("cancelar");
                }
              })
              .catch((error) => {
                if (error.response)
                  message.message_modal(
                    error.response.data.title,
                    error.response.data.text,
                    error.response.status
                  );
              });
        }
      });
  };

  handleNotificar = () => {
    message
      .message_confirmacion_password(
        "¿Desea continuar con la operación modificar?",
        "Ingrese su contraseña y observaciones"
      )
      .then((res) => {
        if (res === true) {
          let mov = {
            user: document.getElementById("usuario_modificador").value,
            pass: document.getElementById("contraseña_modificador").value,
            motivo: document.getElementById("observaciones_textarea").value,
          };
          if (mov.motivo.length === 0)
            swal("Completar el campo:", "Motivo de Notificación", "warning");
          else
            axios
              .post(utility.url_verify, {
                usuario: mov.user,
                password: mov.pass,
              })
              .then((response) => {
                if (response.status >= 200 && response.status < 400) {
                  this.methodPOST_API(
                    utility.url_notifica_depoisto,
                    "notificar",
                    {
                      id_deposito: document.getElementById(
                        "id_consulta_deposito"
                      ).value,
                      observaciones: mov.motivo,
                    }
                  );
                  this.setState(
                    { identificativo: this.state.numDeposito },
                    () => {
                      this.sendNotificacionHandler("crear");
                    }
                  );
                  this.handleCerrar();
                }
              })
              .catch((error) => {
                if (error.response)
                  message.message_modal(
                    error.response.data.title,
                    error.response.data.text,
                    error.response.status
                  );
              });
        }
      });
  };
  /* FUNCIONES PARA EL FUNCIONAMIENTO DE INPUTS */
  handleBanco = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let banco_select_text = e[label];
    let banco_select_id = e[label_id];
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["banco"] = banco_select_id;
    this.setState({ banco_select_text, banco_select_id, data_modificacion });
  };
  handleDocumento = (e) => {
    let documento = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["documento"] = documento;
    this.setState({ documento, data_modificacion });
  };
  handleImporte = (e) => {
    let importe = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["importe"] = importe;
    this.setState({ importe, data_modificacion });
  };
  handleTipo = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_text = e[label];
    let tipo_id = e[label_id];
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["tipo_deposito"] = tipo_id;
    this.setState({ tipo_text, tipo_id, data_modificacion });
  };
  handleObservaciones = (e) => {
    let observaciones = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["observaciones"] = observaciones;
    this.setState({ observaciones, data_modificacion });
  };
  handleNumDeposito = (e) => {
    let numDeposito = e.target.value;
    this.setState({
      numDeposito,
    });
  };

  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "ingresos":
              if (this._isMounted === true)
                this.setState({
                  arr_ingresos: response.data.results,
                });
              break;
            case "pagos":
              if (this._isMounted === true)
                this.setState({
                  arr_pagos: response.data.results,
                });
              break;
            case "tercero":
              if (this._isMounted === true)
                this.setState({
                  arr_terceros: response.data.results,
                });
              break;
            case "bancos":
              if (this._isMounted === true)
                this.setState({
                  arr_bancos: response.data.results,
                });
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  bancoCompleto = (banco) => {
    const selectedValue = banco;
    const [numero] = selectedValue.split(" - ");

    axios
      .post(utility.url_info_bancos, {
        numero,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted) {
            const nombre = response.data.results.nombre;
            let nombreCompleto = banco + " - " + nombre;
            this.setState({ bancos: nombreCompleto });
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "consulta":
              const results = response.data.results;
              const data = Array.isArray(results) ? results[0] : results;

              this.setState(
                {
                  numero_select: data.cliente || "Sin cliente",
                  banco_select_text: this.bancoCompleto(data.banco),
                  documento: data.documento || "Sin documento",
                  importe: data.importe,
                  tipo_text:
                    this.state.arr_tipos_notificacion.find(
                      (tipo) => tipo.clave === data.tipo_deposito
                    )?.nombre || "Sin tipo de depósito",
                  observaciones: data.observaciones || "Sin observaciones",
                  status: data.status,
                  fecha_alta: data.fecha_alta,
                  fecha_notificacion:
                    data.fecha_notificacion || "Sin Notificar",
                  nombre_select: utility.get_name_cliente(
                    data.cliente || "Sin cliente",
                    this.props.clientes
                  ),
                  solicitudes: Array.isArray(results)
                    ? results[0].solicitudes
                    : [],
                },
                () => {
                  this.methodPOST_API(
                    utility.url_solicitudes_disponibles,
                    "solicitudes_cliente",
                    { status: "A", cliente: this.state.numero_select }
                  );
                }
              );

              document.getElementById("tipo_consulta_deposito").value =
                data.tipo_deposito;

              if (
                Array.isArray(results) &&
                results[0].solicitudes.length === 0
              ) {
                this.setState({
                  desactive_input: [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    true,
                    true,
                    false,
                    false,
                  ],
                });
              } else {
                this.setState({
                  desactive_input: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                  ],
                });
              }

              if (
                data.status === "N" ||
                data.status === "C" ||
                data.status === "P"
              ) {
                this.setState({
                  desactive_input: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                  ],
                });
              }
              break;

            case "solicitudes_cliente":
              this.setState({
                solicitudes_disponibles: response.data.results,
              });
              break;
            case "cancelar":
              this.closeModal();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
              case "notificar":
                let numero = document.getElementById("id_consulta_deposito").value;
                let url_pdf_notificacion_con_numero = `${utility.url_pdf_notificacion}${numero}`;
                this.methodPDF_API(utility.url_pdf_notificacion, "notifico", numero);
                this.closeModal();
                swal({
                    icon: "success",
                    title: response.data.title,
                    text: response.data.text,
                    button: true,
                    content: (
                        <div>
                            <LinkD
                                href={url_pdf_notificacion_con_numero} 
                                id="dowload_pdf_notifico_deposito"
                                download={`Notificación de Depósito ${numero}.pdf`} 
                            />
                            <br />
                            <LinkV
                                href={url_pdf_notificacion_con_numero} 
                                id="window_pdf_notifico_deposito"
                                target="_blank"
                            />
                        </div>
                    ),
                });
              this.props.update(1);

              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPUT_API = (url, state, data) => {
    let url_intern = url;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "modifica":
              this.closeModal();
              this.props.update(1);
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma Get para colsultar un PDF.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} num Número del documento.
   */
  methodPDF_API = (url, state, num) => {
    let url_intern = url + `${num}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta_notificacion":
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);
              document.getElementById(
                "dowload_pdf_consulta_notificacion"
              ).href = fileURL;
              document.getElementById("window_pdf_consulta_notificacion").href =
                fileURL;
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") text_add = "";
    else if (numero_cliente.length <= 4) {
      switch (numero_cliente.length) {
        case 1:
          text_add = "000";
          text_add += numero_cliente;
          break;
        case 2:
          text_add = "00";
          text_add += numero_cliente;
          break;
        case 3:
          text_add = "0";
          text_add += numero_cliente;
          break;
        case 4:
          text_add = numero_cliente;
          break;
        default:
          return null;
      }
    } else text_add = "";
    for (let i = 0; i < this.props.clientes.length; i++) {
      if (text_add.length === 0) {
        document.getElementById("nombre_cliente_consulta_deposito").value = "";
        this.suggestionSelected("");
        break;
      }
      if (text_add === this.props.clientes[i].numero) {
        document.getElementById("nombre_cliente_consulta_deposito").value =
          this.props.clientes[i].nombre;
        this.suggestionSelected(this.props.clientes[i].nombre);
        break;
      }
    }
  };
  suggestionSelected = (value) => {
    let number = utility.get_number_cliente(value, this.props.clientes);
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["cliente"] = number;
    if (number === undefined) number = "";
    document.getElementById("numero_cliente_consulta_deposito").value = number;
    if (data_modificacion.cliente === undefined) {
      delete data_modificacion.cliente;
      this.setState({
        nombre_select: value,
        numero_select: number,
        nombre_suggestions: [],
        data_modificacion,
      });
    } else {
      this.setState(
        {
          nombre_select: value,
          numero_select: number,
          nombre_suggestions: [],
          data_modificacion,
        },
        () => {
          this.methodPOST_API(
            utility.url_solicitudes_disponibles,
            "solicitudes_cliente",
            { status: "A", cliente: this.state.numero_select }
          );
        }
      );
    }
  };
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_nombres_clientes(this.props.clientes)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      nombre_suggestions: suggestions,
      nombre_select: value,
    });
  };
  renderSuggestion = () => {
    if (this.state.nombre_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.closeModal}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.closeModal}>
          Consulta Notificación De Depósito
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-10 col-8"
                id="id_consulta_deposito"
                nameLabel="ID Deposito"
                value={this.state.numDeposito}
                onChange={this.handleNumDeposito}
              />
              <Button
                color="primary"
                className="col-lg-2 col-4 mb-2"
                onClick={this.handleConsulta}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="numero_cliente_consulta_deposito"
                nameLabel="Número de cliente"
                disabled={this.state.desactive_input[0]}
                value={this.state.numero_select}
                onChange={this.nameGenerator}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="nombre_cliente_consulta_deposito"
                nameLabel="Nombre de cliente"
                disabled={this.state.desactive_input[1]}
                value={this.state.nombre_select}
                onChange={this.onNameChange}
              />
              {this.renderSuggestion()}
            </FormGroup>
            <FormGroup className="row mb-0">
              <CustomSelect
                bootstrap="col-lg-6 col-12"
                first_option={this.state.banco_select_text}
                name_text="banco"
                name_index="numero"
                id="banco_consulta_deposito"
                nameLabel="Banco"
                value={this.state.bancos}
                onChange={this.handleBanco}
                disabled={this.state.desactive_input[2]}
                options={this.state.arr_bancos}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="documento_consulta_deposito"
                nameLabel="Documento"
                disabled={this.state.desactive_input[3]}
                value={this.state.documento}
                onChange={this.handleDocumento}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="importe_consulta_deposito"
                nameLabel="Importe"
                disabled={this.state.desactive_input[4]}
                value={this.state.importe}
                onChange={this.handleImporte}
              />
              <CustomSelect
                bootstrap="col-lg-6 col-12"
                name_text="nombre"
                name_index="clave"
                id="tipo_consulta_deposito"
                nameLabel="Tipo de deposito"
                value={this.state.tipo_text}
                onChange={this.handleTipo}
                disabled={this.state.desactive_input[5]}
                options={this.state.arr_bancos}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="fecha_alta_consulta_deposito"
                nameLabel="Fecha de alta"
                disabled={this.state.desactive_input[6]}
                value={this.state.fecha_alta}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="fecha_notifiacion_consulta_deposito"
                nameLabel="Fecha de Notificación"
                disabled={this.state.desactive_input[7]}
                value={this.state.fecha_notificacion}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="status_consulta_deposito"
                nameLabel="Estado"
                disabled={this.state.desactive_input[8]}
                value={this.state.status}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyTextArea
                type="text"
                bootstrap="col-12"
                id="observaciones_consulta_deposito"
                nameLabel="Observaciones"
                disabled={this.state.desactive_input[9]}
                value={this.state.observaciones}
                onChange={this.handleObservaciones}
              />
            </FormGroup>
            <FormGroup
              className={`row mb-0 ${
                this.state.tipo !== "SAn" ? "d-none" : ""
              }`}
            >
              <MySelect
                bootstrap="col-lg-10 col-8"
                id="solicitudes_consulta_deposito"
                nameLabel="Solictudes del cliente"
                first_option="Seleccione una solicitud"
                disabled={
                  this.state.tipo === "SAn"
                    ? this.state.status === "A"
                      ? false
                      : true
                    : true
                }
              >
                {this.state.solicitudes_disponibles.map((sol) => (
                  <option key={sol.id_solicitud} value={sol.id_solicitud}>
                    {sol.id_solicitud}
                  </option>
                ))}
              </MySelect>
              <Button
                color="primary"
                className="col-lg-2 col-4 mb-2"
                onClick={this.handleSolicitud_agrega}
                disabled={
                  this.state.tipo === "SAn"
                    ? this.state.status === "A"
                      ? false
                      : true
                    : true
                }
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </FormGroup>
          </Form>
          <Table
            size="sm"
            className={`${this.state.tipo !== "SAn" ? "d-none" : ""}`}
            bordered
            hover
            responsive
          >
            <thead className="text-center header-table">
              <tr>
                <td>No. Solicitud</td>
                <td>Referencia</td>
                <td>Tipo de Movimiento</td>
                <td>Concepto</td>
                <td>Cargo</td>
                <td></td>
              </tr>
            </thead>
            <tbody className="body-table">
              {this.state.solicitudes.map((sol, i, array) => (
                <tr key={sol.id_solicitud}>
                  <td className="text-center">{sol.id_solicitud}</td>
                  <td className="text-center">{sol.referencia}</td>
                  <td>
                    <p className="mb-0 text-white">-</p>
                    {sol.movimientos.map((mov, index, array) => {
                      return (
                        <p
                          className="mb-0 text-center mx-auto"
                          style={{ width: "170px" }}
                          key={mov.tipo_solicitud + "mov" + index}
                        >
                          {utility.tipo_solicitud(mov.tipo_solicitud)}
                        </p>
                      );
                    })}
                  </td>
                  <td className="text-center">
                    <p className="mb-0 text-white">-</p>
                    {sol.movimientos.map((mov, index, array) => {
                      return (
                        <p
                          className="mb-0 text-center"
                          key={mov.clave_concepto + "con" + index}
                        >
                          {utility.get_type_concepto(
                            mov.clave_concepto,
                            mov.tipo_solicitud,
                            this.state.arr_ingresos,
                            this.state.arr_pagos,
                            this.state.arr_terceros
                          )}
                        </p>
                      );
                    })}
                  </td>
                  <td>
                    <p className="mb-0 text-white">-</p>
                    {sol.movimientos.map((mov, index, array) => {
                      return (
                        <p
                          className="mb-0 text-center mx-auto"
                          style={{ width: "90px" }}
                          key={mov.monto + "car" + index}
                        >
                          $ {mov.monto}
                        </p>
                      );
                    })}
                  </td>
                  <td className="text-center">
                    <Button
                      color="danger"
                      size="sm"
                      value={sol.id_solicitud}
                      onClick={this.handleSolicitud_elimina}
                      disabled={
                        this.state.tipo === "SAn"
                          ? this.state.status === "A"
                            ? false
                            : true
                          : true
                      }
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="5" className="text-right">
                  <p className="mb-0 pl-2 mt-2 font-weight-bold">TOTAL:</p>
                </td>
                <td className="text-center">
                  <Input
                    className="mx-auto"
                    disabled={this.state.desactive_input[10]}
                    onChange={this.handleImporte}
                    value={this.state.importe}
                    style={{ width: "80px" }}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
          <FormText color="muted" className="row mb-1 text-center">
            Si la Notificación de depósito contiene alguna solicitud de
            anticipo, sólo podrá agregar o quitar solicitudes. Para modificar
            otro campo, deberá eliminar las solicitudes de la notificación.
          </FormText>
          <br></br>
          <div className="d-flex justify-content-center">
            <LinkD
              href=""
              id="dowload_pdf_consulta_notificacion"
              download={`Notificación de Depósito ${this.state.numDeposito}`}
            />
            <LinkV
              href=""
              id="window_pdf_consulta_notificacion"
              target={this.state.enable_pdf ? "_blank" : "_self"}
              className="ml-5"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="success"
            className="mr-md-auto"
            onClick={this.handleNotificar}
            disabled={
              this.state.status === "A" &&
              !permisos.obtener_permiso_especifico(
                "notificar",
                this.props.permisos
              )
                ? false
                : true
            }
          >
            Notificar
          </Button>
          <Button
            type="button"
            color="danger"
            onClick={this.handleCancelar}
            disabled={
              this.state.status === "A" &&
              !permisos.obtener_permiso_especifico(
                "cancelar",
                this.props.permisos
              )
                ? false
                : true
            }
          >
            Cancelar
          </Button>
          <Button
            color="info"
            onClick={this.handleModifica}
            disabled={
              this.state.status === "A" &&
              !permisos.obtener_permiso_especifico(
                "modificar",
                this.props.permisos
              )
                ? false
                : true
            }
          >
            Modificar
          </Button>
          <Button color="primary" onClick={this.closeModal}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalConsulta;
