/* 
    Descripción: Componente general de la aplicación poliza. 
*/
import axios from "axios";
import React, { Component } from "react";
import * as message from "./messages/messages";
import * as utility from "./utils/utility";
import SearchBar from "./SearchBar";
import TablePoliza from "./Table";
import * as permisos from "../../utils/permisos/permisos";

class Polizas extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      clientes: [],
      bancos: [],
      checked: [],
      actualizar: 0,
      actualizar_con_dev: 0,
      permisos: permisos.obtener_permisos_by_app(
        "poliza",
        localStorage.getItem("perm")
      ),
      on_cliente:
        localStorage.getItem("puesto") === "Cliente"
          ? localStorage.getItem("id_usr")
          : false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getClientes();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

 /*  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "clientes":
              if (this._isMounted === true)
                this.setState({ clientes: response.data.results });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }; */

  getClientes (){
    const url = utility.url_clientes;
    axios.get(url).then(response =>{
     if (response.status >= 200 && response.status < 400){
         if (this._isMounted)
         this.setState({
          clientes: response.data.results
         });
     }
   })
   .catch((error) => {
     if (error.response)
       message.message_modal(
         error.response.data.title,
         error.response.data.text,
         error.response.status
       );
   });
  }
  
  /* methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "clientes":
              if (this._isMounted === true)
                this.setState({ clientes: response.data });
              break;
            case "referencias":
              if (this._isMounted === true)
                this.setState({ referencias: response.data.results });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }; */
  update = (cambio) => {
    this.setState({ actualizar: cambio });
  };
  update_con_dev = (cambio) => {
    this.setState({ actualizar_con_dev: cambio });
  };
  onCheckedSol = (data) => {
    this.setState({
      checked: data,
    });
  };

  render() {
    return (
      <div className="container-fluid">
        <SearchBar
          clientes={this.state.clientes}
          bancos={this.state.bancos}
          update={this.update}
          update_con_dev={this.update_con_dev}
          checked={this.state.checked}
          permisos={this.state.permisos}
          on_cliente={this.state.on_cliente}
        />
        <TablePoliza
          clientes={this.state.clientes}
          bancos={this.state.bancos}
          tipo_movimiento={this.state.tipo_movimiento}
          update={this.update}
          update_con_dev={this.update_con_dev}
          actualizar_con_dev={this.state.actualizar_con_dev}
          actualizar={this.state.actualizar}
          checked={this.onCheckedSol}
          on_cliente={this.state.on_cliente}
          permisos={this.state.permisos}
        />
      </div>
    );
  }
}
export default Polizas;
