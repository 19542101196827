
import React, { Component } from "react";
import { ButtonGroup, Button } from "reactstrap";
import ModalAlta from "./ModalAlta";

class ModalAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toogle_nuevo: false,
      toogle_consulta: false,
      on_back: false,
    };
    this.toogleNuevo = this.toogleNuevo.bind(this);
    this.toogleConsulta = this.toogleConsulta.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  toogleNuevo = () => {
    this.setState((prevState) => ({ toogle_nuevo: !prevState.toogle_nuevo }));
  };
  toogleConsulta = () => {
    this.setState((prevState) => ({
      toogle_consulta: !prevState.toogle_consulta,
    }));
  };
  desgloseCuenta = () => {
    this.props.desglose_cuenta(1);
  };
  onBack = () => {
    this.props.update(1);
    this.props.on_back(false);
  };
  render() {
    return (
      <div className="col-lg-3 col-sm-12 d-flex justify-content-center flex-column mb-2 my-md-2">
        <ButtonGroup className="mb-1">
          <Button color="primary" onClick={this.toogleNuevo}>
            Complemento De Pago
          </Button>
          <Button color="success" onClick={this.toogleConsulta}>
            Cierre Contable
          </Button>
        </ButtonGroup>
        <ModalAlta
          toogle_nuevo={this.state.toogle_nuevo}
          toogleNuevo={this.toogleNuevo}
          codigos_sat={this.props.codigos_sat}
          update={this.props.update}
        />
      </div>
    );
  }
}
export default ModalAction;
