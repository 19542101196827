import swal from 'sweetalert';

/**
 * Función que permite retornar el mensaje pertienente a su llamado.
 * @param {String} title Titulo del mensaje. 
 * @param {String} text Texto de la información importante del mensaje. 
 * @param {Number} estado Status HTTP.
 */
export const message_modal = (title, text, estado) => {
    if (estado >= 100 && estado <= 199)
        return swal(title, text, "info");
    else if (estado >= 200 && estado <= 299)
        return swal(title, text, "success");
    else if (estado >= 300 && estado <= 299)
        return swal(title, text, "warning");
    else if (estado >= 400 && estado <= 499)
        return swal(title, text, "warning");
    else
        return swal(title, text, "error");
}
export const message_confimacion = (title, text) => {
    return swal({
      icon: "warning",
      closeOnClickOutside: false,
      title: title,
      text: text,
      buttons: ["Cancelar", true],
      dangerMode: false,
    });
  };
  
export const warningMessage = (text) => {
    return swal({
      icon: "warning",
      closeOnClickOutside: false,
      title: "Advertencia",
      text: text
    });
  };


  export const message_success = (title, text) => {
    return swal({
      icon: "success",
      closeOnClickOutside: false,
      title: title,
      text: text,
    });
  };