export const url_pagination_phxcdc = '/DjangoWeb/BaseApp/consulta/cat_phxcdc/paginacion';
export const url_crea_phxcdc = '/DjangoWeb/BaseApp/crea_modifica/cat_phxcdc';
export const url_consulta_phxcdc = '/DjangoWeb/BaseApp/consulta/cat_phxcdc';


export const url_crea_cuentas_aux = '/DjangoWeb/CuentasAuxiliaresApp/crea_modifica/cuenta_auxiliares';
export const url_crea_auxiliares = '/DjangoWeb/CuentasAuxiliaresApp/crea_modifica/control_auxiliar/cuenta';
export const url_consulta_auxiliares = '/DjangoWeb/CuentasAuxiliaresApp/consulta/cuenta_auxiliar';
export const url_pagination_cuentas = '/DjangoWeb/CuentasAuxiliaresApp/consulta/cuentas_auxiliares/paginacion/principal';
export const url_codigos_sat = '/DjangoWeb/BaseApp/consulta/codigos_sat/lista';
export const url_auxiliares = '/DjangoWeb/CuentasAuxiliaresApp/consulta/cuentas_auxiliares/paginacion/principal';

/**
 * Función que nos permite filtar la tabla por el input general que serra de tipo text.
 */
 export const general_search = (e) => {
    e.preventDefault();
    let tr = document.getElementById('tabla_phxcdc').getElementsByTagName('tr');
    let data_input = document.getElementById('busqueda_general_phxcdc').value.toUpperCase();
    for (let i = 0; i < tr.length; i++) {
        let mostrar = false;
        let data = tr[i].getElementsByTagName('p');
        for (let j = 0; j < data.length; j++) {
            if (data_input === "") {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
            else if (data[j].textContent.toUpperCase().includes(data_input)) {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "rgba(0,123,255,1)";
                data[j].style.color = "#fff";
            }
            else {
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que nos permite filtrar latabla solamnete por la fecha.
 */
export const date_search = (e) => {
    e.preventDefault();
    var tabla_anticipo, input_general, tr, dato, input_dato, mostrar;
    tabla_anticipo = document.getElementById("tabla_proveedoress");
    input_general = document.getElementById("busqueda_date_proveedores");
    input_dato = input_general.value;
    tr = tabla_anticipo.getElementsByTagName("tr");
    for (var i = 0; i < tr.length; i++) {
        mostrar = false;
        dato = tr[i].getElementsByTagName("p");
        for (var j = 0; j < dato.length; j++) {
            if (dato[j].textContent === input_dato) {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "rgba(0,123,255,1)";
                dato[j].style.color = "#fff";
            }
            else if (input_dato === "") {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
            else {
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que valida los datos vacios para la petición POST
 * @param {Object} data Objeto que contiene los datos para POST 
 * @return {String} Mensaje de los campos vacios.
 */
 export const validadCamposPOST = (data) => {
    let message = "";
    if (data.nombre.length === 0)
        message += "Nombre del Ingreso, ";
    if (data.corto.length === 0)
        message += "Nombre corto, ";

    message = message.slice(0, message.length - 2);
    return message;
}

export const valida_cuenta = (mayor, subcuenta, nivel) => {
    let cuenta = "";
    if (nivel === 1)
        cuenta = mayor+"-000-0000";
    if (nivel === 2)
        cuenta = `${mayor}-`;
    if (nivel === 3)
        cuenta = `${mayor}-${subcuenta}`;
    return cuenta;
}