/* ---------- URL para consumos de Solicitud de Pagos a Terceros ---------- */
export const url_paginacion = '/DjangoWeb/PagoTercerosApp/consulta/pagoterceros/paginacion/principal';
export const url_alta_anticipo = '/DjangoWeb/PagoTercerosApp/crea_modifica/pagoterceros';
export const url_consulta_anticipo = "/DjangoWeb/PagoTercerosApp/consulta/pagoterceros";
export const url_consolida = '/DjangoWeb/PagoTercerosApp/consolida/pagoterceros';
export const url_devuelve = '/DjangoWeb/PagoTercerosApp/devolucion/pagoterceros';
export const url_modifica_anticipo = '/DjangoWeb/PagoTercerosApp/crea_modifica/pagoterceros';
export const url_pdf_alta_anticipo = "/DjangoWeb/ExpedienteApp/descargar/archivo_pdf/pago_tercero";
export const url_tipo_movimiento = "/DjangoWeb/PagoTercerosApp/consulta/pagoterceros/catalogo";
export const url_verify = '/DjangoWeb/UsuariosApp/verica/usuario';
export const url_pdf_poliza = '/DjangoWeb/PolizasApp/consulta/polizas/pdf/';
export const url_pdf_devolucion = '/DjangoWeb/PagotercerosApp/consulta/devolucion/pagoterceros/pdf/';

export const url_bancos = '/DjangoWeb/BaseApp/consulta/bancos/lista';
export const url_clientes = '/DjangoWeb/BaseApp/consulta/clientes/lista';
export const url_info_bancos = '/DjangoWeb/BaseApp/consulta/bancos'; 
 // export const url_pagos_terceros = '/DjangoWeb/PagoTerceros'


 //Reportes Pagos Terceros
export const url_reportes_pagos_terceros_activos = 'DjangoWeb/ReportesApp/pagos_terceros_app/pagos_terceros_activos';
export const url_pdf_reporte_pagos_terceros_activos = 'DjangoWeb/ReportesApp/pagos_terceros_app/pagos_terceros_activos/pdf/';
export const url_reportes_pagos_terceros_desglose = 'DjangoWeb/ReportesApp/pagos_terceros_app/pagos_terceros_desglose';
export const url_pdf_reporte_pagos_terceros_desglose = 'DjangoWeb/ReportesApp/pagos_terceros_app/pagos_terceros_desglose/pdf/';


/**
 * Función que nos permite filtar la tabla por el input general que serra de tipo text.
 */
export const general_search = (e) => {
    e.preventDefault();
    let tr = document.getElementById('tabla_anticipos').getElementsByTagName('tr');
    let data_input = document.getElementById('busqueda_general_anticipos').value.toUpperCase();
    for (let i = 0; i < tr.length; i++) {
        let mostrar = false;
        let data = tr[i].getElementsByTagName('p');
        for (let j = 0; j < data.length; j++) {
            if (data_input === "") {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
            else if (data[j].textContent.toUpperCase().includes(data_input)) {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "rgba(0,123,255,1)";
                data[j].style.color = "#fff";
            }
            else {
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que nos permite filtrar latabla solamnete por la fecha.
 */
export const date_search = (e) => {
    e.preventDefault();
    var tabla_anticipo, input_general, tr, dato, input_dato, mostrar;
    tabla_anticipo = document.getElementById("tabla_anticipos");
    input_general = document.getElementById("busqueda_date_antcipos");
    input_dato = input_general.value;
    tr = tabla_anticipo.getElementsByTagName("tr");
    for (var i = 0; i < tr.length; i++) {
        mostrar = false;
        dato = tr[i].getElementsByTagName("p");
        for (var j = 0; j < dato.length; j++) {
            if (dato[j].textContent === input_dato) {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "rgba(0,123,255,1)";
                dato[j].style.color = "#fff";
            }
            else if (input_dato === "") {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
            else {
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/* ---------- Funciones para el proceso de información de Anticipos ---------- */
/**
 * Función que permite obtener el nivel que tendra un usuario en la aplicación dada.
 * @param {Array} apps Arreglo de apps que puede ver el usuario.
 * @param {String} actual_app Numero de app a buscar su nivel. 
 * @returns {Number} Nivel de la app.
 */
export const get_nivel_app = (apps, actual_app) => {
    for (let i = 0; i < apps.length; i++)
        if (apps[i].app === actual_app)
            return apps[i].nivel;
}
/**
 * Nos permite ordenar los anticipos por cliente.
 * @param {array} arreglo Arreglo de la paginación de Anticipos
 */
export const anticipo_by_cliente = (arreglo) => {
    let array_filter = [...arreglo];
    let aux;
    for (let i = 1; i < array_filter.length; i++)
        for (let j = 0; j < (array_filter.length - i); j++)
            if (parseInt(array_filter[j].cliente) > parseInt(array_filter[j + 1].cliente)) {
                aux = array_filter[j];
                array_filter[j] = array_filter[j + 1];
                array_filter[j + 1] = aux;
            }
    return (array_filter);
}
/**
 * 
 * @param {number} cliente Número de cliente.
 * @param {array} arreglo Arreglo de anticipos.
 */
export const repeticion_cliente = (cliente, arreglo) => {
    let contador = 0;
    for (let i = 0; i < arreglo.length; i++)
        if (cliente === parseInt(arreglo[i].cliente))
            contador++;
    return contador;
}
/**
 * Función que permite retornar el nombre de un cliente ne especifico.
 * @param {String} numero Número de cliente.
 * @param {Array} array Arreglo de clientes.
 * @returns {String} Nombre del cliente.
 */
export const get_name_cliente = (numero, array) => {
    let complete_number = "";
    switch (numero.length) {
        case 1: complete_number = '000' + numero; break;
        case 2: complete_number = '00' + numero; break;
        case 3: complete_number = '0' + numero; break;
        case 4: complete_number = numero; break;
        default: break;
    }
    for (let i = 0; i < array.length; i++)
        if (complete_number === array[i].numero)
            return (array[i].nombre);
}
/**
 * Función que permite sacar la direfncia de dias dada una fecha con respecto a la actual.
 * @param {Date} fin Fecha límite.
 * @returns {number} Dias de diferencia. 
 */
export const dif_fechas = (fin) => {
    let fecha_inicial = new Date();
    let fecha_final = new Date(fin);

    return fin === "" ? "" : Math.ceil((fecha_final - fecha_inicial) / (60 * 60 * 24 * 1000));
}
/**
 * Función que permir}te retonar la fecha y hora actual.
 * @returns {Object} Fecha y hora actual.
 */
export const get_fecha_actual = () => {
    const actual = new Date();
    return {
        fecha: actual.toISOString().slice(0, 10),
    }
}

/**
 * Función que obtiene los nombres de todos los clientes
 * @param {array} arreglo 
 * @returns {array} Arreglo de nombres.
 */
export const get_nombres_clientes = (arreglo) => {
    var aux = [];
    for (var i = 0; i < arreglo.length; i++) {
        aux.push(arreglo[i].nombre);
    }
    return (aux);
}
/**
 * Finción que permite el retorno del número de cliente por su nombre.
 * @param {Sring} nombre Nombre del cliente. 
 * @param {Array} array Arreglo de clientes.
 * @returns {String} Número del cliente.
 */
export const get_number_cliente = (nombre, array) => {
    let only_number = "";
    let bandera = 0;
    for (let i = 0; i < array.length; i++)
        if (nombre === array[i].nombre) {
            for (let j = 0; j < 4; j++) {
                if (array[i].numero[j] !== '0')
                    bandera = 1;
                if (bandera === 1)
                    only_number += array[i].numero[j];
            }
            return (only_number);
        }
}
export const valida_devolucion = (array) => {
    for (let i = 0; i < array.length; i++)
        if (array[i].status !== 'C')
            return false
    return true;
}
export const valida_cancelar = (array) => {
    return array.some(item => item.status === 'A');
}

export const valida_consolidacion = (array) => {
    for (let i = 0; i < array.length; i++)
        if (array[i].status === 'C')
            return false
    return true
}
export const tipo_movimiento = (num, array) => {
    for (let i = 0; i < array.length; i++)
        if (num === array[i].clave)
            return (array[i].nombre);
}

export const obtener_numeros_consolidacion = (array) => {
    let arreglo = [];
    for (let i = 0; i < array.length; i++)
        arreglo.push(array[i].numero);
    return arreglo;
}
/* ------------------------------------------- Funciones para cambio de input desactivado ----------------------------------------------- */
export const add_class_disabled = (id) => {
    document.getElementById(id).classList.add('input-animatedd');
}
