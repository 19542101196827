import { faEye, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Table,
  Tooltip,
} from "reactstrap";
import * as utility from "../utils/utility";
import * as message from "../messages/messages";
import axios from "axios";
import swal from "@sweetalert/with-react";
import MyInput from "../utils/MyInput";
import ModalModifiPolizas from "./ModalModifiPolizas";
import * as permisos from "../../../utils/permisos/permisos";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalConsulta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      numero: "",
      fecha: "",
      firstFecha: "",
      firstSubTipo: "",
      subtipo: "",
      tipo: "",
      concepto: "",
      firstConcepto: "",
      movimientos: [],
      enable_pdf: false,
      numeroPoliza: "",
      consecutivo: "",
      toogle_consulta_sub: false,
      showTooltip: false,
    };
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  
  toggleTooltip() {
    this.setState((prevState) => ({
      showTooltip: !prevState.showTooltip,
    }));
  }

  handleConsulta = () => {
    if (this.state.numero === "" || this.state.tipo === "")
      message.warningMessage("Por favor, ingrese los campos faltantes");
    else {
      this.methodPOST_API(utility.url_consulta_poliza, "consulta", {
        numero_poliza: this.state.numero,
        tipo: this.state.tipo,
      });
      const btnSubTipo = document.getElementById("subtipo_consulta_poliza");
      btnSubTipo.disabled = false;
      const btnConcepto = document.getElementById("concepto_consulta_poliza");
      btnConcepto.disabled = false;
      const btnFecha = document.getElementById("fecha_consulta_poliza");
      btnFecha.disabled = false;
    }
  };
  handleNumero = (e) => {
    let numero = e.target.value;
    this.setState({ numero });
  };
  handleFecha = (e) => {
    let fecha = e.target.value;
    this.setState({ fecha });
  };
  handleSubtipo = (e) => {
    let subtipo = e.target.value;
    this.setState({ subtipo });
  };
  handleTipo = (e) => {
    let tipo = e.target.value;
    this.setState({ tipo });
  };
  handleConcepto = (e) => {
    let concepto = e.target.value;
    this.setState({ concepto });
  };
  handleCerrar = () => {
    this.props.toogleConsulta();
    this.handleLimpiar();
  };
  handleLimpiar = () => {
    this.setState({
      numero: "",
      fecha: "",
      subtipo: "",
      tipo: "",
      concepto: "",
      movimientos: [],
      enable_pdf: false,
    });
  };
  /**
   * Función que permite hacer una petición de forma POST.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} data Objeto a mandar atraves del metodo POST.
   */
  methodPOST_API = (url, state, data) => {
    /*  SI SE REALIZA UNA OPERACION QUE DEBE CERRAR EL MODAL, MOSTAR UN MENSAJE Y ACTUALIZAR LA 
        TABLA PRINCIPAL ESTA ES LA ESTRCUTURA.

        this.props.toogleXXXXX(); -> FUNCIÓN QUE CIRRA EL MODAL.
        message.message_modal(response.data.title, response.data.text, response.status); -> MENSAJE A MOSTAR.
        this.props.update(1); -> ACTUALIZACIÓN DE LA TABLA PRINCIPAL.   */

    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "alta":
              break;
            case "modificacion":
              break;
            case "consulta":
              this.setState({ enable_pdf: true }, () => {
                this.methodPDF_API(
                  utility.url_pdf_poliza,
                  "consulta_poliza",
                  `${utility.tipo_poliza_number(this.state.tipo)}/${
                    this.state.numero
                  }`
                );
              });
              this.setState({
                fecha: response.data.results.fecha,
                subtipo: response.data.results.subtipo,
                tipo: response.data.results.tipo,
                concepto: response.data.results.concepto,
                movimientos: response.data.results.movimientos,
                numeroPoliza: response.data.results.numero,
                consecutivo: response.data.results.consecutivo,
              });
              const firstConcepto = document.getElementById(
                "concepto_consulta_poliza"
              ).value;
              this.setState({ firstConcepto: firstConcepto });
              const firstSubTipo = document.getElementById(
                "subtipo_consulta_poliza"
              ).value;
              this.setState({ firstSubTipo: firstSubTipo });
              const firstFecha = document.getElementById(
                "fecha_consulta_poliza"
              ).value;
              this.setState({ firstFecha: firstFecha });
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma Get para colsultar un PDF.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} num Número del documento.
   */
  methodPDF_API = (url, state, num) => {
    let url_intern = url + `${num}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta_poliza":
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);
              document.getElementById("dowload_pdf_consulta_poliza").href =
                fileURL;
              document.getElementById("window_pdf_consulta_poliza").href =
                fileURL;
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handleModifMov = (
    id,
    cliente,
    referencia,
    auxiliar,
    documento,
    tipo_mov,
    cargo,
    abono
  ) => {
    this.setState({
      modificarId: id,
      modificarCliente: cliente,
      modificarRef: referencia,
      modificarAux: auxiliar,
      modificarDoc: documento,
      modificarTipoMov: tipo_mov,
      modificarCargo: cargo,
      modificarAbono: abono,
    });

    this.setState((prevState) => ({
      toogle_consulta_sub: !prevState.toogle_consulta_sub,
    }));
  };

  handleModificar = () => {
    if (
      this.state.firstConcepto != this.state.concepto ||
      this.state.firstSubTipo != this.state.subtipo ||
      this.state.firstFecha != this.state.fecha
    ) {
      message
        .message_confirmacion_password(
          "¿Desea continuar con la operación modificar?",
          "Ingrese su contraseña y observaciones"
        )
        .then((res) => {
          if (res === true) {
            let mov = {
              user: document.getElementById("usuario_modificador").value,
              pass: document.getElementById("contraseña_modificador").value,
              motivo: document.getElementById("observaciones_textarea").value,
            };
            if (mov.motivo.length === 0)
              swal("Completar el campo:", "Motivo de Notificación", "warning");
            else
              axios
                .post(utility.url_verify, {
                  usuario: mov.user,
                  password: mov.pass,
                })
                .then((response) => {
                  if (response.status >= 200 && response.status < 400) {
                    if (this._isMounted) {
                      //Comienza el post para modificar poliza cabecera en subtipo, concepto y fecha
                      if (
                        this.state.subtipo === "" &&
                        this.state.concepto === "" &&
                        this.state.fecha === ""
                      ) {
                        message.warningMessage(
                          "Complete todos los campos correspondientes"
                        );
                      } else {
                        axios
                          .post(utility.url_modificar_poliza, {
                            consecutivo: this.state.consecutivo,
                            subtipo: this.state.subtipo,
                            concepto: this.state.concepto,
                            fecha: this.state.fecha,
                            observaciones: mov.motivo,
                          })
                          .then((response) => {
                            if (
                              response.status >= 200 &&
                              response.status < 400
                            ) {
                              if (this._isMounted) {
                                swal({
                                  icon: "success",
                                  title: response.data.title,
                                  text: response.data.text,
                                  button: true,
                                });
                              }
                              this.props.update(1);
                            }
                          })
                          .catch((error) => {
                            if (error.response)
                              message.message_modal(
                                error.response.data.title,
                                error.response.data.text,
                                error.response.status
                              );
                          });
                      }
                    }
                  }
                })
                .catch((error) => {
                  if (error.response)
                    message.message_modal(
                      error.response.data.title,
                      error.response.data.text,
                      error.response.status
                    );
                });
          }
        });
    } else {
      message.warningMessage("No existió alguna modificación");
    }
  };

  sumarAbonos() {
    let totalAbonos = 0;
    this.state.movimientos.forEach((mov) => {
      totalAbonos += parseFloat(mov.abono);
    });
    return totalAbonos.toFixed(2);
  }

  sumarCargos() {
    let totalCargos = 0;
    this.state.movimientos.forEach((mov) => {
      totalCargos += parseFloat(mov.cargo);
    });
    return totalCargos.toFixed(2);
  }

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>Consulta Poliza</ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-5 col-12"
                id="numero_consulta_poliza"
                nameLabel="Número de Poliza"
                value={this.state.numero}
                onChange={this.handleNumero}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-5 col-12"
                id="tipo_consulta_poliza"
                nameLabel="Tipo"
                value={this.state.tipo}
                onChange={this.handleTipo}
              />
              <Button
                color="primary"
                className="col-lg-2 col-12 mb-2"
                onClick={this.handleConsulta}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="date"
                bootstrap="col-lg-4 col-12"
                id="fecha_consulta_poliza"
                nameLabel="Fecha"
                value={this.state.fecha}
                onChange={this.handleFecha}
                disabled={true}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="subtipo_consulta_poliza"
                nameLabel="Subtipo"
                value={this.state.subtipo}
                onChange={this.handleSubtipo}
                disabled={true}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="concepto_consulta_poliza"
                nameLabel="Concepto"
                value={this.state.concepto}
                onChange={this.handleConcepto}
                disabled={true}
              />
            </FormGroup>
          </Form>
          <Table size="sm" id="tabla_poliza" bordered hover responsive>
            <thead className="text-center header-table">
              <tr>
                <th>Cliente</th>
                <th>Referencia</th>
                <th>Auxiliar</th>
                <th>Documento</th>
                <th>Tipo de Mov.</th>
                <th>Cargo</th>
                <th>Abono</th>
                <td></td>
              </tr>
            </thead>
            <tbody className="body-table">
              {this.state.movimientos.map((mov) => (
                <tr key={mov.movimiento}>
                  <td>
                    <p className="mb-0 text-center">{mov.cliente}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{mov.referencia}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{mov.auxiliar}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{mov.documento}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{mov.tipo_mov}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{mov.cargo}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{mov.abono}</p>
                  </td>
                  <td className="text-center">
                    <Button
                      id="boton"
                      color="info"
                      size="sm"
                      onClick={() =>
                        this.handleModifMov(
                          mov.id,
                          mov.cliente,
                          mov.referencia,
                          mov.auxiliar,
                          mov.documento,
                          mov.tipo_mov,
                          mov.cargo,
                          mov.abono
                        )
                      }
                      disabled={permisos.obtener_permiso_especifico(
                        "modificar",
                        this.props.permisos
                      )}
                    >
                      Modificar
                    </Button>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="5"></td>
                <td>
                  <p
                    id="tooltip_poliza"
                    className={`mb-0 text-center font-weight-bold ${
                      this.sumarAbonos() !== this.sumarCargos()
                        ? "text-danger"
                        : this.sumarCargos() !== "0.00"
                        ? "text-success"
                        : "text-black"
                    }`}
                  >
                    Total: {this.sumarCargos()}
                  </p>
                </td>
                <td>
                  <p
                    id="tooltip_poliza"
                    className={`mb-0 text-center font-weight-bold ${
                      this.sumarAbonos() !== this.sumarCargos()
                        ? "text-danger"
                        : this.sumarCargos() !== "0.00"
                        ? "text-success"
                        : "text-black"
                    }`}
                  >
                    Total: {this.sumarAbonos()}
                  </p>
                </td>
              </tr>
            </tbody>
            <div className="text-center" data-toggle="tooltip">
              {this.sumarAbonos() !== this.sumarCargos() && (
                <Tooltip
                  placement="top"
                  isOpen={this.state.showTooltip}
                  target="tooltip_poliza"
                  toggle={this.toggleTooltip}
                >
                  Existe diferencia entre Cargo y Abono.
                </Tooltip>
              )}
            </div>
          </Table>
          <div className="d-flex justify-content-center">
            <LinkD
              href="#"
              id="dowload_pdf_consulta_poliza"
              download={`Poliza ${this.state.numero}`}
            />
            <LinkV
              href="#"
              id="window_pdf_consulta_poliza"
              target={this.state.enable_pdf ? "_blank" : "_self"}
              className="ml-5"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={this.handleModificar}
            disabled={
              this.state.subtipo === ""
                ? true
                : false && this.state.concepto === ""
                ? true
                : false && this.state.fecha === ""
                ? true
                : false && permisos.obtener_permiso_especifico(
                  "modificar",
                  this.props.permisos
                )
            }
          >
            Modificar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
        {this.state.toogle_consulta_sub && (
          <ModalModifiPolizas
          toogle_consulta={this.state.toogle_consulta_sub}
          toogleConsulta={this.handleModifMov}
          polizaId={this.state.modificarId}
          polizaRef={this.state.modificarRef}
          polizaAux={this.state.modificarAux}
          polizaDoc={this.state.modificarDoc}
          polizaCliente={this.state.modificarCliente}
          polizaTipoMov={this.state.modificarTipoMov}
          polizaCargo={this.state.modificarCargo}
          polizaAbono={this.state.modificarAbono}
          consecutivo={this.state.consecutivo}
          tipoPoliza={this.state.tipo}
        />
        )}


        
      </Modal>
    );
  }
}
export default ModalConsulta;
