/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un anticipo. 
*/
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardBody,
  Collapse,
  Table,
  Input,
} from "reactstrap";
import * as message from "../messages/messages";
import MyInput from "../../../utils/custom/MyInput";
import MyTextArea from "../../../utils/custom/MyTextArea";
import * as utility from "../utils/utility";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomSelect from "../../../utils/custom/CustomSelect";
import MySelect from "../../../utils/custom/MySelect";
import WebSocketInstance from "../../../utils/websocket";

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      numero_select:
        this.props.on_cliente === false ? "" : this.props.on_cliente,
      nombre_select:
        this.props.on_cliente === false ? "" : localStorage.getItem("emp_name"),
      cliente_enable: this.props.on_cliente === false ? false : true,
      nombre_suggestions: [],
      solicitudes_disponibles: [],
      solicitudes_agrega: [],
      importe: "",
      banco_select_id_PT: "",
      banco_select_text_PT: "Seleccione un banco",
      banco_select_id_AN: "",
      banco_select_text_AN: "Seleccione un banco",
      banco_select_id_SD: "",
      banco_select_text_SD: "Seleccione un banco",
      solicitud_select: "Seleccione una solicitud",

      arr_ingresos: [],
      arr_pagos: [],
      arr_terceros: [],
      arr_bancos: [],

      toogle_PCC: false,
      toogle_AN: false,
      toogle_SOL: false,

      importeDepositoPCC: "",
      importeDepositoAN:"",
    };
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.identificativo;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "transaccion",
      motivo: motivo,
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */

  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_ingresos, "ingresos");
    this.methodGET_API(utility.url_contribuciones, "pagos");
    this.methodGET_API(utility.url_phxcc, "tercero");
    this.methodGET_API(utility.url_bancos, "bancos");

    document.addEventListener('keyup', this.handleEnterKeyPress);
  }
  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener('keyup', this.handleEnterKeyPress);
  }
  toogleAnticipo = () => {
    this.setState((prevState) => ({
      toogle_AN: !prevState.toogle_AN,
      toogle_SOL: false,
      toogle_PCC: false,
      numero_select:
        this.props.on_cliente === false ? "" : this.props.on_cliente,
      nombre_select:
        this.props.on_cliente === false ? "" : localStorage.getItem("emp_name"),
      cliente_enable: this.props.on_cliente === false ? false : true,
      nombre_suggestions: [],
    }));
  };
  toogleSolicitud = () => {
    this.setState((prevState) => ({
      toogle_SOL: !prevState.toogle_SOL,
      toogle_AN: false,
      toogle_PCC: false,
      numero_select:
        this.props.on_cliente === false ? "" : this.props.on_cliente,
      nombre_select:
        this.props.on_cliente === false ? "" : localStorage.getItem("emp_name"),
      cliente_enable: this.props.on_cliente === false ? false : true,
      nombre_suggestions: [],
    }));
  };
  tooglePagos = () => {
    this.setState((prevState) => ({
      toogle_PCC: !prevState.toogle_PCC,
      toogle_AN: false,
      toogle_SOL: false,
      numero_select:
        this.props.on_cliente === false ? "" : this.props.on_cliente,
      nombre_select:
        this.props.on_cliente === false ? "" : localStorage.getItem("emp_name"),
      cliente_enable: this.props.on_cliente === false ? false : true,
      nombre_suggestions: [],
    }));
  };
  closeModal = () => {
    this.props.toogleNuevo();
    this.setState({
      numero_select:
        this.props.on_cliente === false ? "" : this.props.on_cliente,
      nombre_select:
        this.props.on_cliente === false ? "" : localStorage.getItem("emp_name"),
      cliente_enable: this.props.on_cliente === false ? false : true,
      nombre_suggestions: [],
      solicitudes_disponibles: [],
      solicitudes_agrega: [],
      importe: "",
      solicitud_select: "Seleccione una solicitud",
      toogle_PCC: false,
      toogle_AN: false,
      toogle_SOL: false,
    });
  };

  handleBanco_PT = (e) => {
    const selectedValue = e.target.value;
    const [numero] = selectedValue.split(" - ");
    this.setState({ banco_select_id_PT: numero });
  };

  handleEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      // Si la tecla presionada fue 'Enter', llamamos a la función para manejar el evento
      if (e.target.id === 'importe_alta_deposito_PCC') {
        this.handleimporteDepositoPCCBlur();
      }
      if (e.target.id === 'importe_alta_deposito_AN') {
        this.handleimporteDepositoANBlur();
      }
    }
  };

  handleimporteDepositoPCC = (e) => {
    let importeDepositoPCC = e.target.value;

    // Remover caracteres que no son números, puntos o comas
    importeDepositoPCC = importeDepositoPCC.replace(/[^\d.,]/g, '');
  
    // Reemplazar comas por puntos
    importeDepositoPCC = importeDepositoPCC.replace(/,/g, '.');
  
    // Si hay más de un punto, eliminar los puntos extras
    const dotCount = importeDepositoPCC.split('.').length - 1;
    if (dotCount > 1) {
      importeDepositoPCC = importeDepositoPCC.replace(/\./g, (match, offset) => offset ? '' : match);
    }
  
    // Limitar a dos decimales
    const parts = importeDepositoPCC.split('.');
    if (parts[1] && parts[1].length > 2) {
      importeDepositoPCC = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    this.setState({ importeDepositoPCC });
  };

  handleimporteDepositoPCCBlur = () => {
    let importeDepositoPCC = this.state.importeDepositoPCC;
  
    // Si el campo está vacío, establecer el valor como '0.00'
    if (!importeDepositoPCC) {
      importeDepositoPCC = '0.00';
    } else {
      // Convertir el valor a un número
      let num = parseFloat(importeDepositoPCC);
  
      // Verificar si el valor es un número válido
      if (!isNaN(num)) {
        // Formatear el número con dos decimales
        importeDepositoPCC = num.toFixed(2);
      } else {
        // Si no es un número válido, establecer el valor como '0.00'
        importeDepositoPCC = '0.00';
      }
    }
  
    this.setState({ importeDepositoPCC });
  };

  handleimporteDepositoAN = (e) => {
    let importeDepositoAN = e.target.value;

    // Remover caracteres que no son números, puntos o comas
    importeDepositoAN = importeDepositoAN.replace(/[^\d.,]/g, '');
  
    // Reemplazar comas por puntos
    importeDepositoAN = importeDepositoAN.replace(/,/g, '.');
  
    // Si hay más de un punto, eliminar los puntos extras
    const dotCount = importeDepositoAN.split('.').length - 1;
    if (dotCount > 1) {
      importeDepositoAN = importeDepositoAN.replace(/\./g, (match, offset) => offset ? '' : match);
    }
  
    // Limitar a dos decimales
    const parts = importeDepositoAN.split('.');
    if (parts[1] && parts[1].length > 2) {
      importeDepositoAN = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    this.setState({ importeDepositoAN });
  };

  handleimporteDepositoANBlur = () => {
    let importeDepositoAN = this.state.importeDepositoAN;
  
    // Si el campo está vacío, establecer el valor como '0.00'
    if (!importeDepositoAN) {
      importeDepositoAN = '0.00';
    } else {
      // Convertir el valor a un número
      let num = parseFloat(importeDepositoAN);
  
      // Verificar si el valor es un número válido
      if (!isNaN(num)) {
        // Formatear el número con dos decimales
        importeDepositoAN = num.toFixed(2);
      } else {
        // Si no es un número válido, establecer el valor como '0.00'
        importeDepositoAN = '0.00';
      }
    }
  
    this.setState({ importeDepositoAN });
  };

  handleBanco_AN = (e) => {
    const selectedValue = e.target.value;
    const [numero] = selectedValue.split(" - ");
    this.setState({ banco_select_id_AN: numero });
  };
  handleBanco_SD = (e) => {
    const selectedValue = e.target.value;
    const [numero] = selectedValue.split(" - ");
    this.setState({ banco_select_id_SD: numero });
  };
  handleSolicitud = (e) => {
    let label = e.name_text;
    let solicitud_select = e[label];
    this.setState({ solicitud_select });
  };
  handleAlta_anticipo = () => {
    if (this.state.numero_select === "" || this.state.nombre_select === "") {
      message.warningMessage("Por favor, ingrese los campos faltantes");
    } else {
      message
        .message_confimacion("¿Desea continuar con la operación alta?", "")
        .then((res) => {
          if (res) {
            let data = {
              importe: document.getElementById("importe_alta_deposito_AN")
                .value,
              tipo_deposito: "An",
              cliente: this.state.numero_select,
              observaciones: document.getElementById(
                "observaciones_alta_deposito_AN"
              ).value,
              banco: this.state.banco_select_id_AN,
            };
            if (utility.valida_data_deposito_anticipo(data).length > 0)
              message.message_modal(
                "Verificar datos",
                utility.valida_data_deposito_anticipo(data),
                400
              );
            else {
              this.methodPOST_API(utility.url_alta_deposito, "alta", data);
            }
          }
        });
    }
  };
  handleAlta_solicitud = () => {
    if (this.state.numero_select === "" || this.state.nombre_select === "") {
      message.warningMessage("Por favor, ingrese los campos faltantes");
    } else {
      message
        .message_confimacion("¿Desea continuar con la operación alta?", "")
        .then((res) => {
          if (res) {
            let sol_array = [];
            this.state.solicitudes_agrega.forEach((sol) =>
              sol_array.push(sol.id_solicitud)
            );
            let data = {
              importe: document.getElementById("importe_alta_deposito").value,
              solicitudes: sol_array,
              cliente: this.state.numero_select,
              observaciones: document.getElementById(
                "observaciones_alta_deposito"
              ).value,
              banco: this.state.banco_select_id_SD,
              tipo_deposito: "Sd",
            };
            if (utility.valida_data_deposito_anticipo(data).length > 0)
              message.message_modal(
                "Verificar datos",
                utility.valida_data_deposito_anticipo(data),
                400
              );
            else {
              this.methodPOST_API(utility.url_alta_deposito, "alta", data);
            }
          }
        });
    }
  };
  handleAlta_PCC = () => {
    if (this.state.numero_select === "" || this.state.nombre_select === "") {
      message.warningMessage("Por favor, ingrese los campos faltantes");
    } else {
      message
        .message_confimacion("¿Desea continuar con la operación alta?", "")
        .then((res) => {
          if (res) {
            let data = {
              importe: document.getElementById("importe_alta_deposito_PCC")
                .value,
              tipo_deposito: "Pt",
              cliente: this.state.numero_select,
              observaciones: document.getElementById(
                "observaciones_alta_deposito_PCC"
              ).value,
              banco: this.state.banco_select_id_PT,
            };
            if (utility.valida_data_deposito_anticipo(data).length > 0)
              message.message_modal(
                "Verificar datos",
                utility.valida_data_deposito_anticipo(data),
                400
              );
            else {
              this.methodPOST_API(utility.url_alta_deposito, "alta", data);
            }
          }
        });
    }
  };
  handleSolicitud_agrega = () => {
    let sol_select = parseInt(this.state.solicitud_select);
    let new_sol = this.state.solicitudes_agrega;
    let solicitudes = this.state.solicitudes_disponibles;
    let suma = 0;
    for (let i = 0; i < this.state.solicitudes_disponibles.length; i++)
      if (sol_select === this.state.solicitudes_disponibles[i].id_solicitud) {
        new_sol.push(this.state.solicitudes_disponibles[i]);
        suma += parseInt(this.state.solicitudes_disponibles[i].importe);
        solicitudes.splice(i, 1);
      }
    this.setState({
      solicitudes_agrega: new_sol,
      solicitudes_disponibles: solicitudes,
      importe: suma,
    });
  };
  handleImporte = (e) => {
    let importe = e.target.importe;
    this.setState({ importe });
  };
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "ingresos":
              if (this._isMounted === true)
                this.setState({
                  arr_ingresos: response.data.results,
                });
              break;
            case "pagos":
              if (this._isMounted === true)
                this.setState({
                  arr_pagos: response.data.results,
                });
              break;
            case "tercero":
              if (this._isMounted === true)
                this.setState({
                  arr_terceros: response.data.results,
                });
              break;
            case "bancos":
              if (this._isMounted === true)
                this.setState({
                  arr_bancos: response.data.results,
                });
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "alta":
              this.closeModal();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.setState(
                { identificativo: response.data.results.id_deposito },
                () => {
                  this.sendNotificacionHandler("crear");
                }
              );
              this.props.update(1);
              break;
            case "solicitudes_cliente":
              this.setState({
                solicitudes_disponibles: response.data.results,
              });
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  // Función para buscar una solicitud por su id_solicitud en el array solicitudes_agrega
  getSolicitudById = (id_solicitud) => {
    return this.state.solicitudes_agrega.find(
      (solicitud) => solicitud.id_solicitud === id_solicitud
    );
  };

  // Función para eliminar una solicitud del array solicitudes_agrega
  handleDeleteSol = (id_solicitud) => {
    const toFind = id_solicitud;
    const solicitudes_agrega = this.state.solicitudes_agrega.filter(
      (solicitud) => solicitud.id_solicitud !== toFind
    );
    this.setState({
      solicitudes_agrega,
    });

    // Obtener la solicitud eliminada del array solicitudes_agrega
    const solicitudEliminada = this.getSolicitudById(id_solicitud);

    if (solicitudEliminada) {
      // Agregar la solicitud eliminada de nuevo al array solicitudes_disponibles
      this.setState((prevState) => ({
        solicitudes_disponibles: [
          ...prevState.solicitudes_disponibles,
          solicitudEliminada,
        ],
      }));
    }
  };

  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") text_add = "";
    else if (numero_cliente.length <= 4) {
      switch (numero_cliente.length) {
        case 1:
          text_add = "000";
          text_add += numero_cliente;
          break;
        case 2:
          text_add = "00";
          text_add += numero_cliente;
          break;
        case 3:
          text_add = "0";
          text_add += numero_cliente;
          break;
        case 4:
          text_add = numero_cliente;
          break;
        default:
          return null;
      }
    } else text_add = "";
    for (let i = 0; i < this.props.clientes.length; i++) {
      if (text_add.length === 0) {
        document.getElementById("nombre_cliente_alta_deposito").value = "";
        this.suggestionSelected("");
        break;
      }
      if (text_add === this.props.clientes[i].numero) {
        document.getElementById("nombre_cliente_alta_deposito").value =
          this.props.clientes[i].nombre;
        this.suggestionSelected(this.props.clientes[i].nombre);
        break;
      }
    }
  };
  suggestionSelected = (value) => {
    let number = utility.get_number_cliente(value, this.props.clientes);
    if (number === undefined) number = "";
    document.getElementById("numero_cliente_alta_deposito").value = number;
    this.setState(
      {
        nombre_select: value,
        numero_select: number,
        nombre_suggestions: [],
        solicitudes_agrega: [],
        solicitudes_disponibles: [],
        importe: "",
      },
      () => {
        this.methodPOST_API(
          utility.url_solicitudes_disponibles,
          "solicitudes_cliente",
          { cliente: this.state.numero_select }
        );
      }
    );
  };
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_nombres_clientes(this.props.clientes)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      nombre_suggestions: suggestions,
      nombre_select: value,
      solicitudes_agrega: [],
      solicitudes_disponibles: [],
      importe: "",
    });
  };
  renderSuggestion = () => {
    if (this.state.nombre_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_nuevo}
        toggle={this.closeModal}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.closeModal}>
          Alta Notificación De Depósito
        </ModalHeader>
        <ModalBody>
          <div className="row mb-2 d-flex justify-content-center">
            <div className="col-lg-10 col-12 d-flex justify-content-around">
              <Button color="info" onClick={this.tooglePagos}>
                Pagos A Terceros
              </Button>
              <Button color="info" onClick={this.toogleAnticipo}>
                Anticipos
              </Button>
              <Button color="info" onClick={this.toogleSolicitud}>
                Solicitud De Depósito
              </Button>
            </div>
          </div>
          <Collapse isOpen={this.state.toogle_PCC}>
            <Card outline color="info">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="numero_cliente_alta_deposito"
                      nameLabel="Número de Cliente"
                      onChange={this.nameGenerator}
                      value={this.state.numero_select}
                      disabled={this.state.cliente_enable}
                      classInput={"input-animatedd"}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="nombre_cliente_alta_deposito"
                      nameLabel="Nombre de Cliente"
                      onChange={this.onNameChange}
                      value={this.state.nombre_select}
                      disabled={this.state.cliente_enable}
                      classInput={"input-animatedd"}
                    />
                    {this.renderSuggestion()}
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="fecha_alta_deposito"
                      nameLabel="Fecha de alta"
                      disabled="true"
                      classInput={"input-animatedd"}
                      required
                      value={utility.get_fecha_actual().fecha_string}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="importe_alta_deposito_PCC"
                      nameLabel="Importe"
                      value={this.state.importeDepositoPCC}
                      onChange={this.handleimporteDepositoPCC}
                      onBlur={this.handleimporteDepositoPCCBlur}
                    />
                    <MySelect
                      bootstrap="col-lg-4 col-12"
                      name_text="banco"
                      name_index="numero"
                      id="banco_alta_deposito_PCC"
                      nameLabel="Banco"
                      first_option="Seleccione un banco"
                      required
                      onChange={this.handleBanco_PT}
                    >
                      {this.state.arr_bancos.map((ban) => (
                        <option key={ban.numero}>
                          {ban.numero} - {ban.nombre}
                        </option>
                      ))}
                    </MySelect>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyTextArea
                      bootstrap="col-12"
                      id="observaciones_alta_deposito_PCC"
                      nameLabel="Observaciones"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      color="success"
                      className="mr-2"
                      onClick={this.handleAlta_PCC}
                    >
                      Crear
                    </Button>
                    <Button color="primary" onClick={this.closeModal}>
                      Cerrar
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_AN}>
            <Card outline color="info">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="numero_cliente_alta_deposito"
                      nameLabel="Número de Cliente"
                      onChange={this.nameGenerator}
                      value={this.state.numero_select}
                      disabled={this.state.cliente_enable}
                      classInput={"input-animatedd"}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="nombre_cliente_alta_deposito"
                      nameLabel="Nombre de Cliente"
                      onChange={this.onNameChange}
                      value={this.state.nombre_select}
                      disabled={this.state.cliente_enable}
                      classInput={"input-animatedd"}
                    />
                    {this.renderSuggestion()}
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="fecha_alta_deposito"
                      nameLabel="Fecha de alta"
                      disabled="true"
                      classInput={"input-animatedd"}
                      required
                      value={utility.get_fecha_actual().fecha_string}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="importe_alta_deposito_AN"
                      nameLabel="Importe"
                      value={this.state.importeDepositoAN}
                      onChange={this.handleimporteDepositoAN}
                      onBlur={this.handleimporteDepositoANBlur}
                    />
                    <MySelect
                      bootstrap="col-lg-4 col-12"
                      id="banco_alta_deposito_AN"
                      nameLabel="Banco"
                      first_option="Seleccione un banco"
                      required
                      onChange={this.handleBanco_AN}
                    >
                      {this.state.arr_bancos.map((ban) => (
                        <option key={ban.numero}>
                          {ban.numero} - {ban.nombre}
                        </option>
                      ))}
                    </MySelect>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyTextArea
                      bootstrap="col-12"
                      id="observaciones_alta_deposito_AN"
                      nameLabel="Observaciones"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      color="success"
                      className="mr-2"
                      onClick={this.handleAlta_anticipo}
                    >
                      Crear
                    </Button>
                    <Button color="primary" onClick={this.closeModal}>
                      Cerrar
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_SOL}>
            <Card outline color="info">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="numero_cliente_alta_deposito"
                      nameLabel="Número de Cliente"
                      onChange={this.nameGenerator}
                      value={this.state.numero_select}
                      disabled={this.state.cliente_enable}
                      classInput={"input-animatedd"}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="nombre_cliente_alta_deposito"
                      nameLabel="Nombre de Cliente"
                      onChange={this.onNameChange}
                      value={this.state.nombre_select}
                      disabled={this.state.cliente_enable}
                      classInput={"input-animatedd"}
                    />
                    {this.renderSuggestion()}
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="fecha_alta_deposito"
                      nameLabel="Fecha de alta"
                      disabled="true"
                      classInput={"input-animatedd"}
                      required
                      value={utility.get_fecha_actual().fecha_string}
                    />
                    <MySelect
                      bootstrap="col-lg-4 col-12"
                      id="banco_alta_deposito"
                      nameLabel="Banco"
                      first_option="Seleccione un banco"
                      required
                      onChange={this.handleBanco_SD}
                    >
                      {this.state.arr_bancos.map((ban) => (
                        <option key={ban.numero}>
                          {ban.numero} - {ban.nombre}
                        </option>
                      ))}
                    </MySelect>
                    <CustomSelect
                      name_text="id_solicitud"
                      name_index="id_solicitud"
                      bootstrap="col-lg-3 col-8"
                      id="solicitudes_alta_deposito"
                      nameLabel="Solicitudes de anticipos"
                      value={this.state.solicitud_select}
                      onChange={this.handleSolicitud}
                      options={this.state.solicitudes_disponibles}
                    />
                    <Button
                      color="primary"
                      className="col-lg-1 col-4 mb-2"
                      onClick={this.handleSolicitud_agrega}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyTextArea
                      bootstrap="col-12"
                      id="observaciones_alta_deposito"
                      nameLabel="Observaciones"
                    />
                  </FormGroup>
                </Form>
                <Table size="sm" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>No. Solicitud</td>
                      <td>Referencia</td>
                      <td>Tipo de Movimiento</td>
                      <td>Concepto</td>
                      <td>Cargo</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.solicitudes_agrega.map((sol) => (
                      <tr key={sol.id_solicitud}>
                        <td className="text-center">{sol.id_solicitud}</td>
                        <td className="text-center">{sol.referencia}</td>
                        <td>
                          <p className="mb-0 text-white">-</p>
                          {sol.movimientos.map((mov, index, array) => {
                            return (
                              <p
                                className="mb-0 text-center mx-auto"
                                style={{ width: "170px" }}
                                key={mov.tipo_solicitud + "mov" + index}
                              >
                                {utility.tipo_solicitud(mov.tipo_solicitud)}
                              </p>
                            );
                          })}
                        </td>
                        <td className="text-center">
                          <p className="mb-0 text-white">-</p>
                          {sol.movimientos.map((mov, index, array) => {
                            return (
                              <p
                                className="mb-0 text-center"
                                key={mov.clave_concepto + "con" + index}
                              >
                                {utility.get_type_concepto(
                                  mov.clave_concepto,
                                  mov.tipo_solicitud,
                                  this.state.arr_ingresos,
                                  this.state.arr_pagos,
                                  this.state.arr_terceros
                                )}
                              </p>
                            );
                          })}
                        </td>
                        <td>
                          <p className="mb-0 text-white">-</p>
                          {sol.movimientos.map((mov, index, array) => {
                            return (
                              <p
                                className="mb-0 text-center mx-auto"
                                style={{ width: "90px" }}
                                key={mov.monto + "car" + index}
                              >
                                $ {mov.monto}
                              </p>
                            );
                          })}
                        </td>
                        <td className="text-center">
                          <p className="mb-0 text-white">-</p>
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() =>
                              this.handleDeleteSol(sol.id_solicitud)
                            }
                          >
                            Eliminar
                          </Button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="5" className="text-right">
                        <p className="mb-0 pl-2 mt-2 font-weight-bold">
                          TOTAL:
                        </p>
                      </td>
                      <td className="text-center">
                        <Input
                          className="mx-auto"
                          id="importe_alta_deposito"
                          value={this.state.importe}
                          style={{ width: "80px" }}
                          onChange={this.handleImporte}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div className="container-fluid">
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      color="success"
                      className="mr-2"
                      onClick={this.handleAlta_solicitud}
                      disabled={
                        this.state.solicitudes_agrega.length === 0
                          ? true
                          : false
                      }
                    >
                      Crear
                    </Button>
                    <Button color="primary" onClick={this.closeModal}>
                      Cerrar
                    </Button>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Collapse>
        </ModalBody>
      </Modal>
    );
  }
}
export default ModalAlta;
