/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente general de la aplicación anticipos. 
*/
import React, { Component } from 'react';
import SearchBar from './SearchBar';
import TableSol from './Table';
import * as permisos from '../../utils/permisos/permisos';

class Consolidadoras extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actualizar: 0,
            permisos: permisos.obtener_permisos_by_app('catconsolidadoras', localStorage.getItem('perm')),
        };
    }
    update = (cambio) => {
        this.setState({ actualizar: cambio });
    }
    render() {
        return (
            <div className="container-fluid">
                <SearchBar update={this.update} permisos={this.state.permisos} />
                <TableSol update={this.update} actualizar={this.state.actualizar} />
            </div>
        );
    }
}
export default Consolidadoras;