/* 
Componente que genera un input de tipo input orientado al diseño del sistema
*/
import React from 'react';
import '../../../assets/style.css';

const MyInput = (props) => {
    return (
        <div className={`grupo p-0 ${props.bootstrap}`}>
            <input className="input-animated col-12 mb-2" type={props.type} id={props.id} disabled={props.disabled} value={props.value} 
               maxLength={props.maxLength}  required={props.required === "false" ? false : true} onChange={props.onChange} />
            <span className="barra"></span>
            <br /><label className="label-animated" htmlFor={props.id}>{props.nameLabel}</label>
        </div>
    );
}
export default MyInput;