import React, { Component } from 'react';
import '../../assets/style.css';

class Footer extends Component {
    render() {
        return (
            <footer className="container-fluid footer">
                <div className="row py-3">
                    <div className="col">
                        <p className="mb-0 text-center text-muted">Sistema Intranet</p>
                    </div>
                </div>
            </footer>
        );
    }
}
export default Footer;
