/* 
Autor: Alexis Yael Hernández Grimaldo
Descripción: El componenete genera un bloque correspondiente a la busqueda dinamica en la tabla para acceder a la información.
*/
import React, { Component } from "react";
import { Form, FormGroup, Input } from "reactstrap";
import "../../assets/style.css";
import ModalAction from "./Modal/ModalAction";
import * as utility from "./utils/utility";

class BusquedaTabla extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-lg-9 col-sm-12 my-2">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <FormGroup className="d-flex justify-content-center flex-column mb-0">
              <Input
                type="text"
                placeholder="Buscar"
                id="busqueda_general_sol_anticipos"
                onKeyUp={utility.general_search}
              />
              <Input
                type="date"
                id="busqueda_date_sol_antcipos"
                onChange={utility.date_search}
                className="mt-1"
              />
            </FormGroup>
          </Form>
        </div>
        <ModalAction
          referencias={this.props.referencias}
          clientes={this.props.clientes}
          update={this.props.update}
          checked={this.props.checked}
          sum_solicitudes={this.props.sum_solicitudes}
          sum_ingresos={this.props.sum_ingresos}
          sum_contribuciones={this.props.sum_contribuciones}
          sum_terceros={this.props.sum_terceros}
          update_pago={this.props.update_pago}
          permisos={this.props.permisos}
          on_cliente={this.props.on_cliente}
          ingresos={this.props.ingresos}
          contribuciones={this.props.contribuciones}
          phxcc={this.props.phxcc}
        />
      </div>
    );
  }
}
export default BusquedaTabla;
