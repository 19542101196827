/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente Principal para el Modal de las distintas opciones de operación para anticipos. 
*/

import React, { Component } from "react";
import { ButtonGroup, Button } from "reactstrap";
import ModalAlta from "./ModalAlta";
import ModalConsulta from "./ModalConsulta";
import ModalDevCon from "./ModalDevCon";
import ModalReporte from "./ModalReporte";
import * as permisos from "../../../utils/permisos/permisos";

class ModalAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toogle_nuevo: false,
      toogle_dev_con: false,
      toogle_reporte: false,
      toogle_consulta: false,
    };
    this.toogleNuevo = this.toogleNuevo.bind(this);
    this.toogleConsulta = this.toogleConsulta.bind(this);
    this.toogleReporte = this.toogleReporte.bind(this);
  }
  toogleNuevo = () => {
    this.setState((prevState) => ({ toogle_nuevo: !prevState.toogle_nuevo }));
  };
  toogleConsulta = () => {
    this.setState((prevState) => ({
      toogle_consulta: !prevState.toogle_consulta,
    }));
  };
  toogleDevCon = () => {
    this.setState((prevState) => ({
      toogle_dev_con: !prevState.toogle_dev_con,
    }));
  };
  toogleReporte = () => {
    this.setState((prevState) => ({
      toogle_reporte: !prevState.toogle_reporte,
    }));
  };
  render() {
    return (
      <div className="col-lg-3 col-sm-12 d-flex justify-content-center flex-column">
        <ButtonGroup className="mb-1">
          <Button color="primary" onClick={this.toogleNuevo}>
            Nuevo
          </Button>
          <Button color="primary" onClick={this.toogleConsulta}>
            Consulta
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button
            color="success"
            onClick={this.toogleDevCon}
            disabled={
              !permisos.obtener_permiso_especifico(
                "modificar",
                this.props.permisos
              ) ||
              !permisos.obtener_permiso_especifico(
                "consolidar",
                this.props.permisos
              )
                ? false
                : true
            }
          >
            Operaciones
          </Button>
          <Button color="success" onClick={this.toogleReporte}>
            Reportes
          </Button>
        </ButtonGroup>
        <ModalAlta
          toogle_nuevo={this.state.toogle_nuevo}
          toogleNuevo={this.toogleNuevo}
          clientes={this.props.clientes}
          bancos={this.props.bancos}
          update={this.props.update}
          on_cliente={this.props.on_cliente}
          on_nivel={this.props.on_nivel}
        />
        <ModalConsulta
          toogle_consulta={this.state.toogle_consulta}
          toogleConsulta={this.toogleConsulta}
          clientes={this.props.clientes}
          bancos={this.props.bancos}
          update={this.props.update}
          on_cliente={this.props.on_cliente}
          permisos={this.props.permisos}
        />
        <ModalDevCon
          toogle_dev_con={this.state.toogle_dev_con}
          toogleDevCon={this.toogleDevCon}
          checked={this.props.checked}
          clientes={this.props.clientes}
          update_con_dev={this.props.update_con_dev}
          permisos={this.props.permisos}
          on_cliente={this.props.on_cliente}
        />
        <ModalReporte
          toogle_reporte={this.state.toogle_reporte}
          toogleReporte={this.toogleReporte}
          clientes={this.props.clientes}
          bancos={this.props.bancos}
          update={this.props.update}
          on_cliente={this.props.on_cliente}
          permisos={this.props.permisos}
        />
      </div>
    );
  }
}
export default ModalAction;
