/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un anticipo. 
*/
import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Collapse,
  Card,
  CardBody,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import * as utility from "../utils/utility";
import * as message from "../messages/messages";
import axios from "axios";
import swal from "@sweetalert/with-react";
import { faFileDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyInput from "../../../utils/custom/MyInput";
import MySelect from "../../../utils/custom/MySelect";
import MyTextArea from "../../../utils/custom/MyTextArea";
import WebSocketInstance from "../../../utils/websocket";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalAlta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toogle_solicitud: false,
      toogle_anticipo: false,
      fecha_vencimiento: "",
      fecha_alta: "",
      numero_select: "",
      nombre_select: "",
      identificativo: "",
      nombre_suggestions: [],
      bancos: "",
      referencia_bancaria: "",
      importe: "",
      observaciones: "",
    };
    this.toogleSolicitud = this.toogleSolicitud.bind(this);
    this.toogleAnticipo = this.toogleAnticipo.bind(this);
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.identificativo;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "pago_tercero",
      motivo: motivo,
      folio: [folio],
    };
    if (this.props.on_cliente === false)
      notificacionObject["cliente"] = [parseInt(this.state.numero_select)];
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */

  componentDidMount() {
    this._isMounted = true;
    document.addEventListener("keyup", this.handleEnterKeyPress);
  }
  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("keyup", this.handleEnterKeyPress);
  }

  toogleSolicitud = () => {
    this.setState(
      (prevState) => ({
        toogle_solicitud: !prevState.toogle_solicitud,
        toogle_anticipo: false,
      }),
      this.handleLimpiar()
    );
  };
  toogleAnticipo = () => {
    this.setState(
      (prevState) => ({
        toogle_anticipo: !prevState.toogle_anticipo,
        toogle_solicitud: false,
      }),
      this.handleLimpiar()
    );
  };
  handlebancos = (e) => {
    const selectedValue = e.target.value;
    const [numero] = selectedValue.split(" - ");
    this.setState({
      bancos: numero,
    });
  };
  handleReferencia = (e) => {
    this.setState({ referencia_bancaria: e.target.value });
  };

  handleObservaciones = (e) => {
    this.setState({ observaciones: e.target.value });
  };

  handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      // Si la tecla presionada fue 'Enter', llamamos a la función para manejar el evento
      if (e.target.id === "importe_anticipo") {
        this.handleImporteBlur();
      }
    }
  };

  handleImporte = (e) => {
    let importe = e.target.value;

    // Remover caracteres que no son números, puntos o comas
    importe = importe.replace(/[^\d.,]/g, "");

    // Reemplazar comas por puntos
    importe = importe.replace(/,/g, ".");

    // Si hay más de un punto, eliminar los puntos extras
    const dotCount = importe.split(".").length - 1;
    if (dotCount > 1) {
      importe = importe.replace(/\./g, (match, offset) =>
        offset ? "" : match
      );
    }

    // Limitar a dos decimales
    const parts = importe.split(".");
    if (parts[1] && parts[1].length > 2) {
      importe = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    this.setState({ importe });
  };

  handleImporteBlur = () => {
    let importe = this.state.importe;

    // Si el campo está vacío, establecer el valor como '0.00'
    if (!importe) {
      importe = "0.00";
    } else {
      // Convertir el valor a un número
      let num = parseFloat(importe);

      // Verificar si el valor es un número válido
      if (!isNaN(num)) {
        // Formatear el número con dos decimales
        importe = num.toFixed(2);
      } else {
        // Si no es un número válido, establecer el valor como '0.00'
        importe = "0.00";
      }
    }

    this.setState({ importe });
  };

  /* ------ EVENTOS DE SUBMIT ------ */
  handleAltaSolAnticipo = (e) => {
    e.preventDefault();

    // Obtenemos los valores de los campos
    const {
      bancos,
      fecha_vencimiento,
      referencia_bancaria,
      fecha_alta,
      observaciones,
    } = this.state;

    // Verificamos si alguno de los campos requeridos está vacío
    if (!bancos || !fecha_vencimiento || !referencia_bancaria || !fecha_alta) {
      message.warningMessage(
        "Por favor, complete todos los campos obligatorios."
      );
    } else {
      message
        .message_confimacion("¿Desea continuar con la operación alta?", "")
        .then((res) => {
          if (res) {
            this.methodPUT_API(utility.url_alta_anticipo, "alta", {
              banco: bancos,
              fecha_vencimiento: fecha_vencimiento,
              referencia_bancaria: referencia_bancaria,
              id_deposito: document.getElementById("numero_alta_solAnticipo")
                .value,
              fecha: fecha_alta,
              observaciones: observaciones,
            });
          }
        });
    }
  };

  handleAltaAnticipo = (e) => {
    e.preventDefault();
    if (
      this.state.numero_select === "" ||
      this.state.nombre_select === "" ||
      this.state.importe === "" ||
      this.state.referencia_bancaria === ""
    ) {
      message.warningMessage("Por favor, ingrese los campos faltantes");
    } else {
      message
        .message_confimacion("¿Desea continuar con la operación alta?", "")
        .then((res) => {
          if (res) {
            this.methodPOST_API(utility.url_alta_anticipo, "alta", {
              cliente: this.state.numero_select,
              banco: this.state.bancos,
              referencia_bancaria: this.state.referencia_bancaria,
              observaciones: this.state.observaciones,
              fecha_vencimiento: this.state.fecha_vencimiento,
              importe: this.state.importe,
              fecha: this.state.fecha_alta,
            });
          }
        });
    }
  };
  /* ------ FIN EVENTOS DE SUBMIT ------ */
  handleFecha_alta = (e) => {
    let fecha = e.target.value;

    if (fecha && fecha.match(/^\d{4}-\d{2}-\d{2}$/)) {
      let vencimiento = new Date(fecha);
      let dias = parseInt(
        document.getElementById("vigencia_alta_anticipo").value
      );
      vencimiento.setDate(vencimiento.getDate() + dias);

      utility.add_class_disabled("vencimiento_alta_anticipo");
      this.setState({
        fecha_vencimiento: vencimiento.toISOString().slice(0, 10),
        fecha_alta: fecha,
      });
    } else {
      console.error("La fecha ingresada no es válida.");
    }
  };

  onFechaVencimiento = (e) => {
    if (document.getElementById("fecha_actual_anticipo").value.length === 0)
      return null;
    else {
      let dias = parseInt(e.target.value);
      let vencimiento = new Date(
        document.getElementById("fecha_actual_anticipo").value
      );
      vencimiento.setDate(vencimiento.getDate() + dias);
      utility.add_class_disabled("vencimiento_alta_anticipo");
      this.setState({
        fecha_vencimiento: vencimiento.toISOString().slice(0, 10),
      });
    }
  };
  handleFecha_altaSol = (e) => {
    let fecha = e.target.value;
    let vencimiento = new Date(fecha);
    let dias = parseInt(
      document.getElementById("vigencia_alta_solAnticipo").value
    );
    vencimiento.setDate(vencimiento.getDate() + dias);
    utility.add_class_disabled("vencimiento_alta_solAnticipo");
    this.setState({
      fecha_vencimiento: vencimiento.toISOString().slice(0, 10),
      fecha_alta: fecha,
    });
  };
  onFechaVencimientoSol = (e) => {
    if (document.getElementById("fecha_actual_solAnticipo").value.length === 0)
      return null;
    else {
      let dias = parseInt(e.target.value);
      let vencimiento = new Date(
        document.getElementById("fecha_actual_solAnticipo").value
      );
      vencimiento.setDate(vencimiento.getDate() + dias);
      utility.add_class_disabled("vencimiento_alta_solAnticipo");
      this.setState({
        fecha_vencimiento: vencimiento.toISOString().slice(0, 10),
      });
    }
  };
  handleCerrar = () => {
    this.setState({
      toogle_solicitud: false,
      toogle_anticipo: false,
      fecha_vencimiento: "",
      numero_select: "",
      nombre_select: "",
      nombre_suggestions: [],
      bancos: "",
      referencia_bancaria: "",
      importe: "",
      observaciones: "",
      fecha_alta: "",
      identificativo: "",
    });
    this.props.toogleNuevo();
  };
  handleLimpiar = () => {
    this.setState({
      fecha_vencimiento: "",
      nombre_suggestions: [],
      bancos: "",
      referencia_bancaria: "",
      importe: "",
      observaciones: "",
      fecha_alta: "",
    });
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta":
              this.setState(
                { identificativo: response.data.results.numero },
                () => {
                  this.methodPDF_API(
                    utility.url_pdf_alta_anticipo +
                      `/${response.data.results.numero}` +
                      "/deposito",
                    "alta_anticipo",
                    response.data.results.numero
                  );
                  this.sendNotificacionHandler("crear");
                }
              );
              this.handleCerrar();
              swal({
                icon: "success",
                title: response.data.title,
                text: response.data.text,
                button: true,
                content: (
                  <div>
                    <LinkD
                      href=""
                      id="dowload_pdf_alta_anticipo"
                      download={`Anticipo ${response.data.results.numero}`}
                    />
                    <br />
                    <LinkV
                      href=""
                      id="window_pdf_alta_anticipo"
                      target="_blank"
                    />
                  </div>
                ),
              });
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPUT_API = (url, state, data) => {
    let url_intern = url;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta":
              this.setState(
                { identificativo: response.data.results.numero },
                () => {
                  this.methodPDF_API(
                    utility.url_pdf_alta_anticipo,
                    "alta_anticipo",
                    response.data.results.numero
                  );
                  //this.sendNotificacionHandler('crear');
                }
              );
              this.handleCerrar();
              swal({
                icon: "success",
                title: response.data.title,
                text: response.data.text,
                button: true,
                content: (
                  <div>
                    <LinkD
                      href=""
                      id="dowload_pdf_alta_anticipo"
                      download={`Anticipo ${response.data.results.numero}`}
                    />
                    <br />
                    <LinkV
                      href=""
                      id="window_pdf_alta_anticipo"
                      target="_blank"
                    />
                  </div>
                ),
              });
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPDF_API = (url, state, num) => {
    let url_intern = url;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta_anticipo":
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);
              document.getElementById("dowload_pdf_alta_anticipo").href =
                fileURL;
              document.getElementById("window_pdf_alta_anticipo").href =
                fileURL;
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") text_add = "";
    else if (numero_cliente.length <= 4) {
      switch (numero_cliente.length) {
        case 1:
          text_add = "000";
          text_add += numero_cliente;
          break;
        case 2:
          text_add = "00";
          text_add += numero_cliente;
          break;
        case 3:
          text_add = "0";
          text_add += numero_cliente;
          break;
        case 4:
          text_add = numero_cliente;
          break;
        default:
          return null;
      }
    } else text_add = "";
    for (let i = 0; i < this.props.clientes.length; i++) {
      if (text_add.length === 0) {
        document.getElementById("nombre_cliente_anticipo").value = "";
        this.suggestionSelected("");
        break;
      }
      if (text_add === this.props.clientes[i].numero) {
        document.getElementById("nombre_cliente_anticipo").value =
          this.props.clientes[i].nombre;
        this.suggestionSelected(this.props.clientes[i].nombre);
        break;
      }
    }
  };
  suggestionSelected = (value) => {
    let number = utility.get_number_cliente(value, this.props.clientes);
    if (number === undefined) number = "";
    document.getElementById("numero_cliente_anticipo").value = number;
    this.setState({
      nombre_select: value,
      numero_select: number,
      nombre_suggestions: [],
    });
  };
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_nombres_clientes(this.props.clientes)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      nombre_suggestions: suggestions,
      nombre_select: value,
    });
  };
  renderSuggestion = () => {
    if (this.state.nombre_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_nuevo}
        toggle={this.props.toogleNuevo}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.props.toogleNuevo}>
          Alta Pago A Terceros / Notificación De Depósito
        </ModalHeader>
        <ModalBody>
          <div className="row mb-2 d-flex justify-content-center">
            <div className="col-lg-10 col-12 d-flex justify-content-around">
              <Button color="primary" onClick={this.toogleAnticipo}>
                Pago A Terceros
              </Button>
              <Button color="info" onClick={this.toogleSolicitud}>
                Notificación De Depósito
              </Button>
            </div>
          </div>
          <Collapse isOpen={this.state.toogle_anticipo}>
            <Card outline color="primary">
              <CardBody>
                <Form
                  className="container-fluid"
                  onSubmit={this.handleAltaAnticipo}
                >
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="numero_cliente_anticipo"
                      onChange={this.nameGenerator}
                      value={this.state.numero_select}
                      nameLabel="Número de Cliente"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="nombre_cliente_anticipo"
                      onChange={this.onNameChange}
                      value={this.state.nombre_select}
                      nameLabel="Nombre de Cliente"
                    />
                    {this.renderSuggestion()}
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="importe_anticipo"
                      nameLabel="Importe"
                      value={this.state.importe}
                      onChange={this.handleImporte}
                      onBlur={this.handleImporteBlur}
                    />
                    <MySelect
                      bootstrap="col-lg-6"
                      nameLabel="Vigencia"
                      first_option="Seleccione una vigencia"
                      id="vigencia_alta_anticipo"
                      onChange={this.onFechaVencimiento}
                    >
                      <option>30</option>
                      <option>45</option>
                      <option>60</option>
                    </MySelect>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="date"
                      bootstrap="col-lg-6"
                      id="fecha_actual_anticipo"
                      max={utility.get_fecha_actual().fecha}
                      value={this.state.fecha}
                      onChange={this.handleFecha_alta}
                      nameLabel="Fecha alta"
                    />
                    <MyInput
                      type="date"
                      bootstrap="col-lg-6"
                      id="vencimiento_alta_anticipo"
                      value={this.state.fecha_vencimiento}
                      nameLabel="Fecha vigente"
                      disabled
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MySelect
                      bootstrap="col-lg-6"
                      nameLabel="Bancos"
                      first_option="Seleccione un banco"
                      onChange={this.handlebancos}
                      id="bancos_alta_anticipo"
                    >
                      {this.props.bancos.map((ban) => (
                        <option key={ban.numero}>
                          {ban.numero} - {ban.nombre}
                        </option>
                      ))}
                    </MySelect>
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      value={this.state.referencia_bancaria}
                      onChange={this.handleReferencia}
                      nameLabel="Referencia bancaria"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyTextArea
                      bootstrap="col-12"
                      value={this.state.observaciones}
                      onChange={this.handleObservaciones}
                      nameLabel="Observaciones"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      type="button"
                      color="success"
                      className="mr-2"
                      onClick={this.handleAltaAnticipo}
                    >
                      Aceptar
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      onClick={this.handleCerrar}
                    >
                      Cerrar
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_solicitud}>
            <Card outline color="info">
              <CardBody>
                <Form
                  onSubmit={this.handleAltaSolAnticipo}
                  className="container-fluid"
                >
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="numero_alta_solAnticipo"
                      nameLabel="Número de Notificación de Depósito"
                    />
                    <MySelect
                      bootstrap="col-lg-6"
                      nameLabel="Vigencia"
                      first_option="Seleccione una vigencia"
                      id="vigencia_alta_solAnticipo"
                      onChange={this.onFechaVencimientoSol}
                    >
                      <option>30</option>
                      <option>45</option>
                      <option>60</option>
                    </MySelect>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="date"
                      bootstrap="col-lg-6"
                      id="fecha_actual_solAnticipo"
                      max={utility.get_fecha_actual().fecha}
                      value={this.state.fecha_alta}
                      onChange={this.handleFecha_altaSol}
                      nameLabel="Fecha alta"
                    />
                    <MyInput
                      type="date"
                      bootstrap="col-lg-6"
                      id="vencimiento_alta_solAnticipo"
                      value={this.state.fecha_vencimiento}
                      nameLabel="Fecha vigente"
                      disabled
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MySelect
                      bootstrap="col-lg-6"
                      nameLabel="Bancos"
                      first_option="Seleccione un banco"
                      onChange={this.handlebancos}
                      id="bancos_alta_solAnticipo"
                    >
                      {this.props.bancos.map((ban) => (
                        <option key={ban.numero}>
                          {ban.numero} - {ban.nombre}
                        </option>
                      ))}
                    </MySelect>
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      value={this.state.referencia_bancaria}
                      onChange={this.handleReferencia}
                      nameLabel="Referencia bancaria"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyTextArea
                      bootstrap="col-12"
                      value={this.state.observaciones}
                      onChange={this.handleObservaciones}
                      nameLabel="Observaciones"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      type="button"
                      color="success"
                      className="mr-2"
                      onClick={this.handleAltaSolAnticipo}
                    >
                      Aceptar
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      onClick={this.handleCerrar}
                    >
                      Cerrar
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
        </ModalBody>
      </Modal>
    );
  }
}
export default ModalAlta;
