import {
  faEye,
  faFileDownload,
  faPlus,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  CustomInput,
  Badge,
  Collapse,
} from "reactstrap";
import * as message from "../messages/messages";
import MySelect from "../../../utils/custom/MySelect";
import * as utility from "../utils/utility";
import * as permisos from "../../../utils/permisos/permisos";
import MyInput from "../../../utils/custom/MyInput";
import MyDropzone from "../utils/DropzoneM";
const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props} download>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalConsulta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      numero_pedimento: "",
      patente: "",
      aduana: "",
      referencia: "",
      infoGeneral: [],
      guias: [],
      facturas: [],
      toogle_collapse: false,
      archivo_select: [],
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleConsulta = () => {
    if (this.state.numero_pedimento.length === 0)
      message.message_modal("Advertencia", "Debe tener un número", 400);
    else {
      let data = { pedimento: this.state.numero_pedimento };
      if (this.props.on_cliente !== false)
        data["cliente"] = this.props.on_cliente;
      this.methodPOST_API(utility.url_consulta_pedimento, "consulta", data);
    }
  };

  handleCancelar = () => {
    if (this.state.numero_pedimento.length === 0)
      message.message_modal("Advertencia", "Debe tener un número", 400);
    else {
      let data = { pedimento: this.state.numero_pedimento };
      if (this.props.on_cliente !== false)
        data["cliente"] = this.props.on_cliente;
      this.methodPOST_API(utility.url_cancela_pedimento, "cancelar", data);
    }
  };

  handleNumeroPedimento = (e) => {
    let numero_pedimento = e.target.value;
    this.setState({
      numero_pedimento,
    });
  };

  handleImporte = (e) => {
    let importe = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["importe"] = importe;
    this.setState({
      importe,
      data_modificacion,
    });
  };

  handleErase = () => {
    this.setState({
      numero_pedimento: "",
      patente: "",
      aduana: "",
      referencia: "",
      infoGeneral: [],
      guias: [],
      facturas: [],
      toogle_collapse: false,
      archivo_select: [],
    });
    this.props.toogleConsulta();
  };
  /**
   * Función que permite hacer una petición de forma Get para colsultar un PDF.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} num Número del documento.
   */
  methodPDF_API = (url, state, num) => {
    let url_intern = url + `${num}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta_anticipo":
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);

              /* document.getElementById("dowload_pdf_consulta_anticipo").href =
                fileURL; */
              document.getElementById("window_pdf_consulta_anticipo").href =
                fileURL;

              const linkD = document.getElementById(
                "dowload_pdf_consulta_anticipo"
              );
              linkD.href = fileURL;
              linkD.download = `Anticipo${this.state.numero_pedimento}`;

              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  toogleCollapse = (e) => {
    this.setState((prevState) => ({
      toogle_collapse: !prevState.toogle_collapse,
    }));
  };

  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta":
              this.setState({
                patente: response.data.results.patente,
                aduana: response.data.results.aduana,
                referencia: response.data.results.referencia,
                infoGeneral: response.data.results.info_general,
                guias: response.data.results.rn_guias,
                facturas: response.data.results.p_facturas,
              });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  modificaPUT_API = (url, state, data) => {
    let url_intern = url;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "modifica":
              this.handleErase();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.handleErase}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleErase}>Consulta Pedimento</ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-10 col-8"
                id="numero_pedimento_consulta"
                required
                value={this.state.numero_pedimento}
                onChange={this.handleNumeroPedimento}
                nameLabel="Número de Pedimento"
              />
              <Button
                color="primary"
                className="col-lg-2 col-4 mb-2"
                onClick={this.handleConsulta}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4"
                nameLabel="Referencia"
                id="referencia_consulta"
                onChange={this.handleReferencia}
                value={this.state.referencia}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4"
                nameLabel="Aduana"
                id="aduana_consulta"
                onChange={this.handleAduana}
                value={this.state.aduana}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4"
                nameLabel="Patente"
                id="patente_consulta"
                onChange={this.handlePatente}
                value={this.state.patente}
              />
            </FormGroup>
          </Form>
          <Table size="sm" bordered hover responsive>
            <thead className="text-center header-table">
              <tr>
                <th>Tipo Operación</th>
                <th>Importador/Exportador</th>
                <th>RFC</th>
                <th>CURP</th>
                <th>Guía</th>
                <th>Núm. Factura</th>
                <th>Fecha Factura</th>
                <th>Proveedor/Comprador</th>
                <th>Dólares</th>
              </tr>
            </thead>
            <tbody className="body-table">
              {this.state.infoGeneral.map((info, index) => (
                <tr key={index}>
                  <td className="text-center">{info.tipo_operacion}</td>
                  <td className="text-center">{info.importador_exportador}</td>
                  <td className="text-center">
                    {info.rfc_importador_exportador}
                  </td>
                  <td className="text-center">
                    {info.curp_importador_exportador}
                  </td>
                  {this.state.guias[index] ? (
                    <>
                      <td className="text-center">
                        {this.state.guias[index].guia}
                      </td>
                      <td className="text-center">
                        {this.state.facturas[index].numero_factura}
                      </td>
                      <td className="text-center">
                        {this.state.facturas[index].fecha_facturacion}
                      </td>
                      <td className="text-center">
                        {this.state.facturas[index].proveedor_comprador}
                      </td>
                      <td className="text-center">
                        ${this.state.facturas[index].valor_dolares}
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="text-center">-</td>
                      <td className="text-center">-</td>
                      <td className="text-center">-</td>
                      <td className="text-center">-</td>
                      <td className="text-center">-$</td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>

          {/* <div className="d-flex justify-content-center">
            <LinkD href="" id="dowload_pdf_consulta_anticipo">
              <FontAwesomeIcon icon={faFileDownload} /> Descargar
            </LinkD>

            <LinkV
              href="#"
              id="window_pdf_consulta_anticipo"
              target={this.state.enable_pdf ? "_self" : "_blank"}
              className="ml-5"
            />
          </div> */}

          <br></br>
          <div className="row mb-2 mx-1">
            <h5 className="col-md-11 col-9 text-md-left px-0 mb-0">
              Cargar Archivos
            </h5>
            <Button
              color="info"
              onClick={this.toogleCollapse}
              className="col-md-1 col-3"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
          <Collapse isOpen={this.state.toogle_collapse}>
          <Form className="container-fluid">
            <FormGroup className="row mb-0 mt-2 mt-lg-0">
              <MyDropzone handleArchivo={this.handleArchivo} />
              <div className="col-12 my-2 d-flex justify-content-center flex-wrap">
                {this.state.archivo_select.map((archivo, index) => (
                  <Badge color="light" className="border">
                    {archivo.name}&nbsp;
                    <spam
                      style={{ cursor: "pointer" }}
                      id={index}
                      onClick={this.handleEliminaArchivo}
                    >
                      &times;
                    </spam>
                  </Badge>
                ))}
              </div>
              <Button
                color="primary"
                className="col-12"
                onClick={this.handleSubirArchivos}
              >
                Subir Archivo
              </Button>
            </FormGroup>
          </Form>
          </Collapse>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            /*  disabled={
              this.state.status === "A" &&
              !permisos.obtener_permiso_especifico(
                "cancelar",
                this.props.permisos
              )
                ? false
                : true
            } */
            onClick={this.handleCancelar}
          >
            Cancelar
          </Button>
          <Button color="primary" onClick={this.handleErase}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalConsulta;
