import axios from "axios";
import React, { Component } from "react";
import { Spinner, Table } from "reactstrap";
import * as message from "../../utils/messages/message";
import Paginacion from "../Pagination";
import ConsultaEspecifica from "./SearchSpecific";
import * as utility from "./utils/utility";

class TableOperacion extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      pagination: { count: 1, results: [] },
      page_actual: 1,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_paginacion, "pagination");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps) {
    if (this.props.actualizar !== prevProps.actualizar) {
      this.handlePage(this.state.page_actual);
      this.props.update(0);
    }
  }
  handlePage = (page_actual) => {
    this.setState({ page_actual }, () => {
      this.methodGET_API(utility.url_paginacion, "pagination");
    });
  };
  methodGET_API = (url, state) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "pagination":
              if (this._isMounted)
                this.setState({
                  pagination: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                });
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma POST
   * @param {String} url URL a consultar
   * @param {String} state Onpción del case
   * @param {Object} data Objeto a mandar atraves del metodo POST
   */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "pagination":
              if (this._isMounted)
                if (response.data.results)
                  this.setState({
                    pagination: {
                      count: response.data.count,
                      results: response.data.results,
                    },
                  });
                else
                  message.message_modal(
                    response.data.title,
                    response.data.text,
                    response.status
                  );
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  onBackTable = () => {
    this.methodGET_API(utility.url_paginacion, "pagination");
  };
  onChangeFiltrada = (data) => {
    this.setState({ page_actual: 1 }, () => {
      this.methodPOST_API(utility.url_paginacion, "pagination", data);
    });
  };
  // Funcion para obtener el nombre de la consolidadora
  getConsolidadoraName = (numero) => {
    const numeroString = numero.toString();
    const consolidadora = this.props.consolidadoras.find(
      (item) => item.numero === numeroString
    );
    return consolidadora ? consolidadora.nombre : "Desconocido";
  };
  render() {
    return (
      <React.Fragment>
        <ConsultaEspecifica
          onBackTable={this.onBackTable}
          onChangeFiltrada={this.onChangeFiltrada}
        />
        <div className="overflow-auto tm-table">
          <Table size="sm" id="tabla_operacion" bordered hover responsive>
            <thead className="text-center header-table">
              <tr>
                <td>Folio</td>
                <td>Cliente</td>
                <td>Referencia</td>
                <td>Guia principal</td>
                <td>Consolidadora</td>
                <td>Tipo</td>
                <td>Patente</td>
                <td>Proveedor</td>
                <td>Procedencia</td>
                <td>Fecha alta</td>
                <td>Fecha llegada</td>
                <td>Descripcion</td>
                <td>Estado</td>
              </tr>
            </thead>
            <tbody className="body-table hover-gray">
              {this.state.pagination.results.length === 0 ? (
                <tr>
                  <td colSpan="12" align="center">
                    <Spinner
                      style={{ width: "10rem", height: "10rem" }}
                      color="danger"
                      type="grow"
                      className="align-self-center"
                    />
                  </td>
                </tr>
              ) : (
                this.state.pagination.results.map((operacion) => (
                  <tr key={operacion.referencia}>
                    <td>
                      <p className="mb-0 text-center">{operacion.folio}</p>
                    </td>
                    <td>
                      <p className="mb-0 text-center">{operacion.cliente}</p>
                    </td>
                    <td>
                      <p className="mb-0 text-center">{operacion.referencia}</p>
                    </td>
                    <td>
                      <p className="mb-0 text-center">
                        {operacion.guia_principal}
                      </p>
                    </td>
                    <td>
                      <p className="mb-0 text-center">
                        {this.getConsolidadoraName(operacion.consolidadora)}
                      </p>
                    </td>
                    <td>
                      <p className="mb-0 text-center">
                        {operacion.tipo === "0" ? "Importación" : "Exportación"}
                      </p>
                    </td>
                    <td>
                      <p className="mb-0 text-center">{operacion.patente}</p>
                    </td>
                    <td>
                      <p className="mb-0 text-center">{operacion.proveedor}</p>
                    </td>
                    <td>
                      <p className="mb-0 text-center">
                        {operacion.procedencia}
                      </p>
                    </td>
                    <td>
                      <p className="mb-0 text-center">{operacion.fecha_alta}</p>
                    </td>
                    <td>
                      <p className="mb-0 text-center">
                        {operacion.fecha_llegada}
                      </p>
                    </td>
                    <td>
                      <p className="mb-0 text-center">
                        {operacion.descripcion}
                      </p>
                    </td>
                    <td>
                      <p className="mb-0 text-center">{operacion.status}</p>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
        <Paginacion
          actual={this.state.page_actual}
          count={this.state.pagination.count}
          paginate={this.handlePage}
        />
      </React.Fragment>
    );
  }
}
export default TableOperacion;
