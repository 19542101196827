/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la consulta de una patente. 
*/
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Component } from 'react';
import { Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import MyInput from '../../../utils/custom/MyInput';
import MySelect from '../../../utils/custom/MySelect';
import * as message from '../messages/messages';
import * as utility from '../utils/utility';
import CustomSelect from '../../../utils/custom/CustomSelect';

class ModalConsulta extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            numero: "",
            aduana: "",
            patente: "",
            nombre: "",
            rfc: "",
            curp: "",
            tipo_patente_text: "Seleccione un tipo de patente",
            tipo_patente_id: "",
            domicilio: "",
            colonia_id: "",
            colonia_text: "Seleccione una colonia",
            codigo_postal: "",
            estado: "",
            ciudad: "",

            contacto: "",
            telefono: "",
            correo: "",

            contactos: [],
            colonias: [],
            tipos_patente: [{ clave: 0, nombre: "Propia" }, { clave: 1, nombre: "Socio" }, { clave: 2, nombre: "Corresponsal" }],
            input: [true, true, true, true, true, true, true, true, true, true, true, true, true],

            data_modificacion: {},
            data_modificacion_contactos: {}
        }

    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    handleConsulta = () => {
        if (this.state.numero.length === 0)
            message.message_modal("Advertencia", "Debe tener un Número de Agente Aduanal", 400);
        else {
            this.methodPOST_API(utility.url_consulta_patente, 'consulta', { numero: this.state.numero });
        }
    }
    handleModificar = () => {
        if (this.state.numero.length === 0)
            message.message_modal("Advertencia", "Debe tener un Número de Agente Aduanal", 400);
        else {
            message.message_confimacion('¿Desea continuar con la operación modificar?', 'Ingrese sus observaciones ')
                .then(res => {
                    if (res) {
                        let data = this.state.data_modificacion;
                        let data_contactos = this.state.data_modificacion_contactos;
                        if (Object.values(this.state.data_modificacion).length > 0 && Object.values(this.state.data_modificacion_contactos).length > 0) {
                            data['numero'] = this.state.numero;
                            data_contactos['id'] = this.state.numero;
                            this.methodPUT_API(utility.url_alta_patente, 'modifica', data);
                            this.methodPUT_API(utility.url_alta_contacto, 'default', data_contactos);
                        }
                        else if (Object.values(this.state.data_modificacion).length === 0 && Object.values(this.state.data_modificacion_contactos).length > 0) {
                            data['numero'] = this.state.numero;
                            data_contactos['numero'] = this.state.numero;
                            this.methodPUT_API(utility.url_alta_contacto, 'modifica', data_contactos);
                        }
                        else if (Object.values(this.state.data_modificacion).length > 0 && Object.values(this.state.data_modificacion_contactos).length === 0) {
                            data['numero'] = this.state.numero;
                            data_contactos['numero'] = this.state.numero;
                            this.methodPUT_API(utility.url_alta_patente, 'modifica', data);
                        }
                        else
                            message.message_modal("Advertencia", "No hay alguna modificación", 400);
                    }
                });
        }
    }
    handleCerrar = () => {
        this.props.toogleConsulta();
        this.clearState();
    }
    handleNumero = (e) => {
        let numero = e.target.value;
        this.setState({ numero });
    }
    handleAduana = (e) => {
        let aduana = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['aduana'] = aduana;
        this.setState({ aduana, data_modificacion });
    }
    handlePatente = (e) => {
        let patente = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['patente'] = patente;
        this.setState({ patente, data_modificacion });
    }
    handleNombre = (e) => {
        let nombre = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['nombre'] = nombre;
        this.setState({ nombre, data_modificacion });
    }
    handleRFC = (e) => {
        let rfc = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['rfc'] = rfc;
        this.setState({ rfc, data_modificacion });
    }
    handleCURP = (e) => {
        let curp = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['curp'] = curp;
        this.setState({ curp, data_modificacion });
    }
    handleTipo_patente = (e) => {
        let label = e.name_text;
        let label_id = e.name_index;
        let tipo_patente_text = e[label];
        let tipo_patente_id = e[label_id];
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['tpo_patente'] = tipo_patente_id;
        this.setState({ tipo_patente_id, tipo_patente_text, data_modificacion });
    }
    handleContacto = (e) => {
        let contacto = e.target.value;
        this.setState({ contacto });
    }
    handleTelefono = (e) => {
        let telefono = e.target.value;
        this.setState({ telefono });
    }
    handleCorreo = (e) => {
        let correo = e.target.value;
        this.setState({ correo });
    }
    handleDomicilio = (e) => {
        let domicilio = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['domicilio'] = domicilio;
        this.setState({ domicilio, data_modificacion });
    }
    handleColonia = (e) => {
        let label = e.name_text;
        let label_id = e.name_index;
        let colonia_text = e[label];
        let colonia_id = e[label_id];
        let data_modificacion = this.state.data_modificacion;
        let estado, ciudad;
        this.state.colonias.forEach(col => {
            if (colonia_id === col.asentamiento) {
                estado = col.estado;
                ciudad = col.ciudad;
            }
        })
        data_modificacion['colonia'] = colonia_id;
        this.setState({ colonia_id, colonia_text, data_modificacion, estado, ciudad });
    }
    handleCodigo_postal = (e) => {
        let codigo_postal = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['codigo_postal'] = codigo_postal;
        if (codigo_postal.length === 5)
            this.methodPOST_API(utility.url_codigo_postal, 'codigo_postal', { codigo: codigo_postal });
        this.setState({ codigo_postal, data_modificacion });
    }
    handleAgregar = () => {
        let contactos = this.state.contactos;
        let data_modificacion_contactos = this.state.data_modificacion_contactos;
        let data = {
            contacto: this.state.contacto,
            telefono: this.state.telefono,
            correo: this.state.correo,
        }
        contactos.push(data);
        data_modificacion_contactos['contactos'] = contactos;
        this.setState({ contactos, data_modificacion_contactos, contacto: "", telefono: "", correo: "" });
    }
    handleEliminaContacto = (e) => {
        let contactos = this.state.contactos;
        let data_modificacion_contactos = this.state.data_modificacion_contactos;
        contactos.splice(e.target.value, 1);
        data_modificacion_contactos['contactos'] = contactos;
        this.setState({ contactos, data_modificacion_contactos });
    }
    clearState = () => {
        this.setState({
            numero: "",
            aduana: "",
            patente: "",
            nombre: "",
            rfc: "",
            curp: "",
            tipo_patente_text: "Seleccione un tipo de patente",
            tipo_patente_id: "",
            domicilio: "",
            colonia_id: "",
            colonia_text: "Seleccione una colonia",
            codigo_postal: "",
            estado: "",
            ciudad: "",

            contacto: "",
            telefono: "",
            correo: "",

            contactos: [],
            colonias: [],
            tipos_patente: [{ clave: 0, nombre: "Propia" }, { clave: 1, nombre: "Socio" }, { clave: 2, nombre: "Corresponsal" }],
            input: [true, true, true, true, true, true, true, true, true, true, true, true, true],

            data_modificacion: {},
            data_modificacion_contactos: {}
        });
    }
    /**
     * Función que permite hacer una petición de forma POST.
     * @param {String} url URL a consultar. 
     * @param {String} state Opción del case.  
     * @param {Object} data Objeto a mandar atraves del metodo POST. 
     */
    methodPOST_API = (url, state, data) => {
        let url_intern = url;
        axios.post(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        case 'modificacion':
                            break;
                        case 'codigo_postal':
                            if (this._isMounted)
                                this.setState({
                                    colonias: response.data.results
                                });
                            break;
                        case 'consulta':
                            this.setState({
                                aduana: response.data.results.aduana,
                                patente: response.data.results.patente,
                                nombre: response.data.results.nombre,
                                rfc: response.data.results.rfc,
                                curp: response.data.results.curp,
                                tipo_patente_text: this.state.tipos_patente.find(tipo => tipo.clave === parseInt(response.data.results.tpo_patente)).nombre,
                                domicilio: response.data.results.domicilio,
                                colonia_text: response.data.results.colonia,
                                codigo_postal: response.data.results.codigo_postal,
                                estado: response.data.results.estado,
                                ciudad: response.data.results.ciudad,
                                contactos: response.data.results.contactos,
                                input: [false, false, false, false, false, false, false, false, false, false, false, false, false]
                            }, () => { this.methodPOST_API(utility.url_codigo_postal, 'codigo_postal', { codigo: response.data.results.codigo_postal }); });

                            break;
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    /**
     * Función que permite hacer una petición de forma GET
     * @param {String} url URL a consulta 
     * @param {String} state Opción del case 
     */
    methodGET_API = (url, state) => {
        let url_intern = url;
        axios.get(url_intern)
            .then(response => {
                if (response.status >= 200 && response.status < 400) {
                    switch (state) {
                        case 'patentes':
                            if (this._isMounted)
                                this.setState({
                                    patentes: response.data.results
                                });
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    /**
     * Función que permite hacer una petición de forma PUT
     * @param {String} url URL a consulta 
     * @param {String} state Opción del case 
     * @param {Object} data Objeto que contiene los datos a modficar
     */
    methodPUT_API = (url, state, data) => {
        let url_intern = url;
        axios.put(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400) {
                    switch (state) {
                        case 'modifica':
                            this.props.toogleConsulta();
                            message.message_modal(response.data.title, response.data.text, response.status);
                            this.clearState();
                            this.props.update(1);
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    render() {
        return (
            <Modal isOpen={this.props.toogle_consulta} toggle={this.handleCerrar} backdrop="static" size="xl">
                <ModalHeader toggle={this.handleCerrar}>Consulta Agente Aduanal</ModalHeader>
                <ModalBody>
                    <Form className="container-fluid">
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-10 col-8" id="numero_consulta"
                                onChange={this.handleNumero} nameLabel="No. Agente Aduanal" />
                            <Button color="primary" className="col-lg-2 col-4 mb-2" onClick={this.handleConsulta} >Consulta</Button>
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-12 col-12" id="nombre_consulta_patente" nameLabel="Nombre del Agente Aduanal"
                                value={this.state.nombre} onChange={this.handleNombre} disabled={this.state.input[0]} />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-6 col-12" id="rfc_consulta_patente" nameLabel="RFC"
                                value={this.state.rfc} onChange={this.handleRFC} disabled={this.state.input[1]} />
                            <MyInput type="text" bootstrap="col-lg-6 col-12" id="curp_consulta_patente" nameLabel="CURP"
                                value={this.state.curp} onChange={this.handleCURP} disabled={this.state.input[2]} />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="aduana_consulta_patente" nameLabel="Aduana"
                                value={this.state.aduana} onChange={this.handleAduana} disabled={this.state.input[3]} />
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="patente_consulta_patente" nameLabel="Patente"
                                value={this.state.patente} onChange={this.handlePatente} disabled={this.state.input[4]} />
                            <CustomSelect bootstrap="col-lg-4 col-12" name_text='nombre' name_index='clave' id="patente_alta_tipo_patente"
                                nameLabel="Tipo de Patente" value={this.state.tipo_patente_text} onChange={this.handleTipo_patente}
                                options={this.state.tipos_patente} disabled={this.state.input[5]} />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="domicilio_consulta_patente" nameLabel="Domicilio"
                                value={this.state.domicilio} onChange={this.handleDomicilio} disabled={this.state.input[6]} />
                            <MyInput type="text" bootstrap="col-lg-4 col-12" id="codigo_postal_consulta_patente" nameLabel="Código Postal"
                                value={this.state.codigo_postal} onChange={this.handleCodigo_postal} disabled={this.state.input[8]}
                                maxLength="5" />
                            <CustomSelect bootstrap="col-lg-4 col-12" name_text='asentamiento' name_index='asentamiento' id="colonia_consulta_patente"
                                nameLabel="Colonia" value={this.state.colonia_text} onChange={this.handleColonia}
                                options={this.state.colonias} disabled={this.state.input[7]} />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-6 col-12" id="curp_alta_patente" nameLabel="Estado/Provincia/Región"
                                value={this.state.estado} disabled />
                            <MyInput type="text" bootstrap="col-lg-6 col-12" id="rfc_alta_patente" nameLabel="Ciudad"
                                value={this.state.ciudad} disabled />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-5 col-12" id="contacto_consulta_patente" nameLabel="Nombre del Contacto"
                                value={this.state.contacto} onChange={this.handleContacto} disabled={this.state.input[9]} />
                            <MyInput type="text" bootstrap="col-lg-5 col-12" id="telefono_consulta_patente" nameLabel="Telefono del Contacto"
                                value={this.state.telefono} onChange={this.handleTelefono} disabled={this.state.input[10]} />
                            <Button color="primary" className="col-lg-2 col-4 mb-2" onClick={this.handleAgregar} disabled={this.state.input[11]}>
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-12 col-12" id="correo_consulta_patente" nameLabel="Correo del Contacto"
                                value={this.state.correo} onChange={this.handleCorreo} disabled={this.state.input[12]} />
                        </FormGroup>
                    </Form>
                    <Table size="sm" bordered hover responsive>
                        <thead className="text-center header-table">
                            <tr>
                                <td>Nombre</td>
                                <td>Telefono</td>
                                <td>Correo</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody className="body-table">
                            {this.state.contactos.map((contacto, i) => (
                                <tr key={i}>
                                    <td className="text-center">{contacto.contacto}</td>
                                    <td className="text-center">{contacto.telefono}</td>
                                    <td className="text-center">{contacto.correo}</td>
                                    <td className="text-center"><Button color="danger" size="sm" value={i} onClick={this.handleEliminaContacto}>
                                        Eliminar
                                    </Button></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={this.handleModificar}>Modificar</Button>
                    <Button color="primary" onClick={this.handleCerrar}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default ModalConsulta;