import axios from "axios";
import React, { Component } from "react";
import { Spinner, Table } from "reactstrap";
import "../../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import * as message from "../../utils/messages/message";
import Paginacion from "../Pagination";
import ConsultaEspecifica from "./SearchSpecific";
import * as utility from "./utils/utility";

class TableDiario extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      page_actual: 1,
      pagination: { count: 1, results: [] },
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_pagination_Num_parte, "pagination");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps) {
    if (this.props.actualizar !== prevProps.actualizar) {
      this.handlePage(this.state.page_actual);
      this.props.update(0);
    }
  }
  handlePage = (page_actual) => {
    this.setState({ page_actual }, () => {
      this.methodGET_API(utility.url_pagination_Num_parte, "pagination");
    });
  };
  methodGET_API = (url, state) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "pagination":
              if (this._isMounted)
                this.setState({
                  pagination: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "pagination":
              if (this._isMounted === true)
                this.setState({
                  pagination: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  onBackTable = () => {
    this.methodGET_API(utility.url_pagination_Num_parte, "pagination");
  };
  onChangeFiltrada = (data) => {
    this.setState({ page_actual: 1 }, () => {
      this.methodPOST_API(utility.url_pagination_Num_parte, "pagination", data);
    });
  };
  render() {
    return (
      <React.Fragment>
        <ConsultaEspecifica
          onBackTable={this.onBackTable}
          onChangeFiltrada={this.onChangeFiltrada}
        />
        <div className="overflow-auto tm-table">
          <Table size="sm" id="tabla_Diario" bordered hover responsive>
            <thead className="text-center header-table">
              <tr>
                <th>Parte</th>
                <th>Número Lote</th>
                <th>Número Auxiliar</th>
                <th>Fracción</th>
                <th>Visualizar</th>
              </tr>
            </thead>
            <tbody className="body-table hover-gray">
              {this.state.pagination.results.length === 0 ? (
                <tr>
                  <td colSpan="12" align="center">
                    <Spinner
                      style={{ width: "10rem", height: "10rem" }}
                      color="danger"
                      type="grow"
                      className="align-self-center"
                    />
                  </td>
                </tr>
              ) : (
                <React.Fragment>
                  {this.state.pagination.results.map((num) => (
                    <tr key={num.id_parte}>
                      <td>
                        <p className="mb-0 text-center">{num.num_lote}</p>
                      </td>
                      <td>
                        <p className="mb-0 text-center">{num.id_parte}</p>
                      </td>
                      <td>
                        <p className="mb-0 text-center">{num.num_auxiliar}</p>
                      </td>
                      <td>
                        <p className="mb-0 text-center">{num.id_fraccion}</p>
                      </td>
                      <td className="text-center" style={{ cursor: "pointer" }}>
                        <span>
                          <FontAwesomeIcon icon={faEye} />
                        </span>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              )}
            </tbody>
          </Table>
        </div>
        <Paginacion
          actual={this.state.page_actual}
          count={this.state.pagination.count}
          paginate={this.handlePage}
        />
      </React.Fragment>
    );
  }
}
export default TableDiario;
