/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente general de la aplicación empleados. 
*/
import React, { Component } from "react";
import * as permisos from "../../utils/permisos/permisos";
import SearchBar from "./SearchBar";
import TablePuestos from "./Table";
import * as utility from "./utils/utility";
import axios from "axios";
import * as message from "../../utils/messages/message";

class Puestos extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      actualizar: 0,
      patentes: [],
      clientes: [],
      paises: [],
      consolidadoras: [],
      proveedores: [],
      beneficiarios: [],
      phxcc: [],
      permisos: permisos.obtener_permisos_by_app(
        "operacion",
        localStorage.getItem("perm")
      ),
      on_cliente:
        localStorage.getItem("puesto") === "Cliente"
          ? localStorage.getItem("id_usr")
          : false,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_clientes, "clientes");
    this.methodGET_API(utility.url_patentes, "patentes");
    this.methodGET_API(utility.url_paises, "paises");
    this.methodGET_API(utility.url_consolidadoras, "consolidadoras");
    this.methodGET_API(utility.url_proveedor, "proveedores");
    this.methodGET_API(utility.url_phxcc, "phxcc");
    this.methodGET_API(utility.url_cat_beneficiarios, "beneficiarios");
    this.methodGET_API(utility.url_pedimento, "pedimento");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "clientes":
              if (this._isMounted === true)
                this.setState({ clientes: response.data.results });
              break;
            case "patentes":
              if (this._isMounted === true)
                this.setState({ patentes: response.data.results });
              break;
            case "paises":
              if (this._isMounted === true)
                this.setState({ paises: response.data.results });
              break;
            case "consolidadoras":
              if (this._isMounted === true)
                this.setState({ consolidadoras: response.data.results });
              break;
            case "pedimento":
              if (this._isMounted === true) {
                const numerosPedimento = response.data.results.map(
                  (item) => item.numero_pedimento
                );
                this.setState({ pedimento: numerosPedimento });
              }
              break;
            case "proveedores":
              if (this._isMounted === true)
                this.setState({ proveedores: response.data.results });
              break;
            case "phxcc":
              if (this._isMounted === true)
                this.setState({ phxcc: response.data.results });
              break;
            case "beneficiarios":
              if (this._isMounted === true)
                this.setState({ beneficiarios: response.data.results });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  update = (cambio) => {
    this.setState({ actualizar: cambio });
  };
  render() {
    return (
      <div className="container-fluid">
        <SearchBar
          update={this.update}
          permisos={this.state.permisos}
          clientes={this.state.clientes}
          patentes={this.state.patentes}
          paises={this.state.paises}
          consolidadoras={this.state.consolidadoras}
          proveedores={this.state.proveedores}
          phxcc={this.state.phxcc}
          beneficiarios={this.state.beneficiarios}
          pedimento={this.state.pedimento}
        />
        <TablePuestos update={this.update} actualizar={this.state.actualizar} consolidadoras={this.state.consolidadoras}/>
      </div>
    );
  }
}
export default Puestos;
