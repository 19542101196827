/* 
Componente que genera un input de tipo input orientado al diseño del sistema
*/
import React from "react";
import "../../assets/style.css";

const MyInput = (props) => {
  return (
    <div className={`grupo p-0 ${props.bootstrap}`}>
      <input
        className={`input-animated col-12 mb-2`}
        type={props.type}
        id={props.id}
        disabled={props.disabled}
        value={props.value}
        defaultValue={props.defaultValue}
        maxLength={props.maxLength}
        onChange={props.onChange}
        placeholder=""
        min={props.min}
        max={props.max}
        step={props.step}
        required={props.required}
      />
      <span className="barra"></span>
      <br />
      <label className="label-animated" htmlFor={props.id}>
        {props.nameLabel}
      </label>
    </div>
  );
};
export default MyInput;

/* Intento para chrome

import React, { Component } from "react";
import "../../assets/style.css";

class MyInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };
  }

  handleFocus = () => {
    // Agrega un retraso para dar tiempo al navegador a procesar el enfoque
    setTimeout(() => {
      this.setState({ focused: true });
    }, 10);
  };

  handleBlur = () => {
    this.setState({ focused: false });
  };

  render() {
    const { bootstrap, type, id, disabled, value, defaultValue, maxLength, onChange, nameLabel, min, max, step, required } = this.props;
    const { focused } = this.state;

    const inputClassName = `input-animated col-12 mb-2 ${focused ? "focused" : ""}`;
    const labelClassName = `label-animated ${focused ? "focused" : ""}`;

    return (
      <div className={`grupo p-0 ${bootstrap}`}>
        <input
          className={inputClassName}
          type={type}
          id={id}
          disabled={disabled}
          value={value}
          defaultValue={defaultValue}
          maxLength={maxLength}
          onChange={onChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          placeholder=""
          min={min}
          max={max}
          step={step}
          required={required}
        />
        <span className="barra"></span>
        <br />
        <label className={labelClassName} htmlFor={id}>
          {nameLabel}
        </label>
      </div>
    );
  }
}

export default MyInput; */

/* ------------------------------------------------------------------------------------------------
ESTRUCTURA PARA LA LOGICA DEL INPUT GENERAL MODIFICADO <MyInput />
    SE NECESITA LO SIGUIENTE:
        -UNA VARIABLE PARA GUARDAR EL CONTENIDO DEL INPUT.
            variable: ""
        -UNA FUNCIÓN DE CAMBIO DE ESTADO.
            handleNombre_funcion = (e) => {
                let variable_texto = e.target.value;
                this.setState({ variable_texto });
            }
    LA ESTRUCTURA DEL INPUT TIENE UNA LOGICA, SUS ETIQUETAS SE EXPLICAN A CONTINUCACIÓN:
    <MyInput 
        *type=""         -> String que selecciona el tipo de input a usar.
        bootstrap=""     -> String para agregar clases de bootstrap.
        id=""            -> String quer contine el identificador de HTML. 
        *nameLabel=""    -> Nombre del Input a mostar.
        *value={}        -> Variable que guarda el contenido del input.
        *onChange={}     -> Evento de cambio en el input.
        disabled={}      -> Variable booleana quye decide si se hablita o no el input.
        defaultValue={}  -> Variable que indica si tendra un valor predefinido.
        maxLength={}     -> Texto que idnica el numero de caracteres que son aceptados.
        min={}           -> Máximo número para el input de tipo numero.
        max={}           -> Mínimo número para el input de tipo numero.
        step={}          -> Incremento de número para el input de tipo numero.
    />
    * Incica que las etiquetas son obligatorias.
------------------------------------------------------------------------------------------------ */
