import {
  faBell,
  faCaretDown,
  faComment,
  faHome,
  faPowerOff,
  faEnvelope,
  faExternalLinkAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Collapse, Form, Nav, Navbar, NavbarToggler } from "reactstrap";
import "../../assets/style.css";
import Name from "../../img/Arancel_Name.png";
import Arancel from "../../img/logo.png";
import * as authActions from "../../utils/actions/auth";
import * as notifyActions from "../../utils/actions/notify";
import OptionNav from "./optionNav";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      open_drop: false,
      notificaciones_mensaje: false,
      notificaciones_correo: false,
    };
    this.handleOpen = this.handleOpen.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (this.props.notificaciones !== prevProps.notificaciones) {
      for (let i = 0; i < this.props.notificaciones.length; i++)
        if (this.props.notificaciones[i].aplicacion === "mensaje")
          this.setState({
            notificaciones_mensaje: true,
            notificaciones_correo: true,
          });
    }
  }
  handleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  handleDrop = () => {
    let drop_item = document.getElementById("drop-down-buttons");
    this.setState({ open_drop: !this.state.open_drop }, () => {
      if (this.state.open_drop === true) drop_item.classList.remove("d-none");
      else drop_item.classList.add("d-none");
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onAuth(localStorage.getItem("user_name"));
    this.props.history.push("/login");
  };
  handleChats = (e) => {
    e.preventDefault();
    let drop_item = document.getElementById("drop-down-buttons");
    drop_item.classList.add("d-none");
    this.setState({ notificaciones_mensaje: false });
    this.props.history.push("/mensajes");
  };
  handleNotificaciones = (e) => {
    e.preventDefault();
    let drop_item = document.getElementById("drop-down-buttons");
    drop_item.classList.add("d-none");
    this.props.removeNotificaciones();
    this.props.history.push("/");
  };
  handleEmail = (e) => {
    e.preventDefault();
    let drop_item = document.getElementById("drop-down-buttons");
    drop_item.classList.add("d-none");
    this.setState({ notificaciones_correo: false });
    this.props.history.push("/correo");
  };
  handleOpenCurrentPageInNewTab = () => {
    const homeUrl = window.location.origin + "/";
    window.open(homeUrl, "_blank");
  };
  render() {
    let areas = [...localStorage.getItem("areas").split(",")];
    let all_apps = JSON.parse(localStorage.getItem("apps"));
    const puesto = localStorage.getItem("puesto");

    if (puesto !== "Transportista") {
      return (
        <Navbar expand="lg" className="header" id="navegacion_menu" dark>
          <Link
            to="/"
            className="navbar-brand pr-3"
            style={{
              background: "rgba(255,255,255,0.3)",
              borderRadius: "6px",
              color: "black",
              border: "solid white 2px",
            }}
          >
            <img
              src={Arancel}
              style={{ width: "45px" }}
              alt="Arancel S.A. de C.V."
            />
            <img
              src={Name}
              className="pt-1"
              style={{ width: "90px" }}
              alt="Arancel S.A. de C.V."
            />
          </Link>
          <Link to="/" className="navbar-brand d-none d-lg-inline">
            <div className="button-header ">
              <FontAwesomeIcon icon={faHome} />
            </div>
          </Link>
          <Link
            to="#"
            onClick={this.handleOpenCurrentPageInNewTab}
            className="navbar-brand d-none d-lg-inline"
          >
            <div className="button-header">
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </div>
          </Link>
          <Form className="d-flex justify-content-between d-lg-none col-6 p-0">
            <button
              className="button-header d-inline"
              style={{ padding: "6px 10px", border: "none", color: "#fff" }}
              onClick={this.handleChats}
            >
              {/* <spam className="m-pendiente"></spam> */}
              <FontAwesomeIcon icon={faComment} />
            </button>
            <div
              className="button-header d-inline-block"
              style={{ padding: "6px 11px", border: "none", color: "#fff" }}
              onClick={this.handleEmail}
            >
              {this.state.notificaciones_correo.length > 0 ? (
                <spam className="m-pendiente"></spam>
              ) : null}
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div
              className="button-header d-inline-block"
              style={{ padding: "6px 11px", border: "none", color: "#fff" }}
              onClick={this.handleNotificaciones}
            >
              {this.props.notificaciones.length > 0 ? (
                <spam className="m-pendiente"></spam>
              ) : null}
              <FontAwesomeIcon icon={faBell} />
            </div>
            <button
              className="button-header d-inline-block"
              style={{ padding: "6px 10px", border: "none", color: "#fff" }}
              onClick={this.handleSubmit}
            >
              <FontAwesomeIcon icon={faPowerOff} />
            </button>
          </Form>
          <NavbarToggler className="col-12 mt-2" onClick={this.handleOpen} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav navbar>
              {areas.map((area, index) => {
                if (area === "7") {
                  let options = all_apps.map((data) =>
                    data.menu === "7" ? data.apps_menu : null
                  );
                  let option_menu = [];
                  for (let i = 0; i < options.length; i++) {
                    if (options[i] !== null) option_menu = [...options[i]];
                  }
                  return (
                    <OptionNav
                      area="administracion"
                      options={option_menu}
                      key={index}
                    />
                  );
                }
                if (area === "6") {
                  let options = all_apps.map((data) =>
                    data.menu === "6" ? data.apps_menu : null
                  );
                  let option_menu = [];
                  for (let i = 0; i < options.length; i++) {
                    if (options[i] !== null) option_menu = [...options[i]];
                  }
                  option_menu.push("chats_app");
                  option_menu.push("correo_app");
                  return (
                    <OptionNav
                      area="utilerias"
                      options={option_menu}
                      key={index}
                    />
                  );
                }
                if (area === "5") {
                  let options = all_apps.map((data) =>
                    data.menu === "5" ? data.apps_menu : null
                  );
                  let option_menu = [];
                  for (let i = 0; i < options.length; i++) {
                    if (options[i] !== null) option_menu = [...options[i]];
                  }
                  return (
                    <OptionNav
                      area="catalogos"
                      options={option_menu}
                      key={index}
                    />
                  );
                }
                if (area === "4") {
                  let options = all_apps.map((data) =>
                    data.menu === "4" ? data.apps_menu : null
                  );
                  let option_menu = [];
                  for (let i = 0; i < options.length; i++) {
                    if (options[i] !== null) option_menu = [...options[i]];
                  }
                  return (
                    <OptionNav
                      area="operacion"
                      options={option_menu}
                      key={index}
                    />
                  );
                }
                if (area === "3") {
                  let options = all_apps.map((data) =>
                    data.menu === "3" ? data.apps_menu : null
                  );
                  let option_menu = [];
                  for (let i = 0; i < options.length; i++) {
                    if (options[i] !== null) option_menu = [...options[i]];
                  }
                  return (
                    <OptionNav
                      area="bancos"
                      options={option_menu}
                      key={index}
                    />
                  );
                }
                if (area === "2") {
                  let options = all_apps.map((data) =>
                    data.menu === "2" ? data.apps_menu : null
                  );
                  let option_menu = [];
                  for (let i = 0; i < options.length; i++) {
                    if (options[i] !== null) option_menu = [...options[i]];
                  }
                  return (
                    <OptionNav
                      area="facturacion"
                      options={option_menu}
                      key={index}
                    />
                  );
                }
                if (area === "1") {
                  let options = all_apps.map((data) =>
                    data.menu === "1" ? data.apps_menu : null
                  );
                  let option_menu = [];
                  for (let i = 0; i < options.length; i++) {
                    if (options[i] !== null) option_menu = [...options[i]];
                  }
                  return (
                    <OptionNav
                      area="contabilidad"
                      options={option_menu}
                      key={index}
                    />
                  );
                }
                return null;
              })}
            </Nav>
          </Collapse>
          <Form className="ml-auto d-lg-inline d-none">
            <button
              type="button"
              className="button-header d-inline-block mr-3"
              style={{
                padding: "6px 13px",
                border: "none",
                color: "#fff",
                marginLeft: "4px",
              }}
              onClick={this.handleDrop}
            >
              {this.props.notificaciones.length > 0 ? (
                <spam className="m-pendiente"></spam>
              ) : null}
              <FontAwesomeIcon icon={faCaretDown} />
            </button>
            <div id="drop-down-buttons" className="d-none mt-2">
              <button
                className="button-header d-inline"
                style={{ padding: "6px 10px", border: "none", color: "#fff" }}
                onClick={this.handleChats}
              >
                {this.state.notificaciones_mensaje ? (
                  <spam className="m-pendiente"></spam>
                ) : null}
                <FontAwesomeIcon icon={faComment} />
              </button>
              <br />
              <br />
              <button
                className="button-header d-inline"
                style={{ padding: "8px 11px", border: "none", color: "#fff" }}
                onClick={this.handleEmail}
              >
                {this.state.notificaciones_correo.length > 0 ? (
                  <spam className="m-pendiente"></spam>
                ) : null}
                <FontAwesomeIcon icon={faEnvelope} />
              </button>
              <br />
              <br />
              <button
                className="button-header d-inline"
                style={{ padding: "8px 11px", border: "none", color: "#fff" }}
                onClick={this.handleNotificaciones}
              >
                {this.props.notificaciones.length > 0 ? (
                  <spam className="m-pendiente"></spam>
                ) : null}
                <FontAwesomeIcon icon={faBell} />
              </button>
            </div>
            <button
              className="button-header d-inline-block"
              style={{ padding: "6px 10px", border: "none", color: "#fff" }}
              onClick={this.handleSubmit}
            >
              <FontAwesomeIcon icon={faPowerOff} />
            </button>
          </Form>
        </Navbar>
      );
    }

    if (puesto === "Transportista") {
      return (
        <Navbar expand="lg" className="header" id="navegacion_menu" dark>
          <Link
            to="/"
            className="navbar-brand pr-3"
            style={{
              background: "rgba(255,255,255,0.3)",
              borderRadius: "6px",
              color: "black",
              border: "solid white 2px",
            }}
          >
            <img
              src={Arancel}
              style={{ width: "45px" }}
              alt="Arancel S.A. de C.V."
            />
            <img
              src={Name}
              className="pt-1"
              style={{ width: "90px" }}
              alt="Arancel S.A. de C.V."
            />
          </Link>
          <Link to="/" className="navbar-brand d-none d-lg-inline">
            <div className="button-header ">
              <FontAwesomeIcon icon={faHome} />
            </div>
          </Link>
          <Form className="d-flex justify-content-between d-lg-none col-6 p-0">
            <button
              className="button-header d-inline-block"
              style={{ padding: "6px 10px", border: "none", color: "#fff" }}
              onClick={this.handleSubmit}
            >
              <FontAwesomeIcon icon={faPowerOff} />
            </button>
          </Form>
          <NavbarToggler className="col-12 mt-2" onClick={this.handleOpen} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav navbar>
              {areas.map((area, index) => {
                if (area === "4") {
                  let options = all_apps.map((data) =>
                    data.menu === "4" ? data.apps_menu : null
                  );
                  let option_menu = [];
                  for (let i = 0; i < options.length; i++) {
                    if (options[i] !== null) option_menu = [...options[i]];
                  }
                  return (
                    <OptionNav
                      area="operacion"
                      options={option_menu}
                      key={index}
                    />
                  );
                }
                return null;
              })}
            </Nav>
          </Collapse>
          <Form className="ml-auto d-lg-inline d-none">
            <button
              className="button-header d-inline-block"
              style={{ padding: "6px 10px", border: "none", color: "#fff" }}
              onClick={this.handleSubmit}
            >
              <FontAwesomeIcon icon={faPowerOff} />
            </button>
          </Form>
        </Navbar>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    notificaciones: state.notify.notificaciones,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username) => dispatch(authActions.authLogout(username)),
    removeNotificaciones: () => dispatch(notifyActions.removeNotificaciones()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
