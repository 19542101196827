export const url_crea_empleado = '/DjangoWeb/EmpleadosApp/crea_modifica/empleado';
export const url_paginacion = '/DjangoWeb/EmpleadosApp/consulta/empleados/paginacion/principal';
export const url_departamentos = '/DjangoWeb/EmpleadosApp/consulta/departamentos/lista';
export const url_puestos = '/DjangoWeb/EmpleadosApp/consulta/puestos/paginacion/principal';
export const url_crea_puesto = '/DjangoWeb/EmpleadosApp/crea_modifica/puestos';
export const url_consulta_empleado = '/DjangoWeb/EmpleadosApp/consulta/empleado';
export const url_desactivar = '/DjangoWeb/EmpleadosApp/desactiva/empleado';

export const url_estados = '/DjangoWeb/BaseApp/consulta/estados/lista';
export const url_codigo_postal = '/DjangoWeb/BaseApp/consulta/codigo_postal';
/**
 * Función que nos permite filtar la tabla por el input general que serra de tipo text.
 */
export const general_search = (e) => {
    e.preventDefault();
    let tr = document.getElementById('tabla_empleados').getElementsByTagName('tr');
    let data_input = document.getElementById('busqueda_general_empleados').value.toUpperCase();
    for (let i = 0; i < tr.length; i++) {
        let mostrar = false;
        let data = tr[i].getElementsByTagName('p');
        for (let j = 0; j < data.length; j++) {
            if (data_input === "") {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
            else if (data[j].textContent.toUpperCase().includes(data_input)) {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "rgba(0,123,255,1)";
                data[j].style.color = "#fff";
            }
            else {
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que nos permite filtrar latabla solamnete por la fecha.
 */
export const date_search = (e) => {
    e.preventDefault();
    var tabla_anticipo, input_general, tr, dato, input_dato, mostrar;
    tabla_anticipo = document.getElementById("tabla_empleados");
    input_general = document.getElementById("busqueda_date_empleados");
    input_dato = input_general.value;
    tr = tabla_anticipo.getElementsByTagName("tr");
    for (var i = 0; i < tr.length; i++) {
        mostrar = false;
        dato = tr[i].getElementsByTagName("p");
        for (var j = 0; j < dato.length; j++) {
            if (dato[j].textContent === input_dato) {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "rgba(0,123,255,1)";
                dato[j].style.color = "#fff";
            }
            else if (input_dato === "") {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
            else {
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que obtiene el los nombres de todos los puestos
 * @param {Array} array Arreglode puestos 
 * @returns {Array} Arreglo con solo los nombres de los puestos
 */
export const get_name_puesto = (array) => {
    let only_name = [];
    for (let i = 0; i < array.length; i++) {
        only_name.push(array[i].puesto);
    }
    return (only_name);
}
/**
 * Función que obtiene el número del puesto
 * @param {Array} array Arreglo de puestos 
 * @param {String} nombre Nombre del puesto 
 * @returns {Number} Número del puesto previamente dado
 */
export const get_number_puesto = (array, nombre) => {
    for (let i = 0; i < array.length; i++)
        if (nombre === array[i].puesto)
            return (array[i].id_puesto);
}
/**
 * Funcion que permite crear un objeto para dar de alta cambiando el nombre del puesto por su número
 * @param {Array} puestos Arreglo de puestos 
 * @returns {Object} Objeto para la alta de un empleado
 */
export const handleTextInput = (puestos) => {
    let puesto_s = null;
    for (let i = 0; i < puestos.length; i++)
        if (document.getElementById("puesto_alta_empleado").value === puestos[i].puesto)
            puesto_s = puestos[i].id_puesto;
    let data = {
        nombre: document.getElementById("nombre_alta_empleado").value,
        apellido_paterno: document.getElementById("apellidoP_alta_empleado").value,
        apellido_materno: document.getElementById("apellidoM_alta_empleado").value,
        rfc: document.getElementById("rfc_alta_empleado").value,
        curp: document.getElementById("curp_alta_empleado").value,
        imss: document.getElementById("imss_alta_empleado").value,
        fecha_ingreso: document.getElementById("fecha_ingreso_alta_empleado").value,
        domicilio: document.getElementById("domicilio_alta_empleado").value,
        ciudad: document.getElementById("ciudad_alta_empleado").value,
        codigo_postal: document.getElementById("cp_alta_empleado").value,
        estado: document.getElementById("estado_alta_empleado").value,
        telefono: document.getElementById("telefono_alta_empleado").value,
        correo: document.getElementById("correo_alta_empleado").value,
        puesto: puesto_s,
        nivel_estudios: document.getElementById("nivel_estudios_alta_empleado").value,
    };
    return (data);
}
/**
 * Función que agrega una clase un objeto del DOM
 * @param {String} id ID del objeto en el DOM 
 */
export const add_class_disabled = (id) => {
    document.getElementById(id).classList.add('input-animatedd');
}
/**
 * Función que valida si el puesto existe
 * @param {String} puesto Puesto  
 * @param {Array} array Arreglo de puesto 
 * @returns {Boolean} Si el puesto existe o no
 */
export const valida_puesto = (puesto, array) => {
    for (let i = 0; i < array.length; i++)
        if (puesto === array[i].puesto)
            return true;
        else
            continue;
    return false;
}/**
 * Función que permite buscar el ID de un departamento dado su nombre
 * @param {String} departamento Nombre del departamento 
 * @param {Array} array Arreglo de departamento 
 * @returns {Number} ID del departamento solicitado
 */
export const get_id_departamento = (departamento, array) => {
    for (let i = 0; i < array.length; i++)
        if (departamento === array[i].nombre)
            return array[i].id_departamento;
}
/**
 * Función que permite validar si algun campo de la data esta vacio o no.
 * @param {Object} data Datos para la consulta filtrada de la tabla.
 */
export const valida_specific_data = (data) => {
    if (data.nombre.length === 0)
        delete data.nombre;
    if (data.apellido_paterno.length === 0)
        delete data.apellido_paterno;
    if (data.apellido_materno.length === 0)
        delete data.apellido_materno;
    if (data.rfc.length === 0)
        delete data.rfc;
    if (data.curp.length === 0)
        delete data.curp;
    if (data.nivel_estudios.length === 0)
        delete data.nivel_estudios;
    if (data.domicilio.length === 0)
        delete data.domicilio;
    if (data.estado.length === 0)
        delete data.estado;
    if (data.colonia.length === 0)
        delete data.colonia;
    if (data.departamento.length === 0)
        delete data.departamento;
    if (data.imss.length === 0)
        delete data.imss;
    if (data.puesto.length === 0)
        delete data.puesto;
}