/* 
Autor: Alexis Yael Hernández Grimaldo
Descripción: El componenete genera un bloque correspondiente a la busqueda dinamica en la tabla para acceder a la información.
*/
import React, { Component } from 'react';
import { Button, Collapse, Form, FormGroup } from 'reactstrap';
import '../../assets/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

class BusquedaEspecifica extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toogle_collapse: false,
        }
    }
    toogleCollapse = (e) => {
        this.setState(prevState => ({ toogle_collapse: !prevState.toogle_collapse }))
    }
    handleValida = (data) => {
        if (data.consecutivo.length === 0)
            delete data.consecutivo;
        if (data.tipo.length === 0)
            delete data.tipo;
        if (data.status.length === 0)
            delete data.status;
        if (data.cliente.length === 0)
            delete data.cliente;
        if (data.fecha_inicio.length > 0 && data.fecha_final.length > 0) {
            data['fechas'] = data.fecha_inicio + "," + data.fecha_final;

        }
        else if (data.fecha_inicio.length > 0 && data.fecha_final.length === 0) {
            data['fechas'] = data.fecha_inicio + "," + data.fecha_inicio;

        }
        else if (data.fecha_inicio.length === 0 && data.fecha_final.length > 0) {
            data['fechas'] = data.fecha_final + "," + data.fecha_final;
        }
        else {
            delete data.fecha_inicio
            delete data.fecha_final
        }
    }
    handleFiltrada = () => {
        let data = {
            consecutivo: document.getElementById('specific_numero').value,
            fecha_inicio: document.getElementById('specific_fecha_inicio').value,
            fecha_final: document.getElementById('specific_fecha_final').value,
            tipo: document.getElementById('specific_tipo').value,
            status: document.getElementById('specific_status').value,
            cliente: document.getElementById('specific_cliente').value,
        }
        this.handleValida(data)
        if (data.fechas) {
            delete data.fecha_final;
            delete data.fecha_inicio;
        }
        this.props.onChangeFiltrada(data);
    }
    render() {
        return (
            <React.Fragment>
                <div className="row mb-2 mx-1">
                    <h3 className="col-md-11 col-9 text-md-left px-0 mb-0">
                        Consulta Específica
					</h3>
                    <Button
                        color="info"
                        onClick={this.toogleCollapse}
                        className="col-md-1 col-3">
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </div>
                <Collapse isOpen={this.state.toogle_collapse} >
                    <Form className="container-fluid">
                        <FormGroup className="row mb-0">
                            <div className="grupo col-lg-4 p-0">
                                <input type="text" className="input-animated col-12 mb-2" id="specific_numero"
                                    required />
                                <span className="barra"></span>
                                <br /><label className="label-animated" htmlFor="specific_numero">Consecutivo</label>
                            </div>
                            <div className="grupo col-lg-4 p-0">
                                <input type="date" className="input-animated col-12 mb-2" id="specific_fecha_inicio" />
                                <span className="barra"></span>
                                <br /><label className="label-animated" htmlFor="specific_fecha_inicio">Fecha Inicio</label>
                            </div>
                            <div className="grupo col-lg-4 p-0">
                                <input type="date" className="input-animated col-12 mb-2" id="specific_fecha_final" />
                                <span className="barra"></span>
                                <br /><label className="label-animated" htmlFor="specific_fecha_final">Fecha Final</label>
                            </div>
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <div className="grupo col-lg-4 p-0">
                                <input type="text" className="input-animated col-12 mb-2" id="specific_tipo"
                                    required />
                                <span className="barra"></span>
                                <br /><label className="label-animated" htmlFor="specific_tipo">Tipo</label>
                            </div>
                            <div className="grupo col-lg-4 p-0">
                                <input type="text" className="input-animated col-12 mb-2" id="specific_status" required />
                                <span className="barra"></span>
                                <br /><label className="label-animated" htmlFor="specific_status">Status</label>
                            </div>
                            <div className="grupo col-lg-4 p-0">
                                <input type="text" className="input-animated col-12 mb-2" id="specific_cliente" required />
                                <span className="barra"></span>
                                <br /><label className="label-animated" htmlFor="specific_cliente">Cliente</label>
                            </div>
                        </FormGroup>
                        <FormGroup className="row d-flex justify-content-center mb-0">
                            <Button color="danger" className="mb-1" onClick={this.props.onBackTable}>Regresar</Button>
                            <Button color="success ml-2 mb-1" onClick={this.handleFiltrada}>Consultar</Button>
                        </FormGroup>
                    </Form>
                </Collapse>
            </React.Fragment>

        );
    }
}
export default BusquedaEspecifica;