/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un XXX. 
*/
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  Collapse,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap";
import CustomSelect from "../../../utils/custom/CustomSelect";
import MyInput from "../../../utils/custom/MyInput";
import * as message from "../messages/messages";
import WebSocketInstance from "../../../utils/websocket";
import * as utility from "../utility/utility";
import ModalAuxiliarContado from "./ModalAuxiliarContado";
import ModalAuxiliarAnticipo from "./ModalAuxiliarAnticipo";
import ModalAuxiliarPhxcdc from "./ModalAuxiliarPhxcdc";
import ModalAuxiliarCredito from "./ModalAuxiliarCredito";
import ModalAuxiliarSaldo from "./ModalAuxiliarSaldo";
import MySelect from "../../../utils/custom/MySelect";

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      numero: "",
      nombre: "",
      rfc: "",
      domicilio: "",
      colonia: "Seleccione una colonia",
      codigo_postal: "",
      pais_text: "Seleccione un país",
      pais_id: "",
      patente_text: "Seleccione una patente",
      patente_id: "",
      grupo_text: "Seleccione un grupo",
      grupo_id: "",
      regimen_fiscal_text: "Selecciona un regimen fiscal",
      regimen_fiscal_id: "",
      cta_banco_pago_text: "Selecciona una cuenta",
      cta_banco_pago_id: "",
      estado: "",
      ciudad: "",
      aduana: "",
      apellido_paterno: "",
      apellido_materno: "",
      curp: "",
      numero_interior: "",
      encargo_conferido: "0",
      rfc_sociedad: "",
      poder_notarial: "",
      colonias: [],

      cuenta: "",

      tarifa_entrega: "",
      tarifa_importacion: "",
      tarifa_exportacion: "",
      descuento_importacion: "",
      descuento_exportacion: "",

      nombre_representante: "",
      rfc_representante: "",
      curp_representante: "",

      nombre_contacto: "",
      telefono_contacto: "",
      correo_contacto: "",

      quien_recibe_domicilio: "",
      telefono_domicilio: "",
      pais_domicilio_text: "Seleccione un país",
      pais_domicilio_id: "",
      domicilio_domicilio: "",
      numero_interior_domicilio: "",
      codigo_postal_domicilio: "",
      estado_domicilio: "",
      ciudad_domicilio: "",
      colonia_domicilio: "",

      representantes: [],
      contactos_empresa: [],
      domicilios_cliente: [],

      auxiliarContado: "",
      auxiliarAnticipo: "",
      auxiliarPhxcdc: "",
      auxiliarCredito: "",
      auxiliarSaldo: "",

      toogle_cliente: true,
      toogle_contactos: false,
      toogle_domicilios: false,
      toogle_cuenta_aux: false,

      toogle_auxiliar_contado: false,
      toogle_auxiliar_anticipo: false,
      toogle_auxiliar_phxcdc: false,
      toogle_auxiliar_credito: false,
      toogle_auxiliar_saldo: false,

      enable_contacto: true,
      enable_domicilio: true,
      enable_cuenta_aux: true,

      data_modificacion: {},
    };
    this.toogleAuxiliarContado = this.toogleAuxiliarContado.bind(this);
    this.toogleAuxiliarAnticipo = this.toogleAuxiliarAnticipo.bind(this);
    this.toogleAuxiliarPhxcdc = this.toogleAuxiliarPhxcdc.bind(this);
    this.toogleAuxiliarCredito = this.toogleAuxiliarCredito.bind(this);
    this.toogleAuxiliarSaldo = this.toogleAuxiliarSaldo.bind(this);
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.numero;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "cliente",
      motivo: motivo,
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
    document.addEventListener('keyup', this.handleEnterKeyPress);
  }
  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener('keyup', this.handleEnterKeyPress);
  }
  toogleCliente = () => {
    this.setState((prevState) => ({
      toogle_cliente: !prevState.toogle_cliente,
      toogle_contactos: false,
      toogle_domicilios: false,
      toogle_cuenta_aux: false,
    }));
  };
  toogleContactos = () => {
    this.setState((prevState) => ({
      toogle_contactos: !prevState.toogle_contactos,
      toogle_cliente: false,
      toogle_domicilios: false,
      toogle_cuenta_aux: false,
    }));
  };
  toogleDomicilios = () => {
    this.setState((prevState) => ({
      toogle_domicilios: !prevState.toogle_domicilios,
      toogle_contactos: false,
      toogle_cliente: false,
      toogle_cuenta_aux: false,
    }));
  };
  toogleCuentaAuxiliar = () => {
    this.setState((prevState) => ({
      toogle_cuenta_aux: !prevState.toogle_cuenta_aux,
      toogle_contactos: false,
      toogle_cliente: false,
      toogle_domicilios: false,
    }));
  };
  toogleAuxiliarContado = () => {
    this.setState((prevState) => ({
      toogle_auxiliar_contado: !prevState.toogle_auxiliar_contado,
    }));
  };
  toogleAuxiliarAnticipo = () => {
    this.setState((prevState) => ({
      toogle_auxiliar_anticipo: !prevState.toogle_auxiliar_anticipo,
    }));
  };
  toogleAuxiliarPhxcdc = () => {
    this.setState((prevState) => ({
      toogle_auxiliar_phxcdc: !prevState.toogle_auxiliar_phxcdc,
    }));
  };
  toogleAuxiliarCredito = () => {
    this.setState((prevState) => ({
      toogle_auxiliar_credito: !prevState.toogle_auxiliar_credito,
    }));
  };
  toogleAuxiliarSaldo = () => {
    this.setState((prevState) => ({
      toogle_auxiliar_saldo: !prevState.toogle_auxiliar_saldo,
    }));
  };
  handleCallbackContado = (childData) => {
    this.setState({ auxiliarContado: childData });
  };
  handleCallbackAnticipo = (childData) => {
    this.setState({ auxiliarAnticipo: childData });
  };
  handleCallbackPhxcdc = (childData) => {
    this.setState({ auxiliarPhxcdc: childData });
  };
  handleCallbackCredito = (childData) => {
    this.setState({ auxiliarCredito: childData });
  };
  handleCallbackSaldo = (childData) => {
    this.setState({ auxiliarSaldo: childData });
  };

  handleCuentaChange = (e) => {
    this.setState({ cuenta: e.target.value });
  };

  handleCerrar = () => {
    this.props.toogleNuevo();
    this.handleLimpiar();
  };
  handleLimpiar = () => {
    this.setState({
      numero: "",
      nombre: "",
      rfc: "",
      domicilio: "",
      colonia: "Seleccione una colonia",
      codigo_postal: "",
      pais_text: "Seleccione un país",
      pais_id: "",
      patente_text: "Seleccione una patente",
      patente_id: "",
      grupo_text: "Seleccione un grupo",
      grupo_id: "",
      regimen_fiscal_text: "Selecciona un regimen fiscal",
      regimen_fiscal_id: "",
      cta_banco_pago_text: "Selecciona una cuenta",
      cta_banco_pago_id: "",
      estado: "",
      ciudad: "",
      aduana: "",
      apellido_paterno: "",
      apellido_materno: "",
      curp: "",
      numero_interior: "",
      encargo_conferido: "0",
      rfc_sociedad: "",
      poder_notarial: "",
      colonias: [],

      tarifa_entrega: "",
      tarifa_importacion: "",
      tarifa_exportacion: "",
      descuento_importacion: "",
      descuento_exportacion: "",

      nombre_representante: "",
      rfc_representante: "",
      curp_representante: "",

      nombre_contacto: "",
      telefono_contacto: "",
      correo_contacto: "",

      quien_recibe_domicilio: "",
      telefono_domicilio: "",
      pais_domicilio_text: "Seleccione un país",
      pais_domicilio_id: "",
      domicilio_domicilio: "",
      numero_interior_domicilio: "",
      codigo_postal_domicilio: "",
      estado_domicilio: "",
      ciudad_domicilio: "",
      colonia_domicilio: "",

      representantes: [],
      contactos_empresa: [],
      domicilios_cliente: [],

      auxiliarContado: "",
      auxiliarAnticipo: "",
      auxiliarPhxcdc: "",
      auxiliarCredito: "",
      auxiliarSaldo: "",

      toogle_cliente: true,
      toogle_contactos: false,
      toogle_domicilios: false,
      toogle_cuenta_aux: false,

      toogle_auxiliar_contado: false,
      toogle_auxiliar_anticipo: false,
      toogle_auxiliar_phxcdc: false,
      toogle_auxiliar_credito: false,
      toogle_auxiliar_saldo: false,

      enable_contacto: true,
      enable_domicilio: true,
      enable_cuenta_aux: true,

      data_modificacion: {},
    });
  };
  /* ------------------------ FUNCIONES QUE INTERACTUAN CON EL BACKEND ------------------------ */
  handleAlta_cliente = () => {
    let data_cliente = {
      nombre: this.state.nombre,
      apellido_paterno: this.state.apellido_paterno,
      apellido_materno: this.state.apellido_materno,
      rfc: this.state.rfc,
      curp: this.state.curp,
      pais: this.state.pais_id,
      direccion: this.state.domicilio,
      numero_interior: this.state.numero_interior,
      codigo_postal: this.state.codigo_postal,
      colonia: this.state.colonia,
      aduana: this.state.aduana,
      patente: this.state.patente_id,
      grupo: this.state.grupo_id,
      encargo_conferido: this.state.encargo_conferido,
      rfc_sociedad: this.state.rfc_sociedad,
      cta_banco_pago: this.state.cta_banco_pago_text,
      poder_notarial: this.state.poder_notarial,
      regimen_fiscal: this.state.regimen_fiscal_id,
    };

    let data_acuerdo = {
      tarifa_entrega: this.state.tarifa_entrega,
      tarifa_importacion: this.state.tarifa_importacion,
      tarifa_exportacion: this.state.tarifa_exportacion,
      descuento_importacion: this.state.descuento_importacion,
      descuento_exportacion: this.state.descuento_exportacion,
    };
    
    // Función para agregar ceros decimales si es necesario
    const agregarCerosDecimales = (valor) => {
      // Si el valor está vacío, devolver "0.00"
      if (!valor || valor.trim() === "") {
        return "0.00";
      }
    
      if (!valor.includes(".")) {
        // Si no tiene un punto decimal, agregar dos ceros decimales
        return valor + ".00";
      } else {
        // Si ya tiene un punto decimal, verificar si tiene dos decimales
        const partesValor = valor.split(".");
        if (partesValor[1].length !== 2) {
          // Si no tiene dos decimales, agregar ceros decimales adicionales
          return valor + "0"; // Agregar un cero decimal adicional
        } else {
          // Si ya tiene dos decimales, no es necesario hacer cambios
          return valor;
        }
      }
    };
    
    
    // Correcciones para tarifa_entrega, tarifa_importacion, tarifa_exportacion, descuento_importacion y descuento_exportacion
    data_acuerdo.tarifa_entrega = agregarCerosDecimales(this.state.tarifa_entrega);
    data_acuerdo.tarifa_importacion = agregarCerosDecimales(this.state.tarifa_importacion);
    data_acuerdo.tarifa_exportacion = agregarCerosDecimales(this.state.tarifa_exportacion);
    data_acuerdo.descuento_importacion = agregarCerosDecimales(this.state.descuento_importacion);
    data_acuerdo.descuento_exportacion = agregarCerosDecimales(this.state.descuento_exportacion);
    

    message
      .message_confimacion("¿Desea continuar con la operación alta?", "")
      .then((res) => {
        if (res)
          if (
            utility.valida_data_alta_cliente(
              data_cliente,
              data_acuerdo,
              this.state.representantes
            ).length > 0
          )
            message.message_modal(
              "Verificar datos",
              utility.valida_data_alta_cliente(
                data_cliente,
                data_acuerdo,
                this.state.representantes
              ),
              400
            );
          else
            this.methodPOST_API(utility.url_alta_cliente, "alta_cliente", {
              cliente: data_cliente,
              acuerdo: data_acuerdo,
              representantes: this.state.representantes,
            });
      });
  };
  handleAlta_contacto = () => {
    let data = {
      cliente: this.state.numero,
      contactos: this.state.contactos_empresa,
    };
    message
      .message_confimacion("¿Desea continuar con la operación alta?", "")
      .then((res) => {
        if (res)
          if (utility.valida_data_alta_contacto(data).length > 0)
            message.message_modal(
              "Verificar datos",
              utility.valida_data_alta_contacto(data),
              400
            );
          else
            this.methodPOST_API(utility.url_alta_contactos, "alta_contacto", {
              cliente: this.state.numero,
              contactos: this.state.contactos_empresa,
            });
      });
  };
  handleAlta_domicilio = () => {
    let data = {
      cliente: this.state.numero,
      domicilios: this.state.domicilios_cliente,
    };
    message
      .message_confimacion("¿Desea continuar con la operación alta?", "")
      .then((res) => {
        if (res)
          if (utility.valida_data_alta_domicilio(data).length > 0)
            message.message_modal(
              "Verificar datos",
              utility.valida_data_alta_domicilio(data),
              400
            );
          else
            this.methodPOST_API(utility.url_alta_domicilios, "alta_domicilio", {
              cliente: this.state.numero,
              domicilios: this.state.domicilios_cliente,
            });
      });
  };
  handleGuardar = () => {
    // Verificar si hay campos faltantes
    const camposFaltantes = [];
    if (this.state.auxiliarContado === "") camposFaltantes.push("Contado");
    if (this.state.auxiliarAnticipo === "") camposFaltantes.push("Anticipo");
    if (this.state.auxiliarPhxcdc === "") camposFaltantes.push("Phxcdc");

    // Si hay campos faltantes, mostrar un mensaje de advertencia
    if (camposFaltantes.length > 0) {
      message.completeMessage(
        `${camposFaltantes.join(", ")}`
      );
    } else {
      // Si todos los campos están completos, proceder con el guardado
      let datos = {
        catalogo: "cl",
        cliente: this.state.numero,
      };

      // Agregar los datos de los campos no vacíos
      if (this.state.auxiliarContado !== "") datos.contado = this.state.auxiliarContado;
      if (this.state.auxiliarAnticipo !== "") datos.anticipo = this.state.auxiliarAnticipo;
      if (this.state.auxiliarPhxcdc !== "") datos.phxcdc = this.state.auxiliarPhxcdc;
      if (this.state.auxiliarCredito !== "") datos.credito = this.state.auxiliarCredito;
      if (this.state.auxiliarSaldo !== "") datos.saldo_favor = this.state.auxiliarSaldo;

      // Realizar la petición POST
      axios
        .post(utility.url_crea_auxiliares, datos)
        .then((response) => {
          if (response.status >= 200 && response.status < 400) {
            if (this._isMounted) {
              message.message_success(
                "Se creó exitosamente",
                "Registros de control de auxiliares creados"
              );
            }
            this.handleCerrar();
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response)
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
        });
    }
};

  /* ------------------------ FUNCIONES QUE INTERACTUAN CON EL DOM ------------------------ */
  handleNombre = (e) => {
    let nombre = e.target.value;
    this.setState({ nombre });
  };
  handleRFC = (e) => {
    let rfc = e.target.value;
    this.setState({ rfc });
  };
  handleDomicilio = (e) => {
    let domicilio = e.target.value;
    this.setState({ domicilio });
  };
  handleCredito = (e) => {
    let auxiliarCredito = e.target.value;
    this.setState({ auxiliarCredito });
  };
  handleColonia = (e) => {
    let label = e.name_text;
    let colonia = e[label];
    let estado, ciudad;
    const coloniaDomicilio = this.state.colonia_domicilio;
    this.setState({ colonia: coloniaDomicilio });
    this.state.colonias.forEach((col) => {
      if (colonia === col.asentamiento) {
        estado = col.estado;
        ciudad = col.ciudad;
      }
    });
    this.setState({ colonia, estado, ciudad });
  };
  handleCodigo_postal = (e) => {
    let codigo_postal = e.target.value;
    if (codigo_postal.length === 5)
      this.methodPOST_API(utility.url_codigo_postal, "codigo_postal", {
        codigo: codigo_postal,
      });
    this.setState({ codigo_postal });
  };
  handlePais = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let pais_text = e[label];
    let pais_id = e[label_id];
    this.setState({ pais_text, pais_id });
  };
  handlePatente = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let patente_text = e[label];
    let patente_id = e[label_id];
    this.setState({ patente_text, patente_id });
  };
  handleAduana = (e) => {
    let aduana = e.target.value;
    this.setState({ aduana });
  };
  handleEncargo = (e) => {
    let encargo_conferido = e.target.checked ? "1" : "0";
    this.setState({ encargo_conferido });
  };
  handleApellidoP = (e) => {
    let apellido_paterno = e.target.value;
    this.setState({ apellido_paterno });
  };
  handleApellidoM = (e) => {
    let apellido_materno = e.target.value;
    this.setState({ apellido_materno });
  };
  handleCURP = (e) => {
    let curp = e.target.value;
    this.setState({ curp });
  };
  handleNumero_int = (e) => {
    let numero_interior = e.target.value;
    this.setState({ numero_interior });
  };
  handleRFC_social = (e) => {
    let rfc_sociedad = e.target.value;
    this.setState({ rfc_sociedad });
  };
  handleMetodo = (e) => {
    let metodo_pago = e.target.value;
    this.setState({ metodo_pago });
  };
  handleCuenta = (e) => {
    this.setState({ cta_banco_pago_text: e.target.value.slice(0, 2) });
  };
  handlePoder = (e) => {
    let poder_notarial = e.target.value;
    this.setState({ poder_notarial });
  };
  handleGrupo = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let grupo_text = e[label];
    let grupo_id = e[label_id];
    this.setState({ grupo_text, grupo_id });
  };
  handleRegimen = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let regimen_fiscal_text = e[label];
    let regimen_fiscal_id = e[label_id];
    this.setState({ regimen_fiscal_text, regimen_fiscal_id });
  };

  handleEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      // Si la tecla presionada fue 'Enter', llamamos a la función para manejar el evento
      if (e.target.id === 'tarifa_entrega_input') {
        this.handleTarifa_entregaBlur();
      }
      if (e.target.id === 'tarifa_importacion_input') {
        this.handleTarifa_importacionBlur();
      }
      if (e.target.id === 'tarifa_exportacion_input') {
        this.handleTarifa_exportacionBlur();
      }
      if (e.target.id === 'descuento_importacion_input') {
        this.handleDescuento_importacionBlur();
      }
      if (e.target.id === 'descuento_exportacion_input') {
        this.handleDescuento_exportacionBlur();
      }
    }
  };
  

  handleTarifa_entrega = (e) => {
    let tarifa_entrega = e.target.value;

     // Remover caracteres que no son números, puntos o comas
     tarifa_entrega = tarifa_entrega.replace(/[^\d.,]/g, '');
  
     // Reemplazar comas por puntos
     tarifa_entrega = tarifa_entrega.replace(/,/g, '.');
   
     // Si hay más de un punto, eliminar los puntos extras
     const dotCount = tarifa_entrega.split('.').length - 1;
     if (dotCount > 1) {
      tarifa_entrega = tarifa_entrega.replace(/\./g, (match, offset) => offset ? '' : match);
     }
   
     // Limitar a dos decimales
     const parts = tarifa_entrega.split('.');
     if (parts[1] && parts[1].length > 2) {
      tarifa_entrega = `${parts[0]}.${parts[1].slice(0, 2)}`;
     }

    this.setState({ tarifa_entrega });
  };

  handleTarifa_entregaBlur = () => {
    let tarifa_entrega = this.state.tarifa_entrega;
  
    // Si el campo está vacío, establecer el valor como '0.00'
    if (!tarifa_entrega) {
      tarifa_entrega = '0.00';
    } else {
      // Convertir el valor a un número
      let num = parseFloat(tarifa_entrega);
  
      // Verificar si el valor es un número válido
      if (!isNaN(num)) {
        // Formatear el número con dos decimales
        tarifa_entrega = num.toFixed(2);
      } else {
        // Si no es un número válido, establecer el valor como '0.00'
        tarifa_entrega = '0.00';
      }
    }
  
    this.setState({ tarifa_entrega });
  };

  handleTarifa_importacion = (e) => {
    let tarifa_importacion = e.target.value;

    // Remover caracteres que no son números, puntos o comas
    tarifa_importacion = tarifa_importacion.replace(/[^\d.,]/g, '');
  
    // Reemplazar comas por puntos
    tarifa_importacion = tarifa_importacion.replace(/,/g, '.');
  
    // Si hay más de un punto, eliminar los puntos extras
    const dotCount = tarifa_importacion.split('.').length - 1;
    if (dotCount > 1) {
      tarifa_importacion = tarifa_importacion.replace(/\./g, (match, offset) => offset ? '' : match);
    }
  
    // Limitar a dos decimales
    const parts = tarifa_importacion.split('.');
    if (parts[1] && parts[1].length > 2) {
      tarifa_importacion = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    this.setState({ tarifa_importacion });
  };

  handleTarifa_importacionBlur = () => {
    let tarifa_importacion = this.state.tarifa_importacion;
  
    // Si el campo está vacío, establecer el valor como '0.00'
    if (!tarifa_importacion) {
      tarifa_importacion = '0.00';
    } else {
      // Convertir el valor a un número
      let num = parseFloat(tarifa_importacion);
  
      // Verificar si el valor es un número válido
      if (!isNaN(num)) {
        // Formatear el número con dos decimales
        tarifa_importacion = num.toFixed(2);
      } else {
        // Si no es un número válido, establecer el valor como '0.00'
        tarifa_importacion = '0.00';
      }
    }
  
    this.setState({ tarifa_importacion });
  };

  handleTarifa_exportacion = (e) => {
    let tarifa_exportacion = e.target.value;

    // Remover caracteres que no son números, puntos o comas
    tarifa_exportacion = tarifa_exportacion.replace(/[^\d.,]/g, '');
  
    // Reemplazar comas por puntos
    tarifa_exportacion = tarifa_exportacion.replace(/,/g, '.');
  
    // Si hay más de un punto, eliminar los puntos extras
    const dotCount = tarifa_exportacion.split('.').length - 1;
    if (dotCount > 1) {
      tarifa_exportacion = tarifa_exportacion.replace(/\./g, (match, offset) => offset ? '' : match);
    }
  
    // Limitar a dos decimales
    const parts = tarifa_exportacion.split('.');
    if (parts[1] && parts[1].length > 2) {
      tarifa_exportacion = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    this.setState({ tarifa_exportacion });
  };

  handleTarifa_exportacionBlur = () => {
    let tarifa_exportacion = this.state.tarifa_exportacion;
  
    // Si el campo está vacío, establecer el valor como '0.00'
    if (!tarifa_exportacion) {
      tarifa_exportacion = '0.00';
    } else {
      // Convertir el valor a un número
      let num = parseFloat(tarifa_exportacion);
  
      // Verificar si el valor es un número válido
      if (!isNaN(num)) {
        // Formatear el número con dos decimales
        tarifa_exportacion = num.toFixed(2);
      } else {
        // Si no es un número válido, establecer el valor como '0.00'
        tarifa_exportacion = '0.00';
      }
    }
  
    this.setState({ tarifa_exportacion });
  };

  handleDescuento_importacion = (e) => {
    let descuento_importacion = e.target.value;

     // Remover caracteres que no son números, puntos o comas
     descuento_importacion = descuento_importacion.replace(/[^\d.,]/g, '');
  
     // Reemplazar comas por puntos
     descuento_importacion = descuento_importacion.replace(/,/g, '.');
   
     // Si hay más de un punto, eliminar los puntos extras
     const dotCount = descuento_importacion.split('.').length - 1;
     if (dotCount > 1) {
      descuento_importacion = descuento_importacion.replace(/\./g, (match, offset) => offset ? '' : match);
     }
   
     // Limitar a dos decimales
     const parts = descuento_importacion.split('.');
     if (parts[1] && parts[1].length > 2) {
      descuento_importacion = `${parts[0]}.${parts[1].slice(0, 2)}`;
     }

    this.setState({ descuento_importacion });
  };

  handleDescuento_importacionBlur = () => {
    let descuento_importacion = this.state.descuento_importacion;
  
    // Si el campo está vacío, establecer el valor como '0.00'
    if (!descuento_importacion) {
      descuento_importacion = '0.00';
    } else {
      // Convertir el valor a un número
      let num = parseFloat(descuento_importacion);
  
      // Verificar si el valor es un número válido
      if (!isNaN(num)) {
        // Formatear el número con dos decimales
        descuento_importacion = num.toFixed(2);
      } else {
        // Si no es un número válido, establecer el valor como '0.00'
        descuento_importacion = '0.00';
      }
    }
  
    this.setState({ descuento_importacion });
  };

  handleDescuento_exportacion = (e) => {
    let descuento_exportacion = e.target.value;

    // Remover caracteres que no son números, puntos o comas
    descuento_exportacion = descuento_exportacion.replace(/[^\d.,]/g, '');
  
    // Reemplazar comas por puntos
    descuento_exportacion = descuento_exportacion.replace(/,/g, '.');
  
    // Si hay más de un punto, eliminar los puntos extras
    const dotCount = descuento_exportacion.split('.').length - 1;
    if (dotCount > 1) {
      descuento_exportacion = descuento_exportacion.replace(/\./g, (match, offset) => offset ? '' : match);
    }
  
    // Limitar a dos decimales
    const parts = descuento_exportacion.split('.');
    if (parts[1] && parts[1].length > 2) {
      descuento_exportacion = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    this.setState({ descuento_exportacion });
  };

  handleDescuento_exportacionBlur = () => {
    let descuento_exportacion = this.state.descuento_exportacion;
  
    // Si el campo está vacío, establecer el valor como '0.00'
    if (!descuento_exportacion) {
      descuento_exportacion = '0.00';
    } else {
      // Convertir el valor a un número
      let num = parseFloat(descuento_exportacion);
  
      // Verificar si el valor es un número válido
      if (!isNaN(num)) {
        // Formatear el número con dos decimales
        descuento_exportacion = num.toFixed(2);
      } else {
        // Si no es un número válido, establecer el valor como '0.00'
        descuento_exportacion = '0.00';
      }
    }
  
    this.setState({ descuento_exportacion });
  };

  handleNombre_representante = (e) => {
    let nombre_representante = e.target.value;
    this.setState({ nombre_representante });
  };
  handleRFC_representante = (e) => {
    let rfc_representante = e.target.value;
    this.setState({ rfc_representante });
  };
  handleCURP_representante = (e) => {
    let curp_representante = e.target.value;
    this.setState({ curp_representante });
  };
  handleAgrega_representante = () => {
    if (
      this.state.nombre_representante === "" ||
      this.state.rfc_representante === "" ||
      this.state.curp_representante === ""
    ) {
      message.warningMessage("Por favor, ingrese los campos faltantes");
    } else {
      let representantes = this.state.representantes;
      let data = {
        nombre: this.state.nombre_representante,
        rfc: this.state.rfc_representante,
        curp: this.state.curp_representante,
      };
      representantes.push(data);
      this.setState({
        representantes,
        nombre_representante: "",
        rfc_representante: "",
        curp_representante: "",
      });
    }
  };

  hanldeNombre_contacto = (e) => {
    let nombre_contacto = e.target.value;
    this.setState({ nombre_contacto });
  };
  handleTelefono_contacto = (e) => {
    let telefono_contacto = e.target.value;
    this.setState({ telefono_contacto });
  };
  handleCorreo_contacto = (e) => {
    let correo_contacto = e.target.value;
    this.setState({ correo_contacto });
  };

  handleAgrega_contactos = () => {
    if (
      this.state.numero === "" ||
      this.state.nombre_contacto === "" ||
      this.state.telefono_contacto === "" ||
      this.state.correo_contacto === ""
    ) {
      message.warningMessage("Por favor, ingrese los campos faltantes");
    } else {
      let contactos_empresa = this.state.contactos_empresa;
      let data = {
        nombre: this.state.nombre_contacto,
        telefono: this.state.telefono_contacto,
        correo: this.state.correo_contacto,
      };
      contactos_empresa.push(data);
      this.setState({ contactos_empresa: contactos_empresa });

      this.setState({
        contactos_empresa,
        nombre_contacto: "",
        telefono_contacto: "",
        correo_contacto: "",
      });
    }
  };

  handleElimina_contactos = (nombre) => {
    const toFind = nombre;
    const contactos_empresa = this.state.contactos_empresa.filter(
      (contactos_empresa) => contactos_empresa.nombre !== toFind
    );
    this.setState({
      contactos_empresa: contactos_empresa,
    });
  };

  hanldeNombre_domicilio = (e) => {
    let quien_recibe_domicilio = e.target.value;
    this.setState({ quien_recibe_domicilio });
  };
  handleTelefono_domicilio = (e) => {
    let telefono_domicilio = e.target.value;
    this.setState({ telefono_domicilio });
  };
  handlePais_contacto = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let pais_domicilio_text = e[label];
    let pais_domicilio_id = e[label_id];
    this.setState({ pais_domicilio_text, pais_domicilio_id });
  };
  handleDomicilio_domicilio = (e) => {
    let domicilio_domicilio = e.target.value;
    this.setState({ domicilio_domicilio });
  };
  handleNumero_int_domicilio = (e) => {
    let numero_interior_domicilio = e.target.value;
    this.setState({ numero_interior_domicilio });
  };
  handleCodigo_postal_domicilio = (e) => {
    let codigo_postal_domicilio = e.target.value;
    if (codigo_postal_domicilio.length === 5)
      this.methodPOST_API(utility.url_codigo_postal, "codigo_postal", {
        codigo: codigo_postal_domicilio,
      });
    this.setState({ codigo_postal_domicilio });
  };
  handleColonia_domicilio = (e) => {
    let label = e.name_text;
    let colonia_domicilio = e[label];
    let estado_domicilio, ciudad_domicilio;
    this.state.colonias.forEach((col) => {
      if (colonia_domicilio === col.asentamiento) {
        estado_domicilio = col.estado;
        ciudad_domicilio = col.ciudad;
      }
    });
    this.setState({ colonia_domicilio, estado_domicilio, ciudad_domicilio });
  };
  handleAgrega_domicilio = () => {
    if (
      this.state.quien_recibe_domicilio === "" ||
      this.state.pais_domicilio_text === "" ||
      this.state.domicilio_domicilio === "" ||
      this.state.codigo_postal_domicilio === "" ||
      this.state.colonia === ""
    ) {
      message.warningMessage("Por favor, ingrese los campos faltantes");
    } else {
      let domicilios_cliente = this.state.domicilios_cliente;
      let data = {
        nombre: this.state.quien_recibe_domicilio,
        pais: this.state.pais_domicilio_text,
        direccion: this.state.domicilio_domicilio,
        codigo_postal: this.state.codigo_postal_domicilio,
        colonia: this.state.colonia,
      };
      domicilios_cliente.push(data);
      this.setState({
        domicilios_cliente,
        quien_recibe_domicilio: "",
        domicilio_domicilio: "",
        numero_interior_domicilio: "",
        colonia_domicilio: "",
        codigo_postal_domicilio: "",
        pais_domicilio: "",
        telefono_domicilio: "",
        colonias: [],
        estado_domicilio: "",
        ciudad_domicilio: "",
        colonia: "",
      });
    }
  };
  handleElimina_domicilio = (nombre) => {
    const toFind = nombre;
    const domicilios_cliente = this.state.domicilios_cliente.filter(
      (domicilios_cliente) => domicilios_cliente.nombre !== toFind
    );
    this.setState({
      domicilios_cliente: domicilios_cliente,
    });
  };
  handleElimina_representantes = (nombre) => {
    const toFind = nombre;
    const representantes = this.state.representantes.filter(
      (representantes) => representantes.nombre !== toFind
    );
    this.setState({
      representantes: representantes,
    });
  };

  /* ------------------------ FUNCIONES QUE INTERACTUAN CON LA LOGICA DE LA APLICACIÓN ------------------------ */
  /**
   * Función que permite hacer una petición de forma POST.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} data Objeto a mandar atraves del metodo POST.
   */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "alta_cliente":
              this.setState(
                {
                  enable_contacto: false,
                  enable_domicilio: false,
                  enable_cuenta_aux: false,
                  numero: response.data.results.cliente.numero,
                },
                () => {
                  this.sendNotificacionHandler("crear");
                }
              );
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              break;
            case "alta_contacto":
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.setState({ contactos_empresa: [] });
              break;
            case "alta_domicilio":
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.setState({ domicilios_cliente: [] });
              break;
            case "codigo_postal":
              this.setState({
                colonias: response.data.results,
              });
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  handleAuxiliarC = (e) => {
    let auxiliarContado = e.target.value;
    this.setState({ auxiliarContado });
  };
  handleAuxiliarA = (e) => {
    let auxiliarAnticipo = e.target.value;
    this.setState({ auxiliarAnticipo });
  };
  handleAuxiliarP = (e) => {
    let auxiliarPhxcdc = e.target.value;
    this.setState({ auxiliarPhxcdc });
  };
  handleAuxiliarS = (e) => {
    let auxiliarSaldo = e.target.value;
    this.setState({ auxiliarSaldo });
  };
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_nuevo}
        toggle={this.props.toogleNuevo}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.props.toogleNuevo}>Alta Cliente</ModalHeader>
        <ModalBody>
          <div className="row mb-2 d-flex justify-content-center">
            <div className="col-lg-10 col-12 d-flex justify-content-around">
              <Button color="info" onClick={this.toogleCliente}>
                Cliente
              </Button>
              <Button
                color="info"
                onClick={this.toogleContactos}
                disabled={this.state.enable_contacto}
              >
                Contactos
              </Button>
              <Button
                color="info"
                onClick={this.toogleDomicilios}
                disabled={this.state.enable_domicilio}
              >
                Domicilios
              </Button>
              <Button
                color="info"
                onClick={this.toogleCuentaAuxiliar}
                disabled={this.state.enable_cuenta_aux} /* Inhabilitar o habilitar el botón Cuenta Auxiliar */
              >
                Cuenta Auxiliar
              </Button>
            </div>
          </div>
          <Collapse isOpen={this.state.toogle_cliente}>
            <Card outline color="info">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MySelect
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="cuenta"
                      nameLabel="Cuenta"
                      first_option="Seleccione una cuenta"
                      value={this.state.cuenta}
                      onChange={this.handleCuentaChange}
                    >
                      <option value="Persona">Persona Física</option>
                      <option value="Empresa">Empresa</option>
                    </MySelect>
                    <MyInput
                      type="text"
                      bootstrap="col-lg-8 col-12"
                      id="nombre_alta_cliente"
                      nameLabel="Nombre"
                      value={this.state.nombre}
                      onChange={this.handleNombre}
                    />
                    {this.state.cuenta === "Persona" && (
                      <>
                        <MyInput
                          type="text"
                          bootstrap="col-lg-6 col-12"
                          id="apellidoP_alta_cliente"
                          nameLabel="Apellido Paterno"
                          value={this.state.apellido_paterno}
                          onChange={this.handleApellidoP}
                        />
                        <MyInput
                          type="text"
                          bootstrap="col-lg-6 col-12"
                          id="apellidoM_alta_cliente"
                          nameLabel="Apellido Materno"
                          value={this.state.apellido_materno}
                          onChange={this.handleApellidoM}
                        />
                      </>
                    )}
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="rfc_alta_cliente"
                      nameLabel="RFC"
                      value={this.state.rfc}
                      onChange={this.handleRFC}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="curp_alta_cliente"
                      nameLabel="CURP"
                      value={this.state.curp}
                      onChange={this.handleCURP}
                    />
                    <CustomSelect
                      bootstrap="col-lg-4 col-12"
                      name_text="nombre"
                      name_index="clave"
                      id="pais_alta_cliente"
                      nameLabel="País"
                      value={this.state.pais_text}
                      onChange={this.handlePais}
                      options={this.props.paises}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="direccion_alta_cliente"
                      nameLabel="Domicilio"
                      value={this.state.domicilio}
                      onChange={this.handleDomicilio}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="numero_interior_alta_cliente"
                      nameLabel="Num. Interior"
                      value={this.state.numero_interior}
                      onChange={this.handleNumero_int}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="cp_alta_cliente"
                      nameLabel="Código Postal"
                      value={this.state.codigo_postal}
                      onChange={this.handleCodigo_postal}
                    />
                    {this.state.pais_id !== "MEX" ? (
                      <MyInput
                        type="text"
                        bootstrap="col-lg-3 col-12"
                        id="colonia_alta_cliente"
                        nameLabel="Colonia"
                        onChange={this.handleColonia}
                      />
                    ) : (
                      <CustomSelect
                        bootstrap="col-lg-3 col-12"
                        name_text="asentamiento"
                        name_index="asentamiento"
                        id="colonia_alta_cliente"
                        nameLabel="Colonia"
                        onChange={this.handleColonia}
                        options={this.state.colonias}
                        value={this.state.colonia}
                      />
                    )}
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="estado_alta_cliente"
                      nameLabel="Estado/Provincia/Región"
                      value={this.state.estado}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="ciudad_alta_cliente"
                      nameLabel="Ciudad"
                      value={this.state.ciudad}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="aduana_alta_cliente"
                      nameLabel="Aduana"
                      value={this.state.aduana}
                      onChange={this.handleAduana}
                    />
                    <CustomSelect
                      bootstrap="col-lg-3 col-12"
                      name_text="patente"
                      name_index="patente"
                      id="patente_alta_cliente"
                      nameLabel="Patente"
                      onChange={this.handlePatente}
                      value={this.state.patente_text}
                      options={utility.patente_por_audana(
                        this.state.aduana,
                        this.props.patentes
                      )}
                    />
                    {/* <MySelect
                      bootstrap="col-lg-3 col-12"
                      name_text="patente"
                      name_index="patente"
                      id="patente_alta_cliente"
                      nameLabel="Patente"
                      first_option="Seleccione una patente"
                      value={this.state.patente_text}
                      onChange={this.handlePatente}
                    >
                      {utility.patente_por_audana.map((this.state.aduana,
                        this.props.patentes) => (
                        <option key={che.numero}>
                          {che.numero} - {che.nombre}
                        </option>
                      ))}
                    </MySelect> */}
                    <CustomSelect
                      bootstrap="col-lg-3 col-12"
                      name_text="nombre"
                      name_index="id"
                      id="grupo_alta_cliente"
                      nameLabel="Grupo"
                      onChange={this.handleGrupo}
                      options={this.props.grupos}
                      value={this.state.grupo_text}
                    />
                    <CustomSelect
                      bootstrap="col-lg-3 col-12"
                      name_text="nombre"
                      name_index="clave"
                      id="regimen_fiscal_alta_cliente"
                      nameLabel="Regimen Friscal"
                      onChange={this.handleRegimen}
                      options={this.props.regimen_fiscal}
                      value={this.state.regimen_fiscal_text}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="rfc_sociedad_alta_cliente"
                      nameLabel="RFC Sociedad"
                      value={this.state.rfc_sociedad}
                      onChange={this.handleRFC_social}
                    />
                    <MySelect
                      bootstrap="col-lg-3 col-12"
                      id="banco_alta_cliente"
                      name_text="banco"
                      name_index="numero"
                      nameLabel="Cta. Banco"
                      first_option="Seleccione una cuenta"
                      onChange={this.handleCuenta}
                    >
                      {this.props.bancos.map((ban) => (
                        <option key={ban.numero}>
                          {ban.numero} - {ban.nombre}
                        </option>
                      ))}
                    </MySelect>
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="notarial_alta_cliente"
                      nameLabel="Poder notarial"
                      value={this.state.poder_notarial}
                      onChange={this.handlePoder}
                    />
                    <div className="col-lg-3 col-12 d-flex justify-content-center align-items-center">
                      <p className="mb-1">Encargo conferido</p>&nbsp;&nbsp;
                      <label className="switch" id="encargo_alta_cliente">
                        <input
                          type="checkbox"
                          id="encargo_alta_cliente"
                          onChange={this.handleEncargo}
                        />
                        <div className="slider round"></div>
                      </label>
                    </div>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="tarifa_entrega_input"
                      nameLabel="Tarifa entrega"
                      value={this.state.tarifa_entrega}
                      onChange={this.handleTarifa_entrega}
                      onBlur={this.handleTarifa_entregaBlur}
                      min="0.00"
                      step="1.00"
                      max="9999999999999.99"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="tarifa_importacion_input"
                      nameLabel="Tarifa importación"
                      value={this.state.tarifa_importacion}
                      onChange={this.handleTarifa_importacion}
                      onBlur={this.handleTarifa_importacionBlur}
                      min="0.00"
                      step="1.00"
                      max="9999999999999.99"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="tarifa_exportacion_input"
                      nameLabel="Tarifa exportación"
                      value={this.state.tarifa_exportacion}
                      onChange={this.handleTarifa_exportacion}
                      onBlur={this.handleTarifa_exportacionBlur}
                      min="0.00"
                      step="1.00"
                      max="9999999999999.99"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="descuento_importacion_input"
                      nameLabel="Descuento importación"
                      value={this.state.descuento_importacion}
                      onChange={this.handleDescuento_importacion}
                      onBlur={this.handleDescuento_importacionBlur}
                      min="0"
                      max="100"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="descuento_exportacion_input"
                      nameLabel="Descuento exportación"
                      value={this.state.descuento_exportacion}
                      onChange={this.handleDescuento_exportacion}
                      onBlur={this.handleDescuento_exportacionBlur}
                      min="0"
                      max="100"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="nombre_representante_alta_cliente"
                      nameLabel="Nombre representante"
                      value={this.state.nombre_representante}
                      onChange={this.handleNombre_representante}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="rfc_representante_alta_cliente"
                      nameLabel="RFC Representante"
                      value={this.state.rfc_representante}
                      onChange={this.handleRFC_representante}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="curp_representante_alta_cliente"
                      nameLabel="CURP Representante"
                      value={this.state.curp_representante}
                      onChange={this.handleCURP_representante}
                    />
                    <Button
                      color="primary"
                      className="col-lg-2 col-12 mb-2"
                      onClick={this.handleAgrega_representante}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                </Form>
                <Table size="sm" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>Nombre</td>
                      <td>RFC</td>
                      <td>CURP</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.representantes.map((rep) => (
                      <tr key={rep.nombre}>
                        <td class="text-center">{rep.nombre}</td>
                        <td class="text-center">{rep.rfc}</td>
                        <td class="text-center">{rep.curp}</td>
                        <td class="text-center">
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() =>
                              this.handleElimina_representantes(rep.nombre)
                            }
                          >
                            Eliminar
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="container-fluid">
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      color="success"
                      className="mr-2"
                      onClick={this.handleAlta_cliente}
                      disabled={
                        this.state.representantes.length === 0 ? true : false
                      }
                    >
                      Aceptar
                    </Button>
                    <Button color="primary" onClick={this.handleCerrar}>
                      Cerrar
                    </Button>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_contactos}>
            <Card outline color="info">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-12"
                      id="numero_alta_contacto"
                      nameLabel="Número de Cliente"
                      value={this.state.numero}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="nombre_alta_contacto"
                      nameLabel="Nombre"
                      value={this.state.nombre_contacto}
                      onChange={this.hanldeNombre_contacto}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="telefono_alta_contacto"
                      nameLabel="Telefono"
                      value={this.state.telefono_contacto}
                      onChange={this.handleTelefono_contacto}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="correo_alta_contacto"
                      nameLabel="Correo"
                      value={this.state.correo_contacto}
                      onChange={this.handleCorreo_contacto}
                    />
                    <Button
                      color="primary"
                      className="col-lg-2 col-12 mb-2"
                      onClick={this.handleAgrega_contactos}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                </Form>
                <Table size="sm" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>Nombre</td>
                      <td>RFC</td>
                      <td>CURP</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.contactos_empresa.map((rep) => (
                      <tr key={rep.nombre}>
                        <td class="text-center">{rep.nombre}</td>
                        <td class="text-center">{rep.telefono}</td>
                        <td class="text-center">{rep.correo}</td>
                        <td class="text-center">
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() =>
                              this.handleElimina_contactos(rep.nombre)
                            }
                          >
                            Eliminar
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="container-fluid">
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      color="success"
                      className="mr-2"
                      onClick={this.handleAlta_contacto}
                      disabled={
                        this.state.contactos_empresa.length === 0 ? true : false
                      }
                    >
                      Aceptar
                    </Button>
                    <Button color="primary" onClick={this.handleCerrar}>
                      Cerrar
                    </Button>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_domicilios}>
            <Card outline color="info">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-12"
                      id="numero_alta_domicilio"
                      nameLabel="Numero del Cliente"
                      value={this.state.numero}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="quien_recibe_alta_domicilio"
                      nameLabel="Quien recibe"
                      value={this.state.quien_recibe_domicilio}
                      onChange={this.hanldeNombre_domicilio}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-4 col-12"
                      id="telefono_alta_domicilio"
                      nameLabel="Telefono"
                      value={this.state.telefono_domicilio}
                      onChange={this.handleTelefono_domicilio}
                    />
                    <CustomSelect
                      bootstrap="col-lg-4 col-12"
                      name_text="nombre"
                      name_index="clave"
                      id="pais_alta_domicilio"
                      nameLabel="País"
                      value={this.state.pais_domicilio_text}
                      onChange={this.handlePais_contacto}
                      options={this.props.paises}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="direccion_alta_domicilio"
                      nameLabel="Domicilio"
                      value={this.state.domicilio_domicilio}
                      onChange={this.handleDomicilio_domicilio}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="numero_interior_alta_domicilio"
                      nameLabel="Num. Interior"
                      value={this.state.numero_interior_domicilio}
                      onChange={this.handleNumero_int_domicilio}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-3 col-12"
                      id="cp_alta_domicilio"
                      nameLabel="Código Postal"
                      value={this.state.codigo_postal_domicilio}
                      onChange={this.handleCodigo_postal_domicilio}
                    />
                    {this.state.pais_domicilio_id !== "MEX" ? (
                      <MyInput
                        type="text"
                        bootstrap="col-lg-3 col-12"
                        id="colonia_alta_domicilio"
                        nameLabel="Colonia"
                        onChange={this.handleColonia_domicilio}
                      />
                    ) : (
                      <CustomSelect
                        bootstrap="col-lg-3 col-12"
                        name_text="asentamiento"
                        name_index="asentamiento"
                        id="colonia_alta_domicilio"
                        nameLabel="Colonia"
                        onChange={this.handleColonia_domicilio}
                        options={this.state.colonias}
                        value={this.state.colonia_domicilio}
                      />
                    )}
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="estado_alta_domicilio"
                      nameLabel="Estado/Provincia/Región"
                      value={this.state.estado_domicilio}
                      disabled
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="ciudad_alta_domicilio"
                      nameLabel="Ciudad"
                      value={this.state.ciudad_domicilio}
                      disabled
                    />
                    <Button
                      color="primary"
                      className="col-12 mb-2"
                      onClick={this.handleAgrega_domicilio}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                </Form>
                <Table size="sm" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>Quien recibe</td>
                      <td>País</td>
                      <td>Domicilio</td>
                      <td>Código Postal</td>
                      <td>Colonia</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.domicilios_cliente.map((rep) => (
                      <tr key={rep.nombre}>
                        <td class="text-center">{rep.nombre}</td>
                        <td class="text-center">{rep.pais}</td>
                        <td class="text-center">{rep.direccion}</td>
                        <td class="text-center">{rep.codigo_postal}</td>
                        <td class="text-center">{rep.colonia}</td>
                        <td class="text-center">
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() =>
                              this.handleElimina_domicilio(rep.nombre)
                            }
                          >
                            Eliminar
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="container-fluid">
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      color="success"
                      className="mr-2"
                      onClick={this.handleAlta_domicilio}
                      disabled={
                        this.state.domicilios_cliente.length === 0
                          ? true
                          : false
                      }
                    >
                      Aceptar
                    </Button>
                    <Button color="primary" onClick={this.handleCerrar}>
                      Cerrar
                    </Button>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_cuenta_aux}>
            <Card outline color="info">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-8 col-12"
                      id="cuenta_auxiliar"
                      nameLabel="Cuenta Auxiliar Contado"
                      value={this.state.auxiliarContado}
                      onChange={this.handleAuxiliarC}
                    />
                    <Button
                      color="primary"
                      className="col-lg-4 col-12 mb-2"
                      onClick={this.toogleAuxiliarContado}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-8 col-12"
                      id="cuenta_auxiliar"
                      nameLabel="Cuenta Auxiliar Anticipo"
                      value={this.state.auxiliarAnticipo}
                      onChange={this.handleAuxiliarA}
                    />
                    <Button
                      color="primary"
                      className="col-lg-4 col-12 mb-2"
                      onClick={this.toogleAuxiliarAnticipo}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-8 col-12"
                      id="cuenta_auxiliar"
                      nameLabel="Cuenta Auxiliar Pagos A Terceros"
                      value={this.state.auxiliarPhxcdc}
                      onChange={this.handleAuxiliarP}
                    />
                    <Button
                      color="primary"
                      className="col-lg-4 col-12 mb-2"
                      onClick={this.toogleAuxiliarPhxcdc}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-8 col-12"
                      id="cuenta_auxiliar"
                      nameLabel="Cuenta Auxiliar Crédito"
                      value={this.state.auxiliarCredito}
                      onChange={this.handleCredito}
                    />
                    <Button
                      color="primary"
                      className="col-lg-4 col-12 mb-2"
                      onClick={this.toogleAuxiliarCredito}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-8 col-12"
                      id="cuenta_auxiliar"
                      nameLabel="Cuenta Auxiliar Saldo A Favor"
                      value={this.state.auxiliarSaldo}
                      onChange={this.handleAuxiliarS}
                    />
                    <Button
                      color="primary"
                      className="col-lg-4 col-12 mb-2"
                      onClick={this.toogleAuxiliarSaldo}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                </Form>
                <div className="container-fluid">
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      color="success"
                      id="btn-guardar"
                      className="mr-2"
                      onClick={this.handleGuardar}
                    >
                      Guardar
                    </Button>
                    <Button color="primary" onClick={this.handleCerrar}>
                      Cerrar
                    </Button>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Collapse>
        </ModalBody>
        <ModalAuxiliarContado
          toogle_catalogo={this.state.toogle_auxiliar_contado}
          toogleCatalogo={this.toogleAuxiliarContado}
          parentCallback={this.handleCallbackContado}
          codigos_sat={this.props.codigos_sat}
        />
        <ModalAuxiliarAnticipo
          toogle_catalogo={this.state.toogle_auxiliar_anticipo}
          toogleCatalogo={this.toogleAuxiliarAnticipo}
          parentCallback={this.handleCallbackAnticipo}
          codigos_sat={this.props.codigos_sat}
        />
        <ModalAuxiliarPhxcdc
          toogle_catalogo={this.state.toogle_auxiliar_phxcdc}
          toogleCatalogo={this.toogleAuxiliarPhxcdc}
          parentCallback={this.handleCallbackPhxcdc}
          codigos_sat={this.props.codigos_sat}
        />
        <ModalAuxiliarCredito
          toogle_catalogo={this.state.toogle_auxiliar_credito}
          toogleCatalogo={this.toogleAuxiliarCredito}
          parentCallback={this.handleCallbackCredito}
          codigos_sat={this.props.codigos_sat}
        />
        <ModalAuxiliarSaldo
          toogle_catalogo={this.state.toogle_auxiliar_saldo}
          toogleCatalogo={this.toogleAuxiliarSaldo}
          parentCallback={this.handleCallbackSaldo}
          codigos_sat={this.props.codigos_sat}
        />
      </Modal>
    );
  }
}
export default ModalAlta;
