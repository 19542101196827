import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from './utility';

export const initialState = {
    csrfToken: null,
    userid: null,
    error: null,
    loading: false,
}

const authStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    });
}

const authEnd = (state, action) => {
    return updateObject(state, {
        csrfToken: null,
        userid: null,
    });
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        csrfToken: action.csrfToken,
        userid: action.userid,
        error: null,
        loading: false
    });
}

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_END: return authEnd(state, action);
        default:
            return state;
    }
}

export default reducer;