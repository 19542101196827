/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente Principal para el Modal de las distintas opciones de operación para solicitud de anticipos. 
*/
import React, { Component } from "react";
import { ButtonGroup, Button } from "reactstrap";
import ModalAlta from "./ModalAlta";
import * as permisos from "../../../utils/permisos/permisos";
import * as utility from "../utils/utility";
import ModalReporte from "./ModalReporte";
import ModalConsulta from "./ModalConsulta";
import ModalRectificar from "./ModalRectificar";

class ModalAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toogle_consulta: false,
      toogle_alta: false,
      toogle_reporte: false,
      toogle_rectificar: false,
    };
    this.toogleConsulta = this.toogleConsulta.bind(this);
    this.toogleAlta = this.toogleAlta.bind(this);
  }
  toogleConsulta = () => {
    this.setState((prevState) => ({
      toogle_consulta: !prevState.toogle_consulta,
    }));
  };
  toogleAlta = () => {
    this.setState((prevState) => ({ toogle_alta: !prevState.toogle_alta }));
  };
  toogleReporte = () => {
    this.setState((prevState) => ({
      toogle_reporte: !prevState.toogle_reporte,
    }));
  };
  toogleRectificar= () => {
    this.setState((prevState) => ({
      toogle_rectificar: !prevState.toogle_rectificar,
    }));
  };
  render() {
    return (
      <div className="col-lg-3 col-sm-12 d-flex justify-content-center flex-column mb-2 my-md-2">
        <ButtonGroup className="mb-1">
          <Button
            color="primary"
            onClick={this.toogleAlta}
            /* disabled={utility.obtener_permiso_especifico(
              "crear",
              this.props.permisos
            )} */
          >
            Nuevo
          </Button>
          <Button
            color="primary"
            onClick={this.toogleConsulta}
            /* disabled={utility.obtener_permiso_especifico(
              "visualizar",
              this.props.permisos
            )} */
          >
            Consulta
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button color="success" onClick={this.toogleReporte}>
            Reportes
          </Button>
          <Button color="success" onClick={this.toogleRectificar}>
            Rectificar
          </Button>
        </ButtonGroup>
        <ModalAlta
          toogle_alta={this.state.toogle_alta}
          toogleAlta={this.toogleAlta}
          update={this.props.update}
        />
        <ModalConsulta
          toogle_consulta={this.state.toogle_consulta}
          toogleConsulta={this.toogleConsulta}
          clientes={this.props.clientes}
          bancos={this.props.bancos}
          update={this.props.update}
          on_cliente={this.props.on_cliente}
          permisos={this.props.permisos}
        />
        <ModalRectificar
          toogle_rectificar={this.state.toogle_rectificar}
          toogleRectificar={this.toogleRectificar}
          clientes={this.props.clientes}
          bancos={this.props.bancos}
          update={this.props.update}
          on_cliente={this.props.on_cliente}
          permisos={this.props.permisos}
        />
        <ModalReporte
          toogle_reporte={this.state.toogle_reporte}
          toogleReporte={this.toogleReporte}
          clientes={this.props.clientes}
          bancos={this.props.bancos}
          update={this.props.update}
          on_cliente={this.props.on_cliente}
          permisos={this.props.permisos}
        />
      </div>
    );
  }
}
export default ModalAction;
