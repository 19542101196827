export const url_paginacion = '/DjangoWeb/EmpleadosApp/consulta/departamentos/paginacion/principal';
export const url_crea_departamento = '/DjangoWeb/EmpleadosApp/crea_modifica/departamento';
export const url_consulta_departamento = '/DjangoWeb/EmpleadosApp/consulta/departamentos/';
export const url_modifica_departamento = '/DjangoWeb/EmpleadosApp/crea_modifica/departamento';

/**
 * Función que nos permite filtar la tabla por el input general que serra de tipo text.
 */
export const general_search = (e) => {
    e.preventDefault();
    let tr = document.getElementById('tabla_departamentos').getElementsByTagName('tr');
    let data_input = document.getElementById('busqueda_general_departamentos').value.toUpperCase();
    for (let i = 0; i < tr.length; i++) {
        let mostrar = false;
        let data = tr[i].getElementsByTagName('p');
        for (let j = 0; j < data.length; j++) {
            if (data_input === "") {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
            else if (data[j].textContent.toUpperCase().includes(data_input)) {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "rgba(0,123,255,1)";
                data[j].style.color = "#fff";
            }
            else {
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que nos permite filtrar latabla solamnete por la fecha.
 */
export const date_search = (e) => {
    e.preventDefault();
    var tabla_anticipo, input_general, tr, dato, input_dato, mostrar;
    tabla_anticipo = document.getElementById("tabla_departamentos");
    input_general = document.getElementById("busqueda_date_departamentos");
    input_dato = input_general.value;
    tr = tabla_anticipo.getElementsByTagName("tr");
    for (var i = 0; i < tr.length; i++) {
        mostrar = false;
        dato = tr[i].getElementsByTagName("p");
        for (var j = 0; j < dato.length; j++) {
            if (dato[j].textContent === input_dato) {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "rgba(0,123,255,1)";
                dato[j].style.color = "#fff";
            }
            else if (input_dato === "") {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
            else {
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que permite validar silos datos de la alta son correctos
 * @param {Object} data Info para dar de alta 
 */
export const valida_data = (data) => {
    let message = "";
    if (data.nombre.length === 0)
        message += "Nombre,";
    if (data.area.length === 0)
        message += "Areas,";
    if (data.descripcion.length === 0)
        message += "Descripción,";
    return message;
}
/**
 * Función que permite agregar una clase a un elemento dado du ID
 * @param {String} id ID del objeto del DOM 
 */
export const add_class_disabled = (id) => {
    document.getElementById(id).classList.add('input-animatedd');
}
/**
 * Función que permite validar si algun campo de la data esta vacio o no.
 * @param {Object} data Datos para la consulta filtrada de la tabla.
 */
export const valida_specific_data = (data) => {
    if (data.nombre.length === 0)
        delete data.nombre;
    if (data.id_departamento.length === 0)
        delete data.id_departamento;
    if (data.area.length === 0)
        delete data.area;
    if (data.descripcion.length === 0)
        delete data.descripcion;
}