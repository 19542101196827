/* 
Componente que genera un input de tipo select orientado al diseño del sistema
*/
import React from 'react';
import '../../assets/style.css';
import { CustomInput } from 'reactstrap';

const MySelect = (props) => {
    return (
        <div className={`grupo p-0 ${props.bootstrap}`}>
            <CustomInput type="select" className="select-animated col-12" id={props.id} disabled={props.disabled} onChange={props.onChange}>
                <option value="null">{props.first_option}</option>
                {props.children}
            </CustomInput>
            <span className="barra"></span>
            <br /><label className="label-animated" htmlFor={props.id}>{props.nameLabel}</label>
        </div>
    );
}
export default MySelect;

/* ------------------------------------------------------------------------------------------------
ESTRUCTURA PARA LA LOGICA DEL INPUT SELECT MODIFICADO <SelectComponent />
    SE NECESITA LO SIGUIENTE:
        -UNA VARIABLE A MAPEAR EN EL SELECT CON SUS MOVIMIENTOS DE LA SIGUIENTE FORMA:
            array_para_select : [{ id: "", nombre: "" },{ id: "", nombre: "" }]
        -UNA VARIABLE PARA GUARDAR EL ID QUE ESTA SELECCIONADO EN EL INPUT.
            variable_id: ""
        -UNA VARIABLE QUE GUARDE EL TEXTO QUE ESTA SELECCIONADO EN EL INPUT.
            variable_texto: ""
        -UNA FUNCIÓN DE CAMBIO DE ESTADO.
            handleNombre_variable = (e) => {
                let label = e.name_text;
                let label_id = e.name_index;
                let variable_texto = e[label];
                let variable_id = e[label_id];
                this.setState({ variable_texto, variable_id });
            }
    LA ESTRUCTURA DEL INPUT TIENE UNA LOGICA, SUS ETIQUETAS SE EXPLICAN A CONTINUCACIÓN:
    <SelectComponent 
        bootstrap=""   -> String para agregar clases de bootstrap.
        *name_text=""  -> String que identifica la etiqueta que contiene el texto 
                         a mostar en el input del arreglo de objetos que mapea.
        *name_index="" -> String que identifica la etiqueta que contiene el id 
                         que guarda en el input del arreglo de objetos que mapea.
        id=""          -> String quer contine el identificador de HTML.
        *nameLabel=""  -> Nombre del Input a mostar.
        *options={}    -> Arreglo que contine las opciones (array_para_select).
        *value={}      -> Valor actual de texto actual (variable_texto).
        *onChange={}   -> Evento de cambio en el input (handleNombre_variable).
    />
    * Incica que las etiquetas son obligatorias.
------------------------------------------------------------------------------------------------ */