/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un anticipo. 
*/
import {
  faEye,
  faFileDownload,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "@sweetalert/with-react";
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import MyInput from "../../../utils/custom/MyInput";
import CustomSelect from "../../../utils/custom/CustomSelect";
import MyTextArea from "../../../utils/custom/MyTextArea";
import WebSocketInstance from "../../../utils/websocket";
import * as message from "../messages/messages";
import AutocompleteInput from "../../../utils/custom/AutocompleteInput";
import * as utility from "../utils/utility";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      nombre_select: "",
      numero_select: "",
      referencia_select: "",
      aduana_select: "",
      movimiento_select_text: "Seleccione un tipo de movimiento",
      movimiento_select_id: "",
      movimiento_sol_anticipo_text: "Seleccione un movimiento",
      movimiento_sol_anticipo_id: "",
      identificativo: "",
      movimientos_new_sol: [],
      nombre_suggestions: [],
      referencias_suggestions: [],
      referencias_filter: [],
      referencias: [],
      enable_cliente: false,
      enable_referencia: true,
      toogle_nuevaSol: false,
      toogle_nuevoMov: false,

      monto: "",

      tipos_movimiento: [
        { nombre: "Servicios", clave: "0" },
        { nombre: "Cuadro de Liquidación", clave: "1" },
        { nombre: "Pagos hechos por cuenta del cliente", clave: "2" },
      ],
    };
    this.enableReferencia = this.enableReferencia.bind(this);
    this.toogleNuevaSolicitud = this.toogleNuevaSolicitud.bind(this);
    this.toogleNuevoMovimiento = this.toogleNuevoMovimiento.bind(this);
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.identificativo;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "solicitud_anticipo",
      motivo: motivo,
      folio: [folio],
    };
    if (this.props.on_cliente === false)
      notificacionObject["cliente"] = [parseInt(this.state.numero_select)];
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
    document.addEventListener("keyup", this.handleEnterKeyPress);
  }
  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("keyup", this.handleEnterKeyPress);
  }
  handleAlta = () => {
    message
      .message_confimacion("¿Desea continuar con la operación alta?", "")
      .then((res) => {
        if (res) {
          if (
            this.state.referencia_select != null ||
            this.state.referencia_select != ""
          ) {
            let message = "";
            let sol = utility.get_data_solicitud_anticipo();
            if (sol.aduana === "") message += "Aduana, ";
            if (sol.observaciones === "") message += "Observaciones";
            if (message.length === 0) {
              let data;
              if (this.state.movimientos_new_sol.length > 0) {
                let importe = 0;
                this.state.movimientos_new_sol.forEach(
                  (mov) => (importe += parseFloat(mov.monto))
                );
                data = {
                  ...utility.get_data_solicitud_anticipo(),
                  movimientos: this.state.movimientos_new_sol,
                  importe: importe,
                };
              } else
                data = { ...utility.get_data_solicitud_anticipo(), importe: 0 };
              this.methodPOST_API(
                utility.url_crear_modifica_solicitud,
                "alta",
                data
              );
            } else swal("Completar los campos:", message, "warning");
          } else {
            let message = "";
            let sol = utility.get_solicitud_anticipo_sin_referencia();
            if (sol.aduana === "") message += "Aduana, ";
            if (sol.observaciones === "") message += "Observaciones";
            if (message.length === 0) {
              let data;
              if (this.state.movimientos_new_sol.length > 0) {
                let importe = 0;
                this.state.movimientos_new_sol.forEach(
                  (mov) => (importe += parseFloat(mov.monto))
                );
                data = {
                  ...utility.get_solicitud_anticipo_sin_referencia(),
                  movimientos: this.state.movimientos_new_sol,
                  importe: importe,
                };
              } else
                data = {
                  ...utility.get_solicitud_anticipo_sin_referencia(),
                  importe: 0,
                };
              this.methodPOST_API(
                utility.url_crear_modifica_solicitud,
                "alta",
                data
              );
            } else swal("Completar los campos:", message, "warning");
          }
        }
      });
  };
  handleMovimientoSol = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let movimiento_sol_anticipo_text = e[label];
    let movimiento_sol_anticipo_id = e[label_id];
    this.setState({ movimiento_sol_anticipo_text, movimiento_sol_anticipo_id });
  };

  handleMonto = (e) => {
    let monto = e.target.value;

    // Remover caracteres que no son números, puntos o comas
    monto = monto.replace(/[^\d.,]/g, "");

    // Reemplazar comas por puntos
    monto = monto.replace(/,/g, ".");

    // Si hay más de un punto, eliminar los puntos extras
    const dotCount = monto.split(".").length - 1;
    if (dotCount > 1) {
      monto = monto.replace(/\./g, (match, offset) => (offset ? "" : match));
    }

    // Limitar a dos decimales
    const parts = monto.split(".");
    if (parts[1] && parts[1].length > 2) {
      monto = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    this.setState({ monto });
  };

  handleMontoBlur = () => {
    let monto = this.state.monto;

    // Si el campo está vacío, establecer el valor como '0.00'
    if (!monto) {
      monto = "0.00";
    } else {
      // Convertir el valor a un número
      let num = parseFloat(monto);

      // Verificar si el valor es un número válido
      if (!isNaN(num)) {
        // Formatear el número con dos decimales
        monto = num.toFixed(2);
      } else {
        // Si no es un número válido, establecer el valor como '0.00'
        monto = "0.00";
      }
    }

    this.setState({ monto });
  };

  handleNewMovimiento = (e) => {
    let tipo = e.target.value;
    let message = "";
    if (this.state.movimiento_select_id.length <= 0)
      message += "Tipo de Movimiento, ";
    if (this.state.movimiento_sol_anticipo_id.length <= 0)
      message += "Movimiento, ";
    if (tipo === "solicitud")
      if (document.getElementById("monto_nueva_solicitud").value.length <= 0)
        message += "Monto";
    if (tipo === "movimiento")
      if (document.getElementById("monto_nuevo_movimiento").value.length <= 0)
        message += "Monto";
    if (message.length > 0)
      swal("Falta completar los campos", message, "warning");
    else {
      let movimientos = this.state.movimientos_new_sol;
      for (let i = 0; i < movimientos.length; i++) {
        if (movimientos[i].tipo_solicitud === this.state.movimiento_select_id)
          if (
            movimientos[i].clave_concepto ===
            this.state.movimiento_sol_anticipo_id
          ) {
            swal(
              "Ya existe el movimiento seleccionado",
              "sólo puede haber un movimiento de cada tipo registrado",
              "warning"
            );
            return null;
          }
      }
      if (tipo === "solicitud")
        movimientos.push({
          tipo_solicitud: this.state.movimiento_select_id,
          clave_concepto: this.state.movimiento_sol_anticipo_id,
          monto: document.getElementById("monto_nueva_solicitud").value,
        });
      if (tipo === "movimiento")
        movimientos.push({
          tipo_solicitud: this.state.movimiento_select_id,
          clave_concepto: this.state.movimiento_sol_anticipo_id,
          monto: document.getElementById("monto_nuevo_movimiento").value,
        });
      this.setState({
        movimientos_new_sol: movimientos,
      });
    }
  };
  handleDeleteNewMovimiento = (clave_concepto) => {
    const toFind = clave_concepto;
    const movimientos_new_sol = this.state.movimientos_new_sol.filter(
      (movimientos_new_sol) => movimientos_new_sol.clave_concepto !== toFind
    );
    this.setState({
      movimientos_new_sol: movimientos_new_sol,
    });
  };
  handleMovimiento = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let movimiento_select_text = e[label];
    let movimiento_select_id = e[label_id];
    this.setState({ movimiento_select_text, movimiento_select_id });
  };
  toogleNuevaSolicitud = () => {
    this.setState((prevState) => ({
      toogle_nuevaSol: !prevState.toogle_nuevaSol,
      toogle_nuevoMov: false,
      movimiento_select: "",
      movimiento_sol_anticipo: "",
      movimientos_new_sol: [],
    }));
    if (localStorage.getItem("areas") === "6") {
      utility.add_class_disabled("numero_cliente_sol_anticipo");
      utility.add_class_disabled("nombre_cliente_sol_anticipo");
    }
  };
  toogleNuevoMovimiento = () => {
    this.setState((prevState) => ({
      toogle_nuevoMov: !prevState.toogle_nuevoMov,
      toogle_nuevaSol: false,
      movimiento_select: "",
      movimiento_sol_anticipo: "",
      movimientos_new_sol: [],
    }));
  };
  toogleExternAlta = () => {
    this.clearStateNewMovimiento();
    this.clearStateNewSolicitud();
    this.setState({
      nombre_select: "",
      numero_select: "",
      referencia_select: "",
      aduana_select: "",
      movimiento_select_text: "Seleccione un tipo de movimiento",
      movimiento_select_id: "",
      movimiento_sol_anticipo_text: "Seleccione un movimiento",
      movimiento_sol_anticipo_id: "",
      identificativo: "",
      movimientos_new_sol: [],
      nombre_suggestions: [],
      referencias_suggestions: [],
      referencias_filter: [],
      enable_cliente: false,
      enable_referencia: true,
      toogle_nuevaSol: false,
      toogle_nuevoMov: false,
      referencias: [],

      monto: "",
    });
    this.props.toogleNuevo();
  };

  handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      // Si la tecla presionada fue 'Enter', llamamos a la función para manejar el evento
      if (e.target.id === "monto_nueva_solicitud") {
        this.handleMontoBlur();
      }
    }
  };

  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") {
      text_add = "";
      this.setState({
        referencia_select: "",
        aduana_select: "",
      });
    } else if (numero_cliente.length <= 4) {
      switch (numero_cliente.length) {
        case 1:
          text_add = "000";
          text_add += numero_cliente;
          break;
        case 2:
          text_add = "00";
          text_add += numero_cliente;
          break;
        case 3:
          text_add = "0";
          text_add += numero_cliente;
          break;
        case 4:
          text_add = numero_cliente;
          break;
        default:
          return null;
      }
    } else text_add = "";
    for (let i = 0; i < this.props.clientes.results.length; i++) {
      if (text_add.length === 0) {
        document.getElementById("nombre_cliente_sol_anticipo").value = "";
        this.suggestionSelected("");
        break;
      }
      if (text_add === this.props.clientes.results[i].numero) {
        document.getElementById("nombre_cliente_sol_anticipo").value =
          this.props.clientes.results[i].nombre;
        this.suggestionSelected(this.props.clientes.results[i].nombre);
        break;
      }
    }
  };
  suggestionSelected = (value) => {
    let number = utility.get_number_cliente(value, this.props.clientes.results);
    if (number === undefined) number = "";
    document.getElementById("numero_cliente_sol_anticipo").value = number;
    this.setState({
      nombre_select: value,
      numero_select: number,
      nombre_suggestions: [],
    });
  };
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_nombres_clientes(this.props.clientes.results)
        .sort()
        .filter((v) => regex.test(v));
    }
    if (value === "") {
      this.setState({
        referencia_select: "",
        aduana_select: "",
      });
    }
    this.setState({
      nombre_suggestions: suggestions,
      nombre_select: value,
    });
  };
  renderSuggestion = () => {
    if (this.state.nombre_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */

  handleConsultaReferencia = () => {
    if (!this.state.numero_select) {
      swal("Advertencia", "Por favor escribe un número de cliente", "warning");
      return; 
    }

    this.enableReferencia();
    const data = {
      cliente: this.state.numero_select,
      status: "A",
    };

    axios
      .post(utility.referencia_filtrada, data)
      .then((response) => {
        this.setState({ referencias: response.data.results });
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  /* ------ Bloque de funciones que nos permitiran el autocompletado de las referencias ------ */
  referenciaGenerator = (e) => {
    let aduana = e.target.value;
    let referencias_aduana = [];
    let referencias_aduana_cliente = [];
    if (aduana === "") {
      this.suggestionSelectedReferecia("");
    } else {
      for (let i = 0; i < this.props.referencias.length; i++)
        if (this.props.referencias[i].aduana === aduana)
          referencias_aduana.push(this.props.referencias[i]);
      for (let j = 0; j < referencias_aduana.length; j++)
        if (
          referencias_aduana[j].cliente ===
          utility.complete_number_cliente(this.state.numero_select)
        )
          referencias_aduana_cliente.push(referencias_aduana[j]);
      this.setState({
        referencias_filter: referencias_aduana_cliente,
        aduana_select: aduana,
      });
    }
  };
  suggestionSelectedReferecia = (value) => {
    let aduana = utility.get_aduana(
      value,
      utility.complete_number_cliente(this.state.numero_select),
      this.props.referencias
    );
    if (aduana === undefined) aduana = "";
    document.getElementById("aduana_sol_anticipo").value = aduana;
    this.setState({
      referencia_select: value,
      aduana_select: aduana,
      referencias_suggestions: [],
    });
  };

  onReferenciaChange = (option) => {
    const referencia_select = option.referencia;
    this.setState({
      referencia_select,
    });
  };

  renderSuggestionReferencia = () => {
    if (this.state.referencias_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.referencias_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelectedReferecia(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  enableReferencia = () => {
    if (this.state.numero_select.length === 0)
      this.setState({ enable_referencia: true });
    else this.setState({ enable_referencia: false });
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de las referencias ------ */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta":
              this.clearStateNewSolicitud();
              this.props.toogleNuevo();
              if (data.movimientos)
                this.setState(
                  { identificativo: response.data.results.referencia },
                  () => {
                    this.methodPDF_API(
                      utility.url_pdf_solicitud,
                      "alta_anticipo",
                      response.data.results.id_solicitud
                    );
                    this.sendNotificacionHandler("crear");
                  }
                );
              swal({
                icon: "success",
                title: response.data.title,
                text: response.data.text,
                content: data.movimientos ? (
                  <div>
                    <LinkD
                      href=""
                      id="dowload_pdf_alta_solicitud"
                      download={`Solicitud de anticipo ${response.data.results.id_solicitud}`}
                    />
                    <br />
                    <LinkV
                      href=""
                      id="window_pdf_alta_solicitud"
                      target="_blank"
                    />
                  </div>
                ) : (
                  <div></div>
                ),
              });
              this.props.update(1);
              break;
            case "movimiento":
              this.clearStateNewMovimiento();
              this.props.toogleNuevo();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPDF_API = (url, state, num) => {
    let url_intern = url + `${num}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta_anticipo":
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);
              document.getElementById("dowload_pdf_alta_solicitud").href =
                fileURL;
              document.getElementById("window_pdf_alta_solicitud").href =
                fileURL;
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  clearStateNewSolicitud = () => {
    if (this.props.on_cliente !== null)
      this.setState({
        referencia_select: "",
        aduana_select: "",
        movimiento_select_text: "Seleccione un tipo de movimiento",
        movimiento_select_id: "",
        movimiento_sol_anticipo_text: "Seleccione un movimiento",
        movimiento_sol_anticipo_id: "",
        identificativo: "",
        movimientos_new_sol: [],
        nombre_suggestions: [],
        referencias_suggestions: [],
        referencias_filter: [],
        enable_cliente: false,
        enable_referencia: true,
        toogle_nuevaSol: false,
        toogle_nuevoMov: false,

        tipos_movimiento: [
          { nombre: "Servicios", clave: "0" },
          { nombre: "Cuadro de Liquidación", clave: "1" },
          { nombre: "Pagos hechos por cuenta del cliente", clave: "2" },
        ],
      });
    else
      this.setState({
        nombre_select: "",
        numero_select: "",
        referencia_select: "",
        aduana_select: "",
        movimiento_select_text: "Seleccione un tipo de movimiento",
        movimiento_select_id: "",
        movimiento_sol_anticipo_text: "Seleccione un movimiento",
        movimiento_sol_anticipo_id: "",
        identificativo: "",
        movimientos_new_sol: [],
        nombre_suggestions: [],
        referencias_suggestions: [],
        referencias_filter: [],
        enable_cliente: false,
        enable_referencia: true,
        toogle_nuevaSol: false,
        toogle_nuevoMov: false,

        tipos_movimiento: [
          { nombre: "Servicios", clave: "0" },
          { nombre: "Cuadro de Liquidación", clave: "1" },
          { nombre: "Pagos hechos por cuenta del cliente", clave: "2" },
        ],
      });
    document.getElementById("observacion_sol_anticipo").value = "";
  };
  clearStateNewMovimiento = () => {
    this.setState({
      clave_concepto: [],
      movimiento_select: "",
      movimiento_sol_anticipo: "",
      movimientos_new_sol: [],
    });
  };
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_nuevo}
        toggle={this.toogleExternAlta}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.toogleExternAlta}>
          Alta Solicitud De Depósito
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="mb-0 row">
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="numero_cliente_sol_anticipo"
                nameLabel="Número de Cliente"
                onChange={this.nameGenerator}
                value={this.state.numero_select}
                disabled={this.state.enable_cliente}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-7 col-12"
                id="nombre_cliente_sol_anticipo"
                nameLabel="Nombre de Cliente"
                onChange={this.onNameChange}
                value={this.state.nombre_select}
                disabled={this.state.enable_cliente}
              />
              {this.renderSuggestion()}
              <Button
                color="primary"
                className="col-lg-1 col-4 mb-2"
                value="solicitud"
                onClick={this.handleConsultaReferencia}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="fecha_alta_sol_anticipo"
                nameLabel="Fecha actual"
                value={utility.get_date_actual().fecha}
                disabled
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="aduana_sol_anticipo"
                nameLabel="Aduana"
                onChange={this.referenciaGenerator}
                value={this.state.aduana_select}
                disabled={this.state.enable_referencia}
              />
              <AutocompleteInput
                type="text"
                name_text="referencia" // Clave a usar en el filtro
                bootstrap="col-lg-4 col-12"
                id="referencia_sol_anticipo"
                nameLabel="Referencia"
                value={this.state.referencia_select}
                onSelect={this.onReferenciaChange}
                options={this.state.referencias}
                disabled={this.state.enable_referencia}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyTextArea
                bootstrap="col-lg-12 col-12"
                id="observacion_sol_anticipo"
                nameLabel="Observaciones"
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <CustomSelect
                bootstrap="col-lg-4 col-12"
                name_text="nombre"
                name_index="clave"
                id="tipo_movimiento_sol_antiicpo"
                nameLabel="Tipo de movimiento"
                value={this.state.movimiento_select_text}
                onChange={this.handleMovimiento}
                options={this.state.tipos_movimiento}
              />
              <CustomSelect
                bootstrap="col-lg-4 col-12"
                name_text={
                  this.state.movimiento_select_id === "0"
                    ? "nombre"
                    : this.state.movimiento_select_id === "1"
                    ? "descripcion"
                    : this.state.movimiento_select_id === "2"
                    ? "nombre"
                    : null
                }
                name_index={
                  this.state.movimiento_select_id === "0"
                    ? "clave"
                    : this.state.movimiento_select_id === "1"
                    ? "id"
                    : this.state.movimiento_select_id === "2"
                    ? "clave"
                    : null
                }
                id="tipo_movimiento_sol_anticpo"
                nameLabel="Tipo de movimiento"
                value={this.state.movimiento_sol_anticipo_text}
                onChange={this.handleMovimientoSol}
                options={
                  this.state.movimiento_select_id === "0"
                    ? this.props.ingresos
                    : this.state.movimiento_select_id === "1"
                    ? this.props.contribuciones
                    : this.state.movimiento_select_id === "2"
                    ? this.props.phxcc
                    : []
                }
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3 col-8"
                id="monto_nueva_solicitud"
                nameLabel="Monto"
                value={this.state.monto}
                onChange={this.handleMonto}
                onBlur={this.handleMontoBlur}
              />
              <Button
                color="primary"
                className="col-lg-1 col-4 mb-2"
                value="solicitud"
                onClick={this.handleNewMovimiento}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </FormGroup>
          </Form>
          <Table size="sm" bordered hover responsive>
            <thead className="text-center header-table">
              <tr>
                <td>Tipo de Movimiento</td>
                <td>Concepto</td>
                <td>Cargo</td>
                <td></td>
              </tr>
            </thead>
            <tbody className="body-table">
              {this.state.movimientos_new_sol.map((mov) => (
                <tr key={mov.clave_concepto}>
                  <td className="text-center">
                    {mov.tipo_solicitud === "0"
                      ? "Servicios"
                      : mov.tipo_solicitud === "1"
                      ? "Cuadro Liquidación"
                      : "Pagos Hechos por Cuenta del Cliente"}
                  </td>
                  <td className="text-center">
                    {utility.get_type_concepto(
                      mov.clave_concepto,
                      mov.tipo_solicitud,
                      this.props.ingresos,
                      this.props.contribuciones,
                      this.props.phxcc
                    )}
                  </td>
                  <td className="text-center">{mov.monto}</td>
                  <td className="text-center">
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() =>
                        this.handleDeleteNewMovimiento(mov.clave_concepto)
                      }
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="success"
            className="mr-2"
            onClick={this.handleAlta}
            disabled={
              this.state.movimientos_new_sol.length === 0 ? true : false
            }
          >
            Aceptar
          </Button>
          <Button type="button" color="info" onClick={this.toogleExternAlta}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalAlta;
