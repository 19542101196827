import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormText } from 'reactstrap';

function DropzoneM(props) {
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0)
      props.handleArchivo(acceptedFiles);
  }, [props]);

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true, 
    accept: '.e, .a, .E, .A', 
    disabled: props.disabled ? props.disabled : false
  });

  return (
    <div {...getRootProps()} className="col-12 dropzone-container">
      <input {...getInputProps()} id="archivos" />
      <p className="mb-0 text-center">{
        isDragActive ?
          'Suelte sus archivos aquí' :
          'Seleccione o arrastre sus archivos'
      }<br />
        <FormText color="muted" className="d-block">
          Solo archivos con extensión E y A serán aceptados
        </FormText>
      </p>
    </div>
  );
}

export default DropzoneM;

