import axios from 'axios';
import React, { Component } from 'react';
import { Table, Alert } from 'reactstrap';
import * as message from '../../utils/messages/message';
import * as utility from './utility';
import Paginacion from '../Pagination';
import * as permisos from '../../utils/permisos/permisos';

class TableHistoricoBan extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            pagination_solicitud: { count: 1, results: [] },
            pagination_anticipo: { count: 1, results: [] },
            pagination_transaccion: { count: 1, results: [] },
            page_actual: 1,
        };
    }
    componentDidMount() {
        this._isMounted = true;
        if (!permisos.obtener_permiso_especifico('visualizar', this.props.permisos.anticipo_solicitud))
            this.methodGET_API(utility.url_historico_solicitud, 'pagination_solicitud');
        if (!permisos.obtener_permiso_especifico('visualizar', this.props.permisos.anticipo))
            this.methodGET_API(utility.url_historico_anticipo, 'pagination_anticipo');
        if (!permisos.obtener_permiso_especifico('visualizar', this.props.permisos.transferencia))
            this.methodGET_API(utility.url_historico_transaccion, 'pagination_transaccion');
    }
    handlePage = (page_actual) => {
        if (this.props.change_table === "0")
            this.setState({ page_actual }, () => { this.methodGET_API(utility.url_historico_solicitud, 'pagination_solicitud'); });
        else if (this.props.change_table === "1")
            this.setState({ page_actual }, () => { this.methodGET_API(utility.url_historico_anticipo, 'pagination_anticipo'); });
        else if (this.props.change_table === "2")
            this.setState({ page_actual }, () => { this.methodGET_API(utility.url_historico_transaccion, 'pagination_transaccion'); });
        else
            return null;
    }
    handleTipo_count = (tipo_app) => {
        if (tipo_app === "0")
            return this.state.pagination_solicitud.count;
        else if (tipo_app === "1")
            return this.state.pagination_anticipo.count;
        else if (tipo_app === "2")
            return this.state.pagination_transaccion.count;
        else
            return 1;
    }
    methodGET_API = (url, state) => {
        let url_intern = url + `?page=${this.state.page_actual}`;
        axios.get(url_intern)
            .then(response => {
                if (response.status >= 200 && response.status < 400) {
                    switch (state) {
                        case 'pagination_solicitud':
                            if (this._isMounted === true) {
                                let arr = [];
                                arr = utility.Burbuja(response.data.results);
                                this.setState({
                                    pagination_solicitud: { count: response.data.count, results: arr }
                                });
                            }
                            break;
                        case 'pagination_anticipo':
                            if (this._isMounted === true) {
                                let arr = [];
                                arr = utility.Burbuja_Anticipos(response.data.results);
                                this.setState({
                                    pagination_anticipo: { count: response.data.count, results: arr }
                                });
                            }
                            break;
                        case 'pagination_transaccion':
                            if (this._isMounted === true) {
                                this.setState({
                                    pagination_transaccion: { count: response.data.count, results: response.data.results }
                                });
                            }
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    render() {
        return (
            <React.Fragment>
                <div className="overflow-auto tm-table">
                    {
                        this.props.change_table === "0" ?
                            (<Table size="sm" id="tabla_historico" bordered hover responsive>
                                <thead className="text-center header-table">
                                    <tr>
                                        <th>No. Solicitud</th>
                                        <th>Aduana</th>
                                        <th>Referencia</th>
                                        <th>Fecha</th>
                                        <th>Observaciones</th>
                                        <th>Usuario</th>
                                    </tr>
                                </thead>
                                <tbody className="body-table">
                                    {this.state.pagination_solicitud.results.map((sol, index, arreglo) => {
                                        let sol_ant = "";
                                        if (index !== 0)
                                            sol_ant = arreglo[index - 1].id_solicitud;
                                        return (<tr>
                                            {sol_ant === sol.id_solicitud ? null :
                                                (<td rowSpan={utility.repeticion_solicitud(sol.id_solicitud, this.state.pagination_solicitud.results)} >
                                                    <p className="text-center mb-0">{sol.id_solicitud}</p>
                                                </td>)
                                            }
                                            <td ><p className="text-center mb-0">{sol.aduana}</p></td>
                                            <td ><p className="text-center mb-0">{sol.referencia}</p></td>
                                            <td ><p className="text-center mb-0">{sol.fecha}</p></td>
                                            <td ><p className="text-center mb-0">{sol.observaciones}</p></td>
                                            <td ><p className="text-center mb-0">{sol.usuario}</p></td>
                                        </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>) :
                            this.props.change_table === "1" ?
                                (<Table size="sm" id="tabla_historico" bordered hover responsive>
                                    <thead className="text-center header-table">
                                        <tr>
                                            <th>No. Anticipo</th>
                                            <th>Banco</th>
                                            <th>Fecha Modificación</th>
                                            <th>Documento</th>
                                            <th>Observaciones</th>
                                            <th>Saldo</th>
                                            <th>Usuario</th>
                                        </tr>
                                    </thead>
                                    <tbody className="body-table">
                                        {
                                            this.state.pagination_anticipo.results.map((ant, i, array) => {
                                                let ant_cli = "";
                                                if (i !== 0)
                                                    ant_cli = array[i - 1].numero;
                                                return (<tr>
                                                    {ant_cli === ant.numero ? null :
                                                        (<td rowSpan={utility.repeticion_anticipo(ant.numero, this.state.pagination_anticipo.results)} >
                                                            <p className="text-center mb-0">{ant.numero}</p>
                                                        </td>)
                                                    }
                                                    <td><p className="mb-0 text-center">{ant.banco}</p></td>
                                                    <td><p className="mb-0 text-center">{ant.fecha_modificacion}</p></td>
                                                    <td><p className="mb-0 text-center">{ant.documento}</p></td>
                                                    <td><p className="mb-0 text-center">{ant.observaciones}</p></td>
                                                    <td><p className="mb-0 text-center">$ {ant.importe}</p></td>
                                                    <td><p className="mb-0 text-center">{ant.usuario}</p></td>
                                                </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>) :
                                this.props.change_table === "2" ?
                                    (<Table size="sm" id="tabla_historico" bordered hover responsive>
                                        <thead className="text-center header-table">
                                            <tr>
                                                <th>Número</th>
                                                <th>Banco</th>
                                                <th>Tipo</th>
                                                <th>Beneficiario</th>
                                                <th>Concepto</th>
                                                <th>Fecha</th>
                                                <th>Comprobado</th>
                                                <th>Pago</th>
                                                <th>Cantidad</th>
                                                <th>Importe</th>
                                                <th>Estado</th>
                                            </tr>
                                        </thead>
                                        <tbody className="body-table">
                                            {
                                                this.state.pagination_transaccion.results.map(transaccion => (
                                                    <tr key={transaccion.numero}>
                                                        <td><p className="mb-0 text-center">{transaccion.numero}</p></td>
                                                        <td><p className="mb-0 text-center">{transaccion.banco}</p></td>
                                                        <td><p className="mb-0 text-center">{transaccion.tipo}</p></td>
                                                        <td><p className="mb-0 text-center">{transaccion.beneficiario}</p></td>
                                                        <td><p className="mb-0 text-center">{transaccion.concepto}</p></td>
                                                        <td><p className="mb-0 text-center">{transaccion.fecha}</p></td>
                                                        <td><p className="mb-0 text-center">{transaccion.comprobado}</p></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><p className="mb-0 text-center">{transaccion.importe}</p></td>
                                                        <td><p className="mb-0 text-center">{transaccion.status}</p></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>) :
                                    (<Alert color="danger" className="mt-5 col-lg-8 col-12 mx-auto">
                                        <h2 className="mb-0 text-center">Debe seleccionar una aplicación para ver su tabla de histórico</h2>
                                    </Alert>)
                    }
                </div>
                {this.props.change_table === 0 ? (null) : (
                    <Paginacion actual={this.state.page_actual} count={this.handleTipo_count(this.props.change_table)} paginate={this.handlePage} />
                )}
            </React.Fragment>);
    }
}
export default TableHistoricoBan;