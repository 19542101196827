import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from './components/Login';
import NotFound404 from './components/NotFound404';
import Principal from './components/Principal';
import Usuarios from './components/AltaUser';
import Empleados from './components/Empleados';
import Alerta from './components/Alerta';
import Departamentos from './components/Departamentos';
import Puestos from './components/Puestos';
import SolAnticipo from './components/SolAnticipo';
import Proveedores from './components/Proveedores';
import Clientes from './components/Clientes';
import Polizas from './components/Poliza';
import CuentasAux from './components/CuentasAux';
import Consolidadoras from './components/Consolidadoras';
import Patentes from './components/Patentes';
import ContabilidadEle from './components/ContabilidadEle';
import NotificacionDeposito from './components/NotificacionDeposito';
import Anticipos from './components/Anticipos';
import HistoricoOperacion from './components/HistoricoOperacion';
import HistoricoBancos from './components/HistoricoBancos'; 
import HistoricoContabilidad from './components/HistoricoContabilidad';
import Operacion from './components/Operacion';
import Chat from './components/Chat';
import Pedimento from './components/Pedimento';
import Transacciones from './components/Transacciones';
import Ingresos from './components/Cat_Ingresos';
import PHXCDC from './components/Cat_Phxcdc';
import Egresos from './components/Cat_Egresos';
import CuentasGastos from './components/CuentasGastos';
import Conciliacion from './components/Conciliacion';
import PagoTerceros from './components/PagosTerceros';
import Correo from './components/Correo';
import ComplementoPago from './components/Pruebas/ComplementoPago';
import Beneficiarios from './components/Cat_Beneficiarios';
import CuentasBancarias from './components/Cat_CuentasBancarias';
import Diario from './components/Cat_diario';
import NumeroParte from './components/Cat_NumeroParte';
import Fracciones from './components/Cat_Fracciones';
import Facturacion from './components/Facturacion';
import Pruebas from './components/Pruebas';
import ConciliacionFactura  from './components/ConciliacionFactura';
import Transportista  from './components/Transportista';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={
            ({ location, props }) => {
                if (localStorage.getItem('csrfToken'))
                    return (<Component {...props} />);
                else
                    return (<Redirect to={{ pathname: "/login", state: { from: location } }} />);
            }
        } />
    );
}

const AppRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/" component={Principal} />
            <PrivateRoute exact path="/empleados" component={Empleados} />
            <PrivateRoute exact path="/usuarios" component={Usuarios} />
            <PrivateRoute exact path="/alerta" component={Alerta} />
            <PrivateRoute exact path="/departamentos" component={Departamentos} />
            <PrivateRoute exact path="/puestos" component={Puestos} />
            <PrivateRoute exact path="/solicitud_deposito" component={SolAnticipo} />
            <PrivateRoute exact path="/proveedores" component={Proveedores} />
            <PrivateRoute exact path="/clientes" component={Clientes} />
            <PrivateRoute exact path="/polizas" component={Polizas} />
            <PrivateRoute exact path="/cuentas_auxiliares" component={CuentasAux} />
            <PrivateRoute exact path="/consolidadoras" component={Consolidadoras} />
            <PrivateRoute exact path="/agenetes_aduanales" component={Patentes} />
            <PrivateRoute exact path="/contabilidad_electronica" component={ContabilidadEle} />
            <PrivateRoute exact path="/notificacion_deposito" component={NotificacionDeposito} />
            <PrivateRoute exact path="/anticipos" component={Anticipos} />
            <PrivateRoute exact path="/historico_operacion" component={HistoricoOperacion} />
            <PrivateRoute exact path="/historico_bancos" component={HistoricoBancos} />
            <PrivateRoute exact path="/historico_contabilidad" component={HistoricoContabilidad} />
            <PrivateRoute exact path="/operacion" component={Operacion} />
            <PrivateRoute exact path="/mensajes" component={Chat} />
            <PrivateRoute exact path="/pedimentos" component={Pedimento} />
            <PrivateRoute exact path="/transacciones" component={Transacciones} />
            <PrivateRoute exact path="/ingresos" component={Ingresos} />
            <PrivateRoute exact path="/pagos_terceros" component={PHXCDC} />
            <PrivateRoute exact path="/egresos" component={Egresos} />
            <PrivateRoute exact path="/cuentas_gastos" component={CuentasGastos} />
            <PrivateRoute exact path="/conciliacionPrueba" component={Conciliacion} />
            <PrivateRoute exact path="/conciliacion" component={ConciliacionFactura} />
            <PrivateRoute exact path="/pagos_a_terceros" component={PagoTerceros} />
            <PrivateRoute exact path="/correo" component={Correo} />
            <PrivateRoute exact path="/complemento_pago" component={ComplementoPago} />
            <PrivateRoute exact path="/beneficiarios" component={Beneficiarios} />
            <PrivateRoute exact path="/cuentas_bancarias" component={CuentasBancarias} />
            <PrivateRoute exact path="/diario" component={Diario} />
            <PrivateRoute exact path="/numparte" component={NumeroParte} />
            <PrivateRoute exact path="/fracciones" component={Fracciones} />
            <PrivateRoute exact path="/facturacion" component={Facturacion} />
            <PrivateRoute exact path="/transportista" component={Transportista} />
            <PrivateRoute exact path="/pruebas" component={Pruebas} />
            <Route component={NotFound404} />
        </Switch>
    );
}

export default AppRoutes;