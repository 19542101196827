import axios from 'axios';
import React, { Component } from 'react';
import { Spinner, Table } from 'reactstrap';
import * as utility from './utils/utility';
import Paginacion from '../Pagination';
import ConsultaEspecifica from './SearchSpecific';
import * as message from './messages/messages';

class TableDeposito extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            pagination: { count: 1, results: [] },
            page_actual: 1,
        }
    }
    componentDidMount() {
        this._isMounted = true;
        if (this.props.on_cliente === false)
            this.methodGET_API(utility.url_paginacion, 'pagination');
        else
            this.methodPUT_API(utility.url_paginacion, 'pagination', { cliente: this.props.on_cliente });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (this.props.actualizar !== prevProps.actualizar) {
            this.handlePage(this.state.page_actual);
            this.props.update(0);
        }
    }
    handlePage = (page_actual) => {
        if (this.props.on_cliente === false)
            this.setState({ page_actual }, () => { this.methodGET_API(utility.url_paginacion, 'pagination') });
        else
            this.setState({ page_actual }, () => { this.methodPUT_API(utility.url_paginacion, 'pagination', { cliente: this.props.on_cliente }); });
    }
    methodGET_API = (url, state) => {
        let url_intern = url;
        if (state === 'pagination')
            url_intern += `?page=${this.state.page_actual}`;
        axios.get(url_intern)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        case 'pagination':
                            if (this._isMounted)
                                this.setState({
                                    pagination: { count: response.data.count, results: response.data.results }
                                });
                            break;
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    methodPOST_API = (url, state, data) => {
        let url_intern = url;
        if (state === 'pagination')
            url_intern += `?page=${this.state.page_actual}`;
        axios.post(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        case 'pagination':
                            if (this._isMounted)
                                this.setState({
                                    pagination: { count: response.data.count, results: response.data.results }
                                });
                            break;
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    methodPUT_API = (url, state, data) => {
        let url_intern = url;
        if (state === 'pagination')
            url_intern += `?page=${this.state.page_actual}`;
        axios.put(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400) {
                    switch (state) {
                        case 'pagination':
                            if (this._isMounted === true)
                                this.setState({
                                    pagination: { count: response.data.count, results: response.data.results }
                                });
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    onBackTable = () => {
        if (this.props.on_cliente === false)
            this.methodGET_API(utility.url_paginacion, 'pagination')
        else
            this.methodPUT_API(utility.url_paginacion, 'pagination', { cliente: this.props.on_cliente });
    }
    onChangeFiltrada = (data) => {
        if (this.props.on_cliente === false)
            this.setState({ page_actual: 1 }, () => { this.methodPOST_API(utility.url_paginacion, 'pagination', data); })
        else
            this.setState({ page_actual: 1 }, () => { this.methodPOST_API(utility.url_paginacion, 'pagination', { ...data, cliente: this.props.on_cliente }); })
    }
    render() {
        return (
            <React.Fragment>
                <ConsultaEspecifica onBackTable={this.onBackTable} onChangeFiltrada={this.onChangeFiltrada} on_cliente={this.props.on_cliente} />
                <div className="overflow-auto tm-table">
                    <Table size="sm" id="tabla_deposito" bordered hover responsive>
                        <thead className="text-center header-table">
                            <tr>
                                <td>ID Deposito</td>
                                <td>Cliente</td>
                                <td>Banco</td>
                                <td>Documento</td>
                                <td>Importe</td>
                                <td>Tipo Deposito</td>
                                <td>Fecha Alta</td>
                                <td>Fecha Notificación</td>
                                <td>Estado</td>
                                <td>Observaciones</td>
                            </tr>
                        </thead>
                        <tbody className="body-table hover-gray">
                            {
                                this.state.pagination.results.length === 0 ? (
                                    <tr>
                                        <td colSpan="12" align="center">
                                            <Spinner
                                                style={{ width: '10rem', height: '10rem' }}
                                                color="danger"
                                                type="grow"
                                                className="align-self-center"
                                            />
                                        </td>
                                    </tr>
                                ) : (<React.Fragment>
                                    {
                                        this.state.pagination.results.map(notifiacion => (
                                            <tr key={notifiacion.id_deposito}>
                                                <td><p className="mb-0 text-center">{notifiacion.id_deposito}</p></td>
                                                <td><p className="mb-0 text-center">{notifiacion.cliente}</p></td>
                                                <td><p className="mb-0 text-center">{notifiacion.banco}</p></td>
                                                <td><p className="mb-0 text-center">{notifiacion.documento}</p></td>
                                                <td><p className="mb-0 text-center">{notifiacion.importe}</p></td>
                                                <td><p className="mb-0 text-center">{notifiacion.tipo_deposito}</p></td>
                                                <td><p className="mb-0 text-center">{notifiacion.fecha_alta}</p></td>
                                                <td><p className="mb-0 text-center">{notifiacion.fecha_notificacion}</p></td>
                                                <td><p className="mb-0 text-center">{notifiacion.status}</p></td>
                                                <td><p className="mb-0 text-center">{notifiacion.observaciones}</p></td>
                                            </tr>
                                        ))
                                    }
                                </React.Fragment>)
                            }
                        </tbody>
                    </Table>
                </div>
                <Paginacion actual={this.state.page_actual} count={this.state.pagination.count} paginate={this.handlePage} />
            </React.Fragment>
        );
    }
}
export default TableDeposito;