/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la consulta de una patente. 
*/
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import CustomSelect from "../../../utils/custom/CustomSelect";
import MyInput from "../../../utils/custom/MyInput";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      numero: "",
      aduana: "",
      patente: "",
      nombre: "",
      rfc: "",
      curp: "",
      tipo_patente_text: "Seleccione un tipo de patente",
      tipo_patente_id: "",
      domicilio: "",
      colonia_id: "",
      colonia_text: "Seleccione una colonia",
      codigo_postal: "",
      estado: "",
      ciudad: "",
      contacto: "",
      telefono: "",
      correo: "",

      colonias: [],
      contactos: [],
      tipos_patente: [
        { clave: 0, nombre: "Propia" },
        { clave: 1, nombre: "Socio" },
        { clave: 2, nombre: "Corresponsal" },
      ],
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  /* --------------- Funciones que actualizan las variables de los inputs --------------- */
  handleCerrar = () => {
    this.props.toogleAlta();
    this.clearState();
  };
  handleNumero = (e) => {
    let numero = e.target.value;
    this.setState({ numero });
  };
  handleAduana = (e) => {
    let aduana = e.target.value;
    this.setState({ aduana });
  };
  handlePatente = (e) => {
    let patente = e.target.value;
    this.setState({ patente });
  };
  handleNombre = (e) => {
    let nombre = e.target.value;
    this.setState({ nombre });
  };
  handleRFC = (e) => {
    let rfc = e.target.value;
    this.setState({ rfc });
  };
  handleCURP = (e) => {
    let curp = e.target.value;
    this.setState({ curp });
  };
  handleTipo_patente = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_patente_text = e[label];
    let tipo_patente_id = e[label_id];
    this.setState({ tipo_patente_text, tipo_patente_id });
  };
  handleContacto = (e) => {
    let contacto = e.target.value;
    this.setState({ contacto });
  };
  handleTelefono = (e) => {
    let telefono = e.target.value;
    this.setState({ telefono });
  };
  handleCorreo = (e) => {
    let correo = e.target.value;
    this.setState({ correo });
  };
  handleDomicilio = (e) => {
    let domicilio = e.target.value;
    this.setState({ domicilio });
  };
  handleColonia = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let colonia_text = e[label];
    let colonia_id = e[label_id];
    let estado, ciudad;
    this.state.colonias.forEach((col) => {
      if (colonia_id === col.asentamiento) {
        estado = col.estado;
        ciudad = col.ciudad;
      }
    });
    this.setState({ colonia_id, colonia_text, estado, ciudad });
  };
  handleCodigo_postal = (e) => {
    let codigo_postal = e.target.value;
    if (codigo_postal.length === 5)
      this.methodPOST_API(utility.url_codigo_postal, "codigo_postal", {
        codigo: codigo_postal,
      });
    this.setState({ codigo_postal });
  };
  handleEstado = (e) => {
    let estado = e.target.value;
    this.setState({ estado });
  };
  handleCiudad = (e) => {
    let ciudad = e.target.value;
    this.setState({ ciudad });
  };
  /* --------------- Fin de funciones que actualizan las variables de los inputs --------------- */

  /* --------------- Funciones de eventos que generan una petición --------------- */
  handleAgregar = () => {
    
    let contactos = this.state.contactos;
    let data = {
      contacto: this.state.contacto,
      telefono: this.state.telefono,
      correo: this.state.correo,
    };
    contactos.push(data);
    this.setState({ contactos, contacto: "", telefono: "", correo: "" });
  };
  handleEliminaContacto = (e) => {
    let contactos = this.state.contactos;
    contactos.splice(e.target.value, 1);
    this.setState({ contactos });
  };
  handleAlta = () => {
    let data = {
      patente: this.state.patente,
      nombre: this.state.nombre,
      tpo_patente: this.state.tipo_patente_id,
      rfc: this.state.rfc,
      curp: this.state.curp,
      aduana: this.state.aduana,
      domicilio: this.state.domicilio,
      colonia: this.state.colonia,
      codigo_postal: this.state.codigo_postal,
    };
    message
      .message_confimacion("¿Desea continuar con la operación alta?", "")
      .then((res) => {
        if (res)
          if (utility.validadCamposPOST(data).length > 0)
            message.message_modal(
              "Falta completar los siguientes campos:",
              utility.validadCamposPOST(data),
              "warning"
            );
          else this.methodPOST_API(utility.url_alta_patente, "alta", data);
      });
  };
  /* --------------- Fin de funciones de eventos que generan una petición --------------- */
  clearState = () => {
    this.setState({
      numero: "",
      aduana: "",
      patente: "",
      nombre: "",
      rfc: "",
      curp: "",
      tipo_patente_text: "Seleccione un tipo de patente",
      tipo_patente_id: "",
      domicilio: "",
      colonia_id: "",
      colonia_text: "Seleccione una colonia",
      codigo_postal: "",
      estado: "",
      ciudad: "",
      contacto: "",
      telefono: "",
      correo: "",

      colonias: [],
      contactos: [],
      tipos_patente: [
        { clave: 0, nombre: "Propia" },
        { clave: 1, nombre: "Socio" },
        { clave: 2, nombre: "Corresponsal" },
      ],
    });
  };
  /**
   * Función que permite hacer una petición de forma POST.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} data Objeto a mandar atraves del metodo POST.
   */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "codigo_postal":
              if (this._isMounted)
                this.setState({
                  colonias: response.data.results,
                });
              break;
            case "alta":
              if (this.state.contactos.length > 0)
                this.methodPOST_API(
                  utility.url_alta_contacto,
                  "alta_contacto",
                  {
                    contactos: this.state.contactos,
                    agente_aduanal: response.data.results.numero,
                  }
                );
              this.props.toogleAlta();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_alta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Alta Agente Aduanal
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-12 col-12"
                id="nombre_alta_patente"
                nameLabel="Nombre del Agente Aduanal"
                value={this.state.nombre}
                onChange={this.handleNombre}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="rfc_alta_patente"
                nameLabel="RFC"
                value={this.state.rfc}
                onChange={this.handleRFC}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="curp_alta_patente"
                nameLabel="CURP"
                value={this.state.curp}
                onChange={this.handleCURP}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="aduana_alta_patente"
                nameLabel="Aduana"
                value={this.state.aduana}
                onChange={this.handleAduana}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="patente_alta_patente"
                nameLabel="Patente"
                value={this.state.patente}
                onChange={this.handlePatente}
              />
              <CustomSelect
                bootstrap="col-lg-4 col-12"
                name_text="nombre"
                name_index="clave"
                id="patente_alta_tipo_patente"
                nameLabel="Tipo de Patente"
                value={this.state.tipo_patente_text}
                onChange={this.handleTipo_patente}
                options={this.state.tipos_patente}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="domicilio_consulta_patente"
                nameLabel="Domicilio"
                value={this.state.domicilio}
                onChange={this.handleDomicilio}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="codigo_postal_consulta_patente"
                nameLabel="Código Postal"
                value={this.state.codigo_postal}
                onChange={this.handleCodigo_postal}
              />
              <CustomSelect
                bootstrap="col-lg-4 col-12"
                name_text="asentamiento"
                name_index="asentamiento"
                id="colonia_consulta_patente"
                nameLabel="Colonia"
                value={this.state.colonia_text}
                onChange={this.handleColonia}
                options={this.state.colonias}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="curp_alta_patente"
                nameLabel="Estado/Provincia/Región"
                value={this.state.estado}
                disabled
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="rfc_alta_patente"
                nameLabel="Ciudad"
                value={this.state.ciudad}
                disabled
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-5 col-12"
                id="contacto_alta_patente"
                nameLabel="Nombre del Contacto"
                value={this.state.contacto}
                onChange={this.handleContacto}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-5 col-12"
                id="telefono_alta_patente"
                nameLabel="Telefono del Contacto"
                value={this.state.telefono}
                onChange={this.handleTelefono}
              />
              <Button
                color="primary"
                className="col-lg-2 col-4 mb-2"
                onClick={this.handleAgregar}
              >
                {" "}
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-12 col-12"
                id="correo_alta_patente"
                nameLabel="Correo del Contacto"
                value={this.state.correo}
                onChange={this.handleCorreo}
              />
            </FormGroup>
          </Form>
          <Table size="sm" bordered hover responsive>
            <thead className="text-center header-table">
              <tr>
                <td>Nombre</td>
                <td>Telefono</td>
                <td>Correo</td>
                <td></td>
              </tr>
            </thead>
            <tbody className="body-table">
              {this.state.contactos.map((contacto, i) => (
                <tr>
                  <td className="text-center">{contacto.contacto}</td>
                  <td className="text-center">{contacto.telefono}</td>
                  <td className="text-center">{contacto.correo}</td>
                  <td className="text-center">
                    <Button
                      color="danger"
                      size="sm"
                      value={i}
                      onClick={this.handleEliminaContacto}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            className="mr-2"
            onClick={this.handleAlta}
            disabled={this.state.contactos.length === 0 ? true : false}
          >
            Aceptar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalAlta;
