import axios from "axios";
import React, { Component } from "react";
import { Spinner, Table, Button } from "reactstrap";
import * as utility from "./utils/utility";
import Paginacion from "../Pagination";
import ConsultaEspecifica from "./SearchSpecific";
import * as message from "./messages/messages";
import swal from "@sweetalert/with-react";

class TableDepartamentos extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      pagination: { count: 1, results: [] },
      page_actual: 1,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_paginacion, "pagination");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps) {
    if (this.props.actualizar !== prevProps.actualizar) {
      this.handlePage(this.state.page_actual);
      this.props.update(0);
    }
  }
  handlePage = (page_actual) => {
    this.setState({ page_actual }, () => {
      this.methodGET_API(utility.url_paginacion, "pagination");
    });
  };
  methodGET_API = (url, state) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "pagination":
              if (this._isMounted)
                this.setState({
                  pagination: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                });
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            500
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma POST
   * @param {String} url URL a consultar
   * @param {String} state Onpción del case
   * @param {Object} data Objeto a mandar atraves del metodo POST
   */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "pagination":
              if (this._isMounted)
                if (response.data.results)
                  this.setState({
                    pagination: {
                      count: response.data.count,
                      results: response.data.results,
                    },
                  });
                else
                  message.message_modal(
                    response.data.title,
                    response.data.text,
                    response.status
                  );
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  onBackTable = () => {
    this.methodGET_API(utility.url_paginacion, "pagination");
  };
  onChangeFiltrada = (data) => {
    this.setState({ page_actual: 1 }, () => {
      this.methodPOST_API(utility.url_paginacion, "pagination", data);
    });
  };

  handleEliminar = (id) => {
    message
        .message_confirmacion_only_password(
          "¿Desea desactivar el departamento?",
          "Ingrese su contraseña"
        )
        .then((res) => {
          if (res === true) {
            let mov = {
              user: document.getElementById("usuario_modificador").value,
              pass: document.getElementById("contraseña_modificador").value,
            };
            if (mov.pass.length === 0)
              swal("Completar el campo:", "Contraseña", "warning");
            axios
              .put(utility.url_modifica_departamento, {
                id_departamento:id,
                status:"B"
              })
              .then((response) => {
                if (response.status >= 200 && response.status < 400) {
                  if (this._isMounted) {
                    swal({
                      icon: "success",
                      title: response.data.title,
                      text: response.data.text,
                      button: true,
                    });
                    this.props.update(1);
                  }
                }
              })
              .catch((error) => {
                if (error.response)
                  message.message_modal(
                    error.response.data.title,
                    error.response.data.text,
                    error.response.status
                  );
              });
          }
        });
  };

  render() {
    return (
      <React.Fragment>
        <ConsultaEspecifica
          onBackTable={this.onBackTable}
          onChangeFiltrada={this.onChangeFiltrada}
        />
        <div className="overflow-auto tm-table">
          <Table size="sm" id="tabla_departamentos" bordered hover responsive>
            <thead className="text-center header-table">
              <tr>
                <td>ID Departamento</td>
                <td>Nombre</td>
                <td>Área</td>
                <td>Descripción</td>
                <td></td>
              </tr>
            </thead>
            <tbody className="body-table hover-gray">
              {this.state.pagination.results.length === 0 ? (
                <tr>
                  <td colSpan="12" align="center">
                    <Spinner
                      style={{ width: "10rem", height: "10rem" }}
                      color="danger"
                      type="grow"
                      className="align-self-center"
                    />
                  </td>
                </tr>
              ) : (
                <React.Fragment>
                  {this.state.pagination.results.map((departamento) => (
                    <tr key={departamento.id_departamento}>
                      <td>
                        <p className="mb-0 text-center">
                          {departamento.id_departamento}
                        </p>
                      </td>
                      <td>
                        <p className="mb-0 text-center">
                          {departamento.nombre}
                        </p>
                      </td>
                      <td>
                        <p className="mb-0 text-center">{departamento.area}</p>
                      </td>
                      <td>
                        <p className="mb-0 text-center">
                          {departamento.descripcion}
                        </p>
                      </td>
                      <td className="text-center">
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() => this.handleEliminar(departamento.id_departamento)}
                        >
                          Eliminar
                        </Button>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              )}
            </tbody>
          </Table>
        </div>
        <Paginacion
          actual={this.state.page_actual}
          count={this.state.pagination.count}
          paginate={this.handlePage}
        />
      </React.Fragment>
    );
  }
}
export default TableDepartamentos;
