/* 
Autor: Alexis Yael Hernández Grimaldo
Descripción: El componenete genera un bloque correspondiente a la busqueda dinamica en la tabla para acceder a la información.
*/
import React, { Component } from 'react';
import { Button, Collapse, Form, FormGroup } from 'reactstrap';
import '../../assets/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import MyInput from '../../utils/custom/MyInput';

class BusquedaEspecifica extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toogle_collapse: false,
        }
    }
    toogleCollapse = (e) => {
        this.setState(prevState => ({ toogle_collapse: !prevState.toogle_collapse }))
    }
    handleValida = (data) => {
        if (data.numero.length === 0)
            delete data.numero;
        if (data.fecha_alta_inicio.length > 0 && data.fecha_alta_final.length > 0)
            data['fechas'] = data.fecha_alta_inicio + "," + data.fecha_alta_final;
        else if (data.fecha_alta_inicio.length > 0 && data.fecha_alta_final.length === 0)
            data['fechas'] = data.fecha_alta_inicio + "," + data.fecha_alta_inicio;
        else if (data.fecha_alta_inicio.length === 0 && data.fecha_alta_final.length > 0)
            data['fechas'] = data.fecha_alta_final + "," + data.fecha_alta_final;
        else {
            delete data.fecha_alta_inicio
            delete data.fecha_alta_final
        }
        if (data.fecha_vencimiento_inicio.length > 0 && data.fecha_vencimiento_final.length > 0)
            data['fechas_vencimiento'] = data.fecha_inicio + "," + data.fecha_final;
        else if (data.fecha_vencimiento_inicio.length > 0 && data.fecha_vencimiento_final.length === 0)
            data['fechas_vencimiento'] = data.fecha_vencimiento_inicio + "," + data.fecha_vencimiento_inicio;
        else if (data.fecha_vencimiento_inicio.length === 0 && data.fecha_vencimiento_final.length > 0)
            data['fechas_vencimiento'] = data.fecha_vencimiento_final + "," + data.fecha_vencimiento_final;
        else {
            delete data.fecha_vencimiento_inicio
            delete data.fecha_vencimiento_final
        }
        if (data.cliente.length === 0)
            delete data.cliente;
        if (data.bancos.length === 0)
            delete data.bancos;
        if (data.documento.length === 0)
            delete data.documento;
        if (data.importe_inicio.length > 0 && data.importe_final.length > 0)
            data['importes'] = data.importe_inicio + "," + data.importe_final;
        else if (data.importe_inicio.length > 0 && data.importe_final.length === 0)
            data['importes'] = data.importe_inicio + "," + data.importe_inicio;
        else if (data.importe_inicio.length === 0 && data.importe_final.length > 0)
            data['importes'] = data.importe_final + "," + data.importe_final;
        else {
            delete data.importe_inicio
            delete data.importe_final
        }
    }
    handleFiltrada = () => {
        let data = {
            numero: document.getElementById('specific_numero').value,
            fecha_alta_inicio: document.getElementById('specific_fecha_anticipoI').value,
            fecha_alta_final: document.getElementById('specific_fecha_anticipoF').value,
            fecha_vencimiento_inicio: document.getElementById('specific_fechaV_anticipoI').value,
            fecha_vencimiento_final: document.getElementById('specific_fechaV_anticipoF').value,
            cliente: document.getElementById('specific_cliente').value,
            bancos: document.getElementById('specific_bancos').value,
            documento: document.getElementById('specific_documento').value,
            importe_inicio: document.getElementById('specific_importe_inicial_anticipo').value,
            importe_final: document.getElementById('specific_importe_final_anticipo').value,
        }
        this.handleValida(data)
        if (data.fecha_alta_inicio) {
            delete data.fecha_alta_final;
            delete data.fecha_alta_inicio;
        }
        if (data.fecha_vencimiento_inicio) {
            delete data.fecha_vencimiento_final;
            delete data.fecha_vencimiento_inicio;
        }
        if (data.importe_inicio) {
            delete data.importe_final;
            delete data.importe_inicio;
        }
        this.props.onChangeFiltrada(data);
    }
    render() {
        return (
            <React.Fragment>
                <div className="row mb-2 mx-1">
                    <h3 className="col-md-11 col-9 text-md-left px-0 mb-0">
                        Consulta Específica
                    </h3>
                    <Button
                        color="info"
                        onClick={this.toogleCollapse}
                        className="col-md-1 col-3">
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </div>
                <Collapse isOpen={this.state.toogle_collapse}>
                    <Form className="container-fluid">
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-3" id="specific_numero" nameLabel="Número de pago"
                                 />
                            <MyInput type="text" bootstrap="col-lg-3" id="specific_cliente" nameLabel="Número de cliente"
                                defaultValue={this.props.on_cliente !== false ? this.props.on_cliente : ""}
                                disabled={this.props.on_cliente !== false ? true : false}  />
                            <MyInput type="text" bootstrap="col-lg-3" id="specific_bancos" nameLabel="bancos"
                                 />
                            <MyInput type="text" bootstrap="col-lg-3" id="specific_documento" nameLabel="Documento"
                                 />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="date" bootstrap="col-lg-3" id="specific_fecha_anticipoI" nameLabel="Fecha Alta (Inicial)"
                                 />
                            <MyInput type="date" bootstrap="col-lg-3" id="specific_fecha_anticipoF" nameLabel="Fecha Alta (Final)"
                                 />
                            <MyInput type="text" bootstrap="col-lg-3" id="specific_importe_inicial_anticipo" nameLabel="Importe (Inicial)"
                                 />
                            <MyInput type="text" bootstrap="col-lg-3" id="specific_importe_final_anticipo" nameLabel="Importe (Final)"
                                 />
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="date" bootstrap="col-lg-3" id="specific_fechaV_anticipoI" nameLabel="Fecha Vencimiento (Inicial)"
                                 />
                            <MyInput type="date" bootstrap="col-lg-3" id="specific_fechaV_anticipoF" nameLabel="Fecha Vencimiento (Final)"
                                 />
                            <MyInput type="text" bootstrap="col-lg-6" id="specific_observaciones_anticipo" nameLabel="Observaciones"
                                 />
                        </FormGroup>
                        <FormGroup className="row d-flex justify-content-center mb-0">
                            <Button color="danger" className="mb-1" onClick={this.props.onBackTable}>Regresar</Button>
                            <Button color="success ml-2 mb-1" onClick={this.handleFiltrada}>Consultar</Button>
                        </FormGroup>
                    </Form>
                </Collapse>
            </React.Fragment>

        );
    }
}
export default BusquedaEspecifica;