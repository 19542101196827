import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Collapse,
  Card,
  CardBody,
  FormText,
  ListGroup,
  ListGroupItem,
  CustomInput,
  Badge,
  Spinner,
} from "reactstrap";
import MyInput from "../../../utils/custom/MyInput";
import MyTextArea from "../../../utils/custom/MyTextArea";
import CustomSelect from "../../../utils/custom/CustomSelect";
import WebSocketInstance from "../../../utils/websocket";
import * as message from "../../../utils/messages/message";
import * as utility from "../utils/utility";
import {
  faPlus,
  faEye,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "@sweetalert/with-react";
import * as permisos from "../../../utils/permisos/permisos";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalFactura extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      //VARIBLES PARA LA PARTE DE CUENTA DE GASTOS
      cuenta_gastos: "",
      numero_cliente_factura: "",
      nombre_cliente_factura: "",
      nombre_suggestions_factura: [],
      condicion_pago: "",
      forma_pago_text: "Seleccione una forma de pago",
      forma_pago_id: "",
      tipo_uso_text: "Seleccione un tipo",
      tipo_uso_id: "",
      comprobante_text: "Seleccione un tipo de comprobante",
      comprobante_id: "",
      metodo_pago_text: "Seleccione un metodo de pago",
      metodo_pago_id: "",
      movimientos_ingresos: [],
      movimientos_select: [],

      //VARIABLES PARA LA PARTE DE FACTURA
      factura: "",
      numero_cliente_factura_c: "",
      nombre_cliente_factura_c: "",
      nombre_suggestions_factura_c: [],
      metodo_pago_text_c: "Seleccione un metodo de pago",
      metodo_pago_id_c: "",
      forma_pago_text_c: "Seleccione una forma de pago",
      forma_pago_id_c: "",
      comprobante_text_c: "Seleccione un tipo de comprobante",
      comprobante_id_c: "",
      tipo_uso_text_c: "Seleccione un tipo",
      tipo_uso_id_c: "",
      condicion_pago_c: "",
      pago_id: "",
      pago_text: "Seleccione un pago",
      movimientos_factura: [],
      movimientos_factura_ingresos: [],
      movimientos_factura_ingresos_select: [],
      movimientos_pagos: [],
      uuid: "",
      fecha_validacion: "",
      sello_sat: "",
      sello_cfdi: "",
      data_factura: "",
      disabled_input: [true, true, true, true, true, true, true, true],

      toogle_alta: true,
      toogle_consulta: false,
      enable_pdf: true,

      metodos_pago: [
        { id: "PPD", text: "PAGO EN PARCIALIDADES O DIFERIDO" },
        { id: "PUE", text: "PAGO EN UNA SOLA EXHIBICIÓN" },
      ],
      comprobante_options: [
        { id: "I", text: "Ingreso" },
        { id: "E", text: "Egreso" },
        { id: "T", text: "Traslado" },
        { id: "P", text: "Pago" },
        { id: "N", text: "Nomina" },
      ],
    };
    this.toogleAlta = this.toogleAlta.bind(this);
    this.toogleConsulta = this.toogleConsulta.bind(this);
  }
  toogleAlta = () => {
    this.setState((prevState) => ({
      toogle_alta: !prevState.toogle_alta,
      toogle_consulta: false,
    }));
  };
  toogleConsulta = () => {
    this.setState((prevState) => ({
      toogle_consulta: !prevState.toogle_consulta,
      toogle_alta: false,
    }));
  };
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  /* --------------- Funciones que actualizan las variables de los inputs --------------- */
  //FUNCIONES PARA EL CAMBIO EN VARIABLES DE CUENTA DE GASTOS
  handleCuenta = (e) => {
    let cuenta_gastos = e.target.value;
    this.setState({ cuenta_gastos });
  };
  handleCondicion_pago = (e) => {
    let condicion_pago = e.target.value;
    this.setState({ condicion_pago });
  };
  handleMetodo_pago = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let metodo_pago_text = e[label];
    let metodo_pago_id = e[label_id];
    this.setState({ metodo_pago_text, metodo_pago_id });
  };
  handleForma_pago = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let forma_pago_text = e[label];
    let forma_pago_id = e[label_id];
    this.setState({ forma_pago_text, forma_pago_id });
  };
  handleTipo_comprobante = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let comprobante_text = e[label];
    let comprobante_id = e[label_id];
    this.setState({ comprobante_text, comprobante_id });
  };
  handleTipo_uso = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_uso_text = e[label];
    let tipo_uso_id = e[label_id];
    this.setState({ tipo_uso_text, tipo_uso_id });
  };
  handleCuenta_gastos = () => {
    this.methodPOST_API(
      utility.url_consulta_movimientos_ingresos,
      "consulta_movimientos",
      { cuenta: this.state.cuenta_gastos }
    );
  };
  handleAgrega_referencia = (e) => {
    let checked = e.target.checked;
    let data = JSON.parse(e.target.value);
    let movimientos_select = this.state.movimientos_select;
    let ingresos = [];
    if (checked) {
      data.pagados.forEach((pago) =>
        pago.ingresos.forEach((ingreso) => ingresos.push(ingreso))
      );
      movimientos_select.push({
        documento: data.documento,
        movimientos: ingresos,
      });
    } else {
      for (let i = 0; i < movimientos_select.length; i++)
        if (movimientos_select[i].documento === data.documento) {
          movimientos_select.splice(i, 1);
          break;
        }
    }
    this.setState({ movimientos_select });
  };

  //FUNCIONES PARA EL CAMBIO EN VARIABLES DE FACTURA
  handleFactura_id = (e) => {
    let factura = e.target.value;
    this.setState({ factura });
  };
  handleFactura = () => {
    this.methodPOST_API(utility.url_consulta_factura, "consulta_factura", {
      factura: this.state.factura,
    });
  };
  handleMetodo_pago_c = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let metodo_pago_text_c = e[label];
    let metodo_pago_id_c = e[label_id];
    this.setState({ metodo_pago_text_c, metodo_pago_id_c });
  };
  handleForma_pago_c = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let forma_pago_text_c = e[label];
    let forma_pago_id_c = e[label_id];
    this.setState({ forma_pago_text_c, forma_pago_id_c });
  };
  handleTipo_comprobante_c = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let comprobante_text_c = e[label];
    let comprobante_id_c = e[label_id];
    this.setState({ comprobante_text_c, comprobante_id_c });
  };
  handleTipo_uso_c = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_uso_text_c = e[label];
    let tipo_uso_id_c = e[label_id];
    this.setState({ tipo_uso_text_c, tipo_uso_id_c });
  };
  handleCondicion_pago_c = (e) => {
    let condicion_pago_c = e.target.value;
    this.setState({ condicion_pago_c });
  };
  /* --------------- Fin de funciones que actualizan las variables de los inputs --------------- */

  /* --------------- Funciones de eventos que generan una petición --------------- */
  handleAlta = () => {
    message
      .message_confimacion("¿Desea continuar con la operación alta?", "")
      .then((res) => {
        if (res) {
          let factura = {
            cuenta: this.state.cuenta_gastos,
            cliente_factura: this.state.numero_cliente_factura,
            tipo_comprobante: this.state.comprobante_id,
            condicion_pago: this.state.condicion_pago,
            metodo_pago: this.state.metodo_pago_id,
            forma_pago: this.state.forma_pago_id,
            tipo_uso: this.state.tipo_uso_id,
            movimientos: {
              agregados: [],
            },
          };
          this.state.movimientos_select.forEach((data) =>
            data.movimientos.forEach((mov) =>
              factura.movimientos.agregados.push(mov.id)
            )
          );
          if (utility.valida_data_alta_factura(factura).length > 0)
            message.message_modal(
              "Falta completar los siguientes campos:",
              utility.valida_data_alta_factura(factura),
              400
            );
          else this.methodPOST_API(utility.url_crea_factura, "alta", factura);
        }
      });
  };
  /* handleTimbrar = () => {
    message
      .message_confimacion("¿Desea continuar con la operación timbrar?", "")
      .then((res) => {
        if (res) {
          //FALTA CAMBIAR "LugarExpedicion"
          let data_factura = this.state.data_factura;
          let sub_total = 0;
          let total = 0;
          let conceptos = [];
          let otro = [];
          let impuestos = 0;
          let conceptos_string = "";
          let fecha_actual = new Date().toJSON().split(".")[0].split("T");
          let hora_actual = new Date()
            .toLocaleTimeString()
            .split(" ")[0]
            .split(":");
          if (parseInt(hora_actual) <= 9) hora_actual[0] = "0" + hora_actual[0];
          fecha_actual[1] =
            hora_actual[0] + ":" + hora_actual[1] + ":" + hora_actual[2];
          let fecha_actual_string = fecha_actual[0] + "T" + fecha_actual[1];
          data_factura.factura_ingreso.forEach((doc) =>
            doc.pagados.forEach((ingreso) =>
              ingreso.ingresos.forEach((mov) => {
                conceptos.push(mov);
                sub_total += parseFloat(mov.importe);
              })
            )
          );
          data_factura.factura_ingreso.forEach((doc) => (total += doc.total));
          for (let i = 0; i < conceptos.length; i++) {
            let cantidad = 0;
            for (let j = conceptos.length - 1; j >= 0; j--)
              if (conceptos[i].tipo_ingreso === conceptos[j].tipo_ingreso) {
                if (cantidad === 0) {
                  cantidad++;
                  otro.push({ cantidad: cantidad, ...conceptos[i] });
                } else {
                  if (conceptos[i].importe === conceptos[j].importe) {
                    cantidad++;
                    otro[otro.length - 1].cantidad = cantidad;
                    conceptos.splice(j, 1);
                  }
                }
              }
          }
          otro.forEach(
            (mov) => (impuestos += parseInt(mov.importe) * mov.cantidad * 0.16)
          );
          otro.forEach(
            (mov) =>
              (conceptos_string += `<cfdi:Concepto ClaveProdServ="80151605" Cantidad="${
                mov.cantidad
              }" ClaveUnidad="H87" Unidad="SERVICIO" Descripcion="${
                mov.concepto
              }" ValorUnitario="${mov.importe}" Importe="${
                parseInt(mov.importe) * mov.cantidad
              }" ObjetoImp="02" >
                            <cfdi:Impuestos>
                            <cfdi:Traslados>
                                <cfdi:Traslado Base="${
                                  parseInt(mov.importe) * mov.cantidad
                                }" Importe="${
                parseInt(mov.importe) * mov.cantidad * 0.16
              }" Impuesto="002" TasaOCuota="0.160000" TipoFactor="Tasa"/>
                            </cfdi:Traslados>
                            </cfdi:Impuestos>
                        </cfdi:Concepto>`)
          );
          let XML = `<?xml version="1.0" encoding="UTF-8"?>
                        <cfdi:Comprobante xsi:schemaLocation="http://www.sat.gob.mx/cfd/4 http://www.sat.gob.mx/sitio_internet/cfd/4/cfdv40.xsd" Version="4.0" Serie="${data_factura.folio.slice(
                          5
                        )}" Folio="${data_factura.folio.slice(
            0,
            5
          )}"  MetodoPago="${
            data_factura.metodo_pago
          }" Fecha="${fecha_actual_string}" FormaPago="${
            data_factura.forma_pago
          }" LugarExpedicion="15520" ${
            data_factura.condicion_pago !== null
              ? `CondicionesDePago="${data_factura.condicion_pago}"`
              : ""
          } SubTotal="${sub_total}" Moneda="MXN" Total="${
            total + impuestos
          }" TipoDeComprobante="${
            data_factura.tipo_comprobante
          }" Exportacion="01" xmlns:cfdi="http://www.sat.gob.mx/cfd/4" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
                            <cfdi:Emisor Rfc="${
                              data_factura.emisor.rfc
                            }" Nombre="ARANCEL" RegimenFiscal="${
            data_factura.emisor.regimen_fiscal
          }" />
                            <cfdi:Receptor Rfc="${
                              data_factura.receptor.rfc
                            }" Nombre="${
            data_factura.receptor.nombre
          }" DomicilioFiscalReceptor="${
            data_factura.receptor.direccion
          }" RegimenFiscalReceptor="${
            data_factura.receptor.regimen_fiscal
          }" UsoCFDI="${data_factura.tipo_uso}" />
                            <cfdi:Conceptos>
                                ${conceptos_string}
                            </cfdi:Conceptos>
                            <cfdi:Impuestos TotalImpuestosTrasladados="${impuestos}">                       
                                <cfdi:Traslados>
                                    <cfdi:Traslado Base="${sub_total}" Importe="${impuestos}" Impuesto="002" TasaOCuota="0.160000" TipoFactor="Tasa"/>
                                </cfdi:Traslados>
                            </cfdi:Impuestos>
                        </cfdi:Comprobante> `;
          let XML_64 = btoa(XML);
          swal({
            title: "Timbrando factura",
            text: "No cerrar o actualizar la página",
            buttons: false,
            closeOnClickOutside: false,
            content: (
              <div>
                <Spinner
                  color="success"
                  style={{
                    width: "5rem",
                    height: "5rem",
                    marginBottom: "26px",
                    animation: "1.25s linear infinite spinner-border",
                  }}
                >
                  Loading...
                </Spinner>
              </div>
            ),
          });
          axios({
            method: "POST",
            url: "/documents",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJxVjdJbWt4VGZ4T3dMc0tTVEM2bkpHZEdKa2dsWDB6V2p1YmlXRDhvIiwic3ViIjoiQXJhbmNlbCBbU1RBR0VdIiwiaXNzIjoiRU1JVEUgW1NUQUdFXSIsImF1ZCI6IkJFTkdBTEEgW1NUQUdFXSJ9.n6t6u_BbzK_aVZt6c2ncXa6WgJzSQ5B56A8akkiV1uo",
            },
            data: {
              xml: XML_64,
              return_method: "base64",
            },
          })
            .then((response) => {
              let data = response.data.data;
              let document_info = {
                xml: atob(data.document_info.xml),
                certificate: data.document_info.certificate,
                signature: data.document_info.signature,
                issuer: data.document_info.issuer,
                recipient: data.document_info.recipient,
                serie: data.document_info.serie,
                folio: data.document_info.folio,
                total: data.document_info.total,
                created_at: data.document_info.created_at,
                fingerprint: data.document_info.fingerprint,
              };
              let stamp_info = {
                type: data.stamp_info.type,
                xml: atob(data.stamp_info.xml),
                uuid: data.stamp_info.uuid,
                signature: data.stamp_info.signature,
                created_at: data.stamp_info.created_at,
                certificate: data.stamp_info.certificate,
                provider: data.stamp_info.provider,
                legend: data.stamp_info.legend,
                original_chain: data.stamp_info.original_chain,
                validation_url: data.stamp_info.validation_url,
              };
              let request_id = response.data.request_id;
              this.methodPUT_API(utility.url_crea_factura, "timbrar", {
                folio: this.state.factura,
                uuid: stamp_info.uuid,
                validacion_url: stamp_info.validation_url,
                certificado_csd: document_info.certificate,
                certificado_sat: stamp_info.certificate,
                rfc_proveedor: stamp_info.provider,
                sello_sat: stamp_info.signature,
                sello_cfdi: document_info.signature,
                cadena_original: stamp_info.original_chain,
                fecha_validacion: document_info.created_at,
                xml: document_info.xml,
              });
            })
            .catch((error) => {
              if (error.response) {
                let errores = error.response.data.data.details;
                let text = "";
                errores.forEach(
                  (error) =>
                    (text += error.code + ": " + error.description + "\n\n")
                );
                message.message_modal(
                  "Error al timbrar",
                  text.slice(0, text.length - 2),
                  error.response.status
                );
              }
            });
        }
      });
  }; */


  handleTimbrar = () => {
    message
      .message_confimacion("¿Desea continuar con la operación timbrar?", "")
      .then((res) => {
        if (res) {
          //FALTA CAMBIAR "LugarExpedicion"
          let data_factura = this.state.data_factura;
          let sub_total = 0;
          let total = 0;
          let conceptos = [];
          let otro = [];
          let impuestos = 0;
          let conceptos_string = "";
          let fecha_actual = new Date().toJSON().split(".")[0].split("T");
          let hora_actual = new Date()
            .toLocaleTimeString()
            .split(" ")[0]
            .split(":");
          if (parseInt(hora_actual) <= 9) hora_actual[0] = "0" + hora_actual[0];
          fecha_actual[1] =
            hora_actual[0] + ":" + hora_actual[1] + ":" + hora_actual[2];
          let fecha_actual_string = fecha_actual[0] + "T" + fecha_actual[1];
          data_factura.factura_ingreso.forEach((doc) =>
            doc.pagados.forEach((ingreso) =>
              ingreso.ingresos.forEach((mov) => {
                conceptos.push(mov);
                sub_total += parseFloat(mov.importe);
              })
            )
          );
          data_factura.factura_ingreso.forEach((doc) => (total += doc.total));
          for (let i = 0; i < conceptos.length; i++) {
            let cantidad = 0;
            for (let j = conceptos.length - 1; j >= 0; j--)
              if (conceptos[i].tipo_ingreso === conceptos[j].tipo_ingreso) {
                if (cantidad === 0) {
                  cantidad++;
                  otro.push({ cantidad: cantidad, ...conceptos[i] });
                } else {
                  if (conceptos[i].importe === conceptos[j].importe) {
                    cantidad++;
                    otro[otro.length - 1].cantidad = cantidad;
                    conceptos.splice(j, 1);
                  }
                }
              }
          }
          otro.forEach(
            (mov) => (impuestos += parseInt(mov.importe) * mov.cantidad * 0.16)
          );
          otro.forEach(
            (mov) =>
              (conceptos_string += `<cfdi:Concepto ClaveProdServ="80151605" Cantidad="${
                mov.cantidad
              }" ClaveUnidad="H87" Unidad="SERVICIO" Descripcion="${
                mov.concepto
              }" ValorUnitario="${mov.importe}" Importe="${
                parseInt(mov.importe) * mov.cantidad
              }" ObjetoImp="02" >
                            <cfdi:Impuestos>
                            <cfdi:Traslados>
                                <cfdi:Traslado Base="${
                                  parseInt(mov.importe) * mov.cantidad
                                }" Importe="${
                parseInt(mov.importe) * mov.cantidad * 0.16
              }" Impuesto="002" TasaOCuota="0.160000" TipoFactor="Tasa"/>
                            </cfdi:Traslados>
                            </cfdi:Impuestos>
                        </cfdi:Concepto>`)
          );
          let XML = `<?xml version="1.0" encoding="UTF-8"?>
                        <cfdi:Comprobante xsi:schemaLocation="http://www.sat.gob.mx/cfd/4 http://www.sat.gob.mx/sitio_internet/cfd/4/cfdv40.xsd" Version="4.0" Serie="${data_factura.folio.slice(
                          5
                        )}" Folio="${data_factura.folio.slice(
            0,
            5
          )}"  MetodoPago="${
            data_factura.metodo_pago
          }" Fecha="${fecha_actual_string}" FormaPago="${
            data_factura.forma_pago
          }" LugarExpedicion="15520" ${
            data_factura.condicion_pago !== null
              ? `CondicionesDePago="${data_factura.condicion_pago}"`
              : ""
          } SubTotal="${sub_total}" Moneda="MXN" Total="${
            total + impuestos
          }" TipoDeComprobante="${
            data_factura.tipo_comprobante
          }" Exportacion="01" xmlns:cfdi="http://www.sat.gob.mx/cfd/4" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
                            <cfdi:Emisor Rfc="${
                              data_factura.emisor.rfc
                            }" Nombre="ARANCEL" RegimenFiscal="${
            data_factura.emisor.regimen_fiscal
          }" />
                            <cfdi:Receptor Rfc="${
                              data_factura.receptor.rfc
                            }" Nombre="${
            data_factura.receptor.nombre
          }" DomicilioFiscalReceptor="${
            data_factura.receptor.direccion
          }" RegimenFiscalReceptor="${
            data_factura.receptor.regimen_fiscal
          }" UsoCFDI="${data_factura.tipo_uso}" />
                            <cfdi:Conceptos>
                                ${conceptos_string}
                            </cfdi:Conceptos>
                            <cfdi:Impuestos TotalImpuestosTrasladados="${impuestos}">                       
                                <cfdi:Traslados>
                                    <cfdi:Traslado Base="${sub_total}" Importe="${impuestos}" Impuesto="002" TasaOCuota="0.160000" TipoFactor="Tasa"/>
                                </cfdi:Traslados>
                            </cfdi:Impuestos>
                        </cfdi:Comprobante> `;
          let XML_64 = btoa(XML);
          swal({
            title: "Timbrando factura",
            text: "No cerrar o actualizar la página",
            buttons: false,
            closeOnClickOutside: false,
            content: (
              <div>
                <Spinner
                  color="success"
                  style={{
                    width: "5rem",
                    height: "5rem",
                    marginBottom: "26px",
                    animation: "1.25s linear infinite spinner-border",
                  }}
                >
                  Loading...
                </Spinner>
              </div>
            ),
          });
          axios({
            method: "POST",
            url: "/documents",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJxVjdJbWt4VGZ4T3dMc0tTVEM2bkpHZEdKa2dsWDB6V2p1YmlXRDhvIiwic3ViIjoiQXJhbmNlbCBbU1RBR0VdIiwiaXNzIjoiRU1JVEUgW1NUQUdFXSIsImF1ZCI6IkJFTkdBTEEgW1NUQUdFXSJ9.n6t6u_BbzK_aVZt6c2ncXa6WgJzSQ5B56A8akkiV1uo",
            },
            data: {
              xml: XML_64,
              return_method: "base64",
            },
          })
            .then((response) => {
              let data = response.data.data;
              let document_info = {
                xml: atob(data.document_info.xml),
                certificate: data.document_info.certificate,
                signature: data.document_info.signature,
                issuer: data.document_info.issuer,
                recipient: data.document_info.recipient,
                serie: data.document_info.serie,
                folio: data.document_info.folio,
                total: data.document_info.total,
                created_at: data.document_info.created_at,
                fingerprint: data.document_info.fingerprint,
              };
              let stamp_info = {
                type: data.stamp_info.type,
                xml: atob(data.stamp_info.xml),
                uuid: data.stamp_info.uuid,
                signature: data.stamp_info.signature,
                created_at: data.stamp_info.created_at,
                certificate: data.stamp_info.certificate,
                provider: data.stamp_info.provider,
                legend: data.stamp_info.legend,
                original_chain: data.stamp_info.original_chain,
                validation_url: data.stamp_info.validation_url,
              };
              let request_id = response.data.request_id;
              this.methodPUT_API(utility.url_crea_factura, "timbrar", {
                folio: this.state.factura,
                uuid: stamp_info.uuid,
                validacion_url: stamp_info.validation_url,
                certificado_csd: document_info.certificate,
                certificado_sat: stamp_info.certificate,
                rfc_proveedor: stamp_info.provider,
                sello_sat: stamp_info.signature,
                sello_cfdi: document_info.signature,
                cadena_original: stamp_info.original_chain,
                fecha_validacion: document_info.created_at,
                xml: document_info.xml,
              });
            })
            .catch((error) => {
              if (error.response) {
                let errores = error.response.data.data.details;
                let text = "";
                errores.forEach(
                  (error) =>
                    (text += error.code + ": " + error.description + "\n\n")
                );
                message.message_modal(
                  "Error al timbrar",
                  text.slice(0, text.length - 2),
                  error.response.status
                );
              }
            });
        }
      });
  };

  
  /* --------------- Fin de funciones de eventos que generan una petición --------------- */
  handleCerrar = () => {
    this.setState({
      cuenta_gastos: "",
      numero_cliente: "",
      nombre_cliente: "",
      condicion_pago: "",
      forma_pago_text: "Seleccione una forma de pago",
      forma_pago_id: "",
      tipo_uso_text: "Seleccione un tipo",
      tipo_uso_id: "",
      comprobante_text: "Seleccione un tipo de comprobante",
      comprobante_id: "",
      comprobante_options: [
        { id: "I", text: "Ingreso" },
        { id: "E", text: "Egreso" },
        { id: "T", text: "Traslado" },
        { id: "P", text: "Pago" },
        { id: "N", text: "Nomina" },
      ],
      metodo_pago_text: "Seleccione un metodo de pago",
      metodo_pago_id: "",
      metodos_pago: [
        { id: "PPD", text: "PAGO EN PARCIALIDADES O DIFERIDO" },
        { id: "PUE", text: "PAGO EN UNA SOLA EXHIBICIÓN" },
      ],

      movimientos_ingresos: [],

      toogle_alta: true,
      toogle_consulta: false,
    });
    this.props.toogleFactura();
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta_movimientos":
              this.setState({ movimientos_ingresos: response.data.results });
              break;
            case "consulta_movimientos_select":
              this.setState({
                movimientos_factura_ingresos: response.data.results,
              });
              break;
            case "alta":
              this.handleCerrar();
              this.methodPDF_API(
                utility.url_consulta_pdf_factura,
                "alta_factura",
                response.data.results.folio
              );
              swal({
                icon: "success",
                title: response.data.title,
                text: response.data.text,
                button: true,
                content: (
                  <div>
                    <LinkD
                      href=""
                      id="dowload_pdf_alta_factura"
                      download={`Factura - ${response.data.results.factura}`}
                    />
                    <br />
                    <LinkV
                      href=""
                      id="window_pdf_alta_factura"
                      target="_blank"
                    />
                  </div>
                ),
              });
              this.props.update(1);
              break;
            case "consulta_factura":
              this.methodPDF_API(
                utility.url_consulta_pdf_factura,
                "consulta_factura",
                this.state.factura
              );
              this.setState(
                {
                  numero_cliente_factura_c:
                    response.data.results.cliente_factura,
                  nombre_cliente_factura_c: "",
                  metodo_pago_id_c: response.data.results.metodo_pago,
                  metodo_pago_text_c: utility.get_text_by_id(
                    response.data.results.metodo_pago,
                    "id",
                    "text",
                    this.state.metodos_pago
                  ),
                  forma_pago_id_c: response.data.results.forma_pago,
                  forma_pago_text_c: utility.get_text_by_id(
                    response.data.results.forma_pago,
                    "clave",
                    "descripcion",
                    this.props.formas_pagos
                  ),
                  comprobante_id_c: response.data.results.tipo_comprobante,
                  comprobante_text_c: utility.get_text_by_id(
                    response.data.results.tipo_comprobante,
                    "id",
                    "text",
                    this.state.comprobante_options
                  ),
                  tipo_uso_id_c: response.data.results.tipo_uso,
                  tipo_uso_text_c: utility.get_text_by_id(
                    response.data.results.tipo_uso,
                    "clave",
                    "descripcion",
                    this.props.tipos_usos
                  ),
                  condicion_pago_c:
                    response.data.results.condicion_pago === null
                      ? ""
                      : response.data.results.condicion_pago,
                  movimientos_factura: response.data.results.factura_ingreso,
                  data_factura: response.data.results,
                  uuid: response.data.results.uuid,
                  fecha_validacion: response.data.results.fecha_validacion,
                  sello_cfdi: response.data.results.sello_cfdi,
                  sello_sat: response.data.results.sello_sat,
                  status: response.data.results.status,
                  enable_pdf: false,
                  disabled_input:
                    response.data.results.status === "F"
                      ? [true, true, true, true, true, true, true]
                      : [false, false, false, false, false, false, false],
                },
                () => {
                  this.methodPOST_API(
                    utility.url_consulta_movimientos_ingresos,
                    "consulta_movimientos_select",
                    { cuenta: response.data.results.cuenta }
                  );
                }
              );
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPUT_API = (url, state, data) => {
    let url_intern = url;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "timbrar":
              swal.close();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPDF_API = (url, state, num) => {
    let url_intern = url + `${num}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta_factura":
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);
              document.getElementById("dowload_pdf_alta_factura").href =
                fileURL;
              document.getElementById("window_pdf_alta_factura").href = fileURL;
              break;
            case "consulta_factura":
              const file_pagos = new Blob([response.data], {
                type: "application/pdf",
              });
              const file_pagos_URL = URL.createObjectURL(file_pagos);
              document.getElementById("dowload_pdf_consulta_factura").href =
                file_pagos_URL;
              document.getElementById("window_pdf_consulta_factura").href =
                file_pagos_URL;
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") text_add = "";
    else if (numero_cliente.length <= 4) {
      switch (numero_cliente.length) {
        case 1:
          text_add = "000";
          text_add += numero_cliente;
          break;
        case 2:
          text_add = "00";
          text_add += numero_cliente;
          break;
        case 3:
          text_add = "0";
          text_add += numero_cliente;
          break;
        case 4:
          text_add = numero_cliente;
          break;
        default:
          return null;
      }
    } else text_add = "";
    for (let i = 0; i < this.props.clientes.length; i++) {
      if (text_add.length === 0) {
        document.getElementById("nombre_cliente_factura_alta").value = "";
        this.suggestionSelected("");
        break;
      }
      if (text_add === this.props.clientes[i].numero) {
        document.getElementById("nombre_cliente_factura_alta").value =
          this.props.clientes[i].nombre;
        this.suggestionSelected(this.props.clientes[i].nombre);
        break;
      }
    }
  };
  suggestionSelected = (value) => {
    let number = utility.get_number_cliente(value, this.props.clientes);
    if (number === undefined) number = "";
    document.getElementById("numero_cliente_factura_alta").value = number;
    this.setState({
      nombre_cliente_factura: value,
      numero_cliente_factura: number,
      nombre_suggestions_factura: [],
    });
  };
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^ ${value} `, "i");
      suggestions = utility
        .get_nombres_clientes(this.props.clientes)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      nombre_suggestions_factura: suggestions,
      nombre_cliente_factura: value,
    });
  };
  renderSuggestion = () => {
    if (this.state.nombre_suggestions_factura.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator_c = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") text_add = "";
    else if (numero_cliente.length <= 4) {
      switch (numero_cliente.length) {
        case 1:
          text_add = "000";
          text_add += numero_cliente;
          break;
        case 2:
          text_add = "00";
          text_add += numero_cliente;
          break;
        case 3:
          text_add = "0";
          text_add += numero_cliente;
          break;
        case 4:
          text_add = numero_cliente;
          break;
        default:
          return null;
      }
    } else text_add = "";
    for (let i = 0; i < this.props.clientes.length; i++) {
      if (text_add.length === 0) {
        document.getElementById("nombre_cliente_factura_consulta").value = "";
        this.suggestionSelected_c("");
        break;
      }
      if (text_add === this.props.clientes[i].numero) {
        document.getElementById("nombre_cliente_factura_consulta").value =
          this.props.clientes[i].nombre;
        this.suggestionSelected_c(this.props.clientes[i].nombre);
        break;
      }
    }
  };
  suggestionSelected_c = (value) => {
    let number = utility.get_number_cliente(value, this.props.clientes);
    if (number === undefined) number = "";
    document.getElementById("numero_cliente_factura_consulta").value = number;
    this.setState({
      nombre_cliente_factura_c: value,
      numero_cliente_factura_c: number,
      nombre_suggestions_factura_c: [],
    });
  };
  onNameChange_c = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^ ${value} `, "i");
      suggestions = utility
        .get_nombres_clientes(this.props.clientes)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      nombre_suggestions_factura_c: suggestions,
      nombre_cliente_factura_c: value,
    });
  };
  renderSuggestion_c = () => {
    if (this.state.nombre_suggestions_factura_c.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions_c.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected_c(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_factura}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>Facturación</ModalHeader>
        <ModalBody>
          <div className="row mb-2 d-flex justify-content-center">
            <div className="col-lg-10 col-12 d-flex justify-content-around">
              <Button color="primary" onClick={this.toogleAlta}>
                Alta Factura
              </Button>
              <Button color="info" onClick={this.toogleConsulta}>
                Consulta Factura
              </Button>
            </div>
          </div>
          <Collapse isOpen={this.state.toogle_alta}>
            <Card outline color="primary">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-10 col-8"
                      id="cuenta_factura_alta"
                      nameLabel="Cuenta de Gastos"
                      value={this.state.cuenta_gastos}
                      onChange={this.handleCuenta}
                    />
                    <Button
                      color="primary"
                      className="col-lg-2 col-4 mb-2"
                      onClick={this.handleCuenta_gastos}
                    >
                      {/* <FontAwesomeIcon icon={faPlus} /> */}
                      Consulta
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="numero_cliente_factura_alta"
                      onChange={this.nameGenerator}
                      value={this.state.numero_cliente_factura}
                      nameLabel="Número de Cliente (Factura)"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="nombre_cliente_factura_alta"
                      onChange={this.onNameChange}
                      value={this.state.nombre_cliente_factura}
                      nameLabel="Nombre de Cliente (Factura)"
                    />
                    {this.renderSuggestion()}
                  </FormGroup>
                </Form>
                <Table size="sm" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>Documento</td>
                      <td>Tipo</td>
                      <td>Referencia</td>
                      <td>Movimiento</td>
                      <td>Total + IVA</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.movimientos_ingresos.map((mov, index) => {
                      return (
                        <React.Fragment>
                          <tr>
                            <td
                              className="text-center"
                              rowSpan={mov.pagados.length}
                            >
                              {mov.documento}
                            </td>
                            <td
                              className="text-center"
                              rowSpan={mov.pagados.length}
                            >
                              {mov.tipo}
                            </td>
                            <td className="text-center">
                              {mov.pagados[0].referencia}
                            </td>
                            <td className="text-center">
                              <p className="mb-0 text-center text-white">-</p>
                              {mov.pagados[0].ingresos.map((ingreso) => (
                                <p className="mb-0 text-center">
                                  {ingreso.tipo_ingreso}
                                </p>
                              ))}
                            </td>
                            <td
                              className="text-center"
                              rowSpan={mov.pagados.length}
                            >
                              $ {mov.total}
                            </td>
                            <td rowSpan={mov.pagados.length}>
                              <div className="d-flex justify-content-center my-auto">
                                <input
                                  type="checkbox"
                                  id={`ref_${index} `}
                                  value={JSON.stringify(mov)}
                                  onChange={this.handleAgrega_referencia}
                                />
                                <label
                                  htmlFor={`ref_${index} `}
                                  className="check-circle"
                                  style={{ width: "30px" }}
                                ></label>
                              </div>
                            </td>
                          </tr>
                          {mov.pagados.map((mov, index) => {
                            if (index > 0)
                              return (
                                <tr>
                                  <td className="text-center">
                                    {mov.referencia}
                                  </td>
                                  <td className="text-center">
                                    <p className="mb-0 text-center text-white">
                                      -
                                    </p>
                                    {mov.ingresos.map((ingreso) => (
                                      <p className="mb-0 text-center">
                                        {ingreso.tipo_ingreso}
                                      </p>
                                    ))}
                                  </td>
                                </tr>
                              );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <CustomSelect
                      bootstrap="col-lg-4 col-12 mb-2"
                      name_text="text"
                      name_index="id"
                      id="meotodo_pago_alta_factura"
                      nameLabel="Metodo de Pago"
                      options={this.state.metodos_pago}
                      value={this.state.metodo_pago_text}
                      onChange={this.handleMetodo_pago}
                    />
                    <CustomSelect
                      bootstrap="col-lg-4 col-12 mb-2"
                      name_text="descripcion"
                      name_index="clave"
                      id="forma_pago_alta_factura"
                      nameLabel="Forma de Pago"
                      options={this.props.formas_pagos}
                      value={this.state.forma_pago_text}
                      onChange={this.handleForma_pago}
                    />
                    <CustomSelect
                      bootstrap="col-lg-4 col-12 mb-2"
                      name_text="text"
                      name_index="id"
                      id="forma_pago_alta_factura"
                      nameLabel="Tipo comprobante"
                      options={this.state.comprobante_options}
                      value={this.state.comprobante_text}
                      onChange={this.handleTipo_comprobante}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <CustomSelect
                      bootstrap="col-lg-6 col-12 mb-2"
                      name_text="descripcion"
                      name_index="clave"
                      id="tipo_uso_alta_factura"
                      nameLabel="Tipo de uso"
                      options={this.props.tipos_usos}
                      value={this.state.tipo_uso_text}
                      onChange={this.handleTipo_uso}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="condicion_factura_alta"
                      nameLabel="Condiciones de pago"
                      value={this.state.condicion_pago}
                      onChange={this.handleCondicion_pago}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      type="button"
                      color="success"
                      className="mr-2"
                      onClick={this.handleAlta}
                    >
                      Aceptar
                    </Button>
                    <Button
                      type="button"
                      color="info"
                      onClick={this.handleCerrar}
                    >
                      Cerrar
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_consulta}>
            <Card outline color="info">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-10 col-8"
                      id="numero_factura_consulta"
                      nameLabel="Factura"
                      value={this.state.factura}
                      onChange={this.handleFactura_id}
                    />
                    <Button
                      color="primary"
                      className="col-lg-2 col-4 mb-2"
                      onClick={this.handleFactura}
                    >
                      Consulta
                    </Button>
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="numero_cliente_factura_consulta"
                      disabled={this.state.disabled_input[0]}
                      onChange={this.nameGenerator_c}
                      value={this.state.numero_cliente_factura_c}
                      nameLabel="Número de Cliente (Factura)"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="nombre_cliente_factura_consulta"
                      disabled={this.state.disabled_input[1]}
                      onChange={this.onNameChange_c}
                      value={this.state.nombre_cliente_factura_c}
                      nameLabel="Nombre de Cliente (Factura)"
                    />
                    {this.renderSuggestion_c()}
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <CustomSelect
                      bootstrap="col-lg-4 col-12 mb-2"
                      name_text="text"
                      name_index="id"
                      id="meotodo_pago_alta_factura"
                      nameLabel="Metodo de Pago"
                      options={this.state.metodos_pago}
                      value={this.state.metodo_pago_text_c}
                      onChange={this.handleMetodo_pago_c}
                      disabled={this.state.disabled_input[2]}
                    />
                    <CustomSelect
                      bootstrap="col-lg-4 col-12 mb-2"
                      name_text="descripcion"
                      name_index="clave"
                      id="forma_pago_alta_factura"
                      nameLabel="Forma de Pago"
                      options={this.props.formas_pagos}
                      value={this.state.forma_pago_text_c}
                      onChange={this.handleForma_pago_c}
                      disabled={this.state.disabled_input[3]}
                    />
                    <CustomSelect
                      bootstrap="col-lg-4 col-12 mb-2"
                      name_text="text"
                      name_index="id"
                      id="forma_pago_alta_factura"
                      nameLabel="Tipo comprobante"
                      options={this.state.comprobante_options}
                      value={this.state.comprobante_text_c}
                      onChange={this.handleTipo_comprobante_c}
                      disabled={this.state.disabled_input[4]}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <CustomSelect
                      bootstrap="col-lg-6 col-12 mb-2"
                      name_text="descripcion"
                      name_index="clave"
                      id="tipo_uso_alta_factura"
                      nameLabel="Tipo de uso"
                      options={this.props.tipos_usos}
                      value={this.state.tipo_uso_text_c}
                      onChange={this.handleTipo_uso_c}
                      disabled={this.state.disabled_input[5]}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="condicion_factura_consulta"
                      nameLabel="Condiciones de pago"
                      value={this.state.condicion_pago_c}
                      onChange={this.handleCondicion_pago_c}
                      disabled={this.state.disabled_input[6]}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <CustomSelect
                      bootstrap="col-lg-10 col-8 mb-2"
                      name_text="text"
                      name_index="id"
                      id="pagos_alta_factura"
                      nameLabel="Pagos disponibles"
                      options={this.state.movimientos_pagos}
                      value={this.state.pago_text}
                      onChange={this.handleTipo_uso_c}
                      disabled={this.state.disabled_input[7]}
                    />
                    <Button color="primary" className="col-lg-2 col-4 mb-2">
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </FormGroup>
                </Form>
                <Table size="sm" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>Documento</td>
                      <td>Tipo</td>
                      <td>Referencia</td>
                      <td>Movimiento</td>
                      <td>Total + IVA</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    {this.state.movimientos_factura.map((mov, index) => {
                      return (
                        <React.Fragment>
                          <tr>
                            <td
                              className="text-center"
                              rowSpan={mov.pagados.length}
                            >
                              {mov.documento}
                            </td>
                            <td
                              className="text-center"
                              rowSpan={mov.pagados.length}
                            >
                              {mov.tipo}
                            </td>
                            <td className="text-center">
                              {mov.pagados[0].referencia}
                            </td>
                            <td className="text-center">
                              <p className="mb-0 text-center text-white">-</p>
                              {mov.pagados[0].ingresos.map((ingreso) => (
                                <p className="mb-0 text-center">
                                  {ingreso.tipo_ingreso}
                                </p>
                              ))}
                            </td>
                            <td
                              className="text-center"
                              rowSpan={mov.pagados.length}
                            >
                              $ {mov.total}
                            </td>
                            <td rowSpan={mov.pagados.length}></td>
                          </tr>
                          {mov.pagados.map((mov, index) => {
                            if (index > 0)
                              return (
                                <tr>
                                  <td className="text-center">
                                    {mov.referencia}
                                  </td>
                                  <td className="text-center">
                                    <p className="mb-0 text-center text-white">
                                      -
                                    </p>
                                    {mov.ingresos.map((ingreso) => (
                                      <p className="mb-0 text-center">
                                        {ingreso.tipo_ingreso}
                                      </p>
                                    ))}
                                  </td>
                                </tr>
                              );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="uuid_factura_consulta"
                      value={this.state.uuid}
                      nameLabel="UUID"
                      disabled="true"
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6"
                      id="fecha_factura_consulta"
                      value={this.state.fecha_validacion}
                      nameLabel="Fecha de validación"
                      disabled="true"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyTextArea
                      bootstrap="col-12"
                      style={{ fontSize: "12px" }}
                      value={this.state.sello_cfdi}
                      nameLabel="Sello Digital del CFDI"
                      disabled="true"
                    />
                    <MyTextArea
                      bootstrap="col-12"
                      style={{ fontSize: "12px" }}
                      value={this.state.sello_sat}
                      nameLabel="Sello Digital del SAT"
                      disabled="true"
                    />
                  </FormGroup>
                  <div className="d-flex justify-content-center mb-2">
                    <LinkD
                      href="#"
                      id="dowload_pdf_consulta_factura"
                      target="_self"
                      download={`Factura ${this.state.factura}`}
                    />
                    <LinkV
                      href="#"
                      id="window_pdf_consulta_factura"
                      target={this.state.enable_pdf ? "_self" : "_blank"}
                      className="ml-5"
                    />
                  </div>
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      type="button"
                      color="danger"
                      className="mr-2"
                      disabled={permisos.obtener_permiso_especifico(
                        "cancelar",
                        this.props.permisos
                      )}
                    >
                      Cancelar
                    </Button>
                    <Button
                      type="button"
                      color="success"
                      className="mr-2"
                      onClick={this.handleTimbrar}
                    >
                      Timbrar
                    </Button>
                    <Button
                      type="button"
                      color="info"
                      onClick={this.handleCerrar}
                    >
                      Cerrar
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
        </ModalBody>
      </Modal>
    );
  }
}
export default ModalFactura;
