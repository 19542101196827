/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la consulta de un anticipo. 
*/
import axios from 'axios';
import React, { Component } from 'react';
import { Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as message from '../messages/messages';
import MyInput from '../utils/MyInput';
import MySelect from '../utils/MySelect';
import MyTextArea from '../utils/MyTextArea';
import * as utility from '../utils/utility';
import * as permisos from '../../../utils/permisos/permisos';
import WebSocketInstance from "../../../utils/websocket";

class ModalConsulta extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            departamentos: [],
            id_departamento: "",
            descripcion: "",
            puesto: "",
            input_active: [true, true, true],
            data_modificacion: {},
        };
    }
    /*--------------------------------------------------------------------- */
    /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
    sendNotificacionHandler = (motivo) => {
        let folio = this.state.puesto;
        const notificacionObject = {
            tipo: 0,
            aplicacion: 'puesto',
            motivo: motivo,
            folio: [folio]
        }
        WebSocketInstance.newNotificacion(notificacionObject);
    }
    /*--------------------------------------------------------------------- */
    componentDidMount() {
        this._isMounted = true;
        this.methodGET_API(utility.url_departamentos, 'departamentos');
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    clearState = () => {
        this.setState({
            id_departamento: "",
            descripcion: "",
            puesto: "",
            input_active: [true, true, true],
            data_modificacion: {},
        })
    }
    closeWindow = () => {
        this.clearState();
        this.props.toogleConsulta();
    }
    handlePuesto = (e) => {
        let puesto = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['puesto'] = puesto;
        this.setState({ puesto, data_modificacion });
    }
    handleDepartamento = (e) => {
        let id_departamento = parseInt(e.target.value);
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['id_departamento'] = id_departamento;
        this.setState({ id_departamento, data_modificacion });
    }
    handleDescripcion = (e) => {
        let descripcion = e.target.value;
        let data_modificacion = this.state.data_modificacion;
        data_modificacion['descripcion'] = descripcion;
        this.setState({ descripcion, data_modificacion });
    }
    handleConsulta = () => {
        if (document.getElementById("id_consulta_puesto").value.length === 0)
            message.message_modal("Advertencia", "Debe tener un ID de Puesto", 400);
        else {
            this.methodPOST_API(utility.url_consulta_puesto, 'consulta', { id_puesto: document.getElementById("id_consulta_puesto").value });
        }
    }
    handleModificar = () => {
        if (document.getElementById("id_consulta_puesto").value.length === 0)
            message.message_modal("Advertencia", "Debe tener un ID  de Puesto", 400);
        else {
            message.message_confimacion_textarea('¿Desea continuar con la operación modificar?', 'Ingrese sus observaciones ')
                .then(res => {
                    if (res) {
                        let observaciones = document.getElementById('observaciones_textarea').value;
                        let data = this.state.data_modificacion;
                        if (observaciones.length === 0)
                            message.message_modal('Completar el campo:', 'Motivo de Modificación', 400);
                        else
                            if (Object.values(this.state.data_modificacion).length > 0) {
                                data['id_puesto'] = document.getElementById("id_consulta_puesto").value;
                                data['observaciones'] = observaciones;
                                this.methodPUT_API(utility.url_crea_puesto, 'modifica', data);
                            }
                            else
                                message.message_modal("Advertencia", "No hay alguna modificación", 400);
                    }
                });
        }
    }
    methodGET_API = (url, state) => {
        let url_intern = url;
        axios.get(url_intern)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        case 'departamentos':
                            if (this._isMounted)
                                this.setState({ departamentos: response.data.results });
                            break;
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    methodPOST_API = (url, state, data) => {
        let url_intern = url;
        axios.post(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        case 'consulta':
                            this.setState({
                                puesto: response.data.results.puesto,
                                id_departamento: response.data.results.id_departamento,
                                descripcion: response.data.results.descripcion,
                                input_active: [false, false, false],
                            })
                            let options = document.getElementById("departamento_consulta_puesto").options;
                            for (let i = 0; i < options.length; i++)
                                if (parseInt(options[i].value) === this.state.id_departamento)
                                    document.getElementById("departamento_consulta_puesto").value = options[i].value;
                            break;
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    /**
     * Función que permite hacer una petición de forma PUT 
     * @param {String} url URL a consultar 
     * @param {String} state Onpción del case  
     * @param {Object} data Objeto a mandar atraves del metodo PUT 
     */
    methodPUT_API = (url, state, data) => {
        let url_intern = url;
        axios.put(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        case 'modifica':
                            this.setState({}, () => {
                                this.sendNotificacionHandler('modificar');
                            })
                            this.closeWindow();
                            message.message_modal(response.data.title, response.data.text, response.status);
                            this.props.update(1);
                            break;
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    render() {
        return (
            <Modal isOpen={this.props.toogle_consulta} toggle={this.closeWindow} backdrop="static" size="xl"  >
                <ModalHeader toggle={this.closeWindow}>Consulta Puesto</ModalHeader>
                <ModalBody>
                    <Form className="container-fluid">
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-10 col-8" id="id_consulta_puesto" nameLabel="ID Puesto" />
                            <Button color="primary" className="col-lg-2 col-4 mb-2" onClick={this.handleConsulta}>Consulta</Button>
                        </FormGroup>
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-6 col-12" id="nombre_consulta_puesto" nameLabel="Puesto"
                                value={this.state.puesto} onChange={this.handlePuesto} disabled={this.state.input_active[0]} />
                            <MySelect type="text" bootstrap="col-lg-6 col-12" id="departamento_consulta_puesto" nameLabel="Departamento"
                                first_option="Seleccione un departamento" onChange={this.handleDepartamento} disabled={this.state.input_active[1]}>
                                {this.state.departamentos.map(departamento => (
                                    <option key={departamento.id_departamento} value={departamento.id_departamento}>{departamento.nombre}</option>
                                ))}
                            </MySelect>
                            <MyTextArea bootstrap="col-lg-12 col-12" id="descripcion_consulta_puesto" nameLabel="Descripción"
                                value={this.state.descripcion} onChange={this.handleDescripcion} disabled={this.state.input_active[2]} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={this.handleModificar} disabled={permisos.obtener_permiso_especifico('modificar', this.props.permisos)}>Modificar</Button>
                    <Button color="primary" onClick={this.closeWindow}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default ModalConsulta;