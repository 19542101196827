import React, { useState }  from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import '../../assets/style.css';

const Paginacion = ({ actual, count, paginate }) => {
    const total_page = Math.ceil(count / 50);
    const [pageNumbers, setPage] = useState([1, 2, 3, 4, 5, 6]);
    if (actual > pageNumbers[pageNumbers.length - 1])
        setPage([pageNumbers[0] + 6, pageNumbers[1] + 6, pageNumbers[2] + 6, pageNumbers[3] + 6, pageNumbers[4] + 6, pageNumbers[5] + 6]);
    if (actual < pageNumbers[0])
        setPage([pageNumbers[0] - 6, pageNumbers[1] - 6, pageNumbers[2] - 6, pageNumbers[3] - 6, pageNumbers[4] - 6, pageNumbers[5] - 6]);
    return (
        <div className="d-flex justify-content-center mt-3">
            <Pagination>
                <PaginationItem disabled={actual === 1 ? true : false}>
                    <PaginationLink first onClick={() => paginate(actual = 1)} />
                </PaginationItem>
                <PaginationItem disabled={actual === 1 ? true : false} >
                    <PaginationLink previous onClick={() => paginate(actual - 1)} />
                </PaginationItem>
                {
                    pageNumbers.map(data => {
                        return (
                            <PaginationItem active={actual === data ? true : false} disabled={data > total_page ? true : false} key={data}>
                                <PaginationLink onClick={() => paginate(data)}>
                                    {data}
                                </PaginationLink>
                            </PaginationItem>)
                    })
                }
                <PaginationItem disabled={actual === total_page ? true : false} >
                    <PaginationLink next onClick={() => paginate(actual + 1)} />
                </PaginationItem>
                <PaginationItem disabled={actual === total_page ? true : false}>
                    <PaginationLink last onClick={() => paginate(total_page)} />
                </PaginationItem>
            </Pagination>
        </div>
    );
}
export default Paginacion;