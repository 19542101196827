import * as actionsTypes from "./actionsTypes";

export const addMessage = message => {
  return {
    type: actionsTypes.ADD_MESSAGE,
    message: message
  };
};

export const setMessages = message => {
  return {
    type: actionsTypes.SET_MESSAGES,
    message: message
  };
};

export const getChats = chats => {
  return {
    type: actionsTypes.GET_CHATS_SUCCESS,
    chats: chats
  };
};