export const url_pagination_clientes = '/DjangoWeb/BaseApp/consulta/clientes/paginacion/principal';
export const url_grupos_clientes = '/DjangoWeb/BaseApp/consulta/grupos_clientes/lista';
export const url_paises_lista = '/DjangoWeb/BaseApp/consulta/paises/lista';
export const url_codigo_postal = '/DjangoWeb/BaseApp/consulta/codigo_postal';
export const url_patentes = '/DjangoWeb/BaseApp/consulta/agente_aduanal/lista';
export const url_bancos = '/DjangoWeb/BaseApp/consulta/bancos/lista';

export const url_alta_cliente = '/DjangoWeb/BaseApp/crea_modifica/clientes';
export const url_alta_contactos = '/DjangoWeb/BaseApp/crea_modifica/contactos_empresa';
export const url_alta_domicilios = '/DjangoWeb/BaseApp/crea_modifica/domicilios_entrega';
export const url_alta_representantes = '/DjangoWeb/BaseApp/crea_modifica/representantes_legales';
export const url_alta_acuerdo = '/DjangoWeb/BaseApp/crea_modifica/acuerdos_comerciales';
export const url_consulta_cliente = '/DjangoWeb/BaseApp/consulta/clientes'
export const url_consulta_regimen_fiscal = '/DjangoWeb/BaseApp/consulta/regimen_fiscal';

export const url_permisos = '/DjangoWeb/UsuariosApp/search_permissions/';

export const url_crea_auxiliares = 'DjangoWeb/CuentasAuxiliaresApp/crea_modifica/control_auxiliar/cuenta';
export const url_consulta_auxiliares = '/DjangoWeb/CuentasAuxiliaresApp/consulta/cuenta_auxiliar';
export const url_crea_cuentas_aux = '/DjangoWeb/CuentasAuxiliaresApp/crea_modifica/cuenta_auxiliares';
export const url_pagination_cuentas = '/DjangoWeb/CuentasAuxiliaresApp/consulta/cuentas_auxiliares/paginacion/principal';
export const url_codigos_sat = '/DjangoWeb/BaseApp/consulta/codigos_sat/lista';
export const url_auxiliares = '/DjangoWeb/CuentasAuxiliaresApp/consulta/cuentas_auxiliares/paginacion/principal'; 
/**
 * Función que nos permite filtar la tabla por el input general que serra de tipo text.
 */
export const general_search = (e) => {
    e.preventDefault();
    let tr = document.getElementById('tabla_clientes').getElementsByTagName('tr');
    let data_input = document.getElementById('busqueda_general_clientes').value.toUpperCase();
    for (let i = 0; i < tr.length; i++) {
        let mostrar = false;
        let data = tr[i].getElementsByTagName('p');
        for (let j = 0; j < data.length; j++) {
            if (data_input === "") {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
            else if (data[j].textContent.toUpperCase().includes(data_input)) {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "rgba(0,123,255,1)";
                data[j].style.color = "#fff";
            }
            else {
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}

export const valida_data_alta_cliente = (data_cliente, data_acuerdo, representantes) => {
    let message = '';
    if (data_cliente.nombre.length === 0)
        message += 'Nombre, ';
    if (data_cliente.rfc.length === 0)
        message += 'RFC, ';
    if (data_cliente.pais.length === 0)
        message += 'País, ';
    if (data_cliente.direccion.length === 0)
        message += 'Dirección, ';
    if (data_cliente.codigo_postal.length === 0)
        message += 'Código Postal, ';
    if (data_cliente.colonia.length === 0)
        message += 'Colonia, ';
    if (data_cliente.aduana.length === 0)
        message += 'Aduana, ';
    if (data_cliente.patente.length === 0)
        message += 'Patente, ';
    if (data_cliente.encargo_conferido.length === 0)
        message += 'Encargo Conferido, ';
    if (data_acuerdo.tarifa_entrega.length === 0)
        message += 'Tarifa entrega, ';
    if (data_acuerdo.tarifa_importacion.length === 0)
        message += 'Tarifa importación, ';
    if (data_acuerdo.descuento_exportacion.length === 0)
        message += 'Descuento exportación, ';
    if (data_acuerdo.descuento_importacion.length === 0)
        message += 'Descuento importación, ';
    if (representantes.length === 0)
        message += 'Representante legal, ';
    if (data_cliente.regimen_fiscal.length === 0)
        message += 'Regimen Fiscal, ';
    message = message.slice(0, message.length - 2);
    return message;
}

export const valida_data_alta_contacto = (data) => {
    let message = '';
    if (data.cliente.length === 0)
        message += 'Cliente, ';
    if (data.contactos.length === 0)
        message += 'Agregar un contacto, ';
    message = message.slice(0, message.length - 2);
    return message;
}

export const valida_data_alta_domicilio = (data) => {
    let message = '';
    if (data.cliente.length === 0)
        message += 'Cliente, ';
    if (data.domicilios.length === 0)
        message += 'Agregar un domicilio, ';
    message = message.slice(0, message.length - 2);
    return message;
}

export const valida_modificacion_representantes = (data) => {
    if (data.eliminados.length === 0)
        delete data.eliminados;
    if (data.modificados.length === 0)
        delete data.modificados;
    if (data.creados.length === 0)
        delete data.creados;
}

export const valida_modificacion_contactos = (data) => {
    if (data.eliminados.length === 0)
        delete data.eliminados;
    if (data.modificados.length === 0)
        delete data.modificados;
    if (data.creados.length === 0)
        delete data.creados;
}

export const valida_modificacion_domicilios = (data) => {
    if (data.eliminados.length === 0)
        delete data.eliminados;
    if (data.creados.length === 0)
        delete data.creados;
}

export const patente_por_audana = (aduana, array) => {
    let patentes = [];
    for (let i = 0; i < array.length; i++)
        if (aduana === array[i].aduana)
            patentes.push(array[i]);
    return patentes;
}

export const get_text_by_id = (id, identificador, contendio, array) => {
    for (let i = 0; i < array.length; i++)
        if (array[i][identificador] === id)
            return array[i][contendio];
}

export const valida_data = (data) => {
    let message = "";
    if (data.codigo.length === 0)
        message += "Código, ";
    if (data.clave.length === 0)
        message += "Tipo, ";
    if (data.auxiliar.length === 0)
        message += "Cuenta, ";
    if (data.descripcion.length === 0)
        message += "Descripción, ";
    if (data.naturaleza.length === 0)
        message += "Naturaleza, ";
    if (data.tipoCuenta.length === 0)
        message += "Tipo de cuenta, ";
    if (data.nivel === 0)
        message += "Nivel, ";
    return message;
}

export const valida_cuenta = (mayor, subcuenta, nivel) => {
    let cuenta = "";
    if (nivel === 1)
        cuenta = mayor+"-000-0000";
    if (nivel === 2)
        cuenta = `${mayor}-`;
    if (nivel === 3)
        cuenta = `${mayor}-${subcuenta}`;
    return cuenta;
}