/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la consulta de un proveedor. 
*/
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import MyInput from "../../../utils/custom/MyInput";
import WebSocketInstance from "../../../utils/websocket";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";
import * as permisos from "../../../utils/permisos/permisos";
import { faArrowRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalAuxiliar from "../../Poliza/Modal/ModalAuxiliar";

class ModalConsulta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      numero: "",
      nombre: "",
      corto: "",

      data_modificacion: {},
      input_disabled: [true, true],

      auxiliar: "",
      toogle_auxiliar: false,
      auxiliares: [],
      text_cfdi_unidad: "",
      cfdi_unidad_suggestions: [],
      cat_cfdi_unidad: [],

      text_cfdi_productos: "",
      cfdi_productos_suggestions: [],
      cat_cfdi_productos: [],

      auxiliarInicial: "",
      cfdi_unidadInicial: "",
      cfdi_productosInicial: "",
    };
    this.toogleAuxiliar = this.toogleAuxiliar.bind(this);
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  /* sendNotificacionHandler = (motivo) => {
        let folio = this.state.numero_proveedor;
        const notificacionObject = {
            tipo: 0,
            aplicacion: 'proveedor',
            motivo: motivo,
            folio: [folio]
        }
        WebSocketInstance.newNotificacion(notificacionObject);
    } */
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleNumero = (e) => {
    this.setState({ numero: e.target.value });
  };
  handleConsulta = () => {
    if (this.state.numero.length === 0)
      message.message_modal("Advertencia", "Debe tener un ID de ingreso", 400);
    else {
      this.methodPOST_API(utility.url_consulta_ingreso, "consulta", {
        clave: this.state.numero,
      });
    }
  };
  handleModificar = () => {
    if (this.state.numero.length === 0)
      message.message_modal("Advertencia", "Debe tener un ID de ingreso", 400);
    else {
      message
        .message_confimacion("¿Desea continuar con la operación modificar?", "")
        .then((res) => {
          if (res) {
            let data_modificacion = this.state.data_modificacion;
            // Verificar si el campo auxiliar se ha modificado
            if (this.state.auxiliar !== this.state.auxiliarInicial) {
              data_modificacion["auxiliar"] = this.state.auxiliar;
            }
            // Verificar si el campo cfdi_unidadInicial se ha modificado
            if (this.state.text_cfdi_unidad !== this.state.cfdi_unidadInicial) {
              data_modificacion["clave_unidad"] = this.state.text_cfdi_unidad;
            }
            // Verificar si el campo cfdi_productosInicial se ha modificado
            if (
              this.state.text_cfdi_productos !==
              this.state.cfdi_productosInicial
            ) {
              const numero = this.state.text_cfdi_productos
                .split("-")[0]
                .trim();
              data_modificacion["clave_producto"] = numero;
            }
            if (Object.values(data_modificacion).length > 0) {
              data_modificacion["clave"] = this.state.numero;
              this.methodPUT_API(
                utility.url_crea_ingreso,
                "modificar",
                data_modificacion
              );
              //this.sendNotificacionHandler('modificar');
            } else
              message.message_modal(
                "Advertencia",
                "No hay modificaciones",
                400
              );
          }
        });
    }
  };
  handleCerrar = () => {
    this.setState({
      numero: "",
      nombre: "",
      corto: "",

      data_modificacion: {},
      input_disabled: [true, true],

      auxiliar: "",
      toogle_auxiliar: false,
      auxiliares: [],

      text_cfdi_unidad: "",
      cfdi_unidad_suggestions: [],
      cat_cfdi_unidad: [],

      text_cfdi_productos: "",
      cfdi_productos_suggestions: [],
      cat_cfdi_productos: [],

      auxiliarInicial: "",
      cfdi_unidadInicial: "",
      cfdi_productosInicial: "",
    });
    this.props.toogleConsulta();
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta":
              this.setState({
                nombre: response.data.results.nombre,
                corto: response.data.results.corto,
                auxiliar: response.data.results.auxiliar[0]?.auxiliar || "",
                input_disabled: [false, false],
                text_cfdi_productos: response.data.results.clave_producto,
                text_cfdi_unidad: response.data.results.clave_unidad,

                auxiliarInicial:
                  response.data.results.auxiliar[0]?.auxiliar || "",
                cfdi_unidadInicial: response.data.results.clave_unidad,
                cfdi_productosInicial: response.data.results.clave_producto,
              });
              break;
            case "cfdi_productos":
              this.setState({
                cat_cfdi_productos: response.data.results,
                cfdi_productos_suggestions: utility.organiza_cfdi_productos(
                  response.data.results
                ),
              });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "cfdi_unidad":
              this.setState({
                cat_cfdi_unidad: response.data.results,
              });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPUT_API = (url, state, data) => {
    let url_intern = url;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "modificar":
              this.handleCerrar();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  handleNombre = (e) => {
    let nombre = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["nombre"] = nombre;
    this.setState({ nombre, data_modificacion });
  };
  handleNombre_corto = (e) => {
    let corto = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["corto"] = corto;
    this.setState({ corto, data_modificacion });
  };
  handleConsulta_producto = () => {
    if (this.state.text_cfdi_productos === "") {
      message.warningMessage("Por favor, ingrese la clave de producto");
    } else {
      this.methodPOST_API(utility.url_cfdi_producto, "cfdi_productos", {
        descripcion: this.state.text_cfdi_productos,
      });
    }
  };
  handleAuxiliar = (e) => {
    let auxiliar = e.target.value;
    this.setState({ auxiliar });
  };
  handleCallback = (childData) => {
    this.setState({ auxiliar: childData });
  };
  toogleAuxiliar = () => {
    this.setState((prevState) => ({
      toogle_auxiliar: !prevState.toogle_auxiliar,
    }));

    if (this.state.auxiliar === "") {
      this.getAuxiliaresMayores();
    } else {
      this.postAuxiliarSegundoNivel();
    }
  };
  getAuxiliaresMayores() {
    const url = utility.url_auxiliares;
    axios
      .get(url)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted) {
            this.setState({
              auxiliares: response.data.results,
            });
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  postAuxiliarSegundoNivel() {
    axios
      .post(utility.url_auxiliares, {
        auxiliar: this.state.auxiliar,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted) {
            this.setState({
              auxiliares: response.data.results,
            });
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  /*--------------- FUNCIONES QUE ACTUALIZAN CAMPOS DE CFDI UNIDAD ---------------*/
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`${value}`, "i");
      suggestions = utility
        .organiza_cfdi_unidad(this.state.cat_cfdi_unidad)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      cfdi_unidad_suggestions: suggestions,
      text_cfdi_unidad: value,
    });
  };
  renderSuggestion = () => {
    if (this.state.cfdi_unidad_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.cfdi_unidad_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  suggestionSelected = (value) => {
    this.setState({
      text_cfdi_unidad: value,
      cfdi_unidad_suggestions: [],
    });
  };
  /*--------------- FIN FUNCIONES QUE ACTUALIZAN CAMPOS DE CFDI UNIDAD ---------------*/

  /*--------------- FUNCIONES QUE ACTUALIZAN CAMPOS DE CFDI PRODUCTOS ---------------*/
  onNameChange_P = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`${value}`, "i");
      suggestions = utility
        .organiza_cfdi_productos(this.state.cat_cfdi_productos)
        .sort()
        .filter((v) => regex.exec(v));
    }
    this.setState({
      cfdi_productos_suggestions: suggestions,
      text_cfdi_productos: value,
    });
  };
  renderSuggestion_P = () => {
    if (this.state.cfdi_productos_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.cfdi_productos_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected_P(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  suggestionSelected_P = (value) => {
    this.setState({
      text_cfdi_productos: value,
      cfdi_productos_suggestions: [],
    });
  };
  /*--------------- FIN FUNCIONES QUE ACTUALIZAN CAMPOS DE CFDI PRODUCTOS ---------------*/

  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let codigo = e.target.value;
    this.setState({ codigo });
    for (let i = 0; i < this.props.codigos_sat.length; i++) {
      if (codigo.length === 0) this.suggestionSelectedd("");
      if (codigo === this.props.codigos_sat[i].codigo)
        this.suggestionSelectedd(this.props.codigos_sat[i].descripcion);
    }
  };
  suggestionSelectedd = (value) => {
    this.setState({
      nombre_select: value,
    });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>Consulta Ingresos</ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-10 col-8"
                id="numero_proveedor_consulta"
                value={this.state.numero_proveedor}
                onChange={this.handleNumero}
                nameLabel="Clave Ingreso"
              />
              <Button
                color="primary"
                className="col-lg-2 col-4 mb-2"
                onClick={this.handleConsulta}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="nombre_ingreso_alta"
                nameLabel="Nombre Ingreso"
                value={this.state.nombre}
                onChange={this.handleNombre}
                /* disabled={this.state.input_disabled[0]} */
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="nombre_corto_ingreso_alta"
                nameLabel="Nombre Corto"
                value={this.state.corto}
                onChange={this.handleNombre_corto}
                maxLength="7"
                d/* isabled={this.state.input_disabled[1]} */
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-10 col-12"
                id="auxiliar_poliza"
                nameLabel="Auxiliar"
                value={this.state.auxiliar}
                onChange={this.handleAuxiliar}
              />
              <Button
                color="primary"
                className="col-lg-2 col-12 mb-2"
                onClick={this.toogleAuxiliar}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-12"
                id="clave_unidad_ingreso_alta"
                onChange={this.onNameChange}
                value={this.state.text_cfdi_unidad}
                nameLabel="Claves de Unidad"
              />
              {this.renderSuggestion()}
              <MyInput
                type="text"
                bootstrap="col-lg-10 col-10"
                id="clave_productos_ingreso_alta"
                onChange={this.onNameChange_P}
                value={this.state.text_cfdi_productos}
                nameLabel="Clave de Producto"
              />
              <Button
                color="primary"
                className="col-lg-2 col-4 mb-2"
                onClick={this.handleConsulta_producto}
              >
                Consulta
              </Button>
              {this.renderSuggestion_P()}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={this.handleModificar}
            disabled={permisos.obtener_permiso_especifico(
              "modificar",
              this.props.permisos
            )}
          >
            Modificar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
        <ModalAuxiliar
          toogle_catalogo={this.state.toogle_auxiliar}
          toogleCatalogo={this.toogleAuxiliar}
          listaAuxiliares={this.state.auxiliares}
          parentCallback={this.handleCallback}
        />
      </Modal>
    );
  }
}
export default ModalConsulta;
