import axios from "axios";
import React, { Component } from "react";
import { Spinner, Table } from "reactstrap";
import "../../assets/style.css";
import * as message from "./messages/messages";
import Paginacion from "../Pagination";
import ConsultaEspecifica from "./SearchSpecific";
import * as utility from "./utils/utility";

class TablePoliza extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      page_actual: 1,
      pagination: { count: 1, results: [] },
      arr_diario: [],
      arr_egresos: [],
      arr_ingresos: [],
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_paginacion_polizas, "pagination");
    this.methodGET_API(utility.url_mov_Ingresos, "ingresos");
    this.methodGET_API(utility.url_mov_Egresos, "egresos");
    /* this.methodGET_API(utility.url_mov_Diario, "diario"); */
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handlePage = (page_actual) => {
    this.setState({ page_actual }, () => {
      this.methodGET_API(utility.url_paginacion_polizas, "pagination");
    });
  };
  methodGET_API = (url, state) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "pagination":
              if (this._isMounted === true)
                this.setState({
                  pagination: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                });
              break;
            case "ingresos":
              if (this._isMounted === true)
                this.setState({
                  arr_ingresos: response.data.results,
                });
              break;
            case "egresos":
              if (this._isMounted === true)
                this.setState({
                  arr_egresos: response.data.results,
                });
              break;
            case "diario":
              if (this._isMounted === true)
                this.setState({
                  arr_diario: response.data.results,
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "pagination":
              if (this._isMounted === true)
                this.setState({
                  pagination: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  onBackTable = () => {
    this.methodGET_API(utility.url_paginacion_polizas, "pagination");
  };
  onChangeFiltrada = (data) => {
    this.setState({ page_actual: 1 }, () => {
      this.methodPOST_API(utility.url_paginacion_polizas, "pagination", data);
    });
  };
  render() {
    return (
      <React.Fragment>
        <ConsultaEspecifica
          onBackTable={this.onBackTable}
          onChangeFiltrada={this.onChangeFiltrada}
        />
        <div className="overflow-auto tm-table">
          <Table size="sm" id="tabla_poliza" bordered hover responsive>
            <thead className="text-center header-table">
              <tr>
                <th>Consecutivo</th>
                <th>Número</th>
                <th>Fecha</th>
                <th>Tipo</th>
                <th>Concepto</th>
                <th>Movimiento</th>
                <th>Referencia</th>
                <th>Auxiliar</th>
                <th>Documento</th>
                <th>Tipo de Mov.</th>
                <th>Cargo</th>
                <th>Abono</th>
              </tr>
            </thead>
            <tbody className="body-table hover-gray">
              {this.state.pagination.results.length === 0 ? (
                <tr>
                  <td colSpan="12" align="center">
                    <Spinner
                      style={{ width: "10rem", height: "10rem" }}
                      color="danger"
                      type="grow"
                      className="align-self-center"
                    />
                  </td>
                </tr>
              ) : (
                <React.Fragment>
                  {this.state.pagination.results.map((poliza) => {
                    return (
                      <tr key={poliza.consecutivo}>
                        <td>
                          <p className="mb-0 text-center">
                            {poliza.consecutivo}
                          </p>
                        </td>
                        <td>
                          <p className="mb-0 text-center">{poliza.numero}</p>
                        </td>
                        <td>
                          <p className="mb-0 text-center">{poliza.fecha}</p>
                        </td>
                        <td>
                          <p className="mb-0 text-center">{poliza.tipo}</p>
                        </td>
                        <td>
                          <p className="mb-0 text-center">{poliza.concepto}</p>
                        </td>
                        <td>
                          <p className="mb-0 text-white">-</p>
                          {poliza.movimientos.map((mov) => (
                            <p
                              className="mb-0 text-center"
                              key={mov.movimiento}
                            >
                              {mov.movimiento}
                            </p>
                          ))}
                        </td>
                        <td>
                          <p className="mb-0 text-white">-</p>
                          {poliza.movimientos.map((mov) => (
                            <p
                              className="mb-0 text-center"
                              key={mov.movimiento}
                            >
                              {mov.referencia}
                            </p>
                          ))}
                        </td>
                        <td>
                          <p className="mb-0 text-white">-</p>
                          {poliza.movimientos.map((mov) => (
                            <p
                              className="mb-0 text-center"
                              key={mov.movimiento}
                            >
                              {mov.auxiliar}
                            </p>
                          ))}
                        </td>
                        <td>
                          <p className="mb-0 text-white">-</p>
                          {poliza.movimientos.map((mov) => (
                            <p
                              className="mb-0 text-center"
                              key={mov.movimiento}
                            >
                              {mov.documento}
                            </p>
                          ))}
                        </td>
                        <td>
                        <p className="mb-0 text-white">-</p>
                          {poliza.movimientos.map((mov) => {
                            return (
                              <p
                                className="mb-0 text-center"
                                key={mov.movimiento}
                              >
                                {utility.get_type_poliza(
                                  mov.tipo_mov,
                                  poliza.tipo,
                                  this.state.arr_ingresos,
                                  this.state.arr_egresos,
                                  this.state.arr_diario
                                )}
                              </p>
                            );
                          })}
                        </td>
                        <td>
                          <p className="mb-0 text-white">-</p>
                          {poliza.movimientos.map((mov) => (
                            <p
                              className="mb-0 text-center"
                              key={mov.movimiento}
                            >
                              {mov.cargo}
                            </p>
                          ))}
                        </td>
                        <td>
                          <p className="mb-0 text-white">-</p>
                          {poliza.movimientos.map((mov) => (
                            <p
                              className="mb-0 text-center"
                              key={mov.movimiento}
                            >
                              {mov.abono}
                            </p>
                          ))}
                        </td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              )}
            </tbody>
          </Table>
        </div>
        <Paginacion
          actual={this.state.page_actual}
          count={this.state.pagination.count}
          paginate={this.handlePage}
        />
      </React.Fragment>
    );
  }
}
export default TablePoliza;
