import React from "react";

class SelectComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.handleDropdownClick = this.handleDropdownClick.bind(this);
  }

  handleDropdownClick() {
    if (this.props.disabled) this.setState({ isOpen: false });
    else this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const { bootstrap, nameLabel, value, onChange, options } = this.props;
    const { isOpen } = this.state;

    let cls = 'select';
    if (isOpen) cls += ' _active select_backgroud';

    return (
      <div className={`grupo p-0 ${bootstrap}`}>
        <span className={isOpen ? 'label-select-custom label-select-custom-focus' : 'label-select-custom'}>
          {nameLabel}
        </span>
        <div
          className={cls}
          style={{ background: this.props.disabled ? '#e9ecef' : '#fff' }}
          onClick={this.handleDropdownClick}
        >
          <div className="select-selected">{value}</div>
          {isOpen && (
            <div className="select-dropdown" style={{ zIndex: "10", position: "relative", maxHeight: "300px", overflowY: "scroll", borderBottom: "solid 1px #ced4da", borderLeft: "solid 1px #ced4da", borderRight: "solid 1px #ced4da" }}>
              {options.map((option, index) => (
                <SelectOption
                  key={index}
                  text={option}
                  onClick={(e) => {
                    onChange(option);
                    this.setState({ isOpen: false });
                    e.stopPropagation();
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default SelectComponent;

class SelectOption extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="select-option" onClick={this.props.onClick}>
                {this.props.text}
            </div>
        );
    }
}