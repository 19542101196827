import React, { Component } from 'react';
import SearchBar from './SearchBar';
import Table from './Table';
import * as permisos from '../../utils/permisos/permisos';

class HistoricoOperacion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            change_table: 0,
            permisos: {
                alerta: permisos.obtener_permisos_by_app('alertahistorico', localStorage.getItem('perm')),
            }
        }
    }
    changeTable = (num_table) => {
        this.setState({ change_table: num_table })
    }
    render() {
        return (
            <div className="container-fluid">
                <SearchBar changeTable={this.changeTable} permisos={this.state.permisos} />
                <Table change_table={this.state.change_table} />
            </div>);
    }

}
export default HistoricoOperacion;