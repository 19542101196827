/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente general de la aplicación anticipos. 
*/
import axios from "axios";
import React, { Component } from "react";
import * as message from "./messages/messages";
import SearchBar from './SearchBar';
import TablePedimentos from './Table';
import * as permisos from '../../utils/permisos/permisos';
import * as utility from "./utils/utility";

class Pedimentos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actualizar: 0,
            permisos: permisos.obtener_permisos_by_app('m500', localStorage.getItem('perm')),
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.methodGET_API(utility.url_bancos, "bancos");
        this.methodGET_API(utility.url_cliente, "clientes");
      }
      
      componentWillUnmount() {
        this._isMounted = false;
      }
    
    update = (cambio) => {
        this.setState({ actualizar: cambio });
    }

    methodGET_API = (url, state) => {
        let url_intern = url;
        axios
          .get(url_intern)
          .then((response) => {
            if (response.status >= 200 && response.status < 400) {
              switch (state) {
                case "clientes":
                  if (this._isMounted === true)
                    this.setState({ clientes: response.data.results });
                  break;
                case "bancos":
                  if (this._isMounted === true)
                    this.setState({ bancos: response.data.results });
                  break;
                default:
                  break;
              }
            }
          })
          .catch((error) => {
            if (error.response)
              message.message_modal(
                error.response.data.title,
                error.response.data.text,
                error.response.status
              );
          });
      };

    render() {
        return (
            <div className="container-fluid">
                <SearchBar permisos={this.state.permisos} update={this.update} />
                <TablePedimentos actualizar={this.state.actualizar} update={this.update} />
            </div>
        );
    }
}
export default Pedimentos;