import axios from "axios";
import React, { Component } from "react";
import { Button, Spinner, Table, ButtonGroup } from "reactstrap";
import "../../../assets/style.css";
import ConsultaEspecifica from './SearchSpecific';
import * as message from "./messages/messages";
import * as utility from "./utils/utility";
/* import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; */

class TableComplementoPago extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      page_actual: 1,
      pagination: { count: 1, results: [] },
      active: [],
      cuenta_select: "",
      only_mayor: 0,

      complementosDePago: [],
      prueba:"",
    };
  }
  componentDidMount() {
    this._isMounted = true;

  }
  componentWillUnmount() {
    this._isMounted = false;
  }
 

  render() {
    return (
      <React.Fragment>
        <ConsultaEspecifica onBackTable={this.onBackTable} onChangeFiltrada={this.onChangeFiltrada} />
        <div className="overflow-auto tm-table">
          <Table
            size="sm"
            id="tabla_cuentas_complementosDePago"
            bordered
            hover
            responsive
          >
            <thead className="text-center header-table">
              <tr>
                <td>Folio Fiscal</td>
                <td>Serie</td>
                <td>Folio</td>
                <td>Fecha Emisión</td>
                <td>Monto Del Pago</td>
                <td>Método Del Pago</td>
                <td>Número De Cuenta</td>
                <td>RFC</td>
                <td>Número De Operación</td>
              </tr>
            </thead>
            <tbody className="body-table hover-gray">
              {this.state.complementosDePago.map((aux) => (
                <tr key={aux.auxiliar}>
                  <td>
                    <p className="mb-0 text-center">{aux.auxiliar}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{aux.descripcion}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{aux.codigo}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{aux.auxiliar}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{aux.descripcion}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{aux.codigo}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{aux.auxiliar}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{aux.descripcion}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{aux.codigo}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end btn-regresar-anticipos">
        </div>
      </React.Fragment>
    );
  }
}
export default TableComplementoPago;
