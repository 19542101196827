import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Form,
  FormGroup,
} from "reactstrap";
import * as utility from "../utils/utility";
import * as message from "../messages/messages";
import axios from "axios";
import swal from "@sweetalert/with-react";
import WebSocketInstance from "../../../utils/websocket";
import MyInput from "../../../utils/custom/MyInput";
import MySelect from "../../../utils/custom/MySelect";

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.selectRef = React.createRef(); // Inicializa la referencia
    this.state = {
      nombre: "",
      cta_banco: "",
      banco: "",
      identificativo: "",
    };
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.identificativo;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "beneficiario",
      motivo: motivo,
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleNombre = (e) => {
    let nombre = e.target.value;
    this.setState({ nombre });
  };

  handleCuentaBancaria = (e) => {
    let cta_banco = e.target.value;
    this.setState({ cta_banco });
  };

  handleCuentaBanco = (e) => {
    let banco = e.target.value;
    this.setState({ banco });
  };

  handlebancos = (e) => {
    this.setState({ banco: e.target.value.slice(0, 4) });
  };

  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "estados":
              if (this._isMounted)
                this.setState({ estados: response.data.results });
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta_beneficiario":
              this.setState(
                { identificativo: response.data.results.numero },
                () => {
                  this.sendNotificacionHandler("crear");
                }
              );
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  validateForm = () => {
    const { nombre, cta_banco, banco } = this.state;

    return nombre && cta_banco && banco !== "";
  };

  handleAlta = (e) => {
    e.preventDefault();
    if (!this.validateForm()) {
      // Mostrar mensaje de error indicando que todos los campos deben estar llenos
      swal("Advertencia", "Todos los campos deben estar llenos", "warning");
      return;
    }

    message
      .message_confimacion("¿Desea continuar con la operación alta?", "")
      .then((res) => {
        if (res) {
          const { nombre, cta_banco } = this.state;
          const data = {
            nombre,
            cta_banco,
            banco: this.state.banco,
          };
          this.methodPOST_API(
            utility.url_crear_beneficiario,
            "alta_beneficiario",
            data
          );
          this.handleCerrar();
          /* this.sendNotificacionHandler("crear"); */
        }
      });
  };

  handleCerrar = () => {
    this.setState({
      nombre: "",
      cta_banco: "",
      banco: "",
    });
    const selectElement = document.querySelector("#bancos_alta");
    if (selectElement) {
      selectElement.value = "";
    }
    this.props.toogleNuevo();
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_nuevo}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>Alta Beneficiario</ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-1">
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                nameLabel="Nombre de Beneficiario"
                id="nombre_beneficiario_alta"
                onChange={this.handleNombre}
                value={this.state.nombre}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                nameLabel="Cuenta Banco"
                id="cuenta_banco"
                onChange={this.handleCuentaBancaria}
                value={this.state.cta_banco}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                nameLabel="Banco"
                id="banco"
                onChange={this.handleCuentaBanco}
                value={this.state.banco}
              />
              {/* <MySelect
                bootstrap="col-lg-6 col-12"
                nameLabel="Bancos"
                first_option="Seleccione un banco"
                required
                onChange={this.handlebancos}
                id="bancos_alta"
                ref={this.selectRef} // referencia al elemento select
              >
                {this.props.bancos.map((ban) => (
                  <option key={ban.numero}>
                    {ban.numero} - {ban.nombre}
                  </option>
                ))}
              </MySelect> */}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={this.handleAlta}>
            Aceptar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalAlta;
