import React, { Component } from "react";
import swal from "@sweetalert/with-react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Form,
  FormGroup,
} from "reactstrap";
import * as utility from "../utils/utility";
import * as message from "../messages/messages";
import axios from "axios";
import WebSocketInstance from "../../../utils/websocket";
import MyInput from "../../../utils/custom/MyInput";
import MySelect from "../../../utils/custom/MySelect";
import SelectBanco from "../utils/SelectBanco";

class ModalConsulta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      cta_banco: "",
      numero_banco: "",
      nombre: "",
      CLABE: "",
      tipo_banco: "",
      numeroBanco: "",
      tipoBanco: "",
    };
  }

  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.identificativo;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "cuenta_bancaria",
      motivo: motivo,
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleNombre = (e) => {
    let nombre = e.target.value;
    this.setState({ nombre });
  };

  handleCuentaBancaria = (e) => {
    let cta_banco = e.target.value;
    this.setState({ cta_banco });
  };

  handleNumeroBanco = (e) => {
    let numeroBanco = e.target.value;
    this.setState({ numeroBanco });
  };

  handleNombreBanco = (e) => {
    let nombre = e.target.value;
    this.setState({ nombre });
  };

  handleCLABE = (e) => {
    let CLABE = e.target.value;
    this.setState({ CLABE });
  };

  handleTipoBanco = (event) => {
    const selectedValue = event.target.value;
    console.log("Valor seleccionado:", selectedValue);

    // Actualizar el estado tipoBanco con el valor seleccionado
    this.setState({
      tipoBanco: selectedValue === "1" ? "Nacional" : "Internacional",
    });
  };

  handlebancos = (event) => {
    const selectedValue = event.target.value;
    const [numero, tipo_banco] = selectedValue.split(" - ")[0].split(".");

    this.setState({
      numero_banco: numero,
      tipo_banco: tipo_banco,
    });
  };

  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "estados":
              if (this._isMounted)
                this.setState({ estados: response.data.results });
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta_cuenta_bancaria":
              this.setState(
                { identificativo: response.data.results.numero },
                () => {
                  this.sendNotificacionHandler("crear");
                }
              );
              this.props.toogleNuevo();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handleConsulta = () => {
    const { numero_banco } = this.state;

  if (!numero_banco || numero_banco.trim() === '') {
    swal("Advertencia", "Debe seleccionar un banco", "warning");
    return; 
  }

    const data = {
      numero: numero_banco,
/*       tipo_banco: tipo_banco, */
    };

    axios
      .post(utility.url_consulta_bancos, data)
      .then((response) => {
        this.setState(
          {
            nombre: response.data.results.nombre,
            CLABE: response.data.results.CLABE,
            numeroBanco: response.data.results.numero_banco,
/*             tipoBanco:
              response.data.results.tipo_banco === "1"
                ? "Nacional"
                : "Internacional", */
          }
        );
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handleModificar = () => {
    const { numero_banco } = this.state;
    const data = {
      numero: numero_banco,
      /* tipo_banco: tipo_banco, */
    };
    
    axios
      .post(utility.url_consulta_bancos, data)
      .then((response) => {
        const nombreOriginal = response.data.results.nombre;
        const CLABEOriginal = response.data.results.CLABE;
        

        const {
          nombre: nombreModificado,
          CLABE: CLABEModificado,
        } = this.state;

        const data = {};

        data.numero = this.state.numero_banco;

        if (nombreModificado !== nombreOriginal) {
          data.nombre = nombreModificado;
        }
        if (CLABEModificado !== CLABEOriginal) {
          data.CLABE = CLABEModificado;
        }

        if (Object.keys(data).length === 0) {
          swal({
            title: "Sin modificaciones",
            text: "No se han realizado cambios en los datos",
            icon: "warning",
          });
          return;
        }

        axios
          .put(utility.url_crea_mod_bancos, data)
          .then((response) => {
            // Lógica adicional después de la respuesta exitosa
            swal("Modificación exitosa", response.data.text, "success");
            this.props.update(1);
            this.props.toogleConsulta();
          })
          .catch((error) => {
            if (error.response) {
              swal(
                "Error en la modificación",
                error.response.data.text,
                "error"
              );
            }
          });
      })
      .catch((error) => {
        if (error.response) {
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
        }
      });
  };

  validateForm = () => {
    const { numero_banco, nombre, CLABE} = this.state;

    return numero_banco && nombre && CLABE;
  };

  handleCerrar = () => {
    this.setState({
      nombre: "",
      cta_banco: "",
      numero_banco: "",
      CLABE: "",
      tipo_banco: "",
    });
    const selectElement = document.querySelector("#bancos_alta");
    if (selectElement) {
      selectElement.value = "";
    }
    this.props.toogleConsulta();
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.props.toogleConsulta}>
          Consulta De Bancos
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MySelect
                bootstrap="col-lg-8 col-12"
                nameLabel="Bancos"
                first_option="Seleccione un banco"
                required
                onChange={this.handlebancos}
                id="specific_banco"
              >
                {this.props.bancos.map((ban) => (
                  <option key={ban.numero}>
                    {ban.numero} - {ban.nombre}
                  </option>
                ))}
              </MySelect>

              <Button
                color="primary"
                className="col-lg-4 col-4 mb-2"
                onClick={this.handleConsulta}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-1">
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                nameLabel="Número de Banco"
                id="numero_banco"
                onChange={this.handleNumeroBanco}
                value={this.state.numeroBanco}
                disabled={true}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                nameLabel="Nombre de Banco"
                id="nombre"
                onChange={this.handleNombreBanco}
                value={this.state.nombre}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                nameLabel="CLABE"
                id="clabe"
                onChange={this.handleCLABE}
                value={this.state.CLABE}
              />
              {/* <SelectBanco
                bootstrap="col-lg-6 col-12"
                nameLabel="Tipo de Banco"
                name="tipo_banco"
                id="tipo_banco"
                value={
                  this.state.tipoBanco === "Nacional"
                    ? "1"
                    : this.state.tipoBanco === "Internacional"
                    ? "2"
                    : ""
                }
                onChange={this.handleTipoBanco}
                disabled={true}
              >
                <option value="">Seleccione una opción</option>
                <option value="1">Nacional</option>
                <option value="2">Internacional</option>
              </SelectBanco> */}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={this.handleModificar}>
            Modificar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalConsulta;
