import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../utils/actions/auth';
import Footer from '../Footer';
import Header from '../Header';

class Content extends Component {
    componentDidMount() {
        this.props.getLocation(this.props.location.pathname);
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.props.getLocation(this.props.location.pathname);
        }
    }
    render() {
        if (this.props.isAuthenticated)
            return (
                <React.Fragment>
                    <Header {...this.props} />
                    {this.props.children}
                    <Footer />
                </React.Fragment>
            );
        else
            return (
                <React.Fragment>
                    {this.props.children}
                    <Footer />
                </React.Fragment>
            );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        end: () => dispatch(actions.authEnd())
    }
}
export default withRouter(connect(null, mapDispatchToProps)(Content));