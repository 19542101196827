import axios from "axios";
import React, { Component } from "react";
import { Button, Spinner, Table, ButtonGroup } from "reactstrap";
import "../../assets/style.css";
import * as message from "./messages/messages";
import Paginacion from "../Pagination";
import ConsultaEspecifica from "./SearchSpecific";
import * as utility from "./utils/utility";
/* import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; */

class TableCuentasAux extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      page_actual: 1,
      pagination: { count: 1, results: [] },
      active: [],
      cuenta_select: "",
      only_mayor: 0,

      auxiliares: [],
      prueba:"",
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_pagination_cuentas, "pagination");
    this.toogleAuxiliar();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps) {
    if (this.props.actualizar !== prevProps.actualizar) {
      this.handlePage(this.state.page_actual);
      this.props.update(0);
    }
    if (this.props.mostrar !== prevProps.mostrar) {
      if (this.state.cuenta_select.length === 0) {
        message.message_modal(
          "Advertencia",
          "Debe seleccionar una cuenta",
          400
        );
        this.props.desglose_cuenta(0);
        this.props.on_back(false);
      } else {
        this.onChangeMayor({ mayor: this.state.cuenta_select });
        this.props.desglose_cuenta(0);
        this.props.on_back(true);
      }
    }
    if (this.props.onback !== prevProps.onback) {
      if (this.props.onback === false) this.setState({ cuenta_select: "" });
    }
  }
  handlePage = (page_actual) => {
    this.setState({ page_actual }, () => {
      this.methodGET_API(utility.url_pagination_cuentas, "pagination");
    });
  };
  methodGET_API = (url, state) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "pagination":
              if (this._isMounted === true)
                this.setState(
                  {
                    pagination: {
                      count: response.data.count,
                      results: response.data.results,
                    },
                  },
                  () => {
                    let active = [];
                    for (
                      let i = 0;
                      i < this.state.pagination.results.length;
                      i++
                    )
                      active.push(false);
                    this.setState({
                      active: active,
                      only_mayor: 0,
                    });
                  }
                );
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "pagination":
              if (this._isMounted === true)
                this.setState(
                  {
                    pagination: {
                      count: response.data.count,
                      results: response.data.results,
                    },
                  },
                  () => {
                    let active = [];
                    for (
                      let i = 0;
                      i < this.state.pagination.results.length;
                      i++
                    )
                      active.push(false);
                    this.setState({
                      active: active,
                    });
                  }
                );
              break;
            case "only_mayor":
              if (this._isMounted === true)
                this.setState({
                  pagination: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                  only_mayor: 1,
                });
              break;
            case "filtrada":
              if (this._isMounted === true)
                this.setState({
                  pagination: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  onColapseMayor = (e) => {
    let mayor = e.target.value;
    let arreglo = [...document.getElementsByClassName(`${mayor}-subcuenta-dp`)];
    if (arreglo[0].style.display === "none")
      for (let i = 0; i < arreglo.length; i++)
        arreglo[i].style.display = "table-row";
    else
      for (let i = 0; i < arreglo.length; i++)
        arreglo[i].style.display = "none";
  };
  onSelectCuenta = (e) => {
    let numero = e.target.id.slice(18, e.target.id.length);
    let cuenta = e.target.id.slice(4, 17);
    let arreglo = this.state.active;
    for (let i = 0; i < arreglo.length; i++)
      if (arreglo[i] === true) arreglo[i] = false;
    arreglo[parseInt(numero)] = true;
    console.log(numero, cuenta);
    this.setState({ active: arreglo, cuenta_select: cuenta.slice(0, 4) });
  };
  onChangeMayor = (data) => {
    this.methodPOST_API(utility.url_pagination_cuentas, "only_mayor", data);
  };
  onChangeFiltrada = (data) => {
    this.setState({ page_actual: 1 }, () => {
      this.methodPOST_API(utility.url_pagination_cuentas, "filtrada", data);
    });
  };
  onBackTable = () => {
    this.methodGET_API(utility.url_pagination_cuentas, "pagination");
  };

  toogleAuxiliar = () => {
    this.getAuxiliaresMayores();
  };

  getAuxiliaresMayores() {
    const url = utility.url_auxiliares;
    axios
      .get(url)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              pagination: {
                count: response.data.count,
                results: response.data.results,
              }
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  postAuxiliarSegundoNivel() {
    axios
      .post(utility.url_auxiliares, {
        auxiliar: this.state.auxiliar,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              pagination: {
                count: response.data.count,
                results: response.data.results,
              }
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  handleVerMas = (aux) => {
    const segundoNivel = aux.slice(7, 8);
    
    if (segundoNivel == 0) {
      const auxiliar = aux.slice(0, 5);
      axios
        .post(utility.url_auxiliares, {
          auxiliar: auxiliar,
          nivel:"2"
        })
        .then((response) => {
          if (response.status >= 200 && response.status < 400) {
            if (this._isMounted)
              this.setState({
                pagination: {
                  count: response.data.count,
                  results: response.data.results,
                }
              });
          }
        })
        .catch((error) => {
          if (error.response)
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
        });
    } else {
      axios
        .post(utility.url_auxiliares, {
          auxiliar: aux.slice(0, 9),
          nivel:"3"
        })
        .then((response) => {
          if (response.status >= 200 && response.status < 400) {
            if (this._isMounted)
              this.setState({
                pagination: {
                  count: response.data.count,
                  results: response.data.results,
                }
              });
          }
        })
        .catch((error) => {
          if (error.response)
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
        });
    }
  };

  handleAuxiliar = (aux) => {
    this.setState({
      auxiliarSegundoNivel: aux,
    });
  };

  handleRegresar = () => {
    const url = utility.url_auxiliares;
    axios
      .get(url)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              pagination: {
                count: response.data.count,
                results: response.data.results,
              }
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  render() {
    return (
      <React.Fragment>
        <ConsultaEspecifica
          cuenta_select={this.state.cuenta_select}
          onChangeMayor={this.onChangeMayor}
          onBackTable={this.onBackTable}
          onChangeFiltrada={this.onChangeFiltrada}
        />
        <div className="overflow-auto tm-table">
          <Table
            size="sm"
            id="tabla_cuentas_auxiliares"
            bordered
            hover
            responsive
          >
            <thead className="text-center header-table">
              <tr>
                <td>Auxiliar</td>
                <td>Descripción</td>
                <td>Código</td>
                <td>Detalle</td>
              </tr>
            </thead>
            <tbody className="body-table hover-gray">
              {this.state.pagination.results.map((aux) => (
                <tr key={aux.auxiliar}>
                  <td>
                    <p className="mb-0 text-center">{aux.auxiliar}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{aux.descripcion}</p>
                  </td>
                  <td>
                    <p className="mb-0 text-center">{aux.codigo}</p>
                  </td>
                  <td className="text-center">
                    <Button
                      color="info"
                      size="sm"
                      onClick={() => this.handleVerMas(aux.auxiliar)}
                      disabled={aux.tpo_cuenta === "D" ? true : false}
                    >
                      Ver más
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end btn-regresar-anticipos">
        <Button
          type="button"
          color="info"
          size="lg"
          onClick={this.handleRegresar}
          disabled={this.state.pagination.results.length === 0 ? true : false}
        >
          Regresar
        </Button>
        </div>
        <Paginacion
          actual={this.state.page_actual}
          count={1}
          paginate={this.handlePage}
        />
      </React.Fragment>
    );
  }
}
export default TableCuentasAux;
