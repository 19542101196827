/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la consulta de una patente. 
*/
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Spinner,
  CustomInput,
  Badge,
  Collapse,
} from "reactstrap";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";
import MyInput from "../../../utils/custom/MyInput";
import {
  faPlus,
  faSyncAlt,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyTextArea from "../../../utils/custom/MyTextArea";
import XMLParser from "react-xml-parser";
import SelectComponent from "../utils/CustomSelect";
import MyDropzone from "../utils/MyDropzone";
import swal from "@sweetalert/with-react";
import MySelect from "../../../utils/custom/MySelect";
import * as permisos from "../../../utils/permisos/permisos";
import WebSocketInstance from "../../../utils/websocket";
import ModalModificaMov from "./ModalModificaMov";

class ModalConsulta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      numero: "",
      concepto: "",
      fecha: "",
      observaciones: "",
      importe: "",
      referencia: "",
      status: "",
      archivo_xml: null,
      archivo_pdf: null,
      archivo_valido: false,
      enable_add: true,
      desglose: [],
      desglose_operacion: [],
      movimientos: [],
      movimientos_actuales: [],
      movimientos_elimina: [],
      movimientos_select: [],
      cheque: "",

      beneficiario: "Seleccione un beneficiario",
      id_beneficiario: "",
      tipo_transaccion: "Seleccione un tipo",
      id_transaccion: "",
      banco: "Seleccione un banco",
      bancos: "Seleccione un banco",
      id_banco: "",
      tipo_movimiento: "Seleccione un tipo",
      id_tipo_movimiento: "",
      tipo: "Seleccione un tipo",
      id_tipo: "",
      año: "Seleccione un año",
      id_año: "",
      nombreBanco: "",

      comprobante_campos: {},
      emisor_campos: {},
      receptor_campos: {},
      timbre_campos: {},

      tipo_transaccion_array: [
        { id: "CC", text: "Comprobable del cliente" },
        { id: "CE", text: "Comprobable de la empresa" },
        { id: "PC", text: "Por comprobar del cliente" },
        { id: "PE", text: "Por comprobar de la empresa" },
      ],
      tipo_movimiento_array: [
        { id: 0, nombre: "Transacción" },
        { id: 1, nombre: "Cheque" },
      ],
      año_array: [],

      enable_input: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],
      data_modificacion: {},
      toogle_collapse: false,
      toogle_modificacion: false,

      modificarAuxiliar: "",
      modificarDocumento: "",
      modificarReferencia: "",
      modificarAbono: "",
      modificarArchivo: null,
    };
    this.handleModificacion = this.handleModificacion.bind(this);
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.identificativo;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "transaccion",
      motivo: motivo,
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
    let año_array = this.state.año_array;
    let año_actual = new Date().getFullYear();
    for (let i = año_actual; i >= 2000; i--)
      año_array.push({ id: i, name: i.toString() });
    this.setState({ año_array });
    this.getListaPhxcdc();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  /* --------------- Funciones que actualizan las variables de los inputs --------------- */
  handleCerrar = () => {
    this.props.toogleConsulta();
    this.clearState();
  };
  handleNumero = (e) => {
    let numero = e.target.value;
    this.setState({ numero });
  };
  handleConcepto = (e) => {
    let concepto = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["concepto"] = concepto;
    this.setState({ concepto, data_modificacion });
  };
  handleFecha = (e) => {
    let fecha = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["fecha"] = fecha;
    this.setState({ fecha, data_modificacion });
  };
  handleTipo_transaccion = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let data_modificacion = this.state.data_modificacion;
    let tipo_transaccion = e[label];
    let id_transaccion = e[label_id];
    data_modificacion["tipo"] = id_transaccion;
    this.setState({
      tipo_transaccion,
      id_transaccion,
      tipo_movimiento: "Seleccione un tipo",
      id_tipo_movimiento: "",
      data_modificacion,
    });
  };
  handleBeneficiario = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let beneficiario = e[label];
    let id_beneficiario = e[label_id];
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["beneficiario"] = id_beneficiario;
    this.setState({ beneficiario, id_beneficiario, data_modificacion });
  };
  handleObservaciones = (e) => {
    let observaciones = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["observaciones"] = observaciones;
    this.setState({ observaciones, data_modificacion });
  };
  handleTipo_movimiento = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_movimiento = e[label];
    let id_tipo_movimiento = e[label_id];
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["tipo_movimiento"] = id_tipo_movimiento;
    this.setState({ tipo_movimiento, id_tipo_movimiento, data_modificacion });
  };
  handleDocumento = (e) => {
    let documento = e.target.value;
    this.setState({ documento });
  };
  handleReferencia = (e) => {
    let referencia = e.target.value;
    this.setState({ referencia });
  };
  handleAbono = (e) => {
    let abono = e.target.value;
    this.setState({ abono });
  };
  handleBanco = (e) => {
    const selectedValue = e.target.value;
    const [numero] = selectedValue.split(" - ");
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["banco"] = numero;
    this.setState({
      bancos: numero,
      data_modificacion,
    });
  };
  handleImporte = (e) => {
    let importe = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["importe"] = importe;
    this.setState({ importe, data_modificacion });
  };
  handleArchivo = (file) => {
    let archivo = file;
    if (archivo.type === "text/xml") {
      let arch = new FileReader();
      arch.onload = (event) => {
        this.setState({ archivo_xml: archivo });
        this.handleXMLS(event.target.result);
      };
      arch.readAsText(archivo);
    } else if (archivo.type === "application/pdf")
      this.setState({ archivo_pdf: archivo });
    else message.message_modal("Error", "El archivo no es compatible", 500);
  };
  handleAño = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let año = e[label];
    let id_año = e[label_id];
    this.setState({ año, id_año });
  };
  toogleCollapse = (e) => {
    this.setState((prevState) => ({
      toogle_collapse: !prevState.toogle_collapse,
    }));
  };
  handleEliminaArchivoXML = () => {
    this.setState({ archivo_xml: null });
  };
  handleEliminaArchivoPDF = () => {
    this.setState({ archivo_pdf: null });
  };
  handleXML = (XMLData) => {
    axios
      .get(XMLData, {
        "Content-Type": "application/xml; charset=utf-8",
      })
      .then((res) => {
        let comprobante = new XMLParser().parseFromString(res.data);
        let emisor = comprobante.getElementsByTagName("cfdi:Emisor")[0];
        let receptor = comprobante.getElementsByTagName("cfdi:Receptor")[0];
        let timbre = comprobante.getElementsByTagName(
          "tfd:TimbreFiscalDigital"
        )[0];
        this.setState(
          {
            comprobante_campos: {
              folio: comprobante.attributes.Folio,
              fecha: comprobante.attributes.Fecha,
              forma_de_pago: comprobante.attributes.FormaPago,
              no_certificado: comprobante.attributes.NoCertificado,
              moneda: comprobante.attributes.Moneda,
              total: comprobante.attributes.Total,
              metodo_pago: comprobante.attributes.MetodoPago,
              tipo_comprobante: comprobante.attributes.TipoDeComprobante,
              numero_pago: comprobante.attributes.NumCtaPago,
              certificado: comprobante.attributes.Certificado,
              version: comprobante.attributes.Version,
            },
            emisor_campos: {
              rfc: emisor.attributes.Rfc,
              nombre: emisor.attributes.Nombre,
            },
            receptor_campos: {
              rfc: receptor.attributes.Rfc,
            },
            timbre_campos: {
              version: timbre.attributes.Version,
              uuid: timbre.attributes.UUID,
              fecha_timbrado: timbre.attributes.FechaTimbrado,
              sello_cfd: timbre.attributes.SelloCFD,
              sello_sat: timbre.attributes.SelloSAT,
            },
          },
          () => {
            this.methodPOST_SOAP();
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleXMLS = (XMLData) => {
    let comprobante = new XMLParser().parseFromString(XMLData);
    let emisor = comprobante.getElementsByTagName("cfdi:Emisor")[0];
    let receptor = comprobante.getElementsByTagName("cfdi:Receptor")[0];
    let timbre = comprobante.getElementsByTagName("tfd:TimbreFiscalDigital")[0];
    if (
      emisor === undefined ||
      receptor === undefined ||
      timbre === undefined
    ) {
      message.message_modal(
        "Error",
        "La factura no cumple con el formato del SAT",
        500
      );
      this.setState({
        comprobante_campos: {},
        emisor_campos: {},
        receptor_campos: {},
        timbre_campos: {},
        enable_add: true,
        archivo: {},
      });
    } else
      this.setState(
        {
          comprobante_campos: {
            folio: comprobante.attributes.Folio,
            fecha: comprobante.attributes.Fecha,
            forma_de_pago: comprobante.attributes.FormaPago,
            no_certificado: comprobante.attributes.NoCertificado,
            moneda: comprobante.attributes.Moneda,
            total: comprobante.attributes.Total,
            metodo_pago: comprobante.attributes.MetodoPago,
            tipo_comprobante: comprobante.attributes.TipoDeComprobante,
            numero_pago: comprobante.attributes.NumCtaPago,
            certificado: comprobante.attributes.Certificado,
            version: comprobante.attributes.Version,
          },
          emisor_campos: {
            rfc: emisor.attributes.Rfc,
            nombre: emisor.attributes.Nombre,
          },
          receptor_campos: {
            rfc: receptor.attributes.Rfc,
          },
          timbre_campos: {
            version: timbre.attributes.Version,
            uuid: timbre.attributes.UUID,
            fecha_timbrado: timbre.attributes.FechaTimbrado,
            sello_cfd: timbre.attributes.SelloCFD,
            sello_sat: timbre.attributes.SelloSAT,
          },
        },
        () => {
          this.methodPOST_SOAP();
        }
      );
  };
  handleMovimientos_desglose = () => {
    if (this.state.referencia.length === 0)
      message.message_modal("Advertencia", "Debe tener una Referencia", 400);
    else
      this.methodPOST_API(utility.url_desglose_operacion, "movimientos", {
        referencia: this.state.referencia,
      });
  };
  buildFormData(formData, data, parentKey) {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;
      formData.append(parentKey, value);
    }
  }
  jsonToFormData(data) {
    const formData = new FormData();
    this.buildFormData(formData, data);
    return formData;
  }
  handleAgregar = () => {
    let movimientos = this.state.movimientos;
    let data = {
      auxiliar: this.state.id_tipo_movimiento,
      documento: this.state.documento,
      referencia: this.state.referencia,
      abono: this.state.abono,
      archivo: "-",
    };
    movimientos.push(data);
    this.setState({
      movimientos,
      archivo: {},
      tipo_movimiento: "Seleccione un tipo",
      documento: "",
      referencia: "",
      abono: "",
      comprobante_campos: {},
      emisor_campos: {},
      receptor_campos: {},
      timbre_campos: {},
    });
  };
  handleElimina = (e) => {
    let movimientos = this.state.movimientos;
    let desglose = this.state.desglose;
    let id = parseInt(e.target.id);
    for (let i = 0; i < desglose.length; i++)
      for (let j = 0; j < desglose[i].movimientos.length; j++)
        if (id === desglose[i].movimientos[j].auxiliar)
          desglose[i].movimientos.splice(j, 1);
    movimientos.splice(e.target.value, 1);
    this.setState({ movimientos, desglose });
  };
  handleSet_archivo = () => {
    let archivo_xml = this.state.archivo_xml;
    let archivo_pdf = this.state.archivo_pdf;
    let movimientos_select = this.state.movimientos_select;
    let movimientos = this.state.movimientos;
    let desglose = this.state.desglose;
    let data = {
      xml: archivo_xml,
      pdf: archivo_pdf,
      movimientos: [],
    };
    for (let i = 0; i < movimientos_select.length; i++) {
      movimientos[
        parseInt(movimientos_select[i].slice(4, "mov_0".length))
      ].archivo = archivo_xml.name;
      data.movimientos.push(
        movimientos[parseInt(movimientos_select[i].slice(4, "mov_0".length))]
      );
    }
    desglose.push(data);
    this.setState({
      desglose,
      movimientos,
      archivo_xml: null,
      archivo_pdf: null,
      enable_add: true,
    });
  };
  handleSelect = (e) => {
    let id = e.target.id;
    let movimientos_select = this.state.movimientos_select;
    let is_checked = e.target.checked;
    if (is_checked) {
      movimientos_select.push(id);
      this.setState({ movimientos_select });
    } else
      for (let i = 0; i < movimientos_select.length; i++)
        if (movimientos_select[i] === id) movimientos_select.splice(i, 1);
    this.setState({ movimientos_select });
  };
  /* --------------- Fin de funciones que actualizan las variables de los inputs --------------- */

  /* --------------- Funciones de eventos que generan una petición --------------- */
  handleConsulta = () => {
    if (this.state.año === "Seleccione un año" || this.state.numero === "")
      message.message_modal("Advertencia", "Debe tener un año y número", 400);
    else {
      this.methodPOST_API(utility.url_consulta_transaccion, "consulta", {
        numero: this.state.numero,
        año: this.state.año,
      });
    }
  };

  getListaPhxcdc() {
    const url = utility.url_cat_phxcdc;
    axios
      .get(url)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              listaPhxcdc: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }
  handleConsolidar = () => {

    axios
      .post(utility.url_info_bancos, {
       
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted) {
            
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }
  handleModificar = () => {
    if (this.state.año === "Seleccione un año" || this.state.numero === "")
      message.message_modal("Advertencia", "Debe tener un año y número", 400);
    else {
      if (this.state.status === "A")
        message
          .message_confimacion_textarea(
            "¿Desea continuar con la operación modificar?",
            "Ingrese sus observaciones"
          )
          .then((res) => {
            if (res) {
              let data = this.state.data_modificacion;
              let observaciones = document.getElementById(
                "observaciones_textarea"
              ).value;
              if (this.state.desglose.length > 0)
                data["desglose"] = this.state.desglose;
              if (this.state.movimientos_elimina.length > 0)
                data["eliminados"] = this.state.movimientos_elimina;
              if (observaciones.length === 0)
                message.message_modal(
                  "Completar el campo:",
                  "Motivo de Modificación",
                  400
                );
              else if (Object.values(this.state.data_modificacion).length > 0) {
                data["id"] = this.state.id;
                data["observaciones"] = observaciones;
                axios
                  .put(
                    utility.url_alta_transaccion,
                    this.jsonToFormData(data),
                    { headers: { "Content-type": "multipart/form-data" } }
                  )
                  .then((response) => {
                    this.setState(
                      {
                        identificativo: response.data.results.results[0].numero,
                      },
                      () => {
                        this.sendNotificacionHandler("modificar");
                      }
                    );
                    this.handleCerrar();
                    message.message_modal(
                      response.data.title,
                      response.data.text,
                      response.status
                    );
                    this.props.update(1);
                  })
                  .catch((error) => {
                    if (error.response)
                      message.message_modal(
                        error.response.data.title,
                        error.response.data.text,
                        error.response.status
                      );
                  });
              } else
                message.message_modal(
                  "Advertencia",
                  "No hay alguna modificación",
                  400
                );
            }
          });
      else if (this.state.status === "C") {
        message
          .message_confirmacion_password(
            "¿Desea continuar con la operación modificar?",
            "Ingrese su contraseña y observaciones"
          )
          .then((res) => {
            if (res === true) {
              let mov = {
                user: document.getElementById("usuario_modificador").value,
                pass: document.getElementById("contraseña_modificador").value,
                motivo: document.getElementById("observaciones_textarea").value,
              };
              if (mov.motivo.length === 0)
                message.message_modal(
                  "Completar el campo:",
                  "Motivo de Modificación",
                  400
                );
              else {
                let data = this.state.data_modificacion;
                let observaciones = mov.motivo;
                if (this.state.desglose.length > 0)
                  data["desglose"] = this.state.desglose;
                if (this.state.movimientos_elimina.length > 0)
                  data["eliminados"] = this.state.movimientos_elimina;
                if (observaciones.length === 0)
                  message.message_modal(
                    "Completar el campo:",
                    "Motivo de Modificación",
                    400
                  );
                else if (
                  Object.values(this.state.data_modificacion).length > 0
                ) {
                  data["id"] = this.state.id;
                  data["observaciones"] = observaciones;
                  axios
                    .put(
                      utility.url_alta_transaccion,
                      this.jsonToFormData(data),
                      { headers: { "Content-type": "multipart/form-data" } }
                    )
                    .then((response) => {
                      this.handleCerrar();
                      message.message_modal(
                        response.data.title,
                        response.data.text,
                        response.status
                      );
                      this.props.update(1);
                    })
                    .catch((error) => {
                      if (error.response)
                        message.message_modal(
                          error.response.data.title,
                          error.response.data.text,
                          error.response.status
                        );
                    });
                } else
                  message.message_modal(
                    "Advertencia",
                    "No hay alguna modificación",
                    400
                  );
              }
            }
          });
      } else
        message.message_modal("Advertencia", "No hay alguna modificación", 400);
    }
  };
  handleModificacion = (aux, doc, ref, abono, archivo) => {
    let nombre;
    {
      this.state.id_transaccion === "CE" || this.state.id_transaccion === "PE"
        ? (nombre = utility.obtener_nombre_pagos(aux, this.props.egresos))
        : (nombre = utility.obtener_nombre_pagos(aux, this.props.phxcdc));
    }

    this.setState({
      modificarAuxiliar: nombre,
      modificarDocumento: doc,
      modificarReferencia: ref,
      modificarAbono: abono,
      modificarArchivo: archivo,
    });

    this.setState((prevState) => ({
      toogle_modificacion: !prevState.toogle_modificacion,
    }));
  };
  handleElimina_movimiento = (e) => {
    let index = parseInt(e.target.value.split("-")[1]);
    let id = e.target.value.split("-")[0];
    let movimientos_actuales = this.state.movimientos_actuales;
    let movimientos_elimina = this.state.movimientos_elimina;
    movimientos_actuales.splice(index, 1);
    movimientos_elimina.push(id);
    this.setState({ movimientos_actuales, movimientos_elimina });
  };
  /* --------------- Fin de funciones de eventos que generan una petición --------------- */
  clearState = () => {
    this.setState({
      id: "",
      numero: "",
      concepto: "",
      fecha: "",
      observaciones: "",
      importe: "",
      referencia: "",
      bancos: "Seleccione un banco",
      archivo_xml: null,
      archivo_pdf: null,
      archivo_valido: false,
      enable_add: true,
      desglose: [],
      desglose_operacion: [],
      movimientos: [],
      movimientos_actuales: [],
      movimientos_select: [],

      beneficiario: "Seleccione un beneficiario",
      id_beneficiario: "",
      tipo_transaccion: "Seleccione un tipo",
      id_transaccion: "",
      banco: "Seleccione un banco",
      id_banco: "",
      tipo_movimiento: "Seleccione un tipo",
      id_tipo_movimiento: "",
      tipo: "Seleccione un tipo",
      id_tipo: "",
      año: "Seleccione un año",
      id_año: "",

      comprobante_campos: {},
      emisor_campos: {},
      receptor_campos: {},
      timbre_campos: {},

      tipo_transaccion_array: [
        { id: "CC", text: "Comprobable del cliente" },
        { id: "CE", text: "Comprobable de la empresa" },
        { id: "PC", text: "Por comprobar del cliente" },
        { id: "PE", text: "Por comprobar de la empresa" },
      ],
      tipo_movimiento_array: [
        { id: 0, nombre: "Transacción" },
        { id: 1, nombre: "Cheque" },
      ],

      enable_input: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],
      data_modificacion: {},
      toogle_collapse: false,
    });
  };
  /**
   * Función que permite hacer una petición de forma POST.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} data Objeto a mandar atraves del metodo POST.
   */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "codigo_postal":
              if (this._isMounted)
                this.setState({
                  colonias: response.data.results,
                });
              break;
            case "consulta":
              this.setState({
                id: response.data.results.id,
                beneficiario: response.data.results.beneficiario.nombre,
                banco: this.bancoCompleto(response.data.results.banco),
                concepto: response.data.results.concepto,
                fecha: response.data.results.fecha,
                importe: response.data.results.importe,
                cheque: response.data.results.cheque,
                observaciones: response.data.results.observaciones,
                tipo_transaccion: this.state.tipo_transaccion_array.find(
                  (tipo) => tipo.id === response.data.results.tipo
                ).text,
                movimientos_actuales: response.data.results.t_desglose,
                status: response.data.results.status,
                enable_input: [
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                ],
              });
              break;
            case "movimientos":
              let movimientos = this.state.movimientos;
              let lista = response.data.results.lista_pagos;
              for (let i = 0; i < lista.length; i++) {
                let data = {
                  auxiliar: lista[i].tpo_pago,
                  documento: lista[i].factura,
                  referencia: lista[i].referencia,
                  abono: lista[i].cantidad,
                  archivo: "-",
                };
                movimientos.push(data);
              }
              this.setState({
                desglose_operacion: response.data.results.lista_pagos,
                referencia: "",
                movimientos,
              });
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handleBancos = (e) => {
    const selectedValue = e.target.value;
    const [numero] = selectedValue.split(" - "); 
    this.setState({
      banco: numero,
    });
  };
  bancoCompleto = (banco) => {
    const selectedValue = banco;
    const [numero] = selectedValue.split(" - "); 


    axios
      .post(utility.url_info_bancos, {
        numero,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted) {
            const nombre = response.data.results.nombre;
            let nombreCompleto = banco + " - " + nombre;
            this.setState({ bancos: nombreCompleto });
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /* bancoCompleto = (banco) => {
    const selectedValue = banco;
    const [numero, tpo_bancoFull] = selectedValue.split(".");
    const [tipo_banco] = tpo_bancoFull.split(" - ");

    axios
      .post(utility.url_info_bancos, {
        numero,
        tipo_banco,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted) {
            const nombre = response.data.results.nombre;
            let nombreCompleto = banco + " - " + nombre;
            this.setState({ bancos: nombreCompleto });
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }; */

  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  methodPOST_SOAP = () => {
    let sr =
      '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">' +
      "<soapenv:Header/>" +
      "<soapenv:Body>" +
      "<tem:Consulta>" +
      "<!--Optional:-->" +
      `<tem:expresionImpresa><![CDATA[?re=${this.state.emisor_campos.rfc}&rr=${this.state.receptor_campos.rfc}&tt=${this.state.comprobante_campos.total}
                &id=${this.state.timbre_campos.uuid}]]></tem:expresionImpresa>` +
      "</tem:Consulta>" +
      "</soapenv:Body>" +
      "</soapenv:Envelope>";
    swal({
      title: "Validando factura...",
      buttons: false,
      closeOnClickOutside: false,
      content: (
        <div>
          <Spinner
            color="success"
            style={{
              width: "5rem",
              height: "5rem",
              marginBottom: "26px",
              animation: "1.25s linear infinite spinner-border",
            }}
          >
            Loading...
          </Spinner>
        </div>
      ),
    });
    axios
      .post("/ConsultaCFDIService.svc", sr, {
        headers: {
          "Content-type": 'text/xml;charset="utf-8"',
          Accept: "text/xml",
          SOAPAction: "http://tempuri.org/IConsultaCFDIService/Consulta",
          "cache-control": "no-cache",
          Host: "consultaqr.facturaelectronica.sat.gob.mx",
        },
      })
      .then((res) => {
        let comprobante = new XMLParser().parseFromString(res.data);
        let codigo_estatus =
          comprobante.getElementsByTagName("a:CodigoEstatus")[0];
        let estado = comprobante.getElementsByTagName("a:Estado")[0];
        swal.close();
        if (
          codigo_estatus.value ===
          "S - Comprobante obtenido satisfactoriamente."
        )
          if (estado.value === "Vigente") {
            message.message_modal(
              "Factura valida",
              "Puede agregar el movimiento",
              200
            );
            this.setState({ enable_add: false });
          } else {
            message.message_modal(
              "Factura cancelada",
              "No puede agregar el movimiento",
              400
            );
            this.setState({ enable_add: true });
          }
        else {
          message.message_modal(
            "No existe la factura",
            "Seleccione otro archivo",
            500
          );
          this.setState({ enable_add: true });
        }
      })
      .catch((err) => {
        if (err)
          message.message_modal("Ocurrio un error", "Intentelo de nuevo", 500);
      });
  };
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Consulta Transacción
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <SelectComponent
                bootstrap="col-lg-5 col-12"
                name_text="name"
                name_index="id"
                id="año_alta_transaccion"
                nameLabel="Año"
                options={this.state.año_array}
                value={this.state.año}
                onChange={this.handleAño}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-5 col-12"
                id="numero_alta_transaccion"
                nameLabel="Número de Cheque o Tranferencia"
                value={this.state.numero}
                onChange={this.handleNumero}
              />
              <Button
                color="primary"
                className="col-lg-2 col-12 mb-2"
                onClick={this.handleConsulta}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="concepto_alta_transaccion"
                nameLabel="Concepto"
                value={this.state.concepto}
                onChange={this.handleConcepto}
                disabled={this.state.enable_input[0]}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="importe_alta_transaccion"
                nameLabel="Importe"
                value={this.state.importe}
                onChange={this.handleImporte}
                disabled={this.state.enable_input[1]}
              />
              <MyInput
                type="date"
                bootstrap="col-lg-4 col-12"
                id="fecha_alta_transaccion"
                nameLabel="Fecha de alta"
                value={this.state.fecha}
                onChange={this.handleFecha}
                disabled={this.state.enable_input[2]}
              />
            </FormGroup>
            <FormGroup className="row mb-2">
              <SelectComponent
                bootstrap="col-lg-4 col-12"
                name_text="text"
                name_index="id"
                id="tipo_transaccion_alta_transaccion"
                nameLabel="Tipo de Transacción"
                options={this.state.tipo_transaccion_array}
                value={this.state.tipo_transaccion}
                onChange={this.handleTipo_transaccion}
                disabled={this.state.enable_input[3]}
              />
              <SelectComponent
                bootstrap="col-lg-4 col-12"
                name_text="nombre"
                name_index="id"
                id="id"
                nameLabel="Beneficiario"
                options={this.props.beneficiarios}
                value={this.state.beneficiario}
                onChange={this.handleBeneficiario}
                disabled={this.state.enable_input[4]}
              />
              <MySelect
                bootstrap="col-lg-4 col-12"
                nameLabel="Bancos"
                id="banco_alta_transaccion"
                first_option={this.state.bancos}
                required
                value={this.state.bancos}
                onChange={this.handleBanco}
                disabled={this.state.enable_input[5]}
              >
                {this.props.bancos.map((ban) => (
                  <option key={ban.numero}>
                    {ban.numero} - {ban.nombre}
                  </option>
                ))}
              </MySelect>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyTextArea
                bootstrap="col-12"
                nameLabel="Observaciones"
                id="observaciones_alta_transaccion"
                onChange={this.handleObservaciones}
                value={this.state.observaciones}
                disabled={this.state.enable_input[6]}
              />
            </FormGroup>
            <br></br>
            <div className="row mb-2 mx-1">
              <h5 className="col-md-11 col-9 text-md-left px-0 mb-0">
                Agregar Movimiento
              </h5>
              <Button
                color="info"
                onClick={this.toogleCollapse}
                className="col-md-1 col-3"
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
            <Collapse isOpen={this.state.toogle_collapse}>
              <FormGroup className="row mb-0">
                <MyInput
                  type="text"
                  bootstrap="col-lg-4 col-8"
                  id="referencia_alta_transaccion"
                  nameLabel="Referencia"
                  value={this.state.referencia}
                  onChange={this.handleReferencia}
                  disabled={this.state.enable_input[7]}
                />
                <MyInput
                  type="text"
                  bootstrap="col-lg-4 col-12"
                  id="documento_alta_transaccion"
                  nameLabel="Documento"
                  value={this.state.documento}
                  onChange={this.handleDocumento}
                  disabled={this.state.enable_input[9]}
                />
                <MyInput
                  type="number"
                  bootstrap="col-lg-4 col-12"
                  id="abono_alta_transaccion"
                  nameLabel="Abono"
                  value={this.state.abono}
                  onChange={this.handleAbono}
                  disabled={this.state.enable_input[10]}
                />
              </FormGroup>
              <FormGroup className="row mb-2">
                {this.state.id_transaccion === "CE" ||
                this.state.id_transaccion === "PE" ? (
                  <SelectComponent
                    bootstrap="col-lg-10 col-8"
                    name_text="nombre"
                    name_index="clave"
                    id="egreso_alta_transaccion"
                    nameLabel=" Tipo de Egreso"
                    options={this.props.egresos}
                    value={this.state.tipo_movimiento}
                    onChange={this.handleTipo_movimiento}
                    disabled={this.state.enable_input[11]}
                  />
                ) : (
                  <SelectComponent
                    bootstrap="col-lg-10 col-8"
                    name_text="nombre"
                    name_index="clave"
                    id="phxcdc_alta_transaccion"
                    nameLabel="Tipo de PHXCDC"
                    options={this.props.phxcdc}
                    value={this.state.tipo_movimiento}
                    onChange={this.handleTipo_movimiento}
                    disabled={this.state.enable_input[11]}
                  />
                )}
                <Button
                  color="primary"
                  className="col-lg-2 col-4"
                  onClick={this.handleAgregar}
                  disabled={this.state.enable_input[12]}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </FormGroup>
              <FormGroup className="row mb-0 mt-2 mt-lg-0">
                <MyDropzone
                  handleArchivo={this.handleArchivo}
                  disabled={this.state.enable_input[13]}
                />
                <div className="col-12 mt-2 d-flex justify-content-center flex-wrap">
                  {this.state.archivo_xml !== null ? (
                    <Badge color="light" className="border mb-2">
                      {this.state.archivo_xml.name}&nbsp;
                      <spam
                        style={{ cursor: "pointer" }}
                        onClick={this.handleEliminaArchivoXML}
                      >
                        &times;
                      </spam>
                    </Badge>
                  ) : null}
                  {this.state.archivo_pdf !== null ? (
                    <Badge color="light" className="border mb-2">
                      {this.state.archivo_pdf.name}&nbsp;
                      <spam
                        style={{ cursor: "pointer" }}
                        onClick={this.handleEliminaArchivoPDF}
                      >
                        &times;
                      </spam>
                    </Badge>
                  ) : null}
                </div>
                <Button
                  color="primary"
                  className="col-12 mb-2"
                  onClick={this.handleSet_archivo}
                  disabled={this.state.enable_add}
                >
                  <FontAwesomeIcon icon={faFileExport} />
                </Button>
              </FormGroup>
            </Collapse>
            <br></br>
          </Form>
          <Table size="sm" bordered hover responsive>
            <thead className="text-center header-table">
              <tr>
                <td></td>
                <td>Movimiento</td>
                <td>Documento</td>
                <td>Referencia</td>
                <td>Abono</td>
                <td>Archivo</td>
                <td></td>
                <td></td>
              </tr>
            </thead>
            <tbody className="body-table">
              {this.state.movimientos_actuales.map((data, index) => (
                <tr>
                  <td className="text-center text-white">--</td>
                  <td className="text-center">
                    {this.state.id_transaccion === "CE" ||
                    this.state.id_transaccion === "PE"
                      ? utility.obtener_nombre_pagos(
                          data.auxiliar,
                          this.props.egresos
                        )
                      : utility.obtener_nombre_pagos(
                          data.auxiliar,
                          this.props.phxcdc
                        )}
                  </td>
                  <td className="text-center">{data.documento}</td>
                  <td className="text-center">{data.referencia}</td>
                  <td className="text-center">{data.abono}</td>
                  <td className="text-center">
                    {data.xml ? data.xml.split("/")[6] : null}
                  </td>
                  <td className="text-center">
                    <Button
                      size="sm"
                      color="danger"
                      value={`${data.id}-${index}`}
                      onClick={this.handleElimina_movimiento}
                    >
                      Eliminar
                    </Button>
                  </td>
                  <td className="text-center">
                    <Button
                      size="sm"
                      color="info"
                      onClick={() =>
                        this.handleModificacion(
                          data.auxiliar,
                          data.documento,
                          data.referencia,
                          data.abono,
                          data.xml
                        )
                      }
                    >
                      Modificar
                    </Button>
                  </td>
                </tr>
              ))}
              {this.state.movimientos.map((data, index) => (
                <tr>
                  <td className="text-center">
                    <CustomInput
                      type="checkbox"
                      id={`mov_${index}`}
                      label=""
                      onChange={this.handleSelect}
                    />
                  </td>
                  <td className="text-center">
                    {this.state.id_transaccion === "CE" ||
                    this.state.id_transaccion === "PE"
                      ? utility.obtener_nombre_pagos(
                          data.auxiliar,
                          this.props.egresos
                        )
                      : utility.obtener_nombre_pagos(
                          data.auxiliar,
                          this.props.phxcdc
                        )}
                  </td>
                  <td className="text-center">{data.documento}</td>
                  <td className="text-center">{data.referencia}</td>
                  <td className="text-center">{data.abono}</td>
                  <td className="text-center">{data.archivo}</td>
                  <td className="text-center">
                    <Button
                      size="sm"
                      color="danger"
                      id={data.auxiliar}
                      value={index}
                      onClick={this.handleElimina}
                    >
                      Eliminar
                    </Button>
                  </td>
                  <td className="text-center">
                    <Button
                      size="sm"
                      color="info"
                      onClick={() =>
                        this.handleModificacion(
                          data.auxiliar,
                          data.documento,
                          data.referencia,
                          data.abono,
                          data.archivo
                        )
                      }
                    >
                      Modificar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          {/* <Button
            color="success"
            className="mr-2"
            onClick={this.handleConsolidar}
            disabled={permisos.obtener_permiso_especifico(
              "consolidar",
              this.props.permisos
            )}
          >
            Consolidar
          </Button> */}
          <Button
            color="info"
            className="mr-2"
            onClick={this.handleModificar}
            disabled={permisos.obtener_permiso_especifico(
              "modificar",
              this.props.permisos
            )}
          >
            Modificar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
        {this.state.toogle_modificacion && (
          <ModalModificaMov
            toogle_modificacion={this.state.toogle_modificacion}
            toogleModificacion={this.handleModificacion}
            propAux={this.state.modificarAuxiliar}
            propDoc={this.state.modificarDocumento}
            propRef={this.state.modificarReferencia}
            propAbono={this.state.modificarAbono}
            propArchivo={this.state.modificarArchivo}
            listaPhxcdc={this.state.listaPhxcdc}
          />
        )}
      </Modal>
    );
  }
}
export default ModalConsulta;
