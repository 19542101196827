/* URL's que se ocupan en la aplicación de USUARIOS */
export const url_alta_usuario = '/DjangoWeb/UsuariosApp/registra_modifica/empleado';
export const url_alta_cliente = '/DjangoWeb/UsuariosApp/registra_modifica/cliente';
export const url_alta_transportista = '/DjangoWeb/UsuariosApp/registra_modifica/transportista';
export const url_alta_administrador = '/DjangoWeb/UsuariosApp/registra/administrador';
export const url_paginacion = '/DjangoWeb/UsuariosApp/consulta/usuarios/principal';
export const url_elimina_usuario = '/DjangoWeb/UsuariosApp/borrar/usuario';
export const url_desactiva_usuario = '/DjangoWeb/UsuariosApp/deactivar/usuario';
export const url_activa_usuario = '/DjangoWeb/UsuariosApp/activar/usuario';
export const url_consulta_usuario = '/DjangoWeb/UsuariosApp/consulta/usuario';
export const url_lista_permisos = '/DjangoWeb/UsuariosApp/consulta/permisos_aplicaciones/lista';

export const url_lista_empleados = '/DjangoWeb/EmpleadosApp/consulta/empleados/lista';

export const url_lista_clientes = '/DjangoWeb/BaseApp/consulta/clientes/lista';

/**
 * Función que nos permite filtar la tabla por el input general que serra de tipo text.
 */
 export const general_search = (e) => {
    e.preventDefault();
    let tr = document.getElementById('tabla_usuario').getElementsByTagName('tr');
    let data_input = document.getElementById('busqueda_general_usuario').value.toUpperCase();
    for (let i = 0; i < tr.length; i++) {
        let mostrar = false;
        let data = tr[i].getElementsByTagName('p');
        for (let j = 0; j < data.length; j++) {
            if (data_input === "") {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
            else if (data[j].textContent.toUpperCase().includes(data_input)) {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "rgba(0,123,255,1)";
                data[j].style.color = "#fff";
            }
            else {
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}
/**
 * Función que permite retornar que pipo de alta se hara.
 * @param {Number} num
 * @returns {String} Tipo de alata a realizar.
 */
export const tipo_alta_name = (num) => {
    switch (num) {
        case -1: return "Seleccione un tipo de usuario";
        case 0: return "Administrador";
        case 1: return "Empleado";
        case 2: return "Cliente";
        default: break;
    }
}
/**
 * Valida los datos de el objeto dpendiendo si es un empleado, cliente o administrador.
 * @param {String} user_type Tipo de usuario 
 * @param {Object} data Objeto a evaluar sus datos 
 * @returns {Object} Objeto con la validación
 */
export const valida_datos_alta = (user_type, data) => {
    let message = "";
    if (user_type === "Cliente") {
        if (data.name.length === 0)
            message += "Número de Cliente, ";
        if (data.usuario.length === 0)
            message += "Nombre de Usuario, ";
        if (data.email.length === 0)
            message += "Correo, ";
        if (data.password.length === 0)
            message += "Contraseña, ";
        if (data.password_m.length === 0)
            message += "Contraseña de Modificación, ";
    }
    else if (user_type === "Transportista") {
        if (data.usuario.length === 0)
            message += "Razón Social, ";
        if (data.email.length === 0)
            message += "Correo, ";
        if (data.password.length === 0)
            message += "Contraseña, ";
        if (data.name.length === 0)
            message += "RFC, ";
    }
    else if (user_type === "Empleado") {
        if (data.usuario.length === 0)
            message += "Nombre de Usuario, ";
        if (data.email.length === 0)
            message += "Correo, ";
        if (data.password.length === 0)
            message += "Contraseña, ";
        if (data.password_m.length === 0)
            delete data.password_m;
        if (data.empleado === "null")
            message += "Empleado, ";
    }
    else if (user_type === "Administrador") {
        if (data.usuario.length === 0)
            message += "Nombre de Usuario, ";
        if (data.email.length === 0)
            message += "Correo, ";
        if (data.password.length === 0)
            message += "Contraseña, ";
        if (data.password_m.length === 0)
            message += "Contraseña de Modificación, ";
        if (data.empleado === "null")
            message += "Empleado, ";
    }
    return {
        valida: message.length === 0 ? true : false,
        mensaje: message
    };
}
/**
 * Función que permite retornar los permisos por aplicación.
 * @param {String} name_app Nombre de la aplicación.
 * @param {Object} array_apps Obteto que contiene todos los permisos de las aplicaciones.
 * @returns Permisos.
 */
export const permisos_for_app = (name_app, object_apps) => {
    switch (name_app) {
        //APPS DE CONTABILIDAD
        case "polizas": return permisos_object_to_string(object_apps.poliza);
        case "balance": return permisos_object_to_string(object_apps);
        case "cuentas_auxiliares": return permisos_object_to_string(object_apps.cuentasauxiliares);
        case "estado_resultados": return permisos_object_to_string(object_apps);
        case "clientes": return permisos_object_to_string(object_apps.clientes);
        case "balanza": return permisos_object_to_string(object_apps);
        case "proveedores": return permisos_object_to_string(object_apps.catproveedores);
        case "historico_poliza": return permisos_object_to_string(object_apps.polizahistorico);
        //APPS DE BANCOS
        case "solicitud_anticipo": return permisos_object_to_string(object_apps.anticiposolicitud);
        case "anticipo": return permisos_object_to_string(object_apps.anticipo);
        case "solicitud_anticipo_historico": return permisos_object_to_string(object_apps.anticiposolicitudhistorico);
        case "anticipo_historico": return permisos_object_to_string(object_apps.anticipohistorico);
        //APPS DE FACTURACIÓN

        //APPS DE OPERACIÓN
        case "notificacion": return permisos_object_to_string(object_apps.alerta);
        case "notificacion_historico": return permisos_object_to_string(object_apps.alertahistorico);
        //APPS DE CATALOGOS
        case "consolidadoras": return permisos_object_to_string(object_apps.catconsolidadoras);
        case "patentes": return permisos_object_to_string(object_apps.catpatentes);
        case "contabilidad_electronica": return permisos_object_to_string(object_apps.contabilidadelectronicaarchivo);
        //APPS DE ADMINISTRACIÓN
        case "usuarios": return permisos_object_to_string(object_apps.user);
        case "departamentos": return permisos_object_to_string(object_apps.catdepartamentos);
        case "empleados": return permisos_object_to_string(object_apps.catempleados);
        case "puestos": return permisos_object_to_string(object_apps.catpuestos);
        default: break;
    }
}
/**
 * Función que permite transfromar los permisos en String.
 * @param {Object} permisos Objeto que contiene los permisos de una aplicación.
 */
export const permisos_object_to_string = (permisos) => {
    if (!permisos) {
        console.error('El objeto de permisos es undefined');
        return [];
    }

    let json_permisos = JSON.stringify(permisos);
    let expresion = /"|{|}/ig;
    return json_permisos.replaceAll(expresion, '').split(',');
}

/**
 * Función que permite agregar una clase a un elemento dado du ID
 * @param {String} id ID del objeto del DOM 
 */
export const add_class_disabled = (id) => {
    document.getElementById(id).classList.add('input-animatedd');
}