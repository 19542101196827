import axios from "axios";
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Collapse,
} from "reactstrap";
import * as message from "../messages/messages";
import MySelect from "../utils/MySelect";
import * as utility from "../utils/utility";
import WebSocketInstance from "../../../utils/websocket";
import MyInput from "../utils/MyInput";
import MyTextArea from "../utils/MyTextArea";
import * as permisos from "../../../utils/permisos/permisos";

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      toogle_collapse: false,
      correos: [],
      app: [],
      tipoDoc: [],
      destino: "",
      asunto: "",
      mensaje: "",
      referencia: "",
      documento: "",
    };
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.identificativo;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "cuenta_auxiliar",
      motivo: motivo,
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  toogleCollapse = (e) => {
    this.setState((prevState) => ({
      toogle_collapse: !prevState.toogle_collapse,
    }));
  };

  handleDestino = (e) => {
    let destino = e.target.value;
    this.setState({ destino });
  };
  handleAsunto = (e) => {
    let asunto = e.target.value;
    this.setState({ asunto });
  };
  handleMensaje = (e) => {
    let mensaje = e.target.value;
    this.setState({ mensaje });
  };
  handleReferencia = (e) => {
    let referencia = e.target.value;
    this.setState({ referencia });
  };
  handleDocumento = (e) => {
    let documento = e.target.value;
    this.setState({ documento });
  };
  handleTipoDoc = (e) => {
    let tipoDoc = e.target.value;
    this.setState({ tipoDoc });
  };

  handleCerrar = () => {
    this.props.toogleNuevo();
    this.handleLimpiar();
  };

  handleLimpiar = () => {
    this.setState({
      toogle_collapse: false,
      correos: [],
      app: [],
      tipoDoc: [],
      destino: "",
      asunto: "",
      mensaje: "",
      referencia: "",
      documento: "",
    });
  };

  handleEliminar = (nombre) => {
    const toFind = nombre;
    const correos = this.state.correos.filter(
      (correos) => correos.nombre !== toFind
    );
    this.setState({
      correos: correos,
    });
  };

  handleAgregar = () => {
    if (
      this.state.app === "Seleccione una app" ||
      this.state.tipoDoc === "Seleccione un tipo de documento" ||
      this.state.referencia === "" ||
      this.state.documento === "" ||
      this.state.expediente === ""
    )
      message.warningMessage("Por favor, ingrese los campos faltantes");
    else {
      let descripcion = this.state.correos;

      let info = {
        nombre: this.state.asunto,
        fecha: "FECHA",
        tam: "2MB",
      };
      descripcion.push(info);
      this.setState({ correos: descripcion });
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_nuevo}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Nuevo Correo Electrónico
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                required
                type="text"
                bootstrap="col-lg-12"
                id="destino"
                nameLabel="Para:"
                value={this.state.destino}
                onChange={this.handleDestino}
              />
              <MyInput
                required
                type="text"
                bootstrap="col-lg-12"
                id="asunto"
                nameLabel="Asunto:"
                value={this.state.asunto}
                onChange={this.handleAsunto}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyTextArea
                bootstrap="col-12"
                id="mensaje"
                nameLabel="Mensaje:"
                value={this.state.mensaje}
                onChange={this.handleMensaje}
              />
            </FormGroup>
            <div className="row mb-2 mx-1">
              <h4 className="col-md-11 col-9 text-md-left px-0 mb-0">
                Adjuntar
              </h4>
              <Button
                color="info"
                onClick={this.toogleCollapse}
                className="col-md-1 col-3"
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
            <Collapse isOpen={this.state.toogle_collapse}>
              <FormGroup className="row mb-0">
                <MySelect
                  type="text"
                  bootstrap="col-lg-6"
                  nameLabel=""
                  id="tipoArchivo"
                  first_option="Seleccione"
                >
                  <option value={"1"}>Archivo</option>
                  <option value={"2"}>Expediente</option>
                </MySelect>
                <MySelect
                  bootstrap="col-lg-6"
                  id="bancos_alta_anticipo"
                  nameLabel="Aplicaciones"
                  first_option="Seleccione una aplicación"
                  required
                  onChange={this.handleApp}
                >
                  {this.state.app.map((ban) => (
                    <option key={ban.numero}>
                      {ban.numero} - {ban.nombre}
                    </option>
                  ))}
                </MySelect>
                <MySelect
                  required
                  type="text"
                  bootstrap="col-lg-6"
                  id="tipoDocumento"
                  nameLabel="Tipo De Documento"
                  first_option="Seleccione un tipo de documento"
                  value={this.state.tipoDoc}
                  onChange={this.handleTipoDoc}
                >
                  {this.state.tipoDoc.map((ban) => (
                    <option key={ban.numero}>
                      {ban.numero} - {ban.nombre}
                    </option>
                  ))}
                </MySelect>
                <MyInput
                  required
                  type="text"
                  bootstrap="col-lg-6"
                  id="documento"
                  nameLabel="Documento"
                  value={this.state.documento}
                  onChange={this.handleDocumento}
                />
              </FormGroup>
              <FormGroup className="row mb-0">
                <MyInput
                  required
                  type="text"
                  bootstrap="col-lg-6"
                  id="referencia"
                  nameLabel="Referencia"
                  value={this.state.referencia}
                  onChange={this.handleReferencia}
                />
                <Button
                  color="primary"
                  className="col-lg-6 col-12 mb-2"
                  onClick={this.handleAgregar}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </FormGroup>
              <Table size="sm" id="tabla_poliza" bordered hover responsive>
                <thead className="text-center header-table">
                  <tr>
                    <th>Nombre</th>
                    <th>Fecha</th>
                    <th>Tamaño</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="body-table">
                  {this.state.correos.map((email) => (
                    <tr key={email.nombre}>
                      <td>
                        <p className="mb-0 text-center">{email.nombre}</p>
                      </td>
                      <td>
                        <p className="mb-0 text-center">{email.fecha}</p>
                      </td>
                      <td>
                        <p className="mb-0 text-center">{email.tam}</p>
                      </td>
                      <td className="text-center">
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() => this.handleEliminar(email.nombre)}
                        >
                          Eliminar
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Collapse>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={this.handleAltaCorreo}
            disabled={
              this.state.mensaje === ""
                ? true
                : false && this.state.destino === ""
                ? true
                : false && permisos.obtener_permiso_especifico(
                  "enviar",
                  this.props.permisos
                )
            }
          >
            Enviar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalAlta;
