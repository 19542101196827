/* 
Autor: Alexis Yael Hernández Grimaldo
Descripción: El componenete genera un bloque correspondiente a la busqueda dinamica en la tabla para acceder a la información.
*/
import React, { Component } from "react";
import { Form, FormGroup, Button, Collapse } from "reactstrap";
import "../../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import MyInput from "../../utils/custom/MyInput";

class BusquedaEspecifica extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      toogle_collapse: false,
      change_form: false,
      numero_specific: "",
      aduana_specific: "",
      cliente_specific: "",
      inicial_specific: "",
      final_specific: "",
      referencia: "",
      observaciones_specific: "",
    };
  }
  toogleCollapse = (e) => {
    this.setState((prevState) => ({
      toogle_collapse: !prevState.toogle_collapse,
    }));
  };
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  /* ------------------- Funciones para capturdar datos de consulta filtrada de Solicitud de Anticipos -------------------------- */
  handleNumero = (e) => {
    this.setState({ numero_specific: e.target.value });
  };
  handleAduana = (e) => {
    this.setState({ aduana_specific: e.target.value });
  };
  handleCliente = (e) => {
    this.setState({ cliente_specific: e.target.value });
  };
  handleInicial = (e) => {
    this.setState({ inicial_specific: e.target.value });
  };
  handleFinal = (e) => {
    this.setState({ final_specific: e.target.value });
  };
  handleObservaciones = (e) => {
    this.setState({ observaciones_specific: e.target.value });
  };
  handleReferencia = (e) => {
    this.setState({ referencia: e.target.value });
  };
  hanldeChange = () => {
    let data_specific = {};
    let importes = "";
    if (this.state.change_form === false) {
      if (this.state.numero_specific.length > 0)
        data_specific = {
          ...data_specific,
          id_solicitud: this.state.numero_specific,
        };
      if (this.state.aduana_specific.length > 0)
        data_specific = {
          ...data_specific,
          aduana: this.state.aduana_specific,
        };
      if (this.state.cliente_specific.length > 0)
        data_specific = {
          ...data_specific,
          cliente: this.state.cliente_specific,
        };
      if (this.state.referencia.length > 0)
        data_specific = {
          ...data_specific,
          referencia: this.state.referencia,
        };
      if (this.state.observaciones_specific.length > 0)
        data_specific = {
          ...data_specific,
          observaciones: this.state.observaciones_specific,
        };
      if (this.state.inicial_specific > 0 && this.state.final_specific > 0) {
        importes =
          this.state.inicial_specific + "," + this.state.final_specific;
        data_specific = { ...data_specific, importes: importes };
      } else if (
        this.state.inicial_specific.length === 0 &&
        this.state.final_specific.length > 0
      ) {
        importes = "0," + this.state.final_specific;
        data_specific = { ...data_specific, importes: importes };
      } else if (
        this.state.inicial_specific.length > 0 &&
        this.state.final_specific.length === 0
      ) {
        importes =
          this.state.inicial_specific + "," + this.state.inicial_specific;
        data_specific = { ...data_specific, importes: importes };
      }
    } else {
      if (document.getElementById("specific_numero_deposito").value.length > 0)
        data_specific = {
          ...data_specific,
          id_deposito: document.getElementById("specific_numero_deposito")
            .value,
        };
      if (
        document.getElementById("specific_fecha_alta_deposito").value.length > 0
      )
        data_specific = {
          ...data_specific,
          fecha_alta: document.getElementById("specific_fecha_alta_deposito")
            .value,
        };
      if (
        document.getElementById("specific_fecha_not_deposito").value.length > 0
      )
        data_specific = {
          ...data_specific,
          fecha_notificacion: document.getElementById(
            "specific_fecha_not_deposito"
          ).value,
        };
      if (
        document.getElementById("specific_improteI_deposito").value.length >
          0 &&
        document.getElementById("specific_improteF_deposito").value.length > 0
      ) {
        importes =
          document.getElementById("specific_improteI_deposito").value +
          "," +
          document.getElementById("specific_improteF_deposito").value;
        data_specific = { ...data_specific, importes: importes };
      } else if (
        document.getElementById("specific_improteI_deposito").value.length ===
          0 &&
        document.getElementById("specific_improteF_deposito").value.length > 0
      ) {
        importes =
          "0," + document.getElementById("specific_improteF_deposito").value;
        data_specific = { ...data_specific, importes: importes };
      } else if (
        document.getElementById("specific_improteI_deposito").value.length >
          0 &&
        document.getElementById("specific_improteF_deposito").value.length === 0
      ) {
        importes =
          document.getElementById("specific_improteI_deposito").value +
          "," +
          document.getElementById("specific_improteI_deposito").value;
        data_specific = { ...data_specific, importes: importes };
      }
      if (
        document.getElementById("specific_status_deposito").value.length > 0
      ) {
        data_specific = {
          ...data_specific,
          status: document.getElementById("specific_status_deposito").value,
        };
      }
    }
    this.props.onChangeSpecific(data_specific);
  };
  /* ------------------- Fin de funciones para capturdar datos de consulta filtrada de Solicitud de Anticipos -------------------------- */
  handleBack = () => {
    this.props.onBack(this.props.page_actual);
  };

  
  render() {
    return (
      <React.Fragment>
        <div className="row mb-2 mx-1">
          <h3 className="col-md-11 col-9 text-md-left px-0 mb-0">
            Consulta Específica
          </h3>
          <Button
            color="info"
            onClick={this.toogleCollapse}
            className="col-md-1 col-3"
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
        <Collapse isOpen={this.state.toogle_collapse}>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                nameLabel="Número Solicitud de Anticipo"
                id="specific_numero_sol_anticipo"
                value={this.state.numero_specific}
                onChange={this.handleNumero}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                nameLabel="Aduana"
                id="specific_aduana_sol_anticipo"
                value={this.state.aduana_specific}
                onChange={this.handleAduana}
                disabled={this.props.toogleTap_referencia === "2" ? true : false }
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                nameLabel="Número Cliente"
                id="specific_cliente_sol_anticipo"
                value={this.state.cliente_specific}
                onChange={this.handleCliente}
                defaultValue={
                  this.props.on_cliente !== false ? this.props.on_cliente : ""
                }
                disabled={this.props.on_cliente !== false ? true : false || this.props.toogleTap_referencia === "2" ? true : false}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                nameLabel="Referencia"
                id="specific_referencia_sol_anticipo"
                value={this.state.referencia}
                onChange={this.handleReferencia}
                disabled={this.props.toogleTap_referencia === "2" ? true : false }
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                nameLabel="Importe (Inicial)"
                id="specific_importe_inicial_sol_anticipo"
                value={this.state.inicial_specific}
                onChange={this.handleInicial}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                nameLabel="Importe (Final)"
                id="specific_importe_final_sol_anticipo"
                value={this.state.final_specific}
                onChange={this.handleFinal}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-12 col-12"
                nameLabel="Observaciones"
                id="specific_obiservaciones_sol_anticipo"
                value={this.state.observaciones_specific}
                onChange={this.handleObservaciones}
              />
            </FormGroup>
          </Form>
          <FormGroup className="row d-flex justify-content-center mb-0">
            <Button color="danger" className="mb-1" onClick={this.handleBack}>
              Regresar
            </Button>
            <Button color="success ml-2 mb-1" onClick={this.hanldeChange}>
              Consultar
            </Button>
          </FormGroup>
        </Collapse>
      </React.Fragment>
    );
  }
}
export default BusquedaEspecifica;
