import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Collapse,
  Card,
  CardBody,
  FormText,
  ListGroup,
  ListGroupItem,
  CustomInput,
  Badge,
} from "reactstrap";
import MyInput from "../../../utils/custom/MyInput";
/* import MyInput from "../utils/MyInput"; */
import CustomSelect from "../../../utils/custom/CustomSelect";
import WebSocketInstance from "../../../utils/websocket";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";
import { faArrowRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ModalConsulta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      numero: "",

      id: null,
      folio: "",
      metodo_pago: "",
      forma_pago: "",
      tipo_comprobante: "",
      tipo_uso: "",
      condicion_pago: "",
      fecha: "",
      numero_select: "",
      nombre_select:"",

      data_modificacion: {},

      nombre_suggestions: [],


      metodos_pago: [
        { id: "PPD", text: "PAGO EN PARCIALIDADES O DIFERIDO" },
        { id: "PUE", text: "PAGO EN UNA SOLA EXHIBICIÓN" },
      ],
      comprobante_options: [
        { id: "I", text: "Ingreso" },
        { id: "E", text: "Egreso" },
        { id: "T", text: "Traslado" },
        { id: "P", text: "Pago" },
        { id: "N", text: "Nomina" },
      ],
      forma_pago_text: "Seleccione una forma de pago",
      forma_pago_id: "",
      tipo_uso_text: "Seleccione un tipo",
      tipo_uso_id: "",
      comprobante_text: "Seleccione un tipo de comprobante",
      comprobante_id: "",
      metodo_pago_text: "Seleccione un metodo de pago",
      metodo_pago_id: "",
    };
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  /* sendNotificacionHandler = (motivo) => {
        let folio = this.state.folio;
        const notificacionObject = {
            tipo: 0,
            aplicacion: 'proveedor',
            motivo: motivo,
            folio: [folio]
        }
        WebSocketInstance.newNotificacion(notificacionObject);
    } */
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleNumero = (e) => {
    this.setState({ numero: e.target.value });
  };

  handleIdChange = (e) => {
    this.setState({ id: e.target.value });
  };

  handleFolioChange = (e) => {
    this.setState({ folio: e.target.value });
  };

  handleMetodoPagoChange = (e) => {
    this.setState({ metodo_pago: e.target.value });
  };

  handleFormaPagoChange = (e) => {
    this.setState({ forma_pago: e.target.value });
  };

  handleTipoComprobanteChange = (e) => {
    this.setState({ tipo_comprobante: e.target.value });
  };

  handleTipoUsoChange = (e) => {
    this.setState({ tipo_uso: e.target.value });
  };

  handleCondicionPagoChange = (e) => {
    this.setState({ condicion_pago: e.target.value });
  };

  handleFechaChange = (e) => {
    this.setState({ fecha: e.target.value });
  };

  handleForma_pago = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let forma_pago_text = e[label];
    let forma_pago_id = e[label_id];
    this.setState({ forma_pago_text, forma_pago_id });
  };

  handleMetodo_pago = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let metodo_pago_text = e[label];
    let metodo_pago_id = e[label_id];
    this.setState({ metodo_pago_text, metodo_pago_id });
  };

  handleTipo_comprobante = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let comprobante_text = e[label];
    let comprobante_id = e[label_id];
    this.setState({ comprobante_text, comprobante_id });
  };

  handleTipo_uso = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let tipo_uso_text = e[label];
    let tipo_uso_id = e[label_id];
    this.setState({ tipo_uso_text, tipo_uso_id });
  };

  handleCondicion_pago = (e) => {
    let condicion_pago = e.target.value;
    this.setState({ condicion_pago });
  };

  handleConsulta = () => {
    let warningMessage = "";
  
    if (this.state.numero.length === 0) {
      warningMessage += "Folio, ";
    }
  
    if (this.state.numero_select.length === 0) {
      warningMessage += "Número de Cliente, ";
    }
  
    if (warningMessage !== "") {
      message.completeMessage(warningMessage.slice(0, -2));
    } else {
      this.methodPOST_API(utility.url_consulta_factura, "consulta", {
        folio: this.state.numero,
        cliente: this.state.numero_select,
      });
    }
  };

  
  handleModificar = () => {
    if (this.state.numero.length === 0)
      message.message_modal("Advertencia", "Debe tener un ID de ingreso", 400);
    else {
      message
        .message_confimacion("¿Desea continuar con la operación modificar?", "")
        .then((res) => {
          if (res) {
            let data_modificacion = this.state.data_modificacion;
            // Verificar si el campo auxiliar se ha modificado
            if (this.state.auxiliar !== this.state.auxiliarInicial) {
              data_modificacion["auxiliar"] = this.state.auxiliar;
            }
            if (Object.values(data_modificacion).length > 0) {
              data_modificacion["clave"] = this.state.numero;
              this.methodPUT_API(
                utility.url_crea_egresos,
                "modificar",
                data_modificacion
              );
              //this.sendNotificacionHandler('modificar');
            } else
              message.message_modal(
                "Advertencia",
                "No hay modificaciones",
                400
              );
          }
        });
    }
  };
  handleCerrar = () => {
    this.setState({
      numero: "",

      id: null,
      folio: "",
      metodo_pago: "",
      forma_pago: "",
      tipo_comprobante: "",
      tipo_uso: "",
      condicion_pago: "",
      fecha: "",
      numero_select: "",
      nombre_select:"",

      data_modificacion: {},

      nombre_suggestions: [],


      metodos_pago: [
        { id: "PPD", text: "PAGO EN PARCIALIDADES O DIFERIDO" },
        { id: "PUE", text: "PAGO EN UNA SOLA EXHIBICIÓN" },
      ],
      comprobante_options: [
        { id: "I", text: "Ingreso" },
        { id: "E", text: "Egreso" },
        { id: "T", text: "Traslado" },
        { id: "P", text: "Pago" },
        { id: "N", text: "Nomina" },
      ],
      forma_pago_text: "Seleccione una forma de pago",
      forma_pago_id: "",
      tipo_uso_text: "Seleccione un tipo",
      tipo_uso_id: "",
      comprobante_text: "Seleccione un tipo de comprobante",
      comprobante_id: "",
      metodo_pago_text: "Seleccione un metodo de pago",
      metodo_pago_id: "",
    });
    this.props.toogleConsulta();
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta":
              this.setState({
                id: response.data.results.id,
                fecha: response.data.results.fecha,
                metodo_pago: response.data.results.metodo_pago,
                forma_pago: response.data.results.forma_pago,
                tipo_comprobante: response.data.results.tipo_comprobante,
                tipo_uso: response.data.results.tipo_uso,
                condicion_pago: response.data.results.condicion_pago,
              });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  methodPUT_API = (url, state, data) => {
    let url_intern = url;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "modificar":
              this.handleCerrar();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  /* ------ Bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */
  nameGenerator = (e) => {
    let text_add = "";
    let numero_cliente = e.target.value;
    if (numero_cliente === "") text_add = "";
    else if (numero_cliente.length <= 4) {
      switch (numero_cliente.length) {
        case 1:
          text_add = "000";
          text_add += numero_cliente;
          break;
        case 2:
          text_add = "00";
          text_add += numero_cliente;
          break;
        case 3:
          text_add = "0";
          text_add += numero_cliente;
          break;
        case 4:
          text_add = numero_cliente;
          break;
        default:
          return null;
      }
    } else text_add = "";
    for (let i = 0; i < this.props.clientes.length; i++) {
      if (text_add.length === 0) {
        document.getElementById("nombre_cliente_cta_gastos_alta").value = "";
        this.suggestionSelected("");
        break;
      }
      if (text_add === this.props.clientes[i].numero) {
        document.getElementById("nombre_cliente_cta_gastos_alta").value =
          this.props.clientes[i].nombre;
        this.suggestionSelected(this.props.clientes[i].nombre);
        break;
      }
    }
  };
  suggestionSelected = (value) => {
    let number = utility.get_number_cliente(value, this.props.clientes);
    if (number === undefined) number = "";
    document.getElementById("numero_cliente_cta_gastos_alta").value = number;
    this.setState({
      nombre_select: value,
      numero_select: number,
      nombre_suggestions: [],
    });
  };
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_nombres_clientes(this.props.clientes)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      nombre_suggestions: suggestions,
      nombre_select: value,
    });
  };
  renderSuggestion = () => {
    if (this.state.nombre_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.nombre_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  /* ------ Fin del bloque de funciones que nos permitiran el autocompletado de nombre de cliente dependiendo su número ------ */

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>Consulta Facturas</ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4"
                id="folio_consulta"
                value={this.state.numero}
                onChange={this.handleNumero}
                nameLabel="Folio"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4"
                id="numero_cliente_cta_gastos_alta"
                onChange={this.nameGenerator}
                value={this.state.numero_select}
                nameLabel="Número de Cliente"
              />
              <Button
                color="primary"
                className="col-lg-4 col-4 mb-2"
                onClick={this.handleConsulta}
              >
                Consulta
              </Button>
            </FormGroup>

            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-8"
                id="nombre_cliente_cta_gastos_alta"
                onChange={this.onNameChange}
                value={this.state.nombre_select}
                nameLabel="Nombre de Cliente"
              />
              {this.renderSuggestion()}
            <MyInput
                type="date"
                bootstrap="col-lg-4"
                id="fecha_consulta"
                nameLabel="Fecha"
                value={this.state.fecha}
                onChange={this.handleFechaChange}
              />
            </FormGroup>

            <FormGroup className="row mb-0">
                    <CustomSelect
                      bootstrap="col-lg-4 col-12 mb-2"
                      name_text="text"
                      name_index="id"
                      id="meotodo_pago_alta_factura"
                      nameLabel="Metodo de Pago"
                      options={this.state.metodos_pago}
                      value={this.state.metodo_pago_text}
                      onChange={this.handleMetodo_pago}
                    />
                    <CustomSelect
                      bootstrap="col-lg-4 col-12 mb-2"
                      name_text="descripcion"
                      name_index="clave"
                      id="forma_pago_alta_factura"
                      nameLabel="Forma de Pago"
                      options={this.props.formas_pagos}
                      value={this.state.forma_pago_text}
                      onChange={this.handleForma_pago}
                    />
                    <CustomSelect
                      bootstrap="col-lg-4 col-12 mb-2"
                      name_text="text"
                      name_index="id"
                      id="forma_pago_alta_factura"
                      nameLabel="Tipo comprobante"
                      options={this.state.comprobante_options}
                      value={this.state.comprobante_text}
                      onChange={this.handleTipo_comprobante}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <CustomSelect
                      bootstrap="col-lg-6 col-12 mb-2"
                      name_text="descripcion"
                      name_index="clave"
                      id="tipo_uso_alta_factura"
                      nameLabel="Tipo de uso"
                      options={this.props.tipos_usos}
                      value={this.state.tipo_uso_text}
                      onChange={this.handleTipo_uso}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="condicion_factura_alta"
                      nameLabel="Condiciones de pago"
                      value={this.state.condicion_pago}
                      onChange={this.handleCondicion_pago}
                    />
                  </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalConsulta;
