/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un anticipo. 
*/
import axios from 'axios';
import React, { Component } from 'react';
import { Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as message from '../messages/messages';
import MyInput from '../utils/MyInput';
import MySelect from '../utils/MySelect';
import MyTextArea from '../utils/MyTextArea';
import * as utility from '../utils/utility';
import WebSocketInstance from "../../../utils/websocket";

class ModalAlta extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            departamentos: [],
        };
    }
    /*--------------------------------------------------------------------- */
    /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
    sendNotificacionHandler = (motivo) => {
        let folio = this.state.identificativo;
        const notificacionObject = {
            tipo: 0,
            aplicacion: 'puesto',
            motivo: motivo,
            folio: [folio]
        }
        WebSocketInstance.newNotificacion(notificacionObject);
    }
    /*--------------------------------------------------------------------- */
    componentDidMount() {
        this._isMounted = true;
        this.methodGET_API(utility.url_departamentos, 'departamentos');
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    handleAlta = () => {
        message.message_confimacion('¿Desea continuar con la operación alta?', '')
            .then(res => {
                if (res) {
                    let data = {
                        puesto: document.getElementById("nombre_alta_puesto").value,
                        id_departamento: document.getElementById("departamento_alta_puesto").value,
                        descripcion: document.getElementById("descripcion_alta_puesto").value
                    };
                    this.methodPOST_API(utility.url_crea_puesto, 'alta', data);
                }
            });
    }
    methodGET_API = (url, state) => {
        let url_intern = url;
        axios.get(url_intern)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        case 'departamentos':
                            if (this._isMounted)
                                this.setState({ departamentos: response.data.results });
                            break;
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    methodPOST_API = (url, state, data) => {
        let url_intern = url;
        axios.post(url_intern, data)
            .then(response => {
                if (response.status >= 200 && response.status < 400)
                    switch (state) {
                        case 'alta':
                            this.setState({ identificativo: response.data.results.puesto }, () => {
                                this.sendNotificacionHandler('crear');
                            })
                            this.props.toogleNuevo();
                            message.message_modal(response.data.title, response.data.text, response.status);
                            this.props.update(1);
                            break;
                        default:
                            break;
                    }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    render() {
        return (
            <Modal isOpen={this.props.toogle_nuevo} toggle={this.props.toogleNuevo} backdrop="static" size="xl"  >
                <ModalHeader toggle={this.props.toogleNuevo}>Nuevo Puesto</ModalHeader>
                <ModalBody>
                    <Form className="container-fluid">
                        <FormGroup className="row mb-0">
                            <MyInput type="text" bootstrap="col-lg-6 col-12" id="nombre_alta_puesto" nameLabel="Puesto" />
                            <MySelect type="text" bootstrap="col-lg-6 col-12" id="departamento_alta_puesto" nameLabel="Departamento"
                                first_option="Seleccione un departamento">
                                {this.state.departamentos.map(departamento => (
                                    <option key={departamento.id_departamento} value={departamento.id_departamento}>{departamento.nombre}</option>
                                ))}
                            </MySelect>
                            <MyTextArea bootstrap="col-lg-12 col-12" id="descripcion_alta_puesto" nameLabel="Descripción" />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={this.handleAlta} >Aceptar</Button>
                    <Button color="primary" onClick={this.props.toogleNuevo}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default ModalAlta;