/* 
Autor: Alexis Yael Hernández Grimaldo
Descripción: El componenete genera un bloque correspondiente a la busqueda dinamica en la tabla para acceder a la información.
*/
import React, { Component } from "react";
import { Form, FormGroup, Input } from "reactstrap";
import "../../assets/style.css";
import ModalAction from "./Modal/ModalAction";
import * as utility from "./utils/utility";

export default class BusquedaTabla extends Component {
  render() {
    return (
      <div className="row my-2">
        <div className="col-lg-9 col-sm-12">
          <Form>
            <FormGroup className="d-flex justify-content-center flex-column mb-0">
              <Input
                type="text"
                placeholder="Buscar"
                id="busqueda_general_alerta"
                onKeyUp={utility.general_search}
              />
              <Input
                type="date"
                placeholder="Fecha"
                className="mt-1"
                id="busqueda_date_alerta"
                onChange={utility.date_search}
              />
            </FormGroup>
          </Form>
        </div>
        <ModalAction
          clientes={this.props.clientes}
          consolidadoras={this.props.consolidadoras}
          update={this.props.update}
          checked_recolectar={this.props.checked_recolectar}
          checked_llegada={this.props.checked_llegada}
          checked_entrega={this.props.checked_entrega}
          checkedRecoleccion={this.props.checkedRecoleccion}
          checkedLlegada={this.props.checkedLlegada}
          checkedEntrega={this.props.checkedEntrega}
          permisos={this.props.permisos}
          on_cliente={this.props.on_cliente}
        />
      </div>
    );
  }
}
