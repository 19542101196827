/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un empleado. 
*/
import swal from "@sweetalert/with-react";
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import CustomSelect from "../../../utils/custom/CustomSelect";
import MyInput from "../../../utils/custom/MyInput";
import MyTextArea from "../../../utils/custom/MyTextArea";
import WebSocketInstance from "../../../utils/websocket";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      departamentos: [],
      puestos: [],
      puestos_suggestions: [],
      puesto_select: "",
      new_puesto_id: "",
      estados: [],

      domicilio: "",
      colonia_text: "Seleccione una colonia",
      colonia_id: "",
      codigo_postal: "",
      estado: "",
      ciudad: "",
      departamento_id: "",
      departamento_text: "Seleccione un departamento",

      colonias: [],

      identificativo: "",
    };
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo, app) => {
    let folio = this.state.identificativo;
    const notificacionObject = {
      tipo: 0,
      aplicacion: app,
      motivo: motivo,
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_departamentos, "departamentos");
    this.methodGET_API(utility.url_puestos, "puestos");
    this.methodGET_API(utility.url_estados, "estados");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleAlta = () => {
    message
      .message_confimacion("¿Desea continuar con la operación alta?", "")
      .then((res) => {
        if (res) {
          let data = utility.handleTextInput(this.state.puestos);
          data["departamento"] = this.state.departamento_id;
          data["colonia"] = this.state.colonia_id;
          if (data.puesto === null) {
            this.handlePuesto({
              puesto: document.getElementById("puesto_alta_empleado").value,
              id_departamento: data.departamento,
            });
          } else {
            this.methodPOST_API(utility.url_crea_empleado, "alta", data);
          }
        }
      });
  };
  handlePuesto = (data) => {
    swal({
      icon: "warning",
      closeOnClickOutside: false,
      title: "Puesto inexistente",
      text: "Se dara de alta el puesto y continuara el proceso",
      buttons: ["Cancelar", true],
      dangerMode: true,
      content: (
        <div>
          <button
            value={JSON.stringify(data)}
            id="data"
            className="d-none"
          ></button>
          <MyTextArea
            bootstrap="col-12"
            id="descripción_alta_puesto"
            nameLabel="Descripción"
          />
        </div>
      ),
    }).then((res) => {
      if (res) {
        let data = JSON.parse(document.getElementById("data").value);
        data["descripcion"] = document.getElementById(
          "descripción_alta_puesto"
        ).value;
        if (data.descripcion.length === 0)
          message.message_modal(
            "Advertencia",
            "Es necesario una descripción del puesto",
            400
          );
        else this.methodPOST_API(utility.url_crea_puesto, "alta_puesto", data);
      } else swal("Error", "Seleccione un puesto existente", "warning");
    });
  };

  handleDomicilio = (e) => {
    let domicilio = e.target.value;
    this.setState({ domicilio });
  };
  handleColonia = (e) => {
    let estado, ciudad;
    let label_id = e.name_index;
    let label = e.name_text;
    let colonia_text = e[label];
    let colonia_id = e[label_id];
    this.state.colonias.forEach((col) => {
      if (colonia_id === col.asentamiento) {
        estado = col.estado;
        ciudad = col.ciudad;
      }
    });
    this.setState({ colonia_text, colonia_id, estado, ciudad });
  };
  handleDepartamento = (e) => {
    let label_id = e.name_index;
    let label = e.name_text;
    let departamento_text = e[label];
    let departamento_id = e[label_id];
    this.setState({ departamento_text, departamento_id });
  };
  handleCodigo_postal = (e) => {
    let codigo_postal = e.target.value;
    if (codigo_postal.length === 5)
      this.methodPOST_API(utility.url_codigo_postal, "codigo_postal", {
        codigo: codigo_postal,
      });
    this.setState({ codigo_postal });
  };
  handleEstado = (e) => {
    let estado = e.target.value;
    this.setState({ estado });
  };
  handleCiudad = (e) => {
    let ciudad = e.target.value;
    this.setState({ ciudad });
  };

  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "departamentos":
              if (this._isMounted)
                this.setState({ departamentos: response.data.results });
              break;
            case "puestos":
              if (this._isMounted)
                this.setState({ puestos: response.data.results });
              break;
            case "estados":
              if (this._isMounted)
                this.setState({ estados: response.data.results });
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Función que permite hacer una petición de forma POST
   * @param {String} url URL a consultar
   * @param {String} state Onpción del case
   * @param {Object} data Objeto a mandar atraves del metodo POST
   */
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case "codigo_postal":
              if (this._isMounted)
                this.setState({
                  colonias: response.data.results,
                });
              break;
            case "alta":
              this.setState(
                { identificativo: response.data.results.nombre },
                () => {
                  this.sendNotificacionHandler("crear", "empleado");
                }
              );
              this.onClearState();
              this.props.toogleNuevo();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            case "alta_puesto":
              let new_data = "";
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.setState(
                {
                  new_puesto_id: response.data.results.id_puesto,
                  identificativo: response.data.results.puesto,
                },
                () => {
                  this.sendNotificacionHandler("crear", "puesto");
                  new_data = utility.handleTextInput(this.state.puestos);
                  new_data["puesto"] = this.state.new_puesto_id;
                  this.methodPOST_API(
                    utility.url_crea_empleado,
                    "alta",
                    new_data
                  );
                  this.methodGET_API(utility.url_puestos, "puestos");
                }
              );
              break;
            default:
              break;
          }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  /**
   * Funcion que borra el estado para no un mejor funcionamiento
   */
  onClearState = () => {
    this.setState({
      puestos_suggestions: [],
      puesto_select: "",
      new_puesto_id: "",
    });
  };
  /* ------ Bloque de funciones que nos permitiran el autocompletado de un puesto ------ */
  onNameChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_name_puesto(this.state.puestos)
        .sort()
        .filter((v) => regex.test(v));
    }
    this.setState({
      puestos_suggestions: suggestions,
      puesto_select: value,
    });
  };
  renderSuggestion = () => {
    if (this.state.puestos_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.puestos_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelected(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };
  suggestionSelected = (value) => {
    document.getElementById("puesto_alta_empleado").value = value;
    this.setState({
      puesto_select: value,
      puestos_suggestions: [],
    });
  };
  /* ------ Bloque de funciones que nos permitiran el autocompletado de un puesto ------ */
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_nuevo}
        toggle={this.props.toogleNuevo}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.props.toogleNuevo}>
          Nuevo Empleado
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="nombre_alta_empleado"
                nameLabel="Nombre(s)"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="apellidoP_alta_empleado"
                nameLabel="Apellido Paterno"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="apellidoM_alta_empleado"
                nameLabel="Apellido Materno"
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="rfc_alta_empleado"
                nameLabel="RFC"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="curp_alta_empleado"
                nameLabel="CURP"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="imss_alta_empleado"
                nameLabel="Número de Seguro Social"
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="domicilio_alta_empleado"
                nameLabel="Domicilio"
                value={this.state.domicilio}
                onChange={this.handleDomicilio}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="cp_alta_empleado"
                nameLabel="Código Postal"
                value={this.state.codigo_postal}
                onChange={this.handleCodigo_postal}
              />
              <CustomSelect
                bootstrap="col-lg-4 col-12"
                name_text="asentamiento"
                name_index="asentamiento"
                id="colonia_alta_empleado"
                nameLabel="Colonia"
                value={this.state.colonia_text}
                onChange={this.handleColonia}
                options={this.state.colonias}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="estado_alta_empleado"
                nameLabel="Estado/Provincia/Región"
                value={this.state.estado}
                disabled
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                id="ciudad_alta_empleado"
                nameLabel="Ciudad"
                value={this.state.ciudad}
                disabled
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="telefono_alta_empleado"
                nameLabel="Telefono"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="correo_alta_empleado"
                nameLabel="Correo"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="nivel_estudios_alta_empleado"
                nameLabel="Nivel Estudios"
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <CustomSelect
                bootstrap="col-lg-4 col-12"
                name_text="nombre"
                name_index="id_departamento"
                id="departamento_alta_empleado"
                nameLabel="Departamento"
                value={this.state.departamento_text}
                onChange={this.handleDepartamento}
                options={this.state.departamentos}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4 col-12"
                id="puesto_alta_empleado"
                nameLabel="Puesto"
                value={this.state.puesto_select}
                onChange={this.onNameChange}
              />
              <MyInput
                type="date"
                bootstrap="col-lg-4 col-12"
                id="fecha_ingreso_alta_empleado"
                nameLabel="Fecha de ingreso"
              />
            </FormGroup>
            <FormGroup className="text-center">
              {this.renderSuggestion()}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={this.handleAlta}>
            Aceptar
          </Button>
          <Button color="primary" onClick={this.props.toogleNuevo}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalAlta;
