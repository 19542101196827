/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente Principal para el Modal de las distintas opciones de operación para solicitud de anticipos. 
*/
import React, { Component } from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import ModalConsulta from './ModalConsulta';
import * as permisos from '../../../utils/permisos/permisos';

class ModalAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toogle_consulta: false,

        }
        this.toogleConsulta = this.toogleConsulta.bind(this);
    }
    toogleConsulta = () => {
        this.setState((prevState) => ({ toogle_consulta: !prevState.toogle_consulta }));
    }
    render() {
        return (
            <div className="col-lg-3 col-sm-12 d-flex justify-content-center flex-column mb-2 my-md-2">
                <ButtonGroup className="mb-1">
                    <Button color="primary" disabled={permisos.obtener_permiso_especifico('crear', this.props.permisos)}>
                        Nuevo
					</Button>
                </ButtonGroup>
                <ButtonGroup className="mb-1">
                    <Button color="success" onClick={this.toogleConsulta}>
                        Consulta
					</Button>
                </ButtonGroup>
                <ModalConsulta toogle_consulta={this.state.toogle_consulta} toogleConsulta={this.toogleConsulta} />
            </div>
        );
    }
}
export default ModalAction;