/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un Proveedor. 
*/
import swal from "@sweetalert/with-react";
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormText,
  Collapse,
  Card,
  CardBody,
} from "reactstrap";
import MyInput from "../../../utils/custom/MyInput";
import MySelect from "../../../utils/custom/MySelect";
import WebSocketInstance from "../../../utils/websocket";
import MyTextArea from "../utils/MyTextArea";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalAuxiliar from "../../Poliza/Modal/ModalAuxiliar";
import ModalCrearEnCatalogos from "../../CuentasAux/Modal/ModalCrearEnCatalogos";

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      nombre_corto: "",

      auxiliar: "",
      codigo: "",
      nivel: 0,
      naturaleza: "",
      tipoCuenta: "",
      descripcion: "",
      toogle_collapse: false,
      nombre_select: "",
      tipo: "",
      mayor: "",
      subcuenta: "",
      detalle: "",
      cuentas_mayor: [],
      cuentas_menor: [],

      toogle_phxcdc: false,
      toogle_cuentaux: false,

      clave: "",
      toogle_auxiliar: false,
      auxiliares: [],
      enableCuentaAuxiliar: true,
    };
    this.toogleAltaPhxcdc = this.toogleAltaPhxcdc.bind(this);
    this.toogleCuentaAux = this.toogleCuentaAux.bind(this);
    this.toogleAuxiliar = this.toogleAuxiliar.bind(this);
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  /*  sendNotificacionHandler = (motivo) => {
        let folio = this.state.identificativo;
        const notificacionObject = {
            tipo: 0,
            aplicacion: 'proveedor',
            motivo: motivo,
            folio: [folio]
        }
        WebSocketInstance.newNotificacion(notificacionObject);
    } */
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleNombre = (e) => {
    let nombre = e.target.value;
    this.setState({ nombre });
  };
  handleNombre_corto = (e) => {
    let nombre_corto = e.target.value;
    this.setState({ nombre_corto });
  };
  handleAuxiliar = (e) => {
    let auxiliar = e.target.value;
    this.setState({ auxiliar });
  };
  handleNivel = (e) => {
    let nivel = parseInt(e.target.value);
    if (nivel >= 2)
      this.methodPOST_API(utility.url_pagination_cuentas, "cuentas_mayor", {
        nivel: 1,
      });
    this.setState({ nivel, mayor: "", subcuenta: "", detalle: "" });
  };
  handleMayor = (e) => {
    let mayor = e.target.value;
    if (this.state.nivel === 3)
      this.methodPOST_API(utility.url_pagination_cuentas, "cuentas_menor", {
        mayor: mayor,
        nivel: 2,
      });
    this.setState({ mayor });
  };
  handleSubcuenta = (e) => {
    let subcuenta = e.target.value;
    this.setState({ subcuenta });
  };
  handleDetalle = (e) => {
    let detalle = e.target.value;
    this.setState({ detalle });
  };
  handleNivel = (e) => {
    let nivel = parseInt(e.target.value);
    if (nivel >= 2)
      this.methodPOST_API(utility.url_pagination_cuentas, "cuentas_mayor", {
        nivel: 1,
      });
    this.setState({ nivel, mayor: "", subcuenta: "", detalle: "" });
  };
  handleNaturaleza = (e) => {
    let naturaleza = e.target.value;
    this.setState({ naturaleza });
  };
  handleTipoCuenta = (e) => {
    let tipoCuenta = e.target.value;
    this.setState({ tipoCuenta });
  };
  handleTipo = (e) => {
    let tipo = e.target.value;
    this.setState({ tipo });
  };
  handleDescripcion = (e) => {
    let descripcion = e.target.value;
    this.setState({ descripcion });
  };

  toogleCollapse = (e) => {
    this.setState((prevState) => ({
      toogle_collapse: !prevState.toogle_collapse,
    }));
  };
  toogleAltaPhxcdc = () => {
    this.setState((prevState) => ({
      toogle_phxcdc: !prevState.toogle_phxcdc,
      toogle_cuentaux: false,
    }));
  };
  toogleCuentaAux = () => {
    this.setState((prevState) => ({
      toogle_cuentaux: !prevState.toogle_cuentaux,
      toogle_phxcdc: false,
    }));
  };
  toogleCuentaAux = () => {
    this.setState((prevState) => ({
      toogle_cuentaux: !prevState.toogle_cuentaux,
      toogle_phxcdc: false,
    }));
  };

  handleCallback = (childData) => {
    this.setState({ auxiliar: childData });
  };
  toogleAuxiliar = () => {
    this.setState((prevState) => ({
      toogle_auxiliar: !prevState.toogle_auxiliar,
    }));
    if (this.state.auxiliar === "") {
      this.getAuxiliaresMayores();
    } else {
      this.postAuxiliarSegundoNivel();
    }
  };
  getAuxiliaresMayores() {
    const url = utility.url_auxiliares;
    axios
      .get(url)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              auxiliares: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  postAuxiliarSegundoNivel() {
    axios
      .post(utility.url_auxiliares, {
        auxiliar: this.state.auxiliar,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          if (this._isMounted)
            this.setState({
              auxiliares: response.data.results,
            });
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  }

  handleAltaPhxcdc = () => {
    message
      .message_confimacion("¿Desea continuar con la operación alta?", "")
      .then((res) => {
        if (res) {
          let data = {
            nombre: this.state.nombre,
            corto: this.state.nombre_corto,
          };
          if (utility.validadCamposPOST(data).length > 0)
            message.message_modal(
              "Falta completar los siguientes campos:",
              utility.validadCamposPOST(data),
              400
            );
          else {
            this.methodPOST_API(utility.url_crea_phxcdc, "alta", data);
            this.setState({ enableCuentaAuxiliar: false });
            this.toogleAltaPhxcdc();
            this.toogleCuentaAux();
          }
        }
      });
  };

  handleAltaCA = () => {
    if (
      this.state.codigo === "" ||
      this.state.nivel === "" ||
      this.state.naturaleza === "" ||
      this.state.tipoCuenta === "" ||
      this.state.descripcion === ""
    ) {
      message.warningMessage("Por favor ingrese todos los campos");
    } else {
      axios
        .post(utility.url_crea_cuentas_aux, {
          auxiliar: utility.valida_cuenta(
            this.state.mayor,
            this.state.subcuenta,
            this.state.nivel
          ),
          codigo: this.state.codigo,
          clave: this.state.tipo,
          descripcion: this.state.descripcion,
          nivel: this.state.nivel,
          naturaleza: this.state.naturaleza,
          tpo_cuenta: this.state.tipoCuenta,
        })
        .then((response) => {
          if (response.status >= 200 && response.status < 400) {
            if (this._isMounted) {
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response)
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
        });
    }
  };
  handleAltaPhxcdcCA = () => {
    if (this.state.nombre_corto === "" || this.state.nombre === "" ) {
      message.warningMessage("Por favor ingrese todos los campos");
    } else {
      axios
        .post(utility.url_crea_phxcdc, {
          nombre: this.state.nombre,
          corto: this.state.nombre_corto,
        })
        .then((response) => {
          if (response.status >= 200 && response.status < 400) {
            if (this._isMounted) {
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              this.handleCerrar();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response)
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
        });
    }
  };

  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta":
              if (this._isMounted) {
                this.setState({
                  clave: response.data.results.clave,
                });
                message.message_modal(
                  response.data.title,
                  response.data.text,
                  response.status
                );
                this.props.update(1);
              }
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  nameGenerator = (e) => {
    let codigo = e.target.value;
    this.setState({ codigo });
    for (let i = 0; i < this.props.codigos_sat.length; i++) {
      if (codigo.length === 0) this.suggestionSelected("");
      if (codigo === this.props.codigos_sat[i].codigo)
        this.suggestionSelected(this.props.codigos_sat[i].descripcion);
    }
  };
  suggestionSelected = (value) => {
    this.setState({
      nombre_select: value,
    });
  };
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  handleCerrar = () => {
    this.setState({
      nombre: "",
      nombre_corto: "",

      auxiliar: "",
      codigo: "",
      nivel: 0,
      naturaleza: "",
      tipoCuenta: "",
      descripcion: "",
      toogle_collapse: false,
      nombre_select: "",
      tipo: "",
      mayor: "",
      subcuenta: "",
      detalle: "",
      cuentas_mayor: [],
      cuentas_menor: [],

      toogle_phxcdc: false,
      toogle_cuentaux: false,

      clave: "",
      toogle_auxiliar: false,
      auxiliares: [],
      enableCuentaAuxiliar: true,
    });
    this.props.toogleNuevo();
  };
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_nuevo}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Alta Pagos A Terceros
        </ModalHeader>
        <ModalBody>
          {/*  
          <div className="row mb-2 d-flex justify-content-center">
            <div className="col-lg-10 col-12 d-flex justify-content-around">
              <Button color="primary" onClick={this.toogleAltaPhxcdc}>
                Pagos A Terceros
              </Button>
              <Button
                color="info"
                onClick={this.toogleCuentaAux}
                disabled={this.state.enableCuentaAuxiliar}
              >
                Cuenta Auxiliar
              </Button>
            </div>
          </div>
          <Collapse isOpen={this.state.toogle_phxcdc}>
            <Card outline color="primary">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-12"
                      id="nombre_ingreso_alta"
                      nameLabel="Nombre Pagos A Terceros"
                      value={this.state.nombre}
                      onChange={this.handleNombre}
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-12"
                      id="nombre_corto_ingreso_alta"
                      nameLabel="Nombre Corto"
                      value={this.state.nombre_corto}
                      onChange={this.handleNombre_corto}
                      maxLength="7"
                    />
                  </FormGroup>
                  <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                    <Button
                      color="success"
                      className="mr-2"
                      onClick={this.handleAltaPhxcdc}
                    >
                      Aceptar
                    </Button>
                    <Button color="primary" onClick={this.handleCerrar}>
                      Cerrar
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.toogle_cuentaux}>
            <Card outline color="info">
              <CardBody>
                <Form className="container-fluid">
                  <FormGroup className="row mb-0">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-8 col-12"
                      id="auxiliar_poliza"
                      nameLabel="Auxiliar"
                      value={this.state.auxiliar}
                      onChange={this.handleAuxiliar}
                    />
                    <Button
                      color="primary"
                      className="col-lg-4 col-12 mb-2"
                      onClick={this.toogleAuxiliar}
                    >
                      Consulta
                    </Button>
                  </FormGroup>
                </Form>
                {this.state.toogle_cuentaux && (
                  <ModalCrearEnCatalogos
                    toogle_catalogo={this.state.toogle_cuentaux}
                    toogleCatalogo={this.toogleAuxiliar}
                    codigos_sat={this.props.codigos_sat}
                  />
                )}
                <br></br>
                <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
                  <Button
                    color="success"
                    className="mr-2"
                    onClick={this.handleAltaPhxcdcCA}
                  >
                    Aceptar
                  </Button>
                  <Button color="primary" onClick={this.handleCerrar}>
                    Cerrar
                  </Button>
                </FormGroup>
              </CardBody>
            </Card>
          </Collapse> */}

          <CardBody>
            <Form className="container-fluid">
              <FormGroup className="row mb-0">
                <MyInput
                  type="text"
                  bootstrap="col-lg-12 col-2"
                  id="nombre_ingreso_alta"
                  nameLabel="Nombre Pagos A Terceros"
                  value={this.state.nombre}
                  onChange={this.handleNombre}
                />
              </FormGroup>
              <FormGroup className="row mb-0">
                <MyInput
                  type="text"
                  bootstrap="col-lg-12 col-2"
                  id="nombre_corto_ingreso_alta"
                  nameLabel="Nombre Corto"
                  value={this.state.nombre_corto}
                  onChange={this.handleNombre_corto}
                  maxLength="7"
                />
              </FormGroup>
            </Form>
          </CardBody>
        </ModalBody>
        <ModalFooter>
          <FormGroup className="row mb-0 mt-3 d-flex justify-content-end">
            <Button
              color="success"
              className="mr-2"
              onClick={this.handleAltaPhxcdcCA}
            >
              Aceptar
            </Button>
            <Button color="primary" onClick={this.handleCerrar}>
              Cerrar
            </Button>
          </FormGroup>
        </ModalFooter>
        <ModalAuxiliar
          toogle_catalogo={this.state.toogle_auxiliar}
          toogleCatalogo={this.toogleAuxiliar}
          listaAuxiliares={this.state.auxiliares}
          parentCallback={this.handleCallback}
        />
      </Modal>
    );
  }
}
export default ModalAlta;
