
import React, { Component } from "react";
import { Form, FormGroup, Input } from "reactstrap";
import "../../../assets/style.css";
import ModalAction from "./Modal/ModalAction";
import ModalAlta from "./Modal/ModalAlta";
import * as utility from "./utils/utility";

class BusquedaTabla extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-lg-9 col-md-8 col-sm-12 my-2">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <FormGroup className="d-flex justify-content-center flex-column mb-0">
              <Input
                type="text"
                placeholder="Buscar"
                id="busqueda_general_cuentas_auxiliares"
                onKeyUp={utility.general_search}
              />
            </FormGroup>
          </Form>
        </div>
        <ModalAction
          cuenta_select={this.props.cuenta_select}
          codigos_sat={this.props.codigos_sat}
          update={this.props.update}
          desglose_cuenta={this.props.desglose_cuenta}
          onback={this.props.onback}
          on_back={this.props.on_back}
        />
      </div>
    );
  }
}
export default BusquedaTabla;
